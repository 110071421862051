import React from 'react';
import ExamMetrics from '../ExamMetrics';

function ExamMetricsList() {
  return (
    <main className="main-content main-content--block">
      <ExamMetrics />
    </main>
  );
}

export default ExamMetricsList;
