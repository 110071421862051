import { useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';

const FIFTEEN_MINUTES_IN_SECONDS = 900;

export function YoutubeWithSurvey({ videoId, showModalSurvey, answers, setShowModalSurvey = () => null }) {
  const [watchedTime, setWatchedTime] = useState(0);

  const playerRef = useRef(null);
  const intervalRef = useRef(null);
  const lastTimeRef = useRef(null);
  const videoRef = useRef(null);

  const onReady = event => {
    event.target.playVideo();
    videoRef.current = event.target;
    callInterval();
    setPlayer(event.target);
  };

  const handleStateChange = event => {
    if (event.data === YouTube.PlayerState.PLAYING) {
      lastTimeRef.current = event.target.getCurrentTime();
      intervalRef.current = setInterval(() => {
        const currentTime = event.target.getCurrentTime();
        const diff = currentTime - lastTimeRef.current;
        lastTimeRef.current = currentTime;
        setWatchedTime(prevTime => Math.round(prevTime + diff));
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    if (watchedTime >= FIFTEEN_MINUTES_IN_SECONDS && !showModalSurvey) {
      setShowModalSurvey(true);
    }
  }, [watchedTime, showModalSurvey]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <YouTube
      ref={playerRef}
      videoId={videoId}
      onReady={onReady}
      onStateChange={answers.length < 5 ? handleStateChange : null}
      style={{ height: '100%' }}
      opts={{
        height: '100%',
        width: '100%',
        playerVars: {
          autoplay: 1
        }
      }}
    />
  );
}
