import { AnswerCommentForm } from 'app/components/AnswerCommentForm';
import { useContext, useEffect, useState } from 'react';
import userAvatar from 'app/images/user-avatar.svg';
import trashIcon from 'app/images/icons/trash-icon.svg';
import moment from 'moment';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { useSession } from 'app/hooks/useSession';
import Swal from 'sweetalert2';
import { ProjectsContext } from './provider';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export function ProjectsCardComment({ comment, comments, onDelete }) {
  const { session } = useSession();
  const client = useClient();
  const { t } = useTranslation();
  const authorityLevel = getAuthorityLevel(session);
  const { selectedProject } = useContext(ProjectsContext);
  const [isOpenAnswer, setIsOpenAnswer] = useState(false);
  const [answers, setAnswers] = useState([]);

  const findAnswers = () => {
    const commentsWithAnswers = comments?.filter(comment => comment?.['comment-parent-id'] !== null);
    const answersByCommentId = commentsWithAnswers?.filter(item => item?.['comment-parent-id'] === comment?.id);

    setAnswers(answersByCommentId);
  };

  const handleCreateAnswer = async value => {
    try {
      const response = await client.mutate('/comments', {
        'comment-parent-id': comment.id,
        body: value,
        'project-version-id': comment['project-version-id']
      });

      setAnswers(prev => [response.data, ...prev]);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao responder');
    }
  };

  const handleDeleteAnswer = async answerId => {
    const { error } = await client.delete(`/comments/${answerId}`);

    if (!error) {
      setAnswers(prev => prev.filter(item => item.id !== answerId));
    } else {
      console.log(error);
      toast.error('Erro ao deletar resposta');
    }
  };

  useEffect(() => {
    findAnswers();
  }, [comments]);

  return (
    <div className="post-comment">
      <div
        className="post-comment__avatar"
        style={{
          backgroundImage: `url(${(comment.user && comment.user.image.url) || userAvatar})`
        }}
      />

      <div className="post-comment__body">
        <div className="post-comment__header">
          <div className="post-comment__header-name">{(comment.user && comment.user.name) || 'Usuário do sistema'}</div>
          <div className="post-comment__header-time">{moment(comment.created_at).fromNow()}</div>
        </div>

        <div className="post-comment__description">
          <div className="post-comment__description-text">{comment.body}</div>
        </div>

        <AnswerCommentForm
          answers={answers}
          onSubmit={handleCreateAnswer}
          isOpenAnswer={isOpenAnswer}
          onDelete={handleDeleteAnswer}
        />
      </div>

      <div>
        <button
          className="btn--answers"
          style={{ opacity: isOpenAnswer ? 0.5 : 1 }}
          onClick={() => setIsOpenAnswer(prev => !prev)}
        >
          Responder
        </button>

        {(authorityLevel === 'admin' || comment.user?.id === session.user.id) && !selectedProject.approved ? (
          <div
            className="post-comment__actions"
            onClick={() => onDelete(comment.id)}
          >
            <div
              className="post-comment__action"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <div
                className="post-comment__action-icon"
                style={{ margin: 0 }}
              >
                <img
                  alt=""
                  src={trashIcon}
                />
              </div>
            </div>
          </div>
        ) : (
          <div style={{ width: 14, height: 1 }} />
        )}
      </div>
    </div>
  );
}
