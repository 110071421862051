import { Link } from '@reach/router';

export default function ShortcutCard({ source, type, title, link, text, linkText }) {
  return (
    <div
      className="guide-card-dashboard u-mb-3"
      style={{ padding: 34 }}
    >
      <div className="guide-card-dashboard__left">
        <h3>{type}</h3>
        <h1>{title}</h1>
        <p>{text}</p>
        <a
          className="btn btn--link u-p-0"
          href={link}
        >
          {linkText}
        </a>
      </div>
      <div className="guide-card-dashboard__right">
        <img
          style={{ width: '170px' }}
          src={source}
        />
      </div>
    </div>
  );
}
