import React, { useState } from 'react';
import { ReactComponent as Star } from 'app/images/icons/fi-star.svg';

export default function StarRating(props) {
  const { answerRate, liveClassId, questionId, answerId, setAnswers, onSubmit } = props;

  const [rate, setRate] = useState(answerRate);
  const [hover, setHover] = useState(0);

  const stars = [...Array(5)];

  function handleRate(currentRate) {
    setRate(currentRate);
    onSubmit(
      answerId
        ? {
            rating: currentRate,
            answerId
          }
        : {
            'live-classroom-id': Number(liveClassId),
            'live-classroom-question-id': Number(questionId),
            rating: currentRate
          }
    );
  }

  return (
    <div className="star-rating">
      {stars.map((_, index) => {
        const current = index + 1;

        return (
          <button
            key={index}
            type="button"
            onClick={() => handleRate(current)}
            onMouseEnter={() => setHover(current)}
            onMouseLeave={() => setHover(rate)}
            className={(hover || rate) >= current ? 'star-rating__btn on' : 'star-rating__btn off'}
          >
            <Star className="star-rating__star" />
          </button>
        );
      })}
    </div>
  );
}
