import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import MainSidebar from 'app/components/Sidebar';
import { Link, navigate } from '@reach/router';
import { t } from 'i18next';

export default function ModuleSidebar(props) {
  const { steps, currentStep, setCurrentStep, edit } = props;

  return (
    <MainSidebar hasBrand={false}>
      <nav className="notebook-sidebar__nav">
        <button
          onClick={() => navigate(-1)} //validar
          className="filter-bar__back"
        >
          <span className="card__icon-wrapper">
            <FiChevronLeft className="card__icon" />
          </span>
          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{t('textsCommon.modules')}</span>
          </span>
        </button>

        <ul className="step-by-step-nav">
          {steps.map((step, i) => {
            const index = i + 1;

            return (
              <li
                className={`step-by-step-nav__item ${index === currentStep ? 'active' : ''} ${currentStep > index ? 'step-by-step-nav__item--completed' : ''}`}
                onClick={() => setCurrentStep(index)}
                key={i}
              >
                <Link
                  to={edit ? `/lms/criar/modulo/${step.route}` : ''}
                  className="step-by-step-nav__link"
                  style={{ cursor: edit ? 'pointer' : 'default' }}
                >
                  {step.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </MainSidebar>
  );
}
