import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import MultiSelect from 'app/components/MultiSelect';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export default function LessonForm(props) {
  const { t } = useTranslation();
  const { setShowModal, disciplineId, onSuccess, selectedLesson } = props;
  const client = useClient();
  const [fileType, setFileType] = useState('Arquivo');
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);
  const [taxonomies, setTaxonomies] = useState([]);

  const createOrUpdateLearningSystemItems = async values => {
    setShowModal(false);
    const toBase64 = file =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

    const parsedForm = {
      learning_system_content_id: disciplineId,
      body: values.lessonName,
      order: values.lessonOrder,
      video: undefined,
      url: undefined,
      taxonomy_ids: values.selectedTaxonomies.map(item => item.id),
      unrequired: values.unrequired
    };

    if (values.files.length > 0) {
      parsedForm.video = await toBase64(values.files[0]);
    }

    if (values.url && values.url !== '') {
      parsedForm.url = values.url;
    }

    if (selectedLesson) {
      const { error } = await client.mutate(['learning_system_items', selectedLesson.id], parsedForm);

      if (error) {
        toast.error(t('toast.errorEditLesson'));
      } else {
        toast.success(t('toast.successEditLesson'));
        onSuccess();
      }
    } else {
      const { error } = await client.mutate('learning_system_items', parsedForm);

      if (error) {
        toast.error(t('toast.errorCreateLesson'));
      } else {
        toast.success(t('toast.successCreateLesson'));
        onSuccess();
      }
    }
  };

  const form = selectedLesson
    ? useFormik({
        initialValues: {
          lessonName: selectedLesson.body,
          lessonOrder: selectedLesson.order,
          files: [],
          url: selectedLesson.video?.url || selectedLesson.url,
          selectedTaxonomies: selectedLesson.taxonomies,
          unrequired: selectedLesson.unrequired
        },
        onSubmit: createOrUpdateLearningSystemItems
      })
    : useFormik({
        initialValues: {
          lessonName: '',
          lessonOrder: null,
          files: [],
          url: null,
          selectedTaxonomies: [],
          unrequired: false
        },
        onSubmit: createOrUpdateLearningSystemItems
      });

  const fetchTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetTags'));
    }
  };

  useEffect(() => {
    fetchTaxonomies();
  }, []);

  useEffect(() => {
    if (selectedLesson) {
      setFileType('URL');
    }
  }, [selectedLesson]);

  return (
    <form
      method="post"
      className="form"
      onSubmit={form.handleSubmit}
    >
      <h2 className="form__title">{selectedLesson ? 'Editar aula' : 'Nova aula'}</h2>

      <div className="form__row">
        <div className="form__check form__switch">
          <input
            className="form__check-input"
            id="unrequired"
            type="checkbox"
            name="unrequired"
            checked={form.values.unrequired}
            onChange={form.handleChange}
          />
          <label
            className="form__check-label"
            htmlFor="unrequired"
          >
            Aula livre
          </label>
        </div>
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="lessonName"
        >
          {t('textsCommon.lessonName')}
        </label>
        <input
          className="form__control"
          id="lessonName"
          name="lessonName"
          type="text"
          placeholder="Digite o nome da aula"
          onChange={form.handleChange}
          defaultValue={form.values.lessonName}
        />
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="disciplineName"
        >
          Ordem da disciplina
        </label>
        <input
          className="form__control"
          id="lessonOrder"
          name="lessonOrder"
          type="number"
          min={0}
          placeholder="Ordem da disciplina"
          onChange={form.handleChange}
          value={form.values.lessonOrder}
        />
      </div>

      <fieldset className="form__row">
        <legend className="form__label">Upload do vídeo</legend>

        <div
          className="btn-group u-mb-2"
          role="group"
          aria-label="Insira o vídeo"
        >
          {['Arquivo', 'URL'].map((alternative, index) => (
            <React.Fragment key={index}>
              <input
                type="radio"
                name="fileType"
                autoComplete="off"
                id={`fileType-${index}`}
                checked={alternative === fileType}
                onChange={() => {
                  if (alternative === 'Arquivo') {
                    form.setFieldValue('url', null);
                  } else {
                    form.setFieldValue('files', []);
                  }

                  setFileType(alternative);
                }}
              />
              <label
                className="btn btn--outline"
                htmlFor={`fileType-${index}`}
              >
                {alternative}
              </label>
            </React.Fragment>
          ))}
        </div>

        {fileType === 'Arquivo' && (
          <label className="form__custom-file-input">
            <input
              type="file"
              name="file"
              id="file"
              onChange={e => form.setFieldValue('files', e.target.files)}
            />
            <span className="form__control">{form.values.files.length > 0 ? form.values.files[0].name : t('activity.placeholderUploadFile')}</span>
          </label>
        )}

        {fileType === 'URL' && (
          <input
            className="form__control"
            id="url"
            name="url"
            type="url"
            placeholder={t('activity.placeholderSentUrl')}
            value={form.values.url}
            onChange={form.handleChange}
          />
        )}
      </fieldset>

      <fieldset className="form__row">
        <legend className="form__label">{t('tasks.mattersRelated')}</legend>

        <MultiSelect
          selectedTaxonomies={form?.values?.selectedTaxonomies}
          setFieldValue={form.setFieldValue}
          openModal={() => setIsOpenModalTaxonomies(true)}
          placeholder={t('tasks.mattersAdd')}
        />
      </fieldset>

      <button
        className="btn btn--primary btn--wide"
        type="submit"
      >
        {selectedLesson ? 'Editar aula' : 'Criar aula'}
      </button>

      {taxonomies.length > 0 && isOpenModalTaxonomies && (
        <ModalTaxonomies
          taxonomies={taxonomies}
          selectedTaxonomies={form?.values?.selectedTaxonomies}
          setFieldValue={form.setFieldValue}
          closeModal={() => setIsOpenModalTaxonomies(false)}
        />
      )}
    </form>
  );
}
