import { useNote } from 'app/hooks/useNote';
import React from 'react';
import { useTranslation } from 'react-i18next';

function TabNavBar() {
  const { t } = useTranslation();
  const { activeTab, setActiveTab } = useNote();
  return (
    <div className="tab u-mb-0">
      <div
        className={`tab__link ${activeTab === 'anotacoes-privadas' ? 'active' : ''}`}
        onClick={() => setActiveTab('anotacoes-privadas')}
      >
        {t('header.myNotes')}
      </div>
      <div
        className={`tab__link ${activeTab === 'anotacoes-de-turma' ? 'active' : ''}`}
        onClick={() => setActiveTab('anotacoes-de-turma')}
      >
        {t('header.classNotes')}
      </div>
      <div
        className={`tab__link ${activeTab === 'anotacoes-importantes' ? 'active' : ''}`}
        onClick={() => setActiveTab('anotacoes-importantes')}
      >
        {t('header.importantNotes')}
      </div>
    </div>
  );
}

export default TabNavBar;
