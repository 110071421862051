import React from 'react';

export function Img(props) {
  const {points} = props
  
  return (
    <svg
      width={174}
      height={133}
      viewBox="0 0 174 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_549_150)">
        <rect
          x={16.5405}
          y={54.6636}
          width={points?.toString().length > 3 ? 150 :135.6}
          height={57.6}
          rx={12}
          transform="rotate(-6.255 16.54 54.664)"
          fill="#fff"
        />
        <rect
          x={22.4966}
          y={59.4432}
          width={points?.toString().length > 3 ? 139 : 124.8}
          height={46.8}
          rx={10.2}
          transform="rotate(-6.255 22.497 59.443)"
          fill="#5DC9DD"
          stroke="#46B0C4"
          strokeWidth={3.6}
        />
        <path
          d="M87.705 54.107l50.1-5.492a7.2 7.2 0 017.941 6.373l3.138 28.629a7.2 7.2 0 01-6.373 7.941l-50.1 5.491-4.706-42.942z"
          fill="#73D9EB"
        />
        <text
          transform="rotate(-6.255 630.659 -306.925)"
          fill="#fff"
          style={{
            whiteSpace: 'pre'
          }}
          fontFamily="Nunito"
          fontSize={24}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan
            x={0.296875}
            y={22.396}
          >
            {points || 0} PTS
          </tspan>
        </text>
        <g filter="url(#filter1_d_549_150)">
          <g filter="url(#filter2_d_549_150)">
            <path
              d="M85.004 12.076l-16.955 9.955a4 4 0 00-1.974 3.45v19.818a4 4 0 001.974 3.45l16.955 9.954a4 4 0 004.043.005l17.046-9.961a4 4 0 001.982-3.454V25.486a4 4 0 00-1.982-3.454l-17.046-9.96a4 4 0 00-4.043.004z"
              fill="#fff"
            />
            <path
              d="M85.004 12.076l-16.955 9.955a4 4 0 00-1.974 3.45v19.818a4 4 0 001.974 3.45l16.955 9.954a4 4 0 004.043.005l17.046-9.961a4 4 0 001.982-3.454V25.486a4 4 0 00-1.982-3.454l-17.046-9.96a4 4 0 00-4.043.004z"
              stroke="#ECE9F8"
            />
          </g>
          <path
            d="M85.349 14.904l-14.51 8.583a4 4 0 00-1.964 3.443v17.147a4 4 0 001.963 3.443l14.51 8.582a4 4 0 004.066.004l14.59-8.588a4 4 0 001.971-3.447V26.935a4 4 0 00-1.971-3.447L89.414 14.9a4 4 0 00-4.065.004z"
            fill="#1CA388"
          />
          <path
            d="M84.452 18.467l-9.72 5.788a6 6 0 00-2.93 5.155v11.738a6 6 0 002.93 5.155l9.72 5.789a6 6 0 006.129.006l9.78-5.796a6 6 0 002.941-5.162V29.419a6 6 0 00-2.941-5.162l-9.78-5.796a6 6 0 00-6.13.006z"
            fill="#6FCF97"
          />
          <mask
            id="a"
            style={{
              maskType: 'alpha'
            }}
            maskUnits="userSpaceOnUse"
            x={71}
            y={18}
            width={33}
            height={34}
          >
            <path
              d="M81.39 20.291l-3.728 2.22a12 12 0 00-5.86 10.31v4.917a12 12 0 005.86 10.31l3.727 2.22a12 12 0 0012.258.013l3.773-2.236a12 12 0 005.882-10.324v-4.884a12 12 0 00-5.882-10.323l-3.773-2.236a12 12 0 00-12.258.013z"
              fill="#FFC100"
            />
          </mask>
          <g mask="url(#a)">
            <rect
              x={71.802}
              y={17.6025}
              width={15.2727}
              height={35.4817}
              rx={4}
              fill="#6FCF97"
            />
          </g>
          <path
            d="M94.075 29.02l-9.625 9.065-4.375-4.12"
            stroke="#fff"
            strokeWidth={3}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <path
        d="M112.54 9.282c.281-.96 1.639-.96 1.92 0l1.036 3.543c.096.327.352.583.679.68l3.543 1.035c.959.28.959 1.64 0 1.92l-3.543 1.036a1.001 1.001 0 00-.679.679l-1.036 3.543c-.281.96-1.639.96-1.92 0l-1.036-3.543a1.001 1.001 0 00-.679-.68l-3.543-1.035c-.959-.28-.959-1.64 0-1.92l3.543-1.036c.327-.096.583-.352.679-.679l1.036-3.543zM121.54 24.282c.281-.96 1.639-.96 1.92 0l2.167 7.412a1 1 0 00.679.679l7.412 2.167c.959.28.959 1.64 0 1.92l-7.412 2.167a1 1 0 00-.679.68l-2.167 7.411c-.281.96-1.639.96-1.92 0l-2.167-7.412a1 1 0 00-.679-.679l-7.412-2.167c-.959-.28-.959-1.64 0-1.92l7.412-2.167a1 1 0 00.679-.68l2.167-7.411zM135.54 5.282c.281-.96 1.639-.96 1.92 0l1.941 6.638c.096.327.352.583.679.679l6.638 1.941c.959.28.959 1.64 0 1.92L140.08 18.4a1.001 1.001 0 00-.679.68l-1.941 6.637c-.281.96-1.639.96-1.92 0l-1.941-6.638a1.001 1.001 0 00-.679-.679l-6.638-1.941c-.959-.28-.959-1.64 0-1.92l6.638-1.941c.327-.096.583-.352.679-.68l1.941-6.637z"
        fill="#FFC800"
      />
      <defs>
        <filter
          id="filter0_d_549_150"
          x={0.940527}
          y={0.0897095}
          width={172.269}
          height={132.231}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius={3.6}
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_549_150"
          />
          <feOffset dy={4.8} />
          <feGaussianBlur stdDeviation={6} />
          <feColorMatrix values="0 0 0 0 0.364706 0 0 0 0 0.415686 0 0 0 0 0.52549 0 0 0 0.2 0" />
          <feBlend
            mode="multiply"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_549_150"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_549_150"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_549_150"
          x={52.5747}
          y={1.88971}
          width={69}
          height={75}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius={3}
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_549_150"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.364706 0 0 0 0 0.415686 0 0 0 0 0.52549 0 0 0 0.2 0" />
          <feBlend
            mode="multiply"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_549_150"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_549_150"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_549_150"
          x={55.5747}
          y={5.02541}
          width={63}
          height={68.7286}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={5} />
          <feColorMatrix values="0 0 0 0 0.364706 0 0 0 0 0.415686 0 0 0 0 0.52549 0 0 0 0.05 0" />
          <feBlend
            mode="multiply"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_549_150"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_549_150"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
