import React, { useState } from 'react';
import { FiChevronLeft, FiCalendar } from 'react-icons/fi';
import { Link } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import moment from 'moment';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';

export default function ViewTask(props) {
  const { t } = useTranslation();
  // const [isDone, setIsDone] = useState();
  const [taskData, setTaskData] = useState();
  const [updateTaskData, setUpdateTaskData] = useState();
  const client = useClient();
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTaskData();
  }, []);

  const getTaskData = async () => {
    const requestType = props.taskType === 'tarefa' ? 'slots' : 'master_slots';
    setLoading(true);
    const { data, error } = await client.fetch(`${requestType}/${props.taskId}`);
    if (error) {
      setNotFound(true);
      setLoading(false);
    } else {
      setTaskData(data);
      setLoading(false);
    }
  };

  const updateTask = async accomplished => {
    try {
      const response = await client.mutate(['slots', taskData.id], {
        accomplished: accomplished
      });
      setTaskData(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const authorityLevel = getAuthorityLevel();
  return (
    <>
      {loading && (
        <div className="main-content main-content--block">
          <Loader />
        </div>
      )}
      {!notFound && !loading && (
        <div className="main-content main-content--block">
          <div className="filter-bar">
            <Link
              to={authorityLevel !== 'student' ? '/gestao-de-tarefa/curso' : '/minhas-tarefas'}
              className="filter-bar__back"
            >
              <FiChevronLeft />
              <span className="filter-bar__back-container">
                <span className="filter-bar__title">Tarefas</span>
              </span>
            </Link>
          </div>

          <article className="page-detail">
            <header className="page-detail__header">
              <figure className="page-detail__bg-wrapper">
                <img
                  className="page-detail__bg"
                  src="https://images.pexels.com/photos/3807755/pexels-photo-3807755.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt="imagem de fundo"
                />
              </figure>
            </header>

            <div className="page-detail__body">
              <div className="page">
                <div className="page__col">
                  <div className="page-detail__icon-wrapper">
                    <div className="page-detail__icon">
                      <FiCalendar />
                    </div>
                  </div>

                  <span className="calendar-task-modal__category calendar-task-modal__category--train">{taskData?.['t-activity']}</span>
                  <h1 className="page-detail__title">{taskData?.title}</h1>
                  <p className="page-detail__subtitle">Tarefa criada em {moment(taskData?.date).format(t('datesForm.formatDate'))} por Camila Himura</p>
                  <span className="expanded-calendar__tag expanded-calendar__tag--course">Direito</span>

                  <div className="page-detail__description">
                    <h2 className="page-detail__description-title">Descrição da tarefa</h2>

                    <p>{taskData?.body}</p>
                  </div>
                </div>
                <div className="page__aside">
                  <label className="calendar-task-modal__header">
                    <input
                      onChange={e => updateTask(e.target.checked)}
                      type="checkbox"
                      name="checkbox"
                      id="checkbox"
                      checked={taskData?.accomplished}
                      className="calendar-task-modal__check-input"
                    />
                    <span className="calendar-task-modal__matter">Marcar como concluída</span>
                  </label>
                </div>
              </div>
            </div>
          </article>
        </div>
      )}
      {notFound && !loading && (
        <div className="main-content main-content--block">
          <EmptyState
            type="content"
            title={t('emptyState.contentNotAvaliable')}
            text={t('emptyState.contactATeacherText')}
          />
        </div>
      )}
    </>
  );
}
