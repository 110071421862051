import React, { useEffect, useState } from 'react';
import { FiPlus, FiMoreHorizontal } from 'react-icons/fi';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import EmptyState from 'app/components/EmptyState';
import Modal from 'app/components/Modal';
import Dropdown from 'app/components/Dropdown';
import dragAndDrop from 'app/images/icons/drag-and-drop.svg';
import { useClient } from 'jsonapi-react';
import Swal from 'sweetalert2';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Loader from 'app/components/loader';
import { useParams } from '@reach/router';
import { useTranslation } from 'react-i18next';

export default function DisciplinesList(props) {
  const { t } = useTranslation();
  const { disciplines, setDisciplines, selectedDiscipline, setSelectedDiscipline, moduleId, onSuccess, deleteDiscipline, loading, selectedModule, manageModules } = props;

  const client = useClient();
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editedDiscipline, setEditedDiscipline] = useState('');

  const handleSubmit = async () => {
    const { error, data } = await client.mutate('/learning_system_contents', {
      body: disciplineForm.values.body,
      learningSystemId: selectedModule.id
    });
    if (error) {
      toast.error('Falha ao criar disciplina');
    } else {
      toast.success(t('toast.successCreateDiscipline'));
      onSuccess();
      disciplineForm.setValues({
        body: '',
        learningSystemId: selectedModule.id
      });
    }
  };

  const disciplineForm = useFormik({
    initialValues: {
      body: ''
    },
    onSubmit: handleSubmit
  });

  const handleDelete = id => {
    Swal.fire({
      title: t('projects.warningDeleteMatter'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteDiscipline(id);
      }
    });
  };

  const disciplinesFiltered = disciplines.filter(discipline => {
    if (searchTerm == '') {
      return discipline;
    }
    if (discipline.body != null && discipline.body.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
      return discipline;
    }
  });

  const handleEdit = discipline => {
    setSelectedDiscipline(discipline);
    setEditedDiscipline(discipline.body);
    setEditShow(true);
  };

  const editDisciplineName = async (id, newName) => {
    const { data, error } = await client.mutate(['learning_system_contents', id], {
      body: newName
    });
    if (error) {
      toast.error('Erro ao editar o nome da Disciplina');
    } else {
      toast.success(t('toast.successRenameDiscipline'));
      const newDisciplines = disciplines.map(discipline => (discipline.id === data.id ? data : discipline));
      setSelectedDiscipline(data);
      setDisciplines(newDisciplines);
    }
  };

  return (
    <div className="new-questions__sidebar">
      <div className="form__row">
        <label
          htmlFor="disciplineSearch"
          className="form__label"
        >
          Disciplinas
        </label>

        <input
          aria-label={t('textsCommon.research')}
          className="form__control form__control--search-with-icon"
          placeholder={t('textsCommon.research')}
          type="search"
          name="disciplineSearch"
          id="disciplineSearch"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>

      <button
        className="btn btn--outline-dashed btn--small btn--full u-mb-3"
        onClick={() => setShowModal(true)}
      >
        <FiPlus className="btn__icon" /> {t('button.newDiscipline')}
      </button>

      {manageModules && (
        <EmptyState
          type="content"
          title="Selecione um módulo ao lado."
        />
      )}

      {loading && !manageModules && <Loader />}

      {!loading && selectedDiscipline && disciplines.length === 0 && (
        <EmptyState
          type="content"
          title="Crie disciplinas com o botão acima."
        />
      )}

      {!loading && !selectedModule && disciplines.length === 0 && (
        <EmptyState
          type="discipline"
          title="Selecione um módulo ao lado."
        />
      )}

      {!loading && (
        <Droppable droppableId="droppable">
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {disciplines.length > 0 &&
                disciplinesFiltered.map((discipline, i) => (
                  <Draggable
                    key={i}
                    draggableId={discipline.id.toString()}
                    index={i}
                  >
                    {provided => (
                      <div
                        onClick={() => setSelectedDiscipline(discipline)}
                        className={discipline?.id === selectedDiscipline?.id ? 'taxonomies__item active' : 'taxonomies__item'}
                        key={discipline.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <img
                          className="taxonomies__icon"
                          src={dragAndDrop}
                          alt=""
                        />
                        <span className="taxonomies__text">{discipline?.body}</span>
                        <Dropdown>
                          <Dropdown.Toggle>
                            <FiMoreHorizontal />
                          </Dropdown.Toggle>

                          <Dropdown.Content>
                            <Dropdown.Item onClick={() => handleEdit(discipline)}>{t('button.edit')}</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleDelete(discipline.id)}>{t('button.delete')}</Dropdown.Item>
                          </Dropdown.Content>
                        </Dropdown>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <form
          className="form"
          onSubmit={e => {
            e.preventDefault();
            disciplineForm.handleSubmit();
            setShowModal(false);
          }}
        >
          <h2 className="modal__simple-title">{t('button.newDiscipline')}</h2>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="disciplineName"
            >
              {t('textsCommon.disciplineName')}
            </label>
            <input
              className="form__control"
              id="disciplineName"
              name="disciplineName"
              type="text"
              placeholder={t('textsCommon.writeHere')}
              value={disciplineForm.values.body}
              onChange={e => disciplineForm.setFieldValue('body', e.target.value)}
            />
          </div>

          <button
            className="btn btn--primary btn--wide"
            type="submit"
          >
            {t('button.createDiscipline')}
          </button>
        </form>
      </Modal>

      <Modal
        show={editShow}
        onClose={() => setEditShow(false)}
      >
        <form
          method="post"
          className="form"
          onSubmit={e => {
            e.preventDefault();
            if (editedDiscipline) {
              editDisciplineName(selectedDiscipline.id, editedDiscipline);
              setEditShow(false);
            }
          }}
        >
          <h2 className="form__title">Editar o nome da Disciplina</h2>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="disciplineName"
            >
              Novo nome da Disciplina
            </label>
            <input
              className="form__control"
              id="disciplineName"
              name="disciplineName"
              type="text"
              placeholder="Digite o nome da Disciplina"
              value={editedDiscipline}
              onChange={e => setEditedDiscipline(e.target.value)}
            />
          </div>

          <button
            className="btn btn--primary"
            type="submit"
          >
            Editar e continuar
          </button>
        </form>
      </Modal>
    </div>
  );
}
