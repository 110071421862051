import React from 'react';
import styles from './AnswerKey.module.scss';
import AnswerKeyHeader from './AnswerKeyHeader';
import AccordionItem from './AccordionItem';

export default function AnswerKey() {
  return (
    <div className={styles.wrapper}>
      <h3 className="report-widget__title report-widget__title--sm">Gabarito do teste de nivelamento</h3>

      <div>
        <AnswerKeyHeader />

        <div className="accordion">
          {[...Array(14)].map((item, index) => (
            <AccordionItem key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
