import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FiClipboard, FiChevronLeft } from 'react-icons/fi';
import Modal from 'app/components/Modal';
import TabNavBar from './TabNavBar';
import { useNote } from 'app/hooks/useNote';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSession } from 'app/hooks/useSession';
import PrivateNotes from './PrivateNotes';
import ClassroomNotes from './ClassroomNotes';
import ImportantNotes from './ImportantNotes';
import getPermission from 'app/utils/getPermission';

function Notes() {
  const { t } = useTranslation();
  const { session } = useSession();
  const { createNote, updateNote, activeNote, setActiveNote, getNotes, setNotes, notes, nextBody, activeTab, getNewClassroomNotes, newNotesClasses, showModal, showNotes, setShowModal, setShowNotes, notesModalOpen, setNotesModalOpen } = useNote();

  const [intervalTimer, setIntervalTimer] = useState();
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewClassroom, setViewClassroom] = useState(false);
  const [classroomData, setClassroomData] = useState('');
  const wrapper = `notes-wrapper ${showNotes ? 'show' : ''}`;
  const btnTitle = showNotes ? 'Fechar anotações' : 'Abrir anotações';

  const closeOnEscapeKeyDown = event => event.key === 'Escape' && showNotes && setShowNotes(false);

  useEffect(() => {
    if (showNotes) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';

    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, [showNotes]);

  const checkBodyChange = () => {
    updateNote(activeNote, nextBody);
  };

  useEffect(() => {
    if (activeNote && activeNote.body !== nextBody && activeNote.body !== '<p></p>') {
      clearInterval(intervalTimer);
      const newInterval = setTimeout(() => checkBodyChange(), 1000);
      setIntervalTimer(newInterval);
    }

    // getNotes(classroomData);
  }, [nextBody]);

  const form = useFormik({
    initialValues: {
      title: activeNote?.title || '',
      body: activeNote?.body || '',
      'classroom-id': ''
    },
    onSubmit: values => {
      createNote(values);
      setShowModal(false);
      if (viewClassroom) {
        form.setFieldValue('title', '');
      } else {
        form.resetForm();
      }
    }
  });

  const handleOpenNotesModal = () => {
    setShowNotes(!showNotes);
    setNotesModalOpen(true);
  };

  useEffect(() => {
    if (notesModalOpen) {
      getNotes(classroomData);
      setActiveNote('');
      getNewClassroomNotes();
    }
  }, [activeTab, classroomData, notesModalOpen]);

  useEffect(() => {
    const filterNotes = notes => {
      let filteredNotes = notes;

      if (searchTerm !== '') {
        filteredNotes = filteredNotes.filter(a => a.title != null && a.title.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
      }

      return filteredNotes;
    };

    const newFilteredNotes = filterNotes(notes);
    setFilteredNotes(newFilteredNotes);
  }, [searchTerm, notes]);

  useEffect(() => {
    form.setFieldValue('classroom-id', classroomData.id);
  }, [classroomData]);

  const handleNewNote = () => {
    setShowModal(true);
  };

  const hasNotes = notes.length > 0;

  const markedNotes = notes.filter(note => note.bookmarked === true);

  useEffect(() => {
    if (!viewClassroom) {
      form.setFieldValue('classroom-id', '');
    }
  }, [viewClassroom]);

  return ReactDOM.createPortal(
    <>
      <div className={wrapper}>
        <button
          className="notes-toggle"
          onClick={handleOpenNotesModal}
          title={btnTitle}
        >
          <FiClipboard />
          <span className="notes-toggle__text">{t('header.notes')}</span>
        </button>

        <div className="notes">
          <div className="notes__header">
            {!viewClassroom && <TabNavBar />}

            {viewClassroom && (
              <button
                className="filter-bar__back"
                onClick={() => {
                  setViewClassroom(false);
                }}
              >
                <span className="card__icon-wrapper">
                  <FiChevronLeft className="card__icon" />
                </span>
                <span className="filter-bar__back-container">
                  <span className="filter-bar__title">{classroomData.title}</span>
                </span>
              </button>
            )}
            {!viewClassroom && activeTab !== 'anotacoes-de-turma' && getPermission('Criar anotações', 'Anotações') && (
              <button
                type="button"
                className="btn btn--wide btn--primary"
                onClick={handleNewNote}
              >
                Nova anotação
              </button>
            )}
            {viewClassroom && getPermission('Criar anotações', 'Anotações') && (
              <button
                type="button"
                className="btn btn--wide btn--primary"
                onClick={handleNewNote}
              >
                Nova anotação pública para turma
              </button>
            )}
          </div>
          {activeTab === 'anotacoes-privadas' && (
            <PrivateNotes
              hasNotes={hasNotes}
              handleNewNote={handleNewNote}
              setSearchTerm={setSearchTerm}
              notes={filteredNotes}
              setActiveNote={setActiveNote}
              session={session}
              activeNote={activeNote}
            />
          )}
          {activeTab === 'anotacoes-de-turma' && (
            <ClassroomNotes
              setActiveNote={setActiveNote}
              activeNote={activeNote}
              viewClassroom={viewClassroom}
              classrooms={newNotesClasses}
              setViewClassroom={setViewClassroom}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setClassroomData={setClassroomData}
              setFilteredNotes={setFilteredNotes}
              setNotes={setNotes}
              notes={notes}
            />
          )}
          {activeTab === 'anotacoes-importantes' && (
            <ImportantNotes
              activeNote={activeNote}
              setActiveNote={setActiveNote}
              // setImportantActiveNote={setActiveNote}
              // importantActiveNote={activeNote}
              setSearchTerm={setSearchTerm}
              searchTerm={searchTerm}
              notes={markedNotes}
            />
          )}
        </div>
      </div>

      {showNotes && (
        <div
          className="notes-backdrop"
          onClick={() => setShowNotes(!showNotes)}
        />
      )}

      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <form
          method="post"
          className="form"
          onSubmit={form.handleSubmit}
        >
          <h2 className="form__title">{t('header.createNewNote')}</h2>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="title"
            >
              {t('header.nameNote')}
            </label>
            <input
              className="form__control"
              id="title"
              name="title"
              type="text"
              placeholder={t('header.notesPlaceholder')}
              value={form.values.title}
              onChange={form.handleChange}
            />
          </div>

          <button
            className="btn btn--primary"
            type="submit"
          >
            {t('button.saveContinue')}
          </button>
        </form>
      </Modal>
    </>,
    document.getElementById('modal_root')
  );
}

export default Notes;
