import React from 'react';
import HelpForm from './HelpForm';
import { useTranslation } from 'react-i18next';

export default function HelpFooter() {
  const { t } = useTranslation();
  return (
    <footer
      className="help-footer"
      id="contato"
    >
      <div className="help-footer__inner">
        <HelpForm />
      </div>
    </footer>
  );
}
