import React, { useState } from 'react';
import { FiChevronLeft, FiMoreHorizontal } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import Modal from 'app/components/Modal';
import ParticipantsForm from './ParticipantsForm';
import Dropdown from 'app/components/Dropdown';
import { ReactComponent as UserAvatar } from 'app/images/user-avatar.svg';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from '@reach/router';
import getPermission from 'app/utils/getPermission';
import UserInfo from 'app/components/UserInfo';
import Loader from 'app/components/loader';
import moment from 'moment';
import { t } from 'i18next';

export function ManageParticipants(props) {
  const { classroomId } = props;
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [classroom, setClassroom] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [allProfiles, setAllProfiles] = useState([]);

  useEffect(() => {
    getClassroom();
    getAllProfiles();
  }, []);

  useEffect(() => {
    if (allProfiles.length > 0) {
      getAllUsers();
    }
  }, [allProfiles]);

  const getClassroom = async () => {
    const { data, error } = await client.fetch(`classrooms/${classroomId}`);
    if (error) {
      toast.error('Erro ao buscar informações da turma.');
    } else {
      setClassroom(data);
    }
  };

  const getAllProfiles = async () => {
    const { data, error } = await client.fetch('profiles');
    if (error) {
      toast.error(t('toast.errorGetParticipants'));
    } else {
      setAllProfiles(
        data
          .map(item => item.name)
          .reduce((item, i) => {
            return `${item},${i}`;
          })
      );
    }
  };

  const getAllUsers = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`users?filter[profile]=${allProfiles}`);
    if (error) {
      toast.error(t('toast.errorGetParticipants'));
    } else {
      setAllUsers(data);
      setLoading(false);
    }
  };

  const addParticipantToClassroom = async id => {
    const { data, error } = await client.mutate(['classrooms', classroom.id], {
      'user-ids': [...classroom?.['user-ids'], id]
    });

    if (error) {
      toast.error('Erro ao adicionar participante.');
    } else {
      setClassroom(data);
    }
  };

  const deleteParticipantFromClassroom = async id => {
    const { data, error } = await client.mutate(['classrooms', classroom.id], {
      'user-ids': classroom['user-ids'].filter(i => i !== id)
    });
    if (error) {
      toast.error(t('toast.errorDeleteParticipants'));
    } else {
      setClassroom(data);
      toast.success(t('toast.successRemoveParticipant'));
    }
  };

  const classroomParticipants = allUsers?.filter(participant => classroom['user-ids'].includes(participant.id));

  const classroomStudents = classroomParticipants?.filter(student => student['profile-names'].includes('Aluno'));

  const classroomManagers = classroomParticipants?.filter(item => item['profile-names']?.[0] !== 'Aluno');

  return (
    <>
      <main className="main-content main-content--block">
        <div className="filter-bar">
          <div className="filter-bar__back">
            <div
              className="card__icon-wrapper"
              onClick={() => history.back()}
            >
              <FiChevronLeft />
            </div>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{classroom?.title}</span>
            </span>
          </div>

          {getPermission('Gerenciar turmas', 'Turmas') && (
            <button
              className="btn btn--wide btn--primary"
              onClick={() => setShowModal(true)}
            >
              {t('courses.addParticpants')}
            </button>
          )}
        </div>

        {loading && <Loader />}

        {!loading && !classroomParticipants.length > 0 && (
          <EmptyState
            text={
              <span>
                <strong>Adicione</strong> à turma participantes que estão na plataforma.
              </span>
            }
          />
        )}

        {!loading && classroomParticipants.length > 0 && (
          <div className="fadeIn">
            <section className="archive-section">
              <h3 className="archive-section__title u-mb-0">{t('courses.managers')}</h3>

              <table
                className="round-dg round-dg--light"
                style={{ borderSpacing: '0 10px' }}
              >
                <tbody className="round-dg__body">
                  {classroomManagers?.map((user, i) => {
                    const updatedAt = moment(user['updated-at']);
                    return (
                      <tr className="round-dg__row">
                        <th className="round-dg__cell-header round-dg__cell-header--row">
                          <div className="round-dg__user">
                            <UserInfo user={user} />
                          </div>
                        </th>
                        <td className="round-dg__cell">{user.email}</td>
                        <td className="round-dg__cell">
                          {t('journey.thLastAccess')}: {updatedAt.format('DD/MM/YYYY HH:mm')}
                        </td>
                        <td
                          className="round-dg__cell"
                          style={{ width: '66px' }}
                        >
                          {getPermission('Gerenciar turmas', 'Turmas') && (
                            <Dropdown>
                              <Dropdown.Toggle>
                                <FiMoreHorizontal />
                              </Dropdown.Toggle>

                              <Dropdown.Content>
                                <Dropdown.Item
                                  onClick={() => {
                                    Swal.fire({
                                      title: t('projects.warningDeleteTeacher'),
                                      text: t('projects.warningDeleteCannotBeUndoneText'),
                                      confirmButtonText: t('button.yes'),
                                      showDenyButton: true,
                                      denyButtonText: t('button.no'),
                                      showCloseButton: true
                                    }).then(res => {
                                      if (res.isConfirmed) {
                                        deleteParticipantFromClassroom(user.id);
                                      }
                                    });
                                  }}
                                >
                                  {t('button.remove')}
                                </Dropdown.Item>
                              </Dropdown.Content>
                            </Dropdown>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>

            <section className="archive-section">
              <h3 className="archive-section__title u-mb-0">
                {classroomStudents?.length} {t('projects.titleStudents')}
              </h3>

              <table
                className="round-dg round-dg--light"
                style={{ borderSpacing: '0 10px' }}
              >
                <tbody className="round-dg__body">
                  {classroomStudents?.map((user, i) => {
                    const updatedAt = moment(user['updated-at']);
                    return (
                      <tr
                        key={i}
                        className="round-dg__row"
                      >
                        <th className="round-dg__cell-header round-dg__cell-header--row">
                          <div className="round-dg__user">
                            <UserInfo user={user} />
                          </div>
                        </th>

                        <td className="round-dg__cell">{user.email}</td>
                        <td className="round-dg__cell">
                          {t('journey.thLastAccess')}: {updatedAt.format('DD/MM/YYYY HH:mm')}
                        </td>

                        {/* <td className="round-dg__cell">
                        Participante desde 00/00/0000
                      </td> */}

                        <td
                          className="round-dg__cell"
                          style={{ width: '66px' }}
                        >
                          {getPermission('Gerenciar turmas', 'Turmas') && (
                            <Dropdown>
                              <Dropdown.Toggle>
                                <FiMoreHorizontal />
                              </Dropdown.Toggle>

                              <Dropdown.Content>
                                <Dropdown.Item
                                  onClick={() => {
                                    Swal.fire({
                                      title: t('projects.warningDeleteStudent'),
                                      text: t('projects.warningDeleteCannotBeUndoneText'),
                                      confirmButtonText: t('button.yes'),
                                      showDenyButton: true,
                                      denyButtonText: t('button.no'),
                                      showCloseButton: true
                                    }).then(res => {
                                      if (res.isConfirmed) {
                                        deleteParticipantFromClassroom(user.id);
                                      }
                                    });
                                  }}
                                >
                                  {t('button.remove')}
                                </Dropdown.Item>
                              </Dropdown.Content>
                            </Dropdown>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>
          </div>
        )}

        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          <ParticipantsForm
            setShowModal={setShowModal}
            classroom={classroom}
            allUsers={allUsers}
            addParticipantToClassroom={addParticipantToClassroom}
            deleteParticipantFromClassroom={deleteParticipantFromClassroom}
          />
        </Modal>
      </main>
    </>
  );
}
