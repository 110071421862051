import React, { useState, useEffect } from 'react';
import { useSession } from '../hooks/useSession';
import DonateBanner from '../components/donateBanner';
import EmptyState from '../components/EmptyState';
import DonateCard from '../components/DonateCard';
import bannerOab from '../images/open-banner.jpg';
import InputMask from 'react-input-mask';
import lockIcon from '../images/icons/lock-icon.svg';
import successIcon from '../images/icons/success-icon.svg';
import toast from 'react-hot-toast';
import { useMutation } from 'jsonapi-react';
import { useTranslation } from 'react-i18next';

function Donate() {
  const donateOptions = [
    {
      title: 'Pagar 55 reais',
      price: 55.0,
      description: 'Para quem já é aluno.',
    },
    {
      title: 'Pagar 75 reais',
      price: 75.0,
      description: 'Para quem ainda não é aluno.',
    },
  ];

  const { session, setSession } = useSession();
  const [addOpenSubscription] = useMutation('open_subscriptions');
  const [success, setSuccess] = useState(false);
  const [donate, setDonate] = useState(donateOptions[0].price);
  const [taxes, setTaxes] = useState(0);
  const [values, setValues] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    setTaxes(donate > 0 ? 2 : 0);
  }, [donate]);

  useEffect(() => {
    if (session.user.subscribedOpen) {
      setSuccess(true);
      setDonate(-1);
    }
  }, [donate]);

  const states = [
    'AC',
    'AL',
    'AM',
    'AP',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MG',
    'MS',
    'MT',
    'PA',
    'PB',
    'PE',
    'PI',
    'PR',
    'RJ',
    'RN',
    'RO',
    'RR',
    'RS',
    'SC',
    'SE',
    'SP',
    'TO',
  ];

  const changeDonateOption = value => {
    !success && setDonate(value);
  };

  const createSubscription = e => {
    e.preventDefault();
    if (
      values.email &&
      // values.graduated &&
      values.institution &&
      values.email !== '' &&
      // values.graduated !== "" &&
      values.institution !== ''
    ) {
      addOpenSubscription({ ...values, price: donate + taxes })
        .then(response => {
          if (response.error) {
            toast.error(`${response.error?.id} ${response.error?.title}`);
          } else {
            let newObject = session.user;
            newObject.subscribedOpen = true;
            setSession({ user: newObject });
            setSuccess(true);
          }
        })
        .catch(error => {
          toast.error(t("toast.error"));
          console.error(error);
        });
    } else {
      toast.error('Ops, os campos email e tempo de estudo são obrigatórios');
    }
  };

  const handleChange = e => {
    let auxValues = values;
    if (
      [
        'cpf',
        'phone',
        'card_number',
        'card_expiration',
        'card_cvv',
        'zip_code',
      ].includes(e.target.name)
    ) {
      auxValues[e.target.name] = e.target.value.replaceAll('_', '');
    } else {
      auxValues[e.target.name] = e.target.value;
    }

    setValues({ ...auxValues });
    console.clear();
  };

  return (
    <>
      <div className="banner"></div>

      <div className="side-content side-content--transparent">
        <div className="side-content__subtitle">
          Sua inscrição te dá acesso ao melhor simulado para o CACD e materiais
          especiais como gabaritos comentados e aulas de correção exclusivas.
        </div>

        <div className="side-content__subtitle">
          Nessa 2ª edição cobraremos um valor fixo de R$ 55,00 para os alunos, e
          para quem não é aluno R$ 75,00
        </div>

        {donateOptions &&
          donateOptions.map((options, index) => (
            <DonateCard
              key={index}
              index={index}
              options={options}
              donate={donate}
              changeAction={changeDonateOption}
            />
          ))}
      </div>

      <div className="side-content--donate">
        {!success && (
          <>
            <div className="side-content__title">Detalhes da sua inscrição</div>
            <div className="side-content__subtitle">
              Complete o formulário para efetivar a sua doação
            </div>
          </>
        )}

        {success && (
          <div className="side-content--success">
            <img alt="" src={successIcon} />
            <div className="side-content__title--success">
              <h1>Inscrição realizada</h1>
              <h1>com sucesso!</h1>
            </div>
            <div className="side-content__description--success">
              Em breve você poderá acessar{' '}
              <strong>o link do simulado aqui mesmo!</strong>
            </div>
            <div className="side-content__description--sys">Até breve!</div>
          </div>
        )}

        {!success && donate !== undefined && (
          <form
            className="form__donate"
            method="post"
            onSubmit={e => createSubscription(e)}
          >
            <div className="form__row">
              <label htmlFor="email">Email/Tempo de estudo</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email de cadastro"
                onChange={handleChange}
              />
              {/*<select name="graduated" id="graduated" onChange={handleChange}>
                <option>Já é formado?</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>*/}
              <input
                type="text"
                name="institution"
                id="institution"
                placeholder="Estuda há quanto tempo?"
                onChange={handleChange}
              />
            </div>

            {donate > 0 && (
              <>
                <div className="form__row">
                  <label htmlFor="name">Dados pessoais</label>
                  <input
                    type="text"
                    name="card_holder_name"
                    id="card_holder_name"
                    placeholder="Nome impresso no cartão"
                    onChange={handleChange}
                  />
                  <InputMask
                    mask="999.999.999-99"
                    name="cpf"
                    id="cpf"
                    placeholder="CPF"
                    onChange={handleChange}
                  />
                  <InputMask
                    mask="(99) 99999-9999"
                    name="phone"
                    id="phone"
                    placeholder="Telefone"
                    onChange={handleChange}
                  />
                </div>

                <div className="form__donate--card-info">
                  <label htmlFor="num_cartao">Detalhes do cartão</label>
                  <InputMask
                    mask="9999999999999999"
                    name="card_number"
                    id="card_number"
                    placeholder="Número do cartão"
                    onChange={handleChange}
                  />
                  <InputMask
                    mask="99/99"
                    name="card_expiration"
                    id="card_expiration"
                    placeholder="MM/AA"
                    onChange={handleChange}
                  />
                  <InputMask
                    mask="9999"
                    name="card_cvv"
                    id="card_cvv"
                    placeholder="CVV"
                    onChange={handleChange}
                  />
                </div>

                <div className="form__donate--address-info">
                  <label className="endereco">Endereço de cobrança</label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Endereço"
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="neighborhood"
                    id="neighborhood"
                    placeholder="Bairro"
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="number"
                    id="number"
                    placeholder="Número"
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="city"
                    id="city"
                    placeholder="Cidade"
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    name="complement"
                    id="complement"
                    placeholder="Complemento"
                    onChange={handleChange}
                  />

                  <InputMask
                    mask="99999-999"
                    name="zip_code"
                    id="zip_code"
                    placeholder="CEP"
                    onChange={handleChange}
                  />
                  <select name="state" id="state" onChange={handleChange}>
                    <option>Estado</option>
                    {states &&
                      states.map((state, index) => (
                        <option key={index}>{state}</option>
                      ))}
                  </select>
                </div>

                <div className="form__row">
                  <select name="modality" id="modality" onChange={handleChange}>
                    <option>Modalidade</option>
                    <option value="Geral">{t('nps.toggleAll')}</option>
                    <option value="Pretos, Pardos e Indígenas">
                      Pretos, Pardos e Indígenas
                    </option>
                    <option value="PNE">PNE</option>
                  </select>
                  <input
                    type="text"
                    name="placing"
                    id="placing"
                    placeholder="Classificação"
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
            <div className="form__donate__payment-totals">
              <div className="form__donate__payment-info">
                <p>Subtotal</p>
                <h3>R$ {donate},00</h3>
              </div>
              <div className="form__donate__payment-info">
                <p>Taxa do cartão</p>
                <h3>R$ {taxes},00</h3>
              </div>
              <div className="form__donate__payment-info--total">
                <p>Total</p>
                <h3>R$ {donate + taxes},00</h3>
              </div>
            </div>

            <div className="form__row">
              <input
                type="submit"
                value={`Inscrever-me por R$ ${donate + taxes},00`}
              />
            </div>

            <div className="form__donate__payment-info--crypto">
              <p>
                <img alt="" src={lockIcon} /> pagamento seguro e criptografado
              </p>
            </div>
          </form>
        )}

        {donate === undefined && (
          <EmptyState
            type="select"
            title={t("emptyState.selectOptionTitle")}
            text={t("emptyState.selectOptionRegisterText")}
          />
        )}
      </div>
    </>
  );
}

export default Donate;
