import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';

export default function Form(props) {
  const { handleSubmit, children } = props;

  return <form onSubmit={handleSubmit}>{children}</form>;
}

export function FormControl(props) {
  const { value, counter, className, ...restProps } = props;

  return (
    <div className="form__control-wrapper">
      <input
        className={`form__control ${className}`}
        value={value}
        maxLength={counter}
        {...restProps}
      />

      {counter && (
        <span className={value?.length > counter ? 'form__counter form__counter--danger' : 'form__counter'}>
          {value?.length}/{counter}
        </span>
      )}
    </div>
  );
}

export function FormTextArea(props) {
  const { value, counter, className, ...restProps } = props;

  return (
    <div className="form__control-wrapper">
      <TextareaAutosize
        className={`form__control ${className}`}
        value={value}
        // maxLength={counter}
        style={{ paddingRight: counter ? 70 : 16 }}
        {...restProps}
      />

      {counter && (
        <span className={value?.length > counter ? 'form__counter form__counter--textarea form__counter--danger' : 'form__counter form__counter--textarea'}>
          {value?.length}/{counter}
        </span>
      )}
    </div>
  );
}

Form.Control = FormControl;
Form.TextArea = FormTextArea;
