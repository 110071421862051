import React, { useState } from 'react';
import Modal from 'app/components/Modal';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import { useFormik } from 'formik';
import Select from 'react-select';
import { useEffect } from 'react';
import { useClient } from 'jsonapi-react';

export default function ClassroomModal(props) {
  const { show, onClose, classrooms, course, classroomLoading } = props;
  const client = useClient();
  const [institutions, setInstitutions] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  const filters = useFormik({
    initialValues: {
      institution: 'blank'
    }
  });

  const getInstitutions = async () => {
    const { data, error } = await client.fetch('/institutions');
    if (error) {
      toast.error('Erro ao buscar instituições');
    } else {
      setInstitutions(data);
    }
  };

  const filteredClassrooms = classrooms.filter(item => {
    if (searchTerm === '') {
      return item;
    }
    if (searchTerm != null && item.title.toLowerCase().includes(searchTerm.toLowerCase())) {
      return item;
    }
  });

  const filterClassroomByInstitution = filteredClassrooms.filter(item => {
    return filters.values.institution === 'blank' || item.institution.id === parseInt(filters.values.institution);
  });

  useEffect(() => {
    if (show && institutions.length === 0) {
      getInstitutions();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <div className="modal__simple-header">
        <h3 className="modal__simple-title modal__simple-title--with-btn">
          {t('courses.classesOf')} {course?.title}
        </h3>
        <span className="widget__count">
          {classrooms?.length}
          <span> {t('courses.classes')}</span>
        </span>
      </div>
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <input
            value={searchTerm}
            aria-label="Pesquisar"
            className="form__control form__control--search-with-icon"
            style={{ width: 'auto' }}
            placeholder={t('textsCommon.research')}
            type="search"
            name="search"
            id="search"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="filter-bar__inner">
          <label className="form__label">{t('filter.institution')}:</label>
          <Select
            defaultValue={filters.values.institution}
            openMenuOnFocus
            options={[
              { value: 'blank', label: t('filter.blankLabelAll')  },
              ...institutions?.map(item => {
                return { value: item.id, label: item.name };
              })
            ]}
            className="react-multi-select filter-bar__multi-select"
            classNamePrefix="react-multi-select"
            placeholder={t('filter.blankLabelAll')}
            noOptionsMessage={() => 'Sem opções'}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
            onChange={e => filters.setFieldValue('institution', e.value)}
            style={{ width: '300px' }}
          />
        </div>
      </div>
      <div className="round-dg-wrapper round-dg-wrapper--modal">
        <table className="round-dg round-dg--light">
          <thead className="round-dg__header">
            <tr className="round-dg__row">
              <th className="round-dg__cell-header">{t('courses.classes')}</th>
              <th
                className="round-dg__cell-header"
                style={{ width: '148px' }}
              >
                {t('metrics.totalStudents')}
              </th>
              <th
                className="round-dg__cell-header"
                style={{ width: '148px' }}
              >
                {t('courses.accessIndex')}
              </th>
            </tr>
          </thead>

          <tbody className="round-dg__body">
            {classroomLoading && <Loader />}
            {!classroomLoading &&
              filterClassroomByInstitution?.map((c, i) => (
                <tr
                  className="round-dg__row"
                  key={i}
                >
                  <th className="round-dg__cell-header round-dg__cell-header--row">
                    <div className="round-dg__user">
                      <div className="round-dg__inner-user">
                        <h3 className="round-dg__title">{c.title}</h3>
                      </div>
                    </div>
                  </th>
                  <td className="round-dg__cell">{c['student-count'] ? c['student-count'] : '--'}</td>
                  <td className="round-dg__cell">{c.access ? c.access + '%' : '--'}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
