import React from 'react';

function TabNavBar1({ activeTabs, setActiveTabs }) {
  return (
    <div className="tab">
      <div
        className={`tab__link ${
          activeTabs === 'calendario-de-tarefas' ? 'active' : ''
        }`}
        onClick={() => setActiveTabs('calendario-de-tarefas')}
      >
        Calendário de tarefas
      </div>
      <div
        className={`tab__link ${
          activeTabs === 'rendimento-por-materia' ? 'active' : ''
        }`}
        onClick={() => setActiveTabs('rendimento-por-materia')}
      >
        Rendimento por matéria
      </div>
      <div
        className={`tab__link ${
          activeTabs === 'metricas-por-materia' ? 'active' : ''
        }`}
        onClick={() => setActiveTabs('metricas-por-materia')}
      >
        Métricas gerais
      </div>
    </div>
  );
}

export default TabNavBar1;
