import React, { useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import AdminLessonCard from './AdminLessonCard';
import { t } from 'i18next';

export default function AccordionItem() {
  const [active, setActive] = useState(false);

  const accordionContent = useRef(null);

  return (
    <div className={active ? 'accordion__item active' : 'accordion__item'}>
      <div
        className="accordion__header"
        onClick={() => setActive(!active)}
      >
        <h3 className="accordion__title">Disciplina 1</h3>
        <span className="widget__count">
          10 <span>{t('lms.lms')}</span>
        </span>
        <FiChevronDown className="accordion__chevron" />
      </div>

      <div
        className="accordion__collapse"
        style={{
          height: active ? `${accordionContent.current.scrollHeight}px` : '0'
        }}
      >
        <div
          className="accordion__content"
          ref={accordionContent}
        >
          {[...Array(6)].map((l, i) => (
            <AdminLessonCard
              id={i}
              key={i}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
