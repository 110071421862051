import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function BarChart(props) {
  const { data } = props;

  const series = [
    {
      color: '#8EA7E4',
      data: data.map(item => parseFloat(item.average_result.toFixed(2)))
    }
  ];

  const namesArray = data.map(item => item.name);

  const options = {
    chart: {
      type: 'bar'
    },
    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: namesArray,
      title: {
        text: null
      },
      labels: {
        align: 'left',
        reserveSpace: true,
        style: {
          whiteSpace: 'nowrap',
          width: 112,
          textOverflow: 'ellipsis'
        }
      },
      gridLineWidth: 0,
      lineWidth: 0
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: null
      },
      labels: {
        overflow: 'justify',
        format: '{value}%'
      },
      gridLineWidth: 1,
      lineWidth: 0
    },
    tooltip: {
      valueSuffix: '%'
    },

    series: series
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}
