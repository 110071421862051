import React, { useEffect, useState } from 'react';
import * as Fi from 'react-icons/fi';
import { useClient } from 'jsonapi-react';
import StudentListWidget from './StudentListWidget';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';

export default function ViewClassroom(props) {
  const { t } = useTranslation();
  const { classroomId } = props;
  const client = useClient();
  const [engagementData, setEngagementData] = useState({});
  const [loading, setLoading] = useState(true);

  const getEngagementData = async () => {
    setLoading(true);
    const routePath = `general_metrics?filter[classroom_id]=${classroomId}&students=true`;
    const { data, error } = await client.fetch(routePath);
    if (error) {
      toast.error('Erro ao carregar métricas');
    } else {
      setEngagementData(data);
      setLoading(false);
    }
  };

  const studentsList = engagementData.students;

  const classroomName = engagementData?.classrooms?.[0]?.name;

  useEffect(() => {
    getEngagementData();
  }, []);

  function getIcon(icon) {
    switch (icon) {
      case 'FiUser':
        return <Fi.FiUser />;
      case 'FiUsers':
        return <Fi.FiUsers />;
      case 'FiThumbsUp':
        return <Fi.FiThumbsUp />;
      case 'FiMessageCircle':
        return <Fi.FiMessageCircle />;
      case 'FiEdit':
        return <Fi.FiSend />;
      case 'FiMousePointer':
        return <Fi.FiMousePointer />;
      default:
        return <Fi.FiUser />;
    }
  }
  function getTooltip(label) {
    switch (label) {
      case 'Estudantes':
        return 'Número de estudantes';
      case 'Cursos':
        return 'Número de cursos';
      case 'Turmas':
        return 'Número de turmas';
      case t('textsCommon.likes'):
        return t('textsCommon.numberLikes');
      case t('textsCommon.comments'):
        return t('textsCommon.numberComments');
      case 'Posts':
        return 'Número de posts criados do feed';
      case 'Acessos':
        return 'Número total de acessos únicos no dia';
      case 'Variação de acesso':
        return 'Variação de acesso de alunos da semana atual comparado a semana anterior';
    }
  }

  return (
    <>
      <main className="main-content main-content--block">
        <div className="filter-bar">
          <button
            onClick={() => history.back()}
            className="filter-bar__back"
          >
            <span className="card__icon-wrapper">
              <Fi.FiChevronLeft className="card__icon" />
            </span>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title"> {engagementData?.classrooms?.[0]?.name}</span>
            </span>
          </button>
        </div>

        <div className="widget-wrapper">
          <div className="widget">
            <div className="widget__body">
              <div className="widget-wrapper">
                {loading && <Loader />}
                {!loading &&
                  engagementData?.items?.map(info => (
                    <div
                      className="info-widget info-widget--t2"
                      id={info.label}
                      data-tooltip-content={getTooltip(info.label)}
                      key={info.label}
                    >
                      <ReactTooltip
                        anchorId={info.label}
                        className="tooltip"
                      />

                      <div className="info-widget__header">
                        <h3 className="info-widget__title">{info.label}</h3>
                      </div>
                      <div className="info-widget__body">
                        <p className="info-widget__value">
                          {getIcon(info.icon)} {info.count}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="widget-wrapper">
          <StudentListWidget
            studentsList={studentsList}
            classroomId={classroomId}
            classroomName={classroomName}
            loading={loading}
          />
        </div>
      </main>
    </>
  );
}
