import React from 'react';
import { FiFolder } from 'react-icons/fi';

export default function StudyBlockCard(props) {
  const { material, studyVideo } = props;

  return (
    <tr
      className="round-dg__row u-cursor-pointer"
      onClick={() => window.open(material?.url, '_blank')}
    >
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <div className="card__header">
          <div className="card__inner-header">
            <div className="card__icon-wrapper">
              <FiFolder className="card__icon" />
            </div>
            <h2
              className="card__title"
              style={{ flexGrow: 'initial' }}
              title={studyVideo?.video?.title}
            >
              {studyVideo?.video?.title}
            </h2>
          </div>
        </div>
      </th>
    </tr>
  );
}
