import React, { useEffect, useState } from 'react';
import { useAgenda } from 'app/hooks/useAgenda';
import { FiMoreHorizontal, FiClock } from 'react-icons/fi';
import { Draggable } from 'react-beautiful-dnd';
import { useSession } from 'app/hooks/useSession';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import Dropdown from 'app/components/Dropdown';
import Swal from 'sweetalert2';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';

const Task = ({ disabled, weekSlot, index, onClick, weekMasterSlot, StudentTasks, excessTasks, myTasks, filters, setShowModal }) => {
  let categoryColor = '';
  let categoryName = '';
  const { t } = useTranslation();
  const { session } = useSession();
  const authorityLevel = getAuthorityLevel(session);
  const { deleteSlot, deleteMasterSlot, checkAccomplishSlot, getStudentTasks, currentSelectedWeek, setSelectedTask } = useAgenda();

  if (weekMasterSlot) {
    weekSlot = weekMasterSlot;
  }

  switch (weekSlot.activity) {
    case 'revision':
      categoryName = 'Revisar';
      categoryColor = '#B622FC';
      break;
    case 'Descobrir':
      categoryName = 'Descobrir';
      categoryColor = '#FDB221';
      break;
    case 'learn':
      categoryName = 'Aprender';
      categoryColor = '#21C9FE';
      break;
    case 'train':
      categoryName = 'Treinar';
      categoryColor = '#E95852';
      break;
    default:
      break;
  }

  function handleTagManagement(weekSlot) {
    switch (weekSlot['management-type']) {
      case 'dynamic':
        return 'expanded-calendar__tag';

      case 'course':
        return 'expanded-calendar__tag expanded-calendar__tag--course';

      case 'classroom':
        return 'expanded-calendar__tag expanded-calendar__tag--class';

      case 'student':
        return 'expanded-calendar__tag expanded-calendar__tag--individual';

      default:
        break;
    }
  }

  const clockAmount = weekSlot['clock-amount'] || 1;

  const handleDelete = () => {
    Swal.fire({
      title: t('projects.warningDeleteTask'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        if (myTasks || StudentTasks || excessTasks) {
          deleteSlot(weekSlot.id);
          if (authorityLevel == !'student') {
            setTimeout(() => getStudentTasks(filters, currentSelectedWeek), 500);
          }
        } else {
          deleteMasterSlot(weekSlot.id);
        }
      }
    });
  };

  const handleEdit = weekSlot => {
    setSelectedTask(weekSlot);
    setShowModal(true);
  };

  return (
    <>
      <Draggable
        draggableId={weekSlot.id.toString()}
        index={index}
      >
        {provided => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div
              className={authorityLevel == 'student' ? 'calendar-task' : 'calendar-task calendar-task--teacher'}
              style={{
                borderRightColor: categoryColor,
                height: `${clockAmount === 1 ? clockAmount * 120 : clockAmount * 90}px`
              }}
            >
              {authorityLevel === 'student' &&
                (disabled ? (
                  <input
                    onClick={() => checkAccomplishSlot(weekSlot)}
                    type="checkbox"
                    name="checkbox"
                    id="checkbox"
                    checked={weekSlot.accomplished}
                    className="calendar-task__check-input"
                  />
                ) : (
                  <input
                    onClick={() => checkAccomplishSlot(weekSlot)}
                    type="checkbox"
                    name="checkbox"
                    id="checkbox"
                    defaultChecked={weekSlot.accomplished}
                    className={!excessTasks ? 'calendar-task__check-input' : 'calendar-task__check-input calendar-task__check-input--excess-tasks'}
                  />
                ))}

              <div
                className="calendar-task__container"
                onClick={onClick}
                style={{ height: '100%' }}
              >
                <div className="calendar-task__header">
                  <span
                    className="calendar-task__title"
                    title={weekSlot?.taxonomies[0]?.name || weekSlot?.taxonomy?.name}
                  >
                    {weekSlot?.taxonomies[0]?.name || weekSlot?.taxonomy?.name}
                  </span>

                  {getPermission('Editar tarefas', 'Tarefas') || getPermission('Excluir tarefas', 'Tarefas') ? (
                    <Dropdown>
                      <Dropdown.Toggle>
                        <FiMoreHorizontal />
                      </Dropdown.Toggle>

                      <Dropdown.Content>
                        {getPermission('Editar tarefas', 'Tarefas') && <Dropdown.Item onClick={() => handleEdit(weekSlot)}>{t('button.edit')}</Dropdown.Item>}
                        {getPermission('Excluir tarefas', 'Tarefas') && (
                          <Dropdown.Item
                            onClick={() => {
                              handleDelete();
                            }}
                          >
                            {t('button.delete')}
                          </Dropdown.Item>
                        )}
                      </Dropdown.Content>
                    </Dropdown>
                  ) : null}
                </div>
                <span
                  className="calendar-task__description"
                  title={weekSlot.title ? weekSlot.title : 'Sem título'}
                >
                  <span className="calendar-task__description-text">{weekSlot.title ? weekSlot.title : <span>{t('tasks.untitled')}</span>}</span>
                </span>

                {authorityLevel === 'admin' && !StudentTasks && !myTasks ? (
                  !excessTasks && (
                    <div className="expanded-calendar__progress">
                      <div className="expanded-calendar__progress-container">
                        <div
                          className="expanded-calendar__progress-bar"
                          role="progressbar"
                          style={{ width: `${weekSlot['finished-percent']}%` }}
                          aria-valuenow={40}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                      <div className="expanded-calendar__progress-text">{weekSlot['finished-percent']}%</div>
                    </div>
                  )
                ) : (
                  <span
                    className={handleTagManagement(weekSlot)}
                    title={weekSlot['relationship-tag']}
                  >
                    {weekSlot['relationship-tag']}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
};

export default Task;
