import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import userAvatar from 'app/images/user-avatar.svg';
import Loader from 'app/components/loader';

export default function ModalDataGrid({ report, loading }) {
  return (
    <div
      className="round-dg-wrapper"
      style={{ height: 800 }}
    >
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: 800, overflow: 'auto' }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th
              className="round-dg__cell-header "
              style={{ width: 400 }}
            >
              Pergunta
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {totalAnswersOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setTotalAnswersOrder('ascendent');
                      setParam('total-answers');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('total-answers');
                      setTotalAnswersOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th
              style={{ width: 150 }}
              className="round-dg__cell-header round-dg__cell-header--sm"
            >
              GRE
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {totalErrorsOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setTotalErrorsOrder('ascendent');
                      setParam('total-errors');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('total-errors');
                      setTotalErrorsOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th
              style={{ width: 150 }}
              className="round-dg__cell-header round-dg__cell-header--sm"
            >
              Instituição
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {rightAnswersOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setRightAnswersOrder('ascendent');
                      setParam('right-answers');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('right-answers');
                      setRightAnswersOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th
              style={{ width: '100px' }}
              className="round-dg__cell-header round-dg__cell-header--sm"
            >
              Total de respostas
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {inepOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setInepOrder('ascendent');
                      setParam('inep');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('inep');
                      setInepOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '100px' }}
            >
              Repostas Corretas
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {institutionOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setInstitutionOrder('ascendent');
                      setParam('institution');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('institution');
                      setInstitutionOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '100px' }}
            >
              Repostas Incorretas
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {institutionOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setInstitutionOrder('ascendent');
                      setParam('institution');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('institution');
                      setInstitutionOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {report?.map((item, key) => (
            <tr
              className="round-dg__row u-cursor-pointer"
              key={key}
            >
              <td
                className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row"
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
              >
                {item?.description}
              </td>
              <td className="round-dg__cell round-dg__cell--sm">{item.regional}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item.institution}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item['total-answers']}</td>
              <td className="round-dg__cell round-dg__cell--sm">
                <span className="u-text-truncate">{item['total-correct-answers']}</span>
              </td>
              <td className="round-dg__cell round-dg__cell--sm">
                <span className="u-text-truncate">{item['total-incorrect-answers']}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
