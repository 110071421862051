import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function TaxonomyByDificultyChart(props) {
  const {data, categories} = props

  const options = {
    chart: { type: 'bar' },
    credits: {enabled: false},
    title: { text: null },
    colors: ['#c6c2d9','#7763ae','#442b8c'],
    yAxis: {
      min: 0,
      max: 100,
      tickInterval: 100,
      title: null,
      stackLabels: {enabled: false}
    },
    legend: {
      squareSymbol: false,
      symbolWidth: 20,
      symbolHeight: 6,
      symbolRadius: 0
    },
    tooltip: {
      shared: true,
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:.1f}%</b><br/>'
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {enabled: false}
      }
    },
    xAxis: {
      categories: categories
    },
    series: data
  }

  return(
    <HighchartsReact highcharts={Highcharts} options={options} />
  )
}

export default TaxonomyByDificultyChart
