import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import CourseCard from 'app/screens/Course/CourseCard';
import { useTranslation } from 'react-i18next';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import Modal from 'app/components/Modal';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';

export default function ModuleResultWidget(props) {
  const { userMetrics, userId } = props;
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableNotifications, setDisableNotifications] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState();
  const [showModal, setShowModal] = useState(false);
  const [detailedCourse, setDetailedCourse] = useState();
  const client = useClient();
  const authorityLevel = getAuthorityLevel();
  const journey = true;

  const getCourses = async () => {
    setLoading(true);
    const url = authorityLevel === 'admin' ? `/courses?user_id=${userId}&detailed=true` : '/courses?detailed=true';

    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Erro ao carregar cursos');
    } else {
      setCourses(data);
    }
    setLoading(false);
    setDisableNotifications(true);
  };

  useEffect(() => {
    getCourses();
  }, []);

  const getDetailedCourse = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/courses/${selectedCourse.id}?user_id=${userId}&detailed=true`);
    if (error) {
      toast.error('Erro ao buscar curso');
    } else {
      setDetailedCourse(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedCourse) {
      getDetailedCourse();
    }
  }, [selectedCourse]);

  return (
    <div className="widget">
      <div className="widget__header">
        <h3 className="widget__title u-mb-2">{t('journey.monitoringCoursesTitle')}</h3>
      </div>
      {!loading && courses.length === 0 && <EmptyState type="data" />}
      <div className="widget__body">
        <div
          className="tab__cards"
          style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 500px), 1fr))' }}
        >
          {loading && <Loader />}

          {courses &&
            courses?.map(course => {
              return (
                <CourseCard
                  setShowModal={setShowModal}
                  journey={journey}
                  course={course}
                  key={course.id}
                  setSelectedCourse={setSelectedCourse}
                />
              );
            })}
        </div>
      </div>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        contentClassName="modal__content--md"
      >
        <h3 className="widget__title u-mb-4">Acompanhamento dos módulos</h3>

        <div className="tab__cards">
          {loading ? (
            <Loader />
          ) : (
            detailedCourse?.['learning-systems'].length === 0 && (
              <EmptyState
                type="data"
                text="Nenhum módulo encontrado para este curso"
              />
            )
          )}
          {!loading &&
            detailedCourse?.['learning-systems'].length > 0 &&
            detailedCourse?.['learning-systems'].map(item => {
              return (
                <div
                  className="card"
                  key={item.id}
                >
                  <div className="card__header">
                    <h3
                      className="card__title"
                      title={item.title}
                    >
                      {item.title}
                    </h3>
                    <p className="card__subtitle">{item.last_user_access ? `${t('journey.accessedIn')} ${moment(item.last_user_access).format(t('datesForm.formatDate'))}` : ''}</p>
                  </div>
                  <div className="card__body">
                    <div className="card__progress">
                      <div className="card__progress-container">
                        <div
                          className="card__progress-bar"
                          role="progressbar"
                          style={{
                            width: `${!isNaN(item.progress) ? Math.floor(item.progress) : 0}%`
                          }}
                          aria-valuenow={!isNaN(item.progress) ? Math.floor(item.progress) : 0}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                      <div className="card__progress-text">{!isNaN(item.progress) ? Math.floor(item.progress) : 0}%</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Modal>
    </div>
  );
}
