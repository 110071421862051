import React from 'react';
import { SimpleModal } from 'app/components/Modal';
import LessonDataGrid from './LessonDataGrid';

export default function LessonModal(props) {
  const { show, onClose, data } = props;

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      contentClassName="simple-modal__content--lg"
      hideCloseButton
    >
      <h3 className="modal__simple-title">Aulas iniciadas</h3>

      <LessonDataGrid
        data={data}
        height={366}
      />
    </SimpleModal>
  );
}
