import React from 'react';
import { SimpleModal } from 'app/components/Modal';
import { FiX } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useNavigate } from '@reach/router';

export default function ScheduleAlert(props) {
  const { show, onClose, setShowModal, onUpdate, navigateLink, title = 'Você deseja publicar o auditório?' } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleScheduleNow = async () => {
    setIsLoading(true);
    await onUpdate();
    setIsLoading(false);
    toast.success('Publicação realizada com sucesso');
    navigate(navigateLink);
  };

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      contentClassName="simple-modal__content--br-md"
      hideCloseButton
    >
      <div className="schedule-alert">
        <div className="schedule-alert__header">
          <h3 className="schedule-alert__title">{title}</h3>
          <span
            className="schedule-alert__close"
            onClick={onClose}
          >
            <FiX />
          </span>
        </div>

        <div className="schedule-alert__body">
          <p>Se desejar, você também pode agendar a publicação.</p>
        </div>

        <div className="schedule-alert__footer">
          <button
            className="btn btn--outline"
            onClick={handleScheduleNow}
            disabled={isLoading}
          >
            Publicar agora
          </button>
          <button
            className="btn btn--primary"
            onClick={() => setShowModal(true)}
          >
            Agendar
          </button>
        </div>
      </div>
    </SimpleModal>
  );
}
