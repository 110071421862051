import { navigate } from '@reach/router';
import EmptyState from 'app/components/EmptyState';
import UserInfo from 'app/components/UserInfo';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { FiChevronLeft } from 'react-icons/fi';

export default function ViewInvitations(props) {
  const { inviteId } = props;
  const client = useClient();
  const [invitation, setInvitation] = useState();
  const [loading, setLoading] = useState(true);

  const getInvitation = async () => {
    const { data, error } = await client.fetch(`classroom_invitations/${inviteId}`);
    if (error) {
      toast.error('Erro ao buscar informações do convite.');
    } else {
      setInvitation(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvitation();
  }, [inviteId]);

  return (
    <main className="main-content main-content--block">
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <button
            className="filter-bar__back"
            onClick={() => navigate(-1)}
          >
            <span className="card__icon-wrapper">
              <FiChevronLeft className="card__icon" />
            </span>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{invitation?.classroom?.title}</span>
            </span>
          </button>
        </div>
      </div>

      {loading && <Loader />}
      {!loading && invitation.users.length === 0 && <EmptyState type="data" />}
      {!loading && invitation.users.length > 0 && (
        <table className="round-dg round-dg--light">
          <thead className="round-dg__header">
            <tr className="round-dg__row">
              <th className="round-dg__cell-header">{t('textsCommon.name')}</th>
              <th className="round-dg__cell-header">{t('profile.labelPhone')}</th>
              <th className="round-dg__cell-header">{t('textsCommon.class')}</th>
              <th
                className="round-dg__cell-header"
                style={{ width: '160px' }}
              >
                {t('journey.thLastAccess')}
              </th>
            </tr>
          </thead>
          <tbody className="round-dg__body">
            {invitation?.users?.map((user, index) => (
              <tr
                className="round-dg__row"
                key={index}
              >
                <th className="round-dg__cell-header round-dg__cell-header--row">
                  <UserInfo
                    user={user}
                    viewEmail
                  />
                </th>
                <td className="round-dg__cell">{user.phone}</td>
                <td className="round-dg__cell">{invitation?.classroom?.title}</td>
                <td className="round-dg__cell">{moment(user['last_access']).format('DD/MM/YYYY')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </main>
  );
}
