import React from 'react';
import { FiChevronDown, FiChevronRight, FiChevronUp } from 'react-icons/fi';
import { AiFillFolderOpen, AiOutlineFolder } from 'react-icons/ai';

export default function Folder(props) {
  const { name, subFolders, isExpanded, setIsExpanded, onClick, isOpenFolder, isSubFolder, folderId, setIdOpenedSubFolder, setCurrentFolder } = props;

  const chevronIcon = isOpenFolder ? <FiChevronRight className="card__icon" /> : isOpenFolder || isExpanded === folderId ? <FiChevronUp className="card__icon" /> : <FiChevronDown className="card__icon" />;

  const handleToggle = e => {
    e.stopPropagation();

    if (subFolders?.length > 0) {
      setIsExpanded(prev => (prev === folderId ? null : folderId));
      setIdOpenedSubFolder(null);
      setCurrentFolder(null);
    }
  };

  return (
    <div
      className={isOpenFolder ? 'finder__folder finder__folder--is-open' : 'finder__folder'}
      onClick={onClick}
    >
      {isOpenFolder ? <AiFillFolderOpen className="finder__folder-icon" /> : <AiOutlineFolder className="finder__folder-icon" />}

      <p
        className="finder__folder-label"
        title={name}
      >
        {name}
      </p>
      <button
        className="card__icon-wrapper card__icon-wrapper--sm"
        onClick={handleToggle}
      >
        {chevronIcon}
      </button>
    </div>
  );
}
