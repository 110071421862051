import React from 'react';
import AccuracyRateTag from '../../AccuracyRateTag';

export default function ChallengeRow(props) {
  const { challenge, isStudent, selectedTab, studentAccuracy } = props;

  return (
    <div
      className="round-dg-wrapper"
      style={{ width: '100%' }}
    >
      <table style={{ width: '100%', minWidth: 364 }}>
        <tbody>
          <tr className="round-dg__row">
            <td
              className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row"
              style={{ paddingLeft: 0 }}
            >
              <div className="round-dg__user">
                <div className="round-dg__user-inner">
                  <h3
                    className="round-dg__title"
                    title={challenge.title}
                  >
                    {challenge.title}
                  </h3>
                </div>
              </div>
            </td>
            <td
              className="round-dg__cell round-dg__cell--sm"
              style={{ width: 160 }}
            >
              <span
                className="badge badge--tiny badge--primary"
                title={challenge.taxonomy}
              >
                {challenge.taxonomy}
              </span>
            </td>
            <td
              className="round-dg__cell round-dg__cell--sm"
              style={{ width: 140 }}
            >
              {challenge['initial-date'].toLocaleDateString('pt-br', { day: 'numeric' })} a {challenge['end-date'].toLocaleDateString('pt-br')}
            </td>
            {!isStudent && selectedTab == 'ranking-por-participante' && (
              <td
                className="round-dg__cell round-dg__cell--sm"
                style={{ width: 140 }}
              >
                <span className="u-text-truncate">Rede</span>
              </td>
            )}
            <td
              className="round-dg__cell round-dg__cell--sm"
              style={{ width: 164, paddingRight: 0 }}
            >
              <AccuracyRateTag value={studentAccuracy} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
