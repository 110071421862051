import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import { useAgenda } from 'app/hooks/useAgenda';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import MultiSelect from 'app/components/MultiSelect';
import { useSession } from 'app/hooks/useSession';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import moment from 'moment';
import Modal from 'app/components/Modal';
import { useTranslation } from 'react-i18next';

export const NewSlotForm = ({ filters, day, selectedTask, setShowModal, selectedTab, myTasks, selectedUser, StudentTasks, excessTasks, setLimitAlertMessage, sprint_id }) => {
  const defaultSelected = {
    title: '',
    published: true,
    selectedTaxonomies: [],
    selectedCourses: [],
    selectedClassrooms: [],
    files: []
  };
  const { t } = useTranslation();
  const client = useClient();
  const { createSlot, createMasterSlot, getDailyOvertime, dailyOvertime, setDailyOvertime, setLoading, getSlots, getMasterSlots } = useAgenda();
  const [taxonomies, setTaxonomies] = useState([]);
  const [taxonomiesOptions, setTaxonomiesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState(defaultSelected);
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [classroomData, setClassroomData] = useState([]);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  useEffect(() => {
    fetchTaxonomies();
    if (selectedTab === 'curso') {
      getCourseData();
    }
    if (selectedTab === 'turma') {
      getClassroomData();
    }
  }, []);

  useEffect(() => {
    if ((dailyOvertime?.overtimeStudentCount || 0) > 0) {
      setShowModalConfirm(true);
    }
  }, [dailyOvertime]);

  const fetchTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');
      let options = [];
      data.forEach(item => {
        options.push({ value: item.id, label: item.name });
      });

      setTaxonomies(data);
      setTaxonomiesOptions(options);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      toast.error(t("toast.errorGetTags"));
    }
  };

  const getCourseData = async () => {
    if (filters.values.course !== 'blank') {
      const { data, error } = await client.fetch(`courses/${filters.values.course}`);
      if (error) {
        toast.error(t("toast.errorGetCourseInfo"));
      } else {
        setCourseData(data);
      }
    }
  };

  const getClassroomData = async () => {
    if (filters.values.classroom !== 'blank') {
      const { data, error } = await client.fetch(`classrooms/${filters.values.classroom}`);
      if (error) {
        toast.error(t("toast.errorGetClassesInfo"));
      } else {
        setClassroomData(data);
      }
    }
  };

  const formDefaultValues = {
    title: selectedTask?.title || '',
    body: selectedTask?.body || '',
    duration: selectedTask?.duration || '01:00',
    date: selectedTask?.date || day,
    selectedTaxonomies: selectedTask?.taxonomies || selected.selectedTaxonomies
  };

  let form;

  if (myTasks || StudentTasks || excessTasks) {
    form = useFormik({
      initialValues: {
        ...formDefaultValues,
        activity: selectedTask?.activity || 'train',
        taxonomyId: null,
        ['user-id']: selectedUser?.id,
        'sprint-id': sprint_id
      },
      onSubmit: values => {
        selectedTask ? updateSlot(values) : createSlot({ ...values });
        setShowModal(false);
      }
    });
  } else {
    const formType = Object.keys(filters.values).includes('course') ? 'course' : 'classroom';
    form = useFormik({
      initialValues: {
        ...formDefaultValues,
        activity: 1,
        [`${formType}-id`]: filters.values[formType]
      },
      onSubmit: values => {
        selectedTask ? updateMasterSlot(values) : createMasterSlot({ ...values });
        setShowModal(false);
        setDailyOvertime(null);
      }
    });
  }

  const updateSlot = async values => {
    setLoading(true);
    const formData = values;
    delete formData.selectedTaxonomies;
    const { error } = await client.mutate(['slots', selectedTask.id], formData);
    if (error) {
      toast.error(t("toast.errorEditTask"));
    } else {
      toast.success(t("toast.successEditTask"));
      window.location.reload();
    }
  };

  const updateMasterSlot = async values => {
    setLoading(true);
    const formData = {
      ...values,
      'taxonomy-ids': values.selectedTaxonomies.map(t => t.id)
    };
    delete formData.selectedTaxonomies;
    const { error } = await client.mutate(['master_slots', selectedTask.id], formData);
    if (error) {
      toast.error(t("toast.errorEditTask"));
    } else {
      toast.success(t("toast.successEditTask"));
      window.location.reload();
    }
  };

  const materiasOptions = myTasks
    ? [
        { value: 'learn', label: t("tasks.typeLearn")},
        { value: 'train', label: t("tasks.typeTraining")}
      ]
    : [
        { value: 1, label: t("tasks.typeLearn")},
        { value: 3, label: t("tasks.typeTraining")}
      ];

  const { session } = useSession();
  const authorityLevel = getAuthorityLevel(session);

  function handleFormTitle(selectedTab) {
    switch (selectedTab) {
      case 'curso':
        return `- ${moment(day).format('dddd')}, ${day} - ${courseData?.title}`;

      case 'turma':
        return `- ${moment(day).format('dddd')}, ${day} - ${classroomData?.title}`;

      case 'estudante':
        return `para ${selectedUser.name}`;

      default:
        break;
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (authorityLevel === 'admin') {
      const formType = Object.keys(filters.values).includes('course') ? 'course' : 'classroom';
      getDailyOvertime(form, formType);
    } else {
      form.handleSubmit();
    }
  }

  return (
    <div>
      <div>
        <form
          method="post"
          className="form"
          onSubmit={handleSubmit}
        >
          <h2 className="modal__simple-title">{t("tasks.new")} {authorityLevel === 'admin' && handleFormTitle(selectedTab)}</h2>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="title"
            >
              {t("tasks.titleTaskLabel")}
            </label>
            <input
              className="form__control"
              id="title"
              name="title"
              type="text"
              placeholder={t("tasks.placeholderTitleTask")}
              onChange={form.handleChange}
              defaultValue={form?.values?.title}
            />
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="notebookName"
            >
              {t("tasks.typeLabel")}
            </label>
            <Select
              openMenuOnFocus
              defaultValue={form?.values?.activity}
              options={materiasOptions}
              className="react-multi-select"
              classNamePrefix="react-multi-select"
              placeholder={t("tasks.placeholderType")}
              noOptionsMessage={() => {t("tasks.noOptions")}}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              onChange={e => form.setFieldValue('activity', e.value)}
            />
          </div>

          <div className="form__row u-w-lg-50">
            <label
              className="form__label"
              htmlFor="resolution-time"
            >
              {t("tasks.duration")}
            </label>
            <select
              className="form__select"
              id="duration"
              name="duration"
              aria-label="Selecione a duração"
              onChange={form.handleChange}
              value={form?.values?.duration}
            >
              <option
                defaultValue
                value="01:00"
              >
                1 {t("tasks.hour")}
              </option>
              <option value="02:00">2 {t("tasks.hours")}</option>
              <option value="03:00">3 {t("tasks.hours")}</option>
            </select>
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="resolution-time"
            >
              {t("tasks.description")}
            </label>
            <textarea
              id="body"
              name="body"
              onChange={form.handleChange}
              defaultValue={form?.values?.body}
              placeholder={t("tasks.placeholderDescription")}
            />
          </div>

          <fieldset className="form__row">
            <legend className="form__label">{t("tasks.mattersRelated")}</legend>

            <MultiSelect
              selectedTaxonomies={form?.values?.selectedTaxonomies}
              setFieldValue={form.setFieldValue}
              openModal={() => setIsOpenModalTaxonomies(true)}
              placeholder={t("tasks.mattersAdd")}
            />
          </fieldset>

          <footer className="notebook__side-footer">
            <button
              type="submit"
              className="btn btn--primary btn--wide"
            >
              {t("tasks.buttonCreateTask")}
            </button>
          </footer>
        </form>
      </div>

      {taxonomies.length > 0 && isOpenModalTaxonomies && (
        <ModalTaxonomies
          taxonomies={taxonomies}
          selectedTaxonomies={form?.values?.selectedTaxonomies}
          setFieldValue={form.setFieldValue}
          closeModal={() => setIsOpenModalTaxonomies(false)}
        />
      )}

      <Modal
        show={showModalConfirm}
        onClose={() => setShowModalConfirm(false)}
        contentClassName="modal__content--sm"
      >
        <h2 className="modal__simple-title">{t("tasks.dailyLimit")}</h2>

        <p className="u-text-neutral-dark">
          <strong>{dailyOvertime?.overtimeStudentCount} {t("tasks.dailyLimitStudent")}</strong>
          <br /> {t("tasks.textReduceTime")}
        </p>

        <div className="form__columns">
          <div className="form__col">
            <button
              onClick={() => {
                setShowModalConfirm(false);
                setShowModal(false);
                setDailyOvertime(null);
                toast.success(t("tasks.successUndoTask"));
              }}
              className="btn btn--outline btn--full"
              type="button"
            >
              {t("tasks.buttonUndoTask")}
            </button>
          </div>
          <div className="form__col">
            <button
              onClick={() => {
                setShowModalConfirm(false);
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                });
                setLimitAlertMessage(true);
                form.handleSubmit();
              }}
              className="btn btn--outline btn--full"
              type="button"
            >
              {t('button.continue')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
