import React, { useState } from 'react';
import SurveyCard from './SurveyCard';
import { useTranslation } from 'react-i18next';

export default function Survey(props) {
  const { t } = useTranslation();
  const { onClose, questions, surveyData, submitForm } = props;

  const [questionsRating, setQuestionsRating] = useState(1);

  const changeRating = (item, rating, body) => {
    setQuestionsRating(questions.map(q => (item.id === q.id ? { ...q, rating: rating, body: body } : q)));
  };

  return (
    <div className="survey">
      <header className="survey__header">
        <div
          className="new-questions__info"
          style={{
            width: '240px',
            marginLeft: 'auto',
            marginBottom: '16px'
          }}
        >
          <p>{t('nps.quizDisplayCard')}</p>
        </div>

        <div className="survey__progress">
          {questions?.map(questions => (
            <div
              key={questions.id}
              className={`survey__progress-item ${questions.rating ? 'answered' : ''}`}
            />
          ))}
        </div>

        <h3 className="survey__title">{surveyData?.title}</h3>
        <p className="survey__description">{surveyData?.description}</p>
      </header>

      <div className="survey__body">
        {questions?.map(question => (
          <SurveyCard
            key={question.id}
            data={question}
            changeRating={changeRating}
            representation={surveyData.representation}
          />
        ))}
      </div>

      <footer className="survey__footer">
        <button
          onClick={() => onClose()}
          className="btn btn--outline btn--wide u-mr-4"
        >
          {t('button.edit')}
        </button>
        <button
          onClick={() => submitForm()}
          className="btn btn--primary btn--wide"
        >
          {t('button.finish')}
        </button>
      </footer>
    </div>
  );
}
