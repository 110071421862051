import React from 'react';
import { useTranslation } from 'react-i18next';
import { AttemptCard } from './AttemptCard';
import { useQuery } from 'jsonapi-react';
import Loader from 'app/components/loader';

export default function AnswersGuide(props) {
  const { t } = useTranslation();
  const { examId, amountQuestions, title = t('exams.examAnswer'), examData = [] } = props;
  const { data: questionBooksUsers, isLoading } = useQuery(examData.length > 0 ? false : `question_books_users?filter[current_user]=true&filter[question_book_id]=${examId}`);

  return (
    <>
      <div className="container-header-attempt-modal">
        <h2 className="font-bold text-lg text-primary-dark">{title}</h2>

        <span className="text-neutral">
          <b className="text-primary-dark">{amountQuestions}</b> questões
        </span>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <div className="round-dg-wrapper">
          <table
            className="round-dg round-dg--light"
            style={{ minWidth: '400px' }}
          >
            <tbody className="round-dg__body">
              {examData.length > 0
                ? examData.map((item, index) => (
                    <AttemptCard
                      key={item.id}
                      index={index + 1}
                      attemptData={item}
                    />
                  ))
                : questionBooksUsers.map((item, index) => (
                    <AttemptCard
                      key={item.id}
                      index={index + 1}
                      attemptData={item}
                    />
                  ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
