/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import Loader from 'app/components/loader';
import { ProjectsContext } from './provider';
import { useSession } from 'app/hooks/useSession';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { useTranslation } from 'react-i18next';
import EmptyState from 'app/components/EmptyState';

export const ProjectsList = props => {
  const { t } = useTranslation();
  const { projectId } = props;
  const { session } = useSession();
  const id = session.user.id;
  const { loading, getProject, getProjectVersion, selectedProject, selectedProjectVersion, setSelectedProjectVersion } = useContext(ProjectsContext);
  const [ownProject, setOwnProject] = useState(true);
  const authorityLevel = getAuthorityLevel();

  function checkIfUserIsOnProjec(selectedProject, id) {
    return selectedProject?.users?.some(obj => obj.id === id);
  }

  useEffect(() => {
    if (projectId) {
      getProject(true, projectId);
    }
  }, [projectId]);

  useEffect(() => {
    if (selectedProject) {
      if (checkIfUserIsOnProjec(selectedProject, id) || authorityLevel !== 'student') {
        setOwnProject(true);
      } else {
        setOwnProject(false);
      }
    }
  }, [selectedProject, session]);

  return (
    <>
      {ownProject && (
        <div className="new-questions__sidebar new-questions__sidebar--with-main-nav">
          {loading && <Loader />}

          {!loading && selectedProject && (
            <ul className="project-timeline">
              {selectedProject['project-versions'].map((pv, index) => (
                <li
                  key={index}
                  onClick={() => {
                    getProjectVersion(pv.id);
                  }}
                  className={`project-timeline__item ${selectedProjectVersion && pv.id === selectedProjectVersion.id && 'active'}`}
                >
                  <div className="project-timeline__version">V.{pv.version}</div>
                  <div className="project-timeline__group">
                    <span className="project-timeline__text">
                      {t('projects.versionProject')} {pv.version}.0
                    </span>
                    <span className="project-timeline__create">
                      {t('projects.createdAt')} {moment(pv.created_at).format(t('datesForm.formatDate'))}
                    </span>
                  </div>
                </li>
              ))}
              {selectedProject.approved && <li className="project-timeline__item project-timeline__item--approved">🎉 Projeto aprovado!</li>}
            </ul>
          )}

          {!selectedProjectVersion && !loading && (
            <EmptyState
              type="data"
              bgless
              title={t('emptyState.missingDataTitle')}
              text={authorityLevel === 'admin' ? t('emptyState.textNoDataYet') : t('emptyState.submittingNewVersionText')}
            />
          )}
        </div>
      )}
    </>
  );
};
