
import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import { useSession } from '../hooks/useSession';
import toast from 'react-hot-toast';
import moment from 'moment';
import Modal from './Modal/Simulated';
import { t } from 'i18next';

function OpenOnboardingMain() {
  const { session } = useSession();
  const [showModal, setShowModal] = useState(false);
  const [showCallOpen, setShowCallOpen] = useState(false);
  const [currentQuestionBook, setCurrentQuestionBook] = useState();
  const client = useClient();

  useEffect(() => {
    //getQuestionBooks();
  }, []);

  const isBetweenOpenDays = (questionBook) => {
    return false//questionBook.visibility === 'open' && moment().isBetween(questionBook['published-at'], questionBook['finished-at'], undefined, true);
  }

  const getQuestionBooks = async () => {
    try {
      const filterQuestionBook = { filter: { visibility: 'open' }, page: {number: 1, size: 1}, current_user: session.user.id };
      const { data } = await client.fetch(['question_books', null, filterQuestionBook]);
      if(data.length > 0) {
        const questionBook = data[0];

        if(questionBook && isBetweenOpenDays(questionBook)) {
          setCurrentQuestionBook(questionBook)

          if(session?.user && questionBook['last-question-books-user-id']) {
            if(!(questionBook['is-question-book-done'])) {
              setShowCallOpen(true);
            }
          } else {
            setShowCallOpen(true);
          }
        }
      }
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetQuestionBooks'));
    }
  };

  return (
    <div className="page page--wrap">
      <div className="banner"></div>
      <div className="page__col page__col--center">
        <h1 className="side-content__title side-content__title--lg">
          Bem-vindo(a) ao OPEN
        </h1>

        <p className="side-content__subtitle">
          O OPEN foi criado para ajudar você a se preparar para o exame da
          Ordem e formentar nossa comunidade de estudantes de direito.
        </p>

        {showCallOpen ? (
          <button onClick={() => setShowModal(true)} className="btn btn--primary btn--full">
            Iniciar meu simulado
          </button>
        ) : (
          <p className="side-content__subtitle">
            <strong>
              {currentQuestionBook && currentQuestionBook['is-question-book-done'] ? (
                'Você já finalizou o open. Em breve vamos disponibilizar os resultados.'
              ) : (
                'Em breve vamos disponibilizar o link para a prova. Fique ligado.'
              )}
            </strong>
          </p>
        )}
        {currentQuestionBook && <Modal
          questionBook={currentQuestionBook}
          show={showModal}
          to={`/open/${currentQuestionBook?.id}`}
          onClose={() => setShowModal(false)}
          title="Open"
          teacher="4º Secretariado"
        />}
      </div>

      <div className="page__col page__col--box">
        <h2 className="side-content__title u-mb-4">
          Em breve vamos disponibilizar o seu resultado do simulado aqui.
        </h2>

        <div className="page__soon">Em breve</div>
      </div>
    </div>
  );
}

export default OpenOnboardingMain;
