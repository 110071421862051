import React, { useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import Modal from 'app/components/Modal';
import ActivityForm from './ActivityForm';
import ActivityCard from './ActivityCard';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import Loader from 'app/components/loader';
import { t } from 'i18next';

export default function ActivityTab(props) {
  const { lessonId, showListLessons } = props;
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [activities, setActivities] = useState([]);
  const [key, setKey] = useState(1);
  const [loading, setLoading] = useState();

  const onSuccess = () => {
    setKey(key + 1);
  };

  const getActivities = async () => {
    setLoading(true);

    const { data, error } = await client.fetch(`/activities?filter[learning_system_items.id]=${lessonId}`);

    setLoading(false);

    if (error) {
      toast.error('Erro ao carregar atividades');
    } else {
      setActivities(data);
    }
  };

  const deleteActivity = async id => {
    try {
      await client.delete(`/learning_system_activities/${id}`);
      onSuccess();
    } catch {
      console.error(e);
      toast.error(t('toast.errorDeleteActivity'));
    }
  };

  useEffect(() => {
    getActivities();
  }, [key]);

  const currentLesson = showListLessons.filter(item => item.id == lessonId);

  return (
    <>
      <div className="filter-bar">
        <button
          onClick={() => history.back()}
          className="filter-bar__back"
        >
          <FiChevronLeft />

          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{currentLesson.map(item => item.body)}</span>
          </span>
        </button>

        <button
          className="btn btn--primary btn--wide u-ml-auto"
          onClick={() => setShowModal(true)}
        >
          {t('activiy.activityTitle')}
        </button>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          {activities.length == 0 ? (
            <EmptyState
              type="select"
              title="Atividade complementar"
              text="Crie ou adicione atividades complementares às aulas"
            />
          ) : (
            <div className="tab__cards fadeIn">
              {activities.map(item => {
                return (
                  <ActivityCard
                    deleteActivity={() => deleteActivity(item['learning-system-activities'][0]?.id)}
                    title={item.title}
                    modulus={item.modulus}
                    unit={item.unit}
                    hours={item['class-hours']}
                    isRequired={item['learning-system-activities'][0]?.['is_required']}
                  />
                );
              })}
            </div>
          )}
        </>
      )}

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        contentClassName="modal__content--md"
      >
        <ActivityForm
          onSuccess={onSuccess}
          lessonId={lessonId}
          setShowModal={setShowModal}
          activityListedIds={activities.map(n => n.id)}
        />
      </Modal>
    </>
  );
}
