import { FiTrash, FiTrash2, FiX } from 'react-icons/fi';

function MagicLink(props) {
  const { magicLink, setShowMagicLink, showClose } = props;

  return (
    <div className="magic-link">
      {magicLink.image === undefined && (
        <>
          {showClose && (
            <div
              onClick={() => setShowMagicLink(false)}
              className="magic-link__close"
            >
              <FiX />
            </div>
          )}

          <h1 className="magic-link__title">{magicLink.title}</h1>
          <h2 className="magic-link__url">{magicLink.url}</h2>
          <p className="magic-link__description">{magicLink.description}</p>
        </>
      )}
      {magicLink.image && (
        <>
          <div
            className="magic-link__image"
            style={{ backgroundImage: `url(${magicLink.image})` }}
          ></div>
          <div className="magic-link__body">
            {showClose && (
              <div
                onClick={() => setShowMagicLink(false)}
                className="magic-link__close"
              >
                <FiX />
              </div>
            )}

            <h1 className="magic-link__title">{magicLink.title}</h1>

            <p className="magic-link__description">{magicLink.description}</p>
          </div>
        </>
      )}
    </div>
  );
}
// <h2 className="magic-link__url">{magicLink.url}</h2>
export default MagicLink;
