import React, { useState } from 'react';
import Avatar from '../../components/Avatar';
import { Link } from '@reach/router';
import Modal from 'app/components/Modal';
import AnswersGuide from './AnswersGuide';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function ExamMetricsUserListItem(props) {
  const { t } = useTranslation();
  const { examStudent, exam } = props;

  const avatarImage = examStudent?.user?.image?.url;
  const avatarImageMemo = React.useMemo(() => avatarImage, [avatarImage]);
  const [showModal, setShowModal] = useState(false);

  const userAttempts = examStudent.user['question-books-users'][0];

  return (
    <>
      <tr className="round-dg__row">
        <td className="round-dg__cell">
          <div className="round-dg__user">
            <Link
              to={`/usuario/metricas/${examStudent?.user?.id}`}
              state={{ isRedirected: true }}
            >
              <Avatar
                src={avatarImageMemo}
                alt={examStudent?.user?.name}
                className="profile-resume__avatar"
              />
            </Link>

            <div className="round-dg__user-inner">
              <h3
                className="round-dg__title font-bold text-primary-dark"
                title={examStudent?.user?.name}
              >
                <Link
                  to={`/usuario/metricas/${examStudent?.user?.id}`}
                  state={{ isRedirected: true }}
                >
                  {examStudent?.user?.name}
                </Link>
              </h3>
            </div>
          </div>
        </td>
        {exam.visibility !== 'open' && (
          <td className="round-dg__cell">
            <div className={userAttempts?.status === 'approved' ? 'badge badge--success' : userAttempts?.status === 'accepting_answers' ? 'badge' : 'badge badge--danger'}>
              {userAttempts?.status === 'approved' ? t('exams.statusInApproved') : userAttempts?.status === 'accepting_answers' ? t('exams.inProgress') : t('exams.statusInDisapproved')}
            </div>
          </td>
        )}

        <td className="round-dg__cell">{moment(userAttempts['finished-at']).format('DD/MM/YYYY') != 'Invalid date' ? moment(userAttempts['finished-at']).format('DD/MM/YYYY') : '--'}</td>
        <td className="round-dg__cell">{parseFloat(userAttempts.score).toFixed(2) != 'NaN' ? parseFloat(userAttempts.score).toFixed(2) : '--'}</td>
        <td className="round-dg__cell last-cell">
          <button
            className="btn btn--outline btn--small btn--wide"
            onClick={() => setShowModal(true)}
          >
            {t('button.answerKey')}
          </button>
        </td>
      </tr>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        contentClassName="modal__content--md"
      >
        <AnswersGuide
          examId={exam.id}
          examData={examStudent.user['question-books-users']}
          amountQuestions={exam['question-count']}
        />
      </Modal>
    </>
  );
}
