import React from 'react';
import { FiCheckCircle, FiClock, FiMoreHorizontal } from 'react-icons/fi';
import dragAndDrop from 'app/images/icons/drag-and-drop.svg';
import Dropdown from 'app/components/Dropdown';
import Swal from 'sweetalert2';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { navigate } from '@reach/router';
import { t } from 'i18next';

export default function BookletCard({ booklet, setSelectedBooklets }) {
  const client = useClient();

  const handleDelete = () => {
    Swal.fire({
      title: t('projects.warningDeleteQuestionBook'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        removeBooklet();
      }
    });
  };

  const removeBooklet = async () => {
    const { error } = await client.delete(`/learning_system_question_books/${booklet.id}`);
    if (error) {
      toast.error('Erro ao remover apostila');
    } else {
      toast.success('Apostila removida!');
      setSelectedBooklets(prevState => prevState.filter(item => item.id !== booklet.id));
    }
  };

  return (
    <>
      <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
        <div className="card__header u-mb-0">
          <div className="card__inner-header">
            <img
              src={dragAndDrop}
              alt="ícone de arrastar"
              style={{ width: '10px', cursor: 'move', cursor: 'grab' }}
            />
            <div className="card__icon-wrapper">
              <FiCheckCircle className="card__icon" />
            </div>
            <h2
              className="card__title"
              style={{ flexGrow: 'initial' }}
            >
              {booklet?.['question-book']?.title}
            </h2>
            {booklet?.['is-required'] && <span className="badge badge--tiny badge--danger">Obrigatória</span>}
          </div>
        </div>
      </th>

      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '200px' }}
      >
        <div className="card__badges card__badges--one-line u-mb-0">
          {booklet?.['question-book']?.taxonomies.length <= 3 &&
            booklet?.['question-book']?.taxonomies.map(item => {
              return (
                <span
                  className="badge badge--primary"
                  title={item.name}
                >
                  {item.name}
                </span>
              );
            })}
          {booklet?.['question-book']?.taxonomies.length > 3 &&
            booklet?.['question-book']?.taxonomies?.slice(0, 2).map(item => {
              return (
                <span
                  className="badge badge--more"
                  title={item?.name}
                >
                  +{booklet?.['question-book']?.taxonomies?.slice(0, 2)?.length}
                </span>
              );
            })}
        </div>
      </td>

      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '100px' }}
      >
        <FiCheckCircle className="card__icon" /> {booklet?.['question-book']?.question_count}
      </td>

      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '100px' }}
      >
        <FiClock className="card__icon card__icon--secondary" /> {booklet?.['question-book']?.question_count}
      </td>

      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '44px' }}
      >
        <Dropdown>
          <Dropdown.Toggle>
            <FiMoreHorizontal />
          </Dropdown.Toggle>

          <Dropdown.Content>
            <Dropdown.Item
              onClick={() => {
                navigate(`/${booklet['question-book'].visibility === 'exam'? 'provas' : 'apostilas'}/gerenciar/${booklet?.['question-book']?.id}`);
              }}
            >
              Gerenciar
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDelete()}>{t('button.delete')}</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown>
      </td>
    </>
  );
}
