import React from 'react';
import { Link } from '@reach/router';
import { FiPlayCircle } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import moreMedal from 'app/images/more-medal.svg';
import { t } from 'i18next';

export default function ModuleCard(props) {
  const { newModule, newMessage, completed } = props;

  return (
    <div className="module-card">
      <div className="module-card__header">
        <img
          className="module-card__img"
          src={defaultImage}
          alt=""
        />
        <div className="module-card__inner-header">
          <h3 className="module-card__title">Física Nuclear</h3>
          <div className="card__badges u-mb-0">
            <span className="badge badge--tiny badge--warning">Nova mensagem</span>
            {newModule && <span className="badge badge--tiny badge--warning">Novo</span>}
            {completed && <span className="badge badge--tiny badge--success">Concluído</span>}
          </div>
        </div>
      </div>

      {completed ? (
        <div className="medal medal--sm">
          <img
            src={moreMedal}
            alt="nova medalha"
            className="medal__img"
          />
          <span className="medal__value">
            <AiOutlineTrophy />
          </span>
        </div>
      ) : (
        <p className="module-card__text">
          <strong>10</strong>/12 {t('lms.lms')}
        </p>
      )}

      <div className="module-card__footer">
        {newModule ? (
          <button
            type="button"
            className="btn btn--full btn--outline"
          >
            {t('button.view')}
          </button>
        ) : (
          <>
            <p className="module-card__text">Continue de onde parou</p>
            <Link
              className="module-card__link"
              to={`/show-course/${374}/classroom`}
            >
              <span className="card__icon-wrapper">
                <FiPlayCircle className="card__icon" />
              </span>
              <span className="module-card__link-text">Aula 2 - Física clássica</span>
            </Link>
          </>
        )}
      </div>
    </div>
  );
}
