import React, { useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import AnswerOption from './AnswerOption';
import Dropdown from 'app/components/Dropdown';
import Swal from 'sweetalert2';
import { t } from 'i18next';

export default function Question(props) {
  const { questionNumber, representation, question, index, updateCurrentQuestion, deleteQuestion } = props;

  const handleChange = e => {
    let newQuestion = question;
    newQuestion.question = e.target.value;
    updateCurrentQuestion(index, question);
  };

  const handleAnswerChange = (i, answer) => {
    let newQuestion = question;
    newQuestion['survey-items'][i] = answer;
    updateCurrentQuestion(index, question);
  };

  return (
    <div className="page__content u-mb-4">
      <div className="form__row u-mt-0">
        <div className="form__label-wrapper">
          <label
            htmlFor="questionName"
            className="form__label"
          >
            {t('nps.question')} {questionNumber || 0}
          </label>
          <Dropdown>
            <Dropdown.Toggle>
              <FiMoreHorizontal />
            </Dropdown.Toggle>

            <Dropdown.Content>
              <Dropdown.Item
                onClick={() => {
                  Swal.fire({
                    title: t('projects.warningDeleteQuestion'),
                    text: t('projects.warningDeleteCannotBeUndoneText'),
                    confirmButtonText: t('button.yes'),
                    showDenyButton: true,
                    denyButtonText: t('button.no'),
                    showCloseButton: true
                  }).then(res => {
                    if (res.isConfirmed) {
                      deleteQuestion(question);
                    }
                  });
                }}
              >
                {t('button.delete')}
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </div>
        <input
          id="questionName"
          name="questionName"
          placeholder={t('nps.setYourQuestion')}
          type="text"
          className="form__control"
          value={question.question}
          onChange={handleChange}
        />
      </div>
      {representation === 'estrela' &&
        (question?.['survey-items'] || []).map((answer, i) => (
          <AnswerOption
            key={i}
            index={i}
            answer={answer}
            handleAnswerChange={handleAnswerChange}
          />
        ))}
    </div>
  );
}
