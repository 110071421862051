import React, { useContext, useEffect, useState } from 'react';
import { useClient } from 'jsonapi-react';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { FilterCheckBox } from 'app/components/FilterCheckBox';
import { StudentCard } from 'app/components/StudentCard';
import { useFormik } from 'formik';
import { MetricsContext, MetricsProvider } from './provider/MetricsProvider';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';

export const Metrics1 = () => (
  <MetricsProvider>
    <ScreenContentMetrics />
  </MetricsProvider>
);

const ScreenContentMetrics = () => {
  const { t } = useTranslation();
  const client = useClient();
  const { getMetricsData, metricsData, loading } = useContext(MetricsContext);
  const [filterOptions, setFilterOptions] = useState({
    courses: [],
    classrooms: []
  });
  const [filterOptionsLoading, setFilterOptionsLoading] = useState(true);

  const getFilterOptions = async () => {
    const { data: courses } = await client.fetch('courses');
    const { data: classrooms } = await client.fetch('classrooms');

    setFilterOptions({
      courses: courses || [],
      classrooms: classrooms || []
    });
    setFilterOptionsLoading(false);
  };

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      classroom: 'blank',
      bestPerformance: false,
      minorPerformance: false,
      lowerFrequency: false
    }
  });

  useEffect(getFilterOptions, []);

  useEffect(() => {
    getMetricsData(filters.values);
  }, [filters.values]);

  return (
    <>
      <main className="main-content main-content--block fadeIn">
        {loading || filterOptionsLoading ? (
          <Loader />
        ) : (
          <>
            <div className="filter-bar">
              <FilterSelectionBox
                label={t('filter.labelSelectCourse')}
                value={filters.values.course}
                onChange={e => filters.setFieldValue('course', e.target.value)}
                options={filterOptions.courses.map(o => ({
                  id: o.id,
                  name: o.title
                }))}
              />
              <FilterSelectionBox
                label={t('filter.labelSelectClassroom')}
                value={filters.values.classroom}
                onChange={e => filters.setFieldValue('classroom', e.target.value)}
                options={filterOptions.classrooms.map(o => ({
                  id: o.id,
                  name: o.title
                }))}
              />
              <FilterCheckBox
                label="Melhor performace"
                value={filters.values.bestPerformance}
                onChange={e => {
                  filters.setFieldValue('bestPerformance', e.target.value);
                  filters.setFieldValue('minorPerformance', false);
                  filters.setFieldValue('lowerFrequency', false);
                }}
              />
              <FilterCheckBox
                label="Menor performace"
                value={filters.values.minorPerformance}
                onChange={e => {
                  filters.setFieldValue('bestPerformance', false);
                  filters.setFieldValue('minorPerformance', e.target.value);
                  filters.setFieldValue('lowerFrequency', false);
                }}
              />
              <FilterCheckBox
                label="Menor frequência"
                value={filters.values.lowerFrequency}
                onChange={e => {
                  filters.setFieldValue('bestPerformance', false);
                  filters.setFieldValue('minorPerformance', false);
                  filters.setFieldValue('lowerFrequency', e.target.value);
                }}
              />
            </div>

            <div className="tab__cards">
              {metricsData.users &&
                metricsData.users.length > 0 &&
                metricsData.users.map(student => (
                  <StudentCard
                    id={student.id}
                    name={student.name}
                    image={student.image && student.image.url}
                    email={student.email}
                    answers={student['card-data']['engagement-count'].answers}
                    comments={student['card-data']['engagement-count'].comments}
                    lessons={student['card-data'].lessons}
                    performance={student['card-data'].performance}
                  />
                ))}
            </div>
          </>
        )}
      </main>
    </>
  );
};

export * from './MetricsProfile';
