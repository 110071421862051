import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Modal from 'app/components/Modal';
import { useAuditorium } from 'app/hooks/useAuditorium';

export default function FormNameModal(props) {
  const { createLesson } = useAuditorium();
  const { show, onClose, setLessonName, messages, id } = props;

  const { t } = useTranslation();

  const form = useFormik({
    initialValues: {
      name: ''
    },
    onSubmit: values => {
      setLessonName(values.name);
      createLesson(id, values);
      onClose();
    }
  });

  return (
    <Modal
      show={show}
      onClose={onClose}
    >
      <form
        method="post"
        className="form"
        onSubmit={form.handleSubmit}
      >
        <h2 className="modal__simple-name">{'Novo nome'}</h2>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="name"
          >
            {t('textsCommon.lessonName')}
          </label>
          <input
            className="form__control"
            id="name"
            name="name"
            type="text"
            placeholder={t('textsCommon.writeHere')}
            value={form.values.name}
            onChange={form.handleChange}
          />
        </div>

        <button
          className="btn btn--primary"
          type="submit"
        >
          {t('auditorium.buttonLessonName')}
        </button>
      </form>
    </Modal>
  );
}
