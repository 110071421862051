import React from "react";
import QuestionCardOpen from '../components/questionCardOpen';

function GroupedQuestionOpen(props) {
	const { groupedQuestion, updateLastAnswerSubmited, disabledSaveAnswer, questionBooksUser, answers } = props;
	const introduction = groupedQuestion[0]?.modality === 'right_wrong' ? (
		groupedQuestion[0]?.introduction?.body
	) : (
		groupedQuestion[0]?.description
	)

	return (
		<div className="question-card">
			<div className="tag-list">
				{groupedQuestion
					&& groupedQuestion.length > 0
					&& groupedQuestion[0]?.taxonomies
					&& groupedQuestion[0]?.taxonomies.map((taxonomy, index) => (
						index < 6 && <div className="tag" key={`${index}-${taxonomy.id}`}>{taxonomy.name}</div>
					))}
			</div>

			<div className="question-card__introduction" dangerouslySetInnerHTML={{ __html: introduction }} />

			{groupedQuestion && groupedQuestion[0]['motivator-texts'] && groupedQuestion[0]['motivator-texts'].map((motivatorText, index) => (
				<div className="question-card__motivator-text" key={index}>
					<div className="question-card__title" dangerouslySetInnerHTML={{ __html: motivatorText.title }} />
					<div dangerouslySetInnerHTML={{ __html: motivatorText.body }} />
					<div className="question-card__title" dangerouslySetInnerHTML={{ __html: motivatorText.authors.map((author) => (`${author.fullName} -`)) }} />
					<div className="question-card__print" dangerouslySetInnerHTML={{ __html: motivatorText.print }} />
				</div>
			))}

			{groupedQuestion && groupedQuestion.map((question) => {
				const answer = answers.find((a) => a.questionId === question.id)
				return <QuestionCardOpen
					key={question.id}
					question={question}
					activeAnswer={answer}
					questionBooksUser={questionBooksUser}
					updateLastAnswerSubmited={updateLastAnswerSubmited}
					disabledSaveAnswer={disabledSaveAnswer}
				/>
			})}
		</div>
	)
}

export default GroupedQuestionOpen
