import React from 'react';
import { Link, navigate } from '@reach/router';
import { useFormik } from 'formik';
import { useSession } from 'app/hooks/useSession';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { useEffect } from 'react';
import { WarningModal } from 'app/components/Modal/WarningModal';
import { useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import moment from 'moment';
import getPermission from 'app/utils/getPermission';
import IconVerified from 'app/components/Icons/IconVerified';

export default function ModuleCard(props) {
  const { newMessage, module, moduleId, course, showModule, setShowModule, setCurrentModule } = props;
  const client = useClient();
  const { session } = useSession();
  const userId = session.user.id;
  const [showWarning, setShowWarning] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [unpublishWarning, setUnpublishWarning] = useState(false);
  const authorityLevel = getAuthorityLevel();

  const moduleForm = useFormik({
    initialValues: {
      publish: module?.publish
    }
  });

  const newModule = authorityLevel === 'student' && !module?.learning_system_users.includes(item => item.user_id === parseInt(userId));
  const hasLessonInModuleToPublish = module?.learning_system_items_total_count === 0 && !moduleForm.values.publish;
  const isCourseNotPublished = !course.published;

  useEffect(() => {
    moduleForm.setFieldValue('publish', module?.publish);
  }, [module.publish]);

  const updateModulePublicity = async values => {
    setIsSubmitting(true);
    const { error } = await client.mutate(['learning_systems', moduleId], {
      publish: !values.publish
    });
    moduleForm.setFieldValue('publish', !moduleForm.values.publish);
    if (error) {
      toast.error('Erro ao atualizar conteúdo');
    } else {
      if (moduleForm.values.publish) {
        toast.success('Despublicado com sucesso');
      }
      if (!moduleForm.values.publish) {
        toast.success('Publicado com sucesso!');
      }
    }
    setIsSubmitting(false);
    setShowWarning(false);
    setUnpublishWarning(false);
  };

  const handleChange = () => {
    if ((hasLessonInModuleToPublish || isCourseNotPublished) && !moduleForm.values.publish) {
      setShowWarning(true);
    } else if (moduleForm.values.publish) {
      setUnpublishWarning(true);
    } else {
      updateModulePublicity(moduleForm.values);
    }
  };

  const currentUser = useSession()?.session?.user;
  const hasManagePermission = getPermission('Gerenciar conteúdo', 'Cursos');
  const hasCoursePermission = hasManagePermission && (!course?.['authored-by-user'] || (course?.['authored-by-user'] && currentUser.id === course?.['creator-id']) || currentUser?.profileNames.includes('Administrador'));
  const hasModulePermission = hasManagePermission && (!module?.['authored_by_user'] || (module?.['authored_by_user'] && currentUser.id === module?.['creator_id']) || currentUser?.profileNames.includes('Administrador'));

  return (
    <tr
      className="round-dg__row u-cursor-pointer"
      onClick={() => {
        setShowModule(true);
        setCurrentModule(module);
      }}
    >
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <div className="round-dg__user">
          <div className="round-dg__user-inner">
            <h3
              className="round-dg__title"
              title={module?.title}
            >
              {module?.['official_content'] && <IconVerified />}
              {' ' + module?.title}
            </h3>
          </div>
        </div>

        <span className="card__badges u-mb-0">
          {newMessage && <span className="badge badge--tiny badge--warning">{t('lms.tagNewMessage')}</span>}
          {newModule && <span className="badge badge--tiny badge--warning">{t('lms.tagNew')}</span>}
          {module?.progress === 100 && <span className="badge badge--tiny badge--success">{t('textsCommon.tagConcluded')}</span>}
        </span>
      </th>

      <td className="round-dg__cell">{module?.learning_system_items_total_count}</td>

      <td className="round-dg__cell">{moment(module?.['updated_at']).format('DD/MM/YYYY')}</td>

      <td className="round-dg__cell">
        {hasCoursePermission && hasModulePermission && (
          <div
            className="form__check form__switch u-mb-0"
            onClick={e => e.stopPropagation()}
          >
            <input
              className="form__check-input"
              id={`publish-${moduleId}`}
              name={`publish-${moduleId}`}
              type="checkbox"
              checked={moduleForm.values.publish}
              onClick={handleChange}
            />
            <label htmlFor={`publish-${moduleId}`}>{t('lms.publish')}</label>
          </div>
        )}
      </td>

      <td className="round-dg__cell">
        <Link
          className="btn btn--full btn--tiny btn--outline"
          onClick={e => e.stopPropagation()}
          to={`/show-course/${module?.id}/classroom`}
        >
          Assistir aulas
        </Link>
      </td>

      <td className="round-dg__cell">
        <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
          <FiChevronRight className="card__icon" />
        </span>
      </td>

      <WarningModal
        show={showWarning}
        onClose={() => setShowWarning(false)}
        title={`Você tem certeza que deseja publicar o módulo${module?.learning_system_items_total_count === 0 ? ` sem uma aula publicada?` : '?'}`}
        description="Ao publicar o módulo, o curso também será publicado."
        onSubmitFirstButton={() => updateModulePublicity(moduleForm.values)}
        onSubmitSecondButton={() => setShowWarning(false)}
        isSubmitting={isSubmitting}
      />

      <WarningModal
        show={unpublishWarning}
        onClose={() => setUnpublishWarning(false)}
        title={`Ao despublicar, este conteúdo ficará invisível para os alunos.`}
        description="Você pode publicá-lo novamente a qualquer momento. Deseja prosseguir com a despublicação?"
        onSubmitFirstButton={() => updateModulePublicity(moduleForm.values)}
        onSubmitSecondButton={() => setUnpublishWarning(false)}
        isSubmitting={isSubmitting}
      />

      {/* <WarningModal
        show={isOpenModule}
        onClose={() => setIsOpenModule(false)}
        title="O módulo não poderá ser visualizado"
        description="O curso correspondente ao módulo precisa ser publicado. Deseja publicá-lo agora?"
        titleSecondButton="Quero publicar o curso"
        onSubmitFirstButton={() => setIsOpenModule(false)}
        onSubmitSecondButton={handlePublishCourse}
        isSubmitting={isSubmitting}
      /> */}
    </tr>
  );
}
