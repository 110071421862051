import React, { useEffect, useState } from 'react';
import Modal from 'app/components/Modal';
import UserInfo from 'app/components/UserInfo';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';

export default function CustomParticipantsModal(props) {
  const { t } = useTranslation();
  const { show, onClose, selectedParticipants, addParticipant, removeParticipant, loading, participants } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredParticipants, setFilteredParticipants] = useState([]);

  useEffect(() => {
    if (participants.length > 0) {
      let newParticipants = participants;
      if (searchTerm !== '') {
        newParticipants = newParticipants.filter(p => p.name != null && p.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
      }
      setFilteredParticipants(newParticipants);
    }
  }, [searchTerm, participants]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <h3 className="modal__simple-title">{t('courses.addParticpants')}</h3>

      <div className="form__row">
        <label
          htmlFor="participantsSearch"
          className="form__label"
        >
          {t('textsCommon.research')}
        </label>

        <input
          aria-label="Buscar participante"
          className="form__control form__control--search-with-icon"
          style={{ width: 'auto' }}
          placeholder={t('courses.placeholderParticipantName')}
          type="search"
          name="participantsSearch"
          id="participantsSearch"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>

      <table
        className="dg-widget"
        style={{ tableLayout: 'auto' }}
      >
        <tbody
          className="dg-widget__body"
          style={{ maxHeight: '73vh' }}
        >
          {loading && <Loader />}
          {!loading &&
            filteredParticipants.map((participant, index) => {
              const selectedItem = selectedParticipants?.find(p => p.id === participant.id);
              return (
                <tr
                  className="dg-widget__row"
                  key={index}
                >
                  <th
                    className="dg-widget__cell-header"
                    scope="row"
                  >
                    <UserInfo user={participant} />
                  </th>
                  <td className="dg-widget__cell">{participant.email}</td>
                  <td
                    className="dg-widget__cell"
                    style={{ width: '140px' }}
                  >
                    {selectedItem ? (
                      <button
                        className="btn btn--outline btn--full active"
                        onClick={() => {
                          removeParticipant(participant);
                        }}
                      >
                        {t('button.remove')}
                      </button>
                    ) : (
                      <button
                        className="btn btn--outline btn--full"
                        onClick={() => {
                          addParticipant(participant);
                        }}
                      >
                        Adicionar
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Modal>
  );
}
