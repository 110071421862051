import React from 'react';
import { FiChevronDown, FiFileText, FiMoreHorizontal, FiFolder } from 'react-icons/fi';
import moment from 'moment';
import Dropdown from 'app/components/Dropdown';
import { useSession } from 'app/hooks/useSession';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import Swal from 'sweetalert2';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';
import { i18n } from '../i18n/i18n';

export const MaterialsCard = ({ materialFolder, selectFolder, onEditOpen, onDelete }) => {
  const { t } = useTranslation();
  const { session } = useSession();
  const authorityLevel = getAuthorityLevel(session);

  return (
    <tr className="round-dg__row">
      <th
        className="round-dg__cell-header round-dg__cell-header--row"
        style={{ width: '20%' }}
      >
        <div className="card__header">
          <div className="card__inner-header">
            <div className="card__icon-wrapper">
              <FiFolder />
            </div>
            <h2
              className="card__title"
              style={{ flexGrow: 'initial' }}
            >
              {materialFolder.name}
            </h2>
          </div>
        </div>
      </th>

      <td
        className="round-dg__cell"
        style={{ width: '50px' }}
      >
        <p
          className="card__count"
          title={`${materialFolder.contents.length} arquivos`}
          aria-label={`${materialFolder.contents.length} arquivos`}
        >
          <FiFileText className="card__icon" /> {materialFolder.contents.length}
        </p>
      </td>
      <td
        className="round-dg__cell"
        style={{ width: '100px' }}
      >
        <p className="card__info">
          <span>{t('materials.lastChange')} </span>
          {moment(materialFolder['updated-at']).format(t('datesForm.formatDate'))}
        </p>
      </td>

      <td
        className="round-dg__cell"
        style={{ width: '110px' }}
      >
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          <button
            type="button"
            onClick={selectFolder}
            className="btn btn--outline btn--small"
          >
            {t('materials.buttonAccessMaterials')}
          </button>

          {getPermission('Editar materiais', 'Arquivos e atividades complementares') || getPermission('Excluir materiais', 'Arquivos e atividades complementares') ? (
            <Dropdown className="btn--small btn btn--outline">
              <Dropdown.Toggle>
                <FiChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Content>
                {getPermission('Editar materiais', 'Arquivos e atividades complementares') && <Dropdown.Item onClick={onEditOpen}>{t('button.edit')}</Dropdown.Item>}
                {getPermission('Excluir materiais', 'Arquivos e atividades complementares') && <Dropdown.Item onClick={onDelete}>{t('button.delete')}</Dropdown.Item>}
              </Dropdown.Content>
            </Dropdown>
          ) : null}
        </div>
      </td>
    </tr>
  );
};
