import React, { useEffect, useState } from 'react';
import EmptyState from 'app/components/EmptyState';
import { Link } from '@reach/router';
import QuizModal from './QuizModal';
import QuizDataGrid from './QuizDataGrid';
import { useClient } from 'jsonapi-react';

export default function Quiz() {
  const [showModal, setShowModal] = useState(false);
  const [quizData, setQuizData] = useState([]);

  const client = useClient();

  const getQuizData = async () => {
    const response = await client.fetch('study_plan_reports/quiz_performance');
    return response.data;
  }

  useEffect(() => {
    getQuizData().then((data) => {
      if(data.quiz_performance) setQuizData(data.quiz_performance);
    });
  }, []);

  return (
    <div className="panorama-card">
      <div className="panorama-card__header">
        <h3 className="panorama-card__title">Rendimento nos quizzes das aulas</h3>
      </div>

      {quizData.length === 0 && (
        <div className="panorama-card__body panorama-card__body--empty-state">
          <EmptyState
            type="data"
            title="Você ainda não respondeu nenhum quiz"
            text={
              <span>
                Assista uma <Link to="/curso">aula</Link> para responder um quiz
              </span>
            }
            bgless
          />
        </div>
      )}

      {quizData.length > 0 && (
        <>
          <div className="panorama-card__body">
            <QuizDataGrid data={quizData.slice(0,6)} />
          </div>

          <div className="panorama-card__footer">
            <button
              type="button"
              className="btn btn--tiny btn--link"
              onClick={() => setShowModal(true)}
            >
              Ver mais
            </button>

            <QuizModal
              show={showModal}
              onClose={() => setShowModal(false)}
              data={quizData}
            />
          </div>
        </>
      )}
    </div>
  );
}
