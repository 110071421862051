import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { Link, useParams } from '@reach/router';
// eslint-disable-next-line import/no-unresolved
import CourseCover from 'app/images/placeholders/show-course.jpg';
// eslint-disable-next-line import/no-unresolved
import AvatarPlaceholder from 'app/images/placeholders/user-avatar.svg';
import Loader from '../../components/loader';
import { useTranslation } from 'react-i18next';

function ShowCourse() {
  const { t } = useTranslation();
  const client = useClient();
  const { id } = useParams();
  const [learningSystem, setLearningSystem] = useState([]);
  const [loading, setLoading] = useState(true);

  const getLearnSystem = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`learning_systems/${id}`);

    setLoading(false);
    if (error) {
      toast.error(t('toast.errorGetCourse'));
    } else {
      setLearningSystem(data);
    }
  };

  useEffect(() => {
    getLearnSystem();
  }, []);

  return (
    <>
      <main className="main-content main-content--block">
        {loading ? (
          <Loader />
        ) : (
          <div className="show-course fadeIn">
            <div
              className="show-course-banner"
              style={{
                backgroundImage: `url(${learningSystem.image?.url || CourseCover})`
              }}
            >
              <div className="card-badges">
                <span className="badge badge--tiny badge--warning">{t('lms.tagNew')}</span>
              </div>
              <div className="show-course-banner__title">{learningSystem && learningSystem.title}</div>
              <div className="show-course-banner__description">{learningSystem && learningSystem['short-description']}</div>
              <div className="show-course-banner__cta">
                <Link
                  to={`/show-course/${id}/classroom`}
                  className="btn btn--primary btn--wide btn--highliht"
                >
                  {t('lms.buttonStartModule')}
                </Link>
              </div>
            </div>

            <div className="show-course-main-content">
              <div className="tag-list">
                {(learningSystem?.taxonomies || []).map(taxonomy => (
                  <div className="tag">{taxonomy.name}</div>
                ))}
              </div>

              <span className="expanded-calendar__tag expanded-calendar__tag--course">Curso</span>

              <div className="show-course-section">
                <div className="show-course-section__title">{t('lms.titleAboutModule')}</div>
                <div className="show-course-section__description">{learningSystem && learningSystem.description}</div>
              </div>

              <div className="show-course-section">
                <div className="show-course-section__title">{t('lms.titleWhatLearn')}</div>
                <div className="show-course-section__description">
                  {(learningSystem['learning-system-subjects'] || []).map(learningSystemubject => (
                    <>
                      • {learningSystemubject.body}
                      <br />
                    </>
                  ))}
                </div>
              </div>

              <div className="show-course-section">
                <div className="show-course-section__title">Requisito</div>
                <div className="card-badges">
                  <span className="badge badge--primary">Direitos Humanos</span>
                </div>
              </div>

              {learningSystem['learning-system-requirements'] && learningSystem['learning-system-requirements'].length > 0 && (
                <div className="show-course-section">
                  <div className="show-course-section__title">Há algum requisito para o curso?</div>
                  <div className="tag-list">
                    {learningSystem['learning-system-requirements'].map(lsr => (
                      <div className="tag">{lsr.title}</div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="show-course-sidebar">
              <div
                className="show-course-sidebar__avatar"
                style={{
                  backgroundImage: `url(${learningSystem?.user?.image?.thumb?.url || AvatarPlaceholder})`
                }}
              />
              <div className="show-course-sidebar__user-infon">
                <div className="show-course-sidebar__user-name">{learningSystem?.user?.name}</div>
                <div className="show-course-sidebar__user-description">{learningSystem?.user?.bio}</div>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
}

export default ShowCourse;
