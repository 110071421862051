import { useContext } from 'react';
import { NoteContext } from 'app/providers/NoteProvider';

export const useNote = () => {
  const context = useContext(NoteContext);

  if (!context) {
    throw new Error(
      'useNote must be used within a NoteProvider.'
    );
  }

  return context;
};