import React, { useEffect, useState } from 'react';
import BreadCrumbs from 'app/components/BreadCrumbs';
import { FiDownload, FiPlus } from 'react-icons/fi';
import { RiProhibited2Line } from "react-icons/ri";
import MatterModal from './MatterModal';
import MatterDataGrid from './MatterDataGrid';
import { useClient } from 'jsonapi-react';
import xlsx from 'json-as-xlsx';

export default function StudyGuideSettings(props) {
  const { uri } = props;

  const [showModal, setShowModal] = useState(false);
  const [taxonomies, setTaxonomies] = useState([]);
  const [modalTaxonomies, setModalTaxonomies] = useState([]);

  const client = useClient();

  const getUserTaxonomies = async () => {
    const response = await client.fetch('user_taxonomies');
    return response.data;
  }

  useEffect(() => {
    getUserTaxonomies().then((data) => {
      const ableTaxonomies = data.filter(taxonomy => taxonomy.status !== "disabled");
      const modalTaxonomies = data.filter(taxonomy => taxonomy.status === "disabled");
      setTaxonomies(ableTaxonomies);
      setModalTaxonomies(modalTaxonomies);
    });
  }, [showModal]);

  const breadCrumbs = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: '/guia-de-estudos',
        name: 'Guia de estudos',
        isActive: false
      },
      {
        route: uri,
        name: 'Customização do Guia',
        isActive: true
      }
    ]
  };

  const xlsxStructure = [
    {
      sheet: 'Matérias',
      columns: [
        { label: 'Matéria', value: 'Matéria' },
        { label: 'Submatéria', value: 'Submatéria' },
      ],
      content: taxonomies?.map(item => ({
        Matéria: item['taxonomy-name'],
        Submatéria: item['taxonomy-children'].map(item => item.name).join(', '),
      }))
    }
  ];

  const handleDownloadCSV = () => {
    try {
      xlsx(xlsxStructure);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <div className="custom-guide">
        <div className="custom-guide__header">
          <h1 className="custom-guide__title">
            Aqui estão as <strong>{taxonomies.length} matérias</strong> que já estão no seu Guia.
          </h1>
          
          {false && (
            <button
              type="button"
              className="btn btn--outline"
            >
              <RiProhibited2Line className="btn__icon btn__icon--md" />
              Bloquear matérias
            </button>
          )}
          <button
            type="button"
            className="btn btn--outline"
            onClick={handleDownloadCSV}
          >
            <FiDownload className="btn__icon btn__icon--md" />
            Baixar
          </button>
        </div>

        <div className="custom-guide__body">
          <p className="custom-guide__text">Você pode adicionar, remover ou bloquear conforme suas prioridades. As mudanças entram em vigor na próxima semana</p>

          <button
            type="button"
            className="btn btn--outline-dashed btn--small btn--full"
            onClick={() => setShowModal(true)}
          >
            <FiPlus className="btn__icon" /> Adicionar outras matérias
          </button>

          {modalTaxonomies && <MatterModal
            show={showModal}
            onClose={() => setShowModal(false)}
            taxonomies={modalTaxonomies}
          />}

          {taxonomies && <MatterDataGrid data={taxonomies} />}
        </div>
      </div>
    </main>
  );
}
