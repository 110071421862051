import React, { useEffect, useState } from 'react';
import { FiStar } from 'react-icons/fi';
import InfoWidget from 'app/components/Widget/InfoWidget';
import QuestionChart from './QuestionChart';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import Loader from 'app/components/loader';
import { t } from 'i18next';

export default function GeneralData(props) {
  const { surveyData, loading } = props;
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [surveyReport, setSurveyReport] = useState([]);
  const [surveyQuestions, setSurveyQuestions] = useState([]);

  useEffect(() => {
    getSurveyReport();
    getSurveyQuestions();
  }, [surveyData]);

  const getSurveyReport = async () => {
    if (surveyData.id) {
      setIsLoading(true);
      const { data } = await client.fetch(['surveys', surveyData.id, 'report']);
      setSurveyReport(data);
      setIsLoading(false);
    }
  };

  const getSurveyQuestions = async () => {
    if (surveyData.id) {
      setIsLoading(true);
      const { data } = await client.fetch(`survey_questions?filter[survey_id]=${surveyData.id}`);
      setSurveyQuestions(data);
      setIsLoading(false);
    }
  };

  const quizStatusClass = () => {
    switch (surveyData?.about?.status) {
      case 'Inativo':
        return 'activity-card__status--danger';
        break;
      case 'Ativo':
        return 'activity-card__status--success';
        break;
    }
  };

  return (
    <div className="chart-widget fadeIn u-mb-3">
      <div
        className="chart-widget__container u-mb-4"
        style={{ alignItems: 'center' }}
      >
        <div
          className="chart-widget__group"
          style={{ gap: '0' }}
        >
          <div className="chart-widget__meta">
            <span className={`activity-card__status ${quizStatusClass()} u-mr-2`}>{surveyData?.about?.status}</span>
          </div>

          <h3 className="chart-widget__title chart-widget__title--lg">{surveyData?.title}</h3>
          <span className="activity-card__scale">
            {t('nps.graphicScale')}: <FiStar />
          </span>
        </div>
        {loading && <Loader />}
        <div className="widget-wrapper widget-wrapper--sm-space">
          <InfoWidget
            title={t('nps.totalResponses')}
            className="info-widget--light"
          >
            <InfoWidget.Value>{surveyData?.about?.['answers-count']}</InfoWidget.Value>
          </InfoWidget>

          <InfoWidget
            title={t('nps.totalQuestions')}
            className="info-widget--light"
          >
            <InfoWidget.Value>{surveyData?.about?.['questions-count']}</InfoWidget.Value>
          </InfoWidget>
        </div>
      </div>

      <div className="question-chart-wrapper">
        {surveyReport.map((surveyQuestion, i) => (
          <QuestionChart
            key={i}
            surveyReport={surveyReport}
            surveyQuestion={surveyQuestion}
            surveyData={surveyData}
          />
        ))}
      </div>
    </div>
  );
}
