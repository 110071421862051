import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { useProgramOfStudies } from 'app/hooks/useProgramOfStudies';
import EmptyState from 'app/components/EmptyState';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

export const ContentQuestion = () => {
  const { t } = useTranslation();
  const { selectedQuestion, selectedTaxonomy, updateQuestion, firstLevelTaxonomies } = useProgramOfStudies();

  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);

  const updateQuestionForm = useFormik({
    initialValues: {
      description: '',
      'motivator-texts-attributes': [
        {
          title: '',
          body: '',
          print: ''
        }
      ],
      'alternatives-attributes': [],
      selectedTaxonomies: []
    },
    onSubmit: values => updateQuestion(values)
  });

  const { values, setFieldValue, handleChange, submitForm } = updateQuestionForm;

  useEffect(() => {
    if (selectedQuestion) {
      updateQuestionForm.setValues({
        description: selectedQuestion.description || '',
        'motivator-texts-attributes': [
          selectedQuestion['motivator-texts'][0] || {
            title: '',
            body: '',
            print: ''
          }
        ],
        'alternatives-attributes': selectedQuestion.alternatives.filter(a => a.description !== 'Deixar em branco'),
        selectedTaxonomies: selectedQuestion.taxonomies || [],
        'correct-alternative': selectedQuestion['correct-alternative']
      });
    }
  }, [selectedQuestion]);

  return (
    <div className="new-questions__content">
      {!selectedQuestion ? (
        <EmptyState
          type="select"
          text={t('emptyState.selectSubjectPerformanceText')}
        />
      ) : (
        <>
          <div className="new-questions__header">
            <h1 className="new-questions__title">{selectedTaxonomy.name}</h1>
          </div>

          <div className="new-questions__body">
            <p>{selectedQuestion.modality === 'right_wrong' ? 'Certo ou errado' : 'Múltipla escolha'}</p>
            <h2 className="new-questions__subtitle">Questão #{selectedQuestion.id}</h2>

            <div className="new-questions__page">
              <div className="new-questions__col">
                <div
                  action="#"
                  className="form fadeIn"
                >
                  <div className="form__row">
                    <label htmlFor="title">{t('textsCommon.title')}</label>
                    <input
                      className="form__control"
                      id="title"
                      name="title"
                      type="text"
                      value={values['motivator-texts-attributes'][0].title}
                      onChange={e =>
                        setFieldValue('motivator-texts-attributes', [
                          {
                            ...values['motivator-texts-attributes'][0],
                            title: e.target.value
                          }
                        ])
                      }
                    />
                  </div>

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="textoMotivador"
                    >
                      {t('exams.formTitleMotivatorText')}
                    </label>
                    <TextareaAutosize
                      id="textoMotivador"
                      name="textoMotivador"
                      value={values['motivator-texts-attributes'][0].body}
                      onChange={e =>
                        setFieldValue('motivator-texts-attributes', [
                          {
                            ...values['motivator-texts-attributes'][0],
                            body: e.target.value
                          }
                        ])
                      }
                    />
                  </div>

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="imprenta"
                    >
                      Imprenta
                    </label>
                    <input
                      className="form__control"
                      id="imprenta"
                      name="imprenta"
                      type="text"
                      value={values['motivator-texts-attributes'][0].print}
                      onChange={e =>
                        setFieldValue('motivator-texts-attributes', [
                          {
                            ...values['motivator-texts-attributes'][0],
                            print: e.target.value
                          }
                        ])
                      }
                    />
                  </div>

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="description"
                    >
                      Enunciado
                    </label>
                    <TextareaAutosize
                      id="description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                  </div>

                  <fieldset className="form__row">
                    <legend className="form__label">Alternativas (Escolha a alternativa correta)</legend>

                    {values['alternatives-attributes'].length > 0 &&
                      values['alternatives-attributes'].map(a => (
                        <div className="form__check">
                          <input
                            type="radio"
                            name="alternativa"
                            id={a.id}
                            className="form__check-input"
                            checked={values['correct-alternative'] && values['correct-alternative'].id === a.id}
                            onChange={e => {
                              if (e.target.value === 'on') {
                                setFieldValue('correct-alternative', values['alternatives-attributes'].filter(al => al.id === a.id)[0]);
                              }
                            }}
                          />
                          <span className="form__check-label">
                            <input
                              type="text"
                              name="textoAlternativa1"
                              id="textoAlternativa1"
                              className="form__control form__control--small"
                              placeholder="Insira o texto da alternativa"
                              value={a.description}
                            />
                          </span>
                        </div>
                      ))}
                  </fieldset>

                  {/** <fieldset className="form__row">
										<legend className="form__label">
											Dificuldade da questão
										</legend>

										<div
											className="btn-group"
											role="group"
											aria-label="Escolha o nível de dificuldade"
										>
											<input
												type="radio"
												name="dificuldade"
												id="facil"
												autoComplete="off"
												defaultChecked
											/>
											<label htmlFor="facil" className="btn btn--outline">
												Fácil
											</label>

											<input
												type="radio"
												name="dificuldade"
												id="medio"
												autoComplete="off"
											/>
											<label htmlFor="medio" className="btn btn--outline">
												Médio
											</label>

											<input
												type="radio"
												name="dificuldade"
												id="dificil"
												autoComplete="off"
											/>
											<label htmlFor="dificil" className="btn btn--outline">
												Difícil
											</label>
										</div>
										</fieldset> **/}

                  <fieldset className="form__row">
                    <legend className="form__label">{t('tasks.mattersRelated')}</legend>
                    {values.selectedTaxonomies.length > 0 &&
                      values.selectedTaxonomies.map(t => (
                        <div className="form__check form__check--inline">
                          <input
                            className="form__check-input"
                            type="checkbox"
                            checked
                            onChange={() => {
                              const newSelectedTaxonomies = values.selectedTaxonomies.filter(t2 => t.id !== t2.id);

                              setFieldValue('selectedTaxonomies', newSelectedTaxonomies);
                            }}
                          />
                          <label className="form__check-label">{t.name}</label>
                        </div>
                      ))}
                    <br />
                    <button
                      type="button"
                      className="btn btn--primary"
                      onClick={() => setIsOpenModalTaxonomies(true)}
                    >
                      {values.selectedTaxonomies.length > 0 ? 'Editar matérias' : 'Adicionar matérias'}
                    </button>
                  </fieldset>

                  <button
                    type="submit"
                    className="btn btn--primary btn--full"
                    onClick={() => submitForm()}
                    style={{ marginTop: 50 }}
                  >
                    {t('button.save')}
                  </button>
                </div>
              </div>

              <div className="new-questions__aside">
                <div className="new-questions__info">
                  <p>Questões com o mesmo texto motivador serão automáticamente agrupadas pelo sistema.</p>
                  <p>Tudo está sendo salvo, as alterações são salvas em backgound pelo sistema</p>
                  <p>
                    Para destacar uma citação nos textos e nas altarnativas use a tag: <br />
                    <b>{'<destaq></destaque>'}</b>
                    <br />
                    <br />
                    <b className="new-questions__tag-example">{'..<destaq> O rei de roma </destaque>...'}</b>
                  </p>
                </div>
              </div>

              {firstLevelTaxonomies.length > 0 && isOpenModalTaxonomies && (
                <ModalTaxonomies
                  taxonomies={firstLevelTaxonomies}
                  selectedTaxonomies={values.selectedTaxonomies}
                  setFieldValue={setFieldValue}
                  closeModal={() => setIsOpenModalTaxonomies(false)}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
