import React, { useEffect, useState } from 'react';
import { FiChevronLeft, FiMoreHorizontal, FiPlus } from 'react-icons/fi';
import MainSidebar from 'app/components/Sidebar';
import Dropdown from 'app/components/Dropdown';
import { Link, useLocation } from '@reach/router';
import { useClient } from 'jsonapi-react';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { navigate } from '@reach/router';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { useCourse } from 'app/hooks/useCourse';
import { useTranslation } from 'react-i18next';

export default function Sidebar({ moduleId, setSelectedModule, selectedModule, setManageModules, loading, setModuleId }) {
  const { t } = useTranslation();
  const { courses, getSimpleCourses } = useCourse();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentCourse, setCurrentCourse] = useState(null);
  const [modules, setModules] = useState([]);
  const [filteredModules, setFilteredModules] = useState([]);
  const client = useClient();

  const getModules = async () => {
    const { data } = await client.fetch('/learning_systems');
    setModules(data);
  };

  useEffect(() => {
    setFilteredModules(modules.filter(item => item['course-id'] == currentCourse));
  }, [currentCourse, moduleId]);

  const deleteModule = async item => {
    const { error } = await client.delete(`learning_systems/${item.id}`);
    if (error) {
      toast.error(t('toast.errorDeleteModule'));
    } else {
      toast.success(t('toast.successDeleteModule'));
      setModuleId(null);
      const newModules = modules.filter(m => m.id !== item.id);
      setModules(newModules);
      setFilteredModules(newModules.filter(item => item['course-id'] == currentCourse));
      setSelectedModule(null);
    }
  };

  const handleDelete = item => {
    Swal.fire({
      title: t('projects.warningDeleteModule'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteModule(item);
      }
    });
  };

  useEffect(() => {
    getSimpleCourses();
  }, []);

  useEffect(() => {
    getModules();
  }, [courses]);

  useEffect(() => {
    setFilteredModules(
      filteredModules.filter(module => {
        if (searchTerm == '') {
          return module;
        }
        if (module.title != null && module.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
          return module;
        }
      })
    );
  }, [searchTerm]);

  useEffect(() => {
    if (moduleId && modules.length > 0) {
      const currentModule = modules?.find(item => item.id == moduleId);
      const currentCourse = currentModule?.['course-id'];
      setCurrentCourse(currentCourse);
      setSelectedModule(currentModule);
    }
  }, [modules]);

  return (
    <MainSidebar hasBrand={false}>
      <nav className="notebook-sidebar__nav">
        <div className="filter-bar">
          <Link
            to="/lms"
            className="filter-bar__back"
          >
            <div className="card__icon-wrapper">
              <FiChevronLeft />
            </div>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{t('textsCommon.modules')}</span>
            </span>
          </Link>
        </div>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="courseId"
          >
            {t('filter.labelSelectCourse')}
          </label>

          <select
            className="form__select"
            name="courseId"
            id="courseId"
            value={currentCourse}
            onChange={e => setCurrentCourse(e.target.value)}
          >
            <option value="blank">{t('filter.labelSelectCourse')}</option>
            {courses?.map(item => (
              <option value={item.id}>{item.title}</option>
            ))}
          </select>
        </div>

        <div className="form__row">
          <input
            aria-label="Pesquisar módulos"
            className="form__control form__control--search-with-icon"
            placeholder={t('textsCommon.research')}
            type="search"
            name="moduleSearch"
            id="moduleSearch"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>

        <button className="btn btn--outline-dashed btn--small btn--full u-mb-3">
          <Link to={'/lms/criar/modulo/curso/'}>
            <FiPlus className="btn__icon" /> {t('button.newModule')}
          </Link>
        </button>

        {currentCourse === null && (
          <EmptyState
            type="content"
            title="Nenhum curso selecionado."
            text="Selecione um acima."
          />
        )}

        {currentCourse !== null && !filteredModules.length > 0 && (
          <EmptyState
            type="content"
            title="Nenhum módulo encontrado."
            text="Crie um acima."
          />
        )}

        <ul className="module-list">
          {filteredModules.map(item => {
            return (
              <li
                onClick={() => {
                  window.history.pushState({ moduleId: item.id }, '', `/lms/gerenciar/modulo/${item.id}`);
                  setSelectedModule(item);
                  setManageModules(false);
                }}
                className={selectedModule?.id == item.id ? 'module-list__item active' : 'module-list__item'}
              >
                <h3 className="module-list__title">{item.title}</h3>
                <Dropdown>
                  <Dropdown.Toggle>
                    <FiMoreHorizontal />
                  </Dropdown.Toggle>

                  <Dropdown.Content>
                    <Dropdown.Item
                      onClick={() => {
                        navigate(`/show-course/${item.id}/classroom`);
                      }}
                    >
                      {t('button.view')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate(`/lms/criar/modulo/curso/${item.id}`);
                      }}
                    >
                      {t('button.edit')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        handleDelete(item);
                      }}
                    >
                      {t('button.delete')}
                    </Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown>
              </li>
            );
          })}
        </ul>
      </nav>
    </MainSidebar>
  );
}
