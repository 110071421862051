import React from 'react';
import PositionCard from './PositionCard';
import { ReportLegendWrapper } from 'app/components/ReportLegend';

export default function RankingWidget(props) {
  const { setSelectedPositions } = props;

  const highEvaluation = [
    {
      id: 1,
      name: 'Diretor'
    },
    {
      id: 2,
      name: 'Diretor'
    },
    {
      id: 3,
      name: 'Diretor'
    },
    {
      id: 4,
      name: 'Diretor'
    },
    {
      id: 5,
      name: 'Diretor'
    },
    {
      id: 6,
      name: 'Diretor'
    },
    {
      id: 7,
      name: 'Diretor'
    },
    {
      id: 8,
      name: 'Diretor'
    },
    {
      id: 9,
      name: 'Diretor'
    },
    {
      id: 10,
      name: 'Diretor'
    }
  ];

  const lowEvaluation = [
    {
      id: 28,
      name: 'Diretor'
    },
    {
      id: 29,
      name: 'Diretor'
    },
    {
      id: 30,
      name: 'Diretor'
    },
    {
      id: 31,
      name: 'Diretor'
    },
    {
      id: 32,
      name: 'Diretor'
    },
    {
      id: 33,
      name: 'Diretor'
    },
    {
      id: 34,
      name: 'Diretor'
    },
    {
      id: 35,
      name: 'Diretor'
    },
    {
      id: 36,
      name: 'Diretor'
    },
    {
      id: 37,
      name: 'Diretor'
    }
  ];

  return (
    <section className="report__section">
      <div className="report-widget">
        <div className="report-widget__header">
          <h3 className="report-widget__title">Ranking de cargos</h3>
        </div>

        <div className="report-widget__body">
          <div className="report-position-card-wrapper">
            <PositionCard
              title="Alta  avaliação"
              highTitle
              data={highEvaluation}
              setSelectedPositions={setSelectedPositions}
            />
            <PositionCard
              title="Baixa  avaliação"
              lowTitle
              data={lowEvaluation}
              setSelectedPositions={setSelectedPositions}
            />
          </div>
        </div>
      </div>

      <ReportLegendWrapper />
    </section>
  );
}
