import React, { useState, useEffect } from 'react';
import { FiMenu } from 'react-icons/fi';
import finalCongrats from './img/final-congrats.png';

import '../styles.scss';

const ConceptToCharacteristic = ({ interactive_challenges }) => {
  const themes = interactive_challenges.map((challenge, index) => ({
    concept: { id: `concept_${index}`, name: challenge.key_element },
    educative_feedback: challenge.educative_feedback,
    characteristics: challenge.options.map((option, optionIndex) => ({
      id: `option_${index}_${optionIndex}`,
      text: option,
      correct: option === challenge.correct_answer
    }))
  }));

  const [currentThemeIndex, setCurrentThemeIndex] = useState(0);
  const [shuffledCharacteristics, setShuffledCharacteristics] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [shake, setShake] = useState(false);
  const [correctlyDropped, setCorrectlyDropped] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const [showFinalCongrats, setShowFinalCongrats] = useState(false);

  useEffect(() => {
    shuffleCharacteristics();
  }, [currentThemeIndex]);

  const shuffleCharacteristics = () => {
    const theme = themes[currentThemeIndex];
    setShuffledCharacteristics(shuffleArray([...theme.characteristics]));
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    document.querySelectorAll('.characteristic-card').forEach(el => el.classList.remove('highlight-dropzone'));
  };

  const handleDrop = characteristic => {
    if (characteristic.correct) {
      setCorrectlyDropped(true);
      setShowCongrats(true);
    } else {
      setShake(true);
      setTimeout(() => setShake(false), 500);
    }
    setIsDragging(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.currentTarget.classList.add('highlight-dropzone');
  };

  const handleDragLeave = e => {
    e.currentTarget.classList.remove('highlight-dropzone');
  };

  const handleNext = () => {
    setCorrectlyDropped(false);
    setShowCongrats(false);

    if (currentThemeIndex + 1 < themes.length) {
      setCurrentThemeIndex(prevIndex => prevIndex + 1);
    } else {
      setShowFinalCongrats(true);
    }
  };

  return (
    <div
      className="concept-to-characteristic-container"
      style={{ background: !showFinalCongrats ? '#fafafa' : '#F1F5FA' }}
    >
      {!showFinalCongrats ? (
        <>
          <div
            className={`instructions instructions-container`}
            style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingRight: 10, background: '#fff' }}
          >
            <span>{!showCongrats ? 'Clique, arraste e solte o conceito em cima da explicação correta' : 'Parabéns'}</span>
            <span> {`${currentThemeIndex + 1}/${shuffledCharacteristics.length}`}</span>
          </div>
          <div className="concept-container">
            <div
              className={`concept-card ${shake ? 'shake' : ''} ${correctlyDropped ? 'hidden' : ''}`}
              draggable={!correctlyDropped}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
              <p>{themes[currentThemeIndex].concept.name}</p>
              <FiMenu className="concept-card-icon" />
            </div>
          </div>
          <div className="characteristics-container">
            {shuffledCharacteristics.map(characteristic => (
              <div
                key={characteristic.id}
                className={`characteristic-card ${correctlyDropped && characteristic.correct ? 'stacked' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={() => handleDrop(characteristic)}
              >
                {characteristic.text}
              </div>
            ))}
          </div>
          {showCongrats && (
            <>
              <div className="congrats-message">{themes[currentThemeIndex]?.educative_feedback}</div>
              <button
                onClick={handleNext}
                className="btn btn--primary btn--wide"
              >
                {currentThemeIndex + 1 === themes.length ? 'Finalizar' : 'Próximo Desafio'}
              </button>
            </>
          )}
        </>
      ) : (
        <div className="final-congrats-message">
          <img
            src={finalCongrats}
            style={{ width: 100, height: 100, marginTop: 40, marginBottom: 20 }}
          />
          <h2>Parabéns!</h2>
          <p>Você completou todos os desafios!</p>
        </div>
      )}
    </div>
  );
};

const shuffleArray = array => {
  return array.sort(() => Math.random() - 0.5);
};

export default ConceptToCharacteristic;
