import MultiSelect from 'app/components/MultiSelect';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Select from 'react-select';

const RequirementStep = props => {
  const { t } = useTranslation();
  const { values, modules, setFieldValue, setIsOpenModalTaxonomies, currentModule, setRemovedItems, removedItems } = props;

  return (
    <div className="page__content fadeIn u-mb-5">
      <div className="new-questions__header">
        <h2 className="new-questions__title">{values?.title}</h2>
      </div>

      <fieldset className="form__row">
        <legend className="form__label">{t('lms.requirements')}</legend>

        <Select
          openMenuOnFocus
          value={values.selectedRequirements}
          onChange={selected => {
            const removed = values.selectedRequirements.filter(item => !selected.includes(item));
            setRemovedItems([...removedItems, ...removed]);
            setFieldValue('selectedRequirements', selected);
          }}
          isMulti
          options={modules
            .map(
              e =>
                currentModule?.id !== e.id && {
                  label: e.title,
                  value: e.id
                }
            )
            .filter(e => e)}
          className="react-multi-select"
          classNamePrefix="react-multi-select"
          placeholder={t('lms.placeholderSelectRequirements')}
          noOptionsMessage={() => 'Sem opções'}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: () => null
          }}
        />
      </fieldset>

      <fieldset className="form__row">
        <legend className="form__label">{t("tasks.mattersRelated")}</legend>

        <MultiSelect
          selectedTaxonomies={values?.selectedTaxonomies}
          setFieldValue={setFieldValue}
          openModal={() => setIsOpenModalTaxonomies(true)}
          placeholder={t("tasks.mattersAdd")}
        />
      </fieldset>

      {/* <fieldset className="form__row">
    <legend className="form__label">
      Imagem (1050 x 360 pixels)
    </legend>

    <label className="form__custom-file-input">
      <input
        type="file"
        name="image"
        id="image"
        accept=".jpeg, .png, .jpg"
        onChange={handleChangeImage}
        defaultValue={values.image}
      />
      <span className="form__control">
        {imageLabel
          ? imageLabel
          : 'Clique para fazer upload de uma imagem'}
      </span>
    </label>
  </fieldset> */}
    </div>
  );
};

export default RequirementStep;
