import React, { useState, useEffect, useMemo } from 'react';
import { FiChevronLeft, FiCheck, FiChevronDown, FiUser, FiUsers, FiHelpCircle, FiChevronUp } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import ViewGRE from './ViewGRE';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import { useQuery } from 'jsonapi-react';

export default function RankingByRegion() {
  const [showRegional, setShowRegional] = useState(false);
  const [selectedRegional, setSelectedRegional] = useState(null);
  const [sortDirection, setSortDirection] = useState('desc');

  const { data: regionals, isLoading, error } = useQuery('/challenges/ranking');
  const { data: institutions, isLoading: loadingInstitutions, error: errorInstitutions } = useQuery(selectedRegional ? `/challenges/ranking?filter[regional_id]=${selectedRegional.id}` : null);

  const ordenedRegionals = useMemo(
    () =>
      regionals?.data
        ?.filter(item => item.name !== 'Sem Vinculo' && item.name !== 'Já sou formado' && item.name !== 'Sem escola')
        ?.sort((a, b) => {
          if (sortDirection === 'asc') {
            return a.score - b.score;
          }
          if (sortDirection === 'desc') {
            return b.score - a.score;
          }
        }),
    [regionals]
  );

  const handleSelectRegional = regional => {
    setShowRegional(true);
    setSelectedRegional(regional);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="widget-wrapper">
      {!isLoading && regionals?.length === 0 ? (
        <EmptyState
          type="data"
          text="Nenhuma GRE encontrada"
        />
      ) : (
        <div className="widget">
          <div className="widget__header">
            <h3 className="widget__title u-mb-4">Ranking</h3>

            {showRegional && (
              <div className="filter-bar">
                <div className="filter-bar__inner">
                  <button
                    className="filter-bar__back filter-bar__back--disabled"
                    style={{ alignSelf: 'center' }}
                    onClick={() => setShowRegional(false)}
                  >
                    <span className="filter-bar__back-container">
                      <span className="filter-bar__title">GREs</span>
                    </span>
                  </button>
                  <button
                    className="filter-bar__back"
                    onClick={() => setShowRegional(false)}
                  >
                    <span className="card__icon-wrapper">
                      <FiChevronLeft className="card__icon" />
                    </span>
                    <span className="filter-bar__back-container">
                      <span className="filter-bar__title">{selectedRegional?.name}</span>
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="widget__body">
            {!showRegional ? (
              <div className="round-dg-wrapper">
                <table
                  className="round-dg round-dg--light"
                  style={{ minWidth: '480px' }}
                >
                  <thead className="round-dg__header">
                    <tr className="round-dg__row">
                      <th
                        className="round-dg__cell-header round-dg__cell-header--sm"
                        style={{ width: '80px' }}
                      >
                        Posição
                      </th>
                      <th
                        className="round-dg__cell-header round-dg__cell-header--sm"
                        style={{ width: '44px' }}
                      />
                      <th className="round-dg__cell-header round-dg__cell-header--sm">GRE</th>
                      <th
                        className="round-dg__cell-header round-dg__cell-header--sm u-text-right"
                        style={{ width: '124px' }}
                      >
                        Pontuação total{' '}
                        <span
                          onClick={() => setSortDirection(prev => (prev === 'desc' ? 'asc' : 'desc'))}
                          className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
                        >
                          {sortDirection === 'desc' ? <FiChevronDown className="card__icon" /> : <FiChevronUp className="card__icon" />}
                        </span>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="round-dg__body">
                    {ordenedRegionals?.map((item, i) => (
                      <tr
                        className="round-dg__row u-cursor-pointer"
                        onClick={() => handleSelectRegional(item)}
                        key={i}
                      >
                        <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{i + 1}</th>
                        <td className="round-dg__cell round-dg__cell--sm">
                          {sortDirection === 'desc' && i === 0 && <AiOutlineTrophy className="card__icon card__icon--golden" />}
                          {sortDirection === 'desc' && i === 1 && <AiOutlineTrophy className="card__icon card__icon--silver" />}
                          {sortDirection === 'desc' && i === 2 && <AiOutlineTrophy className="card__icon card__icon--bronze" />}
                        </td>
                        <td className="round-dg__cell round-dg__cell--sm">
                          <div className="round-dg__user">
                            <div className="round-dg__user-inner">
                              <h3 className="round-dg__title">{item.name}</h3>
                            </div>
                          </div>
                        </td>
                        <td className="round-dg__cell u-text-right">{item.score}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <ViewGRE
                institutions={institutions?.data}
                loading={loadingInstitutions}
                error={errorInstitutions}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
