import React, { useState } from 'react';
import { SimpleModal } from 'app/components/Modal';
import VimeoPlayerStudyGuide from '../VideoPlayerStudyGuide';

export default function VideoContentModal(props) {
  const { show, onClose, video } = props;

  const [intervalId, setIntervalId] = useState();
  const [player, setPlayer] = useState();

  const handlePlay = () => {
    setTimeout(() => setCount(0), 2000);
  };

  const onReady = player => {
    setPlayer(player);
  };

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      contentClassName="simple-modal__content--md simple-modal__content--br-md"
      hideCloseButton
    >
      <div className="study-block__video">
      <VimeoPlayerStudyGuide
        videoId={video["video-url"]}
      />
      </div>
    </SimpleModal>
  );
}
