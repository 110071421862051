import { useClient } from 'jsonapi-react';
import React, { createContext, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

export const PlatformAbilitiesContext = createContext();

export const PlatformAbilitiesProvider = ({ children }) => {
  const [abilities, setAbilities] = useState();
  const client = useClient();
  const [loading, setLoading] = useState(true);

  const getAbilities = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('/platform_abilities/1');
    if (error) {
      toast.error('Erro ao buscar configurações da plataforma');
    } else {
      setAbilities(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAbilities();
  }, []);

  return <PlatformAbilitiesContext.Provider value={{ abilities, getAbilities }}>{children}</PlatformAbilitiesContext.Provider>;
};
