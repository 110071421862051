import { useAuditorium } from 'app/hooks/useAuditorium';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import { useSession } from 'app/hooks/useSession';
import { useParams } from '@reach/router';
import { t } from 'i18next';

export default function StepName(props) {
  const { lastLesson } = useAuditorium();
  const { step, form, auditorium } = props;
  const { session } = useSession();
  const { id } = useParams();
  const isAdmin = checkIncludeProfiles({ only: ['admin'] });
  console.log(!id)
  return (
    step === 'nome' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__name">{form?.values?.name || t('auditorium.newAuditorium')}</h2>
        </div>

        <div className="new-questions__body">
          <div className="form__row">
            <label
              className="form__label"
              htmlFor="name"
            >
              *{t('auditorium.NameNewAuditorium')}
            </label>
            <input
              className="form__control"
              placeholder={t('textsCommon.writeHere')}
              id="name"
              name="name"
              type="text"
              value={form?.values?.name}
              onChange={e => form?.setFieldValue('name', e.target.value)}
            />
            {form.touched.name && form.errors.name && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="lessonName"
            >
              *{t('textsCommon.lessonName')}
            </label>
            <input
              className="form__control"
              placeholder={t('lessons.lesson')}
              id="lessonName"
              name="lessonName"
              type="text"
              value={form?.values?.['auditorium-lessons-attributes']?.[0].name}
              onChange={e => form?.setFieldValue('auditorium-lessons-attributes[0].name', e.target.value)}
            />
            {form.touched['auditorium-lessons-attributes']?.[0].name && form.errors['auditorium-lessons-attributes']?.[0].name && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
          </div>

          <div className="form__row">
            {(!id || auditorium?.creator?.id === session?.user?.id) &&
              <div className="form__check form__switch">
                <input
                  className="form__check-input"
                  id="authorialContent"
                  name="authorialContent"
                  type="checkbox"
                  defaultChecked={form?.values?.['authored_by_user']}
                  onClick={() => {
                    form?.setFieldValue('authored_by_user', !form?.values?.['authored_by_user']);
                  }}
                />
                <label htmlFor="authorialContent">
                  Este auditório é de minha autoria e <strong>somente eu posso editá-lo.</strong>
                </label>
              </div>
            }

            {isAdmin &&
              <div className="form__check form__switch">
                <input
                  className="form__check-input"
                  id="officialContent"
                  name="officialContent"
                  type="checkbox"
                  defaultChecked={form?.values?.['official_content']}
                  onClick={() => {
                    form?.setFieldValue('official_content', !form?.values?.['official_content']);
                  }}
                />
                <label htmlFor="officialContent">
                  Este auditório é <strong>oficial;</strong> apenas a equipe administrativa têm permissão para editá-lo.
                </label>
              </div>
            }
          </div>

          <span className="form__text">
            <strong>* {t('warning.requiredField')}</strong>
          </span>
        </div>
      </div>
    )
  );
}
