import React, { useState, useEffect } from 'react';
import ModuleContent from './ModuleContent';
import ModuleSidebar from './ModuleSidebar';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

import { useTranslation } from 'react-i18next';

export function CreateModule(props) {
  const { id, step, courseId } = props;
  const { t } = useTranslation();
  const client = useClient();
  const [modules, setModules] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const currentModule = id ? modules.find(item => item.id === parseInt(id)) : null;

  const steps = [
    {
      id: 1,
      route: 'nome',
      text: t('lms.stepsModulesNameSideBar')
    },
    {
      id: 2,
      route: 'objetivos',
      text: t('lms.stepsModulesObjectivesSideBar')
    },
    {
      id: 3,
      route: 'requisitos',
      text: t('lms.stepsModulesRequirementsSideBar')
    },
    {
      id: 4,
      route: 'imagem',
      text: t('lms.stepsModulesImageSideBar')
    }
  ];

  const getModules = async () => {
    const filter = courseId ? `?filter[course_id]=${courseId}` : '';
    const { data, error } = await client.fetch(`learning_systems${filter}`);
    if (error) {
      toast.error(t('toast.errorGetModule'));
    } else {
      setModules(data);
    }
  };

  useEffect(() => {
    getModules();
  }, []);

  return (
    <div className="main-screen">
      <ModuleSidebar
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
      />
      <main className="main-content">
        <ModuleContent
          courseId={courseId}
          editingPath={id}
          modules={modules}
          currentModule={currentModule}
          step={step}
          steps={steps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </main>
    </div>
  );
}
