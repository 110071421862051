import React from 'react';
import userAvatar from 'app/images/user-avatar.svg';
import { FiMoreHorizontal } from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import { Link } from '@reach/router';
import UserInfo from '../UserInfo';
import { t } from 'i18next';

function Card() {
  return (
    <div>
      <div>Card</div>
    </div>
  );
}
export default Card;

function Student(props) {
  const { increment, photo, name, course, classStudent, registered, dateTime, buttonText, buttonImage, onClick, onClickImage, hasButton, authorityLevel, id } = props;

  return (
    <div className="student-card">
      <header className="student-card__header">
        <Link
          to={`/usuario/metricas/${id}`}
          state={{ isRedirected: true }}
        >
          <img
            className="student-card__avatar"
            src={photo || userAvatar}
            alt="User avatar"
          />
        </Link>

        <div className="student-card__meta">
          <div className="student-card__name">
            <Link
              to={`/usuario/metricas/${id}`}
              state={{ isRedirected: true }}
            >
              {name}
            </Link>
            <span className="student-card__course"> • {course}</span>
          </div>
          {registered ? <span className="student-card__date-time">Inscrito em {dateTime}</span> : <span className={`student-card__class ${increment ? 'is-increment' : ''}`}>{classStudent}</span>}
        </div>
      </header>

      {hasButton && (
        <footer className="student-card__footer">
          <button
            className={`btn btn--small ${registered ? 'btn--outline' : 'btn--primary'}`}
            type="button"
            onClick={onClick}
          >
            {buttonText ? buttonText : 'Mensagem'}
          </button>
        </footer>
      )}

      {authorityLevel === 'admin' && (
        <Dropdown>
          <Dropdown.Toggle>
            <FiMoreHorizontal />
          </Dropdown.Toggle>

          <Dropdown.Content>
            <Dropdown.Item onClick={onClickImage}>{t('textsCommon.delete')}</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown>
      )}
    </div>
  );
}
Card.Student = Student;

function StudentEmail(props) {
  const { student, registered, studentEmail, buttonText, onClick, onClickImage, authorityLevel } = props;

  return (
    <div className="student-card">
      <header className="student-card__header">
        <UserInfo user={student} />
        {registered && (
          <span
            className="student-card__student-email"
            title={studentEmail}
          >
            {studentEmail}
          </span>
        )}
      </header>

      {authorityLevel === 'admin' && (
        <Dropdown>
          <Dropdown.Toggle>
            <FiMoreHorizontal />
          </Dropdown.Toggle>

          <Dropdown.Content>
            <Dropdown.Item onClick={onClickImage}>{t('textsCommon.delete')}</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown>
      )}

      {buttonText && (
        <footer className="student-card__footer">
          <button
            className={`btn btn--small ${registered ? 'btn--outline' : 'btn--primary'}`}
            type="button"
            onClick={onClick}
          >
            {buttonText}
          </button>
        </footer>
      )}
    </div>
  );
}
Card.StudentEmail = StudentEmail;

function ClassStudents(props) {
  return (
    <div className="class-card">
      <header className="class-card__header">
        <h3 className="class-card__title">Diretiro 2º Semestre e 2019 | Matutino</h3>
      </header>
      <footer className="class-card__footer">
        <p className="class-card__average">4.5</p>
      </footer>
    </div>
  );
}
Card.ClassStudents = ClassStudents;
