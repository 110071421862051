import { useTranslation } from 'react-i18next';
import { TopBar } from './TopBar';

export function MyTasksOnboarding() {
  const { t } = useTranslation();

  return (
    <section>
      <TopBar />

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.adjustYourTasks')}</h1>
        <p className="text-option-onboarding">{t('onboarding.adjustYourTasksDescription')}</p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.markYourTasks')}</h1>
        <p className="text-option-onboarding">{t('onboarding.markYourTasksDescription')}</p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.createNewTasks')}</h1>
        <p className="text-option-onboarding">{t('onboarding.createNewTasksDescription')}</p>
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.tasksDetails')}</h1>
        <p className="text-option-onboarding">{t('onboarding.tasksDetailsDescription')}</p>
      </div>
    </section>
  );
}
