import { useContext } from 'react';
import { ClassroomContext } from '../providers/ClassroomProvider';

export const useClassroom = () => {
	const context = useContext(ClassroomContext);

	if (!context) {
		throw new Error(
			'useCourse must be used within a ClassroomProvider.',
		);
	}

	return context;
};
