import React, { useState, useMemo, useEffect } from 'react';
import { navigate } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';
import moment from 'moment';
import { useFormik } from 'formik';
import ReportFilter from 'app/components/ReportFilter';
import PieChartWidget from './PieChartWidget';
import OverviewWidget from './OverviewWidget';
import RankingWidget from './RankingWidget';
import ScatterChartWidget from './ScatterChartWidget';
import CompatibilityWidget from './CompatibilityWidget';

export default function LevelingTestReport() {
  const [currentFilters, setCurrentFilters] = useState([]);
  const [positions, setPositions] = useState([]);

  //filtros
  const [selectedPositions, setSelectedPositions] = useState([]);

  const filters = useFormik({
    initialValues: {
      'start-date': '',
      'end-date': ''
    }
  });

  const kpis = [
    {
      id: 1,
      title: 'Total de colaboradores',
      value: 100,
      help: 'Total de colaboradores.',
      variation: 'report-kpi-card--users'
    },
    {
      id: 2,
      title: 'Fizeram nivelamento',
      value: 52,
      help: 'Fizeram nivelamento.',
      variation: 'report-kpi-card--user'
    },
    {
      id: 3,
      title: 'Porcentagem correspondente',
      value: `${52}%`,
      help: 'Porcentagem correspondente.',
      variation: 'report-kpi-card--percent'
    }
  ];

  const fetchPositions = () => {
    setPositions([
      {
        value: 1,
        label: 'Diretor'
      },
      {
        value: 2,
        label: 'Gerente'
      }
    ]);
  };

  useEffect(() => {
    fetchPositions();
  }, []);

  const data = useMemo(() => {
    //atualiza os seletores de acordo com os filtros aplicados
    return [
      {
        placeholder: 'Digite o nome da Cargo',
        name: 'Cargo',
        options: positions
      }
    ];
  }, [positions]);

  useEffect(() => {
    setCurrentFilters([...selectedPositions]);
  }, [selectedPositions]);

  const hasPosition = selectedPositions.length > 0;

  return (
    <main className="report">
      <header className="report__header">
        <h1 className="report__title">
          <button
            className="card__icon-wrapper"
            aria-label="Voltar"
            title="Voltar"
            onClick={() => navigate(-1)}
          >
            <FiChevronLeft className="card__icon" />
          </button>
          Teste de Nivelamento
        </h1>
        {/* <p className="report__description"></p> */}
      </header>

      <div className="report__body">
        <div className="report__filter report__filter--space report__filter--multi-select">
          <ReportFilter
            setSelectedPositions={setSelectedPositions}
            currentFilters={currentFilters}
            data={data}
          />

          <div className="report-form__datepicker">
            <input
              className="report-form__control"
              id="start-date"
              name="start-date"
              type="date"
              value={moment(filters.values['start-date']).format('YYYY-MM-DD')}
              onChange={e => filters.setFieldValue('start-date', e.target.value)}
              title="Data de início"
              aria-label="Data de início"
            />
            <input
              className="report-form__control"
              id="start-date"
              name="start-date"
              type="date"
              value={moment(filters.values['end-date']).format('YYYY-MM-DD')}
              onChange={e => filters.setFieldValue('end-date', e.target.value)}
              min={moment(filters.values['start-date']).format('YYYY-MM-DD')}
              title="Data de fim"
              aria-label="Data de fim"
            />
          </div>
        </div>

        <OverviewWidget kpis={kpis} />

        {!hasPosition && <PieChartWidget setSelectedPositions={setSelectedPositions} />}

        {!hasPosition && <RankingWidget setSelectedPositions={setSelectedPositions} />}

        {hasPosition && <ScatterChartWidget />}

        {hasPosition && <CompatibilityWidget />}
      </div>
    </main>
  );
}
