import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import informationPng from '../../images/onboarding/profile/informations.png';
import notificationsPng from '../../images/onboarding/profile/notifications.png';
import navigationPng from '../../images/onboarding/profile/navigation.png';
import securityPng from '../../images/onboarding/profile/security.png';

export function ProfileOnboarding() {
  const { t } = useTranslation();

  return (
    <div>
     <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.profileInformations')}</h1>
        <p className="text-option-onboarding">{t('onboarding.profileInformationsDesc')}</p>

          <img src={informationPng} />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('header.notificationTitle')}</h1>
        <p className="text-option-onboarding">{t('onboarding.notificationsDesc')}</p>

        <img
          src={navigationPng}
          className="w-70"
        />
        <img
          style={{ width: 420 }}
          src={notificationsPng}
          className="w-70"
        />
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.security')}</h1>
        <p className="text-option-onboarding">{t('onboarding.securityDesc')}</p>

          <img  style={{ width: 500 }}
                src={securityPng}
          />
      </div>
    </div>
  );
}
