import React from 'react';
import roullete from './img/roullete.svg';

export default function BannerChallenge({ challenge }) {
  return (
    <div className="banner-challenge banner-challenge--admin">
      <div
        className="banner-challenge__bg"
        style={{ backgroundImage: `url(${roullete})` }}
      />
      <div className="banner-challenge__header">
        <h1 className="banner-challenge__title">{challenge?.title}</h1>
        <p className="banner-challenge__date">
          Desafio da semana {new Date(challenge['initial-date']).toLocaleDateString('pt-br')} a {new Date(challenge['end-date']).toLocaleDateString('pt-br')}
        </p>
        <div>
          <span className="badge badge--tiny badge--primary">{challenge?.taxonomy?.name}</span>
        </div>
      </div>
    </div>
  );
}
