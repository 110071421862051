import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import ExamCard from 'app/screens/Exams/ExamsResolution/ExamCard';
import { useQuery } from 'jsonapi-react';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FiAlertCircle } from 'react-icons/fi';
import BreadCrumbs from 'app/components/BreadCrumbs';

function Exams(props) {
  const { courses, exams, loading, uri } = props;
  const { t } = useTranslation();
  const { data: classrooms = [] } = useQuery('/classrooms?simple=true');

  const [filteredExams, setFilteredExams] = useState([]);
  const [filteredClassrooms, setFilteredClassrooms] = useState([]);

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      classroom: { value: 'blank', label: t('filter.blankLabelAll') },
      status: {
        approved: false,
        disapproved: false,
        notRealized: false,
        accepting_answers: false,
        review: false
      }
    }
  });

  const handleFilterStatus = field => {
    let newStatus = { ...filters.values.status, [field]: !filters.values.status[field] };
    filters.setFieldValue('status', newStatus);

    const selectedStatus = Object.entries(newStatus)
      .filter(([_, isChecked]) => isChecked)
      .map(([status]) => (status === 'notRealized' ? null : status));

    const allStatusUnchecked = !Object.values(newStatus).some(Boolean);

    const filterFunction = item => {
      if (allStatusUnchecked) {
        return filters.values.course !== 'blank' ? item['course-ids'].includes(Number(filters.values.course)) : true;
      }

      return selectedStatus.includes(item.status) && (filters.values.course !== 'blank' ? item['course-ids'].includes(Number(filters.values.course)) : true);
    };

    setFilteredExams(exams.filter(filterFunction));
  };

  const handleFilterCourse = courseId => {
    filters.setFieldValue('course', courseId);

    const selectedStatus = Object.entries(filters.values.status)
      .filter(([_, isChecked]) => isChecked)
      .map(([status]) => (status === 'notRealized' ? null : status));

    if (courseId === 'blank') {
      const filterFunction = item => (selectedStatus.length > 0 ? selectedStatus.includes(item.status) : true);
      setFilteredExams(exams.filter(filterFunction));
      return;
    }

    const allStatusUnchecked = !Object.values(filters.values.status).some(Boolean);

    const filterFunction = item => {
      if (allStatusUnchecked) {
        return item['course-ids'].includes(Number(courseId));
      }

      return item['course-ids'].includes(Number(courseId)) && selectedStatus.includes(item.status);
    };

    setFilteredExams(exams.filter(filterFunction));
  };

  const handleFilterClassroom = classroom => {
    filters.setFieldValue('classroom', classroom);
    const id = classroom.value;

    const selectedStatus = Object.entries(filters.values.status)
      .filter(([_, isChecked]) => isChecked)
      .map(([status]) => (status === 'notRealized' ? null : status));

    if (id === 'blank') {
      const filterFunction = item => (selectedStatus.length > 0 ? selectedStatus.includes(item.status) : true);
      setFilteredExams(exams.filter(filterFunction));
      return;
    }

    const allStatusUnchecked = !Object.values(filters.values.status).some(Boolean);

    const filterFunction = item => {
      classrooms.filter(item => item.course.id === parseInt(filters.values.course));
      if (allStatusUnchecked) {
        return item['classroom-ids'].includes(Number(id));
      }

      return item['classroom-ids'].includes(Number(id)) && selectedStatus.includes(item.status);
    };

    setFilteredExams(exams.filter(filterFunction));
  };

  useEffect(() => {
    if (filters.values.course !== 'blank') {
      filters.setFieldValue('classroom', { value: 'blank', label: t('filter.blankLabelAll') });
    }

    const filteredClassrooms = classrooms.filter(item => item.course.id === parseInt(filters.values.course));

    setFilteredClassrooms(filteredClassrooms);
  }, [filters.values.course]);

  useEffect(() => {
    if (exams) {
      setFilteredExams(exams);
    }
    if (classrooms) {
      setFilteredClassrooms(classrooms);
    }
  }, [exams, classrooms]);

  const breadCrumbs = {
    title: 'Avaliações e Questões',
    nav: [
      {
        route: uri,
        name: 'Provas',
        isActive: true
      }
    ]
  };

  return (
    <>
      <BreadCrumbs data={breadCrumbs} />

      <div className="filter-bar">
        <div className="filter-bar__inner">
          <FilterSelectionBox
            label={t('filter.labelSelectCourse')}
            blankLabel={t('filter.blankLabelAll')}
            value={filters.values.course}
            onChange={e => handleFilterCourse(e.target.value)}
            options={courses}
          />
          <div>
            <label className="form__label">Selecione uma turma</label>
            <Select
              data-for="select-tooltip"
              // isDisabled={filters.values.course === 'blank'}
              value={filters.values.classroom}
              openMenuOnFocus
              options={[
                { value: 'blank', label: t('filter.blankLabelAll') },
                ...filteredClassrooms?.map(item => {
                  return { value: item.id, label: item?.institution?.name + ' - ' + item?.course?.title + ' - ' + item.title };
                })
              ]}
              className="react-multi-select filter-bar__multi-select"
              classNamePrefix="react-multi-select"
              placeholder={t('filter.blankLabelAll')}
              noOptionsMessage={() => 'Sem opções'}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              onChange={e => handleFilterClassroom(e)}
            />
          </div>

          <FilterMultiSelect
            label={t('filter.labelSelectStatus')}
            data={[
              {
                label: 'Aprovado',
                value: filters.values.status.approved,
                onChange: () => handleFilterStatus('approved')
              },
              {
                label: 'Reprovado',
                value: filters.values.status.disapproved,
                onChange: () => handleFilterStatus('disapproved')
              },
              {
                label: 'Em análise',
                value: filters.values.status.review,
                onChange: () => handleFilterStatus('review')
              },
              {
                label: 'Aceitando respostas',
                value: filters.values.status.accepting_answers,
                onChange: () => handleFilterStatus('accepting_answers')
              },
              {
                label: t('filter.unrealized'),
                value: filters.values.status.notRealized,
                onChange: () => handleFilterStatus('notRealized')
              }
            ]}
          />
        </div>

        <div className="filter-bar__actions">
          <p className="filter-bar__alert">
            <span className="filter-bar__alert-icon filter-bar__alert-icon--warning">
              <FiAlertCircle />
            </span>{' '}
            <span>
              Provas de módulos são acessadas através
              <br /> da navegação das aulas nos cursos.
            </span>
          </p>
        </div>
      </div>

      {!loading && filteredExams.length === 0 && <EmptyState type="content" />}

      <div className="fadeIn">
        {loading && <Loader />}
        <div className="tab__cards">
          {!loading &&
            filteredExams.map((exam, i) => (
              <ExamCard
                exam={exam}
                key={i}
              />
            ))}
        </div>
      </div>
    </>
  );
}

export default Exams;
