import styles from './styles.module.scss';

export default function Competence({ competence, item }) {
  const checkScore = (score, maxScore) => {
    const oneThird = maxScore / 3;
    const twoThirds = oneThird * 2;

    if (score <= oneThird) {
      return 'danger';
    } else if (score > oneThird && score <= twoThirds) {
      return 'warning';
    } else if (score > twoThirds) {
      return 'success';
    }
  };

  return (
    <div className={`${styles['competences-card']} u-mt-3`}>
      <span>{item?.name}</span>
      <h2 className="widget__title">{item?.description}</h2>
      <span className={`square-tag square-tag--full square-tag--md square-tag--${checkScore(competence?.score, competence?.['max_grade'])} ${styles['competence-card-score']}`}>
        <strong>{competence?.score}</strong>/{competence?.['max_grade']} pts
      </span>
      <div className={styles['competences-card-level']}>
        {/* <span>Nível {competence?.level}</span> */}
        <p>{competence?.comment}</p>
      </div>
    </div>
  );
}
