import React, { useContext, useEffect, useState } from 'react';
import ChartWidget from 'app/components/Widget/ChartWidget';
import Chart from 'app/screens/Admin/Chart';
import MetricsChart from './MetricsChart';
import QuestionTimeChart from './QuestionTimeChart';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

function MetricsGeneral({ userId, user }) {
  const { t } = useTranslation();
  const client = useClient();
  const [metricsData, setMetricsData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await client.fetch(`users/${userId}/metrics/general`);
      setMetricsData(data);
      setIsLoading(false);
    } catch (e) {
      toast.error(t("toast.error"));
      console.error(e);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="widget-wrapper widget-wrapper--chart">
        <ChartWidget title="Versões de projetos enviadas">
          {(metricsData?.submitedProjects || []).length > 0 && (
            <Chart
              name="Versões"
              color="#442B8C"
              chartData={metricsData.submitedProjects}
            />
          )}
          {(metricsData?.submitedProjects || []).length === 0 && (
            <EmptyState type="data" text="Nenhum dado de versões enviadas" />
          )}
        </ChartWidget>

        <ChartWidget title="Questões respondidas">
          {(metricsData?.questionsAnswered || []).length > 0 && (
            <Chart
              name="Questões"
              color="#32C9D3"
              chartData={metricsData.questionsAnswered}
            />
          )}
          {(metricsData?.questionsAnswered || []).length === 0 && (
            <EmptyState
              type="data"
              text="Nenhum dado de questões respondidas"
            />
          )}
        </ChartWidget>

        <ChartWidget title="Materiais acessados">
          {(metricsData?.contentAccess || []).length > 0 && (
            <Chart
              name="Materiais"
              color="#FDB462"
              chartData={metricsData.contentAccess}
            />
          )}
          {(metricsData?.contentAccess || []).length === 0 && (
            <EmptyState type="data" text="Nenhum dado de materiais acessados" />
          )}
        </ChartWidget>
      </div>

      <div className="widget-wrapper widget-wrapper--chart">
        <ChartWidget title="Solução de tarefas">
          {metricsData?.accomplishedSlots && (
            <MetricsChart chartData={metricsData?.accomplishedSlots} />
          )}
          {!metricsData?.accomplishedSlots && (
            <EmptyState
              type="data"
              text="Nenhuma métrica de solução de tarefas"
            />
          )}
        </ChartWidget>

        <ChartWidget title="Tempo acumulado em questões">
          <QuestionTimeChart
            chartData={metricsData?.accumulatedTimeQuestions}
          />
        </ChartWidget>
      </div>
    </>
  );
}

export default MetricsGeneral;
