import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import Loader from 'app/components/loader';

export default function RadarChart(props) {
  const { radarScore } = props;
  const [loading, setLoading] = useState(true);

  HC_more(Highcharts);

  if (radarScore?.['chart-data'].length > 0) {
    const colors = ['#7B63BF', '#5DC9DD', '#8E7BC3', '#B4A7D6', '#DAD3E9', '#D0E0E3', '#A2C4C9', '#76A5AF', '#45808D', '#134E5B'];

    const seriesData = radarScore['chart-data'].map((item, index) => ({
      name: item['question-book']?.title,
      color: colors[index],
      data: item.data.map(entry => parseFloat(entry.average_result.toFixed(2))),
      pointPlacement: 'on'
    }));

    const xAxisCategories = radarScore['chart-data'][0].data.map(entry => entry.name);

    const options = {
      chart: {
        polar: true,
        type: 'line',
        marginBottom: 100
      },

      credits: {
        enabled: false
      },

      title: {
        text: null
      },

      xAxis: {
        categories: xAxisCategories,
        tickmarkPlacement: 'on',
        lineWidth: 0,
        labels: {
          style: {
            whiteSpace: 'nowrap',
            width: 160,
            textOverflow: 'ellipsis'
          }
        }
      },

      yAxis: {
        gridLineInterpolation: 'polygon'
      },

      series: seriesData,

      legend: {
        enabled: true,
        align: 'left'
      }
    };

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    );
  }

  return <Loader />;
}
