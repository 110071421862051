import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import GeneratedContent from 'app/components/GeneratedContent';

export default function ExamMetricsAswerGuideListItem(props) {
  const { studentAnswers, feedback, currentQuestion } = props;
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  const correctAlternative = () => {
    if (['1', '2', '3', ''].includes(studentAnswers?.correct_alternative?.name)) {
      return studentAnswers?.correct_alternative?.description;
    } else {
      return studentAnswers.correct_alternative?.name;
    }
  };

  const studentAlternative = () => {
    if (['1', '2', '3', ''].includes(studentAnswers?.student_alternative.name)) {
      return studentAnswers?.student_alternative.description;
    } else {
      return studentAnswers?.student_alternative.name;
    }
  };

  const activeClass = `round-dg__cell ${feedback === 'positive' ? 'positive-class' : feedback === 'negative' ? 'negative-class' : 'blank-class'}`;

  return (
    <>
      <tr
        className="round-dg__row u-cursor-pointer"
        onClick={() => setActive(!active)}
      >
        <td className="round-dg__cell border-l-rounded">
          <span className="container-question-id">Q{studentAnswers?.question_id}</span>
        </td>
        <td className="round-dg__cell text-primary-dark font-bold text-sm">
          <div dangerouslySetInnerHTML={{ __html: correctAlternative() }} />
        </td>
        <td className={activeClass}>
          <div dangerouslySetInnerHTML={{ __html: studentAlternative() }} />
        </td>
        <td className={activeClass}>{feedback === 'positive' ? t('activity.correct') : feedback === 'negative' ? t('activity.wrong') : t('activity.blank')}</td>
        <td className="round-dg__cell text-primary-dark text-sm border-l">
          <b>{studentAnswers?.overall_performance}</b> acertou
        </td>
        <td className="round-dg__cell border-r-rounded">{active ? <FiChevronUp className="card__icon" /> : <FiChevronDown className="card__icon" />}</td>
      </tr>

      {active && (
        <tr className="round-dg__row">
          <th
            className="round-dg__cell-header round-dg__cell-header--row bg-gray-100"
            colSpan={6}
          >
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
              <strong>Enunciado:</strong>
              <GeneratedContent
                authorName={currentQuestion?.creator?.name}
                verified={currentQuestion?.['official-content']}
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: currentQuestion?.description }} />
            {''}
            <br />
            <strong>Resposta correta: </strong>
            <div dangerouslySetInnerHTML={{ __html: studentAnswers['correct_alternative']?.description }} />
            {''}
            <br />
            <strong>Resposta do Aluno: </strong>
            <div dangerouslySetInnerHTML={{ __html: studentAnswers['student_alternative']?.description }} />
          </th>
        </tr>
      )}
    </>
  );
}
