import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import toast from 'react-hot-toast';
import StarRating from './StarRating';
import { useClient } from 'jsonapi-react';
import { useParams } from '@reach/router';

export default function NPSModal({ answers, setAnswers }) {
  const client = useClient();
  const params = useParams();
  
  const [rate, setRate] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [done, setDone] = useState(false);
  const [questions, setQuestions] = useState([]);

  const fetchSurvey = async () => {
    const { data, error } = await client.fetch('live_classroom_questions');

    if (error) {
      toast.error('Erro ao carregar perguntas da pesquisa.');
      return;
    }

    const questionsNotAnswered = data.filter(question => !answers.some(answer => answer['live-classroom-question-id'] === question.id));

    setQuestions(data);
    setCurrentQuestion(questionsNotAnswered[0].id ?? 1);
  };

  const handleAnswerSurvey = async () => {
    const isAnswered = answers.find(item => item['live-classroom-question-id'] === questions?.[currentQuestion - 1]?.id);

    let response;

    if (isAnswered) {
      response = await client.mutate(`live_classroom_answers/${isAnswered.id}`, {
        rating: rate
      });
    } else {
      response = await client.mutate('live_classroom_answers', {
        rating: rate,
        'live-classroom-id': Number(params.liveClassId),
        'live-classroom-question-id': questions?.[currentQuestion - 1]?.id
      });
      setAnswers(prev => [...prev, response.data])
    }

    if (response.error) {
      toast.error('Erro ao responder pergunta.');
      return;
    }

    if (currentQuestion < 5) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      toast.success('Agradecemos por responder a pesquisa.');
      setDone(true);
    }
  };

  useEffect(() => {
    fetchSurvey();
  }, []);

  return ReactDOM.createPortal(
    <div
      className="nps-modal"
      style={{ pointerEvents: done ? 'none' : 'auto' }}
      onClick={() => {
        toast.error('Termine de responder o questionário');
      }}
    >
      <div
        className={!done ? 'nps-modal__content nps-modal__content--open' : 'nps-modal__content nps-modal__content--close'}
        onClick={e => e.stopPropagation()}
      >
        <div className="nps-modal__header">
          <h3 className="nps-modal__title">
            {questions?.[currentQuestion - 1]?.id}. {questions[currentQuestion - 1]?.title}
          </h3>
        </div>

        <div className="nps-modal__body">
          <StarRating
            rate={rate}
            setRate={setRate}
            setDone={setDone}
            onClick={handleAnswerSurvey}
          />
        </div>

        <div className="nps-modal__footer">
          {currentQuestion > 1 && (
            <button
              type="button"
              className="nps-modal__btn"
              onClick={() => setCurrentQuestion(currentQuestion - 1)}
            >
              <FiArrowLeft className="card__icon" />
            </button>
          )}

          <span className="nps-modal__steps">{questions?.[currentQuestion - 1]?.id}/5</span>

          {currentQuestion < 5 && (
            <button
              type="button"
              className="nps-modal__btn"
              onClick={() => setCurrentQuestion(currentQuestion + 1)}
            >
              <FiArrowRight className="card__icon" />
            </button>
          )}
        </div>
      </div>
    </div>,
    document.getElementById('modal_root')
  );
}
