import smallIcon from '../images/icons/estudologia-small-icon.svg'

function EndScroll() {
  return(
    <div className="end-scroll">
      <img alt="" src={smallIcon}/>
    </div>
  )
}

export default EndScroll
