import { useTranslation } from 'react-i18next';
import { useParams } from '@reach/router';

export default function TipsSidebar(props) {
  const { lampIcon, learningTip, requirementsTip, relatedMaterialsTip, imageAreaTip, darkeningImageTip, currentCourse } = props;
  const { t } = useTranslation();
  const { step } = useParams();

  return (
    <>
      {step === 'nome' && (
        <aside className={`fadeIn page__aside ${currentCourse && 'page__aside--bg'}`}>
          {currentCourse && (
            <>
              <h3
                className="page__aside-title"
                dangerouslySetInnerHTML={{ __html: t('exams.titleAsideBarSimulations', { currentCourseTitle: currentCourse?.title }) }}
              />

              <p
                className="page__aside-text"
                dangerouslySetInnerHTML={{ __html: t('lms.asideTextDescription', { currentCourseTitle: currentCourse?.title }) }}
              />

              <div className="kpi-widget u-mb-3">
                <div className="kpi-widget__header">
                  <h4 className="kpi-widget__count">{currentCourse?.['student-count']}</h4>
                </div>
                <p
                  className="kpi-widget__action-title"
                  dangerouslySetInnerHTML={{ __html: t('lms.studentsCourse', { currentCourseTitle: '' }) }}
                />
              </div>
            </>
          )}
        </aside>
      )}

      {step === 'objetivos' && (
        <aside className="fadeIn page__aside page__aside--bg">
          <img
            src={lampIcon}
            alt="ícone lâmpada"
            className="page__aside-icon"
          />

          <h3 className="page__aside-title">{t('lms.asideTitleLearning')}</h3>

          <p className="page__aside-text">
            <div dangerouslySetInnerHTML={{ __html: t('lms.asideTextLearning') }}></div>
          </p>

          <img
            src={learningTip}
            alt="dica de preenchimento"
          />
        </aside>
      )}

      {step === 'requisitos' && (
        <aside className="fadeIn page__aside page__aside--bg">
          <img
            src={lampIcon}
            alt="ícone lâmpada"
            className="page__aside-icon"
          />

          <h3 className="page__aside-title">{t('lms.requirements')}</h3>
          <p className="page__aside-text">
            <div dangerouslySetInnerHTML={{ __html: t('lms.asideRequirementsDescription') }}></div>
          </p>
          <p className="page__aside-text">
            <img
              src={requirementsTip}
              alt="dica de requisitos"
              style={{ width: '140px' }}
            />
          </p>

          <h3 className="page__aside-title">{t('tasks.mattersRelated')}</h3>
          <p className="page__aside-text">
            <div dangerouslySetInnerHTML={{ __html: t('lms.relatedSubjectDescription') }}></div>
          </p>
          <img
            src={relatedMaterialsTip}
            alt="dica de matérias relacionadas"
            style={{ width: '198px' }}
          />
        </aside>
      )}

      {step === 'imagem' && (
        <aside className="fadeIn page__aside page__aside--bg">
          <img
            src={lampIcon}
            alt="ícone lâmpada"
            className="page__aside-icon"
          />

          <h3 className="page__aside-title">{t('courses.coverImage')}</h3>
          <p className="page__aside-text">{t('courses.asideTextDescription')}</p>
          <p className="page__aside-text u-mb-0">
            <strong>{t('courses.observations')}</strong>
          </p>
          <ul className="page__aside-list">
            <li>
              <div dangerouslySetInnerHTML={{ __html: t('courses.asideTextDimensions') }}></div>
              <img
                src={imageAreaTip}
                alt={t('courses.asideImageSecurity')}
              />
            </li>
            <li>
              <div dangerouslySetInnerHTML={{ __html: t('courses.asideTextLegibility') }}></div>
              <br />
              <img
                src={darkeningImageTip}
                alt={t('courses.asideTextAltTip')}
              />
            </li>
          </ul>
          <p className="page__aside-text page__aside-text--tip">
            <div dangerouslySetInnerHTML={{ __html: t('courses.AsideTextTip') }}></div>
          </p>
        </aside>
      )}
    </>
  );
}
