import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import SemiCircle from './SemiCircle';
function Progress(props) {
  const { valueNow = 0, text } = props;

  return (
    <div className="progress">
      <div
        className="progress__bar"
        role="progressbar"
        style={{ width: `${valueNow}%` }}
        aria-valuenow={valueNow}
        aria-valuemin="0"
        aria-valuemax="100"
      />
      {text && <div className="progress__text">{valueNow}%</div>}
    </div>
  );
}
export default Progress;

const CircularProgress = styled.div`
  .circular-progress {
    width: 60px;
    height: 60px;
    line-height: 60px;
    background: none;
    box-shadow: none;
    position: relative;

    &--small {
      width: 36px;
      height: 36px;
      line-height: 36px;

      .circular-progress__value {
        font-size: 1rem;
      }
    }

    &--md {
      width: 136px;
      height: 136px;
      line-height: 136px;

      .circular-progress__value {
        font-size: 14px;
      }

      &::after {
        border-width: 20px !important;
      }

      .circular-progress__bar {
        border-width: 20px;
      }
    }

    &--clickable {
      cursor: pointer;
    }

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 5px solid #f1f5fa;
      top: 0;
      left: 0;
      box-sizing: border-box;
    }

    & > span {
      position: absolute;
      width: 50%;
      height: 100%;
      overflow: hidden;
      top: 0;
      z-index: 1;
    }

    &__bar {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: none;
      border-width: 5px;
      border-color: #32c9d3;
      border-style: solid;
    }

    &__left {
      left: 0;

      & .circular-progress__bar {
        left: 100%;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
        border-left: 0;
        transform-origin: center left;
        transform: rotate(${props => props.value100}deg);
      }
    }

    &__right {
      right: 0;

      & .circular-progress__bar {
        left: -100%;
        border-top-left-radius: 80px;
        border-bottom-left-radius: 80px;
        border-right: 0;
        transform-origin: center right;
        transform: rotate(${props => props.value50}deg);
      }
    }

    &__value {
      position: absolute;
      width: 100%;
      height: 100%;
      font-size: 1.2rem;
      font-weight: 700;
      color: #5d6a86;
      text-align: center;
    }
  }
`;

function Circular(props) {
  const { valueNow = 100, time, className, onClick, style } = props;

  const [value50, setValue50] = useState(0);

  const [value100, setValue100] = useState(0);

  useEffect(() => {
    if (valueNow <= 50) {
      setValue50((valueNow * 360) / 100);
      setValue100(0);
    } else if (valueNow > 50 && valueNow <= 100) {
      setValue50(180);
      setValue100((valueNow * 360) / 100 - 180);
    } else {
      setValue50(180);
      setValue100(180);
    }

    return () => {
      setValue50(180);
      setValue100(180);
    };
  }, [valueNow]);

  const loading50 = keyframes`
    0% { transform: rotate(0deg)}
    100% { transform: rotate(${value50}deg) }
  `;

  const loading100 = keyframes`
    0% { transform: rotate(0deg) }
    100% { transform: rotate(${value100}deg) }
  `;

  return (
    <CircularProgress
      value100={value100}
      value50={value50}
    >
      <div
        className={className ? `circular-progress ${className}` : 'circular-progress'}
        onClick={onClick ? onClick : null}
        style={style}
      >
        <span className="circular-progress__left">
          <span className="circular-progress__bar"></span>
        </span>
        <span className="circular-progress__right">
          <span className="circular-progress__bar"></span>
        </span>
        <div className="circular-progress__value">{time ? time : <span>{valueNow}%</span>}</div>
      </div>
    </CircularProgress>
  );
}

function Card(props) {
  const { percentage, toFixed = 2 } = props;

  return (
    <div className="card__progress u-mb-0">
      <div className="card__progress-container">
        <div
          className="card__progress-bar"
          role="progressbar"
          style={{ width: `${percentage}%` }}
          aria-valuenow={percentage}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
      <div className="card__progress-text">{`${percentage?.toFixed(toFixed)}%`}</div>
    </div>
  );
}

Progress.Circular = Circular;
Progress.SemiCircle = SemiCircle;
Progress.Card = Card;
