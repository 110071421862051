import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function ActivityModalCard(props) {
  const { t } = useTranslation();
  const { activity, selectActivity, removeActivity, isSelected, setRequireds, isRequired } = props;

  return (
    <div className="card card--h u-mb-2">
      <div
        className="card__body"
        style={{ flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <h3 className="card__title">{activity.title}</h3>
        {/* <h4 className="card__subtitle">
          {t('textsCommon.availableUntil')}: {moment(activity['finish-at']).format(t('datesForm.formatDate'))}
        </h4> */}
      </div>
      <div className="card__footer">
        <div className="form__check">
          <input
            className="form__check-input"
            id={`requiredResponse${activity.id}`}
            type="checkbox"
            checked={isRequired}
            onChange={event => setRequireds(activity, event.target.checked)}
          />
          <label
            className="form__check-label"
            htmlFor={`requiredResponse${activity.id}`}
          >
            Resposta obrigatória
          </label>
        </div>

        <button
          onClick={() => {
            if (isSelected) {
              removeActivity(activity.id);
            } else {
              selectActivity({
                ['activity-id']: activity.id,
                ['is-required']: isRequired
              });
            }
          }}
          className={isSelected ? 'btn btn--outline btn--wide active' : 'btn btn--outline btn--wide'}
        >
          {isSelected ? t('button.remove') : t('button.add')}
        </button>
      </div>
    </div>
  );
}
