import React, { useState, useEffect } from 'react';
import ExamMetricsExamListItem from './ExamMetricsExamListItem';
import { useFormik } from 'formik';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useQuery } from 'jsonapi-react';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import Loader from 'app/components/loader';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import BreadCrumbs from 'app/components/BreadCrumbs';

export function UserMetrics({ courses }) {
  const { t } = useTranslation();
  const { data: questionBooksUsers = [], isLoading } = useQuery('question_books_users/reports');

  const [filteredQuestionBooksUsers, setFilteredQuestionBooksUsers] = useState([]);

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      status: {
        approved: false,
        disapproved: false,
        review: false
      }
    }
  });

  const handleFilterCourse = id => {
    filters.setFieldValue('course', id);

    if (id === 'blank') return setFilteredQuestionBooksUsers(questionBooksUsers);

    setFilteredQuestionBooksUsers(questionBooksUsers.filter(item => item.question_book['course-ids'].includes(Number(id))));
  };

  const handleFilterStatus = field => {
    let newStatus = { ...filters.values.status, [field]: !filters.values.status[field] };
    filters.setFieldValue('status', newStatus);

    const selectedStatus = Object.entries(newStatus)
      .filter(([_, isChecked]) => isChecked)
      .map(([status]) => (status === 'notRealized' ? null : status));

    const allStatusUnchecked = !Object.values(newStatus).some(Boolean);

    const filterFunction = item => {
      if (allStatusUnchecked) {
        return filters.values.course !== 'blank' ? item.question_book['course-ids'].includes(Number(filters.values.course)) : true;
      }

      const status = item.question_book['question-books-users'][0].status;

      return selectedStatus.includes(status) && (filters.values.course !== 'blank' ? item.question_book['course-ids'].includes(Number(filters.values.course)) : true);
    };

    setFilteredQuestionBooksUsers(questionBooksUsers.filter(filterFunction));
  };

  useEffect(() => {
    if (questionBooksUsers) {
      setFilteredQuestionBooksUsers(questionBooksUsers);
    }
  }, [questionBooksUsers]);

  const breadCrumbs = {
    title: 'Avaliações e Questões',
    nav: [
      {
        route: '#',
        name: 'Meus resultados',
        isActive: true
      }
    ]
  };

  return (
    <>
      <BreadCrumbs data={breadCrumbs} />

      <div className="filter-bar">
        <div className="filter-bar__inner">
          <FilterSelectionBox
            label={t('filter.labelSelectCourse')}
            blankLabel={t('filter.blankLabelAll')}
            value={filters.values.course}
            onChange={e => handleFilterCourse(e.target.value)}
            options={courses}
          />
          <FilterMultiSelect
            label={t('filter.labelSelectStatus')}
            data={[
              {
                label: 'Aprovado',
                value: filters.values.status.approved,
                onChange: () => handleFilterStatus('approved')
              },
              {
                label: 'Reprovado',
                value: filters.values.status.disapproved,
                onChange: () => handleFilterStatus('disapproved')
              },
              {
                label: 'Em análise',
                value: filters.values.status.review,
                onChange: () => handleFilterStatus('review')
              }
            ]}
          />
        </div>
      </div>

      <div className="round-dg-wrapper">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {!isLoading && questionBooksUsers.length > 0 ? (
              <table
                className="round-dg round-dg--light"
                style={{ minWidth: '832px' }}
              >
                <thead class="round-dg__header">
                  <tr className="round-dg__row">
                    <th class="round-dg__cell-header">{t('exams.thExams')}</th>
                    <th
                      class="round-dg__cell-header"
                      style={{ width: '132px' }}
                    >
                      {t('exams.thResults')}
                    </th>
                    <th
                      class="round-dg__cell-header"
                      style={{ width: '160px' }}
                    >
                      {t('exams.thResolutionDate')}
                    </th>
                    <th
                      class="round-dg__cell-header"
                      style={{ width: '100px' }}
                    >
                      {t('exams.thScore')}
                    </th>
                    <th
                      class="round-dg__cell-header"
                      style={{ width: '190px' }}
                    />
                  </tr>
                </thead>

                <tbody className="round-dg__body">
                  {filteredQuestionBooksUsers?.map(item => (
                    <ExamMetricsExamListItem
                      examStudent={item}
                      key={item.id}
                    />
                  ))}
                </tbody>
              </table>
            ) : (
              <EmptyState
                type="data"
                title="Ainda não temos os resultados."
                text="Quando avaliações forem realizadas os resultados parecerão aqui."
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
