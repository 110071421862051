import React, { useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import MaterialCard from './MaterialCard';
import Modal from 'app/components/Modal';
import MaterialForm from './MaterialForm';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import Loader from 'app/components/loader';
import Swal from 'sweetalert2';

export default function MaterialTab(props) {
  const { showListLessons, lessonId } = props;
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [showMaterial, setShowMaterial] = useState([]);
  const [loading, setLoading] = useState();
  const [selectedMaterial, setSelectedMaterial] = useState();

  const currentLesson = showListLessons.filter(lesson => lesson.id == lessonId);

  const getMaterial = async () => {
    setSelectedMaterial(undefined);
    setLoading(true);

    const { data, error } = await client.fetch(
      `learning_system_files?filter[learning_system_items.id]=${lessonId}` // pegar ID da aula
    );

    setLoading(false);

    if (error) {
      toast.error('Falha ao carregar material');
    } else {
      setShowMaterial(data);
    }
  };

  const deleteMaterial = async id => {
    try {
      await client.delete([`/learning_system_files/${id}`]);
      setShowMaterial(showMaterial?.filter(Material => Material?.id !== id));
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorDeleteMaterial'));
    }
  };

  const confirmDeleteMaterial = id => {
    Swal.fire({
      title: t('projects.warningDeleteMaterial'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteMaterial(id);
      }
    });
  };

  const editMaterial = item => {
    setSelectedMaterial(item);
    setShowModal(true);
  };

  useEffect(() => {
    getMaterial();
  }, []);

  return (
    <>
      <div className="filter-bar">
        <button
          onClick={() => history.back()}
          className="filter-bar__back"
        >
          <FiChevronLeft />

          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{currentLesson.map(item => item.body)}</span>
          </span>
        </button>

        <button
          className="btn btn--primary btn--wide u-ml-auto"
          onClick={() => setShowModal(true)}
        >
          Criar material
        </button>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          {showMaterial.length === 0 ? (
            <EmptyState
              type="select"
              title="Criar materiais"
              text="Crie ou adicione materiais às aulas"
            />
          ) : (
            <div className="round-dg-wrapper">
              <table className="round-dg round-dg--light">
                <tbody className="round-dg__body">
                  {showMaterial.map((item, i) => (
                    <MaterialCard
                      showMaterial={showMaterial}
                      deleteMaterial={confirmDeleteMaterial}
                      item={item}
                      editMaterial={editMaterial}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}

      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedMaterial(undefined);
        }}
      >
        <MaterialForm
          getMaterial={getMaterial}
          lessonId={lessonId}
          setShowModal={setShowModal}
          selectedMaterial={selectedMaterial}
        />
      </Modal>
    </>
  );
}
