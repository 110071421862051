import MindMap from "app/components/MindMap";

export default function MapaMental () {
  const data = [
    {
      node: 'Redução do Número de Jovens',
      children: [
        { node: 'Planejamento Familiar', children: [] },
        { node: 'Uso de Métodos Contraceptivos', children: [] },
        { node: 'Inserção das Mulheres no Mercado de Trabalho', children: [
          { node: 'Independência Financeira', children: [] },
          { node: 'Adiamento da Maternidade', children: [] },
        ] },
      ],
    },
    {
      node: 'Aumento da População Adulta e Idosa',
      children: [
        { node: 'Maior Expectativa de Vida', children: [] },
        { node: 'Avanços na Medicina', children: [] },
        { node: 'Melhora nas Condições de Vida', children: [] },
      ],
    },
    {
      node: 'Redução da Taxa de Natalidade',
      children: [
        { node: 'Mudanças Culturais', children: [] },
        { node: 'Conscientização sobre Recurso Limitado', children: [] },
      ],
    },
    {
      node: 'Impactos Socioeconômicos',
      children: [
        { node: 'Sustentabilidade Previdenciária', children: [] },
        { node: 'Força de Trabalho', children: [] },
        { node: 'Demanda por Serviços de Saúde', children: [] },
      ],
    },
  ];
  

    return (
        <div>
            <MindMap data={data} />
        </div>
    );


}