import { FiX } from 'react-icons/fi';
import SimpleModal from './SimpleModal';

export function WarningModal(props) {
  const { show, onClose, title, description, titleFirstButton = 'Ok', titleSecondButton = 'Cancelar publicação', onSubmitFirstButton, onSubmitSecondButton, isSubmitting = false } = props;

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      contentClassName="simple-modal__content--br-md"
      hideCloseButton
    >
      <div className="schedule-alert">
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div
            className="schedule-alert__header"
            // style={{ maxWidth: 315 }}
          >
            <h3
              className="schedule-alert__title"
              style={{ lineHeight: 1.5 }}
            >
              {title}
            </h3>
            <span
              className="schedule-alert__close"
              onClick={onClose}
            >
              <FiX />
            </span>

            <div
              className="schedule-alert__body"
              style={{ marginTop: 10 }}
            >
              <p className="u-mb-0">{description}</p>
            </div>
          </div>
        </div>

        <div className="schedule-alert__footer">
          <button
            className="btn btn--outline"
            onClick={onSubmitFirstButton}
            disabled={isSubmitting}
          >
            {titleFirstButton}
          </button>
          <button
            className="btn btn--primary"
            onClick={onSubmitSecondButton}
            disabled={isSubmitting}
          >
            {titleSecondButton}
          </button>
        </div>
      </div>
    </SimpleModal>
  );
}
