import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { i18n } from 'app/components/i18n/i18n';
import Select from 'react-select';

export default function StepCourseClassroom(props) {
  const { t } = useTranslation();
  const { courseLoading, courses, classrooms, form, coursesLoading } = props;

  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LOCALE);
  }, []);

  return (
    <div className="form__columns form__columns--wrap">
      <div className="form__col">
        <label
          htmlFor="course"
          className="form__label"
        >
          *{t('filter.labelSelectCourse')}
        </label>

        <select
          className="form__select"
          name="course"
          id="course"
          value={form.values.course}
          onChange={e => form.setFieldValue('course', e.target.value)}
        >
          {coursesLoading && <option value="">{t('exams.loading')}</option>}
          {!coursesLoading && <option value="">{t('filter.chooseCourse')}</option>}
          {courses?.map(course => (
            <option
              key={course.id}
              value={course.id}
            >
              {course.title}
            </option>
          ))}
        </select>
        {form.touched.course && form.errors.course && <span style={{ color: 'red' }}>{form.errors.course}</span>}
      </div>

      <div className="form__col">
        <div>
          <label
            htmlFor="classroom"
            className="form__label"
          >
            {t('filter.labelSelectClassroom')}
          </label>

          <Select
            value={form.values.classroom}
            openMenuOnFocus
            options={[
              { value: 'blank', label: t('filter.blankLabelAll') },
              ...classrooms?.map(item => {
                return { value: item.id, label: item.institution.name + ' - ' + item.title };
              })
            ]}
            className="react-multi-select filter-bar__multi-select"
            classNamePrefix="react-multi-select"
            placeholder={t('filter.blankLabelAll')}
            noOptionsMessage={() => 'Sem opções'}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
            onChange={e => form.setFieldValue('classroom', e)}
            isDisabled={courseLoading}
          />
        </div>
      </div>
    </div>
  );
}
