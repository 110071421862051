import React, { useEffect, useState } from 'react';
import Modal from 'app/components/Modal';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';

export default function ClassroomModal(props) {
  const { t } = useTranslation();
  const { show, onClose, classrooms } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredClassrooms, setFilteredClassrooms] = useState(classrooms);

  useEffect(() => {
    if (searchTerm == '') {
      setFilteredClassrooms(classrooms);
    } else {
      setFilteredClassrooms([...classrooms?.filter(course => course.title != null && course.title.toLowerCase().includes(searchTerm.toLocaleLowerCase()))]);
    }
  }, [searchTerm, show]);

  useEffect(() => {
    if (show) {
      setSearchTerm('');
    }
  }, [show]);

  const authorityLevel = getAuthorityLevel();

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <div className="modal__simple-header">
        <h3 className="modal__simple-title modal__simple-title--with-btn">{t('journey.studentClasses')}</h3>
        <span className="widget__count">
          {classrooms?.length} <span>{t('journey.thClasses')}</span>
        </span>
      </div>

      <div className="form__row">
        <input
          aria-label={t('textsCommon.research')}
          className="form__control form__control--search-with-icon"
          style={{ width: 'auto' }}
          placeholder={t('textsCommon.research')}
          type="search"
          value={searchTerm}
          name="classroomsSearch"
          id="classroomsSearch"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="round-dg-wrapper round-dg-wrapper--modal">
        <table className="round-dg round-dg--light">
          <tbody className="round-dg__body">
            {filteredClassrooms?.map((classroom, index) => (
              <tr
                className="round-dg__row"
                key={index}
              >
                <th className="round-dg__cell-header round-dg__cell-header--row">
                  <div className="round-dg__user">
                    <div className="round-dg__inner-user">
                      <h3 className="round-dg__title">{authorityLevel !== 'student' ? <Link to={`/metricas/institucional/engajamento/`}>{classroom.title}</Link> : <div>{classroom.title}</div>}</h3>
                    </div>
                  </div>
                </th>

                <td
                  className="round-dg__cell"
                  style={{ width: '150px' }}
                >
                  {`${classroom.studentCount} ${t('metrics.students')}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
