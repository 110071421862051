import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import { useFormik } from 'formik';
import { FiChevronLeft, FiFileText, FiLink } from 'react-icons/fi';
import TextareaAutosize from 'react-autosize-textarea';
import toast from 'react-hot-toast';
import { navigate } from '@reach/router';
import Modal from 'app/components/Modal';
import Loader from 'app/components/loader';
import userAvatar from 'app/images/user-avatar.svg';
import { useSession } from 'app/hooks/useSession';
import { useTranslation } from 'react-i18next';

export function ActivityEvaluation(props) {
  const { t } = useTranslation();
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activity, setActivity] = useState();
  const [activityStudent, setActivityStudent] = useState();
  const { session } = useSession();

  useEffect(() => {
    getActivity();
    getActivityStudent();
  }, []);

  const getActivity = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.fetch(['activities', props.activityId]);
      setActivity(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      toast.error(t('toast.errorGetActivity'));
    }
  };

  const getActivityStudent = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.fetch(['activities', props.activityId, 'activity_students', props.id]);
      setActivityStudent(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      toast.error(t('toast.errorGetActivityStudent'));
    }
  };

  const confirmSubmit = () => {
    setShowModal(true);
  };

  const form = useFormik({
    initialValues: { body: '', 'activity-student-id': props.id },
    onSubmit: confirmSubmit
  });

  const submitForm = async () => {
    if (isSubmitting) {
      return false;
    }
    setIsSubmitting(true);

    const { data, error } = await client.mutate(`activities/${props.activityId}/activity_students/${props.id}`, {
      corrected: true,
      'comments-attributes': [{ ...form.values, ...{ user_id: session.user.id } }]
    });

    if (error) {
      toast.error(`${error.id} ${error.title}`);
      setIsSubmitting(false);
    } else {
      toast.success(t('toast.successExamDone'));
      setTimeout(() => {
        setIsSubmitting(false);
        history.back();
      }, 500);
    }
  };

  return (
    <>
      {isLoading || !activity || !activityStudent ? (
        <Loader />
      ) : (
        <main className="main-content main-content--block fadeIn">
          <div className="filter-bar">
            <button
              onClick={() => navigate(-1)}
              className="filter-bar__back"
            >
              <span className="card__icon-wrapper">
                <FiChevronLeft className="card__icon" />
              </span>

              <span className="filter-bar__back-container">
                <span className="filter-bar__title">{t('activity.activityOf', { activityStudent: activityStudent?.user?.name })}</span>
              </span>
            </button>
          </div>

          <div className="page">
            <div className="page__content">
              <div className="activity-card__header">
                <img
                  className="activity-card__avatar"
                  src={activityStudent?.user?.image || userAvatar}
                  alt="User avatar"
                />
                <div className="activity-card__group">
                  <h2 className="activity-card__title activity-card__title--md">{activityStudent?.user?.name}</h2>
                  <span className="activity-card__info">{activity.title}</span>
                  <br />
                  <span className="activity-card__info">
                    {t('textsCommon.module')} {activity.modulus} | {t('textsCommon.unit')} {activity.unit} | {activity['class-hours'] < 2 ? `${activity['class-hours']} ${t('tasks.hour')}` : `${activity['class-hours']} ${t('tasks.hours')}`}
                  </span>
                </div>
              </div>

              <div className="activity-card__body">
                <h3 className="activity-card__subtitle">{t('textsCommon.comments')}</h3>
                <div className="activity-card__description">
                  {activityStudent.comments.map(comment => (
                    <p>{comment.body}</p>
                  ))}

                  <a
                    href={activityStudent['activity-file']?.url || activityStudent['activity-file']?.file}
                    target="_blank"
                    className="btn btn--link u-pl-0"
                  >
                    <span className="activity-card__tag u-mr-2">{activityStudent['activity-file']?.url ? <FiFileText /> : <FiLink />}</span>
                    {activityStudent['activity-file']?.url ? t('activity.sendUrl') : t('activity.sendFiles')}
                  </a>
                </div>
              </div>

              <form
                className="form"
                onSubmit={form.handleSubmit}
              >
                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="body"
                  >
                    {t('activity.teacherEvaluation')}
                  </label>

                  <TextareaAutosize
                    id="body"
                    name="body"
                    placeholder={t('activity.placeholderTeacherEvaluation')}
                    onChange={form.handleChange}
                    defaultValue={form.values.body}
                  />
                </div>

                <button
                  className="btn btn--primary btn--wide"
                  type="submit"
                >
                  {t('activity.submitEvaluation')}
                </button>
              </form>
            </div>

            <div className="page__aside" />
          </div>

          <Modal
            show={showModal}
            onClose={() => setShowModal(false)}
          >
            <h3 className="modal__dialog-title">{t('warning.warningSendEvaluation')}</h3>

            <div className="form__columns">
              <div className="form__col u-text-right">
                <button
                  onClick={() => setShowModal(false)}
                  className="btn btn--outline btn--wide"
                  type="button"
                >
                  {t('button.cancel')}
                </button>
              </div>
              <div className="form__col">
                <button
                  onClick={() => submitForm()}
                  className="btn btn--primary btn--wide"
                  type="button"
                >
                  {t('button.submit')}
                </button>
              </div>
            </div>
          </Modal>
        </main>
      )}
    </>
  );
}
