import React, { useState, useEffect } from 'react';
import Modal from 'app/components/Modal';
import { useClient } from 'jsonapi-react';
import { t } from 'i18next';

export default function ParticipantsModal(props) {
  const { showModal, onClose, auditorium } = props;
  const [auditoriumDetailed, setAuditoriumDetailed] = useState();
  const client = useClient();

  const getAuditoriumDetailed = async () => {
    const { data } = await client.fetch(`auditoriums/${auditorium.id}?detailed=true`);
    setAuditoriumDetailed(data);
  };

  useEffect(() => {
    if (showModal) {
      getAuditoriumDetailed();
    }
  }, [showModal]);

  return (
    <Modal
      show={showModal}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <h2 className="modal__simple-title">{t('exams.thParticipants')}</h2>

      <div
        className="round-dg-wrapper"
        style={{ height: '200px', flexGrow: '1' }}
      >
        <table className="round-dg round-dg--light">
          <tbody className="round-dg__body">
            {auditoriumDetailed?.courses?.map((item, i) => (
              <tr
                className="round-dg__row"
                key={i}
              >
                <th
                  className="round-dg__cell-header round-dg__cell-header--row"
                  style={{ width: '400px' }}
                >
                  <div className="round-dg__user">
                    <div className="round-dg__inner-user">
                      <h3 className="round-dg__title">{item.title}</h3>
                    </div>
                  </div>
                </th>
                <td
                  className="round-dg__cell"
                  style={{ width: '150px' }}
                >
                  {`${item['student-count']} ${t('textsCommon.students')}`}
                </td>
                <td
                  className="round-dg__cell"
                  style={{ width: '150px' }}
                >
                  {`${item['non-student-count']} ${t('textsCommon.teachers')}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
