import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal, { SimpleModal } from 'app/components/Modal';
import MatterCard from './MatterCard';
import EmptyState from 'app/components/EmptyState';

export default function MatterModal(props) {
  const { show, onClose, taxonomies: initialTaxonomies } = props;
  const [taxonomies, setTaxonomies] = useState(initialTaxonomies || []);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState({}); // Armazena os itens selecionados

  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      setSearchTerm('');
      setTaxonomies(initialTaxonomies); // Resetar a lista de taxonomias quando o modal for exibido
      const initialSelected = {};
      initialTaxonomies.forEach(taxonomy => {
        initialSelected[taxonomy.id] = taxonomy['manual-add'] || false;
      });
      setSelectedItems(initialSelected); // Inicializa o estado de seleção com base nas taxonomias
    }
  }, [show, initialTaxonomies]);

  const handleUpdate = updatedTaxonomy => {
    // Atualizar a lista de taxonomias com a nova taxonomia modificada
    const updatedTaxonomies = taxonomies.map(taxonomy => (taxonomy.id === updatedTaxonomy.id ? updatedTaxonomy : taxonomy));
    setTaxonomies(updatedTaxonomies);
  };

  const handleSelectChange = (taxonomyId, isSelected) => {
    setSelectedItems(prevSelected => ({
      ...prevSelected,
      [taxonomyId]: isSelected,
    }));
  };

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      contentClassName="simple-modal__content--lg"
    >
      <h3 className="modal__simple-title">Adicione matérias ao seu guia</h3>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="search"
        >
          {t('textsCommon.matters')}
        </label>

        <input
          aria-label={t('textsCommon.research')}
          className="form__control form__control--search-with-icon"
          style={{ width: 'auto', maxWidth: '100%' }}
          placeholder={t('textsCommon.research')}
          type="search"
          value={searchTerm}
          name="search"
          id="search"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>

      <div
        className="round-dg-wrapper round-dg-wrapper--modal"
        style={{ height: 420 }}
      >
        <table
          className="round-dg round-dg--light"
          style={{ minWidth: 575 }}
        >
          <tbody className="round-dg__body">
            {taxonomies && taxonomies.filter(matter => matter['taxonomy-name'].toLowerCase().includes(searchTerm.toLowerCase())).length === 0 ? (
              <EmptyState
                type="data"
                title="Não há matérias disponíveis"
                text=" "

              />
            ) : (
              taxonomies
                .filter(matter => matter['taxonomy-name'].toLowerCase().includes(searchTerm.toLowerCase()))
                .map((matter, index) => {
                  return (
                    <MatterCard
                      key={index}
                      taxonomy={matter}
                      isSelected={selectedItems[matter.id]} // Passa o estado de seleção
                      onUpdate={handleUpdate}
                      onSelectChange={handleSelectChange} // Função para alterar o estado de seleção
                    />
                  );
                })
            )}
          </tbody>
        </table>
      </div>
    </SimpleModal>
  );
}
