import React from 'react';
import { FiFileText, FiHeadphones, FiVideo, FiX, FiTrash2, FiMoreHorizontal } from 'react-icons/fi';
import { Link } from '@reach/router';
import Dropdown from 'app/components/Dropdown';
import { useSession } from 'app/hooks/useSession';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { useTranslation } from 'react-i18next';

export const DocumentsCard = ({ document, onDelete }) => {
  const { t } = useTranslation();
  const { session } = useSession();
  const authorityLevel = getAuthorityLevel(session);

  function icon(type) {
    switch (type) {
      case 'Áudio':
        return <FiHeadphones />;
      case 'Vídeo':
        return <FiVideo />;
      default:
        return <FiFileText />;
    }
  }

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <div className="card__header">
          <div className="card__inner-header">
            <div className="card__icon-wrapper">{icon(document.type)}</div>
            <h2
              className="card__title"
              style={{ flexGrow: 'initial' }}
            >
              {document.title}
            </h2>
            <p className="card__meta">{document.files.length > 0 && document.files[0]?.size}</p>
            <span className="documents-card__type">{document['content-type'] && document['content-type']['name']}</span>
          </div>
        </div>
      </th>

      <td className="round-dg__cell">
        {document.taxonomies.length > 0 && (
          <div className="card__badges card__badges--one-line">
            {document.taxonomies.length <= 3 &&
              document.taxonomies.map(t => (
                <span
                  className="badge badge--primary"
                  key={t.id}
                  title={t.name}
                >
                  {t.name}
                </span>
              ))}

            {document.taxonomies.length > 3 && (
              <>
                {document.taxonomies.slice(0, 3).map(t => (
                  <span
                    className="badge badge--primary"
                    key={t.id}
                    title={t.name}
                  >
                    {t.name}
                  </span>
                ))}
                <span
                  className="badge badge--more"
                  title={document.taxonomies.slice(3).map(t => ` ${t.name}`)}
                >
                  +{document.taxonomies.length - 3}
                </span>
              </>
            )}
          </div>
        )}
      </td>

      {/* Tags de cursos relacionados
        <div className="documents-card__body">
        {document?.courses?.map(course => (
          <span className="badge badge--secondary">
            {`${course.title} `}
            <button className="badge__btn">
              <FiX />
            </button>
          </span>
        ))}
      </div> */}

      {authorityLevel === 'student' && document.files.length > 0 && (
        <td
          className="round-dg__cell"
          style={{ width: '110px' }}
        >
          <Link
            className="btn btn--outline"
            to={`/materiais/${document.id}`}
          >
            {t('button.view')}
          </Link>
        </td>
      )}

      {authorityLevel === 'student' && document.files.length <= 0 && (
        <td
          className="round-dg__cell"
          style={{ width: '110px' }}
        >
          {t('textsCommon.unavailable')}
        </td>
      )}

      {authorityLevel !== 'student' && (
        <td
          className="round-dg__cell"
          style={{ width: '66px' }}
        >
          <Dropdown>
            <Dropdown.Toggle>
              <FiMoreHorizontal />
            </Dropdown.Toggle>

            <Dropdown.Content>
              {/* <Dropdown.Item onClick={selectMaterial}>{t('button.edit')}</Dropdown.Item> */}
              {document.files.length > 0 && <Dropdown.Item to={`/materiais/${document.id}`}>{t('button.view')}</Dropdown.Item>}
              <Dropdown.Item
                onClick={() => {
                  Swal.fire({
                    title: t('projects.warningDeleteFileTitle'),
                    confirmButtonText: t('button.yes'),
                    showDenyButton: true,
                    denyButtonText: t('button.no'),
                    showCloseButton: true
                  }).then(res => {
                    if (res.isConfirmed) {
                      deleteDocument();
                    }
                  });
                }}
              >
                {t('textsCommon.delete')}
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </td>
      )}
    </tr>
  );
};
