import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import accessingPng from '../../images/onboarding/auditorium/accessing.png';
import monitoringPng from '../../images/onboarding/auditorium/monitoring.png';
import interactingPng from '../../images/onboarding/auditorium/interacting.png';
import teacherSelectPng from '../../images/onboarding/auditorium/teacher-select.png';

export function AuditoriumOnboarding() {
  const { t } = useTranslation();
  
  return (
    <section>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.accessingAnAuditorium')}</h1>
        <p className="text-option-onboarding">{t('onboarding.accessingAnAuditoriumDesc')}</p>

          <img
            src={accessingPng}
            className="w-auto"
          />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.monitoringTheMonitor')}</h1>
        <p className="text-option-onboarding">{t('onboarding.monitoringTheMonitorDesc')}</p>

        <img src={monitoringPng} />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.interactingWithChat')}</h1>
        <p className="text-option-onboarding">{t('onboarding.interactingWithChatDesc')}</p>

        <img
          src={interactingPng}
          className="w-70"
        />
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.accessFiles')}</h1>
        <p className="text-option-onboarding">{t('onboarding.accessFilesDesc')}</p>

        <ShadowContainer width="50%">
          <img src={teacherSelectPng} />
        </ShadowContainer>
      </div>
    </section>
  );
}
