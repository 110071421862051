import React from 'react';
import { ReactComponent as Star } from 'app/images/icons/fi-star.svg';

export default function StarRating({ rating = 1, style }) {
  return (
    <div className="star-rating" style={{ width: '100px', ...style }}>
      {[...Array(5)].map((star, index) => {
        index += 1;

        return (
          <button
            type="button"
            key={index}
            className={
              index <= rating ? 'star-rating__btn on' : 'star-rating__btn off'
            }
            style={{ cursor: 'default', padding: '0' }}
          >
            <Star className="star-rating__star" />
          </button>
        );
      })}
    </div>
  );
}
