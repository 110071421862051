import { t } from 'i18next';
import React from 'react';

export default function ExamMetricsKpis(props) {
  const { currentExam, examStudents } = props;
  const approvedStudents = examStudents?.filter(item => item.user['question-books-users'][0].status === 'approved').length;

  function averageResolutionTime() {
    let totalSeconds = 0;
    examStudents.forEach(item => {
      const [hours, minutes, seconds] = item?.user?.['question-books-users']?.[0]?.['time-resolution']?.split(':')?.map(Number);
      totalSeconds += hours * 3600 + minutes * 60 + seconds;
    });
    const avgSeconds = totalSeconds / examStudents.length;
    const hours = Math.floor(avgSeconds / 3600);
    const minutes = Math.floor((avgSeconds - hours * 3600) / 60);
    const seconds = Math.floor(avgSeconds - hours * 3600 - minutes * 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  const getAverage = value => {
    const mediumScore = (
      examStudents?.reduce((acc, curr) => {
        const parsedScore = parseFloat(curr?.user?.['question-books-users']?.[0]?.[value]);

        return parseFloat(acc) + parsedScore;
      }, 0) / examStudents?.length
    ).toFixed(2);

    return mediumScore;
  };

  const metricsList = [
    {
      id: 1,
      title: t('exams.participation'),
      description: examStudents?.length
    },
    {
      id: 2,
      title: t('exams.aproved'),
      description: approvedStudents
    },
    {
      id: 3,
      title: t('exams.passingScoreTitle'),
      description: currentExam?.['passing-score'] ? currentExam?.['passing-score'] : '--'
    },
    {
      id: 4,
      title: t('exams.globalAvarage'),
      description: isNaN(getAverage('score')) ? '0.00' : getAverage('score')
    },
    {
      id: 5,
      title: 'Índice de acerto',
      description: `${getAverage('score-percentage')}%`
    },
    {
      id: 6,
      title: t('exams.questionNumber'),
      description: currentExam?.['question-count']
    },
    {
      id: 7,
      title: t('exams.avarageTime'),
      description: averageResolutionTime() != 'NaN:NaN:NaN' ? <p className="info-widget__value text-primary font-bold">{averageResolutionTime()} </p> : '--'
    }
  ];

  return (
    <div className="widget-wrapper">
      <div className="">
        <div className="widget__body">
          <div
            className="widget-wrapper"
            style={{ justifyContent: 'center' }}
          >
            {metricsList.map(item => (
              <div
                key={item.id}
                className="info-widget info-widget--t2 shadow-md card-metrics-kpi"
              >
                <div className="info-widget__header">
                  <h3 className="info-widget__title text-neutral text-md">{item.title}</h3>
                </div>
                <div className="info-widget__body">
                  <p className="info-widget__value text-primary font-bold">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
