import { useTranslation } from 'react-i18next';
import React from 'react';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

function ClassroomTabNavBar({ activeTabs, setActiveTabs, abilities }) {
  const { t } = useTranslation();

  return (
    <div className="tab">
      <div
        className={`tab__link ${activeTabs === 'courseClassroomFiles' ? 'active' : ''}`}
        onClick={() => setActiveTabs('courseClassroomFiles')}
      >
        {abilities?.['cursos-materiais'] ? 'Materiais e fórum' : 'Fórum'}
      </div>

      <div
        className={`tab__link ${activeTabs === 'courseClassroomQuestionBooks' ? 'active' : ''}`}
        onClick={() => setActiveTabs('courseClassroomQuestionBooks')}
      >
        {t('lms.toggleQuestionBooks')}
      </div>
      {abilities?.['cursos-ativ-complementar'] && (
        <div
          className={`tab__link ${activeTabs === 'courseClassroomActivities' ? 'active' : ''}`}
          onClick={() => setActiveTabs('courseClassroomActivities')}
        >
          {t('lms.toggleActivity')}
        </div>
      )}
    </div>
  );
}

export default ClassroomTabNavBar;
