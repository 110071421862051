import * as React from 'react';

function IconSimulated(props) {
  return (
    <svg
      stroke="currentColor"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.385 5.242a.477.477 0 10-.674-.674L5.366 5.912l-.378-.344a.476.476 0 00-.642.702l.714.653a.476.476 0 00.657-.015l1.668-1.666zm0 4.087a.476.476 0 010 .675L5.718 11.67a.476.476 0 01-.658.014l-.714-.653a.476.476 0 01.642-.703l.378.346L6.71 9.33a.476.476 0 01.674 0zm1.567.576a.476.476 0 000 .952h2.381a.476.476 0 100-.952h-2.38zm-.476-4.286a.476.476 0 01.476-.476h2.381a.476.476 0 110 .952h-2.38a.476.476 0 01-.477-.476zM4.191 1.333A2.857 2.857 0 001.333 4.19v7.62a2.857 2.857 0 002.858 2.857h7.619a2.857 2.857 0 002.857-2.857V4.19a2.857 2.857 0 00-2.857-2.857H4.19zM2.286 4.19A1.905 1.905 0 014.19 2.286h7.619a1.905 1.905 0 011.904 1.904v7.62a1.905 1.905 0 01-1.904 1.904H4.19a1.905 1.905 0 01-1.904-1.905V4.19z"
        fill="#442B8C"
      />
    </svg>
  );
}

export default IconSimulated;
