import React from 'react';
import { FiChevronLeft, FiCalendar, FiHome, FiFolder, FiCoffee, FiCheckCircle, FiActivity, FiClipboard, FiEdit3, FiStar, FiVideo } from 'react-icons/fi';
import { navigate, Link } from '@reach/router';
import helpPlay from './img/help-play.svg';
import HelpBg from './HelpBg';
import HelpFooter from './HelpFooter';
import HelpHeader from './HelpHeader';

export default function HelpViewVideo(props) {
  const { id } = props;

  const allVideos = [
    {
      id: 'SS_aqXW4ob0',
      icon: <FiCoffee />,
      title: 'Fórum'
    },
    {
      id: 'g44jc7HO7YY',
      icon: <FiFolder />,
      title: 'Arquivos'
    },
    {
      id: '0SrjT4xP2-g',
      icon: <FiCheckCircle />,
      title: 'Questões'
    },
    {
      id: '_RHp2GVpIj4',
      icon: <FiClipboard />,
      title: 'Projetos'
    },
    {
      id: 'I16j5rMqt4s',
      icon: <FiVideo />,
      title: 'Aulas'
    },
    {
      id: 'l-9Am7IbsEY',
      icon: <FiEdit3 />,
      title: 'Ativ. complementares'
    },
    {
      id: 'v1htLr1lsAk',
      icon: <FiActivity />,
      title: 'Métricas gerais'
    },
    {
      id: '_xkxl1uzQSI',
      icon: <FiCalendar />,
      title: 'Tarefas'
    },
    {
      id: 'oO3DxD_nqws',
      icon: <FiHome />,
      title: 'Jornada'
    },
    {
      id: '2elkHvanXn4',
      icon: <FiStar />,
      title: 'Questionário'
    }
  ];

  const videos = allVideos.filter(v => v.id !== id);

  return (
    <div className="help-center">
      <HelpBg />
      <HelpHeader />

      <main className="help-main">
        <div className="help-container">
          <div className="filter-bar filter-bar--borderless">
            <Link
              to="/ajuda"
              className="filter-bar__back"
            >
              <FiChevronLeft />

              <span className="filter-bar__back-container">
                <span className="filter-bar__title">FEED: Conheça a rede social da Estudologia</span>
              </span>
            </Link>
          </div>

          <figure className="help-video__wrapper">
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${id}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </figure>

          <div className="help-video__videos">
            {videos.map(v => (
              <div
                className="help-video"
                key={v.id}
                onClick={() => {
                  navigate(`/ajuda/${v.id}`);
                  window.scrollTo(0, 0);
                }}
              >
                <header className="help-video__header">
                  <span className="help-video__icon">{v.icon}</span>
                  <h3 className="help-video__title">{v.title}</h3>
                </header>
                <div className="help-video__body">
                  <img
                    src={`https://img.youtube.com/vi/${v.id}/maxresdefault.jpg`}
                    alt={v.title}
                    className="help-video__thumb"
                  />
                  <img
                    src={helpPlay}
                    alt=""
                    className="help-video__play"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>

      <HelpFooter />
    </div>
  );
}
