import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'app/components/Dropdown';
import { useSession } from 'app/hooks/useSession';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { ProjectsContext } from './provider';
import { FiMoreHorizontal } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import Modal from 'app/components/Modal';
import moment from 'moment';
import 'moment/locale/pt';
import EventForm from './eventForm';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';

export default function ProjectSchedule() {
  const { t } = useTranslation();
  const client = useClient();
  const { session } = useSession();
  const authorityLevel = getAuthorityLevel(session);
  const { selectedProject, getProjects, getEvents, events, setEvents, editingEvent, setEditingEvent } = useContext(ProjectsContext);
  const [showFormEvent, setShowFormEvent] = useState(false);
  const [currentEvent, setCurrentEvent] = useState();
  const [showModal, setShowModal] = useState({
    newEvent: false,
    displayEvent: false
  });
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEvents();
  }, []);

  const closeFormEvent = () => {
    setShowFormEvent(false);
    setEditingEvent(null);
    getEvents();
  };

  const editEvent = event => {
    setEditingEvent(event);
    setShowFormEvent(true);
  };

  const eventsCopy = structuredClone(events);

  const confirmDeleteEvent = event => {
    Swal.fire({
      title: t('projects.warningDeleteEvent'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteEvent(event);
        toast.success(t('toast.successDeleteEvent'));
      }
    });
  };

  const deleteEvent = async event => {
    const { error } = await client.delete(['events', event.id]);
    if (!error) {
      window.location.reload(false);
    } else {
    }
  };

  return (
    <section className="new-questions__widget">
      <div className="new-questions__widget-header">
        <h3 className="new-questions__widget-title">{t('projects.titleProjectSchedule')}</h3>
        {!selectedProject?.approved && getPermission('Criar eventos', 'Projetos') ? (
          <Dropdown>
            <Dropdown.Toggle>
              <FiMoreHorizontal />
            </Dropdown.Toggle>

            <Dropdown.Content>
              <Dropdown.Item onClick={() => setShowFormEvent(true)}>{t('projects.newEvent')}</Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        ) : null}
      </div>

      <div className="schedule-wrapper">
        {false && (
          <EmptyState
            type="date"
            title="Novo Evento"
            text={
              <span>
                Ainda não há eventos no projeto. Clique em <FiMoreHorizontal /> ao lado de Cronograma do projeto, para criar novo evento.
              </span>
            }
          />
        )}

        <ul className="schedule">
          {selectedProject?.approved && (
            <li className="schedule__item schedule__item">
              <div className="schedule__date schedule__date--success">04/06</div>
              <div className="schedule__infos">
                <div className="schedule__header">
                  <h4 className="schedule__title">Projeto aprovado</h4>
                </div>
                <div className="schedule__hour">Dia inteiro</div>
              </div>
            </li>
          )}
          {eventsCopy.reverse().map((event, index) => (
            <li
              className="schedule__item"
              key={index}
            >
              <div className="schedule__date">{moment(event?.['initial-date']).format('DD/MM')}</div>
              <div className="schedule__infos">
                <div className="schedule__header">
                  <h4
                    className="schedule__title"
                    title="Data limite da atividade complementar"
                  >
                    {event.title}
                  </h4>

                  <Dropdown>
                    <Dropdown.Toggle>
                      <FiMoreHorizontal />
                    </Dropdown.Toggle>

                    <Dropdown.Content>
                      {getPermission('Editar eventos', 'Projetos') && !selectedProject?.approved ? <Dropdown.Item onClick={() => editEvent(event)}>{t('button.edit')}</Dropdown.Item> : null}
                      {getPermission('Excluir eventos', 'Projetos') && !selectedProject?.approved ? <Dropdown.Item onClick={() => confirmDeleteEvent(event)}>{t('button.delete')}</Dropdown.Item> : null}

                      <Dropdown.Item onClick={() => setCurrentEvent(event)}>{t('button.expand')}</Dropdown.Item>
                    </Dropdown.Content>
                  </Dropdown>
                </div>
                <div className="schedule__hour">
                  {event['initial-time']} - {event['end-time']}
                </div>
              </div>
            </li>
          ))}
          <li className="schedule__item schedule__item--disabled">
            <div className="schedule__date">{moment(selectedProject?.['created-at']).format('DD/MM')}</div>
            <div className="schedule__infos">
              <div className="schedule__header">
                <h4 className="schedule__title">{t('projects.titleCreateProject')}</h4>
              </div>
              <div className="schedule__hour">{t('projects.titleFullDay')}</div>
            </div>
          </li>
        </ul>
      </div>

      <Modal
        show={showFormEvent}
        onClose={closeFormEvent}
      >
        <EventForm
          onClose={() => setShowFormEvent(false)}
          editingEvent={editingEvent}
          events={events}
          setEvent={setEvents}
          selectedProject={selectedProject}
        />
      </Modal>

      <Modal
        show={currentEvent}
        onClose={() => setCurrentEvent(null)}
      >
        {currentEvent && (
          <>
            <h2 className="modal__simple-title">{currentEvent.title}</h2>

            <img
              className="modal__img-schedule"
              src={currentEvent.image?.url}
              alt="banner evento"
            />

            <p className="modal__date-schedule">{moment(currentEvent['initial-date']).format(t('datesForm.formatDate'))}</p>
            <p className="modal__hour-schedule">
              {currentEvent['initial-time']} - {currentEvent['end-time']}
            </p>

            <div className="modal__text-schedule">{currentEvent.description}</div>

            {currentEvent.url && (
              <div className="form">
                <div className="form__row">
                  <label htmlFor="linkEvent">Link</label>

                  <div className="form__input-group">
                    <input
                      className="form__control"
                      id="linkEvent"
                      name="linkEvent"
                      type="url"
                      value={currentEvent.url}
                      readOnly
                      disabled
                    />
                    <a
                      className="btn btn--dark btn--wide"
                      href={currentEvent.url}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Entrar
                    </a>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Modal>
    </section>
  );
}
