import { useFormik } from 'formik';
import { t } from 'i18next';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';


export default function CapabilityCard(props) {
  const { icon, title, subtitle, formik, index, checkboxes } = props;
  const [all, setAll] = useState(null);

  const handleChange = id => {
    let roleGroupIds = formik.values['role-group-item-ids'];
    if (roleGroupIds.includes(id)) {
      roleGroupIds = roleGroupIds.filter(groupId => groupId !== id)
    } else {
      roleGroupIds.push(id);
    }
    formik.setFieldValue('role-group-item-ids', [...roleGroupIds]);
  };

  const updateRoleGroupItemIds = (checkboxes, formik) => {
    let items = checkboxes
    let roleGroupItemIds = formik.values['role-group-item-ids']

    let newIds = items.filter(item => !roleGroupItemIds.includes(item.id)).map(item => item.id);
    if (newIds.length > 0) {
      roleGroupItemIds.push(...newIds)
    } else {
      let itemsToRemove = items.filter(item => roleGroupItemIds.includes(item.id)).map(item => item.id);
      roleGroupItemIds = roleGroupItemIds.filter(id => !itemsToRemove.includes(id))
    }
    formik.setFieldValue('role-group-item-ids', [...roleGroupItemIds])
    setAll(items.every(item => roleGroupItemIds.includes(item.id)))
  }

  useEffect(() => {
    setAll(checkboxes?.every(item => formik.values['role-group-item-ids']?.includes(item.id)))
  }, [])

  return (
    <div className="check-card">
      <div className="check-card__header">
        {icon && <span className="check-card__icon">{icon}</span>}
        <div className="check-card__inner-header">
          <h3 className="check-card__title">{title || t('textsCommon.title')}</h3>
          <p className="check-card__subtitle">{subtitle || 'Subtítulo'}</p>
        </div>
        <div className='check-card__header'>
          <input
            name={title}
            className="form__check-input"
            id={index}
            type="checkbox"
            checked={all}
            onChange={() => updateRoleGroupItemIds(checkboxes, formik)}
          />
          <label htmlFor={index}>Selecionar todas</label>
        </div>
      </div>

      <div className="check-card__body">
        <div
          className={
            icon
              ? 'check-card__check-wrapper check-card__check-wrapper--with-icon'
              : 'check-card__check-wrapper'
          }
        >
          {checkboxes.map((c, i) => {
            return (
              <div className="form__check" key={i}>
                <input
                  name={c.name}
                  className="form__check-input"
                  id={`groupItem-${c.id}`}
                  type="checkbox"
                  checked={formik.values['role-group-item-ids'].includes(c.id)}
                  onChange={() => handleChange(c.id)}
                />
                <label htmlFor={`groupItem-${c.id}`}>{c.name}</label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}
