import React, { useState } from 'react';
import { FiMoreHorizontal, FiEdit3, FiMessageCircle, FiFolder, FiCheckCircle } from 'react-icons/fi';
import { SlNotebook } from 'react-icons/sl';
import dragAndDrop from 'app/images/icons/drag-and-drop.svg';
import Dropdown from 'app/components/Dropdown';
import { navigate } from '@reach/router';
import Swal from 'sweetalert2';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { useFormik } from 'formik';
import moment from 'moment';
import { WarningModal } from 'app/components/Modal/WarningModal';
import getPermission from 'app/utils/getPermission';

export default function LessonCard({ lesson, currentModuleId, selectedDisciplineId, setLessons, courseId, coursePublished, modulePublished }) {
  const client = useClient();
  const [requiredLesson, setRequiredLesson] = useState(lesson?.unrequired);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const now = new Date();

  const lessonForm = useFormik({
    initialValues: {
      published: lesson?.published ?? false
    }
  });

  const deleteLesson = async () => {
    try {
      await client.delete(`/learning_system_items/${lesson.id}`);
      toast.success('Aula excluída com sucesso!');
      setLessons(prevState => prevState.filter(item => item.id !== lesson.id));
    } catch (e) {
      toast.error('Erro ao deletar aula');
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: t('projects.warningDeleteLesson'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteLesson();
      }
    });
  };

  const updateLesson = async () => {
    const { error } = await client.mutate(['/learning_system_items', lesson.id], {
      unrequired: !requiredLesson
    });
    if (error) {
      toast.error('Erro ao atualizar obrigatoriedade da aula');
    } else {
      if (!requiredLesson === true) {
        toast.success(t('toast.successSetLessonNonMandatory'));
      } else {
        toast.success(t('toast.successSetLessonMandatory'));
      }
    }
  };

  const videoId = () => {
    const lessonUrl = lesson.url;

    if (['youtube.com', 'youtu.be'].some(e => lessonUrl.includes(e))) {
      const id = lessonUrl.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=))((\w|-){11})/)[1];
      return { id, type: 'youtube' };
    }
    if (lessonUrl.includes('vimeo.com')) {
      const urlParts = lessonUrl.split('/');
      const id = urlParts[urlParts.length - 1];
      return { id, type: 'vimeo' };
    }
    if (['genially.com', 'genial.ly'].some(e => lessonUrl.includes(e))) {
      const id = lessonUrl.match(/(?:view\.genially\.com\/|view\.genial\.ly\/)([a-zA-Z0-9]+)/)[1];
      console.log(id);
      return { id, type: 'genially' };
    }
  };

  const videoInfo = videoId();

  const handleUpdateScheduleLesson = async () => {
    lessonForm.setFieldValue('published', !lessonForm.values.published);

    const { error } = await client.mutate(['/learning_system_items', lesson.id], {
      published: !lessonForm.values.published
    });

    if (error) {
      toast.error('Erro ao publicar aula');
      return;
    }

    toast.success(!lessonForm.values.published ? 'Aula publicada com sucesso!' : 'Aula despublicada com sucesso!');
  };

  const handleNavigateToLesson = () => {
    if (!coursePublished && !modulePublished) {
      setIsOpenModal(true);
    } else {
      navigate(`/show-course/${currentModuleId}/classroom/${lesson?.id}`);
    }
  };

  const handlePublishCourseAndModule = async () => {
    setIsSubmitting(true);
    try {
      await client.mutate(`courses/${courseId}`, {
        published: true
      });
      await client.mutate(['learning_systems', currentModuleId], {
        publish: true
      });

      toast.success('Curso e módulo publicado com sucesso!');
      navigate(`/show-course/${currentModuleId}/classroom/${lesson?.id}`);
    } catch (e) {
      console.error(e);
      toast.error('Erro ao publicar');
      setIsOpenModal(false);
      setIsSubmitting(false);
    }
  };

  const hasPermissionToPublish = getPermission('Gerenciar conteúdo', 'Cursos');

  const splittedDate = lesson?.['published_at']?.split(':');

  if (splittedDate?.[1] < 59) {
    splittedDate[1] = Number(splittedDate[1]) + 1;
  }
  const parsedDate = splittedDate?.join(':');

  return (
    <div className="card card--h card--discipline">
      <div className="card__header">
        <img
          src={dragAndDrop}
          alt="ícone de arrastar"
          style={{
            width: '10px',
            cursor: 'move',
            cursor: 'grab',
            marginRight: '12px'
          }}
        />
        <div className="card__video">
          {videoInfo && videoInfo.type === 'youtube' && <iframe src={`https://www.youtube.com/embed/${videoInfo.id}`} />}
          {videoInfo && videoInfo.type === 'vimeo' && <iframe src={`https://player.vimeo.com/video/${videoInfo.id}`} />}
          {videoInfo && videoInfo.type === 'genially' && <iframe src={`https://view.genially.com/${videoInfo.id}`} />}
        </div>
      </div>

      <div style={{ flexGrow: '1', maxWidth: '100%' }}>
        <div className="card__inner-header">
          <h3
            className="card__title"
            title={lesson?.body}
          >
            {lesson?.body}
          </h3>

          <div className="card__text-schedule-wrapper">
            {parsedDate && new Date(parsedDate) > now && <p className="card__text-schedule">Agendada para {moment(lesson['published_at']).format('DD/MM/YYYY')}</p>}

            {hasPermissionToPublish && (
              <div className="form__check form__switch u-mb-0">
                <input
                  className="form__check-input"
                  id={`published-${lesson.id}`}
                  name={`published-${lesson.id}`}
                  type="checkbox"
                  checked={lessonForm.values.published}
                  onChange={handleUpdateScheduleLesson}
                />
                <label htmlFor={`publish-${lesson.id}`}>Publicar</label>
              </div>
            )}
          </div>

          <Dropdown>
            <Dropdown.Toggle>
              <FiMoreHorizontal />
            </Dropdown.Toggle>

            <Dropdown.Content>
              <Dropdown.Item onClick={() => navigate(`/lms/criar/aula/${selectedDisciplineId}/nome/${lesson?.id}/${courseId}`, { state: {moduleId: currentModuleId, editLesson: true } })}>
                {t('button.edit')}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDelete()}>{t('button.delete')}</Dropdown.Item>
              <Dropdown.Item onClick={handleNavigateToLesson}>{t('button.view')}</Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </div>

        <div className="card__switcher">
          <div className="form__check form__switch">
            <input
              className="form__check-input"
              id="requiredLesson"
              name="requiredLesson"
              type="checkbox"
              checked={!requiredLesson}
              onChange={() => {
                setRequiredLesson(!requiredLesson);
                updateLesson();
              }}
            />
            <label> {t('textsCommon.mandatoryLesson')}</label>
          </div>
        </div>

        <div className="card__badges">
          {lesson.taxonomies.length === 0 ? (
            <span className="badge badge--primary">{lesson?.taxonomy?.name}</span>
          ) : (
            lesson?.taxonomies?.map((item, index) => (
              <span
                className="badge badge--primary"
                title={item.name}
                key={index}
              >
                {item.name}
              </span>
            ))
          )}
          {lesson?.taxonomies?.length - 3 > 0 ? (
            <span
              className="badge badge--more"
              title={lesson?.taxonomies?.slice(3).map(t => ` ${t.name}`)}
            >
              {lesson?.taxonomies?.length - 3 === 0 ? '' : `+${lesson?.taxonomies?.length - 3}`}
            </span>
          ) : null}
        </div>

        <div className="card__columns card__columns--h u-mb-0">
          <p
            className="card__count"
            title={lesson?.learning_system_activities_count + ' atividades'}
            aria-label={lesson?.learning_system_activities_count}
          >
            <FiEdit3 className="card__icon" /> {lesson?.learning_system_activities_count}
          </p>
          <p
            className="card__count"
            title={lesson?.learning_system_files?.length + ' materiais'}
            aria-label={lesson?.learning_system_files?.length}
          >
            <FiFolder className="card__icon" /> {lesson?.learning_system_files?.length}
          </p>
          <p
            className="card__count"
            title={lesson?.learning_system_question_books_count + ' apostilas'}
            aria-label={lesson?.learning_system_question_books_count}
          >
            <SlNotebook className="card__icon" /> {lesson?.learning_system_question_books_count}
          </p>
          <p
            className="card__count"
            title={lesson?.quiz_items?.length + ' perguntas em tempo real'}
            aria-label={lesson?.quiz_items?.length}
          >
            <FiCheckCircle className="card__icon" /> {lesson?.quiz_items?.length}
          </p>
        </div>
      </div>

      <WarningModal
        show={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        title="A aula não poderá ser visualizada"
        description="O curso e o módulo correspondente a aula ainda não estão publicados. Deseja publicá-los agora?"
        titleSecondButton="Quero publicar o curso e o módulo"
        onSubmitFirstButton={() => setIsOpenModal(false)}
        onSubmitSecondButton={handlePublishCourseAndModule}
        isSubmitting={isSubmitting}
      />
    </div>
  );
}
