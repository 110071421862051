import React, { useEffect, useState } from 'react';
import { FiChevronLeft, FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { Link } from '@reach/router';
import Avatar from 'app/components/Avatar';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { useAgenda } from 'app/hooks/useAgenda';
import { t } from 'i18next';

export function ManageExcessTasks() {
  const { currentSelectedWeek, hasNextSprintSlots, hasPreviousSprint, getSprints, getSelectedSprintSlots, getNextSprintSlots, getPreviousSprintSlots } = useAgenda();

  const currentWeekDay = new Date().getDay();
  const [loading, setLoading] = useState(true);
  const [overtimeStudents, setOvertimeStudents] = useState([]);
  const client = useClient();

  const DaysOfTheWeek = [
    {
      id: 0,
      nome: t('weekDays.sunday'),
      name: 'sunday'
    },
    {
      id: 1,
      nome: t('weekDays.monday'),
      name: 'monday'
    },
    {
      id: 2,
      nome: t('weekDays.tuesday'),
      name: 'tuesday'
    },
    {
      id: 3,
      nome: t('weekDays.wednesday'),
      name: 'wednesday'
    },
    {
      id: 4,
      nome: t('weekDays.thursday'),
      name: 'thursday'
    },
    {
      id: 5,
      nome: t('weekDays.friday'),
      name: 'friday'
    },
    {
      id: 6,
      nome: t('weekDays.saturday'),
      name: 'saturday'
    }
  ];

  useEffect(() => {
    getOvertimeStudents();
  }, []);

  useEffect(() => {
    getSprints();
    getSelectedSprintSlots();
  }, []);

  const getOvertimeStudents = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`overtime_users?filter[date_between[final]]=${currentSelectedWeek?.saturday.format('YYYY-MM-DD')}&filter[date_between[initial]]=${currentSelectedWeek?.sunday.format('YYYY-MM-DD')}`);
    if (error) {
      toast.error('Erro ao buscar estudantes com tarefas excedentes');
    } else {
      setOvertimeStudents(data);
      setLoading(false);
    }
  };

  function handleTag(student) {
    const intHours = parseInt(student.slotInfos.hours);
    if (intHours <= 5 || isNaN(intHours)) {
      return 'calendar-dg__tag calendar-dg__tag--success';
    } else if (intHours <= 8) {
      return 'calendar-dg__tag calendar-dg__tag--warning';
    } else {
      return 'calendar-dg__tag calendar-dg__tag--danger';
    }
  }

  return (
    <>
      <main className="main-content main-content--block">
        <div className="filter-bar">
          <button
            onClick={() => history.back()}
            className="filter-bar__back"
          >
            <FiChevronLeft />

            <span className="filter-bar__back-container">
              <span className="filter-bar__title">Estudantes com tarefas excedentes</span>
            </span>
          </button>
        </div>

        <div className="expanded-calendar">
          <header className="expanded-calendar__header">
            {hasPreviousSprint() && (
              <button
                className="expanded-calendar__btn"
                aria-label="semana anterior"
                onClick={() => getPreviousSprintSlots()}
              >
                <FiArrowLeft />{' '}
                <span
                  className="expanded-calendar__btn-text"
                  aria-hidden
                >
                  {t('liveClass.lastWeek')}
                </span>
              </button>
            )}

            <h2 className="expanded-calendar__selected-week">
              {currentSelectedWeek.sunday.format('DD/MM')} - {currentSelectedWeek.saturday.format('DD/MM')}
            </h2>

            {hasNextSprintSlots() && (
              <button
                className="expanded-calendar__btn"
                aria-label="próxima semana"
                onClick={() => getNextSprintSlots()}
              >
                <span
                  className="expanded-calendar__btn-text"
                  aria-hidden
                >
                  {t('liveClass.nextWeek')}
                </span>{' '}
                <FiArrowRight />
              </button>
            )}
          </header>

          <div className="u-overflow-x">
            <table className="calendar-dg">
              <colgroup>
                {[...Array(9)].map(i => (
                  <col className={currentWeekDay === i ? 'calendar-dg__current-day' : ''} />
                ))}
              </colgroup>
              <thead className="calendar-dg__header">
                <tr className="calendar-dg__row">
                  <th
                    scope="col"
                    className="calendar-dg__day"
                  ></th>
                  {DaysOfTheWeek &&
                    DaysOfTheWeek.map((dayoftheweek, i) => (
                      <th
                        scope="col"
                        className={currentWeekDay === i ? 'calendar-dg__day current-day' : 'calendar-dg__day'}
                        key={i}
                      >
                        <span className="expanded-calendar__day-number u-d-block">{currentSelectedWeek[dayoftheweek.name].format('DD/MM')}</span>
                        <span className="expanded-calendar__weekday u-d-block">{dayoftheweek.nome}</span>
                      </th>
                    ))}
                  <th
                    scope="col"
                    className="calendar-dg__day"
                  ></th>
                </tr>
              </thead>
              <tbody className="calendar-dg__body">
                {overtimeStudents?.users?.map((student, index) => (
                  <tr
                    className="calendar-dg__row"
                    key={index}
                  >
                    <th
                      scope="row"
                      className="calendar-dg__head"
                    >
                      <Link
                        to={`/usuario/metricas/${student.id}`}
                        state={{ isRedirected: true }}
                      >
                        <Avatar
                          src="https://estudologia-bucket.s3.amazonaws.com/uploads/user/image/147/thumb_image.jpeg"
                          alt={student.name}
                          className="avatar--xs"
                        />{' '}
                        {student.name}
                      </Link>
                    </th>
                    {[...Array(7)].map(i => (
                      <td className="calendar-dg__data">
                        <span className={handleTag(student)}>{student.slotInfos.hours || 10}h</span>
                      </td>
                    ))}
                    <td className="calendar-dg__data">
                      <Link
                        to={`${window.location.pathname}/${student?.id}`}
                        className="btn btn--outline btn--full"
                      >
                        Gerenciar tarefas
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
}
