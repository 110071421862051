import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { navigate } from '@reach/router';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import Modal from 'app/components/Modal';
import { useClassroom } from 'app/hooks/useClassroom';
import { useCourse } from 'app/hooks/useCourse';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import EmptyState from 'app/components/EmptyState';
import ClassroomCard from 'app/screens/Admin/Classroom/ClassroomCard';
import getPermission from 'app/utils/getPermission';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';

function CourseTab(props) {
  const { t } = useTranslation();
  const { courseId } = props;
  const client = useClient();

  const [show, setShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editClassroomTitle, setEditClassroomTitle] = useState('');
  const [currentClassroom, setCurrentClassroom] = useState('');
  const [users, setUsers] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const { loading, classrooms, getClassrooms, selectedClassroom, setSelectedClassroom } = useClassroom();

  const { courses, getCourses } = useCourse();

  const filters = useFormik({
    initialValues: {
      discipline: courseId || 'blank',
      bestPerformance: false,
      minorPerformance: false,
      lowerFrequency: false
    },
    onSubmit: values => {
      console.log(values);
    }
  });

  const newClassroomForm = useFormik({
    initialValues: {
      title: '',
      course_id: '1',
      initial_date: moment(),
      end_date: moment()
    },
    onSubmit: async values => {
      const parsedForm = {
        ...values,
        initial_date: moment(values.initial_date).format('YYYY-MM-DD'),
        end_date: moment(values.end_date).format('YYYY-MM-DD')
      };

      const { data, error } = await client.mutate('classrooms', parsedForm);

      if (error) {
        toast.error(t('toast.errorCreateClass'));
      } else {
        getClassrooms();
        navigate(`/turmas/gerenciar/${data.id}`);
      }
      newClassroomForm.resetForm();
    }
  });

  useEffect(() => {
    getClassrooms(courseId, true);

    if (courses.length === 0) {
      getCourses();
    }
  }, []);

  const editClassroomForm = classroom => {
    setCurrentClassroom(classroom);
    setEditClassroomTitle(classroom.title);
    setEditModal(true);
  };

  const editCurrentClassroom = async e => {
    e.preventDefault();

    const { data, error } = await client.mutate(['classrooms', currentClassroom.id], {
      title: editClassroomTitle
    });

    if (error) {
      console.log(error);
      toast.error('Turma não pode ser editada!');
    } else {
      setSelectedClassroom({ ...selectedClassroom, name: data.title });

      let newListEdit = classrooms;
      let newClassroom = _.find(newListEdit, ['id', currentClassroom.id]);
      newClassroom.title = data.title;
      // setClassroomsList([...newListEdit]);

      toast.success(t('toast.successEditClass'));
    }

    setEditModal(false);
    setCurrentClassroom(null);
    setEditClassroomTitle('');
    getClassrooms();
  };

  const filterParticipantByClassroom = classroom => {
    return participants.filter(u => classroom['user-ids'].includes(u.id));
  };

  const getUsers = async () => {
    const { data, error } = await client.fetch('users?filter[profile]=Aluno');
    if (error) {
      toast.error(t('toast.errorGetStudents'));
    } else {
      setUsers(data);
    }
  };

  const getTeachers = async () => {
    const { data, error } = await client.fetch('users?filter[profile]=Professor,Tutor');
    if (error) {
      toast.error('Erro ao buscar professores.');
    } else {
      setTeachers(data);
    }
  };

  const participants = users.concat(teachers);

  useEffect(() => {
    getUsers();
    getTeachers();
  }, []);

  const deleteClassroom = async classroom => {
    const { error } = await client.delete(['classrooms', classroom.id]);
    if (error) {
      toast.error(t('toast.errorDeleteClass'));
    } else {
      toast.success(t('toast.successDeleteClass'));
      getClassrooms();
    }
  };

  let today = new Date();
  today = today.toISOString().split('T')[0];

  return (
    <div className="tab__pane">
      <div className="filter-bar">
        <FilterSelectionBox
          label={t('filter.labelSelectCourse')}
          value={filters.values.discipline}
          onChange={e => {
            filters.setFieldValue('discipline', e.target.value);
            history.pushState({}, null, `/classrooms/${e.target.value}`);
            getClassrooms(e.target.value);
          }}
          options={courses.map(c => ({ id: c.id, name: c.title }))}
        />

        {getPermission('Criar turmas', 'Turmas') && (
          <button
            className="btn btn--primary btn--wide"
            onClick={() => setShow(true)}
          >
            {t('courses.buttonNewClass')}
          </button>
        )}
      </div>

      {loading && <Loader />}

      {!classrooms.length > 0 && !loading && (
        <EmptyState
          type="data"
          title={t('emptyState.missingDataTitle')}
        />
      )}

      {!loading && classrooms.length > 0 && (
        <div className="tab__cards">
          {classrooms.map(c => (
            <ClassroomCard
              key={c.id}
              classroom={c}
              editClassroomForm={editClassroomForm}
              participants={filterParticipantByClassroom(c)}
              deleteClassroom={deleteClassroom}
            />
          ))}
        </div>
      )}

      <Modal
        show={editModal}
        onClose={() => {
          setEditModal(false);
          getClassrooms();
        }}
      >
        <form
          method="patch"
          onSubmit={editCurrentClassroom}
          className="form"
        >
          <h2 className="modal__simple-title">{t('classes.editClass')}</h2>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="classClassroom"
            >
              {t('textsCommon.name')}
            </label>

            <input
              className="form__control"
              id="classClassroom"
              name="classClassroom"
              type="text"
              placeholder={t('courses.placeholderClassName')}
              value={editClassroomTitle}
              onChange={e => setEditClassroomTitle(e.target.value)}
            />
          </div>

          <button
            className="btn btn--primary"
            type="submit"
          >
            {t('classes.editClass')}
          </button>
        </form>
      </Modal>

      <Modal
        show={show}
        onClose={() => setShow(false)}
      >
        <form
          method="post"
          className="form"
          onSubmit={e => {
            e.preventDefault();
            newClassroomForm.submitForm();
            setShow(false);
          }}
        >
          <h2 className="modal__simple-title">{t('courses.newClass')}</h2>

          <p className="form__description">{t('courses.newClassDescription')}</p>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="classTitle"
            >
              {t('courses.className')}
            </label>
            <input
              className="form__control"
              id="classTitle"
              name="classTitle"
              type="text"
              placeholder={t('courses.placeholderClassName')}
              value={newClassroomForm.values.title}
              onChange={e => newClassroomForm.setFieldValue('title', e.target.value)}
            />
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="course"
            >
              {t('courses.relatedClasstoCourse')}
            </label>
            <select
              className="form__select"
              name="course"
              id="course"
              onChange={e => newClassroomForm.setFieldValue('course_id', e.target.value)}
            >
              <option>{t('filter.labelSelectCourse')}</option>
              {courses.map(t => (
                <option value={t.id}>{t.title}</option>
              ))}
            </select>
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="initial_date"
            >
              {t('courses.classAvailabilityPeriod')}
            </label>

            <div className="form__columns">
              <div className="form__col">
                <input
                  className="form__control"
                  id="initial_date"
                  name="initial_date"
                  type="date"
                  min={today}
                  defaultValue={newClassroomForm.values.initial_date}
                  onChange={e => newClassroomForm.setFieldValue('initial_date', e.target.value)}
                />
              </div>
              <div className="form__col">
                <input
                  className="form__control"
                  id="end_date"
                  name="end_date"
                  type="date"
                  min={today}
                  defaultValue={newClassroomForm.values.end_date}
                  onChange={e => newClassroomForm.setFieldValue('end_date', e.target.value)}
                />
              </div>
            </div>
          </div>

          <button
            className="btn btn--primary"
            type="submit"
          >
            {t('courses.buttonNewClass')}
          </button>
        </form>
      </Modal>
    </div>
  );
}

export default CourseTab;
