import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import NewMaterialModal from './NewMaterialModal';
import MaterialCard from './MaterialCard';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { t } from 'i18next';

export default function TabMaterials({ currentModuleId }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState();
  const client = useClient();

  const hasSelectedModule = currentModuleId !== 'm';

  const getMaterials = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/learning_system_files?filter[learning_system_id]=${currentModuleId}`);
    if (error) {
      toast.error('Erro ao carregar materiais deste módulo');
    } else {
      setMaterials(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMaterials();
  }, [currentModuleId, count]);

  useEffect(() => {
    const filteredMaterials = materials?.filter(item => {
      if (searchTerm === '') {
        return true;
      }
      if (searchTerm && searchTerm !== '') {
        return item.title.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });

    setFilteredMaterials(filteredMaterials);
  }, [materials, searchTerm]);

  const handleEdit = material => {
    setSelectedMaterial(material);
    setShowModal(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const updateMaterialsList = async values => {
    setLoading(true);
    const { destination, source } = values;
    if (!destination) {
      return null;
    }
    if (destination.index === source.index) {
      return null;
    }

    const dIndex = destination.index;
    const sIndex = source.index;
    const newItems = reorder(filteredMaterials, sIndex, dIndex);

    const newMaterialsOrder = newItems.map((material, index) => {
      return { id: material.id, order: index };
    });

    const { error } = await client.mutate(
      ['learning_systems', currentModuleId, 'sort'],
      {
        learning_system_files: newMaterialsOrder
      },
      { method: 'PUT' }
    );
    if (error) {
      toast.error('Erro ao reordenar materiais!');
    } else {
      getMaterials();
      toast.success('Materiais reordenados com sucesso!');
    }
  };

  return (
    <DragDropContext onDragEnd={values => updateMaterialsList(values)}>
      <div className="tab__pane fadeIn">
        <div className="filter-bar">
          <div className="filter-bar__inner">
            <div className="filter-bar__row">
              <label
                htmlFor="search"
                className="filter-bar__label"
              >
                {t('textsCommon.search')}
              </label>
              <input
                className="form__control form__control--search-with-icon"
                placeholder={t('filter.searchMaterial')}
                type="search"
                name="search"
                id="search"
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className="filter-bar__actions">
            <button
              type="button"
              onClick={() => setShowModal(true)}
              className={hasSelectedModule ? 'btn btn--wide btn--primary' : 'btn btn--wide btn--primary disabled'}
              >
              {t('textsCommon.newMaterial')}
            </button>
          </div>
        </div>

        {loading && <Loader />}

        {!loading && filteredMaterials?.length === 0 && hasSelectedModule && <EmptyState type="data" />}

        {!loading && !hasSelectedModule && (
          <EmptyState
            type="selectCard"
            text='Selecione um módulo para habilitar "Novo material".'
          />
        )}

        {!loading && (
          <Droppable droppableId="droppable">
            {provided => (
              <div
                className="round-dg-wrapper"
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{overflow: 'initial'}}
              >
                <table
                  className="round-dg round-dg--light"
                  style={{ minWidth: '900px' }}
                >
                  <tbody className="round-dg__body">
                    {filteredMaterials?.length > 0 &&
                      filteredMaterials?.map((material, i) => (
                        <Draggable
                          key={i}
                          draggableId={material.id.toString()}
                          index={i}
                        >
                          {provided => (
                            <tr
                              className="round-dg__row"
                              key={i}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <MaterialCard
                                handleEdit={handleEdit}
                                setSelectedMaterial={setSelectedMaterial}
                                setFilteredMaterials={setFilteredMaterials}
                                material={material}
                                currentModuleId={currentModuleId}
                              />
                            </tr>
                          )}
                        </Draggable>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </Droppable>
        )}

        <NewMaterialModal
          selectedMaterial={selectedMaterial}
          currentModuleId={currentModuleId}
          show={showModal}
          count={count}
          onClose={() => {
            setShowModal(false);
            setCount(prevState => prevState + 1);
            setSelectedMaterial(null);
          }}
        />
      </div>
    </DragDropContext>
  );
}
