import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

export default function DataGrid({
  data,
  setParam,
  studentOrder,
  setStudentOrder,
  emailOrder,
  setEmailOrder,
  profileOrder,
  setProfileOrder,
  inepOrder,
  setInepOrder,
  greOrder,
  setGreOrder,
  performanceOrder,
  setPerformanceOrder,
  institutionOrder,
  setInstitutionOrder,
  rightAnswersOrder,
  setRightAnswersOrder,
  totalErrorsOrder,
  setTotalErrorsOrder,
  orderedReport
}) {
  return (
    <table
      className="round-dg round-dg--light"
      style={{ minWidth: 800 }}
    >
      <thead className="round-dg__header">
        <tr className="round-dg__row">
          <th className="round-dg__cell-header round-dg__cell-header--sm">
            Aluno
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {studentOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setStudentOrder('ascendent');
                    setParam('student');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('student');
                    setStudentOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th style={{width: 250}} className="round-dg__cell-header round-dg__cell-header--sm">
            E-mail
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {emailOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setEmailOrder('ascendent');
                    setParam('email');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('email');
                    setEmailOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th className="round-dg__cell-header round-dg__cell-header--sm">
            Escola
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {institutionOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setInstitutionOrder('ascendent');
                    setParam('institution');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('institution');
                    setInstitutionOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th
            className="round-dg__cell-header round-dg__cell-header--sm"
            style={{ width: '120px' }}
          >
            INEP
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {inepOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setInepOrder('ascendent');
                    setParam('inep');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('inep');
                    setInepOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th className="round-dg__cell-header round-dg__cell-header--sm">
            GRE
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {greOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setGreOrder('ascendent');
                    setParam('gre');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('gre');
                    setGreOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th
            className="round-dg__cell-header round-dg__cell-header--sm"
            style={{ width: '170px' }}
          >
            Quantidade de Acertos
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {rightAnswersOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setRightAnswersOrder('ascendent');
                    setParam('right-answers');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('right-answers');
                    setRightAnswersOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th
            className="round-dg__cell-header round-dg__cell-header--sm"
            style={{ width: '150px' }}
          >
            Quantidade de Erros
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {totalErrorsOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setTotalErrorsOrder('ascendent');
                    setParam('total-errors');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('total-errors');
                    setTotalErrorsOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th
            className="round-dg__cell-header round-dg__cell-header--sm"
            // style={{ width: 130 }}
          >
            % Aproveitamento
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {performanceOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setPerformanceOrder('ascendent');
                    setParam('performance');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('performance');
                    setPerformanceOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
        </tr>
      </thead>

      <tbody className="round-dg__body">
        {data.map((item, key) => (
          <tr
            className="round-dg__row"
            key={key}
          >
            <td className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
              <p className="round-dg__subtitle">{item?.['user-name']}</p>
            </td>
            <td className="round-dg__cell round-dg__cell--sm">{item?.['user-email']}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item?.institution}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item?.inep}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item?.gre}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item?.['num-of-correct-answers']}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item?.['num-of-wrong-answers']}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item?.['accuracy-rate']} %</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
