import React, { useState, useEffect } from 'react';
import TabNavBar from './TabNavBar';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { FiAlertCircle } from 'react-icons/fi';
import { Link } from '@reach/router';
import Calendar from 'app/components/Calendar';
import { useAgenda } from 'app/hooks/useAgenda';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function ManageByClassrom(props) {
  const { t } = useTranslation();
  const { selectedTab, uri } = props;
  const { weeklyOvertime, currentSelectedWeek, getWeeklyOvertime } = useAgenda();
  const [loading, setLoading] = useState(false);
  const [relationships, setRelationships] = useState({
    classrooms: []
  });
  const [courses, setCourses] = useState([])
  const [institutions, setInstitutions] = useState([])
  const [limitAlertMessage, setLimitAlertMessage] = useState(false);
  const ClassroomTasks = true;

  const client = useClient();

  const filters = useFormik({
    initialValues: {
      classroom: 'blank'
    }
  });

  const getRelationships = async () => {
    setLoading(true);
    const { data: classrooms } = await client.fetch('classrooms/simple');
    setRelationships({
      classrooms: classrooms || []
    });
    setLoading(false);
  };

  const getCourses = async () => {
    setLoading(true)
    const { data } = await client.fetch('courses/simple')
    setCourses(data)
    setLoading(false)
  }

  const getInstitutions = async () => {
    setLoading(true)
    const { data } = await client.fetch('institutions')
    setInstitutions(data)
    setLoading(false)
  }

  useEffect(() => {
    getRelationships();
    getInstitutions()
    getCourses()
  }, []);

  useEffect(() => {
    getWeeklyOvertime(currentSelectedWeek);
    checkWeeklyOvertime(weeklyOvertime);
  }, [currentSelectedWeek]);

  function checkWeeklyOvertime() {
    if ((weeklyOvertime?.overtimeStudentCount || 0) !== 0) {
      setLimitAlertMessage(true);
    }
  }

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: uri,
        name: 'Tarefas',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <TabNavBar selectedTab={selectedTab} />

      <div className="tab__pane">
        <div className="filter-bar">
          <div className="filter-bar__row">
            <label className="form__label">{t('filter.selectClass')}</label>
            <Select
              openMenuOnFocus
              options={[
                { value: 'blank', label: t('filter.blankLabel') },
                ...relationships?.classrooms.map(item => {
                  return { value: item.id, label: institutions?.find(inst => item['institution-id'] === inst.id)?.name + ' - ' + courses?.find(course => item['course-id'] === course.id)?.title + ' - ' + item.title };
                })
              ]}
              className="react-multi-select filter-bar__multi-select"
              classNamePrefix="react-multi-select"
              placeholder={loading ? t('loader.loading') : t('filter.blankLabel')}
              noOptionsMessage={() => 'Sem opções'}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              onChange={e => {
                filters.setFieldValue('classroom', e.value);
              }}
            />
          </div>

          {/* <FilterSelectionBox
            blankLabel={t('filter.labelSelectClassroom')}
            label={t('events.toggleClass')}
            value={filters.values.classroom}
            onChange={e => filters.setFieldValue('classroom', e.target.value)}
            options={relationships.classrooms.map(o => ({
              id: o.id,
              name: o.title
            }))}
          /> */}

          {limitAlertMessage && (
            <div className="filter-bar__box u-ml-auto fadeIn">
              <span className="filter-bar__icon">
                <FiAlertCircle />
              </span>
              <span>
                <strong>
                  Atenção! {weeklyOvertime?.overtimeStudentCount} estudantes excederam o limite de 8h diárias
                  <br /> de tarefas
                </strong>{' '}
                na semana de {currentSelectedWeek?.sunday.format('DD/MM')} a {currentSelectedWeek?.saturday.format(t('datesForm.formatDate'))}.
                <br /> <Link to="/gestao-de-tarefa/turma/tarefas">Clique aqui</Link> para gerenciar as tarefas dos estudantes
              </span>
            </div>
          )}
        </div>

        {filters.values.classroom === 'blank' && (
          <EmptyState
            type="select"
            text={t('emptyState.selectClassToStart')}
          />
        )}

        {filters.values.classroom !== 'blank' && (
          <div className="home-content">
            <Calendar.Expanded
              slotType="master_slot"
              ClassroomTasks={ClassroomTasks}
              filters={filters}
              selectedTab={selectedTab}
              setLimitAlertMessage={setLimitAlertMessage}
            />
          </div>
        )}
      </div>
    </main>
  );
}
