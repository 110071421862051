import React, { useState, useEffect } from 'react';
import { Link, navigate, useParams } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';
import moment from 'moment';
import { useClient, useQuery } from 'jsonapi-react';
import ModuleWidget from './ModuleWidget';
import ActivityLog from 'app/screens/Dashboard/ActivityLog';
import TaskWidget from './TaskWidget';
import Modal from 'app/components/Modal';
import EventForm from 'app/components/eventForm';
import ImportantLog from 'app/screens/Dashboard/ImportantLog';
import { t } from 'i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import CourseCover from 'app/images/placeholders/show-course.jpg';
import TabNavBar from './TabNavBar';
import Loader from 'app/components/loader';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function CourseMonitoring(props) {
  const { uri } = props;

  const client = useClient();
  const courseId = parseInt(useParams().id);
  const { abilities } = usePlatformAbilities();
  const { data: course, isLoading: isLoadingCourse } = useQuery(`/courses/${courseId}?detailed=true`);

  const [events, setEvents] = useState([]);
  const [editingEvent, setEditingEvent] = useState();
  const [showFormEvent, setShowFormEvent] = useState(false);
  const [selectedTab, setSelectedTab] = useState('atualizacoes');

  const parsedCourse = { ...course, 'learning-systems': course?.['learning-systems']?.sort((a, b) => a.order - b.order) };

  const fetchEvents = async initDate => {
    try {
      const initialDate = moment(initDate).startOf('month').format('YYYY-MM-DD');
      const endDate = moment(initDate).endOf('month').format('YYYY-MM-DD');
      const courseFilter = courseId ? `&filter[course_id]=${courseId}` : '';
      const { data } = await client.fetch(`events?filter[date_between][initial]=${initialDate}&filter[date_between][final]=${endDate}${courseFilter}`);
      setEvents(data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownloadCertificate = () => {
    window.open(`${process.env.REACT_APP_DEV_URL}//user_certificates/download/${parsedCourse?.certificate?.hash_id}.pdf`, '_blank');
  };

  useEffect(() => {
    fetchEvents();
  }, [courseId]);

  const closeFormEvent = () => {
    setShowFormEvent(false);
    setEditingEvent(null);
  };

  const courseProgress = Number(parsedCourse?.progress?.toFixed(2));

  const courseIsStarted = courseProgress > 0;

  const courseButtonLink = () => {
    if (courseProgress === 100) {
      const lastModule = parsedCourse?.['learning-systems']?.length - 1;
      return `/curso/${parsedCourse?.id}/modulo/${parsedCourse?.['learning-systems']?.[lastModule]?.id}/sobre`;
    } else if (courseProgress === 0) {
      const firstModule = parsedCourse?.['learning-systems']?.[0]?.id;
      return `/curso/${parsedCourse?.id}/modulo/${firstModule}/sobre`;
    } else {
      const nextModule = parsedCourse?.['learning-systems']?.find(item => item.progress < 100);
      return `/show-course/${nextModule?.id}/classroom`;
    }
  };

  const totalLessons = () => {
    const parsedItems = course?.['learning-systems']?.map(item => item?.learning_system_items_total_count);

    const totalLessons = parsedItems?.reduce((sum, curr) => {
      return sum + curr;
    }, 0);

    return totalLessons;
  };

  const hasModuleAndLesson = course?.['learning-systems']?.length > 0 && totalLessons() > 0;

  const breadCrumbs = {
    title: 'Cursos e Aulas',
    nav: [
      {
        route: '/curso',
        name: 'Cursos',
        isActive: false
      },
      {
        route: uri,
        name: parsedCourse?.title,
        'official-content': parsedCourse?.['official-content'] ?? false,
        isActive: true
      }
    ]
  };

  const modules = course?.['learning-systems'];

  const checkOpenModulesFinished = () => {
    const openModules = modules?.filter(module => {
      const moduleRequirements = module.learning_system_requirement_ids;
      const requiredModules = modules.filter(item => moduleRequirements?.includes(item.id));
      if (moduleRequirements.length === 0 || requiredModules?.every(item => item.finished === true)) {
        return module;
      }
    });
    const openModulesFinished = openModules?.every(item => item.progress === 100);

    return openModulesFinished;
  };

  return (
    <main className="main-content main-content--block">
      {isLoadingCourse ? (
        <Loader />
      ) : (
        <>
          <BreadCrumbs data={breadCrumbs} />

          <div className="course-about u-mb-4">
            <div
              className="show-course-banner"
              style={{
                backgroundImage: `url('${parsedCourse?.image?.url || CourseCover}')`
              }}
            >
              <div className="show-course-banner__inner">
                <div className="show-course-banner__container">
                  {/* <span className="badge badge--tiny badge--primary">{parsedCourse?.knowledge}</span> */}

                  <div className="show-course-banner__title">{parsedCourse?.title}</div>

                  {courseIsStarted && (
                    <div className="card__progress card__progress--cover u-mb-0">
                      <div className="card__progress-container">
                        <div
                          className="card__progress-bar"
                          role="progressbar"
                          style={{ width: `${courseProgress}%` }}
                          aria-valuenow={courseProgress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                      <div className="card__progress-text">{courseProgress}%</div>
                    </div>
                  )}

                  <div className="show-course-banner__cta">
                    {hasModuleAndLesson ? (
                      <Link
                        className={`btn btn--wide btn--primary ${checkOpenModulesFinished() ? 'disabled' : ''}`}
                        to={courseButtonLink()}
                      >
                        {courseIsStarted ? 'Continuar onde parei' : 'Começar'}
                      </Link>
                    ) : (
                      <p>Este curso estará disponível em breve.</p>
                    )}

                    {parsedCourse?.certificate?.hash_id && (
                      <button
                        className="btn btn--wide btn--outline"
                        onClick={handleDownloadCertificate}
                      >
                        {t('button.certification')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {courseIsStarted && (
            <div className="u-mb-4">
              <TabNavBar
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </div>
          )}

          {courseIsStarted && selectedTab === 'atualizacoes' && (
            <div className="page fadeIn">
              <div className="page__col">
                <ImportantLog />

                <ActivityLog
                  useType={1}
                  courseId={courseId}
                  title="Últimas atualizações do curso"
                />
              </div>

              <aside className="page__aside">
                {abilities?.tarefas && <TaskWidget currentCourse={parsedCourse} />}

                {/* <DashboardSidebar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            onActiveStartDateChange={onActiveStartDateChange}
            eventDates={eventDates}
            setShowFormEvent={setShowFormEvent}
            events={events}
          /> */}
              </aside>
            </div>
          )}

          {!courseIsStarted ? (
            <ModuleWidget course={parsedCourse} />
          ) : (
            selectedTab === 'sobre' && (
              <div className="fadeIn">
                <ModuleWidget course={parsedCourse} />
              </div>
            )
          )}

          <Modal
            show={showFormEvent}
            onClose={closeFormEvent}
          >
            <EventForm
              onClose={() => setShowFormEvent(false)}
              editingEvent={editingEvent}
              events={events}
              setEvents={setEvents}
            />
          </Modal>
        </>
      )}
    </main>
  );
}
