import React, { useEffect, useState } from 'react';
import EmptyState from 'app/components/EmptyState';
import { Link } from '@reach/router';
import LessonDataGrid from './LessonDataGrid';
import LessonModal from './LessonModal';
import { useClient } from 'jsonapi-react';

export default function Lesson() {
  const [watchedData, setWatchedData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const client = useClient();

  const getWatchedData = async () => {
    const response = await client.fetch('study_plan_reports/watched_videos');
    return response.data;
  }

  useEffect(() => {
    getWatchedData().then((data) => {
      if (data.watched_videos) setWatchedData(data.watched_videos);
    });
  }, []); 

  return (
    <div className="panorama-card">
      <div className="panorama-card__header">
        <h3 className="panorama-card__title">Aulas iniciadas</h3>
      </div>

      {watchedData.length === 0 && (
        <div className="panorama-card__body panorama-card__body--empty-state">
          <EmptyState
            type="data"
            title="Você ainda não iniciou nenhuma aula"
            text={
              <span>
                Inicie uma aula clicando <Link to="/curso">aqui</Link>
              </span>
            }
            bgless
          />
        </div>
      )}

      {watchedData.length > 0 && (
        <>
          <div className="panorama-card__body">
            <LessonDataGrid data={watchedData.slice(0, 6)} />
          </div>

          {watchedData.length > 6 && <div className="panorama-card__footer">
            <button
              type="button"
              className="btn btn--tiny btn--link"
              onClick={() => setShowModal(true)}
            >
              Ver mais
            </button>

            <LessonModal
              show={showModal}
              onClose={() => setShowModal(false)}
              data={watchedData}
            />
          </div>}
        </>
      )}
    </div>
  );
}
