import React, { useEffect, useMemo, useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useFormik } from 'formik';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Dropdown from 'app/components/Dropdown';
import multipleChoice from 'app/images/icons/multiple-choice.svg';
import rightOrWrong from 'app/images/icons/right-or-wrong.svg';
import QuestionModal from './QuestionModal';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { useClient } from 'jsonapi-react';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';
import GeneratedContent from 'app/components/GeneratedContent';
import { useSession } from 'app/hooks/useSession';

export default function QuestionCard(props) {
  const { t } = useTranslation();
  const { id, question, handleDeleteQuestion } = props;
  const client = useClient();
  const currentUser = useSession().session.user;
  const [showModal, setShowModal] = useState(false);

  const questionForm = useFormik({
    initialValues: {
      published: question?.published,
      visibility: question?.visibility
    }
  });

  useEffect(() => {
    questionForm.setValues({
      published: question?.published,
      visibility: question?.visibility
    });
  }, [question]);

  const updateQuestionPublicity = async values => {
    try {
      await client.mutate(['questions', question.id], {
        published: !values.published
      });
      if (!values.published === true) {
        toast.success(t('toast.successPublish'));
      } else {
        toast.success(t('toast.successUnpublish'));
      }
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorPublishQuestion'));
    }
  };

  const updateQuestionVisibility = visibility => {
    switch (visibility) {
      case 'train':
        return <span>{t('exams.nonExclusive')}</span>;
      case 'exam':
        return <span>{t('exams.exams')}</span>;
      case 'mock':
        return <span>{t('exams.simulations')}</span>;
      case 'exam_and_mock':
        return <span>{t('exams.examsSimulations')}</span>;
    }
  };

  const deleteQuestion = async questionId => {
    const { error } = await client.delete(['questions', questionId]);
    if (error) {
      toast.error(t('toast.errorDeleteQuestion'));
    } else {
      handleDeleteQuestion(questionId);
    }
  };

  const handleDelete = questionId => {
    Swal.fire({
      title: t('projects.warningDeleteQuestion'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteQuestion(questionId);
      }
    });
  };

  const hasEditPermission = getPermission('Editar questões', 'Questões e exercícios');
  const hasQuestionPermission = hasEditPermission && (!question?.['authored-by-user'] || (question?.['authored-by-user'] && currentUser.id === question?.creator?.id) || currentUser?.profileNames.includes('Administrador'));

  const shouldRenderDeleteOption = useMemo(() => {
    if (question?.['authored-by-user'] === true) {
      if (question?.creator?.id !== currentUser.id) {
        return false;
      } else return true;
    }

    if (question?.['authored-by-user'] === false) {
      if (getPermission('Excluir questões', 'Questões e exercícios')) {
        return true;
      } else return false;
    }
  }, [question]);

  const shouldRenderEditOption = useMemo(() => {
    if (question?.['authored-by-user'] === true) {
      if (question?.creator?.id !== currentUser.id) {
        return false;
      } else return true;
    }

    if (question?.['authored-by-user'] === false) {
      if (getPermission('Editar questões', 'Questões e exercícios')) {
        return true;
      } else return false;
    }
  }, [question]);

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
        <div
          className="card__icon-wrapper"
          style={{ height: '32px', display: 'grid', placeContent: 'center' }}
          id={`question-type-${id}`}
          data-tooltip-content={question.modality === 'multiple_choice' ? t('exams.selectMultipleChoice') : t('exams.selectTrueOrFalse')}
        >
          <img
            className="card__icon"
            src={question?.modality === 'multiple_choice' ? multipleChoice : rightOrWrong}
            alt="multitpla escolha"
            style={{ width: '16px' }}
          />
        </div>
        <ReactTooltip
          anchorId={`question-type-${id}`}
          className="tooltip"
        />
      </th>
      <td className="round-dg__cell round-dg__cell--sm">
        <h3 className="round-dg__title">{question?.code}</h3>
        <p
          className="round-dg__subtitle"
          dangerouslySetInnerHTML={{ __html: question?.description }}
        />
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <span className="expanded-calendar__tag expanded-calendar__tag--bright">{`Q${question.id}`}</span>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">{question?.knowledge}</td>
      <td className="round-dg__cell round-dg__cell--sm">
        {question?.taxonomies?.length > 0 && (
          <div className="card__badges card__badges--one-line u-mb-0">
            {question?.taxonomies?.length <= 1 &&
              question?.taxonomies?.map(t => (
                <span
                  className="badge badge--primary"
                  key={t.id}
                  title={t.name}
                >
                  {t.name}
                </span>
              ))}

            {question?.taxonomies?.length > 1 && (
              <>
                {question?.taxonomies?.slice(0, 1).map(t => (
                  <span
                    className="badge badge--primary"
                    key={t.id}
                    title={t.name}
                  >
                    {t.name}
                  </span>
                ))}
                <span
                  className="badge badge--more"
                  title={question?.taxonomies?.slice(1).map(t => ` ${t.name}`)}
                >
                  +{question?.taxonomies?.length - 1}
                </span>
              </>
            )}
          </div>
        )}
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <div className="form__check form__switch u-mb-0">
          {hasQuestionPermission && (
            <input
              className="form__check-input"
              id="published"
              name="published"
              type="checkbox"
              defaultChecked={questionForm.values.published}
              onClick={() => {
                questionForm.setFieldValue('published', !questionForm.values.published);
                updateQuestionPublicity(questionForm.values);
              }}
            />
          )}
        </div>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">{getPermission('Editar questões', 'Questões e exercícios') && <>{updateQuestionVisibility(questionForm.values.visibility)}</>}</td>
      <td className="round-dg__cell round-dg__cell--sm">
        <GeneratedContent
          authorName={question?.creator?.name}
          verified={question?.['official-content']}
        />
      </td>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '38px' }}
      >
        <Dropdown>
          <Dropdown.Toggle>
            <FiMoreHorizontal />
          </Dropdown.Toggle>

          <Dropdown.Content>
            {shouldRenderEditOption ? <Dropdown.Item to={`/questoes/criar/nome/${question?.id}`}>{t('button.edit')}</Dropdown.Item> : null}
            {shouldRenderDeleteOption ? <Dropdown.Item onClick={() => handleDelete(question.id)}>{t('button.delete')}</Dropdown.Item> : null}
            <Dropdown.Item onClick={() => setShowModal(true)}>{t('button.view')}</Dropdown.Item>
            <Dropdown.Item to={`/provas/banco-de-questoes/comentar/${question?.id}`}>{'Comentar questão'}</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown>

        <QuestionModal
          question={question}
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      </td>
    </tr>
  );
}
