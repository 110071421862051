import Modal from 'app/components/Modal';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import { useState } from 'react';
import PreVisualizeModal from './PreVisualizeModal';
import { t } from 'i18next';
import { ReactComponent as Stars } from './img/stars.svg';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';

const StepImage = props => {
  const { step, handleChangeImage, file, form, courseId } = props;

  const client = useClient();

  const [showModal, setShowModal] = useState(false);

  const handleCreateByAI = () => {
    //const getImage to use client to generate image in this url api/v1/generate_image/:id using POST method with paykload {“title”: “Título do curso”, “format”: “base64”}
    const title = form?.values?.title;
    const getImage = client.mutate(`generate_image?format=base64&title=${title}`);

    const loadingToastId = toast.loading('Nossa IA está gerando a melhor imagem');

    getImage
      .then(response => {
        form.setFieldValue('image', `data:image/jpeg;base64,${response.data.base64}`);

        toast.success('Imagem gerada com sucesso', { id: loadingToastId });
      })
      .catch(error => {
        console.log(error);
        toast.error('Erro ao gerar imagem', { id: loadingToastId });
      });
  };

  return (
    step === 'imagem' && (
      <div className="u-mb-5">
        <div className="page__content fadeIn u-mb-3">
          <div className="new-questions__header">
            <h2 className="new-questions__title">{t('courses.buttonNewCourse')}</h2>
          </div>

          <fieldset className="form__row">
            <div className="form__ai-banner u-mb-5">
              <h3 className="form__ai-banner-title">Nossa IA te ajuda com a imagem</h3>
              <button
                className="btn btn--small btn--review"
                type="button"
                onClick={() => handleCreateByAI()}
              >
                <Stars className="btn__icon challenge-success__stars challenge-success__stars--icon" />
                Criar com ajuda da T.I.A
              </button>
            </div>

            <legend className="form__label">{t('courses.coverImage')} (1020x252 pixels)</legend>

            <label className="form__custom-file-input">
              <input
                type="file"
                name="image"
                id="image"
                accept=".jpeg, .png, .jpg"
                onChange={handleChangeImage}
                // defaultValue={}
              />
              <span className="form__control">{false ? '' : t('textsCommon.placeholderUploadImage')}</span>
            </label>
          </fieldset>

          <div className="card__header-img-wrapper">
            <img
              className="card__header-img"
              src={form?.values?.image || file || defaultImage}
              alt="imagem do curso"
              style={{ height: '160px' }}
            />
          </div>

          <PreVisualizeModal
            courseId={courseId}
            form={form}
            show={showModal}
            onClose={() => setShowModal(false)}
            src={form?.values?.image || file || defaultImage}
          />
        </div>

        <button
          type="button"
          className="btn btn--link btn--wide"
          onClick={() => setShowModal(true)}
        >
          {t('courses.previewCourse')}
        </button>
      </div>
    )
  );
};

export default StepImage;
