import React, { useRef, useEffect, useState } from 'react';
import { FiAlertTriangle, FiChevronLeft, FiAlertCircle } from 'react-icons/fi';
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import StudyBlockCard from './StudyBlockCard';
import { navigate, useParams, useLocation } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import Modal from 'app/components/Modal';
import QuizModal from 'app/screens/LmsStudent/QuizModal';
import FlashcardBox from './FlashcardBox';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function StudyBlock(props) {
  const { planId, uri } = props;

  const client = useClient();
  const { id, blockId } = useParams();
  const [studyVideo, setStudyVideo] = useState();
  const location = useLocation();
  const [videoId, setVideoId] = useState();
  const [intervalId, setIntervalId] = useState();
  const [videoPlatform, setVideoPlatform] = useState(null);
  const [player, setPlayer] = useState(null);
  const [count, setCount] = useState(0);
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState({});
  const [answered, setAnswered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [studyPlan, setStudyPlan] = useState();

  const timeToSeconds = time => {
    const [hours, minutes, seconds] = time.split(':');
    return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
  };

  const getStudyVideo = async () => {
    setLoading(true);
    try {
      const { data } = await client.fetch(`/study_plan_videos/${blockId}`);
      setStudyVideo({
        ...data,
        video: {
          ...data.video,
          'quiz-items': data.video?.['quiz-items'].map(item => {
            return { ...item, duration: timeToSeconds(item.duration) };
          })
        }
      });
    } catch {
      toast.error(t('toast.errorGetCourse'));
    }
    setLoading(false);
  };

  const getStudyPlan = async () => {
    try {
      const { data } = await client.fetch(`/study_plans/${planId}`);
      if (data) {
        setStudyPlan(data);
      } else {
        // window.location.href = '/plano-de-estudos';
      }
    } catch {
      toast.error(t('Erro ao buscar plano de estudos'));
    }
  };

  useEffect(() => {
    getStudyVideo();
    getStudyPlan();
  }, []);

  const updateStudyPlan = async () => {
    await client.mutate(`/study_plan_videos/${blockId}`, {
      done: true,
      percentage: 100
    });
  };

  useEffect(() => {
    if (studyVideo && studyVideo?.video?.['flashcards-count'] == 0 && !studyVideo?.done) {
      updateStudyPlan();
    }
  }, [studyVideo]);

  const quizTimings = studyVideo?.video?.['quiz-items']?.map(item => {
    return item.duration;
  });
  const url = studyVideo?.video?.['video-url'];

  const checkVideoPlayer = url => {
    const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    const vimeoRegex = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

    const youtubeResult = url.match(youtubeRegex);
    if (youtubeResult) {
      clearInterval(intervalId);
      setVideoPlatform(null);
      setTimeout(() => setVideoPlatform('youtube'), 500);
      setVideoId(youtubeResult[6]);
      return;
    }

    const vimeoResult = url.match(vimeoRegex);
    if (vimeoResult) {
      clearInterval(intervalId);
      setVideoPlatform(null);
      setTimeout(() => setVideoPlatform('vimeo'), 500);
      setVideoId(vimeoResult[4]);
    }
  };

  useEffect(() => {
    if (url) {
      checkVideoPlayer(url);
    }
  }, [url]);

  // VIMEO

  const onTimeUpdate = e => {
    if (quizTimings.includes(Math.floor(e.seconds)) && count === 0) {
      player.pause();
      setCurrentQuiz(studyVideo?.video?.['quiz-items']?.find(item => item.duration == Math.floor(e.seconds)));
      setShowQuizModal(true);
      setCount(1);
    }
  };

  const handlePlay = () => {
    setTimeout(() => setCount(0), 1100);
  };

  const onReadyVimeo = player => {
    setPlayer(player);
  };

  // YOUTUBE
  const videoRef = useRef(null);
  const checkProgress = () => {
    const e = videoRef.current;
    const videoProgress = parseInt(e.getCurrentTime());
    if (quizTimings.includes(videoProgress) && count === 0) {
      player?.pauseVideo();
      const currentQuiz = studyVideo?.video?.['quiz-items'].find(item => item.duration === videoProgress);
      setCurrentQuiz(currentQuiz);
      setShowQuizModal(true);
      setCount(1);
    }
  };

  const callInterval = event => {
    clearInterval(intervalId);
    setIntervalId(setInterval(() => checkProgress(), 100));
    if (event && event.data === YouTube.PlayerState.PLAYING && count === 1) {
      setTimeout(() => setCount(0), 2000);
    }
  };

  const onReady = event => {
    event.target.playVideo();
    videoRef.current = event.target;
    callInterval();
    setPlayer(event.target);
  };

  const onPause = () => clearInterval(intervalId);

  const youtubeOpts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1
    }
  };

  const breadCrumbs = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: '/plano-de-estudos',
        name: 'Guia de estudos',
        isActive: false
      },
      {
        route: `/plano-de-estudos/${planId}`,
        name: studyPlan?.title,
        isActive: false
      },
      {
        route: uri,
        name: `${t('studyPlan.studyBlock')}: ${studyVideo?.taxonomy?.name}`,
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <div className="filter-bar filter-bar--borderless">
        <div className="filter-bar__inner" />

        {studyVideo?.video?.['flashcards-count'] > 0 && (
          <div className="filter-bar__actions">
            <p className="filter-bar__alert">
              <span className="filter-bar__alert-icon filter-bar__alert-icon--warning">
                <FiAlertCircle />
              </span>
              {t('studyPlan.answerToComplete')}
            </p>
          </div>
        )}
      </div>

      <div className="study-block-page">
        <div className="study-block-page__col">
          <div className="classroom-video-content fadeIn">
            <div className="classroom-video-content__video-frame u-mb-3">
              {videoPlatform !== 'youtube' && videoPlatform !== 'vimeo' && <iframe src={studyVideo?.video?.['video-url'].replace('view?usp=share_link', 'preview')}></iframe>}
              {videoPlatform && videoPlatform === 'youtube' && (
                <YouTube
                  videoId={videoId}
                  opts={youtubeOpts}
                  onReady={onReady}
                  onPause={onPause}
                  onPlay={callInterval}
                />
              )}
              {videoPlatform && videoPlatform === 'vimeo' && (
                <Vimeo
                  onReady={onReadyVimeo}
                  onPlay={handlePlay}
                  video={videoId}
                  autoplay
                  height="100%"
                  width="100%"
                  onTimeUpdate={onTimeUpdate}
                />
              )}
            </div>
            {studyVideo?.video?.taxonomies?.length > 0 && (
              <div className="card__badges card__badges--one-line u-mb-0">
                {studyVideo?.video?.taxonomies?.length <= 3 &&
                  studyVideo?.video?.taxonomies?.map(t => (
                    <span
                      className="badge badge--primary"
                      key={t.id}
                      title={t.name}
                    >
                      {t.name}
                    </span>
                  ))}

                {studyVideo?.video?.taxonomies?.length > 3 && (
                  <>
                    {studyVideo?.video?.taxonomies?.slice(0, 3).map(t => (
                      <span
                        className="badge badge--primary"
                        key={t.id}
                        title={t.name}
                      >
                        {t.name}
                      </span>
                    ))}
                    <span
                      className="badge badge--more"
                      title={studyVideo?.video?.taxonomies?.slice(3).map(t => ` ${t.name}`)}
                    >
                      +{studyVideo?.video?.taxonomies?.length - 3}
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <aside className="study-block-page__aside">
          {!studyVideo && loading ? <Loader /> : null}
          {studyVideo && studyVideo?.video?.['flashcards-count'] <= 0 && studyVideo?.video?.['video-materials'].length === 0 && !loading ? (
            <EmptyState
              type="content"
              text={t('emptyState.studyPlan')}
            />
          ) : null}

          {studyVideo && studyVideo?.video?.['flashcards-count'] > 0 ? (
            <FlashcardBox
              studyVideo={studyVideo}
              studyVideoId={studyVideo.id}
              taxonomyName={studyVideo?.taxonomy?.name}
            />
          ) : null}

          <div className="study-block-page__materials">
            <table className="round-dg round-dg--light">
              <tbody className="round-dg__body">
                {studyVideo?.video?.['video-materials'].map(item => (
                  <StudyBlockCard
                    key={item.id}
                    material={item}
                    studyVideo={studyVideo}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </aside>
      </div>

      <Modal
        show={showQuizModal}
        onClose={() => {
          if (answered) {
            setShowQuizModal(false);
            setAnswered(false);
          } else {
            toast('Resposta obrigatória', {
              className: 'toast-error',
              duration: 10000,
              icon: <FiAlertTriangle />
            });
          }
        }}
      >
        <QuizModal
          currentQuiz={currentQuiz}
          setAnswered={setAnswered}
          answered={answered}
        />
      </Modal>
    </main>
  );
}
