import React from 'react';
import brain1 from './img/brain-1.svg';
import brain2 from './img/brain-2.svg';
import brain3 from './img/brain-3.svg';
import brain4 from './img/brain-4.svg';
import brain5 from './img/brain-5.svg';

export default function Brain(props) {
  const { percentage = 0 } = props;

  const item =
    percentage <= 20
      ? { src: brain1, size: 28, tag: { text: '0 a 20%', modifier: 'danger' } }
      : percentage > 20 && percentage <= 40
      ? { src: brain2, size: 40, tag: { text: '21 a 40%', modifier: 'third' } }
      : percentage > 40 && percentage <= 60
      ? { src: brain3, size: 46, tag: { text: '41 a 60%', modifier: 'warning' } }
      : percentage > 60 && percentage <= 80
      ? { src: brain4, size: 48, tag: { text: '61 a 80%', modifier: 'success-light' } }
      : { src: brain5, size: 60, tag: { text: '81 a 100%', modifier: 'success' } };

  return (
    <div className="review-card__img-wrapper review-card__img-wrapper--sm">
      <span className={`review-card__tag review-card__tag--${item?.tag?.modifier}`}>{item?.tag?.text}</span>
      <img
        src={item?.src}
        alt="cérebro"
        className="review-card__img"
        style={{ width: item?.size }}
      />
    </div>
  );
}
