import React, { useEffect, useState } from 'react';
import { FiPlus, FiMoreHorizontal } from 'react-icons/fi';
import { useParams } from '@reach/router';
import Dropdown from 'app/components/Dropdown';
import dragAndDrop from 'app/images/icons/drag-and-drop.svg';
import LessonCard from './LessonCard';
import Modal from 'app/components/Modal';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { navigate } from '@reach/router';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { t } from 'i18next';
import getPermission from 'app/utils/getPermission';

export default function TabDisciplinesAndLessons(props) {
  const { moduleData, course, currentModuleId, filteredDisciplines, setFilteredDisciplines, loading } = props;
  const client = useClient();
  const { disciplineId, moduleId } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [selectedDisciplineId, setSelectedDisciplineId] = useState();
  const [lessons, setLessons] = useState([]);
  const [click, setClick] = useState(0);
  const [editPath, setEditPath] = useState(false);
  const [editedDiscipline, setEditedDiscipline] = useState();
  const [loadingLessons, setLoadingLessons] = useState(false);

  const getDisciplineLessons = async () => {
    setLoadingLessons(true);
    const { data, error } = await client.fetch(`learning_system_items?filter[learning_system_content_id]=${disciplineId}`);
    if (error) {
      toast.error('Erro ao buscar aulas');
    } else {
      setLessons(data);
    }
    setLoadingLessons(false);
  };

  const handleSubmit = async () => {
    const url = editPath ? ['/learning_system_contents', editedDiscipline.id] : '/learning_system_contents';

    const { error, data } = await client.mutate(url, {
      body: disciplineForm.values.body,
      learningSystemId: currentModuleId
    });
    if (error) {
      editPath ? toast.error('Falha ao editar disciplina') : toast.error('Falha ao criar disciplina');
    } else {
      if (!editPath) {
        toast.success('Disciplina criada com sucesso!');
        filteredDisciplines.push({ ...data });
        disciplineForm.setValues({
          body: '',
          learningSystemId: currentModuleId
        });
      } else {
        toast.success('Disciplina editada com sucesso!');
        const newDisciplines = filteredDisciplines.map(item => (item.id === data.id ? data : item));
        setFilteredDisciplines(newDisciplines);
        setEditPath(false);
      }
    }
  };

  const disciplineForm = useFormik({
    initialValues: {
      body: ''
    },
    onSubmit: handleSubmit
  });

  const handleDelete = id => {
    Swal.fire({
      title: t('projects.warningDeleteMatter'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteDiscipline(id);
      }
    });
  };

  const deleteDiscipline = async id => {
    const { error } = await client.delete(`learning_system_contents/${id}`);
    if (error) {
      toast.error('Erro ao excluir disciplina!');
    } else {
      toast.success('Disciplina excluída com sucesso!');
      setFilteredDisciplines(prevState => prevState.filter(item => item.id !== id));
    }
  };

  const handleEdit = discipline => {
    setEditShow(true);
    setEditPath(true);
    setEditedDiscipline(discipline);
  };

  const handleLinkClick = () => {
    const navigationProps = {
      state: { courseId: course.id, moduleId: currentModuleId, disciplineId: disciplineId }
    };

    navigate(`/lms/criar/aula/${disciplineId}/nome/${course?.id}`, navigationProps);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const updateLessonList = async values => {
    setLoadingLessons(true);
    const { destination, source } = values;
    if (!destination) {
      return null;
    }
    if (destination.index === source.index) {
      return null;
    }

    const dIndex = destination.index;
    const sIndex = source.index;
    const newItems = reorder(lessons, sIndex, dIndex);

    const newLessonsOrder = newItems.map((lesson, index) => {
      return { id: lesson.id, order: index };
    });

    const { error } = await client.mutate(
      ['learning_systems', currentModuleId, 'sort'],
      {
        ['learning-system-items']: newLessonsOrder
      },
      { method: 'PUT' }
    );
    if (error) {
      toast.error('Erro ao reordenar as Aulas!');
    } else {
      getDisciplineLessons();
      toast.success('Aulas reordenadas com sucesso!');
    }
  };

  useEffect(() => {
    setSelectedDisciplineId(window?.history?.state?.disciplineId);
  }, [click, currentModuleId]);

  useEffect(() => {
    if (moduleData?.['learning-system-contents'].length === 0) {
      setLessons([]);
    }

    const moduleById = course?.['learning-systems'].find(item => item?.id == moduleId);
    if (moduleById?.learning_system_items_total_count > 0 && filteredDisciplines?.[0]?.id) {
      navigate(`/curso/admin/gerenciar/${course?.id}/${moduleId}/disciplinas-e-aulas/${filteredDisciplines?.[0]?.id}`);
    }
  }, [filteredDisciplines, moduleData]);

  useEffect(() => {
    const filteredDisciplines = moduleData?.['learning-system-contents']?.filter(item => {
      if (searchTerm == '') {
        return true;
      } else if (item.body.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }
    });

    setFilteredDisciplines(filteredDisciplines);
  }, [moduleData, searchTerm]);

  useEffect(() => {
    disciplineForm.setValues({
      body: editedDiscipline?.body
    });
  }, [editedDiscipline]);

  useEffect(() => {
    if (disciplineId !== 'd' && disciplineId !== null) {
      getDisciplineLessons();
    }
  }, [disciplineId]);

  return currentModuleId !== 'm' ? (
    <div className="manage-content fadeIn">
      <div className="manage-content__sidebar">
        <div className="form__row u-mt-0">
          <label
            htmlFor="disciplineSearch"
            className="form__label"
          >
            Disciplinas
          </label>

          <input
            aria-label="Pesquisar"
            className="form__control form__control--search-with-icon"
            placeholder={t('textsCommon.research')}
            type="search"
            name="disciplineSearch"
            id="disciplineSearch"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <button
          className="btn btn--outline-dashed btn--small btn--full"
          onClick={() => setShowModal(true)}
        >
          <FiPlus className="btn__icon" /> {t('button.newDiscipline')}
        </button>
        <hr className="u-hr" />
        {loading && <Loader />}
        {!loading && (
          <Droppable droppableId="droppable">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {filteredDisciplines?.length > 0 &&
                  filteredDisciplines?.map((discipline, i) => (
                    <Draggable
                      key={i}
                      draggableId={discipline.id.toString()}
                      index={i}
                    >
                      {provided => (
                        <div
                          onClick={() => {
                            setClick(prevState => prevState + 1);
                            navigate(`/curso/admin/gerenciar/${course?.id}/${currentModuleId}/disciplinas-e-aulas/${discipline.id}`);
                          }}
                          className={parseInt(disciplineId) === discipline.id ? 'taxonomies__item active' : 'taxonomies__item'}
                          key={discipline.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <img
                            className="taxonomies__icon"
                            src={dragAndDrop}
                            alt=""
                          />
                          <span className="taxonomies__text">{discipline.body}</span>
                          <Dropdown>
                            <Dropdown.Toggle>
                              <FiMoreHorizontal />
                            </Dropdown.Toggle>

                            <Dropdown.Content>
                              <Dropdown.Item onClick={() => handleEdit(discipline)}>{t('button.edit')}</Dropdown.Item>
                              <Dropdown.Item onClick={() => handleDelete(discipline.id)}>{t('button.delete')}</Dropdown.Item>
                            </Dropdown.Content>
                          </Dropdown>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </div>

      <DragDropContext onDragEnd={values => updateLessonList(values)}>
        <div className="manage-content__content">
          {getPermission('Criar módulos, disciplinas e aulas', 'Cursos') && disciplineId !== 'd' ? (
            <button
              onClick={handleLinkClick}
              className="btn btn--outline-dashed btn--small btn--full u-mb-3"
            >
              <FiPlus className="btn__icon" /> {t('button.createLesson')}
            </button>
          ) : null}

          {loadingLessons && <Loader />}
          {!loadingLessons && lessons.length === 0 && <EmptyState type="data" />}
          {!loadingLessons && (
            <Droppable droppableId="droppable">
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <div className="tab__cards tab__cards--h">
                    {lessons.length > 0 &&
                      lessons?.map((lesson, i) => {
                        return (
                          <Draggable
                            key={i}
                            draggableId={lesson.id.toString()}
                            index={i}
                          >
                            {provided => (
                              <div
                                key={i}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <LessonCard
                                  courseId={course?.id}
                                  coursePublished={course?.published}
                                  modulePublished={moduleData?.publish}
                                  setLessons={setLessons}
                                  selectedDisciplineId={disciplineId}
                                  currentModuleId={currentModuleId}
                                  key={i}
                                  lesson={lesson}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                  </div>
                </div>
              )}
            </Droppable>
          )}
        </div>
      </DragDropContext>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <form
          className="form"
          onSubmit={e => {
            e.preventDefault();
            setShowModal(false);
            disciplineForm.handleSubmit();
          }}
        >
          <h2 className="modal__simple-title">{t('button.newDiscipline')}</h2>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="disciplineName"
            >
              {t('textsCommon.disciplineName')}
            </label>

            <input
              className="form__control"
              id="disciplineName"
              name="disciplineName"
              type="text"
              placeholder={t('textsCommon.writeHere')}
              onChange={e => disciplineForm.setFieldValue('body', e.target.value)}
            />
          </div>

          <button
            className="btn btn--primary btn--wide"
            type="submit"
          >
            {t('button.createDiscipline')}
          </button>
        </form>
      </Modal>

      <Modal
        show={editShow}
        onClose={() => {
          setEditPath(false);
          setEditShow(false);
        }}
      >
        <form
          method="post"
          className="form"
          onSubmit={e => {
            e.preventDefault();
            setEditShow(false);
            disciplineForm.handleSubmit();
          }}
        >
          <h2 className="form__title">Editar o nome da Disciplina</h2>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="disciplineName"
            >
              Novo nome da Disciplina
            </label>
            <input
              value={disciplineForm?.values?.body}
              className="form__control"
              id="disciplineName"
              name="disciplineName"
              type="text"
              placeholder="Digite o nome da Disciplina"
              onChange={e => disciplineForm.setFieldValue('body', e.target.value)}
            />
          </div>

          <button
            className="btn btn--primary"
            type="submit"
          >
            Editar e continuar
          </button>
        </form>
      </Modal>
    </div>
  ) : (
    <EmptyState
      type="select"
      title={t('emptyState.titleSelectModule')}
      text={t('emptyState.textSelectModule')}
    />
  );
}
