import React from 'react';
import { Link } from '@reach/router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function ExamMetricsExamListItemForAdmin(props) {
  const { t } = useTranslation();
  const { exam, courses } = props;

  const examCourse = courses.find(item => item.id == exam['course-ids']);

  const initialDate = moment(exam?.['published-at']).format(t('datesForm.formatDate'));
  const finishDate = moment(exam?.['finished-at']).format(t('datesForm.formatDate'));

  return (
    <>
      <tr className="round-dg__row">
        <td className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
          <b>{exam?.title}</b>
        </td>
        <td className="round-dg__cell round-dg__cell--sm">{examCourse?.title}</td>
        <td className="round-dg__cell round-dg__cell--sm">{exam?.['published-at'] && exam?.['finished-at'] ? `${initialDate} a ${finishDate}` : 'Sem data para resolução'}</td>
        <td className="round-dg__cell round-dg__cell--sm">{(Number(exam?.['average-score']) ?? 0).toFixed(2)}</td>
        <td className="round-dg__cell round-dg__cell--sm">{exam?.['student-count']}</td>
        <td className="round-dg__cell round-dg__cell--sm last-cell">
          {(exam?.status === 'Realizada' || exam?.status === 'Realizado' || exam?.['learning-system-ids']?.length > 0) ? (
            <Link
              to={`/lista-resultado-das-provas/${exam.id}`}
              className="btn btn--full btn--outline btn--small"
            >
              {t('button.results')}
            </Link>
          ) : (
            <Link
              to={`/lista-resultado-das-provas/${exam.id}`}
              className="btn btn--full btn--outline btn--small disabled"
            >
              Aguardando
            </Link>
          )}
        </td>
      </tr>
    </>
  );
}
