import React from 'react';
import { t } from 'i18next';

export default function KpiWidget(props) {
  const { title, count, link, route } = props;

  return (
    <div className="kpi-widget">
      <div className="kpi-widget__header">
        <h3 className="kpi-widget__title">{title || t('textsCommon.title')}</h3>
      </div>

      <h4 className="kpi-widget__count">{count || '0'}</h4>
      {route && link && (
        <a
          className="btn--link"
          href={route}
        >
          {link}
        </a>
      )}
    </div>
  );
}
