import { CiStreamOn } from 'react-icons/ci';
import { FiFolder, FiCoffee, FiEdit3, FiCalendar, FiHome, FiClipboard, FiFileText, FiVideo, FiStar, FiCheckCircle, FiTarget, FiEdit } from 'react-icons/fi';
import { FiMessageSquare } from 'react-icons/fi';
import { TbCopy } from 'react-icons/tb';

export default function getIcon(type) {
  switch (type) {
    case 'FiHome':
      return <FiHome />;
    case 'FiCalendar':
      return <FiCalendar />;
    case 'FiClipboard':
      return <FiClipboard />;
    case 'FiCoffee':
      return <FiCoffee />;
    case 'FiEdit3':
      return <FiEdit3 />;
    case 'FiEdit':
      return <FiEdit />;
    case 'FiFolder':
      return <FiFolder />;
    case 'FiStar':
      return <FiStar />;
    case 'FiCheckCircle':
      return <FiCheckCircle />;
    case 'FiVideo':
      return <FiVideo />;
    case 'FiTarget':
      return <FiTarget />;
    case 'TbCopy':
      return <TbCopy />;
    case 'LuMessagesSquare':
      return <FiMessageSquare />;
    case 'CiStreamOn':
      return <CiStreamOn />;
    default:
      return <FiFileText />;
  }
}
