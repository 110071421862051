import React, { useState } from 'react';
import EmptyState from 'app/components/EmptyState';
import NoteCard from './NoteCard';
import RichTextEditor from './RichTextEditor';
import { t } from 'i18next';

export default function ImportantNotes(props) {
  const { setSearchTerm, notes, searchTerm, importantActiveNote, setImportantActiveNote, activeNote, setActiveNote } = props;

  // const [activeNote, setActiveNote] = useState(0);

  const filterNotes = notes => {
    let filteredNotes = notes;

    if (searchTerm !== '') {
      filteredNotes = filteredNotes.filter(a => a.title != null && a.title.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    return filteredNotes;
  };

  const filteredNotes = filterNotes(notes);

  const hasImportant = filteredNotes.length > 0;

  if (!hasImportant)
    return (
      <EmptyState
        type="register"
        title={'Você ainda não tem anotações importantes'}
        text={'Quando você marcar uma anotação como importante, ela aparecerá aqui'}
      />
    );

  return (
    <div className="notes__body">
      <aside className="notes__aside">
        <input
          className="form__control form__control--search-with-icon"
          aria-label={'Pesquisar'}
          placeholder={t('textsCommon.research')}
          type="search"
          name="search"
          id="search"
          onChange={e => setSearchTerm(e.target.value)}
        />
        <nav className="notes__nav">
          {filteredNotes.map(note => (
            <NoteCard
              note={note}
              index={note.id}
              key={note.id}
              activeNote={activeNote}
              setActiveNote={setActiveNote}
              user={{ image: note.user.image.url, name: note.user.name, id: note.user.id }}
            />
          ))}
        </nav>
      </aside>

      <div className="notes__view">
        <RichTextEditor
          note={activeNote}
          emptyStateTitle={t('emptyState.titleImportantNotes')}
          emptyStateText="Aqui estão as anotações que você marcou como importante. Clique para acessar."
        />
      </div>
    </div>
  );
}
