import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import { useFormik } from 'formik';
import ProjectContent from './ProjectContent';
import ProjectSidebar from './ProjectSidebar';
import * as yup from 'yup';
import { useSession } from 'app/hooks/useSession';
import toast from 'react-hot-toast';
import { navigate } from '@reach/router';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import Error404 from 'app/components/Error404';
import { t } from 'i18next';
import { i18n } from 'app/components/i18n/i18n';
import promiseRequest from 'app/utils/promiseToast';

export function CreateProject(props) {
  const { step, id } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [courses, setCourses] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [groupProjects, setGroupProjects] = useState([]);
  const [currentGroupProject, setCurrentGroupProject] = useState(null);
  const [coursesLoading, setCoursesLoading] = useState(false);
  const client = useClient();
  const { session } = useSession();
  const authorityLevel = getAuthorityLevel();
  const { promiseToast, isSubmitting } = promiseRequest();

  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LOCALE);
  }, []);

  const getGroupProjects = async () => {
    let current;
    const { data, error } = await client.fetch('project_groups');
    if (error) {
      toast.error('Erro ao buscar Projetos em grupo.');
    } else {
      setGroupProjects(data);
      current = id ? data.find(item => item.id === +id) : null;
      setCurrentGroupProject(current);
    }
  };

  const steps = [
    {
      id: 1,
      route: 'curso-turma',
      text: t('projects.stepsCourseClass')
    },
    {
      id: 2,
      route: 'nome',
      text: t('projects.stepsName')
    },
    {
      id: 3,
      route: 'imagem',
      text: t('projects.stepsImage')
    },
    {
      id: 4,
      route: 'organizacao',
      text: t('projects.stepsOrganization')
    }
  ];

  const groupSteps = [
    {
      id: 5,
      route: 'participantes',
      text: t('projects.stepsParticipants')
    },
    {
      id: 6,
      route: 'gestores',
      text: t('projects.stepsManagers')
    }
  ];

  const customSteps = [
    {
      id: 5,
      route: 'personalizado-participantes',
      text: t('projects.stepsParticipants')
    }
  ];

  const [allSteps, setAllSteps] = useState(steps);

  const handleSubmit = async () => {
    const form = createProjectForm.values;
    let requestParams, projectableId, projectable, selectedCourse, selectedCourseStudents, selectedClassroomStudents;
    let projectAttributes = [];

    function filterUsersByClassroom(classroomId, classrooms, users) {
      const classroom = classrooms.find(c => c.id === classroomId);
      if (!classroom) {
        return [];
      }
      const userIds = classroom['user-ids'];
      return users.filter(u => userIds.includes(u.id));
    }

    if (form.course !== '') {
      projectable = 'Course';
      projectableId = +form.course;
      const fetchCourse = await client.fetch(`/courses/${parseInt(form.course)}?allow_users=true`);
      selectedCourseStudents = fetchCourse.data.users;
      if (form.course !== '' && form.classroom !== '') {
        projectable = 'Classroom';
        projectableId = +form.classroom.value;
        let users = filterUsersByClassroom(+form.classroom, fetchCourse.data?.classrooms, fetchCourse.data?.users);
        selectedClassroomStudents = users.filter(p => p['profile-names'].includes('Aluno'));
      }
    }

    const deletedProjects = form.projects.map(p => {
      return { id: p.id, _destroy: true };
    });

    if (form.organization === 'group') {
      for (let i = 0; i < form.students.length; i++) {
        const newObject = {
          'user-id': form['user-id'],
          name: form.title,
          about: form.description,
          'projectable-id': projectableId,
          'projectable-type': projectable,
          image: form.image || '',
          'user-ids': []
        };
        newObject['user-ids'] = form.students[i];
        newObject['user-ids'].push(...form.managers);
        projectAttributes.push(newObject);
        projectAttributes.push(...deletedProjects);
      }
      requestParams = {
        'project-model': 'random',
        'users-per-group': form.groupSize,
        'projects-attributes': projectAttributes
      };
    }
    if (form.organization === 'individual') {
      if (projectable === 'Classroom') {
        for (let i = 0; i < selectedClassroomStudents.length; i++) {
          const newObject = {
            'user-id': form['user-id'],
            name: form.title,
            about: form.description,
            'projectable-id': projectableId,
            'projectable-type': projectable,
            image: form.image || '',
            'user-ids': selectedClassroomStudents[i].id
          };
          projectAttributes.push(newObject);
        }
        projectAttributes.push(...deletedProjects);
      }
      if (projectable === 'Course') {
        for (let i = 0; i < selectedCourseStudents.length; i++) {
          const newObject = {
            'user-id': form['user-id'],
            name: form.title,
            about: form.description,
            'projectable-id': projectableId,
            'projectable-type': projectable,
            image: form.image || '',
            'user-ids': selectedCourseStudents[i]
          };
          projectAttributes.push(newObject);
        }
        projectAttributes.push(...deletedProjects);
      }
      requestParams = {
        'project-model': 'individual',
        'users-per-group': '1',
        'projects-attributes': projectAttributes
      };
    }
    if (form.organization === 'custom') {
      requestParams = {
        'project-model': 'custom',
        'projects-attributes': [
          {
            'user-id': session.user.id,
            'user-ids': form.participants,
            'projectable-id': projectableId,
            'projectable-type': projectable,
            name: form.title,
            about: form.description,
            image: form.image
          },
          ...deletedProjects
        ]
      };
    }
    const path = currentGroupProject?.id ? ['/project_groups', currentGroupProject.id] : '/project_groups';

    promiseToast({
      url: path,
      request: requestParams,
      successText: 'Salvo com sucesso!',
      errorText: 'Erro ao salvar Grupo de Projetos!'
    }).then(data => {
      if (data) {
        navigate('/projetos/grupos');
      }
    });
  };

  const createProjectForm = useFormik({
    initialValues: {
      'user-id': session.user.id,
      course: '',
      classroom: '',
      title: '',
      description: '',
      organization: '',
      groupSize: 0,
      students: [],
      managers: [],
      selectedManagers: [],
      participants: [],
      selectedParticipants: [],
      projects: []
    },
    validationSchema: yup.object({
      title: yup.string().required(t('warning.requiredField')),
      course: yup.number().required(t('warning.requiredField')),
      description: yup.string().required(t('warning.requiredField')),
      organization: yup.string().required(t('warning.requiredField')),
      groupSize: yup.number().moreThan(0, 'O número de participantes deve ser maior que 0').required(t('warning.requiredField')),
      managers: yup.array().min(1, 'Os projetos devem ter 1 ou mais Gestores.').required(t('warning.requiredField')),
      students: yup.array().min(1, 'O projeto deve ter no mínimo 1 Estudante.').required(t('warning.requiredField')),
      participants: yup.array().min(1, 'O projeto deve ter no mínimo 1 Participante.').required(t('warning.requiredField')),
      selectedParticipants: yup.array().min(1, 'O projeto deve ter no mínimo 1 Participante.').required(t('warning.requiredField'))
    }),
    onSubmit: () => {}
  });

  const getSteps = () => {
    switch (createProjectForm.values.organization) {
      case 'group':
        setAllSteps([...steps, ...groupSteps]);
        break;
      case 'custom':
        setAllSteps([...steps, ...customSteps]);
        break;
      default:
        setAllSteps(steps);
        break;
    }
  };

  const getCourses = async () => {
    setCoursesLoading(true);

    const { data, error } = await client.fetch('courses/simple');
    if (error) {
      toast.error(t('toast.errorGetCourse'));
    } else {
      setCourses(data);
    }
    setCoursesLoading(false);
  };

  const getClassrooms = async id => {
    const { data, error } = await client.fetch(`classrooms?filter[course_id]=${id}`);
    if (error) {
      toast.error(t('toast.errorGetClasses'));
    } else {
      setClassrooms(data);
    }
  };

  const getStep = () => {
    switch (step) {
      case 'curso-turma':
        setCurrentStep(1);
        break;
      case 'nome':
        setCurrentStep(2);
        break;
      case 'imagem':
        setCurrentStep(3);
        break;
      case 'organizacao':
        setCurrentStep(4);
        break;
      case 'participantes':
        setCurrentStep(5);
        break;
      case 'gestores':
        setCurrentStep(6);
        break;
      case 'personalizado-participantes':
        setCurrentStep(5);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getCourses();
    getStep();
    getGroupProjects();
  }, []);

  useEffect(() => {
    if (createProjectForm.values.course !== '') {
      getClassrooms(createProjectForm.values.course);
    } else {
      setClassrooms([]);
    }
  }, [createProjectForm.values.course]);

  useEffect(() => {
    getSteps();
  }, [createProjectForm.values.organization]);

  useEffect(() => {
    if (currentGroupProject !== null && courses.length > 0) {
      let model, course, classroom, students, managers, selectedManagers, participants, selectedParticipants;
      model = currentGroupProject['project-model'];

      function extractIdsFromObjects(array) {
        return array.map(obj => {
          const students = obj.users.filter(u => u.profiles[0].name === 'Aluno');
          const ids = students.map(user => user.id);
          return ids;
        });
      }

      function extractIdsFromObjects2(array) {
        const managers = array.projects[0].users.filter(u => u.profiles[0].name !== 'Aluno');
        const ids = managers.map(user => user.id);
        return ids;
      }

      function extractIdsFromObjects3(array) {
        const managers = array[0].users.filter(u => u.profiles[0].name !== 'Aluno');
        const ids = managers.map(user => user);
        return ids;
      }

      function extractIdsFromObjects4(array) {
        return array.flatMap(obj => obj.users.map(user => user.id));
      }

      function findCourseByClassroomId(classroomId, courses) {
        return courses.find(course => {
          return course.classrooms.some(classroom => classroom.id === classroomId);
        });
      }

      if (model === 'random') {
        model = 'group';
        students = extractIdsFromObjects(currentGroupProject.projects);
        managers = extractIdsFromObjects2(currentGroupProject);
        selectedManagers = extractIdsFromObjects3(currentGroupProject.projects);
      } else if (model === 'individual') {
        model = 'individual';
        students = extractIdsFromObjects(currentGroupProject.projects);
      } else if (model === 'custom') {
        participants = extractIdsFromObjects4(currentGroupProject.projects);
        selectedParticipants = currentGroupProject.projects[0].users;
      }

      if (currentGroupProject.projects[0]['projectable-type'] === 'Course') {
        course = courses.find(c => c.id === currentGroupProject.projects[0]['projectable-id']);
        classroom = '';
      }

      if (currentGroupProject.projects[0]['projectable-type'] === 'Classroom') {
        course = findCourseByClassroomId(currentGroupProject.projects[0]['projectable-id'], courses);
        classroom = classrooms.find(c => c.id === currentGroupProject.projects[0]['projectable-id']);
      }

      createProjectForm.setValues({
        'user-id': session.user.id,
        projects: currentGroupProject.projects,
        title: currentGroupProject.projects[0].name,
        description: currentGroupProject.projects[0].about,
        organization: model,
        course: course.id,
        classroom: classroom ? classroom.id : '',
        groupSize: currentGroupProject['users-per-group'],
        students: students || [],
        managers: managers || [],
        selectedManagers: selectedManagers || [],
        participants: participants || [],
        selectedParticipants: selectedParticipants || []
      });
    }
  }, [currentGroupProject, groupProjects, courses, classrooms]);

  return (
    <div className="main-screen">
      {authorityLevel !== 'student' ? (
        <>
          <ProjectSidebar
            steps={allSteps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            form={createProjectForm}
          />

          <main className="main-content">
            <ProjectContent
              step={step}
              steps={allSteps}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              courses={courses}
              form={createProjectForm}
              classrooms={classrooms}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              currentGroupProject={currentGroupProject}
              editingPath={id}
              coursesLoading={coursesLoading}
            />
          </main>
        </>
      ) : (
        <Error404 />
      )}
    </div>
  );
}
