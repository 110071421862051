import { useClient } from 'jsonapi-react';
import React, { createContext, useState } from 'react';
import toast from 'react-hot-toast';

export const ReportAbilitiesContext = createContext();

const ReportsAbilitiesProvider = ({ children }) => {
  const client = useClient();
  const [reportAbilities, setReportAbilities] = useState();

  const getReportAbilities = async () => {
    const { data, error } = await client.fetch('platform_report_abilities');
    if (error) {
      toast.error('Erro ao obter dados');
    } else {
      setReportAbilities(data);
    }
  };

  return <ReportAbilitiesContext.Provider value={{ reportAbilities, getReportAbilities }}>{children}</ReportAbilitiesContext.Provider>;
};

export default ReportsAbilitiesProvider;
