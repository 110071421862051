import React, { useContext } from 'react';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { FiChevronLeft, FiFolder } from 'react-icons/fi';
import { DocumentsCard } from 'app/components/Contents/DocumentsCard';
import { MaterialsContext } from '../../providers/MaterialsProvider';
import { t } from 'i18next';

function Documents(props) {
  const { backMaterials, materialsFolder } = props;
  const { deleteMaterialDocument } = useContext(MaterialsContext);

  const filters = useFormik({
    initialValues: {
      document: false,
      video: false,
      audio: false
    },
    onSubmit: values => {
      console.log(values);
    }
  });

  return (
    <div className="tab__pane">
      <div className="widget">
        <div className="filter-bar">
          <div
            onClick={backMaterials}
            className="filter-bar__back"
          >
            <div className="card__icon-wrapper">
              <FiChevronLeft />
              <FiFolder />
            </div>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{materialsFolder.name}</span>
            </span>
          </div>
        </div>

        <div className="round-dg-wrapper">
          <table className="round-dg round-dg--light">
            <tbody className="round-dg__body">
              {materialsFolder.contents.map(document => (
                <DocumentsCard
                  document={document}
                  onDelete={() => {
                    Swal.fire({
                      title: t('projects.warningDeleteMaterial'),
                      confirmButtonText: t('button.yes'),
                      showDenyButton: true,
                      denyButtonText: t('button.no'),
                      showCloseButton: true
                    }).then(res => {
                      if (res.isConfirmed) {
                        deleteMaterialDocument(document.id);
                      }
                    });
                  }}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Documents;
