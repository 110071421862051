import { useClassroom } from 'app/hooks/useClassroom';
import { useClient } from 'jsonapi-react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import ParticipantItemCard from './ParticipantItemCard';
import { t } from 'i18next';

export default function ParticipantsForm(props) {
  const {
    setShowModal,
    classroom,
    allUsers,
    addParticipantToClassroom,
    deleteParticipantFromClassroom
  } = props;

  const client = useClient();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredClassrooms = allUsers?.filter(participant => {
    if (searchTerm == '') {
      return participant;
    }
    if (
      participant.name != null &&
      participant.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    ) {
      return participant;
    }
  });

  return (
    <form
      method="post"
      className="form"
      onSubmit={e => {
        e.preventDefault();
        setShowModal(false);
      }}
    >
      <h2 className="modal__simple-title">{t('courses.addParticpants')}</h2>

      <input
        style={{ position: 'sticky', top: '0' }}
        className="form__control u-mb-3"
        type="search"
        placeholder="Buscar participantes..."
        onChange={e => setSearchTerm(e.target.value)}
      />

      <div
        className="form__row u-overflow-y"
        style={{
          minHeight: '56px',
          maxHeight: '75vh',
          padding: '16px',
          margin: '0 -16px 1.5em -16px',
        }}
      >
        {filteredClassrooms?.map(allUsers => (
          <ParticipantItemCard
            allUsers={allUsers}
            key={allUsers.id}
            addParticipantToClassroom={addParticipantToClassroom}
            deleteParticipantFromClassroom={deleteParticipantFromClassroom}
            classroom={classroom} />
        ))}
      </div>

      <button className="btn btn--primary btn--wide" type="submit">
        Fechar
      </button>
    </form>
  );
}
