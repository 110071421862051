import React, { useEffect, useState, useRef } from 'react';
import Avatar from 'app/components/Avatar';
import { FiTrash2 } from 'react-icons/fi';
import { useAuditorium } from 'app/hooks/useAuditorium';
import { useSession } from 'app/hooks/useSession';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { t } from 'i18next';

export default function CommentCard(props) {
  // const { deleteChat } = useAuditorium();
  const { chat, id, messageChats, setChats } = props;
  const reply = chat['auditorium-message'] ? true : false;
  const bodyReply = chat['auditorium-message']?.body;
  const { session } = useSession();
  const messageCardBodyRef = useRef(null);
  const client = useClient();

  const deleteChat = async () => {
    const { error } = await client.delete(`auditoriums/${id}/auditorium_chat_messages/${chat.id}`);
    const newChatData = messageChats.filter(item => item.id !== chat.id);

    if (error) {
      toast.error(t('toast.errorDeleteMessage'));
    } else {
      setChats(newChatData);
      toast.success(t('toast.successDeleteMessage'));
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: 'Deseja mesmo excluir este comentário?',
      text: 'Esta ação não poderá ser desfeita',
      confirmButtonText: 'Sim',
      showDenyButton: true,
      denyButtonText: 'Não',
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteChat();
      }
    });
  };

  useEffect(() => {
    if (messageCardBodyRef.current) {
      messageCardBodyRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [chat.body]);

  const isMyChat = () => {
    if (session.user.name === chat.user.name) {
      return true;
    }
  };

  const extractTextFromHTML = html => {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  };

  return (
    chat?.user && (
      <div className={reply ? 'chat-widget__comment-wrapper chat-widget__comment-wrapper--reply' : 'chat-widget__comment-wrapper'}>
        {reply && (
          <p
            className="chat-widget__original-text"
            dangerouslySetInnerHTML={{ __html: bodyReply.length > 50 ? `${extractTextFromHTML(bodyReply).substring(0, 60)}...` : bodyReply }}
          ></p>
        )}

        <div className="chat-widget__comment">
          <Avatar
            className="avatar--xs"
            src={chat.user.image?.url}
          />
          <div className="chat-widget__comment-inside">
            <div className="chat-widget__comment-header">
              <h3 className="chat-widget__comment-title">{chat?.user?.name}</h3>
              <span className="badge badge--tiny">{chat?.user?.['profile-names']?.[0]}</span>
            </div>
            <div className="chat-widget__comment-body">
              <p
                className="chat-widget__comment-text"
                ref={messageCardBodyRef}
              >
                {chat?.body}
              </p>
            </div>
          </div>
          <div className="chat-widget__comment-actions">
            {isMyChat() && (
              <FiTrash2
                className="chat-widget__icon chat-widget__icon--danger"
                onClick={handleDelete}
              />
            )}
          </div>
        </div>
      </div>
    )
  );
}
