import React, { useState, useEffect } from 'react';
import Progress from 'app/components/Progress';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function SimulatedClock(props) {
	const { t } = useTranslation();
	const { questionBooksUser, questionBook, setElapsedTime, elapsedTime, examTime } = props;
	const [clock, setClock] = useState();
	const [percentageTime, setPercentageTime] = useState();
	let intervalId = null

	useEffect(() => {
		tick();
		if (intervalId !== null) { clearInterval(intervalId) }
		intervalId = setInterval(() => tick(), 1000);
	}, []);



	const tick = () => {
		if (questionBook && questionBooksUser) {
			const createdAt = questionBooksUser.created_at;
			const resolutionTime = moment(`1970-01-01 ${questionBook['resolution-time']}`)
			const newClock = resolutionTime.subtract(moment().diff(createdAt, 'seconds'), 'second');

			const durationSeconds = moment(questionBook['resolution-time'], 'HH:mm').diff(moment().startOf('day'), 'seconds');
			const passedSeconds = moment().diff(createdAt, 'seconds');
			const percentage = ((durationSeconds - passedSeconds) * 100) / durationSeconds;

			if (percentage <= 0 ) {
				clearInterval(intervalId)
			} else {
				setPercentageTime(100 - percentage)
				setClock(newClock)
				setElapsedTime(passedSeconds)
			}
		}
	}

	return (
		<div className="widget u-mb-2">
			<div className="widget__header">
				<div className="widget__title-with-btn">
					<h3 className="widget__title">{t("exams.time")}</h3>
					<span className="widget__count">
						<span>{!examTime ? "Sem tempo definido!" : examTime + 'h'}</span>
					</span>
				</div>
			</div>
			<div
				className="widget__body"
				style={{ display: 'flex', justifyContent: 'center' }}
			>
				{clock && <Progress.Circular time={clock.format('H:mm:ss')} valueNow={percentageTime} />}
			</div>
		</div>
	)
}
export default SimulatedClock;
