import React, { useState } from 'react';
import StarRating from './StarRating';
import Modal from 'app/components/Modal';
import { useTranslation } from 'react-i18next';

export default function AnswerOption(props) {
  const { t } = useTranslation();
  const { answer, index, handleAnswerChange } = props;

  const [showModal, setShowModal] = useState(false);
  const [proposal, setProposal] = useState(answer.proposal);

  const submitForm = (e) => {
    e.preventDefault();
    setShowModal(false);
    let newAnswer = answer
    newAnswer.proposal = proposal
    handleAnswerChange(index, newAnswer)
  }

  return (
    <div className="quiz-answer-option">
      <h2 className="quiz-answer-option__text">{answer.subtitle}</h2>
      <StarRating rating={answer.rate} />
      <button onClick={() => setShowModal(true)} className="btn btn--outline">
        {t('nps.buttonIntervention')}
      </button>

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <form
          method="post"
          className="form"
          onSubmit={submitForm}
        >
          <div className="form__row">
            <label className="form__label" htmlFor="interventionProposal">
            {t('nps.buttonIntervention')}
            </label>
            <textarea
              id="interventionProposal"
              name="interventionProposal"
              placeholder={t('nps.writeProposal')}
              defaultValue={proposal}
              onChange={(e) => setProposal(e.target.value)}
            />
          </div>

          <button className="btn btn--primary" type="submit">
          {t("button.save")}
          </button>
        </form>
      </Modal>
    </div>
  );
}
