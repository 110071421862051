import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from '@reach/router';
import { CSSTransition } from 'react-transition-group';
import Progress from 'app/components/Progress';
import { useTranslation } from 'react-i18next';

function Simulated(props) {
  const { t } = useTranslation();
  const {
    questionBook,
    show,
    onClose,
    onSubmit,
    title = 'Modal title',
    teacher = 'Teacher',
    finalize,
    to = '/',
  } = props;

  function closeOnEscapeKeyDown(event) {
    if (event.key === 'Escape') onClose();
  }

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
      document.body.style.overflow = 'visible';
    };
  }, []);

  useEffect(() => {
    if (show) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [show]);

  const nodeRef = React.useRef(null); //Fix react-transition-group

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      unmountOnExit
      nodeRef={nodeRef}
      timeout={{ enter: 300, exit: 300 }}
    >
      <div className="modal" onClick={onClose} ref={nodeRef}>
        <div
          className="modal__content"
          onClick={event => event.stopPropagation()}
        >
          <header className="modal__header">
            <h4 className="modal__title">{title}</h4>
            <p className="modal__teacher">{teacher}</p>
          </header>

          <div className="modal__body">
            <span className="modal__test">{questionBook.title}</span>
            {!finalize && (
              <div className="modal__description">
                <div className="modal__progress">
                  <Progress.Circular valueNow={ questionBook['question-book-done-percentage'] || 0} />
                </div>

                <p className="modal__description-text">
                  {`Este caderno de questões contém ${questionBook['question-count']} questões e deve ser respondido em ${questionBook['resolution-time']} ${t("tasks.hours")} corridas.`}
                </p>
              </div>
            )}
          </div>

          <footer className="modal__footer">
            {!finalize ? (
              <>
                <p className="modal__reminder">Bebeu água, foi ao banheiro?</p>
                <Link to={to} className="btn btn--primary">
                  Começar a prova
                </Link>
              </>
            ) : (
              <>
                <p className="modal__reminder modal__reminder--finalize">
                {t("exams.subtitleSubmitExam")}
                </p>
                <div className="modal__btns">
                  <button className="btn btn--outline-danger" onClick={onClose}>
                  {t("button.notFinish")}
                  </button>
                  <button className="btn btn--secondary" onClick={onSubmit}>
                  {t("button.submitAndFinish")}
                  </button>
                </div>
              </>
            )}
          </footer>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById('modal_root'),
  );
}

export default Simulated;
