import React from 'react';
import { ReactComponent as Star } from 'app/images/icons/fi-star.svg';

export default function StarRating(props) {
  const { rate, setRate, onClick } = props;

  const stars = [...Array(5)];

  return (
    <div className="star-rating">
      {stars.map((_, index) => {
        const current = index + 1;

        return (
          <button
            type="button"
            key={index}
            className={rate >= current ? 'star-rating__btn on' : 'star-rating__btn off'}
            onClick={() => {
              setRate(current);
              onClick();
            }}
            onMouseEnter={() => setRate(current)}
            onMouseLeave={() => setRate(0)}
          >
            <Star className="star-rating__star" />
          </button>
        );
      })}
    </div>
  );
}
