import React from 'react';
import { Link } from '@reach/router';
import { t } from 'i18next';

export default function TabNavBar({ selectedTab }) {
  return (
    <div className="tab">
      <Link
        className={selectedTab === 'grupos' ? 'tab__link active' : 'tab__link'}
        to="/projetos/grupos"
      >
        {t('projects.toggleGroups')}
      </Link>
      <Link
        className={
          selectedTab === 'individuais' ? 'tab__link active' : 'tab__link'
        }
        to="/projetos/individuais"
      >
        {t('projects.toggleIndividuals')}
      </Link>
      <Link
        className={
          selectedTab === 'personalizados' ? 'tab__link active' : 'tab__link'
        }
        to="/projetos/personalizados"
      >
        {t('projects.toggleCustomized')}
      </Link>
    </div>
  );
}
