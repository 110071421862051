import React, { useState } from 'react';
import BlockModal from './BlockModal';
import { useClient } from 'jsonapi-react';

export default function MatterRow(props) {
  const { matter } = props;

  const client = useClient();

  const [showModal, setShowModal] = useState(false);
  const [isBlock, setIsBlock] = useState(matter.status === 'blocked');

  const taxonomyChildren = matter?.["taxonomy-children"] || [];
  const firstThreeItems = taxonomyChildren.slice(0, 3);
  const remainingItemsCount = taxonomyChildren.length - firstThreeItems.length;
  const remainingItemsNames = taxonomyChildren.slice(3).map(item => item.name).join(', ');

  const updateTaxonomyStatus = async (id, payload) => {
    try {
      const response = await client.mutate(`user_taxonomies/${id}`, payload);
      console.log('Updated taxonomy:', response.data);
    } catch (error) {
      console.error('Failed to update taxonomy:', error);
    }
  };

  const handleBlock = () => {
    const newStatus = isBlock ? 'active' : 'blocked';
    updateTaxonomyStatus(matter.id, { status: newStatus });
    setIsBlock(!isBlock);
    setShowModal(false);
  };

  return (
    <tr className={isBlock ? 'round-dg__row round-dg__row--danger' : 'round-dg__row'}>
      <td className="round-dg__cell-header round-dg__cell-header--row">
        <div className="round-dg__user">
          <div className="round-dg__user-inner">
            <h3
              className="round-dg__title"
              title={matter.title}
            >
              {matter?.["taxonomy-name"]}
            </h3>
          </div>
        </div>
      </td>
      <td className="round-dg__cell">
        <div
          className="card__badges card__badges--one-line u-mb-0"
          style={{ lineHeight: 1, alignItems: 'center' }}
        >
          {firstThreeItems.map((item) => (
            <span
              key={item.id}
              className="badge badge--tiny"
              title={item.name}
            >
              {item.name}
            </span>
          ))}
          {remainingItemsCount > 0 && (
            <span
              className="badge badge--tiny badge--more"
              title={remainingItemsNames}
            >
              +{remainingItemsCount}
            </span>
          )}
        </div>
      </td>
      <td className="round-dg__cell">
        {matter.isAdd && (
          <span
            className="badge badge--tiny badge--attention"
            title={'Você adicionou'}
          >
            {'Você adicionou'}
          </span>
        )}
      </td>
      <td className="round-dg__cell">
        {isBlock ? (
          <button
            type="button"
            className="btn btn--small btn--full btn--outline-success"
            onClick={handleBlock}
          >
            Desbloquear
          </button>
        ) : (
          <button
            type="button"
            className="btn btn--small btn--full btn--outline-danger"
            onClick={() => setShowModal(true)}
          >
            Bloquear
          </button>
        )}
      </td>

      <BlockModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleBlock}
      />
    </tr>
  );
}
