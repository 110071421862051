import React from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import moment from 'moment';
import { useState } from 'react';
import { useAuditorium } from 'app/hooks/useAuditorium';
import Swal from 'sweetalert2';

export default function FileCard(props) {
  const { messages, files, deleteFile } = useAuditorium();
  const { file, id } = props;
  const isAdmin = !checkIncludeProfiles({ only: ['student'] });
  const [showContent, setShowContent] = useState(false);

  const handleView = () => {
    setShowContent(true);
    window.open(file.file.url, '_blank');
  };

  const handleDelete = () => {
    Swal.fire({
      title: 'Deseja mesmo excluir este arquivo?',
      text: 'Esta ação não poderá ser desfeita',
      confirmButtonText: 'Sim',
      showDenyButton: true,
      denyButtonText: 'Não',
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteFile(id, file);
      }
    });
  };

  return (
    <div className="chat-widget__file">
      <div className="chat-widget__file-inside">
        <h3 className="chat-widget__file-title">{file.filename}</h3>
        <p className="chat-widget__file-meta">{`${file.user?.name ?? ''} em ${moment(file?.['created-at']).format('HH:mm')}`}</p>
      </div>
      {isAdmin ? (
        <Dropdown>
          <Dropdown.Toggle>
            <span className="card__icon-wrapper">
              <FiMoreHorizontal className="card__icon" />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Content>
            <Dropdown.Item onClick={handleView}>{'Visualizar'}</Dropdown.Item>
            <Dropdown.Item onClick={handleDelete}>{'Excluir'}</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown>
      ) : (
        <a
          href={file.file.url}
          target="_blank"
          download
          rel="noopener noreferrer"
          className="btn btn--small btn--outline"
        >
          Baixar
        </a>
      )}
    </div>
  );
}
