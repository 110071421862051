import React from 'react';
import { FiPlus, FiX } from 'react-icons/fi';

export default function MultiSelect(props) {
  const { selectedTaxonomies, setFieldValue, openModal, placeholder } = props;

  return (
    <div className="multi-select">
      <div className="multi-select__value-container">
        {selectedTaxonomies?.length === 0 && (
          <span className="multi-select__placeholder">
            {placeholder || 'Adicione...'}
          </span>
        )}

        {selectedTaxonomies?.length > 0 &&
          selectedTaxonomies?.map(t => (
            <span key={t.id} className="badge badge--primary">
              {t.name}
              <button
                onClick={() => {
                  const newSelectedTaxonomies = selectedTaxonomies?.filter(
                    t2 => t.id !== t2.id,
                  );

                  setFieldValue('selectedTaxonomies', newSelectedTaxonomies);
                }}
                type="button"
                className="badge__btn"
              >
                <FiX />
              </button>
            </span>
          ))}
      </div>

      <button
        type="button"
        className="multi-select__indicator"
        onClick={openModal}
        title={
          selectedTaxonomies?.length > 0
            ? 'Editar matérias'
            : 'Adicionar matérias'
        }
        aria-label={
          selectedTaxonomies?.length > 0
            ? 'Editar matérias'
            : 'Adicionar matérias'
        }
      >
        <FiPlus />
      </button>
    </div>
  );
}
