import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { useTranslation } from 'react-i18next';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import RadarChart from './RadarChart';
import BarChart from './BarChart';
import SeducPiGeral from './Geral';
import SeducPiGRE from './Geral/GRE';

function SeducPi() {
  const client = useClient();
  const { t } = useTranslation();
  const [classrooms, setClassrooms] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState();
  const [selectedClassroom, setSelectedClassroom] = useState();
  const [radarScore, setRadarScore] = useState();
  const [taxonomiesReport, setTaxonomiesReport] = useState();

  const getClassrooms = async () => {
    try {
      const { data } = await client.fetch('/classrooms');
      setClassrooms(data);
    } catch {
      toast.error(t('Erro ao buscar escolas'));
    }
  };

  const getInstitutions = async () => {
    try {
      const { data } = await client.fetch('/institutions');
      setInstitutions(data);
    } catch {
      toast.error(t('Erro ao buscar turmas'));
    }
  };

  const getRadarScore = async () => {
    try {
      const query = selectedInstitution ? `institution_id=${selectedInstitution?.id}` : `classroom_id=${selectedClassroom?.id}`;
      const { data } = await client.fetch('reports/study_plans/parent_taxonomies_report?' + query);
      setRadarScore(data);
    } catch {
      toast.error(t('Erro ao buscar performance das matérias'));
    }
  };

  const getTaxonomiesReport = async () => {
    try {
      const query = selectedInstitution ? `institution_id=${selectedInstitution?.id}` : `classroom_id=${selectedClassroom?.id}`;
      const { data } = await client.fetch('reports/study_plans/child_taxonomies_report?' + query);
      setTaxonomiesReport(data);
    } catch {
      toast.error(t('Erro ao buscar performance das matérias'));
    }
  };

  useEffect(() => {
    getClassrooms();
    getInstitutions();
  }, []);

  useEffect(() => {
    if (selectedInstitution || selectedClassroom) {
      getRadarScore();
      getTaxonomiesReport();
    }
  }, [selectedInstitution, selectedClassroom]);

  const filters = useFormik({
    initialValues: {
      classroom: 'blank',
      institution: 'blank'
    }
  });

  const handeFilterClassroom = e => {
    const selectedId = e.target.value;
    filters.setFieldValue('classroom', selectedId);
    filters.setFieldValue('institution', 'blank');
    setSelectedClassroom(classrooms.find(e => e.id === parseInt(selectedId)));
    setSelectedInstitution(null);
  };

  const handeFilterInstitution = e => {
    const selectedId = e.target.value;
    filters.setFieldValue('institution', selectedId);
    filters.setFieldValue('classroom', 'blank');
    setSelectedClassroom(null);
    setSelectedInstitution(institutions.find(e => e.id === parseInt(selectedId)));
  };

  return (
    <main style={{ padding: '32px' }}>
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <FilterSelectionBox
            label={null}
            blankLabel="Selecione uma escola"
            value={filters.values.classroom}
            onChange={handeFilterClassroom}
            options={classrooms.map(c => {
              return { id: c.id, title: c.title };
            })}
            style={{ width: '250px' }}
          />
          <FilterSelectionBox
            label={null}
            blankLabel="Selecione uma turma"
            value={filters.values.institution}
            onChange={handeFilterInstitution}
            options={institutions.map(c => {
              return { id: c.id, title: c.name };
            })}
            style={{ width: '250px' }}
          />
        </div>
      </div>

      <div className="widget">
        <div className="widget__header">
          <h3 className="widget__title">Performance na prova - {selectedClassroom?.title || selectedInstitution?.name}</h3>
        </div>

        <div className="widget__body">
          <div className="u-mb-4">
            <RadarChart radarScore={radarScore} />
          </div>

          {(taxonomiesReport?.['chart-data'] || []).map((item, i) => {
            const filteredData = item.children.data.filter(e => e.average_result > 0);
            if (filteredData.length > 0) {
              return (
                <div
                  className="u-mb-4"
                  key={i}
                >
                  <h4 className="widget__title">{item['taxonomy-parent-name']}</h4>
                  <BarChart data={filteredData} />
                </div>
              );
            }
          })}
        </div>
      </div>
    </main>
  );
}

export { SeducPi as default, SeducPiGeral, SeducPiGRE };
