import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { navigate } from '@reach/router';
import { useClient } from 'jsonapi-react';
import Loader from 'app/components/loader';
import { t } from 'i18next';

export const ProjectForm = props => {
  const client = useClient();
  const { labelForm, labelFormButton, currentProject } = props;
  const [imageLabel, setImageLabel] = useState();
  const [relationshipsLoading, setRelationshipsLoading] = useState(true);
  const [relationships, setRelationships] = useState({
    courses: [],
    classrooms: []
  });
  const [selectedAlternative, setSelectedAlternative] = useState(currentProject && currentProject['projectable-type'] == 'Classroom' ? 1 : 0);
  const notebookAlternatives = [
    { id: 0, name: 'Curso' },
    { id: 1, name: 'Turma' }
  ];

  const formProject = useFormik({
    initialValues: {
      name: currentProject.projects[0].name,
      'projectable-id': currentProject.projects[0]['projectable-id'],
      'projectable-type': currentProject.projects[0]['projectable-type'],
      image: ''
    },
    onSubmit: values => {}
  });

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses');
    const { data: classrooms } = await client.fetch('classrooms');

    setRelationships({
      courses: courses || [],
      classrooms: classrooms || []
    });
    setRelationshipsLoading(false);
  };

  const handleChangeImage = e => {
    let file = e.target.files[0];
    setImageLabel(e.target.files[0].name);

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = readerEvt => {
    let bynaryString = readerEvt.target.result;
    formProject.setFieldValue('image', `data:image/jpeg;base64,${btoa(bynaryString)}`);
  };

  useEffect(() => {
    getRelationships();
  }, []);

  return (
    <>
      {formProject && (
        <form
          method="post"
          className="form"
          onSubmit={formProject.submitForm}
        >
          <h2 className="form__title">{labelForm}</h2>

          {relationshipsLoading ? (
            <Loader />
          ) : (
            <>
              <div className="form__row">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Escolha o tipo de caderno"
                >
                  {notebookAlternatives.map((alternative, index) => (
                    <React.Fragment key={index}>
                      <input
                        type="radio"
                        name="notebookType"
                        id={alternative.id}
                        autoComplete="off"
                        defaultChecked={index === selectedAlternative}
                        onChange={() => {
                          setSelectedAlternative(alternative.id);
                        }}
                      />
                      <label
                        htmlFor={alternative.id}
                        className="btn btn--outline"
                      >
                        {alternative.name}
                      </label>
                    </React.Fragment>
                  ))}
                </div>
              </div>

              {selectedAlternative === 0 && (
                <div className="form__row form__row--hr">
                  <select
                    className="form__select"
                    name="courses"
                    id="courses"
                    aria-label="Escolha o curso"
                    value={formProject.values['projectable-id']}
                    onChange={e => {
                      formProject.setFieldValue('projectable-id', e.target.value);
                      formProject.setFieldValue('projectable-type', 'Course');
                    }}
                  >
                    <option>{t('filter.labelSelectTheCourse')}</option>
                    {(relationships?.courses || []).map(c => (
                      <option value={c.id}>{c.title}</option>
                    ))}
                  </select>
                </div>
              )}

              {selectedAlternative === 1 && (
                <div className="form__row form__row--hr">
                  <select
                    className="form__select"
                    name="classrooms"
                    id="classrooms"
                    aria-label="Escolha a turma"
                    value={formProject.values['projectable-id']}
                    onChange={e => {
                      formProject.setFieldValue('projectable-id', e.target.value);
                      formProject.setFieldValue('projectable-type', 'Classroom');
                    }}
                  >
                    <option>{t('filter.labelSelectTheClassroom')}</option>
                    {(relationships?.classrooms || []).map(c => (
                      <option value={c.id}>{c.title}</option>
                    ))}
                  </select>
                </div>
              )}

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="name"
                >
                  {t('projects.projectName')}
                </label>
                <input
                  className="form__control"
                  id="name"
                  name="name"
                  type="text"
                  placeholder={t('projects.placeholderProjectName')}
                  value={formProject.values.name}
                  onChange={formProject.handleChange}
                />
              </div>

              <fieldset className="form__row">
                <legend className="form__label">{t('courses.coverImage')} (1020x252 pixels)</legend>
                <label className="form__custom-file-input">
                  <input
                    type="file"
                    name="image"
                    id="image"
                    accept=".jpeg, .png, .jpg"
                    onChange={handleChangeImage}
                    defaultValue={formProject.values.image}
                  />
                  <span className="form__control">{imageLabel ? imageLabel : 'Clique para fazer upload de uma imagem'}</span>
                </label>
              </fieldset>

              <button
                type="button"
                className="btn btn--primary u-ml-auto"
                onClick={formProject.submitForm}
              >
                {labelFormButton}
              </button>
            </>
          )}
        </form>
      )}
    </>
  );
};
