import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { navigate } from '@reach/router';
import { FiChevronLeft, FiHelpCircle } from 'react-icons/fi';
import { FiChevronDown } from 'react-icons/fi';
import { FiChevronUp } from 'react-icons/fi';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { AiOutlineTrophy } from 'react-icons/ai';
import { t } from 'i18next';

export default function SeducPiGRE() {
  const client = useClient();
  const [data, setData] = useState([]);
  const [fristItem, setFristItem] = useState(true);
  const [lastItem, setLastItem] = useState(true);
  const [regional, setRegional] = useState();
  const { questionBookId, greId } = useParams();
  const [orderByNotes, setOrderByNotes] = useState();
  const [orderBySubscribers, setOrderBySubscribers] = useState();
  const [orderByPercentageSubscribers, setOrderByPercentageSubscriber] = useState();
  const [ordenedInstitutions, setOrdenedInstitutions] = useState(data.items ?? []);
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  const getData = async () => {
    setLoading(true)
    let url = `reports/user_registrations?filter[regional_id]=${greId}`
    if (questionBookId) {
      url = `reports/user_registrations?filter[regional_id]=${greId}&question_book_id=${questionBookId}`
    }
    const { data, error } = await client.fetch(url);

    if (error) {
      toast.error('Falha ao carregar dados');
    } else {
      setData(data);
      setFristItem(data.items[0]);
      setLastItem(data.items[data.items.length - 1]);
      setRegional(data.items[0].regional);
      setOrdenedInstitutions(data.items);
    }
    setLoading(false)
  };

  const getTrophyIcon = index => {
    switch (index) {
      case 0:
        return <AiOutlineTrophy className="card__icon card__icon--golden" />;
      case 1:
        return <AiOutlineTrophy className="card__icon card__icon--silver" />;
      case 2:
        return <AiOutlineTrophy className="card__icon card__icon--bronze" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChangeOrder = orderType => {
    let institutions = data?.items;
    switch (orderType) {
      case 'notes':
        setOrderBySubscribers(false);
        setOrderByPercentageSubscriber(false);
        if (orderByNotes) {
          setOrderByNotes(false);
          institutions = institutions?.sort((a, b) => a.average - b.average);
        } else {
          setOrderByNotes(true);
          institutions = institutions?.sort((a, b) => b.average - a.average);
        }
        setOrdenedInstitutions(institutions);
        break;
      case 'subscribers':
        setOrderByNotes(false);
        setOrderByPercentageSubscriber(false);
        if (orderBySubscribers) {
          setOrderBySubscribers(false);
          institutions = institutions?.sort((a, b) => a.studentsCount - b.studentsCount);
        } else {
          setOrderBySubscribers(true);
          institutions = institutions?.sort((a, b) => b.studentsCount - a.studentsCount);
        }
        setOrdenedInstitutions(institutions);
        break;
      case 'percentage':
        setOrderByNotes(false);
        setOrderBySubscribers(false);
        if (orderByPercentageSubscribers) {
          setOrderByPercentageSubscriber(false);
          institutions = institutions?.sort((a, b) => a.studentsPercentage - b.studentsPercentage);
        } else {
          setOrderByPercentageSubscriber(true);
          institutions = institutions?.sort((a, b) => b.studentsPercentage - a.studentsPercentage);
        }
        setOrdenedInstitutions(institutions);
        break;
    }
  };

  const kpis = [
    {
      id: 1,
      variation: 'report-kpi-card--check',
      title: 'Total de estudantes cadastrados',
      value: data.totalStudentsCount
    },
    {
      id: 2,
      variation: 'report-kpi-card--users',
      title: 'U.E com maior número de cadastros',
      value: `${fristItem.name} - ${fristItem.studentsCount} cadastros`
    },
    {
      id: 3,
      variation: 'report-kpi-card--user',
      title: 'U.E com menor número de cadastros',
      value: `${lastItem.name} - ${lastItem.studentsCount} cadastros`
    },
    {
      id: 4,
      variation: '',
      title: 'Média geral',
      value: data?.totalAverage?.toFixed(2)
    }
  ];

  return (
    <main className="report">
      <header className="report__header">
        <h1 className="report__title">
          <button
            className="card__icon-wrapper"
            aria-label="Voltar"
            title="Voltar"
            onClick={() => navigate(-1)}
          >
            <FiChevronLeft className="card__icon" />
          </button>
          Simulados e notas - {location.state.regionalName}
        </h1>
        <p className="report__description">{questionBookId ? location.state.questionBookName : 'Geral'}</p>
      </header>

      <div className="report__body">
        <div className="report-widget">
          <div className="report-widget__header">
            <h3 className="report-widget__title">Visão geral</h3>
          </div>

          <div className="report-widget__body">
            <div className="report-kpi-card-wrapper">
              {kpis.map(kpi => (
                <div
                  className={`report-kpi-card ${kpi.variation}`}
                  key={kpi.id}
                >
                  {kpi.help && (
                    <>
                      <FiHelpCircle
                        className="report-kpi-card__help-icon"
                        data-tooltip-id={kpi.id}
                        data-tooltip-content={kpi.help}
                      />
                      <ReactTooltip
                        id={kpi.id}
                        className="tooltip"
                      />
                    </>
                  )}
                  <h3 className="report-kpi-card__title">{kpi.title}</h3>
                  <p
                    className="report-kpi-card__value"
                    title={kpi.value}
                  >
                    {loading ? t('loader.Loading') : kpi.value} {kpi.percentage ? <span>({kpi.percentage}%)</span> : null}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="report-widget">
          <div className="report-widget__header">
            <h3 className="report-widget__title">Ranking das instituições</h3>
          </div>

          <div className="report-widget__body">
            <div className="round-dg-wrapper">
              <table
                className="round-dg round-dg--light"
                style={{ minWidth: '900px' }}
              >
                <thead className="round-dg__header">
                  <tr className="round-dg__row">
                    <th
                      className="round-dg__cell-header round-dg__cell-header--sm"
                      style={{ width: 80 }}
                    >
                      Posição
                    </th>
                    <th className="round-dg__cell-header round-dg__cell-header--sm">Instituição</th>
                    <th
                      className="round-dg__cell-header round-dg__cell-header--sm"
                      style={{ width: 60 }}
                    />
                    <th
                      className="round-dg__cell-header round-dg__cell-header--sm"
                      onClick={() => handleChangeOrder('notes')}
                      style={{ width: 100 }}
                    >
                      Nota média{' '}
                      {orderByNotes ? (
                        <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                          <FiChevronUp className="card__icon" />
                        </span>
                      ) : (
                        <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                          <FiChevronDown className="card__icon" />
                        </span>
                      )}
                    </th>
                    <th
                      className="round-dg__cell-header round-dg__cell-header--sm"
                      onClick={() => handleChangeOrder('subscribers')}
                      style={{ width: 106 }}
                    >
                      Cadastrados{' '}
                      {orderBySubscribers ? (
                        <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                          <FiChevronUp className="card__icon" />
                        </span>
                      ) : (
                        <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                          <FiChevronDown className="card__icon" />
                        </span>
                      )}
                    </th>
                    <th
                      className="round-dg__cell-header round-dg__cell-header--sm"
                      onClick={() => handleChangeOrder('percentage')}
                      style={{ width: 210 }}
                    >
                      % do total de cadastros da GRE{' '}
                      {orderByPercentageSubscribers ? (
                        <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                          <FiChevronUp className="card__icon" />
                        </span>
                      ) : (
                        <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                          <FiChevronDown className="card__icon" />
                        </span>
                      )}
                    </th>
                  </tr>
                </thead>

                <tbody className="round-dg__body">
                  {ordenedInstitutions?.map((item, index) => (
                    <tr
                      className="round-dg__row"
                      key={index}
                    >
                      <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{index + 1}</th>
                      <td className="round-dg__cell round-dg__cell--sm">
                        <span
                          className="u-text-truncate"
                          title={item.name}
                        >
                          {item.name}
                        </span>
                      </td>
                      <td className="round-dg__cell round-dg__cell--sm">{getTrophyIcon(index)}</td>
                      <td className="round-dg__cell round-dg__cell--sm u-text-right">{item.average.toFixed(2)}</td>
                      <td className="round-dg__cell round-dg__cell--sm u-text-right">{item.studentsCount}</td>
                      <td className="round-dg__cell round-dg__cell--sm u-text-right">{item.studentsPercentage ? item.studentsPercentage.toString().replace('.', ',') : 0}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
