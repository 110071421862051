import React, { useState } from 'react';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import { t } from 'i18next';

export default function Requirements() {
  const [show, setShow] = useState(false);

  return (
    <div className="requirements">
      <div className={show ? 'requirements__inner requirements__inner--open' : 'requirements__inner requirements__inner--close'}>
        <button
          className="requirements__toggle"
          onClick={() => setShow(!show)}
        >
          {t('lms.requirements')} {show ? <FiChevronDown /> : <FiChevronUp />}
        </button>
        <ul className="requirements__content">
          <li className="requirements__item">
            <div className="requirements__inner-item">
              <h3 className="requirements__title">Cálculo 1</h3>
              <p className="requirements__subtitle">Física para iniciantes</p>
            </div>
            <AiOutlineTrophy className="requirements__icon" />
          </li>
          <li className="requirements__item">
            <div className="requirements__inner-item">
              <h3 className="requirements__title">Cálculo 1</h3>
              <p className="requirements__subtitle">Física para iniciantes</p>
            </div>
            <AiOutlineTrophy className="requirements__icon" />
          </li>
          <li className="requirements__item">
            <div className="requirements__inner-item">
              <h3 className="requirements__title">Cálculo 1</h3>
              <p className="requirements__subtitle">Física para iniciantes</p>
            </div>
          </li>
          <li className="requirements__item">
            <div className="requirements__inner-item">
              <h3 className="requirements__title">Cálculo 1</h3>
              <p className="requirements__subtitle">Física para iniciantes</p>
            </div>
          </li>
          <li className="requirements__item">
            <div className="requirements__inner-item">
              <h3 className="requirements__title">Cálculo 1</h3>
              <p className="requirements__subtitle">Física para iniciantes</p>
            </div>
            <AiOutlineTrophy className="requirements__icon" />
          </li>
          <li className="requirements__item">
            <div className="requirements__inner-item">
              <h3 className="requirements__title">Cálculo 1</h3>
              <p className="requirements__subtitle">Física para iniciantes</p>
            </div>
            <AiOutlineTrophy className="requirements__icon" />
          </li>
          <li className="requirements__item">
            <div className="requirements__inner-item">
              <h3 className="requirements__title">Cálculo 1</h3>
              <p className="requirements__subtitle">Física para iniciantes</p>
            </div>
            <AiOutlineTrophy className="requirements__icon" />
          </li>
          <li className="requirements__item">
            <div className="requirements__inner-item">
              <h3 className="requirements__title">Cálculo 1</h3>
              <p className="requirements__subtitle">Física para iniciantes</p>
            </div>
            <AiOutlineTrophy className="requirements__icon" />
          </li>
          <li className="requirements__item">
            <div className="requirements__inner-item">
              <h3 className="requirements__title">Cálculo 1</h3>
              <p className="requirements__subtitle">Física para iniciantes</p>
            </div>
            <AiOutlineTrophy className="requirements__icon" />
          </li>
          <li className="requirements__item">
            <div className="requirements__inner-item">
              <h3 className="requirements__title">Cálculo 1</h3>
              <p className="requirements__subtitle">Física para iniciantes</p>
            </div>
            <AiOutlineTrophy className="requirements__icon" />
          </li>
        </ul>
      </div>
      {show && (
        <div
          className="requirements__backdrop"
          onClick={() => setShow(false)}
        />
      )}
    </div>
  );
}
