import React from 'react';
import Select from 'react-select';

export default function CustomSelect(props) {
  const { isDisabled, value, label = 'Todos', placeholder = 'Selecione', onChange, className, options, isMulti } = props;

  return (
    <Select
      isMulti={isMulti}
      isDisabled={isDisabled}
      value={value?.map(item => {
        return { value: item.id ?? item.value, label: item.title ?? item.name ?? item.label };
      })}
      openMenuOnFocus
      options={[
        { value: 'blank', label: label },
        ...options?.map(item => {
          return { value: item.id, label: item.title ?? item.name };
        })
      ]}
      className={`react-multi-select ${className}`}
      classNamePrefix="react-multi-select"
      placeholder={placeholder ? placeholder : 'Selecione para filtrar'}
      noOptionsMessage={() => 'Sem opções'}
      components={{
        IndicatorSeparator: () => null,
        ClearIndicator: () => null
      }}
      onChange={onChange}
    />
  );
}
