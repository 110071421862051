import ReportLegend from 'app/components/ReportLegend';
import React from 'react';

function DotsImg(props) {
  return (
    <svg
      width={80}
      height={77}
      viewBox="0 0 80 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.354.795a.5.5 0 00-.708 0L.464 3.977a.5.5 0 00.708.707L4 1.855l2.828 2.829a.5.5 0 10.708-.707L4.354.795zM4 72.852h-.5v.5H4v-.5zm75.354.353a.5.5 0 000-.707l-3.182-3.182a.5.5 0 00-.707.707l2.828 2.829-2.829 2.828a.5.5 0 10.708.707l3.182-3.182zM3.5 1.148v71.704h1V1.148h-1zM4 73.352h75v-1H4v1z"
        fill="#CCD2E0"
      />
      <path
        d="M8.121 69.555L70.758 6.918"
        stroke="#8894AC"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <ellipse
        cx={28.3134}
        cy={53.8956}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
      <circle
        cx={19.6596}
        cy={58.8407}
        r={1.64835}
        fill="#FFA51C"
      />
      <circle
        cx={29.5492}
        cy={38.2363}
        r={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={40.6757}
        cy={48.9506}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={36.9672}
        cy={41.9451}
        rx={1.64835}
        ry={2.06044}
        fill="#FFA51C"
      />
      <circle
        cx={49.3295}
        cy={37.4121}
        r={1.64835}
        fill="#FFA51C"
      />
      <circle
        cx={37.7914}
        cy={34.1154}
        r={1.64835}
        fill="#FFA51C"
      />
      <circle
        cx={21.3075}
        cy={43.1814}
        r={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={34.0824}
        cy={46.4781}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={43.5605}
        cy={25.4616}
        rx={1.64835}
        ry={2.06044}
        fill="#FFA51C"
      />
      <circle
        cx={55.9233}
        cy={29.1703}
        r={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={53.0385}
        cy={13.511}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={22.5438}
        cy={50.5989}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={35.3188}
        cy={54.3077}
        rx={1.64835}
        ry={2.06044}
        fill="#FFA51C"
      />
      <circle
        cx={49.3295}
        cy={18.4561}
        r={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={55.099}
        cy={18.8682}
        rx={1.64835}
        ry={2.06044}
        fill="#FFA51C"
      />
      <ellipse
        cx={51.39}
        cy={27.522}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
    </svg>
  );
}

function DotsImgUp(props) {
  return (
    <svg
      width={80}
      height={77}
      viewBox="0 0 80 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.354.795a.5.5 0 00-.708 0L.464 3.977a.5.5 0 00.708.707L4 1.855l2.828 2.829a.5.5 0 10.708-.707L4.354.795zM4 72.852h-.5v.5H4v-.5zm75.354.353a.5.5 0 000-.707l-3.182-3.182a.5.5 0 00-.707.707l2.828 2.829-2.829 2.828a.5.5 0 10.708.707l3.182-3.182zM3.5 1.148v71.704h1V1.148h-1zM4 73.352h75v-1H4v1z"
        fill="#CCD2E0"
      />
      <path
        d="M8.121 69.555L70.758 6.918"
        stroke="#8894AC"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <ellipse
        cx={23.7797}
        cy={37.0008}
        rx={1.64835}
        ry={2.06044}
        fill="#FFA51C"
      />
      <ellipse
        cx={46.4442}
        cy={13.5119}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
      <circle
        cx={17.1879}
        cy={52.2472}
        r={1.64835}
        fill="#FFA51C"
      />
      <circle
        cx={23.7797}
        cy={26.6977}
        r={1.64835}
        fill="#FFA51C"
      />
      <circle
        cx={51.6484}
        cy={6.64829}
        r={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={26.0604}
        cy={16.6483}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
      <circle
        cx={31.6484}
        cy={33.6483}
        r={1.64835}
        fill="#FFA51C"
      />
      <circle
        cx={47.6484}
        cy={19.6483}
        r={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={33.6708}
        cy={18.0431}
        rx={1.64835}
        ry={2.06044}
        fill="#FFA51C"
      />
      <ellipse
        cx={20.0707}
        cy={44.0047}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={39.4403}
        cy={17.2188}
        rx={1.64835}
        ry={2.06044}
        fill="#FFA51C"
      />
      <circle
        cx={38.6161}
        cy={26.6977}
        r={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={17.0604}
        cy={30.6483}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={42.6484}
        cy={6.06038}
        rx={1.64835}
        ry={2.06044}
        fill="#FFA51C"
      />
      <circle
        cx={53.6484}
        cy={12.6483}
        r={1.64835}
        fill="#FFA51C"
      />
    </svg>
  );
}

function DotsImgDown(props) {
  return (
    <svg
      width={80}
      height={77}
      viewBox="0 0 80 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.354.795a.5.5 0 00-.708 0L.464 3.977a.5.5 0 00.708.707L4 1.855l2.828 2.829a.5.5 0 10.708-.707L4.354.795zM4 72.852h-.5v.5H4v-.5zm75.354.353a.5.5 0 000-.707l-3.182-3.182a.5.5 0 10-.707.707l2.828 2.829-2.829 2.828a.5.5 0 10.708.707l3.182-3.182zM3.5 1.148v71.704h1V1.148h-1zM4 73.352h75v-1H4v1z"
        fill="#CCD2E0"
      />
      <path
        d="M8.121 69.555L69.934 6.918"
        stroke="#8894AC"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <ellipse
        cx={27.9013}
        cy={53.8956}
        rx={1.64835}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={24.6484}
        cy={61.6483}
        rx={1.64835}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={32.434}
        cy={62.1374}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={40.2636}
        cy={48.9505}
        rx={1.64835}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={41.6484}
        cy={56.0603}
        rx={1.64835}
        ry={2.06044}
        fill="#FFA51C"
      />
      <ellipse
        cx={49.3295}
        cy={37.4121}
        rx={1.64835}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={58.3954}
        cy={37.4121}
        rx={1.64835}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={54.2748}
        cy={45.6538}
        rx={1.64835}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={43.9725}
        cy={41.533}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={33.6703}
        cy={46.478}
        rx={1.64835}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={46.8573}
        cy={49.3626}
        rx={1.64835}
        ry={2.06044}
        fill="#FFA51C"
      />
      <ellipse
        cx={55.9233}
        cy={29.1703}
        rx={1.64835}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={71.9945}
        cy={25.8736}
        rx={2.06044}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={35.3188}
        cy={54.3077}
        rx={1.64835}
        ry={2.06044}
        fill="#FFA51C"
      />
      <ellipse
        cx={63.3407}
        cy={22.5769}
        rx={1.64835}
        ry={1.64835}
        fill="#FFA51C"
      />
      <ellipse
        cx={64.6484}
        cy={31.6483}
        rx={1.64835}
        ry={1.64835}
        fill="#FFA51C"
      />
    </svg>
  );
}

export default function Legend() {
  return (
    <ReportLegend
      description="O gráfico ao lado procura mostrar a relação entre expectativa e realidade para o cargo em análise. A reta representa o que é esperado e os pontos são as respostas dos colaboradores para cada questão do teste de nivelamento. A leitura gráfica
        pode ser feita da seguinte forma:"
      figures={[
        {
          img: <DotsImg />,
          caption: 'O equilíbrio entre a reta e pontos mostra que as habilidades dos colaboradores estão alinhadas com as do cargo.'
        },
        {
          img: <DotsImgUp />,
          caption: 'Quando os pontos estão acima da reta, a indicação é que as habilidades dos colaboradores superam às expectativas do cargo.'
        },
        {
          img: <DotsImgDown />,
          caption: 'Quando os pontos estão abaixo da reta, a indicação é que as habilidades dos colaboradores estão abaixo do esperado.'
        }
      ]}
    />
  );
}
