import { i18n } from 'app/components/i18n/i18n';
import MatterModal from 'app/screens/Exams/CreateExam/StepName/MatterModal';
import { useClient } from 'jsonapi-react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FiCheckCircle, FiPlus } from 'react-icons/fi';

function NameStep(props) {
  const { t } = useTranslation();
  const { createMockQuestionBookForm } = props;
  const client = useClient();
  const [taxonomies, setTaxonomies] = useState();
  const [showModal, setShowModal] = useState(false);
  const countType = 'open';

  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LOCALE);
  }, []);

  const getTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch {
      toast.error('Erro ao carregar taxonomias');
    }
  };

  const addMatter = matter => {
    createMockQuestionBookForm.setFieldValue('selectedTaxonomies', [...createMockQuestionBookForm?.values?.selectedTaxonomies, matter]);
  };

  const removeMatter = matter => {
    createMockQuestionBookForm.setFieldValue(
      'selectedTaxonomies',
      createMockQuestionBookForm?.values?.selectedTaxonomies.filter(item => item.id !== matter.id)
    );
  };

  useEffect(() => {
    getTaxonomies();
  }, []);

  useEffect(() => {
    createMockQuestionBookForm.setFieldValue('selectedTaxonomies', createMockQuestionBookForm?.values?.selectedTaxonomies);
  }, [createMockQuestionBookForm?.values?.selectedTaxonomies]);

  return (
    <div className="new-questions__body">
      <p className="new-questions__subtitle">Selecione as matérias.</p>

      <fieldset className="form__row">
        <legend className="form__label">*{t('exams.examSubjects')}</legend>

        <button
          type="button"
          className="btn btn--outline-dashed btn--small btn--full"
          onClick={() => setShowModal(true)}
        >
          <FiPlus className="btn__icon" /> {t('textsCommon.buttonAddMatters')}
        </button>

        <MatterModal
          countType={countType}
          addMatter={addMatter}
          removeMatter={removeMatter}
          selectedMatters={createMockQuestionBookForm?.values?.selectedTaxonomies}
          matters={taxonomies}
          show={showModal}
          onClose={() => setShowModal(false)}
        />
        {createMockQuestionBookForm?.touched?.selectedTaxonomies && createMockQuestionBookForm?.errors.selectedTaxonomies && <span style={{ color: 'red' }}>{createMockQuestionBookForm?.errors.selectedTaxonomies}</span>}
      </fieldset>

      <table className="round-dg round-dg--light">
        <tbody className="round-dg__body">
          {createMockQuestionBookForm?.values?.selectedTaxonomies?.map((matter, index) => (
            <tr
              className="round-dg__row"
              key={index}
            >
              <th className="round-dg__cell-header round-dg__cell-header--row">
                <div className="card__header u-mb-0">
                  <div className="card__inner-header">
                    <div className="card__icon-wrapper">
                      <FiCheckCircle className="card__icon" />
                    </div>
                    <h3 className="card__title">{matter.name}</h3>
                  </div>
                </div>
              </th>
              <td className="round-dg__cell">
                {countType && `${(matter?.['question-count-type']?.[countType] || 0) + (matter?.['question-count-type']?.['train'] || 0) + (matter?.['question-count-type']?.['exam_and_mock'] || 0)} ${t('exams.questions')}`}
              </td>
              <td
                className="round-dg__cell"
                style={{ width: '140px' }}
              >
                <button
                  type="button"
                  className="btn btn--full btn--primary"
                  onClick={() => removeMatter(matter)}
                >
                  {t('button.remove')}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default NameStep;
