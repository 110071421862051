import React, { useState } from 'react';
import Modal from 'app/components/Modal';
import AnswersGuide from './AnswersGuide';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function ExamMetricsExamListItem(props) {
  const { t } = useTranslation();
  const { examStudent } = props;
  const [showModal, setShowModal] = useState(false);

  const userAttempts = examStudent.question_book['question-books-users'][0];
  const currentTime = Date.now();
  const finishDateMillis = new Date(examStudent.question_book['finished-at']).getTime();
  const isInAnalysis = currentTime < finishDateMillis;
  const isApproved = userAttempts?.status === 'approved';
  const isDisapproved = userAttempts?.status === 'disapproved' && examStudent?.question_book?.visibility !== 'open';
  const isExam = examStudent?.question_book?.visibility === 'exam';

  return (
    <>
      <tr className="round-dg__row">
        <td className="round-dg__cell">
          <b>{examStudent?.question_book?.title}</b>
          <div style={{ padding: '2px 5px', background: 'var(--color-bright)', borderRadius: '2px', display: 'inline', fontWeight: 400, fontSize: 12, marginLeft: '5px' }}>{isExam ? 'Prova' : 'Simulado'}</div>
        </td>
        <td className="round-dg__cell">
          {isInAnalysis ? (
            <div className="badge badge--tiny badge--warning">{t('exams.statusInAnalysis')}</div>
          ) : isApproved ? (
            <div className="badge badge--tiny badge--success">{isExam ? t('exams.statusInApproved') : 'Corrigido'}</div>
          ) : isDisapproved ? (
            <div className="badge badge--tiny badge--danger">{t('exams.statusInDisapproved')}</div>
          ) : null}
        </td>

        <td className="round-dg__cell">{moment(userAttempts['finished-at']).format(t('datesForm.formatDate'))}</td>

        {isInAnalysis ? <td className="round-dg__cell"></td> : <td className="round-dg__cell">{parseFloat(userAttempts.score).toFixed(2)}</td>}

        <td className="round-dg__cell last-cell">
          <button
            className="btn btn--outline btn--small btn--wide"
            onClick={() => setShowModal(true)}
            disabled={isInAnalysis}
          >
            {t('button.answerKey')}
          </button>
        </td>
      </tr>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        contentClassName="modal__content--md"
      >
        <AnswersGuide
          examId={examStudent.question_book.id}
          amountQuestions={examStudent.question_book['question-count']}
        />
      </Modal>
    </>
  );
}
