import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import StudentDataGrid from './StudentDataGrid';
import TeacherDataGrid from './TeacherDataGrid';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';

export default function RankingByParticipant() {
  const [participants, setParticipants] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const client = useClient();

  const filters = useFormik({
    initialValues: {
      searchTerm: '',
      participant: 'Aluno'
    }
  });

  const options = [
    { name: 'Estudantes', id: 'Aluno' },
    { name: 'Professores', id: 'Professor' }
  ];

  const fetchParticipants = async () => {
    const { participant } = filters.values;
    const { data, error } = await client.fetch(`/challenges/participants_ranking?page[number]=${pageNumber}&page[size]=20&filter[profiles.name]=${filters.values.participant}`);
    if (error) {
      toast.error('Erro ao buscar estudantes');
      setHasMore(false);
    } else {
      if (data.data.length < 20) {
        setHasMore(false);
      }
      if (participant === 'Aluno') {
        setParticipants(prev => [...prev, ...data.data]);
      } else {
        setTeachers(prev => [...prev, ...data.data]);
      }
    }
  };

  useEffect(() => {
    if (hasMore) {
      fetchParticipants();
    }
  }, [filters.values.participant, hasMore, pageNumber]);

  const filteredParticipants = useMemo(() => {
    const { searchTerm, participant } = filters.values;
    if (participant === 'Aluno') {
      return participants.filter(item => searchTerm === '' || item.name.toLowerCase().includes(searchTerm.toLowerCase()));
    } else {
      return teachers.filter(item => searchTerm === '' || item.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
  }, [participants, filters.values, teachers]);

  return (
    <>
      <div className="filter-bar filter-bar--borderless">
        <div className="filter-bar__inner">
          <div className="filter-bar__row">
            <label
              className="filter-bar__label"
              htmlFor="search"
            >
              {t('textsCommon.research')}
            </label>
            <input
              className="form__control form__control--search-with-icon"
              placeholder={filters.values.participant === 'Aluno' ? 'Nome do estudante' : 'Nome do professor'}
              type="search"
              name="search"
              id="search"
              value={filters.values.searchTerm}
              onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
              style={{ width: '400px' }}
            />
          </div>

          <FilterSelectionBox
            label={'Participante'}
            blankLabel={null}
            value={filters.values.participant}
            onChange={e => {
              setHasMore(true);
              filters.setFieldValue('participant', e.target.value);
            }}
            options={options}
          />
        </div>
      </div>

      {filters.values.participant === 'Aluno' && (
        <StudentDataGrid
          filteredParticipants={filteredParticipants}
          setPageNumber={setPageNumber}
          hasMore={hasMore}
        />
      )}
      {filters.values.participant === 'Professor' && (
        <TeacherDataGrid
          setPageNumber={setPageNumber}
          hasMore={hasMore}
          filteredParticipants={filteredParticipants}
        />
      )}
    </>
  );
}
