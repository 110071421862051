import { Link } from '@reach/router';
import content from '../../components/EmptyState/img/content.svg';
import { useState } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';

const EmptyExam = props => {
  const client = useClient();
  const { unfinishedExam } = props;
  const [unfinishedQuestionBook, setUnfinishedQuestionBook] = useState({});

  const getQuestionBook = async () => {
    const { data } = await client.fetch(`question_books/${unfinishedExam.id}`);
    setUnfinishedQuestionBook(data);
  };

  useEffect(() => {
    getQuestionBook();
  }, [unfinishedExam]);

  return (
    <div className="empty-state">
      {unfinishedQuestionBook.visibility !== 'train' && (
        <>
          <img
            className="empty-state__icon"
            alt=""
            src={content}
          />

          <h3 className="empty-state__title">Chegou a hora de colocar em prática o que você aprendeu.</h3>

          <p className="empty-state__text">Em instantes você realizará uma prova sobre os conteúdos estudados até aqui. Boa sorte!</p>
          <Link
            className="btn btn--primary"
            to={`/avaliacoes/sobre/${unfinishedExam?.id}`}
          >
            Acessar a prova
          </Link>
        </>
      )}
      {unfinishedQuestionBook.visibility === 'train' && (
        <>
          <img
            className="empty-state__icon"
            alt=""
            src={content}
          />

          <h3 className="empty-state__title">Chegou a hora de colocar em prática o que você aprendeu.</h3>

          <p className="empty-state__text">Em instantes você realizará uma apostila sobre os conteúdos estudados até aqui. Boa sorte!</p>
          <Link
            className="btn btn--primary"
            to={`/questions/apostilas/${unfinishedExam?.id}`}
          >
            Acessar a apostila
          </Link>
        </>
      )}
    </div>
  );
};

export default EmptyExam;
