import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import EmptyState from 'app/components/EmptyState';
import ChartPerformanceCard from './chartPerformanceCard';
import { FiMinimize2 } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

function IncomeByMatter(props) {
  const { t } = useTranslation();
  const { userId, classroomId, courseId, title, onClose, challenges } = props;
  const client = useClient();
  const [timeFilterSelected, setTimeFilterSeleted] = useState(1);
  const [taxonomies, setTaxonomies] = useState([]);
  const [topics, setTopics] = useState([]);
  const [subTopics, setSubTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const randomId = Math.floor(Math.random() * 100);

  useEffect(() => {
    fetchData('taxonomies');
  }, [timeFilterSelected]);

  const fetchData = async (list, taxonomyParentId) => {
    setLoading(true);
    try {
      let path;
      if (classroomId && classroomId !== 'blank') {
        path = `classrooms/${classroomId}/performance_by_taxonomy`;
      } else if (courseId && courseId !== 'blank') {
        path = `courses/${courseId}/performance_by_taxonomy`;
      } else if (userId) {
        path = `users/${userId}/performance_by_taxonomy`;
      } else {
        path = `performance_by_taxonomy`;
      }

      const taxonomyFilter = taxonomyParentId ? `&taxonomy_parent_id=${taxonomyParentId}` : '';
      const { data } = await client.fetch(`${path}?week=${timeFilterSelected}${taxonomyFilter}`);
      if (list === 'topics') {
        setTopics(data.data);
        setSubTopics([]);
      } else if (list === 'subTopics') {
        setSubTopics(data.data);
      } else {
        setTaxonomies(data.data);
        setTopics([]);
        setSubTopics([]);
      }

      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const selectTaxonomy = (taxonomy, currentList, nextList) => {
    setTaxonomies([...taxonomies]);
    if (nextList === 'subTopics') {
      topics.map(t => (t.isActive = false));
    } else if (nextList === 'topics') {
      taxonomies.map(t => (t.isActive = false));
    } else {
      subTopics.map(t => (t.isActive = false));
    }

    taxonomy.isActive = true;
    fetchData(nextList, taxonomy.id);
  };

  return (
    <>
      <header className="widget__header">
        <h1 className="widget__title">{title || 'Rendimento por assunto'}</h1>
        <p className="widget__description">Selecione a matéria para mais detalhes do rendimento</p>
        {onClose && (
          <button
            className="modal__close-btn"
            onClick={onClose}
          >
            <FiMinimize2 />
          </button>
        )}
      </header>

      <div className="widget__body">
        <div className="matter-performance__container">
          <div className="matter-performance__col">
            <>
              {taxonomies && !challenges
                ? taxonomies.map((taxonomy, index) => (
                    <ChartPerformanceCard
                      key={index}
                      taxonomy={taxonomy}
                      onClick={() => selectTaxonomy(taxonomy, 'taxonomies', 'topics')}
                    />
                  ))
                : null}

              {challenges &&
                challenges.map((taxonomy, index) => (
                  <ChartPerformanceCard
                    challenges={true}
                    key={index}
                    taxonomy={taxonomy}
                    // onClick={() => selectTaxonomy(taxonomy, 'taxonomies', 'topics')}
                  />
                ))}
            </>
          </div>

          {!challenges && (
            <>
              {' '}
              <div className="matter-performance__col">
                {topics &&
                  topics.map((taxonomy, index) => (
                    <ChartPerformanceCard
                      key={index}
                      taxonomy={taxonomy}
                      onClick={() => selectTaxonomy(taxonomy, 'topics', 'subTopics')}
                    />
                  ))}

                {!loading && topics.length === 0 && (
                  <EmptyState
                    type="select"
                    text={t('emptyState.selectTopicText')}
                  />
                )}
              </div>
              <div className="matter-performance__col">
                {subTopics &&
                  subTopics.map((taxonomy, index) => (
                    <ChartPerformanceCard
                      key={index}
                      taxonomy={taxonomy}
                    />
                  ))}

                {!loading && subTopics.length === 0 && (
                  <EmptyState
                    type="select"
                    text={t('emptyState.selectSubtopicText')}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default IncomeByMatter;
