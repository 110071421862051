import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import ongoingCourses from '../../images/onboarding/dashboard/ongoingCourses.png';
import buttonPng from '../../images/onboarding/dashboard/button.png';
import button2Png from '../../images/onboarding/dashboard/button2.png';
import calendarPng from '../../images/onboarding/dashboard/calendar.png';

export function DashboardOnboarding() {
  const { t } = useTranslation();

  return (
    <section>
      
      <TopBar />

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.timeline')}</h1>
        <p className="text-option-onboarding">{t('onboarding.timelineDesc')}</p>

        <div style={{ width: 480, display: 'flex' }}>
          <img src={buttonPng} />
          <img src={button2Png} />
        </div>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.quickAccessCourse')}</h1>
        <p className="text-option-onboarding">{t('onboarding.quickAccessCourseDescription')}</p>

        <img src={ongoingCourses} />
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.monthlyCalendar')}</h1>
        <p className="text-option-onboarding">{t('onboarding.monthlyCalendarDesc')}</p>

        <div style={{ width: 300}}>
          <img src={calendarPng} />
       </div>
      </div>

    </section>
  );
}
