import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import { AiOutlineTrophy } from 'react-icons/ai';
import { FiUser, FiMessageCircle, FiChevronDown, FiVideo } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { useSession } from 'app/hooks/useSession';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import Dropdown from 'app/components/Dropdown';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import getPermission from 'app/utils/getPermission';
import { Formik, useFormik } from 'formik';
import defaultImage from '../../images/placeholders/show-course.jpg';
import { AvatarStack } from 'app/components/Avatar';
import { useTranslation } from 'react-i18next';

export default function ModuleCard(props) {
  const { t } = useTranslation();
  const { ls, id, learningSystems } = props;
  const client = useClient();
  const { session } = useSession();
  const authorityLevel = getAuthorityLevel(session);
  const [progress, setProgress] = useState([]);

  const deleteModule = async () => {
    try {
      await client.delete(`learning_systems/${id}`);
      toast.success(t('toast.successDeleteModule'));
    } catch (e) {
      console.log(e);
      toast.error(t('toast.errorDeleteModule'));
    }
  };

  const handleDelete = moduleId => {
    Swal.fire({
      title: t('projects.warningDeleteModule'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteModule(moduleId);
      }
    });
  };

  useEffect(() => {
    const LMSUser = ls['learning-system-users'].find(u => u.user_id === session.user.id);
    if (LMSUser) {
      setProgress(ls['learning-system-users'].length == 0 ? 0 : Math.round(LMSUser.percent));
    }
  }, []);

  const requirementsFinished = ls['learning-system-requirements']?.every(requirement => {
    const moduleRequired = learningSystems?.find(learningSystem => learningSystem.id === requirement['requirement-id']);

    if (!moduleRequired) {
      return true;
    }
    return moduleRequired['learning-system-users'][0] && moduleRequired['learning-system-users'][0].finished === true;
  });

  const moduleForm = useFormik({
    initialValues: {
      id: ls.id,
      publish: ls.publish
    }
  });

  const updateModulePublicity = async values => {
    try {
      await client.mutate(['learning_systems', ls.id], {
        publish: !values.publish
      });
      if (!values.publish === true) {
        toast.success(t('toast.successPublish'));
      } else {
        toast.success(t('toast.successUnpublish'));
      }
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorPublishTask'));
    }
  };

  const users = ls['learning-system-users'];
  const userData = users.map(user => user.user);

  const hasStartedModule = userData.some(item => item.id === session.user.id);

  const cardImage = ls?.image?.url;
  const cardImageMemo = React.useMemo(() => cardImage, [cardImage]);

  return (
    <div className="card">
      {authorityLevel === 'admin' && (
        <div className="form__check form__switch">
          <input
            className="form__check-input"
            id={`publish-${id}`}
            name={`publish-${id}`}
            type="checkbox"
            checked={moduleForm.values.publish}
            onClick={() => {
              moduleForm.setFieldValue('publish', !moduleForm.values.publish);
              updateModulePublicity(moduleForm.values);
            }}
          />
          <label htmlFor={`publish-${id}`}>Publicar módulo</label>
        </div>
      )}

      <div className="card__header">
        {cardImageMemo ? (
          <img
            className="card__header-img"
            src={cardImageMemo}
            alt="imagem do curso"
          />
        ) : (
          <img
            className="card__header-img"
            src={defaultImage}
            alt="imagem do curso"
          />
        )}

        <div className="card__inner-header">
          <h2
            className="card__title"
            title={ls.title}
          >
            {ls.title}
          </h2>
        </div>

        {/* <h3 className="card__subtitle">Por {ls?.user?.name}</h3> */}

        {progress === 100 && (
          <div className="card__badges">
            <span className="badge badge--tiny badge--success">{t('textsCommon.tagConcluded')}</span>
          </div>
        )}

        {authorityLevel === 'student' && ls['learning-system-users'].length == 0 && (
          <div className="card__badges">
            <span className="badge badge--tiny badge--warning">{t('lms.tagNew')}</span>
          </div>
        )}

        {authorityLevel === ('admin' && 'student') && <div className="card__badges">{ls['has-new-message'] && <span className="badge badge--tiny badge--warning">{t('lms.tagNewMessage')}</span>}</div>}
      </div>

      <div className="card__body">
        <p
          className="card__description"
          title={ls['short-description']}
        >
          {ls['short-description']}
        </p>

        <h3 className="card__badge-title">{t('lms.requirements')}</h3>

        <div className="card__badges card__badges--one-line">
          {ls['learning-system-requirements'].length > 0 &&
            ls['learning-system-requirements'].map((requirement, index) => {
              if (index == 0) {
                return (
                  <span
                    className="badge badge--primary"
                    title={requirement.title}
                    key={index}
                  >
                    {requirement.title}
                  </span>
                );
              }
              if (index == 1) {
                return (
                  <span
                    className="badge badge--primary"
                    title={ls['learning-system-requirements']
                      .map((r, index) => {
                        if (index > 0) return r.title;
                      })
                      .join(', ')
                      .replace(', ', '')}
                    key={index}
                  >
                    {`+${ls['learning-system-requirements'].length - 1}`}
                  </span>
                );
              }
            })}
          {ls['learning-system-requirements'].length === 0 && (
            <span
              className="badge badge--info"
              title="Sem requerimentos"
            >
              {t('lms.tagRequirementsNot')}
            </span>
          )}
        </div>

        {progress && progress === 100 ? (
          <p
            className="card__count"
            title={t('textsCommon.tagConcluded')}
            aria-label={t('textsCommon.tagConcluded')}
          >
            <AiOutlineTrophy
              size={27}
              className="card__icon"
            />
          </p>
        ) : (
          <>
            <div className="card__columns"></div>
            {/* KPIs */}
            <div className="card__columns">
              {authorityLevel === 'student' ? (
                (userData || []).length > 0 && (
                  <p
                    className="card__count"
                    title={t('sideBar.lms')}
                    aria-label="Aulas"
                  >
                    <FiVideo className="card__icon" /> {ls['learning-system-items-count']}
                  </p>
                )
              ) : (
                <AvatarStack
                  data={userData}
                  modalTitle={`Participantes do Módulo ${ls.title}`}
                  className="avatar--xs"
                />
              )}
              <p
                className="card__count"
                title={t('textsCommon.messagesTitle')}
                aria-label="Mensagens"
              >
                <FiMessageCircle className="card__icon" /> {ls['message-count']}
              </p>
            </div>

            {/* barra de progresso */}

            {hasStartedModule
              ? authorityLevel === 'student' &&
                ls?.['learning-system-users']?.length !== 0 &&
                progress >= 0 && (
                  <div className="card__progress">
                    <div className="card__progress-container">
                      <div
                        className="card__progress-bar"
                        role="progressbar"
                        style={{ width: `${progress}%` }}
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      />
                    </div>
                    <div className="card__progress-text">{progress}%</div>
                  </div>
                )
              : ''}
          </>
        )}
      </div>
      <div className="card__footer">
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          {authorityLevel !== 'student' ? (
            moduleForm.values.publish === false ? (
              <Link
                to={`/lms/gerenciar/modulo/${ls.id}`}
                className="btn btn--outline btn--small"
              >
                Continuar editando
              </Link>
            ) : (
              <Link
                to={`/show-course/${ls.id}/classroom`}
                className="btn btn--outline btn--small"
              >
                {t('button.view')}
              </Link>
            )
          ) : hasStartedModule ? (
            <Link
              to={`/show-course/${ls.id}/classroom`}
              className="btn btn--outline btn--small"
            >
              {t('button.continue')}
            </Link>
          ) : (
            <Link
              to={`/show-course/${ls.id}/`}
              className={requirementsFinished ? 'btn btn--outline btn--small' : 'btn btn--outline btn--small disabled'}
            >
              {t('button.view')}
            </Link>
          )}

          {getPermission('Editar módulos, disciplinas e aulas', 'Cursos') || getPermission('Excluir módulos, disciplinas e aulas', 'Cursos') ? (
            <Dropdown className="btn btn--outline btn--small">
              <Dropdown.Toggle>
                <FiChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Content>
                {getPermission('Editar módulos, disciplinas e aulas', 'Cursos') && <Dropdown.Item to={`/lms/gerenciar/modulo/${id}`}>Gerenciar</Dropdown.Item>}
                {getPermission('Editar módulos, disciplinas e aulas', 'Cursos') && <Dropdown.Item to={`/lms/criar/modulo/curso/${id}`}>{t('button.edit')}</Dropdown.Item>}
                {getPermission('Excluir módulos, disciplinas e aulas', 'Cursos') && <Dropdown.Item onClick={() => handleDelete(id)}>{t('button.delete')}</Dropdown.Item>}
              </Dropdown.Content>
            </Dropdown>
          ) : null}
        </div>
      </div>
    </div>
  );
}
