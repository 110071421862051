import React from 'react';
import { Link } from '@reach/router';
import { t } from 'i18next';

export default function TabNavBar(props) {
  const { selectedTab } = props;

  return (
    <div className="tab">
      <Link
        className={selectedTab === 'curso' ? 'tab__link active' : 'tab__link'}
        to="/gestao-de-tarefa/curso"
      >
        {t('tasks.toggleCourses')}
      </Link>
      <Link
        className={selectedTab === 'turma' ? 'tab__link active' : 'tab__link'}
        to="/gestao-de-tarefa/turma"
      >
        {t('tasks.toggleClass')}
      </Link>
      <Link
        className={
          selectedTab === 'estudante' ? 'tab__link active' : 'tab__link'
        }
        to="/gestao-de-tarefa/estudante"
      >
        {t('tasks.toggleStudents')}
      </Link>
    </div>
  );
}
