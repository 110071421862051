import React from 'react';
import InitiatedCourses from './InitiatedCourses';
import ModuleCard from './ModuleCard';
import RecommendedCourseCard from './RecommendedCourseCard';
import ModuleCardAdmin from './ModuleCardAdmin/index';
import ToDo from './ToDo';

export default function Example() {
  return (
    <main className="main-content main-content--block">
      <div className="widget u-mb-4">
        <div className="widget__header">
          <h3 className="widget__title">Module card</h3>
        </div>
        <ModuleCard />
      </div>

      <div className="widget u-mb-4">
        <div className="widget__header">
          <h3 className="widget__title">Initiated courses</h3>
        </div>
        <InitiatedCourses />
      </div>

      <div className="widget u-mb-4">
        <div className="widget__header">
          <h3 className="widget__title">Recommended course card</h3>
        </div>
        <div className="recommended-course-card-wrapper">
          <RecommendedCourseCard />
          <RecommendedCourseCard />
          <RecommendedCourseCard />
          <RecommendedCourseCard />
        </div>
      </div>

      <div className="widget u-mb-4">
        <div className="widget__header">
          <h3 className="widget__title">Module card admin</h3>
        </div>
        <ModuleCardAdmin id={1} />
      </div>

      <div className="widget u-mb-4">
        <div className="widget__header">
          <h3 className="widget__title">To do card (second version)</h3>
        </div>

        <div className="card__todo-wrapper">
          <ToDo />
        </div>
      </div>
    </main>
  );
}
