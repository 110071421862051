import React, { useState } from 'react';
import ActivityCard from './ActivityCard';
import EmptyState from 'app/components/EmptyState';
import { t } from 'i18next';

export default function TabActivities({ currentModule }) {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredActivities = currentModule?.['learning-system-activities']
    ?.map(item => item.activity)
    .filter(activity => {
      if (searchTerm == '') {
        return activity;
      }
      if (activity.title !== null && activity.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
        return activity;
      }
    });

  const isSomeRequirementNotFinished = currentModule?.['learning-system-requirements']?.some((item) => !item.finished)

  return (
    <div className="fadeIn">
      <div className="filter-bar">
        <div className="filter-bar__row">
          <label
            htmlFor="search"
            className="filter-bar__label"
          >
            {t('textsCommon.search')}
          </label>
          <input
            className="form__control form__control--search-with-icon"
            placeholder="Pesquisar uma atividade"
            type="search"
            name="search"
            id="search"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      {filteredActivities && filteredActivities.length > 0 ? (
        <div className="round-dg-wrapper">
          <table
            className="round-dg round-dg--light"
            style={{ minWidth: '620px' }}
          >
            <thead className="round-dg__header">
              <tr className="round-dg__row">
                <th className="round-dg__cell-header">Atividade</th>
                <th className="round-dg__cell-header">Status</th>
                <th className="round-dg__cell-header"></th>
              </tr>
            </thead>
            <tbody className="round-dg__body">
              {filteredActivities.map(activity => {
                return <ActivityCard activity={activity} isNotFinished={isSomeRequirementNotFinished} />;
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyState type="data" />
      )}
    </div>
  );
}
