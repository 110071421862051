function TaxonomyItem(props) {
  const {taxonomy, setTaxonomy} = props;
  const lastMonthResult = parseFloat(taxonomy.last_month_result.toFixed(1))
  let performanceClass = lastMonthResult < 0 ? 'low' : 'high'
  if(lastMonthResult === 0.0) { performanceClass = '' }

  //<div className="subject-list__performance low">4%</div>
  //<div className="subject-list__performance high">4%</div>
  return(
    <div className="subject-list__item" onClick={() => setTaxonomy(taxonomy)}>
      <div className="subject-list__item-name">{taxonomy.name}</div>
      <div className="subject-list__item-percent">{taxonomy.average_result.toFixed(1)}%</div>
      <div className={`subject-list__performance ${performanceClass}`}>{lastMonthResult === 0.0 ? '--' : `${lastMonthResult}%`}</div>
    </div>
  )
}

export default TaxonomyItem
