import React from 'react';
import { FiTrash2 } from 'react-icons/fi';
import Avatar from 'app/components/Avatar';
import { useSession } from 'app/hooks/useSession';
import Swal from 'sweetalert2';
import { t } from 'i18next';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';

export default function CommentCard({ comment, onDelete }) {
  const { session } = useSession();

  const userId = session?.user?.id;
  const isAdmin = !checkIncludeProfiles({ only: ['student'] });

  const handleDelete = () => {
    Swal.fire({
      title: t('projects.warningDeleteCommentTitle'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        onDelete(comment.id);
      }
    });
  };

  return (
    comment?.user && (
      <div className="chat-widget__comment-wrapper">
        <div className="chat-widget__comment">
          <Avatar
            className="avatar--xs"
            src={comment?.user?.image?.url}
          />

          <div className="chat-widget__comment-inside">
            <div className="chat-widget__comment-header">
              <h3 className="chat-widget__comment-title">{comment?.user?.name}</h3>
              <span className="badge badge--tiny">{comment?.user?.['profile-names'][0]}</span>
            </div>
            <div className="chat-widget__comment-body">
              <p className="chat-widget__comment-text">{comment?.body}</p>
            </div>
          </div>

          {(isAdmin || comment?.user?.id === userId) && (
            <div className="chat-widget__comment-actions">
              <FiTrash2
                className="chat-widget__icon chat-widget__icon--danger"
                onClick={() => handleDelete()}
              />
            </div>
          )}
        </div>
      </div>
    )
  );
}
