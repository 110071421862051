import React, { useEffect, useState } from 'react';
import RegionalDataGrid from './RegionalDataGrid';
import InstitutionDataGrid from './InstitutionDataGrid';
import NavInRankingByRegion from './NavInRankingByRegion';
import StudentDataGrid from './StudentDataGrid';
import { useClient, useQuery } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';

export default function RankingByRegion() {
  const [regionals, setRegionals] = useState([]);
  const [selectedRegional, setSelectedRegional] = useState(null);
  const [institutions, setInstitutions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  const [show, setShow] = useState({
    regional: true,
    institution: false,
    student: false
  });

  const client = useClient();

  const fetchRegionals = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/challenges/ranking`);
    if (error) {
      toast.error('Erro ao buscar regionais');
    } else {
      setRegionals(data.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRegionals();
  }, []);

  const fetchInstitutions = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/challenges/ranking?filter[regional_id]=${selectedRegional.id}`);
    if (error) {
      toast.error('Erro ao buscar regionais');
    } else {
      setInstitutions(data.data);
    }
    setLoading(false);
  };

  const fetchStudents = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/challenges/filtred_ranking?filter[institutions.id]=${selectedInstitution.id}&filter[regionals.id]=${selectedRegional.id}&page[number]=${pageNumber}&page[size]=20`);
    if (error) {
      toast.error('Erro ao buscar regionais');
      setHasMore(false);
    } else {
      if (data.data.length < 20) {
        setHasMore(false);
      }
      setStudents(prev => [...prev, ...data.data]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (show.institution && selectedRegional) {
      fetchInstitutions();
    }

    if (show.student && hasMore) {
      fetchStudents();
    }
  }, [selectedRegional, selectedInstitution, show, hasMore, pageNumber]);

  console.log({ hasMore, pageNumber });

  console.log({students})
  return (
    <div className="widget-wrapper">
      <div className="widget">
        <div className="widget__header">
          <h3 className="widget__title u-mb-4">Ranking</h3>

          <NavInRankingByRegion
            selectedRegional={selectedRegional}
            selectedInstitution={selectedInstitution}
            show={show}
            setShow={setShow}
          />
        </div>

        <div className="widget__body">
          {loading && <Loader />}
          {show.regional && !loading && (
            <RegionalDataGrid
              setSelectedRegional={setSelectedRegional}
              regionals={regionals}
              show={show}
              setShow={setShow}
            />
          )}

          {show.institution && !loading && (
            <InstitutionDataGrid
              institutions={institutions}
              setSelectedInstitution={setSelectedInstitution}
              show={show}
              setShow={setShow}
            />
          )}

          {show.student && (
            <StudentDataGrid
              students={students}
              hasMore={hasMore}
              setPageNumber={setPageNumber}
            />
          )}
        </div>
      </div>
    </div>
  );
}
