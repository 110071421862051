import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function QuizDataPieChart(props) {
  const { surveyQuestion } = props;

  const series = (surveyQuestion?.items || []).map((e) => {
    return {name: e.subtitle, y: e.answersPercent}
  });

  const pieColors = (function () {
    let colors = [],
      base = '#442b8c',
      i;

    for (i = 0; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      colors.push(
        Highcharts.color(base)
          .brighten(i / 7)
          .get(),
      );
    }
    return colors;
  })();

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    credits: { enabled: false },
    title: { text: null },
    legend: {
      symbolWidth: 12,
      symbolHeight: 12,
      symbolRadius: 3,
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        colors: pieColors,
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: 'Respostas',
        innerSize: '40%',
        colorByPoint: true,
        data: series
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
