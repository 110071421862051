import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Error404 from 'app/components/Error404';
import getPermission from 'app/utils/getPermission';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import AdminCourseCard from './AdminCourseCard';
import { Link } from '@reach/router';
import CourseAboutAdmin from './CourseAboutAdmin';
import ManageCourse from './ManageCourse';

import { useCourse } from 'app/hooks/useCourse';
import { useClassroom } from 'app/hooks/useClassroom';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { t } from 'i18next';
import ClassroomModal from './CourseAboutAdmin/ClassroomModal';
import ModuleModal from './CourseAboutAdmin/ModuleModal';
import BreadCrumbs from 'app/components/BreadCrumbs';

function Course(props) {
  const { uri } = props;

  const filters = useFormik({
    initialValues: {
      searchTerm: ''
    }
  })

  const { detailedCourses, loading, getDetailedCourses } = useCourse();
  const { detailedClassrooms: classrooms, getDetailedClassrooms, classroomLoading, getCourseModules, courseModules, modulesLoading } = useClassroom();
  const [filteredClassrooms, setFilteredClassrooms] = useState([]);
  const [showModuleModal, setShowModuleModal] = useState(false);
  const [showClassroomModal, setShowClassroomModal] = useState(false);
  const [activeCourse, setActiveCourse] = useState();
  const [filteredCourses, setFilteredCourses] = useState();

  const viewCourse = getPermission('Visualizar lista de cursos', 'Cursos');

  const handleOpenClassroomModal = course => {
    getDetailedClassrooms(course.id);
    setActiveCourse(course);
    setShowClassroomModal(true);
  };

  const handleOpenModulesModal = async course => {
    await getCourseModules(course.id);
    if (!modulesLoading) {
      setActiveCourse(course);
      setShowModuleModal(true);
    }
  };

  useEffect(() => {
    getDetailedCourses();
  }, []);

  useEffect(() => {
    if (classrooms) {
      setFilteredClassrooms(classrooms);
    }
  }, [classrooms]);

  useEffect(() => {
    if (detailedCourses.length > 0) {
      const filteredCourses = detailedCourses.filter(item => {
        const searchTermFilter = filters.values.searchTerm === '' ? true : item.title !== null && item.title.toLowerCase().includes(filters.values.searchTerm.toLowerCase());
        return searchTermFilter;
      });
      setFilteredCourses(filteredCourses);
    }
  }, [filters.values, detailedCourses]);

  const breadCrumbs = {
    title: 'Cursos e Aulas',
    nav: [
      {
        route: uri,
        name: 'Cursos',
        isActive: true
      }
    ]
  };

  if (!viewCourse) return <Error404 />;

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <div className="filter-bar">
        <div className="filter-bar__inner">
          <div className="filter-bar__row">
            <label
              htmlFor="search"
              className="filter-bar__label"
            >
              {t('textsCommon.research')}
            </label>
            <input
              className="form__control form__control--search-with-icon"
              placeholder={t('filter.courseName')}
              type="search"
              name="search"
              id="search"
              onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
            />
          </div>
        </div>
        {getPermission('Criar cursos', 'Cursos') && (
          <div className="filter-bar__actions">
            <Link
              className="btn btn--primary btn--wide"
              to={`/curso/criar/sobre`}
            >
              {t('courses.buttonNewCourse')}
            </Link>
          </div>
        )}
      </div>

      {!filteredCourses && loading && <Loader />}
      {!loading && filteredCourses && filteredCourses?.length === 0 && <EmptyState type="data" />}
      <div className="admin-course-card-wrapper">
        {filteredCourses?.map(item => {
          return (
            <AdminCourseCard
              classroomLoading={classroomLoading}
              setFilteredCourses={setFilteredCourses}
              classrooms={classrooms}
              handleOpenClassroomModal={handleOpenClassroomModal}
              handleOpenModulesModal={handleOpenModulesModal}
              key={item.id}
              course={item}
            />
          );
        })}
        <ClassroomModal
          classroomLoading={classroomLoading}
          classrooms={filteredClassrooms}
          course={activeCourse}
          show={showClassroomModal}
          onClose={() => setShowClassroomModal(false)}
        />

        <ModuleModal
          courseModules={courseModules}
          course={activeCourse}
          show={showModuleModal}
          onClose={() => setShowModuleModal(false)}
        />
      </div>
    </main>
  );
}

export { Course as default, CourseAboutAdmin, ManageCourse };
