import React from 'react';
import { FiClock, FiMoreHorizontal } from 'react-icons/fi';
import { Link } from '@reach/router';
import Dropdown from 'app/components/Dropdown';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import moment from 'moment';
import { useClient } from 'jsonapi-react';
import Swal from 'sweetalert2';
import { t } from 'i18next';
import getPermission from 'app/utils/getPermission';
import toast from 'react-hot-toast';

export default function LiveClassCard(props) {
  const { liveClass, setEditingLiveClass, setShowLiveClassForm, setCurrentLive, setWeekDays } = props;
  const client = useClient();
  const authorityLevel = getAuthorityLevel();
  const isAdmin = authorityLevel !== 'student';

  const isSame = moment(liveClass['initial-date']).format('YYYY-MM-DD') === moment(liveClass['end-date']).format('YYYY-MM-DD');
  const formattedInitialDate = moment(liveClass['initial-date']).format(isSame ? 'HH[h]mm' : 'DD/MM - HH[h]mm');
  const formattedEndDate = moment(liveClass['end-date']).format(isSame ? 'HH[h]mm' : 'DD/MM - HH[h]mm');
  const date = `${formattedInitialDate} às ${formattedEndDate}`;

  const currentDate = moment();

  const isOnSchedule = currentDate.isBetween(liveClass['initial-date'], liveClass['end-date']);

  const deleteLive = async () => {
    const { error } = await client.delete(`live_classrooms/${liveClass?.id}`);
    if (error) {
      toast.error(t('toast.errorDeleteLesson'));
    } else {
      setWeekDays(prevState => prevState.filter(item => item.id !== liveClass?.id));
      toast.success(t('toast.successDeleteLesson'));
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: t('projects.warningDeleteLesson'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteLive();
      }
    });
  };

  return (
    <div className="live-class-card">
      <div className="live-class-card__header">
        <div className="live-class-card__inner-header">
          {isOnSchedule ? <span className="badge badge--small badge--success">Online</span> : <span className="badge badge--small badge--warning">Offline</span>}

          {getPermission('Editar aulas ao vivo', 'Aulas ao vivo') || getPermission('Excluir aulas ao vivo', 'Aulas ao vivo') ? (
            <Dropdown>
              <Dropdown.Toggle>
                <span className="card__icon-wrapper u-py-0">
                  <FiMoreHorizontal className="card__icon" />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Content>
                {getPermission('Editar aulas ao vivo', 'Aulas ao vivo') && (
                  <Dropdown.Item
                    onClick={() => {
                      setEditingLiveClass(true);
                      setShowLiveClassForm(true);
                      setCurrentLive(liveClass);
                    }}
                  >
                    {t('button.edit')}
                  </Dropdown.Item>
                )}
                {getPermission('Excluir aulas ao vivo', 'Aulas ao vivo') && (
                  <Dropdown.Item
                    onClick={() => {
                      handleDelete();
                    }}
                  >
                    {t('button.delete')}
                  </Dropdown.Item>
                )}
              </Dropdown.Content>
            </Dropdown>
          ) : null}
        </div>

        <h3
          className="live-class-card__title"
          title={liveClass.title}
        >
          {liveClass.title}
        </h3>
        <div>
          <p
            className="live-class-card__subtitle"
            title={liveClass.about}
          >
            {liveClass.about}
          </p>
        </div>
      </div>

      <div className="live-class-card__body">
        <p
          className="live-class-card__time"
          title={date}
        >
          <FiClock /> {date}
        </p>
      </div>

      <div className="live-class-card__footer">
        {isOnSchedule ? (
          <Link
            className="btn btn--tiny btn--full btn--primary"
            to={String(liveClass.id)}
          >
            {t('button.access')}
          </Link>
        ) : (
          <div className="btn btn--tiny btn--full btn--primary disabled">{t('button.access')}</div>
        )}
      </div>
    </div>
  );
}
