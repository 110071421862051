import React from 'react';
import Progress from 'app/components/Progress';

export default function QuizDataGrid(props) {
  const { data, height = 'auto' } = props;

  return (
    <div
      className="round-dg-wrapper"
      style={{ height: height }}
    >
      <table className="round-dg round-dg--light">
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th className="round-dg__cell-header">Matéria</th>
            <th
              className="round-dg__cell-header last-cell"
              style={{ width: 110 }}
            >
              % de acerto
            </th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {data?.map((item, key) => (
            <tr
              className="round-dg__row"
              key={key}
            >
              <td className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
                <span
                  className="u-text-truncate"
                  title={item.taxonomy}
                >
                  {item.taxonomy}
                </span>
              </td>
              <td className="round-dg__cell round-dg__cell--sm last-cell">
                <Progress.Card
                  percentage={item.percentage}
                  toFixed={0}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
