import React, { useState } from 'react';
import PostActions from './PostActions';
import moment from 'moment';
import 'moment/locale/pt';
import api from '../services/api.js';
import { useMutation } from 'jsonapi-react';
import toast from 'react-hot-toast';
import ReactHtmlParser from 'react-html-parser';
import MagicLink from './magicLink';
import PostCommentCard from '../components/postComment';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { navigate } from '@reach/router';
import Avatar from './Avatar';

function PostCardShow(props) {
  const { t } = useTranslation();
  const { post } = props;
  const [text, setText] = useState('');
  const [addComment] = useMutation('comments');
  const createComment = e => {
    e.preventDefault();
    addComment({ body: text, post_id: post.id })
      .then()
      .then(response => {
        if (response.error) {
          toast.error(response.error.title);
        } else {
          setText('');
          props.addComments(response);
        }
      })
      .catch(error => {
        toast.error(t('toast.error'));
        console.error(error);
      });
  };

  const deletePost = post => {
    api
      .delete(`posts/${post.id}`)
      .then(response => {
        window.location.pathname = '/feed';
        toast.success(t('toast.successDeleteMensage'));
      })
      .catch(error => {
        console.error(error);
      });
  };

  const deleteComment = comment => {
    Swal.fire({
      title: 'Deseja mesmo excluir este comentário?',
      text: 'Esta ação não poderá ser desfeita',
      confirmButtonText: 'Sim',
      showDenyButton: true,
      denyButtonText: 'Não',
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        api
          .delete(`comments/${comment.id}`)
          .then(response => {
            let newPost = post;
            newPost.comments = newPost.comments.filter(c => c.id !== comment.id);
            newPost['comments-count'] = newPost.comments.length;
            props.setPost({ ...newPost });
            toast.success(t('toast.successDeleteComment'));
          })
          .catch(error => {
            console.error(error);
          });
      }
    });
  };

  const comments = post?.comments.filter(comment => comment['comment-parent-id'] === null);

  const handleNavigateToPost = (event, route, options = {}) => {
    event.stopPropagation();
    navigate(route, options);
  };

  return (
    <div className="post">
      <div
        className="post__avatar"
        onClick={event => handleNavigateToPost(event, `/usuario/metricas/${post['user-id']}`, { state: { isRedirected: true } })}
      >
        <Avatar
          src={post?.user?.image?.url}
          alt={post?.user?.name}
        />
      </div>
      <div className="post__body">
        <div className="post__header">
          <div
            className="post__header-name"
            onClick={event => handleNavigateToPost(event, `/usuario/metricas/${post['user-id']}`, { state: { isRedirected: true } })}
          >
            {post?.user?.name}
          </div>
          <div className="post__header-time">{moment(post['created-at']).fromNow()}</div>
        </div>
        <div className="post__description-text">{ReactHtmlParser(post?.body)}</div>

        {post['magic-links'] &&
          post['magic-links'].map(link => (
            <a
              target="_blank"
              rel="noreferrer"
              href={link?.url}
              key={link?.id}
            >
              <MagicLink magicLink={link} />
            </a>
          ))}
      </div>
      <PostActions
        post={post}
        deletePost={deletePost}
      />
      <div className="post__comments">
        {comments &&
          comments.map(comment => (
            <PostCommentCard
              key={comment?.id}
              comment={comment}
              hidePinnedBtn
              comments={post.comments}
              deleteComment={comment => deleteComment(comment)}
            />
          ))}
      </div>
      {getPermission('Criar postagens e comentários', 'Fórum') && (
        <form
          className="comment-form form"
          method="post"
          onSubmit={e => createComment(e)}
        >
          <input
            className="form__control"
            placeholder={t('textsCommon.placeholderSendYourComment')}
            type="text"
            name="comment"
            id="comment"
            onChange={e => setText(e.target.value)}
            value={text}
          />
          <div className="textarea-instructions">{t('textsCommon.textPressEnter')}</div>
        </form>
      )}
    </div>
  );
}

export default PostCardShow;
