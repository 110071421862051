import React, { useState, createContext, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast, { ToastBar } from 'react-hot-toast';
import { t } from 'i18next';

export const UpdateQuestionBookContext = createContext();

export const UpdateQuestionBookProvider = ({ children }) => {
  const [taxonomiesLoading, setTaxonomiesLoading] = useState(true);
  const [firstLevelTaxonomies, setFirstLevelTaxonomies] = useState([]);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [questionsLoading, setQuestionsLoading] = useState(true);
  const [selectedQuestionBookId, setSelectedQuestionBookId] = useState(null);
  const [questionBook, setQuestionBook] = useState(null);
  const [selectedQuestionBookTaxonomies, setSelectedQuestionBookTaxonomies] =
    useState(null);
  // This state contains all of the questionbook questions.
  // TODO: Pagination.
  const [selectedQuestionBookQuestions, setSelectedQuestionBookQuestions] =
    useState([]);
  // This state contains the questions filtered by the selected taxonomy.
  const [selectedTaxonomyQuestions, setSelectedTaxonomyQuestions] = useState(
    [],
  );
  const [page, setPage] = useState(1);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [draftingQuestion, setDraftingQuestion] = useState(false);

  const client = useClient();

  const fetchTaxonomies = async () => {
    const { data, error } = await client.fetch('taxonomies');

    if (error) {
      toast.error(t('toast.errorGetFirstLvlTaxonomy'));
    } else {
      setFirstLevelTaxonomies(data);
    }
  };

  const getQuestionBookTaxonomies = async questionBookId => {
    setTaxonomiesLoading(true);
    const { data, error } = await client.fetch(
      `question_books/${questionBookId}`,
    );

    if (error) {
      toast.error('Erro ao buscar taxonomias do caderno de questões.');
    } else {
      setQuestionBook(data);
      setSelectedQuestionBookTaxonomies(data.taxonomies);
      setSelectedTaxonomy(data.taxonomies[0]);

      setTaxonomiesLoading(false);
    }
  };

  const getTaxonomies = async questionBookId => {
    await fetchTaxonomies();
    await getQuestionBookTaxonomies(questionBookId);
  };

  const getQuestionBookQuestions = async (
    questionBookId,
    lastSelectedQuestionId,
  ) => {
    if (selectedTaxonomy) {
      setQuestionsLoading(true);
      let url = `questions?filter[question_book_id]=${questionBookId}`;

      if (searchTerm) {
        url = `questions?filter[question_book_id]=${questionBookId}&q[q_cont]=${searchTerm}`;
      }
      const { data, error } = await client.fetch(url);

      if (error) {
        toast.error('Erro ao buscar questões deste caderno.');
      } else {
        setSelectedQuestionBookQuestions(data);

        // This is not a taxonomy. It is a model on the backend called
        // QuestionBookTaxonomies, a join table between question books and taxonomies
        // used to relationate the questions inside the questionbook with the
        // taxonomies themselves. Ask someone from the backend for more info.
        const selectedQuestionBookTaxonomy = questionBook[
          'question-book-taxonomies'
        ].find(qb => qb.taxonomy_id === selectedTaxonomy.id);

        const taxonomyQuestions = data.filter(q =>
          selectedQuestionBookTaxonomy.question_ids.includes(q.id),
        );

        if (lastSelectedQuestionId) {
          const lastSelectedQuestion = data.find(
            q => q.id === lastSelectedQuestionId,
          );

          if (lastSelectedQuestion) {
            taxonomyQuestions.push(lastSelectedQuestion);
          }
        }

        setSelectedTaxonomyQuestions(taxonomyQuestions);

        const questionToBeSelected = lastSelectedQuestionId
          ? taxonomyQuestions.filter(q => q.id === lastSelectedQuestionId)[0]
          : taxonomyQuestions[0];

        setSelectedQuestion(questionToBeSelected);

        setQuestionsLoading(false);
      }
    } else {
      setSelectedQuestionBookQuestions([]);
      setQuestionsLoading(false);
    }
  };

  const updateQuestion = async formValues => {
    const alternatives = formValues['alternatives-attributes'].map(a => ({
      ...a,
      'is-correct': a.id === formValues['correct-alternative'].id,
      question: undefined,
      id: a.isNew ? undefined : a.id,
      isNew: undefined,
    }));

    const taxonomyIds = formValues.selectedTaxonomies.map(t => t.id);

    const questionBookTaxonomyId = questionBook[
      'question-book-taxonomies'
    ].find(e => e.taxonomy_id === selectedTaxonomy.id)?.id;

    const parsedForm = {
      ...formValues,
      'alternatives-attributes': alternatives,
      'correct-alternative': undefined,
      'taxonomy-ids': taxonomyIds,
      selectedTaxonomies: undefined,
      isDraft: undefined,
      'question-book-taxonomy-id': questionBookTaxonomyId,
    };
    delete parsedForm.isDraft;

    if (formValues.isDraft) {
      const { data, error } = await client.mutate('questions', parsedForm);

      if (error) {
        toast.error(t('toast.errorCreateQuestion'));
      } else {
        setDraftingQuestion(false);
        await getQuestionBookQuestions(selectedQuestionBookId, data.id);

        toast.success(t('toast.successCreateQuestion'));
      }
    } else {
      const { data, error } = await client.mutate(
        ['questions', selectedQuestion.id],
        parsedForm,
      );

      if (error) {
        toast.error(t('toast.errorEditQuestion'));
      } else {
        await getQuestionBookQuestions(selectedQuestionBookId, data.id);
      }
    }
  };

  const deleteQuestionBookQuestions = async (question) => {
    try {
      await client.delete([`questions`, question.id]);
      toast.success(t('toast.successDeleteQuestion'))
      await getQuestionBookQuestions(selectedQuestionBookId, question.id);
    } catch (e) {
      console.log(e);
      toast.error(t('toast.errorDeleteQuestion'))
    }
  };

  const addTaxonomyToQuestionBook = async ({ taxonomy }) => {
    const newTaxonomies =
      selectedQuestionBookTaxonomies.length > 0
        ? [...selectedQuestionBookTaxonomies.map(t => t.id), taxonomy]
        : [taxonomy];

    const parsedForm = {
      'taxonomy-ids': newTaxonomies,
    };

    const { error } = await client.mutate(
      ['question_books', selectedQuestionBookId],
      parsedForm,
    );

    if (error) {
      toast.error(t('toast.errorEditQuestion'));
    }

    await getQuestionBookTaxonomies(selectedQuestionBookId);
  };

  useEffect(() => {
    if (selectedQuestion) {
      getQuestionBookQuestions(selectedQuestionBookId);
    }
  }, [page, searchTerm]);



  return (
    <UpdateQuestionBookContext.Provider
      value={{
        taxonomiesLoading,
        questionsLoading,
        firstLevelTaxonomies,
        getTaxonomies,
        selectedTaxonomy,
        setSelectedTaxonomy,
        selectedTaxonomyQuestions,
        setSelectedTaxonomyQuestions,
        setSelectedQuestionBookId,
        selectedQuestionBookQuestions,
        setSelectedQuestionBookQuestions,
        selectedQuestionBookTaxonomies,
        selectedQuestion,
        setSelectedQuestion,
        page,
        setPage,
        getQuestionBookQuestions,
        updateQuestion,
        addTaxonomyToQuestionBook,
        draftingQuestion,
        setDraftingQuestion,
        questionBook,
        searchTerm,
        setSearchTerm,
        deleteQuestionBookQuestions,
      }}
    >
      {children}
    </UpdateQuestionBookContext.Provider>
  );
};
