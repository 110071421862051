import React, { useState } from 'react';
import BookletCard from './BookletCard';
import EmptyState from 'app/components/EmptyState';
import { t } from 'i18next';

export default function TabBooklet({ currentModule }) {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredBooklet = currentModule?.['learning-system-question-books']?.filter(booklet => {
    if (searchTerm == '') {
      return booklet;
    }
    if (booklet?.['question-book']?.title !== null && booklet?.['question-book']?.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
      return booklet;
    }
  });

  return (
    <div className="fadeIn">
      <div className="filter-bar">
        <div className="filter-bar__row">
          <label
            htmlFor="search"
            className="filter-bar__label"
          >
            {t('textsCommon.search')}
          </label>
          <input
            className="form__control form__control--search-with-icon"
            placeholder="Pesquisar uma apostila"
            type="search"
            name="search"
            id="search"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      {filteredBooklet && filteredBooklet.length > 0 ? (
        <div className="round-dg-wrapper">
          <table
            className="round-dg round-dg--light"
            style={{ width: '644px' }}
          >
            <tbody className="round-dg__body">
              {filteredBooklet.map(booklet => (
                <BookletCard booklet={booklet} />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyState type="data" />
      )}
    </div>
  );
}
