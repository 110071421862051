import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FiCheckCircle } from 'react-icons/fi';

export default function AddBookletCard(props) {
  const { id, lessons, booklet, currentModuleId, setSelectedBooklets, selectedBooklets, selectedBooklet } = props;

  const client = useClient();

  const bookletForm = useFormik({
    initialValues: {
      'learning-system-item-id': !selectedBooklet ? '' : selectedBooklet?.['learning-system-item-id'],
      'question-book-id': booklet?.id,
      'is-required': !selectedBooklet ? false : selectedBooklet?.['is-required'],
      'learning-system-id': currentModuleId
    }
  });

  const addBooklet = async () => {
    const auxValues = { ...bookletForm.values };

    if (auxValues['learning-system-item-id']) {
      delete auxValues['learning-system-id'];
    }
    if (!auxValues['learning-system-item-id']) {
      delete auxValues['learning-system-item-id'];
    }
    const { data, error } = await client.mutate('/learning_system_question_books', auxValues);
    if (error) {
      toast.error('Erro ao adicionar apostila');
    } else {
      if (!selectedBooklets?.['question-book']?.id.includes(data['question-book'].id)) {
        setSelectedBooklets(prevState => [...prevState, data]);
      }
      toast.success('Apostila adicionada!');
    }
  };

  const deleteBooklet = async () => {
    const { error } = await client.delete(['/learning_system_question_books/', selectedBooklet.id]);
    if (error) {
      toast.error('Erro ao remover apostila');
    } else {
      toast.success('Apostila removida com sucesso');
      setSelectedBooklets(prevState => prevState.filter(item => item.id !== selectedBooklet.id));
      bookletForm.setFieldValue('is-required', false);
      bookletForm.setFieldValue('learning-system-item-id', '');
    }
  };

  const handleLessonChange = async e => {
    if (!selectedBooklet) {
      bookletForm.setFieldValue('learning-system-item-id', e.target.value);
    } else {
      bookletForm.setFieldValue('learning-system-item-id', e.target.value);
      const { error } = await client.mutate(`/learning_system_question_books/${selectedBooklet.id}`, {
        'learning-system-item-id': e.target.value === 'blank' ? '' : e.target.value
      });
      if (error) {
        toast.error('Erro ao atualizar apostila');
      } else {
        toast.success('Apostila atualizada!');
      }
    }
  };

  const handleRequiredChange = async () => {
    if (!selectedBooklet) {
      bookletForm.setFieldValue('is-required', !bookletForm?.values?.['is-required']);
    } else {
      bookletForm.setFieldValue('is-required', !bookletForm?.values?.['is-required']);
      const { error } = await client.mutate(`/learning_system_question_books/${selectedBooklet.id}`, {
        'is-required': !bookletForm?.values?.['is-required']
      });
      if (error) {
        toast.error('Erro ao atualizar apostila');
      } else {
        toast.success('Apostila atualizada!');
      }
    }
  };

  return (
    <div
      className="card card--h u-mb-2"
      style={{ alignItems: 'center', flexWrap: 'wrap' }}
    >
      <div className="card__icon-wrapper">
        <FiCheckCircle className="card__icon" />
      </div>
      <div
        className="card__body"
        style={{ flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <h3 className="card__title">{booklet?.title}</h3>
        <p className="card__subtitle">{!booklet?.['finished-at'] ? '' : `${t('textsCommon.availableUntil')} ${moment(booklet?.['finished-at']).format('DD/MM/YYYY')}`}</p>
      </div>
      <div className="card__body">
        <FilterSelectionBox
          blankLabel="Associe a uma aula"
          value={bookletForm?.values?.['learning-system-item-id']}
          options={lessons}
          onChange={handleLessonChange}
        />
      </div>
      <div className="card__footer">
        <div className="form__check u-mb-0">
          <input
            className="form__check-input"
            id={`requiredResponse${id}`}
            type="checkbox"
            checked={bookletForm?.values?.['is-required']}
            onChange={handleRequiredChange}
          />
          <label
            className="form__check-label"
            htmlFor={`requiredResponse${id}`}
          >
            Resposta obrigatória
          </label>
        </div>

        <button
          className={`btn btn--outline btn--wide btn--small ${!!selectedBooklet ? 'active' : ''} `}
          type="button"
          onClick={() => (!selectedBooklet ? addBooklet() : deleteBooklet())}
        >
          {!selectedBooklet ? 'Adicionar' : 'Remover'}
        </button>
      </div>
    </div>
  );
}
