import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import TaskSolutionColumnChart from './TaskSolutionColumnChart';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';

export default function TaskSolutionWidget(props) {
  const { t } = useTranslation();
  const { userId } = props;
  const client = useClient();
  const [timeFilterSelected, setTimeFilterSeleted] = useState('week');
  const [studentTasks, setStudentTasks] = useState();

  const getStudentTasks = async () => {
    const { data, error } = await client.fetch(
      `slot_report?user_id=${userId}&time=${timeFilterSelected}`,
    );
    if (error) {
      toast.error('Erro ao buscar tarefas deste usuário');
    } else {
      setStudentTasks(data);
    }
  };

  useEffect(() => {
    getStudentTasks();
  }, [timeFilterSelected]);

  const btnGroup = [
    {
      id: 'ultima-semana-task',
      timeFilter: 'week',
      label: t("journey.lastWeek"),
    },
    {
      id: 'ultimo-mes-task',
      timeFilter: 'last_month',
      label: t("journey.lastmonth"),
    },
    {
      id: 'tres-meses-task',
      timeFilter: '3_month',
      label: t("journey.threeMonth"),
    },
  ];

  const [totalTasks, setTotalTasks] = useState(0);

  useEffect(() => {
    setTotalTasks(
      (
        studentTasks?.slotData?.map(
          e => e.createdCompleted + e.createdNotCompleted,
        ) ?? []
      ).reduce((sum, current) => sum + current, 0),
    );
  }, [studentTasks]);

  return (
    <div className="widget">
      <div className="widget__header">
        <div className="widget__title-with-btn">
          <h3 className="widget__title u-mb-2">{t("textsCommon.solutionTasks")}</h3>
          <span className="widget__count">
            {totalTasks}
            <span> {t("journey.totalTasks")}</span>
          </span>
        </div>

        <div className="btn-group" role="group" aria-label="Escolha o período">
          {btnGroup.map((btn, index) => {
            return (
              <React.Fragment key={index}>
                <input
                  type="radio"
                  name="timeFilterTask"
                  id={btn.id}
                  autoComplete="off"
                  checked={timeFilterSelected === btn.timeFilter}
                  onChange={() => setTimeFilterSeleted(btn.timeFilter)}
                />
                <label htmlFor={btn.id} className="btn btn--outline">
                  {btn.label}
                </label>
              </React.Fragment>
            );
          })}
        </div>
      </div>

      {totalTasks > 0 ? (
        <div className="widget__body">
          {studentTasks && (
            <TaskSolutionColumnChart chartData={studentTasks.slotData} />
          )}
        </div>
      ) : (
        <EmptyState
          type="register"
          text={t('emptyState.noTaskFoundFilter')}
        />
      )}
    </div>
  );
}
