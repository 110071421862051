import React from 'react';
import styles from './styles.module.scss';

function TabNavBar({ selectedTab, setSelectedTab, essayStatus }) {
  return (
    <div className={`tab u-mb-4 ${styles['tab-only-xxl']}`}>
      <button
        className={selectedTab === 'redacao' ? 'tab__link active' : `tab__link`}
        onClick={() => setSelectedTab('redacao')}
      >
        Redação
      </button>
      <button
        className={selectedTab === 'competencias' ? 'tab__link active' : `tab__link ${styles['tab-only-xxl']}`}
        onClick={() => setSelectedTab('competencias')}
      >
        Competências
      </button>
    </div>
  );
}

export default TabNavBar;
