import React from 'react';
import { SimpleModal } from 'app/components/Modal';
import Brain from './Brain';

export default function LegendModal(props) {
  const { show, onClose } = props;

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      contentClassName="simple-modal__content--lg simple-modal__content--bright simple-modal__content--p-md"
      hideCloseButton
    >
      <h3 className="modal__simple-title">Nível de progresso de conteúdo</h3>

      <ul className="progress-lvl">
        <li className="progress-lvl__item">
          <Brain percentage={0} />

          <div className="progress-lvl__content">
            <h4 className="progress-lvl__title">Começando a entender</h4>
            <p className="progress-lvl__subtitle">
              Você está dando os primeiros passos no aprendizado deste conteúdo. As informações ainda são novas, e é normal que leve um tempo para assimilar tudo. Continue estudando para aprofundar o entendimento.
            </p>
          </div>
        </li>
        <li className="progress-lvl__item">
          <Brain percentage={21} />

          <div className="progress-lvl__content">
            <h4 className="progress-lvl__title">Ganhando clareza</h4>
            <p className="progress-lvl__subtitle">Você já está começando a se familiarizar com os conceitos. O conhecimento está se firmando e as ideias estão ficando mais claras. Mantenha o ritmo para solidificar o aprendizado.</p>
          </div>
        </li>
        <li className="progress-lvl__item">
          <Brain percentage={41} />

          <div className="progress-lvl__content">
            <h4 className="progress-lvl__title">Conteúdo assimilado</h4>
            <p className="progress-lvl__subtitle">Você já absorveu boa parte do conteúdo e está se tornando mais confiante. Os conceitos principais estão sendo compreendidos, e você está no caminho certo para dominar o assunto.</p>
          </div>
        </li>
        <li className="progress-lvl__item">
          <Brain percentage={61} />

          <div className="progress-lvl__content">
            <h4 className="progress-lvl__title">Boa retenção</h4>
            <p className="progress-lvl__subtitle">Você está retendo a maior parte do conteúdo com sucesso. Suas habilidades e conhecimento estão bem desenvolvidos, e você está quase pronto para aplicar o que aprendeu de forma prática.</p>
          </div>
        </li>
        <li className="progress-lvl__item">
          <Brain percentage={81} />

          <div className="progress-lvl__content">
            <h4 className="progress-lvl__title">Ótima retenção</h4>
            <p className="progress-lvl__subtitle">Parabéns! Você atingiu um nível excelente de retenção de conhecimento. Agora, você domina o conteúdo e está preparado para utilizá-lo com confiança em diferentes contextos.</p>
          </div>
        </li>
      </ul>
    </SimpleModal>
  );
}
