import React, { useState, useEffect } from 'react';
import { useSession } from '../hooks/useSession';
import OpenOnboardingMain from '../components/openOnboardingMain';
import Donate from '../components/donate';
import bannerInscricoesFinalizadas from 'app/images/quartosecretariado/banner-inscricoes-finalizadoas.svg';
import moment from 'moment';

function OpenOnboarding() {
  const { session } = useSession();
  const [subFinished, setSubFinished] = useState(moment() > moment('2022-04-02 18:00:00'));
  let intervalId = null;

  useEffect(() => {
    if (intervalId !== null) {
      clearInterval(intervalId);
    }
    intervalId = setInterval(() => tick(), 1000);
  }, []);

  const tick = () => {
    const finished = moment() > moment('2022-04-02 18:00:00');
    setSubFinished(finished);
  };

  return (
    <div className="main-screen">
      <main className="main-content">
        {session.user?.subscribedOpen ? (
          <OpenOnboardingMain />
        ) : subFinished ? (
          <>
            <div className="post-list">
              <div className="user-dashboard__banner">
                <img src={bannerInscricoesFinalizadas} />
              </div>
              <div className="user-dashboard-task-list">
                <div className="user-dashboard-task-list__title">INSCRIÇÕES FINALIZADAS!</div>
                <br />
                <br />
                <div className="page__content">As iscrições para o OPEN4SEC foram finalizadas.</div>
              </div>
            </div>
          </>
        ) : (
          <Donate />
        )}
      </main>
    </div>
  );
}

export default OpenOnboarding;
