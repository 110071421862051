import React from 'react';
import TabNavBar from './TabNavBar';
import CourseTab from './CourseTab';
import ClassTab from './ClassTab';
import Error404 from 'app/components/Error404';

import getPermission from 'app/utils/getPermission';

function Course(props) {
  const { courseId } = props;
  const pathName = window.location.pathname;
  const selectedTab = pathName.split('/').filter(e => e)[0];

  const viewClassroom = getPermission('Visualizar lista de turmas', 'Turmas');

  return (
    <>
      {viewClassroom ? (
        <>
          <main className="main-content main-content--block">
            <TabNavBar
              viewClassroom={viewClassroom}
              viewCourse={viewCourse}
              selectedTab={selectedTab}
            />

            <div className="tab__content">
              {viewCourse && selectedTab === 'turma' && <CourseTab />}
              {viewClassroom && selectedTab === 'classrooms' && <ClassTab courseId={courseId} />}
            </div>
          </main>
        </>
      ) : (
        <Error404 />
      )}
    </>
  );
}

export default Course;
export * from './ManageParticipants';
export * from './CreateOrUpdateParticipant';
