import React from 'react';

import { Sidebar } from './Sidebar';
import { ListQuestions } from './ListQuestions';
import { ContentQuestion } from './ContentQuestion';
import { UpdateQuestionBookProvider } from './provider';

export const UpdateQuestionBook = () => (
  <div className="main-screen">
    <UpdateQuestionBookProvider>
      <Sidebar />

      <main className="main-content u-p-0">
        <div className="new-questions">
          <ListQuestions />

          <ContentQuestion />
        </div>
      </main>
    </UpdateQuestionBookProvider>
  </div>
);
