import React, { useState } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

import SurveyCard from './SurveyCard';
import Modal from 'app/components/Modal';

export default function SurveyAnswers(props) {
  const { studentSurvey, onClose } = props;
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [questions, setQuestions] = useState(studentSurvey['survey-questions']);
  const changeRating = (item, rating, body) => {
    setQuestions(questions.map((q) => (
      item.id === q.id ? { ...q, rating: rating, body: body } : q
    )))
  }

  const sendAnswers = async () => {
    let params = {};
    if (studentSurvey.representation == 'stars') {
      params = {
        surveyId: studentSurvey.id,
        surveyItemIds: questions.map((q) => q.rating?.id).filter(e => e)
      }
    } else {
      params = {
        surveyId: studentSurvey.id,
        surveyQuestionAnswersAttributes: questions.map(q => {
          return { answerBody: q.body, surveyQuestionId: q.id }
        }),
      };
    }

    const { data, error } = await client.mutate('survey_answers', params)
    if (error) {
      toast.error(`${error.id} ${error.title}`);
    } else {
      setShowModal(true)
    }
  }

  return (
    <div className="survey">
      <header className="survey__header">
        <div className="survey__progress">
          { questions?.map((question) => (
            <div key={ question.id } className={ `survey__progress-item ${question.rating ? 'answered' : ''}` } />
          )) }
        </div>

        <h3 className="survey__title">{ studentSurvey.title }</h3>
        <p className="survey__description">{ studentSurvey.description }</p>
      </header>

      <div className="survey__body">
        { questions?.map((question) => (
          <SurveyCard
            key={ question.id }
            data={ question }
            changeRating={ changeRating }
            representation={studentSurvey.representation}
          />
        )) }
      </div>

      <footer className="survey__footer">
        <button
          onClick={ sendAnswers }
          className="btn btn--primary"
          disabled={ studentSurvey.representation === 'stars' ? !questions.every((q) => q.rating) : !questions.every((q) => !!q.body) }
        >
          Finalizar e enviar
        </button>
      </footer>

      <Modal show={ showModal } onClose={ () => setShowModal(false) }>
        <div
          style={ {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          } }
        >
          <div className="survey__progress">
            { questions?.map((question) => (
              <div key={ question.id } className="survey__progress-item answered" />
            )) }
          </div>

          <h3 className="modal__dialog-title">Muito bom!</h3>

          <p>
            Você finalizou o questionário <strong>{ studentSurvey.title }</strong>{ ' ' }
            agradecemos a sua participação
          </p>

          <button
            onClick={ () => {
              onClose();
              setShowModal(false);
            } }
            className="btn btn--primary btn--wide u-mx-auto"
            type="button"
          >
            Fechar
          </button>
        </div>
      </Modal>
    </div>
  );
}
