const schema = {
  answers: {
    type: 'answers',
    fields: {
      duration: 'string',
      is_correct: 'boolean'
    },
    relationships: {
      question: {
        type: 'questions'
      },
      alternative: {
        type: 'alternatives'
      }
    }
  },
  questions: {
    type: 'questions',
    fields: {
      description: 'string'
    }
  },
  alternatives: {
    type: 'alternatives',
    fields: {
      name: 'string',
      description: 'string'
    }
  },
  introductions: {
    type: 'introductions',
    fields: {
      body: 'string'
    }
  },
  comments: {
    type: 'comments',
    fields: {
      body: 'string'
    },
    relationships: {
      user: { type: 'users' }
    }
  },
  posts: {
    type: 'posts',
    fields: {
      body: 'string',
      postType: 'string',
      userId: 'integer',
      createdAt: 'date',
      updatedAt: 'date',
      likesCount: 1,
      commentsCount: 1
    },
    relationships: {
      user: { type: 'users' },
      magicLinks: { type: 'magicLinks' },
      comments: { type: 'comments' },
      taxonomies: { type: 'taxonomies' },
      like: { type: 'userInteractions' },
      bookmark: { type: 'bookmarks' }
    }
  },
  users: {
    type: 'users',
    fields: {
      name: 'string',
      email: 'string',
      password: 'string',
      remote_image_url: 'string',
      slug: 'string',
      can_receive_newsletter: 'boolean',
      subscribed_open: 'boolean'
    },
    relationships: {
      objective: { type: 'objectives' },
      'user-detail': { type: 'user-details' }
    }
  },
  taxonomies: {
    type: 'taxonomies',
    fields: {
      name: 'string',
      description: 'string'
    },
    relationships: {
      'taxonomy-children': { type: 'taxonomies' }
    }
  }
};

export default schema;
