import React, { useEffect, useState } from 'react';
import { FiClock } from 'react-icons/fi';
import TIA from 'app/components/TIA';
import { navigate } from '@reach/router';
import { useClient } from 'jsonapi-react';

export default function GuideCard(props) {
  const { done, title, isCurrent, video, guide_index, canBeClicked = false } = props;

  const client = useClient();
  const [tiaMessageProgressMarch, setTiaMessageProgressMarch] = useState(null);

  const getTiaMessageBlock = async id => {
    const response = await client.mutate(`study_plan_videos/${id}/tia_message_blocks`, {}, { method: 'post' });
    return response.data;
  };

  useEffect(() => {
    if (isCurrent) {
      getTiaMessageBlock(video.id).then(data => {
        setTiaMessageProgressMarch(data?.body);
      });
    }
  }, []);

  const clockIconsCount = guide_index % 2 === 0 ? 3 : 2;
  const clockIcons = Array.from({ length: clockIconsCount }, (_, index) => (
    <FiClock key={index} className="guide-card__icon" />
  ));
  return (
    <div
      className={isCurrent ? 'guide-card guide-card--is-current' : 'guide-card'}
      onClick={() => {if (canBeClicked) navigate(`/guia-de-estudos/bloco-de-estudo/${video.id}`)}}
      style={{ cursor: canBeClicked ? 'pointer' : 'not-allowed' }}
    >
      <input
        className="guide-card__check"
        type="checkbox"
        name="done"
        checked={done}
        readOnly
      />

      <div className="guide-card__content">
        <h3 className="guide-card__title">{title}</h3>

        <div
          className="card__badges card__badges--one-line u-mb-0"
          style={{ lineHeight: 1, alignItems: 'center' }}
        >
          {video.video?.taxonomies.slice(0, 3).map((taxonomy, index) => (
            <span
              className="badge badge--tiny"
              title={taxonomy.name}
              key={index}
            >
              {taxonomy.name}
            </span>
          ))}
          {video.video?.taxonomies.length > 3 && (
            <span
              className="badge badge--tiny badge--more"
              title={video.video?.taxonomies.slice(3).map(t => t.name).join(', ')}
            >
              +{video.video?.taxonomies.length - 3}
            </span>
          )}
        </div>
      </div>

      <div className="guide-card__time">
        {clockIcons}
      </div>

      {isCurrent && tiaMessageProgressMarch && <TIA message={tiaMessageProgressMarch} />}
    </div>
  );
}
