import React from 'react';
import { AgendaProvider } from './AgendaProvider';
import { ClassroomProvider } from './ClassroomProvider';
import { CourseProvider } from './CourseProvider';
import { NoteProvider } from './NoteProvider';
import { ProgramOfStudiesProvider } from './ProgramOfStudiesProvider';
import { QuestionBooksProvider } from './QuestionBooksProvider';
import { SessionProvider } from './SessionProvider';
import { SurveyProvider } from './SurveyProvider';
import { PlatformAbilitiesProvider } from './PlatformAbilitiesProvider';
import { AuditoriumProvider } from './AuditoriumProvider';
import { NotificationProvider } from './NotificationProvider';
import { WeeklyChallengeProvider } from './WeeklyChallenge';
import ReportsAbilitiesProvider from './ReportsAbilitiesProvider';

export const Providers = ({ children }) => (
  <SessionProvider>
    <PlatformAbilitiesProvider>
      <AuditoriumProvider>
        <NotificationProvider>
          <AgendaProvider>
            <NoteProvider>
              <SurveyProvider>
                <QuestionBooksProvider>
                  <ProgramOfStudiesProvider>
                    <CourseProvider>
                      <ClassroomProvider>
                        <ReportsAbilitiesProvider>{children}</ReportsAbilitiesProvider>
                      </ClassroomProvider>
                    </CourseProvider>
                  </ProgramOfStudiesProvider>
                </QuestionBooksProvider>
              </SurveyProvider>
            </NoteProvider>
          </AgendaProvider>
        </NotificationProvider>
      </AuditoriumProvider>
    </PlatformAbilitiesProvider>
  </SessionProvider>
);
