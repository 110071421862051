import React from 'react';
import ManageByCourse from './ManageByCourse';
import ManageByClassrom from './ManageByClassrom';
import ManageByStudent from './ManageByStudent';

export default function TaskManagment(props) {
  const { selectedTab, uri } = props;

  return (
    <>
      {selectedTab === 'curso' && (
        <ManageByCourse
          selectedTab={selectedTab}
          uri={uri}
        />
      )}
      {selectedTab === 'turma' && (
        <ManageByClassrom
          selectedTab={selectedTab}
          uri={uri}
        />
      )}
      {selectedTab === 'estudante' && (
        <ManageByStudent
          selectedTab={selectedTab}
          uri={uri}
        />
      )}
    </>
  );
}

export * from './ManageExcessTasks';
export * from './ManageExcessStudentTasks';
