import React from 'react';
import ScatterChart from './ScatterChart';
import Legend from './Legend';

export default function ScatterChartWidget() {
  return (
    <div className="report__section">
      <div className="report-widget">
        <div className="report-widget__header">
          <h3 className="report-widget__title">Respostas esperadas x obtidas</h3>
        </div>

        <div className="report-widget__body">
          <ScatterChart />
        </div>
      </div>

      <Legend />
    </div>
  );
}
