import React, { useState, useEffect } from 'react';
import QuestionContent from './QuestionContent';
import QuestionSidebar from './QuestionSidebar';
import Error404 from 'app/components/Error404';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { t } from 'i18next';

export default function CreateQuestion(props) {
  const { step } = props;
  const authorityLevel = getAuthorityLevel();

  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    {
      id: 0,
      route: 'nome',
      text: t('exams.stepsTextName')
    },
    {
      id: 1,
      route: 'informacoes',
      text: t('exams.stepsTextInfo')
    },
    {
      id: 2,
      route: 'tipo',
      text: t('exams.stepsTextType')
    },
    {
      id: 3,
      route: 'visibilidade',
      text: t('exams.stepsTextVisibility')
    }
  ];

  function getStep() {
    switch (step) {
      case 'nome':
        setCurrentStep(0);
        break;
      case 'informacoes':
        setCurrentStep(1);
        break;
      case 'tipo':
        setCurrentStep(2);
        break;
      case 'visibilidade':
        setCurrentStep(3);
        break;
      default:
        setCurrentStep(0);
        break;
    }
  }

  useEffect(() => getStep());

  return (
    <>
      {authorityLevel !== 'student' ? (
        <div className="main-screen">
          <QuestionSidebar
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            steps={steps}
          />

          <main className="main-content">
            <QuestionContent
              step={step}
              steps={steps}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          </main>
        </div>
      ) : (
        <Error404 />
      )}
    </>
  );
}
