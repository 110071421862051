import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import ChallengeRow from './ChallengeRow';

export default function ChallengeDataGrid(props) {
  const { challenges, setChallenges, setParam, orderByPeriod, orderByParticipants, setOrderByPeriod, setOrderByParticipants, orderByAccuracy, setOrderByAccuracy } = props;

  return (
    <div className="round-dg-wrapper">
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: 1000 }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th className="round-dg__cell-header round-dg__cell-header--sm">Desafio</th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 140 }}
            >
              Tema
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 120 }}
            >
              Período{' '}
              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                {orderByPeriod === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setOrderByPeriod('ascendent');
                      setParam('period');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setOrderByPeriod('descendent');
                      setParam('period');
                    }}
                    className="card__icon"
                  />
                )}
              </span>
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 160 }}
            >
              Disponibilidade
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 160 }}
            >
              Participantes{' '}
              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                {orderByParticipants === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setOrderByParticipants('ascendent');
                      setParam('participants');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setOrderByParticipants('descendent');
                      setParam('participants');
                    }}
                    className="card__icon"
                  />
                )}
              </span>
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 124 }}
            >
              Índice de acerto{' '}
              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                {orderByAccuracy === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setOrderByAccuracy('ascendent');
                      setParam('accuracy');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setOrderByAccuracy('descendent');
                      setParam('accuracy');
                    }}
                    className="card__icon"
                  />
                )}
              </span>
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 152 }}
            ></th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {challenges.map((item, key) => (
            <ChallengeRow
              challenge={item}
              total={challenges.length}
              index={key}
              key={key}
              setChallenges={setChallenges}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
