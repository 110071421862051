import React from "react";
import { Link } from '@reach/router';
import { useSession } from '../hooks/useSession';
import openHeaderImage from 'app/images/openHeaderImage.png';
import openLogo from 'app/images/openLogo.svg';
import horizontalWhiteLogo from 'app/images/horizontal-white-logo.svg';
import { useTranslation } from 'react-i18next';

function OpenRegister() {
  const { t } = useTranslation();
  const { session } = useSession();
  const registerPath = (session && Object.keys(session).length !== 0) ? '/minha-inscricao' : '/register'

  return (
    <div className="open-register">
      <div className="open-register__hero">
        <div className="open-register__hero-image"><img src={openHeaderImage}/></div>

        <div className="open-register__hero-body">
          <div className="open-register__hero-logo"><img src={openLogo}/></div>
          <div className="open-register__hero-description">
            O <b>OPEN4SEC</b> é a sua oportunidade de saber a quantas andam os seus estudos e reorganizar o seu planejamento,É compostos por questões inéditas, elaboradas por professores especialistas no CACD.
          </div>
          <div className="open-register__hero-footer">
            <Link to={registerPath} className="btn btn--secondary btn--full">Fazer minha inscrição</Link>
            <div className="open-register__hero-footer__warning">Inscrições abertas até dia 02/04/2022</div>
          </div>
        </div>
      </div>

      <div className="open-register__about">
        <h1 className="open-register__about-title">Como funciona</h1>
        <div className="open-register__list">
          <div className="open-register__card">
            <div className="open-register__card-title">83 {t("textsCommon.questions")}</div>
            <div className="open-register__card-description">São 83 questões com 332 itens de C ou E.</div>
          </div>
          <div className="open-register__card">
            <div className="open-register__card-title">Tempo de respostas</div>
            <div className="open-register__card-description">O usuário tem 6h corridas para responder tudo.</div>
          </div>
          <div className="open-register__card">
            <div className="open-register__card-title">Quando?</div>
            <div className="open-register__card-description">Ele ocorre ao longo do ano a cada 2 mêses.</div>
          </div>
          <div className="open-register__card">
            <div className="open-register__card-title">Gabarito oficial</div>
            <div className="open-register__card-description">Na 2.ª Feira, pós simulado há gabaritos e resultado.</div>
          </div>
        </div>
        <div className="open-register__cta">
          <Link to={registerPath} className="btn btn--primary">Fazer minha inscrição</Link>
          <div className="open-register__hero-footer__warning">Inscrições abertas até dia 02/04/2022</div>
        </div>
      </div>

      <div className="open-register__footer">
        <div className="open-register__footer-image"></div>
        <div className="open-register__footer-body">
          <div className="open-register__footer-body__image"><img src={horizontalWhiteLogo}/></div>
          <div className="open-register__footer-body__text">Faça parte da comunidade que vai reinventar os estudos de alta performance.</div>
        </div>
      </div>
    </div>
  )
}

export default OpenRegister
