import React, { useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useFormik } from 'formik';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import moment from 'moment';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function NotificationExtract(props) {
  const { uri } = props;

  const { t } = useTranslation();
  const [timeFilterSelected, setTimeFilterSeleted] = useState(1);
  const [platformNotifications, setPlatformNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const client = useClient();

  const filters = useFormik({
    initialValues: {
      notificationType: 'blank'
    }
  });

  const getPlatformNotifications = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`platform_notifications?week=${timeFilterSelected}`);
    if (error) {
      toast.error('Erro carregar notificações');
    } else {
      setPlatformNotifications(data);
      setLoading(false);
    }
  };

  const notificationsType = platformNotifications?.map(item => item['notification-type']);

  const filteredNotificationsType = notificationsType?.filter((current, i) => {
    return notificationsType.indexOf(current) == i;
  });

  const filterNotifications = platformNotifications?.filter(item => filters?.values.notificationType == 'blank' || item?.['notification-type'] == filters.values.notificationType);

  useEffect(() => {
    getPlatformNotifications();
  }, [timeFilterSelected]);

  const btnGroup = [
    {
      id: 'ultima-semana-engajamento',
      name: 'timeFilterEngagement',
      timeFilter: 1,
      label: t('journey.lastWeek')
    },
    {
      id: 'ultimo-mes-engajamento',
      name: 'timeFilterEngagement',
      timeFilter: 4,
      label: t('journey.lastmonth')
    },
    {
      id: 'tres-meses-engajamento',
      name: 'timeFilterEngagement',
      timeFilter: 12,
      label: t('journey.threeMonth')
    }
  ];

  const breadCrumbs = {
    title: 'Organização da Plataforma',
    nav: [
      {
        route: '/participantes/notificacoes',
        name: 'Usuário',
        isActive: false
      },
      {
        route: uri,
        name: 'Extrato de notificações',
        isActive: true
      }
    ]
  };

  return (
    <>
      <main className="main-content main-content--block">
        <BreadCrumbs data={breadCrumbs} />

        <div className="u-mb-3">
          <FilterSelectionBox
            label="Tipo de notificação"
            value={filters.values.notificationType}
            onChange={e => filters.setFieldValue('notificationType', e.target.value)}
            options={filteredNotificationsType?.map(o => ({
              name: o
            }))}
          />
        </div>

        <div
          className="btn-group u-mb-3"
          role="group"
          aria-label="Escolha o período"
        >
          {btnGroup.map(btn => (
            <React.Fragment key={btn.id}>
              <input
                type="radio"
                name={btn.name}
                id={btn.id}
                autoComplete="off"
                checked={timeFilterSelected === btn.timeFilter}
                onChange={() => setTimeFilterSeleted(btn.timeFilter)}
              />
              <label
                htmlFor={btn.id}
                className="btn btn--outline"
              >
                {btn.label}
              </label>
            </React.Fragment>
          ))}
        </div>

        <div className="round-dg-wrapper">
          <table className="round-dg round-dg--light u-mb-4">
            <thead className="round-dg__header">
              <tr className="round-dg__row">
                <th
                  className="round-dg__cell-header"
                  style={{ width: '120px' }}
                >
                  Data
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '86px' }}
                >
                  Horário
                </th>
                <th className="round-dg__cell-header">Tipo de notificação</th>
                <th className="round-dg__cell-header">Quantidade</th>
                <th className="round-dg__cell-header">Perfil</th>
              </tr>
            </thead>

            <tbody className="round-dg__body">
              {!loading &&
                filterNotifications?.map(item => {
                  const date = new Date(item['created-at']);
                  return (
                    <tr className="round-dg__row">
                      <td className="round-dg__cell">{moment(date).format('DD-MM-YYYY')}</td>
                      <td className="round-dg__cell">{moment(date).format('HH:mm')}</td>
                      <td className="round-dg__cell">{item['notification-type']}</td>
                      <td className="round-dg__cell">{`${item['send-count']} usuários`} </td>
                      <td className="round-dg__cell">
                        {item['profile-names'].map(item => (
                          <span>
                            {item} <br />
                          </span>
                        ))}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {loading && <Loader />}
        </div>
      </main>
    </>
  );
}
