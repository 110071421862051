import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import imageBanner from './img/imagem_banner_aulas.png';
import { useTranslation } from 'react-i18next';


export default function DisclaimerModal(props) {
  const { t } = useTranslation();
  const { show, onClose, lessonName } = props;

  function closeOnEscapeKeyDown(event) {
    if (event.key === 'Escape') onClose();
  }

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
      document.body.style.overflow = 'visible';
    };
  }, []);

  useEffect(() => {
    if (show) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [show]);

  const nodeRef = React.useRef(null); //Fix react-transition-group

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      unmountOnExit
      nodeRef={nodeRef}
      timeout={{ enter: 0, exit: 300 }}
    >
      <div

        className={show ? 'success-modal active' : 'success-modal'}
        onClick={onClose}
        ref={nodeRef}
      >
        <div
          className="success-modal__content success-modal__content--disclaimer"
          onClick={event => event.stopPropagation()}
        >
          <img
            className="success-modal__img"
            src={imageBanner}
            alt=""
          />
          <div className="success-modal__inner">
            <div className="success-modal__header">
              <h2 className="success-modal__name">
                Bem vindo à aula:
                <div className="medal"></div>
              </h2>
            </div>

            <div className="success-modal__body">
              <p className="success-modal__text">{lessonName}</p>
              <p className="success-modal__text">
                <b>Atenção:</b> Para finalizar a aula, assista ao vídeo completo e responda às perguntas que surgirem.
              </p>
              <button onClick={onClose} className="btn btn--wide btn--primary">Começar a aula</button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById('modal_root')
  );
}
