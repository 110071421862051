import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import MockQuestionBookSidebar from './MockQuestionBookSidebar';
import MockQuestionBookContent from './MockQuestionBookContent';
import { useParams } from '@reach/router';
import { t } from 'i18next';

export default function CreateMockQuestionBook(props) {
  const { id, step } = props;
  const client = useClient();
  const [courses, setCourses] = useState([]);
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [editingQuestionBook, setEditingQuestionBook] = useState(null);

  const steps = [
    {
      id: 1,
      route: 'curso',
      text: t('exams.stepsSimulationCourse')
    },
    {
      id: 2,
      route: 'name',
      text: t('exams.stepsSimulationName')
    },
    {
      id: 3,
      route: 'details',
      text: t('exams.stepsSimulationDetails')
    }
  ];

  const getCourses = async () => {
    setCoursesLoading(true);
    const { data, error } = await client.fetch('courses/simple');
    if (error) {
      toast.error('Erro ao carregar cursos');
    } else {
      setCourses(data);
      setCoursesLoading(false);
    }
  };

  const getQuestionBook = async () => {
    const { data, error } = await client.fetch(`question_books/${id}`);
    if (error) {
      toast.error('Erro ao buscar apostila');
    } else {
      const evaluationStart = { date: null, hour: null, minute: null };
      const evaluationEnd = { date: null, hour: null, minute: null };
      if (data?.['published-at']) {
        evaluationStart.date = data['published-at'];
        evaluationStart.hour = new Date(data['published-at']).getHours();
        evaluationStart.minute = new Date(data['published-at']).getMinutes();
      }
      if (data?.['finished-at']) {
        evaluationEnd.date = data['finished-at'];
        evaluationEnd.hour = new Date(data['finished-at']).getHours();
        evaluationEnd.minute = new Date(data['finished-at']).getMinutes();
      }
      const open = {
        ...data,
        evaluationStart,
        evaluationEnd
      };
      delete open['finished-at'];
      delete open['published-at'];
      setEditingQuestionBook(open);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      getQuestionBook();
    }
  }, [id]);

  return (
    <div className="main-screen">
      <MockQuestionBookSidebar
        steps={steps}
        edit={false}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
      <main className="main-content">
        <MockQuestionBookContent
          step={step}
          courses={courses}
          setCurrentStep={setCurrentStep}
          coursesLoading={coursesLoading}
          editingQuestionBook={editingQuestionBook}
          steps={steps}
          currentStep={currentStep}
        />
      </main>
    </div>
  );
}
