import { navigate } from '@reach/router';
import { t } from 'i18next';
import React from 'react';
import { FiCheckCircle, FiFileText, FiFolder, FiVideo } from 'react-icons/fi';

export default function BookletCard({ booklet }) {
  return (
    <tr className="round-dg__row">
      <th
        className="round-dg__cell-header round-dg__cell-header--row"
        style={{ width: '100%' }}
      >
        <div className="card__header">
          <div className="card__inner-header">
            <div className="card__icon-wrapper">
              <FiCheckCircle className="card__icon" />
            </div>
            <h2
              className="card__title"
              style={{ flexGrow: 'initial' }}
            >
              {booklet?.['question-book']?.title}
            </h2>
          </div>
        </div>
      </th>

      <td
        className="round-dg__cell"
        style={{ width: '100px' }}
      >
        <p
          className="card__count"
          title={`${booklet?.['question-book']?.['question-count']} questões`}
          aria-label={`${booklet?.['question-book']?.['question-count']} questões`}
        >
          <FiCheckCircle className="card__icon" /> {booklet?.['question-book']?.['question-count']}
        </p>
      </td>
      <td
        className="round-dg__cell"
        style={{ width: '132px' }}
      >
        <span className="badge badge--tiny badge--danger">{booklet?.['is-required'] === true ? 'Obrigatório' : ''}</span>
      </td>
      <td
        className="round-dg__cell"
        style={{ width: '128px' }}
      >
        <button
          onClick={() => navigate(`/questions/apostilas/${booklet?.['question-book']?.id}`)}
          type="button"
          className="btn btn--outline btn--small"
        >
          {t('button.view')}
        </button>
      </td>
    </tr>
  );
}
