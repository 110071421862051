import React, { useState } from 'react';
import Progress from 'app/components/Progress';
import DiagnosticTestModal from './DiagnosticTestModal';
import moment from 'moment';


export default function DiagnosticTestDataGrid(props) {
  const { data, height = 'auto' } = props;

  const [showModal, setShowModal] = useState(false);
  const [selectedQuestionBookUserId, setSelectedQuestionBookUserId] = useState(null);
  const [title, setTitle] = useState('');

  const handleShowModal = (modal,question_book_user_id, title) => {
    setShowModal(modal);
    setSelectedQuestionBookUserId(question_book_user_id);
    setTitle(title);
  }

  return (
    <div
      className="round-dg-wrapper"
      style={{ height: height }}
    >
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: 688 }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th className="round-dg__cell-header round-dg__cell-header--sm">Prova diagnóstica</th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">Data da prova</th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">Maior rendimento em</th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">Menor rendimento em</th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">Acerto geral</th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 128 }}
            />
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {data?.map((item, key) => (
            <tr
              className="round-dg__row"
              key={key}
            >
              <td className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
                <div className="u-text-truncate">{item.title}</div>
              </td>
              <td className="round-dg__cell round-dg__cell--sm">
                <span className="u-text-truncate">{moment(item.finished_at).format('DD/MM/YYYY') }</span>
              </td>
              <td className="round-dg__cell round-dg__cell--sm">
                <span className="u-text-truncate">{item.highest_score.name}</span>
              </td>
              <td className="round-dg__cell round-dg__cell--sm">
                <span className="u-text-truncate">{item.lowest_score.name}</span>
              </td>
              <td className="round-dg__cell round-dg__cell--sm">
                <Progress.Card
                  percentage={parseFloat(item.answer_percentage)}
                  toFixed={0}
                />
              </td>
              <td className="round-dg__cell round-dg__cell--sm">
                <button
                  type="button"
                  className="btn btn--full btn--tiny btn--link"
                  onClick={() => handleShowModal(true, item.question_book_user_id, item.title)}
                >
                  Mais detalhes
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {<DiagnosticTestModal
        show={showModal}
        onClose={() => setShowModal(false)}
        question_book_user_id={selectedQuestionBookUserId}
        title={title}
      />}
    </div>
  );
}
