import React from 'react';

export default function HelpBg() {
  return (
    <div className="help-center__bg">
      <svg
        width="1440"
        height="2206"
        viewBox="0 0 1440 2206"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2107.44 596C2107.44 596 1418.54 596 1022.85 596C627.167 596 599.842 9.28897e-05 1022.85 9.48177e-05C1445.86 9.67458e-05 2107.44 0 2107.44 0L2107.44 596Z"
          fill="#DEF6F8"
        />
        <path
          d="M5.71484 1591.31L-397.393 1188.2L10.2036 780.607L242.899 1013.3C337.015 1107.42 337.015 1260.01 242.899 1354.13L5.71484 1591.31Z"
          stroke="#DEF6F8"
          strokeWidth="15"
        />
        <circle
          cx="1183"
          cy="2305"
          r="404.5"
          stroke="#DEF6F8"
          strokeWidth="15"
        />
      </svg>
    </div>
  );
}
