import React from 'react';

function ChartWidget(props) {
  const { title = 'Widget title', children } = props;

  return (
    <div className="chart-widget">
      <header className="chart-widget__header">
        <h3 className="chart-widget__title">{title}</h3>
      </header>
      <div className="chart-widget__body">{children}</div>
    </div>
  );
}

export default ChartWidget;
