import React from 'react';
import { FiInfo } from 'react-icons/fi';
import roullete from './img/roullete.svg';
import moment from 'moment';

export default function BannerChallenge({ challenges, isLoading }) {
  const initialDate = challenges?.[0]?.['initial-date']?.split('-').reverse().join('/');
  const endDate = challenges?.[0]?.['end-date']?.split('-').reverse().join('/');

  const challengesDone = challenges?.filter(item => item.done)?.length;
  const allChallenges = challenges?.length;

  const percentage = (100 * challengesDone) / allChallenges;

  const now = moment(moment().format('YYYY-MM-DD'));
  const deadline = moment(challenges?.[0]?.['end-date']);

  const DaysLeft = deadline.diff(now, 'days');

  return (
    <div className="banner-challenge">
      <div
        className="banner-challenge__bg"
        style={{ backgroundImage: `url(${roullete})` }}
      />
      <div className="banner-challenge__header">
        <h1 className="banner-challenge__title">Desafio da Semana</h1>
        <p className="banner-challenge__date">
          {initialDate && endDate && (
            <>
              De {initialDate} a {endDate}
            </>
          )}
        </p>
      </div>

      <div className="banner-challenge__body">
        <div className="card__progress card__progress--cover u-mb-0">
          {!isNaN(percentage) && (
            <>
              <div className="card__progress-container">
                <div
                  className="card__progress-bar"
                  role="progressbar"
                  style={{ width: `${percentage}%` }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <div className="card__progress-text">{percentage.toFixed(2)}%</div>
            </>
          )}
        </div>
      </div>

      <div className="banner-challenge__footer">
        <p className="banner-challenge__count">
          <FiInfo /> Restam {DaysLeft} dias
        </p>
      </div>
    </div>
  );
}
