import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useClient, useQuery } from 'jsonapi-react';
import Modal from 'app/components/Modal';
import StarRating from './StarRating';
import Loader from 'app/components/loader';

export default function LiveClassSurvey({ liveClassName, liveClassId, setCurrentSurvey }) {
  const client = useClient();
  const { data: dataQuestions, isLoading: isLoadingQuestions } = useQuery('live_classroom_questions');
  const { data: dataAnswers, isLoading: isLoadingAnswers } = useQuery(`live_classroom_answers?filter[live_classroom_id]=${liveClassId}`);

  const [questions, setQuestions] = useState([]);
  const [showSurvey, setShowSurvey] = useState(false);
  const [qtdAnswered, setQtdAnswered] = useState(0);
  const [answers, setAnswers] = useState([]);

  const handleSubmitSurvey = async options => {
    let response;

    if (options?.answerId) {
      const answerId = options?.answerId;
      response = await client.mutate(`live_classroom_answers/${answerId}`, { rating: options.rating });

      const answerEdited = answers.map(item => {
        if (item.id === answerId) {
          return {
            ...item,
            rating: options.rating
          };
        }

        return item;
      });

      setAnswers(answerEdited);
    } else {
      response = await client.mutate('live_classroom_answers', options);

      setAnswers(prev => [...prev, response.data]);
      setQtdAnswered(qtdAnswered + 1);
    }

    if (response.error) {
      toast.error('Erro ao responder pergunta');
      return;
    }
  };

  useEffect(() => {
    if (qtdAnswered === 5) {
      toast.success('Agradecemos por responder a pesquisa.');
      setShowSurvey(false);
      setCurrentSurvey(prev => prev + 1);
    }
  }, [qtdAnswered]);

  useEffect(() => {
    if (!isLoadingAnswers && !isLoadingQuestions) {
      setQuestions(dataQuestions);
      setAnswers(dataAnswers);
      setShowSurvey(true);

      if (dataAnswers) {
        setQtdAnswered(dataAnswers.length);
      }
    }
  }, [isLoadingAnswers, isLoadingQuestions, liveClassId]);

  if (isLoadingAnswers || isLoadingQuestions) return <Loader />;

  return (
    <Modal
      show={showSurvey}
      onClose={() => setShowSurvey(false)}
    >
      <h2 className="modal__simple-title">Pesquisa de satisfação - {liveClassName}</h2>

      <div className="new-survey">
        {questions?.length > 0 &&
          questions.map(question => {
            const answerValue = answers.find(item => item?.['live-classroom-question-id'] === question.id);

            return (
              <div
                className="new-survey__question"
                key={question.id}
              >
                <h3 className="new-survey__title">{question.title}</h3>

                <StarRating
                  liveClassId={liveClassId}
                  questionId={question.id}
                  answerRate={answerValue?.rating ? Number(answerValue?.rating) : 0}
                  answerId={answerValue?.id ?? null}
                  setAnswers={setAnswers}
                  onSubmit={handleSubmitSurvey}
                />
              </div>
            );
          })}
      </div>
    </Modal>
  );
}
