import React from 'react';
import logo from '../../images/canaleducacao/logo-oros.svg';

const CanalEducacaoTerm = () => {
  return (
    <div className="terms__screen">
      <div className="terms__brand">
        <img
          alt="Logo Oros"
          src={logo}
        />
      </div>
      <div className="terms__container">
        <h1>TERMOS DE USO – HF TECNOLOGIA</h1>
        <p>
          1. O https://plataforma.canaleducacao.tv/(“Website”) é pertencente e operado pela HF TECNOLOGIA LTDA, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o nº 40.432.531/0001-78, com sede no SCN Quadra 05, Brasília Shopping and
          Towers, Bloco A, Sala 118, CEP: 70.715-900. Você pode entrar em contato conosco pelo e-mail suporte@suporte-plataforma.com.br.
        </p>
        <p>2. A HF TECNOLOGIA, por meio do Website e do Aplicativo, provê uma plataforma educacional paraalunos, professores e demais atuantes do ecossistema educacional.</p>
        <p>
          3. Estes Termos de Uso, em conjunto com os demais contratos e a nossa Política de Privacidade, estabelecem os termos e condições aplicáveis à utilização de nosso Website. É muito importante que você leia e compreenda estas regras, bem como
          outras regras que possam ser aplicáveis, incluindo aquelas advindas da Lei nº 8.078/1990 (“Código de Defesa do Consumidor”).
        </p>
        <p>
          4. Para desfrutar das funcionalidades e para acessar as áreas do Website e do Aplicativo, será requerido que VOCÊ confirme expressamente sua anuência com as Condições Gerais (Parte I abaixo) e os Termos e Condições Adicionais (Parte II
          abaixo) antes do seu primeiro acesso.
        </p>
        <p>
          5. Estes Termos e Condições de Uso podem ser alterados por nós a qualquer momento, sem prévio aviso. A última versão destes Termos e Condições de Uso sempre estará disponível no Website <a href={`${window.location.origin}`}>{'<site>'}</a>,
          e sugerimos fortemente que VOCÊ consulte eventuais atualizações de tempos em tempos. Salientamos que tais alterações serão aplicáveis aos nossos serviços desde o momento em que forem disponibilizadas no Website. Algumas condições
          disponíveis em avisos legais em outros locais do Website podem substituir ou complementar estes Termos e Condições de Uso.
        </p>
        <p>6. Qualquer pessoa que utilizar nossos serviços, o que inclui a simples visita ao Website, assume e expressamente concorda com estes Termos e Condições de Uso.</p>
        <h2>Parte I</h2>
        <p>
          A HF TECNOLOGIA oferece um sistema integrador de aprendizagem e gestão, que traz recursos síncronos e assíncronos capazes de armazenar, gerenciar e disponibilizar conteúdos, questões, cursos, métricas e outras ferramentas de aprendizagem,
          tudo com possibilidade de integração com outros sistemas, oferecendo governança e análise de dados para compreender o desempenho de todo o ecossistema educacional e aplicando inteligência artificial para personalizar a aprendizagem com o
          objetivo de acompanhar e tornar a jornada do estudante simples, divertida e feita sob medida.
        </p>
        <p>
          Através de ferramentas facilitadoras para aplicação de metodologias ativas e interface amigável e altamente intuitiva, a HF TECNOLOGIA promove o engajamento entre alunos e atores educacionais. Conforme o uso, as métricas e indicadores de
          performance e produtividade traçam um cenário real da aprendizagem. Esse processo possibilita que a HF TECNOLOGIA passe, então, a guiar e sugerir trilhas de estudo personalizadas, ajudando a manter os estudantes engajados em um processo de
          melhoria contínua, através do ensino adaptativo.
        </p>
        <p>
          Analisando os dados dos estudantes sob nova perspectiva, torna-se possível replicar em escala macro as análises da performance e produtividade individuais, fomentando com informações estratégicas professores, gestores, coordenações,
          diretorias, entre outros. O objetivo, além de ajudar cada aluno, é facilitar a tomada de decisão de cada ente educacional, mostrando possíveis pontos de melhoria.
        </p>
        <p>7. Ao utilizar o Website ou o Aplicativo, VOCÊ expressamente reconhece que a HF TECNOLOGIA não é responsável por quaisquer decisões que VOCÊ possa vir a fazer por meio do Website. Só VOCÊ será responsável por elas.</p>
        <p>
          8. Finalidade
          <br />
          a. A HF TECNOLOGIA poderá utilizar os dados dos usuários para:
          <br />
          <ul>
            <li> i. Validar e atualizar as informações cadastrais dos usuários;</li>
            <li> ii. Acompanhar a atuação acadêmica do usuário;</li>
            <li> iii. Fornecer relatórios acadêmicos aos usuários e responsáveis pedagógicos;</li>
            <li> iv. Fornecer da performance dos usuários;</li>
            <li> v. Fornecer de relatórios de performance aos usuários e responsáveis pedagógicos;</li>
            <li> vi. Sugerir de trilhas de aprendizagem personalizadas ao usuário;</li>
            <li> vii. Gerar indicadores de engajamento e performance, quantificando a aprendizagem de cada usuário;</li>
            <li> viii. Gerar indicadores de engajamento e performance, para análise e ajustes pedagógicos para turmas e usuários;</li>
            <li> ix. Análise de dados a fim de melhorar os algoritmos de sugestão de aprendizagem contidos no sistema.</li>
          </ul>
        </p>
        <p>9. Disponibilidade do Website e do Aplicativo</p>
        <ul>
          <li>
            {' '}
            a. A HF TECNOLOGIA irá empreender seus melhores esforços para garantir a maior disponibilidade possível do Website e do Aplicativo para oferecer seus serviços. Todavia, nós não somos responsáveis por qualquer indisponibilidade do Website
            ou Aplicativo, por qualquer período ou momento.
          </li>
          <li>
            b. O acesso ao Website e Aplicativo podem ser interrompidos, suspensos ou ficarem intermitentes temporariamente, sem qualquer aviso prévio, em caso de falha de sistema, manutenção, alteração de sistemas, ou por motivos que escapem ao
            controle da HF TECNOLOGIA.
          </li>
        </ul>
        <br />
        <p>10. Conteúdo</p>
        <ul>
          <li>
            {' '}
            a. A HF TECNOLOGIA irá se esforçar para manter o conteúdo do Website atualizado e completo, livre de quaisquer defeitos ou vírus. Contudo, a HF TECNOLOGIA não é responsável por esses e outros possíveis problemas. A HF TECNOLOGIA poderá
            alterar o conteúdo do Website a qualquer momento, sem prévio aviso.
          </li>
          <li>b. É fortemente recomendado que o usuário entre em contato com a HF TECNOLOGIA caso tenha quaisquer dúvidas com relação ao Website e aos serviços antes de se utilizar destes.</li>
          <li>c. O usuário concorda em usar este Website e Aplicativo de boa-fé, sem usar, carregar, transmitir ou enviar do Website ou para o Website qualquer material:</li>
          <ul>
            <li> i. Que seja de cunho violento ou ameaçador, difamatório, obsceno, ofensivo, pornográfico, abusivo, passível de incitar qualquer forma de ódio racial, discriminatório ou em violação de privacidade de terceiro.</li>
            <li> ii. Para o qual não tenham sido obtidas pelo usuário todas as licenças e/ou aprovações necessárias.</li>
            <li> iii. Que constitua ou incite condutas que possam ser consideradas ilícitos criminais ou civis, que violem direitos de terceiros seja no Brasil ou no exterior ou que sejam meramente ilegais sob qualquer outra forma.</li>
            <li>
              {' '}
              iv. Que sejam tecnicamente danosos, incluindo, mas não limitado a vírus de computador, macros, “cavalos de tróia”, worms, componentes maliciosos, dados corrompidos e outros programas ou dados de computador maliciosos ou que sejam
              projetados para interferir, interromper ou derrubar as operações normais de um computador.
            </li>
          </ul>
          <li>d. Você deve zelar para que seu uso do Website e dos serviços não seja prejudicial à estabilidade e disponibilidade destes. Caso isso ocorra, VOCÊ poderá ser responsabilizado pelos prejuízos que comprovadamente der causa.</li>
        </ul>
        <br />
        <p>11. Direitos de Propriedade Intelectual</p>
        <ul>
          <li>
            a. O uso comercial do nome, dos desenhos e da expressão "HF TECNOLOGIA" como nome empresarial, marca, ou nome de domínio, bem como os conteúdos das telas relativas aos serviços da HF TECNOLOGIA assim como os infográficos, artigos,
            colunas, widgets, programas, bancos de dados, documentos e demais utilidades e aplicações que permitem ao usuário acessar e usar sua conta de usuário são de propriedade da HF TECNOLOGIA e estão protegidos por todas as leis e tratados
            aplicáveis.
          </li>
          <li>
            b. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos. Caso deseje utilizar algum destes conteúdos, pedimos que entre em contato conosco antes de fazê-lo. Usar qualquer conteúdo aqui mencionado sem a
            nossa prévia e expressa autorização poderá acarretar responsabilizações penais e civis.
          </li>
        </ul>
        <br />
        <p>12. Links</p>
        <ul>
          <li>
            a. Em virtude de nossas parcerias ou por acreditarmos que possa ser de seu interesse, nós poderemos “linkar” em nosso website, a nosso exclusivo critério, outros websites e funcionalidades da internet, sem que isso signifique que esses
            websites sejam de propriedade ou operados pela HF TECNOLOGIA.
          </li>
          <li>b. A HF TECNOLOGIA não será responsável pelos conteúdos, práticas e serviços ofertados por estes websites.</li>
          <li>c. A disponibilização de links para outros websites não implica em relação de sociedade, de supervisão, de cumplicidade, solidariedade ou de garantia da HF TECNOLOGIA para com esses websites e seus conteúdos.</li>
        </ul>
        <br />
        <p>13. Privacidade</p>
        <ul>
          <li>
            a. A privacidade dos usuários é muito importante para a HF TECNOLOGIA. Ao nos prover com suas informações pessoais, VOCÊ nos autoriza a divulgar e/ou utilizar estas informações estritamente nos termos previstos na nossa Política de
            Privacidade, que está disponível no Website.
          </li>
        </ul>
        <br />
        <p>14. Rescisão</p>
        <ul>
          <li>
            a. Para promover o bom funcionamento e qualidade dos serviços do Website e Aplicativo, a HF TECNOLOGIA se reserva no direito de, sem a necessidade de notificação prévia, impedir ou interromper o acesso do usuário que, segundo a HF
            TECNOLOGIA, estiver atuando de qualquer forma a violar qualquer disposição deste contrato.
          </li>
        </ul>
        <br />
        <p> 15. Demais Condições</p>
        <ul>
          <li>a. A HF TECNOLOGIA é uma empresa brasileira e o Website, Aplicativo e seus serviços são criados e mantidos em fiel cumprimento à legislação.</li>
          <li>
            b. Caso a HF TECNOLOGIA deixe de exercer qualquer direito previsto nestes Termos e Condições de Uso, isto não deverá ser interpretado como uma renúncia, abdicação ou revogação de disposição constante destes Termos e Condições de Uso.
          </li>
          <li>
            c. Caso qualquer disposição ou item destes Termos e Condições de Uso seja considerado inaplicável, nulo, ilegal ou de qualquer outra forma ineficaz, as demais disposições e itens destes Termos e Condições de Uso continuarão em pleno
            vigor.
          </li>
          <li>
            d. Todos os itens destes Termos e Condições Gerais serão regidos pelas leis vigentes da República Federativa do Brasil. Para dirimir quaisquer controvérsias é eleito o Foro da Cidade de Brasília, no Distrito Federal, Brasil, exceção feita
            a reclamações apresentadas por usuários que se enquadrem no conceito legal de consumidores, que poderão submeter suas queixas que não encontrarem solução amigável ao foro da cidade em que forem radicados, conforme artigo 101, II do Código
            de Defesa do Consumidor.
          </li>
        </ul>
        <br />
        <h2>Parte II</h2>
        <p>Termos e Condições Adicionais. Estes Termos e Condições Adicionais são complementares aos Termos e Condições Gerais, constantes do Item I, acima.</p>

        <p>16. Cadastro e Registro</p>

        <ul>
          <li>a. Quando VOCÊ realiza o seu cadastro no Website e/ou Aplicativo, VOCÊ atesta que todos os dados fornecidos são verdadeiros, completos e precisos.</li>
          <ul>
            <li>i. Prover informações incompletas, imprecisas ou falsas constitui violação dos deveres deste contrato, estando o usuário inteiramente responsável pelos danos que tal violação der causa.</li>
            <li>
              ii. A HF TECNOLOGIA pode necessitar de mais informações e documentos sobre VOCÊ a qualquer momento, seja para melhor identificá-lo ou para conduzir diligências internas, caso em que VOCÊ será requerido a fornecê-las. Não fornecer
              prontamente tais informações e documentos quando requisitado constituirá violação destes Termos e Condições de Uso.
            </li>
          </ul>
          <li>b. As informações que VOCÊ utilizar para preencher o nosso cadastro podem mudar, e VOCÊ assume o compromisso de mantê-las sempre atualizadas, alterando-as tão logo ocorra alguma modificação.</li>
          <li>c. A HF TECNOLOGIA poderá se valer de todas as formas lícitas para verificar, a qualquer tempo, se as informações que VOCÊ nos proveu são verdadeiras.</li>
          <li>
            d. Se a HF TECNOLOGIA constatar que suas informações são de alguma forma incompletas, imprecisas ou falsas, VOCÊ poderá ter sua conta suspensa ou cancelada, a exclusivo critério da HF TECNOLOGIA, sem prejuízos de outras medidas que sejam
            aplicáveis.
          </li>
          <li>e. O cadastro no Website e/ou Aplicativo é pessoal e intransferível.</li>
          <li>f. A HF TECNOLOGIA emprega seus melhores esforços para garantir a segurança do seu cadastro, contudo, o sigilo e a segurança do seu nome de usuário e senha são de sua única e exclusiva responsabilidade.</li>
          <li>g. Caso acredite que sua conta está sendo utilizada por outra pessoa ou que a segurança de sua conta tenha sido de qualquer forma comprometida, VOCÊ se compromete a notificar a HF TECNOLOGIA imediatamente.</li>
        </ul>
        <br />
        <p>17. Aceitação</p>
        <ul>
          <li>a. A utilização da Plataforma pressupõe o conhecimento e a aceitação integral destes Termos.</li>
          <li>b. CASO VOCÊ NÃO ACEITE OU NÃO CONCORDE COM ESTES TERMOS, NÃO CLIQUE EM “ACEITO OS TERMOS DE CADASTRO.</li>
          <li>c. CASO VOCÊ CONCORDE E ACEITE ESTES TERMOS, FAVOR MANIFESTAR SUA CONCORDÂNCIA POR VIA ELETRÔNICA CLICANDO EM “ACEITO OS TERMOS DE CADASTRO”</li>
        </ul>
      </div>
    </div>
  );
};

export default CanalEducacaoTerm;
