import React, { useEffect, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import StepName from './StepName';
import StepAbout from './StepAbout';
import StepDuration from './StepDuration/StepDuration';
import TipsSidebar from './TipsSidebar';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import * as Yup from 'yup';
import { t } from 'i18next';

export default function CourseContent(props) {
  const { step, steps, currentStep, setCurrentStep } = props;
  const { moduleId, courseId, activityId } = useParams();
  const client = useClient();
  const [activity, setActivity] = useState();

  const schema = Yup.object({
    title: Yup.string().required(t('warning.requiredField')),
    description: Yup.string().required(t('warning.requiredField')),
    publish_at: Yup.string().required(t('warning.requiredField')),
    // finish_at: Yup.string().required(t('warning.requiredField')),
    class_hours: Yup.string().required(t('warning.requiredField'))
  });

  const createActivityForm = useFormik({
    initialValues: {
      title: '',
      description: '',
      'learning-system-activities-attributes': [
        {
          learning_system_item_id: undefined,
          is_required: true
        }
      ],
      publish_at: '',
      // finish_at: '',
      class_hours: '0.5',
      'activity-file-attributes': {
        url: '',
        file: null
      },
      selectedTaxonomies: []
    },
    validationSchema: schema,
    onSubmit: () => handleSubmit
  });
  useEffect(() => {
    if (activity) {
      createActivityForm.setValues({
        title: activity?.title,
        description: activity?.description,
        'learning-system-activities-attributes': [
          {
            learning_system_item_id: activity?.['learning-system-activities']?.[0]?.learning_system_item_id,
            is_required: activity?.['learning-system-activities']?.[0]?.is_required
          }
        ],
        publish_at: moment(activity?.['publish-at']).format('YYYY-MM-DD'),
        class_hours: activity?.['class-hours'],
        'activity-file-attributes': activity?.['activity-file'],
        selectedTaxonomies: activity?.taxonomies
      });
    }
  }, [activity, activityId]);

  const getActivity = async () => {
    const { data, error } = await client.fetch(`/activities/${activityId}}`);
    if (error) {
      toast.error(t('toast.errorFetchingActivity'));
    } else {
      setActivity(data);
    }
  };

  useEffect(() => {
    if (activityId) {
      getActivity();
    }
  }, [activityId]);

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const createActivity = async () => {
    const auxValues = { ...createActivityForm.values };

    if (auxValues?.['activity-file-attributes']?.file && auxValues?.['activity-file-attributes']?.file !== null) {
      auxValues['activity-file-attributes'].file = await toBase64(auxValues?.['activity-file-attributes']?.file);
      auxValues['activity-file-attributes'].url = null;
    }

    if (auxValues?.['activity-file-attributes']?.url && auxValues?.['activity-file-attributes']?.url !== '') {
      auxValues['activity-file-attributes'].url = auxValues?.['activity-file-attributes']?.url;
      auxValues['activity-file-attributes'].file = null;
    }

    if (auxValues['learning-system-activities-attributes'].length === 0) {
      auxValues['learning-system-id'] = moduleId;
      delete auxValues['learning-system-activities-attributes'];
    } else { 
      if (activity && !createActivityForm.values?.['learning-system-activities-attributes'][0]?.is_required) {
        auxValues['learning-system-activities-attributes'][0]._destroy = true;
        delete auxValues['learning-system-activities-attributes'];
        auxValues['learning-system-id'] = moduleId;
      }

      if (!createActivityForm.values?.['learning-system-activities-attributes'][0]?.is_required) {
        delete auxValues['learning-system-activities-attributes'];
        auxValues['learning-system-id'] = moduleId;
      }
    }

    auxValues['taxonomy-ids'] = auxValues.selectedTaxonomies.map(t => t.id);

    delete auxValues.selectedTaxonomies;

    const url = activityId ? ['/activities', activityId] : '/activities';

    const { error } = await client.mutate(url, auxValues);
    if (error) {
      toast.error('Erro ao criar atividade');
    } else {
      if (!activity) {
        toast.success('Atividade criada!');
        navigate(`/curso/admin/gerenciar/${courseId}/${moduleId}/atividades/d`);
      } else {
        toast.success('Atividade editada!');
        navigate(`/curso/admin/gerenciar/${courseId}/${moduleId}/atividades/d`);
      }
    }
  };

  const [titleError, setTitleError] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    switch (step) {
      case 'nome':
        if (!activityId) {
          if (createActivityForm.values.title === '') {
            setTitleError(true);
          }
          if (createActivityForm.values.title !== '') {
            navigate(`/atividades/criar/sobre/${moduleId}/${courseId}`);
            setCurrentStep(1);
            setTitleError(false);
          }
          break;
        } else {
          if (createActivityForm.values.title === '') {
            setTitleError(true);
          }
          if (createActivityForm.values.title !== '') {
            navigate(`/atividades/criar/sobre/${moduleId}/${courseId}/${activityId}`);
            setCurrentStep(1);
            setTitleError(false);
          }
          break;
        }
      case 'sobre':
        if (!activityId) {
          createActivityForm.setFieldTouched('description', true);
          createActivityForm.setFieldTouched('activity-file-attributes.url', true);
          createActivityForm.setFieldTouched('activity-file-attributes.file', true);
          if (!createActivityForm.errors.description) {
            navigate(`/atividades/criar/prazo/${moduleId}/${courseId}`);
            setCurrentStep(2);
          }
          break;
        } else {
          createActivityForm.setFieldTouched('description', true);
          createActivityForm.setFieldTouched('activity-file-attributes.url', true);
          createActivityForm.setFieldTouched('activity-file-attributes.file', true);
          if (!createActivityForm.errors.description) {
            navigate(`/atividades/criar/prazo/${moduleId}/${courseId}/${activityId}`);
            setCurrentStep(2);
          }
          break;
        }
      case 'prazo':
        createActivityForm.setFieldTouched('publish_at', true);
        // createActivityForm.setFieldTouched('finish_at', true);
        if (
          !createActivityForm.errors.publish_at
          //  && !createActivityForm.errors.finish_at
        ) {
          createActivity();
        }
    }
  };

  const btnsStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  };

  return (
    <div className="page page--wrap">
      <div className="page__col">
        <form
          className="form form--step-by-step"
          method="post"
          onSubmit={handleSubmit}
        >
          <StepName
            titleError={titleError}
            step={step}
            form={createActivityForm}
          />
          <StepAbout
            activity={activity}
            step={step}
            form={createActivityForm}
          />
          <StepDuration
            moduleId={moduleId}
            step={step}
            form={createActivityForm}
          />

          <div style={btnsStyles}>
            {step !== 'nome' && (
              <button
                type="button"
                className="btn btn--outline btn--wide"
                onClick={() => {
                  if (!activityId) {
                    navigate(`/atividades/criar/${steps?.[currentStep - 1]?.route}/${moduleId}/${courseId}`);
                    setCurrentStep(currentStep - 1);
                  } else {
                    navigate(`/atividades/criar/${steps?.[currentStep - 1]?.route}/${moduleId}/${courseId}/${activityId}`);
                    setCurrentStep(currentStep - 1);
                  }
                }}
              >
                {t('button.previous')}
              </button>
            )}

            <button
              type="submit"
              className="btn btn--primary btn--wide"
            >
              {step !== 'prazo' ? t('button.next') : t('button.finish')}
            </button>
          </div>
        </form>
      </div>
      <TipsSidebar step={step} />
    </div>
  );
}
