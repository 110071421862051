import lampIcon from './img/lamp-icon.svg';
import imageAreaTip from './img/image-area-tip.svg';
import darkeningImageTip from './img/darkening-image-tip.png';
import { t } from 'i18next';

export default function TipsSidebar(props) {
  const { step, form } = props;

  return (
    <>
      {step === 'nome' && <div className="page__aside" />}
      {step === 'sobre' && <div className="page__aside" />}
      {step === 'imagem' && (
        <aside className="fadeIn page__aside page__aside--bg">
          <img
            src={lampIcon}
            alt="ícone lâmpada"
            className="page__aside-icon"
          />

          <h3 className="page__aside-title">{t('courses.coverImage')}</h3>
          <p className="page__aside-text">{t('courses.asideTextDescription')}</p>
          <p className="page__aside-text u-mb-0">
            <strong>{t('courses.observations')}</strong>
          </p>
          <ul className="page__aside-list">
            <li>
              <div dangerouslySetInnerHTML={{ __html: t('courses.asideTextDimensions') }}></div>
              <img
                src={imageAreaTip}
                alt={t('courses.asideImageSecurity')}
              />
            </li>
            <li>
              <div dangerouslySetInnerHTML={{ __html: t('courses.asideTextLegibility') }}></div>
              <br />
              <img
                src={darkeningImageTip}
                alt={t('courses.asideTextAltTip')}
              />
            </li>
          </ul>
          <p className="page__aside-text page__aside-text--tip">
            <div dangerouslySetInnerHTML={{ __html: t('courses.AsideTextTip') }}></div>
          </p>
        </aside>
      )}
    </>
  );
}
