import Vimeo from '@u-wave/react-vimeo';
import { useEffect, useRef, useState } from 'react';

const FIFTEEN_MINUTES_IN_SECONDS = 900;

export function VimeoWithSurvey({ videoId, hasSurveyToAnswer, showModalSurvey = false, setShowModalSurvey = () => null }) {
  const [watchedTime, setWatchedTime] = useState(0);
  const lastTimeRef = useRef(null);

  const handleTimeUpdate = seconds => {
    if (lastTimeRef.current) {
      const diff = seconds - lastTimeRef.current;
      setWatchedTime(prevTime => prevTime + diff);
    }
    lastTimeRef.current = seconds;
  };

  useEffect(() => {
    if (watchedTime >= FIFTEEN_MINUTES_IN_SECONDS && !showModalSurvey) {
      setShowModalSurvey(true);
    }
  }, [watchedTime, showModalSurvey]);

  return (
    <Vimeo
      video={videoId}
      autoplay
      height="100%"
      width="100%"
      onTimeUpdate={hasSurveyToAnswer ? handleTimeUpdate : null}
    />
  );
}
