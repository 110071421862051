import { useContext } from 'react';
import { AuditoriumContext } from 'app/providers/AuditoriumProvider';

export const useAuditorium = () => {
  const context = useContext(AuditoriumContext);

  if (!context) {
    throw new Error('useAuditorium must be used within a AuditoriumProvider.');
  }

  return context;
};
