import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import TextareaAutosize from 'react-autosize-textarea/lib';

export default function StepInfo(props) {
  const { step, form, question } = props;
  const [description, setDescription] = useState('');
  const [motivatorText, setMotivatorText] = useState('');

  const handleDescriptionChange = data => {
    setDescription(data.getData());
    form.setFieldValue('description', description);
  };

  const handleMotivatorTextChange = data => {
    setMotivatorText(data.getData());
    form.setFieldValue('motivator-texts-attributes.[0].body', motivatorText);
  };

  useEffect(() => {
    if (question) {
      setDescription(question.description);
      setMotivatorText(question['motivator-texts'][0]?.body);
    }
  }, [question]);

  return (
    step === 'informacoes' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{form.values.code || t('exams.titleNewQuestion')}</h2>
        </div>

        <div className="new-questions__body">
          <p className="new-questions__subtitle">{t('exams.subtitleMotivatorText')}</p>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="motivator-texts-attributes"
            >
              {t('exams.formTitleMotivatorText')}
            </label>
            <CKEditor
              editor={Editor}
              data={motivatorText}
              config={{ placeholder: t('exams.pInsertMotivatingText') }}
              onChange={(event, editor) => {
                handleMotivatorTextChange(editor);
              }}
              onBlur={(event, editor) => {
                handleMotivatorTextChange(editor);
              }}
            />
            {form?.touched?.['motivator-texts-attributes']?.[0]?.body && form?.errors?.['motivator-texts-attributes']?.[0]?.body && <span style={{ color: 'red' }}>{form?.errors?.['motivator-texts-attributes']?.[0]?.body}</span>}
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="description"
            >
              *{t('exams.formTitleStatementQuestion')}
            </label>

            <CKEditor
              editor={Editor}
              data={description}
              config={{ placeholder: t('exams.pInsertStatement') }}
              onChange={(event, editor) => {
                handleDescriptionChange(editor);
              }}
              onBlur={(event, editor) => {
                handleDescriptionChange(editor);
              }}
            />
            {form?.touched?.description && form?.errors?.description && <span style={{ color: 'red' }}>{form?.errors.description}</span>}
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="print"
            >
              {t('exams.titlePress')}
            </label>

            <input
              className="form__control"
              placeholder={t('exams.placeholderPress')}
              id="print"
              name="print"
              type="text"
              value={form?.values?.['motivator-texts-attributes']?.[0]?.print}
              onChange={e => form.setFieldValue('motivator-texts-attributes.[0].print', e.target.value)}
            />
            {form?.touched?.['motivator-texts-attributes']?.[0]?.print && form?.errors?.['motivator-texts-attributes']?.[0]?.print && <span style={{ color: 'red' }}>{form?.errors?.['motivator-texts-attributes']?.[0]?.print}</span>}
          </div>
        </div>
      </div>
    )
  );
}
