import React, { useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import HitsByMatterColumnChart from './HitsByMatterColumnChart';
import ChallengeRow from './ChallengeRow';

export default function AccordionItem(props) {
  const { challenge, isStudent, selectedTab, taxonomies, studentAccuracy } = props;

  const accordionContent = useRef(null);
  const [active, setActive] = useState(false);

  const chartData = taxonomies?.map(item => ({ value: item['question-accuracy'], label: item.taxonomy }));

  return (
    <div className={active ? 'accordion__item active' : 'accordion__item'}>
      <div
        className="accordion__header"
        onClick={() => setActive(!active)}
      >
        <ChallengeRow
          studentAccuracy={studentAccuracy}
          challenge={challenge}
          isStudent={isStudent}
          selectedTab={selectedTab}
        />

        <button className="card__icon-wrapper card__icon-wrapper--sm u-ml-auto">
          <FiChevronDown className="card__icon" />
        </button>
      </div>

      <div
        className="accordion__collapse"
        style={{
          height: active ? `${accordionContent.current.scrollHeight}px` : '0'
        }}
      >
        <div
          className="accordion__content"
          ref={accordionContent}
        >
          <h3 className="widget__title u-mb-3">Índice de acerto por matéria</h3>
          <HitsByMatterColumnChart chartData={chartData} />
        </div>
      </div>
    </div>
  );
}
