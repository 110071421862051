import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { useSession } from '../hooks/useSession';
import PostCard from '../components/post';
import SharePost from '../components/SharePost';
import Loader from '../components/loader';
import EndScroll from '../components/endScroll';
import { navigate } from '@reach/router';
import Onboarding from 'app/components/Onboarding';
import getPermission from 'app/utils/getPermission';
import UserInfo from 'app/components/UserInfo';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

function Feed(props) {
  const { uri } = props;
  const animatedComponents = makeAnimated();

  const { t } = useTranslation();
  const client = useClient();
  const { session } = useSession();
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];

  const path = props.path;

  const [pageNumber, setPageNumber] = useState(1);

  const [studentClassrooms, setStudentClassrooms] = useState([]);
  const [selectedClassroomId, setSelectedClassroomId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [professors, setProfessors] = useState([]);

  const [hasMore, setHasMore] = useState(true);
  const [posts, setPosts] = useState([]);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const addPosts = async post => {
    const newPosts = posts;
    newPosts.unshift(post);
    setPosts([...newPosts]);
  };

  const getPostsList = async () => {
    setHasMore(true);
    setPageNumber(1);
    try {
      const { data } = await client.fetch(`posts?page[number]=1&page[size]=10${selectedClassroomId?.value ? `&filter[classroom_id]=${selectedClassroomId?.value}` : ''}`);
      if (data.length === 0) {
        setHasMore(false);
      }
      setPosts([...data]);
    } catch (e) {
      toast.error(t('toast.errorGetPost'));
    }
  };

  const updatePostsList = async () => {
    try {
      const { data } = await client.fetch(`posts?page[number]=${pageNumber}&page[size]=10${selectedClassroomId ? `&filter[classroom_id]=${selectedClassroomId}` : ''}`);
      if (data.length === 0) {
        setHasMore(false);
      }
      setPosts([...posts, ...data]);
    } catch (e) {
      toast.error(t('toast.errorGetPost'));
    }
  };

  const getClassrooms = async () => {
    setIsLoading(true);
    const { error, data } = await client.fetch(`classrooms`);

    if (error) {
      toast.error(t('toast.errorGetClassroom'));
    } else {
      setStudentClassrooms(data);
    }
    setIsLoading(false);
  };

  const getProfessors = async () => {
    const { error, data } = await client.fetch('users?filter[profile]=Professor');

    if (error) {
      toast.error(t('toast.errorGetClassroom'));
    } else {
      setProfessors(data);
    }
  };

  useEffect(() => {
    getClassrooms();
    getProfessors();
  }, []);

  useEffect(() => {
    getPostsList();
  }, [selectedClassroomId]);

  useEffect(() => {
    if (pageNumber !== 1) {
      updatePostsList();
    }
  }, [pageNumber]);

  const breadCrumbs = {
    title: 'Comunidade',
    nav: [
      {
        route: uri,
        name: 'Fórum',
        isActive: true
      }
    ]
  };

  return (
    <>
      <div className="main-content">
        <div className="page">
          <div className="page__col">
            <BreadCrumbs data={breadCrumbs} />

            <div className="filter-bar">
              <div className="filter-bar__row">
                <label className="form__label">{t('filter.labelSelectTheClassroom')}</label>
                <Select
                  className="react-multi-select filter-bar__multi-select"
                  classNamePrefix="react-multi-select"
                  openMenuOnFocus
                  noOptionsMessage={() => 'Sem opções'}
                  isDisabled={isLoading}
                  value={selectedClassroomId}
                  placeholder={isLoading ? 'Carregando...' : t('filter.labelSelectClassroom')}
                  onChange={item => {
                    if (item.value === 'blank') setSelectedClassroomId(null);
                    else setSelectedClassroomId(item);
                  }}
                  components={{
                    animatedComponents,
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null
                  }}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  options={[
                    { value: 'blank', label: t('filter.blankLabelAll') },
                    ...studentClassrooms?.map(o => ({
                      value: o?.id,
                      label: o?.institution?.name + '-' + o?.course?.title + '-' + o?.title
                    }))
                  ]}
                />
              </div>
            </div>

            {getPermission('Criar postagens e comentários', 'Fórum') && (
              <SharePost
                addPosts={e => addPosts(e)}
                selectedClassroomId={selectedClassroomId}
              />
            )}
            <InfiniteScroll
              dataLength={posts.length}
              next={() => setPageNumber(pageNumber + 1)}
              hasMore={hasMore}
              loader={<Loader />}
              // endMessage={<EndScroll />}
              style={{ padding: '0 10px', margin: '0 -10px' }}
            >
              {posts.map(post => (
                <PostCard
                  key={post.id}
                  post={post}
                  setPosts={setPosts}
                />
              ))}
            </InfiniteScroll>
          </div>

          <aside className="page__aside page__aside--forum">
            <h3 className="user-dashboard-task-list__title u-mb-4">{t('feed.mentorsAndTeachers')}</h3>

            {professors &&
              professors.map((p, key) => (
                <div
                  className="u-cursor-pointer"
                  onClick={() => navigate(`/usuario/metricas/${p?.id}`)}
                  state={{ isRedirected: true }}
                  key={key}
                >
                  <div className="student-card u-mb-3">
                    <header className="student-card__header">
                      <UserInfo user={p} />
                    </header>
                  </div>
                </div>
              ))}
          </aside>
        </div>
      </div>

      <Onboarding
        path={path}
        show={showOnboarding}
        setShowOnboarding={setShowOnboarding}
        onClose={() => setShowOnboarding(false)}
        profiles={profiles}
        title={t('onboarding.titleFeed')}
        videoId="SS_aqXW4ob0"
      />
    </>
  );
}

export default Feed;
