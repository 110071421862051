import React, { useState } from 'react';
import { useFormik } from 'formik';
import { ReportLegendWrapper } from 'app/components/ReportLegend';
import CompatibilityCard, { CompatibilityCardWrapper } from './CompatibilityCard';
import DetailModal from './DetailModal';

export default function CompatibilityWidget() {
  const [showModal, setShowModal] = useState(false);

  const filters = useFormik({
    initialValues: {
      searchTerm: ''
    }
  });

  return (
    <section className="report__section">
      <div className="report-widget">
        <div className="report-widget__header">
          <h3 className="report-widget__title">Compatibilidades</h3>
        </div>

        <div className="report-widget__filter">
          <input
            className="report-form__control report-form__control--md"
            aria-label="Pesquisar"
            placeholder="Pesquisar"
            type="search"
            name="search"
            id="search"
            onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
          />
        </div>

        <div className="report-widget__body">
          <CompatibilityCardWrapper>
            {[...Array(8)].map((card, index) => (
              <CompatibilityCard
                setShowModal={setShowModal}
                key={index}
              />
            ))}
          </CompatibilityCardWrapper>

          <DetailModal
            show={showModal}
            onClose={() => setShowModal(false)}
          />
        </div>
      </div>

      <ReportLegendWrapper />
    </section>
  );
}
