import { useState, useEffect } from 'react';
import ExamsResolution from './ExamsResolution';
import AboutExam from './AboutExam';
import StudentResponse from './ExamsResolution/StudentResponse';
import MetricsListTab from './MetricsListTab';
import ExamlistTab from './ExamListTab';
import QuestionListTab from '../Admin/Questions';
import QuestionBook from '../ProgramOfStudies/QuestionBook';
import getPermission from 'app/utils/getPermission';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import MockQuestionBooks from '../ProgramOfStudies';
import MainTabNavBar from 'app/components/MainTabNavBar';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import OfflineExam from './OfflineExam';
import PrintedExam from './PrintedExam';
import { useQuestionBook } from '../../hooks/useQuestionBook';
import BreadCrumbs from 'app/components/BreadCrumbs';

function Exams(props) {
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMocks, setLoadingMocks] = useState(true);
  const [mockQuestionBooks, setMockQuestionBooks] = useState([]);
  const [isFilterCourse, setIsFilterCourse] = useState(false);
  const [taxonomies, setTaxonomies] = useState([]);
  const client = useClient();
  const { questionBooks, setQuestionBooks } = useQuestionBook();
  const [loadingClassrooms, setLoadingClassrooms] = useState(false);

  const { selectedTab, uri } = props;

  const getCourses = async () => {
    try {
      const { data } = await client.fetch('courses/simple');
      setCourses(data);
    } catch {
      toast.error(t('toast.errorGetCourse'));
    }
  };

  const getExams = async () => {
    setLoading(true);
    try {
      const { data } = await client.fetch('question_books?filter[visibility][]=exam');
      setExams(
        data.map(item => {
          item.status = getStatus(item);
          return item;
        })
      );
    } catch {
      toast.error(t('toast.errorGetExams'));
    }
    setLoading(false);
  };

  const getMockQuestionBooks = async () => {
    setLoadingMocks(true);
    const { data, error } = await client.fetch('question_books?filter[visibility]=open');
    if (error) {
      toast.error(t('toast.errorGetSimulations'));
    } else {
      const newData = data?.map(item => {
        if (moment().isAfter(item?.['finished-at'])) {
          return { ...item, status: 'Realizado' };
        } else {
          return { ...item, status: 'Não realizado' };
        }
      });
      setMockQuestionBooks(newData);
    }
    setLoadingMocks(false);
  };

  const getQuestionBooks = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('question_books?filter[visibility]=train');
    if (error) {
      toast.error('Erro ao buscar apostilas');
    } else {
      setQuestionBooks(data.filter(q => q.visibility === 'train'));
    }
    setLoading(false);
  };

  const ExamsAndMocks = [...exams, ...mockQuestionBooks];

  const getClassrooms = async () => {
    setLoadingClassrooms(true);
    const { data, error } = await client.fetch('/classrooms?simple=true');
    if (error) {
      toast.error('Erro ao buscar turmas');
    } else {
      setClassrooms(data);
    }
    setLoadingClassrooms(false);
  };

  const fetchTaxonomies = async () => {
    try {
      const { data } = await client.fetch(`taxonomies?simple=true`);
      setTaxonomies(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    switch (selectedTab) {
      case 'provas':
        courses?.length === 0 && getCourses();
        exams?.length === 0 && getExams();
        classrooms?.length === 0 && getClassrooms();
        break;
      case 'simulados':
        courses?.length === 0 && getCourses();
        mockQuestionBooks?.length === 0 && getMockQuestionBooks();
        break;
      case 'resultados':
        courses?.length === 0 && getCourses();
        mockQuestionBooks?.length === 0 && getMockQuestionBooks();
        exams?.length === 0 && getExams();
        break;
      case 'apostilas':
        courses?.length === 0 && getCourses();
        classrooms?.length === 0 && getClassrooms();
        questionBooks?.length === 0 && getQuestionBooks();
        break;
      case 'banco-de-questoes':
        taxonomies?.length === 0 && fetchTaxonomies();
        break;
    }
  }, [selectedTab]);

  const handleDeleteExam = examId => {
    setExams(exams.filter(e => e.id !== examId));
  };

  const getStatus = exam => {
    const actualDate = moment();
    const initialTime = exam?.['published-at'];
    const finishTime = exam?.['finished-at'];

    if (!initialTime || !finishTime) {
      return 'Em andamento';
    } else if (actualDate.isBefore(initialTime)) {
      return 'Não realizada';
    } else if (actualDate.isBetween(initialTime, finishTime)) {
      return 'Em andamento';
    } else {
      return 'Realizada';
    }
  };

  const viewQuestionBank = getPermission('Visualizar banco de questões', 'Questões e exercícios');

  const viewQuestionbook = getPermission('Visualizar exercícios', 'Questões e exercícios');

  const viewExams = getPermission('Visualizar lista de provas', 'Provas e simulados');
  const { abilities } = usePlatformAbilities();

  const getActiveTabs = () => {
    let tabs = [];
    if (abilities?.['avaliacoes-provas'] || abilities?.['avaliacoes-simulados']) {
      tabs.push({
        name: t('exams.toggleResultExams'),
        url: '/avaliacoes/resultados',
        slug: 'resultados',
        order: 4
      });
    }
    if (viewQuestionBank) {
      tabs.push({ name: t('exams.toggleQuestionBank'), url: '/avaliacoes/banco-de-questoes', slug: 'banco-de-questoes', order: 0 });
    }
    if (viewExams && abilities?.['avaliacoes-provas']) {
      tabs.push({ name: t('exams.toggleExams'), url: '/avaliacoes/provas', slug: 'provas', order: 1 });
    }
    if (viewQuestionbook && abilities?.['avaliacoes-simulados']) {
      tabs.push({ name: t('exams.toggleSimulations'), url: '/avaliacoes/simulados', slug: 'simulados', order: 2 });
    }
    if (viewQuestionbook && abilities?.['avaliacoes-apostilas']) {
      tabs.push({ name: t('exams.toggleQuestionBooks'), url: '/avaliacoes/apostilas', slug: 'apostilas', order: 3 });
    }

    return tabs;
  };

  const breadCrumbs = {
    title: 'Ferramentas de Avaliação',
    nav: [
      {
        route: uri,
        name: selectedTab === 'apostilas' ? 'Exercícios' : selectedTab === 'provas' ? 'Provas' : selectedTab === 'simulados' ? 'Simulados' : 'Banco de Questões',
        isActive: true
      }
    ]
  };

  return (
    <>
      <main className="main-content main-content--block">
        <BreadCrumbs data={breadCrumbs} />

        <MainTabNavBar
          tabs={getActiveTabs()}
          selectedTab={selectedTab}
        />

        <div className="tab__content">
          {props.selectedTab === 'provas' && (
            <ExamlistTab
              handleDeleteExam={handleDeleteExam}
              courses={courses}
              classrooms={classrooms}
              loadingClassrooms={loadingClassrooms}
              exams={exams}
              loading={loading}
            />
          )}
          {props.selectedTab === 'resultados' && (
            <MetricsListTab
              loading={loading}
              exams={ExamsAndMocks}
              courses={courses}
            />
          )}

          {props.selectedTab === 'banco-de-questoes' && <QuestionListTab taxonomies={taxonomies} />}
          {props.selectedTab === 'apostilas' && (
            <QuestionBook
              loadingClassrooms={loadingClassrooms}
              classrooms={classrooms}
              courses={courses}
              questionBooks={questionBooks}
              loading={loading}
            />
          )}
          {props.selectedTab === 'simulados' && (
            <MockQuestionBooks
              mockQuestionBooks={mockQuestionBooks}
              loading={loadingMocks}
              courses={courses}
            />
          )}
        </div>
      </main>
    </>
  );
}

export { Exams as default, ExamsResolution, AboutExam, StudentResponse, OfflineExam, PrintedExam };
