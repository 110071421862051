import React, { useEffect, useState } from 'react';
import Modal from 'app/components/Modal';
import MatterCard from './MatterCard';
import { useTranslation } from 'react-i18next';
import Loader from 'app/components/loader';

export default function MatterModal(props) {
  const { t } = useTranslation();
  const { show, onClose, matters, selectedMatters, addMatter, removeMatter, addedMatters, showModal, countType, loading } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [currentMatters, setCurrentMatters] = useState(matters);

  useEffect(() => {
    if (searchTerm == '') {
      setCurrentMatters(matters);
    } else {
      setCurrentMatters([...matters?.filter(matter => matter.name != null && matter.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()))]);
    }
  }, [searchTerm, show, matters]);

  useEffect(() => {
    if (show) {
      setSearchTerm('');
    }
  }, [show]);

  return (
    <Modal
      show={show || showModal}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <h3 className="modal__simple-title">{t('exams.selectExamsSubject')}</h3>
      {!loading ? (
        <>
          <div className="form__row">
            <label
              className="form__label"
              htmlFor="search"
            >
              {t('textsCommon.matters')}
            </label>

            <input
              aria-label={t('textsCommon.research')}
              className="form__control form__control--search-with-icon"
              style={{ width: 'auto', maxWidth: '100%' }}
              placeholder={t('textsCommon.research')}
              type="search"
              value={searchTerm}
              name="search"
              id="search"
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="round-dg-wrapper round-dg-wrapper--modal">
            <table
              className="round-dg round-dg--light"
              style={{ minWidth: '492px' }}
            >
              <tbody className="round-dg__body">
                {currentMatters?.map((matter, index) => {
                  const selectedItem = selectedMatters?.find(item => (showModal ? item.taxonomy_id === matter.id : item.id === matter.id));
                  return (
                    <MatterCard
                      showModal={showModal}
                      addedMatters={addedMatters}
                      addMatter={addMatter}
                      removeMatter={removeMatter}
                      matter={matter}
                      key={index}
                      isSelected={!!selectedItem}
                      countType={countType}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Modal>
  );
}
