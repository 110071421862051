import React from 'react';
import { SimpleModal } from 'app/components/Modal';
import IncomeByMatter from './IncomeByMatter';

export default function MatterModal(props) {
  const { show, onClose, isStudent, selectedTab, challenges } = props;

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      contentClassName="simple-modal__content--md simple-modal__content--br-md"
      hideCloseButton
    >
      <IncomeByMatter
        challenges={challenges}
        userId={1}
        title={isStudent || selectedTab === 'resultados' ? 'Rendimento por matéria' : 'Rendimento dos estudantes por matéria nos desafios do professor'}
      />
    </SimpleModal>
  );
}
