import React from 'react';
import CanalEducacaoTerm from './Terms/CanalEducacaoTerm.js';
import DefaultTerm from './Terms/DefaultTerm.js';
import { useEffect } from 'react';

const Terms = props => {
  const { setCurrentPath } = props;

  const appName = process.env.REACT_APP_NAME;

  useEffect(() => {
    setCurrentPath('/termos-de-uso');
  }, []);

  switch (appName) {
    case 'canaleducacao':
    case 'encceja':
    case 'maiseducacao':
    case 'egepi':
      return (
        <div className="terms">
          <CanalEducacaoTerm />
        </div>
      );
      break;

    default:
      return (
        <div className="terms_background">
          <DefaultTerm />
        </div>
      );
  }
};

export default Terms;
