import React, { useEffect } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import LiveClassCard from './LiveClassCard';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import { useState } from 'react';
import { t } from 'i18next';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';

export default function LiveClassesCalendar(props) {
  const client = useClient();
  moment.locale(t('language.language'));
  const { setEditingLiveClass, setShowLiveClassForm, setCurrentLive, filterCourseId, currentLive, showLiveClassForm, weekDays, setWeekDays, setNotAnsweredSurveys } = props;
  const [firstWeekDay, setFirstWeekDay] = useState(moment().startOf('week').subtract(1, 'days'));
  const [lastWeekDay, setLastWeekDay] = useState(moment().endOf('week').subtract(1, 'days'));
  const [loading, setLoading] = useState(true);
  const [filteredWeekDays, setFilteredWeekDays] = useState(weekDays ?? []);
  const currentWeekDay = moment().format('dddd');

  const week = [
    {
      weekDay: t('weekDays.sunday'),
      weekDayNumber: firstWeekDay.format('DD'),
      fullDate: moment(firstWeekDay).format('YYYY-MM-DD')
    },
    {
      weekDay: t('weekDays.monday'),
      weekDayNumber: moment(firstWeekDay).add(1, 'days').format('DD'),
      fullDate: moment(firstWeekDay).add(1, 'days').format('YYYY-MM-DD')
    },
    {
      weekDay: t('weekDays.tuesday'),
      weekDayNumber: moment(firstWeekDay).add(2, 'days').format('DD'),
      fullDate: moment(firstWeekDay).add(2, 'days').format('YYYY-MM-DD')
    },
    {
      weekDay: t('weekDays.wednesday'),
      weekDayNumber: moment(firstWeekDay).add(3, 'days').format('DD'),
      fullDate: moment(firstWeekDay).add(3, 'days').format('YYYY-MM-DD')
    },
    {
      weekDay: t('weekDays.thursday'),
      weekDayNumber: moment(firstWeekDay).add(4, 'days').format('DD'),
      fullDate: moment(firstWeekDay).add(4, 'days').format('YYYY-MM-DD')
    },
    {
      weekDay: t('weekDays.friday'),
      weekDayNumber: moment(firstWeekDay).add(5, 'days').format('DD'),
      fullDate: moment(firstWeekDay).add(5, 'days').format('YYYY-MM-DD')
    },
    {
      weekDay: t('weekDays.saturday'),
      weekDayNumber: lastWeekDay.format('DD'),
      fullDate: moment(lastWeekDay).format('YYYY-MM-DD')
    }
  ];

  const fetchLiveClasses = async () => {
    setLoading(true);
    try {
      const typeDateFormat = 'YYYY-MM-DD';
      const initialDate = moment().startOf('week').subtract(1, 'days').format(typeDateFormat);
      const finalDate = moment().endOf('week').subtract(1, 'days').format(typeDateFormat);
      const response = await client.fetch(`/live_classrooms?filter[date_between][initial]=${initialDate}&filter[date_between][final]=${finalDate}`);

      setNotAnsweredSurveys(response.data.filter(item => !item?.['questions-answered'] && moment(item?.['end-date']).isBefore(moment())));

      setWeekDays(response.data);
      setFilteredWeekDays(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (weekDays.length > 0) {
      setFilteredWeekDays(weekDays);
    }
  }, [weekDays]);

  const handleNextWeek = async () => {
    setLoading(true);

    try {
      const initialDate = moment(firstWeekDay).add(7, 'days');
      const finalDate = moment(lastWeekDay).add(7, 'days');
      setFirstWeekDay(initialDate);
      setLastWeekDay(finalDate);

      const url = `/live_classrooms?filter[date_between][initial]=${initialDate.format('YYYY-MM-DD')}&filter[date_between][final]=${finalDate.format('YYYY-MM-DD')}`;
      const response = await client.fetch(url);

      if (filterCourseId !== 'blank') {
        const filteredWeekByCourseId = weekDays.filter(weekDay => weekDay.courses.some(item => item.id === Number(filterCourseId)));
        setWeekDays(filteredWeekByCourseId);
      } else {
        setWeekDays(response.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handlePrevWeek = async () => {
    setLoading(true);
    try {
      const initialDate = moment(firstWeekDay).subtract(7, 'days');
      const finalDate = moment(lastWeekDay).subtract(7, 'days');
      setFirstWeekDay(initialDate);
      setLastWeekDay(finalDate);

      const url = `/live_classrooms?filter[date_between][initial]=${initialDate.format('YYYY-MM-DD')}&filter[date_between][final]=${finalDate.format('YYYY-MM-DD')}`;
      const response = await client.fetch(url);

      if (filterCourseId !== 'blank') {
        const filteredWeekByCourseId = weekDays.filter(weekDay => weekDay.courses.some(item => item.id === Number(filterCourseId)));
        setWeekDays(filteredWeekByCourseId);
      } else {
        setWeekDays(response.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (weekDays && filterCourseId !== 'blank') {
      const filteredWeekByCourseId = weekDays.filter(weekDay => weekDay.courses.some(item => item.id === Number(filterCourseId)));
      setFilteredWeekDays(filteredWeekByCourseId);
    } else {
      if (currentWeekDay === 'Domingo') {
        handleNextWeek();
      } else {
        fetchLiveClasses();
      }
    }
  }, [filterCourseId, currentWeekDay]);

  return (
    <div className="expanded-calendar">
      <header className="expanded-calendar__header">
        <button
          className="expanded-calendar__btn"
          aria-label="Semana anterior"
          onClick={handlePrevWeek}
        >
          <FiArrowLeft />{' '}
          <span
            className="expanded-calendar__btn-text"
            aria-hidden
          >
            {t('liveClass.lastWeek')}
          </span>
        </button>

        <h2 className="expanded-calendar__selected-week">
          {t('liveClass.weekOf')} {firstWeekDay.format('DD/MM')} - {lastWeekDay.format('DD/MM')}
        </h2>

        <button
          className="expanded-calendar__btn"
          aria-label="próxima semana"
          onClick={handleNextWeek}
        >
          <span
            className="expanded-calendar__btn-text"
            aria-hidden
          >
            {t('liveClass.nextWeek')}
          </span>{' '}
          <FiArrowRight />
        </button>
      </header>

      {loading && <Loader />}
      {!loading && weekDays.length === 0 && (
        <EmptyState
          type="data"
          title="Sem aulas ao vivo nesta semana"
        />
      )}

      {week && weekDays.length > 0 && !loading && (
        <div className="expanded-calendar__body">
          {week.map(day => (
            <div
              key={day.weekDay}
              className={day.weekDay === currentWeekDay ? 'expanded-calendar__day current' : 'expanded-calendar__day'}
            >
              <h3 className="expanded-calendar__day-header">
                <span className="expanded-calendar__day-number">{day.weekDayNumber}</span>
                <span className={day.weekDay === currentWeekDay ? 'expanded-calendar__weekday expanded-calendar__weekday--current' : 'expanded-calendar__weekday'}>{day.weekDay}</span>
              </h3>

              <div className="expanded-calendar__hours-container">
                {filteredWeekDays?.map(
                  liveClass =>
                    moment(liveClass['initial-date']).format('YYYY-MM-DD') === day.fullDate && (
                      <LiveClassCard
                        setWeekDays={setWeekDays}
                        setCurrentLive={setCurrentLive}
                        currentLive={currentLive}
                        key={liveClass.id}
                        liveClass={liveClass}
                        setEditingLiveClass={setEditingLiveClass}
                        setShowLiveClassForm={setShowLiveClassForm}
                        showLiveClassForm={showLiveClassForm}
                      />
                    )
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
