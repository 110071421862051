import React, { useEffect, useState } from 'react';
import Modal from 'app/components/Modal';
import SchoolCard from './SchoolCard';
import { useAuditorium } from 'app/hooks/useAuditorium';
import { t } from 'i18next';

export default function SchoolModal(props) {
  const { show, onClose, form, courses, addCourse, removeCourse, selectedCourses } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [filteredCourses, setFilteredCourses] = useState([]);

  useEffect(() => {
    if (show) {
      setSearchTerm('');
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [show]);

  useEffect(() => {
    const filterCourses = courses => {
      let filterCourses = courses;

      if (searchTerm !== '') {
        filterCourses = filterCourses.filter(a => a.title != null && a.title.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
      }

      return filterCourses;
    };

    const newFilteredCourses = filterCourses(courses);
    setFilteredCourses(newFilteredCourses);
  }, [searchTerm, courses]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <h3 className="modal__simple-title">{form.values.name || t('auditorium.newAuditorium')}</h3>

      <p className="modal__text">{t('auditorium.modalTextsAddCourses')}</p>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="search"
        >
          {t('textsCommon.research')}
        </label>

        <input
          className="form__control form__control--search-with-icon"
          placeholder={t('filter.searchHere')}
          type="search"
          value={searchTerm}
          name="search"
          id="search"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <hr className="u-hr u-mt-0 u-mb-3" />

      <div
        className="round-dg-wrapper"
        style={{ height: '200px', flexGrow: '1' }}
      >
        <table className="round-dg round-dg--light">
          <tbody className="round-dg__body">
            {filteredCourses.map((course, index) => {
              const selectedItem = selectedCourses?.find(item => item.id === course.id);
              return (
                <SchoolCard
                  addCourse={addCourse}
                  removeCourse={removeCourse}
                  key={index}
                  course={course}
                  isSelected={!!selectedItem}
                  show={show}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
