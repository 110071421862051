import React, { useEffect, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import StepName from './StepName';
import StepAbout from './StepAbout';
import StepImage from './StepImage/ImageStep';
import TipsSidebar from './TipsSidebar';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import * as yup from 'yup';
import { t } from 'i18next';
import promiseRequest from 'app/utils/promiseToast';

export default function CourseContent(props) {
  const { step, steps, currentStep, setCurrentStep } = props;
  const [imageLabel, setImageLabel] = useState();
  const [file, setFile] = useState();
  const client = useClient();
  const courseId = useParams().id;
  const [course, setCourse] = useState([]);
  const { promiseToast, isSubmitting } = promiseRequest();

  const courseForm = useFormik({
    initialValues: {
      title: '',
      about: '',
      selectedTaxonomies: [],
      image: '',
      'finished-at': '',
      'official-content': false,
      'authored-by-user': false,
      'is-public': false,
      'ai-options': { 'study-guide': false, mindmaps: false, quizzes: false }
    },
    validationSchema: yup.object({
      title: yup.string().required(t('warning.requiredField')),
      about: yup.string().required(t('warning.requiredField')),
      selectedTaxonomies: yup.array().min(1, t('warning.selectTaxonomy')).required(t('warning.requiredField')),
      'finished-at': yup.string().required(t('warning.requiredField'))
    }),
    onSubmit: () => handleSubmit
  });

  const getCourse = async () => {
    const { data, error } = await client.fetch(`/courses/${courseId}`);
    if (error) {
      toast.error('Erro ao carregar curso');
    } else {
      setCourse(data);
    }
  };

  useEffect(() => {
    if (courseId) {
      getCourse();
    }
  }, []);

  useEffect(() => {
    if (course) {
      courseForm.setValues({
        title: course?.title,
        about: course?.about,
        selectedTaxonomies: course?.taxonomies,
        image: course?.image?.url,
        'finished-at': course?.['finished-at'],
        'official-content': course?.['official-content'],
        'authored-by-user': course?.['authored-by-user'],
        'is-public': course?.['is-public']
      });

      courseForm.setFieldValue('ai-options', { 'study-guide': course?.['ai-options']?.['study-guide'] || false, mindmaps: course?.['ai-options']?.mindmaps || false, quizzes: course?.['ai-options']?.quizzes || false });
    }
  }, [course]);

  const createCourse = async () => {
    const path = courseId ? `/courses/${courseId}` : '/courses';
    const auxValues = { ...courseForm.values };
    auxValues['taxonomy-ids'] = auxValues?.selectedTaxonomies?.map(t => t.id);
    delete auxValues.selectedTaxonomies;

    promiseToast({
      url: path,
      request: auxValues,
      successText: courseId ? 'Curso editado com sucesso!' : 'Curso criado com sucesso!',
      errorText: 'Erro ao criar curso'
    }).then(data => navigate(`/curso/admin/${data.id}`));
  };

  const handleSubmit = e => {
    e.preventDefault();
    switch (step) {
      case 'sobre':
        if (courseId) {
          courseForm.setFieldTouched('title', true);
          courseForm.setFieldTouched('about', true);
          courseForm.setFieldTouched('selectedTaxonomies', true);
          courseForm.setFieldTouched('finished-at', true);
          courseForm.setFieldTouched('official-content', true);
          courseForm.setFieldTouched('authored-by-user', true);
          if (!courseForm.errors.title && !courseForm.errors.about && !courseForm.errors.selectedTaxonomies && !courseForm.errors['finished-at']) {
            navigate(`/curso/criar/imagem/${courseId}`);
            setCurrentStep(2);
          }
          break;
        } else {
          courseForm.setFieldTouched('title', true);
          courseForm.setFieldTouched('about', true);
          courseForm.setFieldTouched('', true);
          courseForm.setFieldTouched('selectedTaxonomies', true);
          courseForm.setFieldTouched('finished-at', true);
          courseForm.setFieldTouched('official-content', true);
          courseForm.setFieldTouched('authored-by-user', true);
          if (!courseForm.errors.title && !courseForm.errors.about && !courseForm.errors.selectedTaxonomies && !courseForm.errors['finished-at']) {
            navigate(`/curso/criar/imagem`);
            setCurrentStep(2);
          }
        }
        break;
      case 'imagem':
        courseForm.setFieldTouched('image', true);
        createCourse();
        break;
    }
  };

  const handleChangeImage = e => {
    let file = e.target.files[0];
    setImageLabel(e.target.files[0]?.name);
    setFile(URL.createObjectURL(e.target.files[0]));

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = readerEvt => {
    let bynaryString = readerEvt.target.result;
    courseForm.setFieldValue('image', `data:image/jpeg;base64,${btoa(bynaryString)}`);
  };

  const btnsStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  };

  return (
    <div className="page page--wrap">
      <div className="page__col">
        <form
          className="form"
          method="post"
          onSubmit={handleSubmit}
        >
          <StepAbout
            step={step}
            form={courseForm}
            creatorId={course['creator-id']}
            isEditing={courseId}
          />
          <StepImage
            courseId={courseId}
            file={file}
            handleChangeImage={handleChangeImage}
            step={step}
            form={courseForm}
          />

          <div style={btnsStyles}>
            {step !== 'nome' && (
              <button
                type="button"
                className="btn btn--outline btn--wide"
                onClick={() => {
                  navigate(courseId ? `/curso/criar/${steps?.[currentStep - 1]?.route}/${courseId}` : `/curso/criar/${steps?.[currentStep - 1]?.route}`);
                  setCurrentStep(currentStep - 1);
                }}
              >
                {t('button.previous')}
              </button>
            )}

            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn--primary btn--wide"
            >
              {step !== 'imagem' ? t('button.next') : t('button.finish')}
            </button>
          </div>
        </form>
      </div>
      <TipsSidebar
        step={step}
        form={courseForm}
      />
    </div>
  );
}
