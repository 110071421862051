import React, { useMemo } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useFormik } from 'formik';
import Dropdown from 'app/components/Dropdown';
import Medal from 'app/components/Medal';
import { PiStudent } from 'react-icons/pi';
import toast from 'react-hot-toast';

export default function ChallengeRow(props) {
  const { handleDeleteChallenge, index, total, challenge, handlePublishChallenge, badgeColor } = props;

  const challengeForm = useFormik({
    initialValues: {
      published: challenge.published
    }
  });

  const handleChangePublishedChallenge = async () => {
    const response = await handlePublishChallenge(challenge.id, !challengeForm.values.published);
    if (response.error) {
      toast.error('Erro ao publicar desafio');
    } else if (challengeForm.values.published) {
      challengeForm.setFieldValue('published', !challengeForm.values.published);
      toast.success('Desafio despublicado');
    } else {
      challengeForm.setFieldValue('published', !challengeForm.values.published);
      toast.success('Desafio publicado');
    }
  };

  const availabilityBody = useMemo(() => {
    let availability = [];
    if (challenge.classrooms?.length > 0) {
      availability = challenge.classrooms;
    } else if (challenge.courses?.length > 0) {
      availability = challenge.courses;
    } else if (challenge.institutions?.length > 0) {
      availability = challenge.institutions;
    }

    return availability;
  }, [challenge]);

  return (
    <tr className={total === index + 1 ? 'round-dg__row round-dg__row--last' : 'round-dg__row'}>
      <td className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
        <div className="round-dg__user">
          <div className="round-dg__user-inner">
            <h3
              className="round-dg__title"
              title={challenge.title}
            >
              {challenge.title}
            </h3>
          </div>
        </div>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <span
          className="badge badge--tiny badge--primary"
          title={challenge.taxonomy.name}
        >
          {challenge.taxonomy.name}
        </span>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <span
          className="u-text-truncate"
          title={'Maria da Silva'}
        >
          {challenge.user.name}
        </span>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        {challenge['initial-date'].toLocaleDateString('pt-br', { day: 'numeric' })} a {challenge['end-date'].toLocaleDateString('pt-br')}
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <div
          className="card__badges card__badges--one-line u-mb-0"
          style={{ lineHeight: 1, alignItems: 'center' }}
        >
          {availabilityBody.length > 1 ? (
            <>
              <span
                className="u-text-truncate"
                title={availabilityBody[0].name ?? availabilityBody[0].title}
              >
                {availabilityBody[0].name ?? availabilityBody[0].title}
              </span>
              <span
                className="badge badge--tiny badge--more"
                title={availabilityBody?.slice(1).map(item => item.name ?? item.title)}
              >
                +{availabilityBody?.slice(1).length}
              </span>
            </>
          ) : availabilityBody.length === 1 ? (
            <span
              className="u-text-truncate"
              title={availabilityBody[0].name ?? availabilityBody[0].title}
            >
              {availabilityBody[0].name ?? availabilityBody[0].title}
            </span>
          ) : (
            <span
              className="u-text-truncate"
              title={'Público'}
            >
              Público
            </span>
          )}
        </div>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <Medal value={<PiStudent />} />
          <span
            className="u-text-truncate"
            title={`${challenge?.['participation-count'] ?? '-'} (${challenge?.['participants-percentage'] ?? '-'}%)`}
          >
            {challenge?.['participation-count'] ?? '-'} ({challenge?.['participants-percentage'] ?? '-'}%)
          </span>
        </span>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <span
          className={`badge badge--tiny badge--${badgeColor(challenge)}`}
          title={challenge.status?.charAt(0).toUpperCase() + challenge.status?.slice(1)}
        >
          {challenge.status?.charAt(0).toUpperCase() + challenge.status?.slice(1)}
        </span>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <div className="form__check form__switch u-mb-0">
          <input
            className="form__check-input"
            id={`published-${index}`}
            name={`published-${index}`}
            type="checkbox"
            defaultChecked={challengeForm.values.published}
            onClick={handleChangePublishedChallenge}
          />
          <label htmlFor={`published-${index}`}>Publicar</label>
        </div>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <Dropdown>
          <Dropdown.Toggle>
            <FiMoreHorizontal />
          </Dropdown.Toggle>

          <Dropdown.Content>
            <Dropdown.Item to={`/desafio-da-semana/editar/${challenge.id}`}>Editar</Dropdown.Item>
            <Dropdown.Item onClick={() => handleDeleteChallenge(challenge.id)}>Excluir</Dropdown.Item>
            {/* TODO: <Dropdown.Item>Ver resultados</Dropdown.Item> */}
          </Dropdown.Content>
        </Dropdown>
      </td>
    </tr>
  );
}
