import React, { useContext, useState } from 'react';
import { useClient } from 'jsonapi-react';
import { useFormik } from 'formik';
import TextareaAutosize from 'react-autosize-textarea';
import { useSession } from 'app/hooks/useSession';
import { ProjectsContext } from './provider';
import { useTranslation } from 'react-i18next';

function EventForm(props) {
  const { t } = useTranslation();
  const { editingEvent, selectedProject, onClose } = props;
  const { updateEvent, createEvent } = useContext(ProjectsContext);
  const { session } = useSession();

  const [imageLabel, setImageLabel] = useState();

  let hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(`${String(i).padStart(2, '0')}:00`);
  }

  const form =
    editingEvent?.['project-id'] != null
      ? useFormik({
          initialValues: {
            title: editingEvent?.title || '',
            description: editingEvent?.description || '',
            image: '',
            url: editingEvent?.url || '',
            'initial-date': editingEvent ? editingEvent['initial-date'] : '',
            'initial-time': editingEvent ? editingEvent['initial-time'] : '',
            'end-time': editingEvent ? editingEvent['end-time'] : '',
            course_id: editingEvent ? editingEvent['course-id'] : null,
            classroom_id: editingEvent ? editingEvent['classroom-id'] : null,
            'project-id': selectedProject ? selectedProject.id : null
          },
          onSubmit: values => {
            updateEvent({ ...values });
          }
        })
      : useFormik({
          initialValues: {
            title: '',
            description: '',
            image: '',
            url: '',
            'initial-date': '',
            'initial-time': '',
            'end-time': '',
            'classroom-id': [],
            'course-id': [],
            'project-id': selectedProject?.id
          },
          onSubmit: values => {
            createEvent({ ...values });
            onClose;
          }
        });

  const handleChangeImage = e => {
    let file = e.target.files[0];
    setImageLabel(e.target.files[0].name);

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = readerEvt => {
    let bynaryString = readerEvt.target.result;
    form.setFieldValue('image', `data:image/jpeg;base64,${btoa(bynaryString)}`);
  };

  return (
    <form
      action="post"
      className="form"
      onSubmit={form.handleSubmit}
    >
      <h2 className="modal__simple-title">{t('events.titleCreateNewEvent')}</h2>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="title"
        >
           {t('events.eventTitle')}
        </label>
        <input
          className="form__control"
          id="title"
          name="title"
          type="text"
          placeholder={t('events.placeholderEventDescription')}
          onChange={form.handleChange}
          defaultValue={form.values.title}
        />
      </div>

      <div className="form__row form__row--columns">
        <div className="form__col">
          <label
            className="form__label"
            htmlFor="initial-date"
          >
            Data
          </label>
          <input
            className="form__control"
            id="initial-date"
            name="initial-date"
            type="date"
            onChange={form.handleChange}
            defaultValue={form.values['initial-date']}
          />
        </div>

        <div className="form__col">
          <legend className="form__label">Horário</legend>

          <div className="form__columns">
            <div className="form__col">
              <select
                className="form__select"
                name="initial-time"
                id="initial-time"
                aria-label="Hora início"
                onChange={form.handleChange}
                defaultValue={form.values['initial-time']}
              >
                {hours.map(i => (
                  <option
                    key={i}
                    value={i}
                  >
                    {i}
                  </option>
                ))}
              </select>
            </div>

            <div className="form__col">
              <select
                className="form__select"
                name="end-time"
                id="end-time"
                aria-label="Hora fim"
                onChange={form.handleChange}
                defaultValue={form.values['end-time']}
              >
                {hours.map(i => (
                  <option
                    key={i}
                    value={i}
                  >
                    {i}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="description"
        >
          {t("tasks.description")}
        </label>

        <TextareaAutosize
          id="description"
          name="description"
          placeholder={t('events.placeholderAboutEvent')}
          onChange={form.handleChange}
          defaultValue={form.values.description}
        />
      </div>

      <fieldset className="form__row">
        <legend className="form__label">{t('textsCommon.image')} (1050 x 360 pixels)</legend>

        <label className="form__custom-file-input">
          <input
            type="file"
            name="image"
            id="image"
            accept=".jpeg, .png, .jpg"
            onChange={handleChangeImage}
            defaultValue={form.values.image}
          />
          <span className="form__control">{imageLabel ? imageLabel : t('textsCommon.placeholderUploadImage')}</span>
        </label>
      </fieldset>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="url"
        >
          URL
        </label>
        <input
          className="form__control"
          id="url"
          name="url"
          type="url"
          placeholder={t('textsCommon.linkToMeeting')}
          onChange={form.handleChange}
          defaultValue={form.values.url}
        />
      </div>

      <button
        type="submit"
        className="btn btn--primary"
      >
        {t('button.saveContinue')}
      </button>
    </form>
  );
}

export default EventForm;
