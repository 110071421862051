import React, { useState, useEffect, useRef } from 'react';
import Vimeo from '@u-wave/react-vimeo';

export default function VimeoPlayerStudyGuide(props) {
  const {videoId } = props;
  const [finished, setFinished] = useState(false);
  const [currentPercent, setCurrentPercent] = useState();
  const [count, setCount] = useState(0);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    setFinished(false);
  }, [videoId]);


  const onReady = player => {
    setPlayer(player);
  };

  const handlePlay = () => {
    setTimeout(() => setCount(0), 2000);
  };


  return (
    <Vimeo
      onReady={onReady}
      onPlay={handlePlay}
      video={videoId}
      autoplay
      responsive
    />
  );
}
