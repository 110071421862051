import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { t } from 'i18next';

export default function ActivityDataPieChart(props) {
  const { activityReport } = props;
  const [series, setSeries] = useState([]);
  useEffect(() => {
    if(activityReport) {
      const newData = activityReport.comparativeInfo.map((e) => {
        return {name: e.course, y: e.activitiesSend, percentage: e.activitiesSendPercent}
      });
      setSeries(newData)
    }
  }, [activityReport]);


  const pieColors = (function () {
    let colors = [],
      base = '#442b8c',
      i;

    for (i = 0; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      colors.push(
        Highcharts.color(base)
          .brighten(i / 7)
          .get(),
      );
    }
    return colors;
  })();

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'column',
    },
    credits: { enabled: false },
    title: { text: null },
    legend: {
      enabled: false
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      //column: { stacking:'percent', pointPadding: 0.2, borderWidth: 0 }
    },
    xAxis: {
        type: 'category'
    },
    yAxis: {
      title: {
        text: 'Quantidade de respostas'
      }
    },
    series: [
      {
        name: 'Respostas',
        innerSize: '40%',
        colorByPoint: true,
        data: series
      },
    ],
  };

  return (
    (activityReport && activityReport.comparativeInfo.every((e) => e.activitiesSend === 0)) ? (
      <p className="new-questions__empty-state-text">
        <br /><br /><br /><br /><br />
        {t('activity.emptyInfo')}
      </p>
    ) : (
      <HighchartsReact highcharts={Highcharts} options={options} />
    )
  )
}
