import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

export default function ChallengeHeader(props) {
  const { isStudent, selectedTab, setParam, setOrderByAccuracy, setOrderByPeriod, orderByAccuracy, orderByPeriod } = props;

  return (
    <div
      className="round-dg-wrapper u-mb-2"
      style={{ width: '100%' }}
    >
      <table style={{ width: '100%', minWidth: 364 }}>
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th className="round-dg__cell-header round-dg__cell-header--sm">Desafio</th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 160 }}
            >
              Tema
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 140 }}
            >
              Período{' '}
              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                {orderByPeriod === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setParam('period');
                      setOrderByPeriod('ascendent');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('period');
                      setOrderByPeriod('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span>
            </th>
            {!isStudent && selectedTab == 'ranking-por-participante' && (
              <th
                className="round-dg__cell-header round-dg__cell-header--sm"
                style={{ width: 140 }}
              >
                Disponibilidade
              </th>
            )}
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 164 }}
            >
              Índice de acerto{' '}
              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                {orderByAccuracy === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setParam('accuracy');
                      setOrderByAccuracy('ascendent');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('accuracy');
                      setOrderByAccuracy('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span>
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 66 }}
            ></th>
          </tr>
        </thead>
      </table>
    </div>
  );
}
