import React, { useState, createContext } from 'react';
import moment from 'moment';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import api from 'app/services/api';

export const MetricsContext = createContext();

export const MetricsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [metricsData, setMetricsData] = useState([]);

  const client = useClient();

  const getMetricsData = async filters => {
    setLoading(true);

    let url = 'metrics?filter';

    if (filters) {
      if (filters.course !== 'blank') {
        url += `[course_id]=${filters.course}&`;
      }

      if (filters.classroom !== 'blank') {
        url += `[classroom_id]=${filters.classroom}&`;
      }
    }
    const { data, error } = await client.fetch(url);

    if (error) {
      toast.error('Erro ao buscar métricas.');
    } else {
      setMetricsData(data);
      setLoading(false);
    }
  };

  const getTaxonomyStudentScore = async (id, rangeDates) => {
    setLoading(true);
    const formattedStartDate = moment(rangeDates[0]).format('YYYY-MM-DD');
    const formattedEndDate = moment(rangeDates[1]).format('YYYY-MM-DD');

    api
      .get(
        `reports/journey?initial_date=${formattedStartDate}&end_date=${formattedEndDate}`,
      )
      .then(response => {
        setDateCategories(response.date_categories);
        setDificultyCategories(response.difficulty_categories);
        setTaxonomiesReport(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <MetricsContext.Provider
      value={{
        loading,
        getMetricsData,
        metricsData,
        getTaxonomyStudentScore,
      }}
    >
      {children}
    </MetricsContext.Provider>
  );
};
