import React, { useState, useEffect } from 'react';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import * as Fi from 'react-icons/fi';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { useParams } from '@reach/router';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import moreMedal from 'app/images/more-medal.svg';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import EmptyState from 'app/components/EmptyState';

export default function userMedalView(props) {
  const { t } = useTranslation();
  const [medals, setMedals] = useState([]);
  const [medalsLoading, setMedalsLoading] = useState(true);

  const [searchTerm, setSearchTerm] = useState('');
  const [relationships, setRelationships] = useState({
    courses: []
  });
  const [filteredMedals, setFilteredMedals] = useState([]);

  const filters = useFormik({
    initialValues: {
      course: 'blank'
    }
  });

  useEffect(() => {
    setFilteredMedals(
      medals.filter(item => {
        if (filters.values.course === 'blank') {
          return true;
        } else {
          return item['course-id'] == filters.values.course;
        }
      })
    );
  }, [filters.values.course]);

  const client = useClient();
  const routeProps = useParams();

  const authorityLevel = getAuthorityLevel();

  const getMedals = async () => {
    setMedalsLoading(true);
    if (authorityLevel === 'student') {
      const { data, error } = await client.fetch('medals');
      if (error) {
        toast.error(t('toast.errorMedals'));
      } else {
        setMedals(data);
        setFilteredMedals(data);
        setMedalsLoading(false);
      }
    } else {
      const { data, error } = await client.fetch(`/medals?filter[user_id]=${routeProps.id}`);
      if (error) {
        toast.error(t('toast.errorMedals'));
      } else {
        setMedals(data);
        setFilteredMedals(data);
        setMedalsLoading(false);
      }
    }
  };

  useEffect(() => {
    setFilteredMedals(
      medals.filter(medal => {
        if (searchTerm == '') {
          return medal;
        }
        if (medal['learning-system']?.title != null && medal['learning-system']?.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
          return medal;
        }
      })
    );
  }, [searchTerm]);

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses');

    setRelationships({
      courses: courses || []
    });
  };

  useEffect(() => {
    getRelationships();
    getMedals();
  }, []);

  function getIcon(icon) {
    switch (icon) {
      case 'FiThumbsUp':
        return <Fi.FiThumbsUp className="card__icon" />;
      case 'FiMessageCircle':
        return <Fi.FiMessageCircle className="card__icon" />;
      case 'FiFolder':
        return <Fi.FiFolder className="card__icon" />;
      case 'FiVideo':
        return <Fi.FiVideo className="card__icon" />;
      case '"FiCheckCircle"':
        return <Fi.FiCheckCircle className="card__icon" />;
      case 'FiMousePointer':
        return <Fi.FiMousePointer className="card__icon" />;
    }
  }

  return (
    <>
      <div className="filter-bar filter-bar--left">
        <FilterSelectionBox
          label={t('filter.labelSelectCourse')}
          value={filters.values.course}
          onChange={e => filters.setFieldValue('course', e.target.value)}
          options={relationships.courses.map(o => ({
            id: o.id,
            name: o.title
          }))}
        />

        <div className="filter-bar__row">
          <label
            htmlFor="search"
            className="filter-bar__label"
          >
            {t('textsCommon.research')}
          </label>

          <input
            aria-label="Buscar estudante"
            className="form__control form__control--search-with-icon"
            style={{ width: 'auto' }}
            placeholder={t('textsCommon.research')}
            type="search"
            name="search"
            id="search"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="tab__cards">
        {filteredMedals.length === 0 && <EmptyState type="data" />}
        {filteredMedals.map((item, i) => (
          <div
            className="card"
            key={i}
          >
            <div className="medal medal--sm u-mb-3">
              <img
                src={moreMedal}
                alt="nova medalha"
                className="medal__img"
              />
              <span className="medal__value">+1</span>
            </div>

            <div className="card__header">
              <div className="card__inner-header">
                <h2
                  className="card__title"
                  title={item?.['learning-system']?.title}
                >
                  {item?.['learning-system']?.title}
                </h2>
              </div>
              <p
                className="card__subtitle"
                title={item?.['learning-system']?.course.title}
              >
                {item?.['learning-system']?.course.title}
              </p>
              <time className="card__subtitle">
                {t('journey.conclusion')} {item?.['created-at']}
              </time>
            </div>

            <div className="card__body">
              <div className="card__columns card__columns--bg">
                {item.info.map(item => {
                  return (
                    <div className="card__count">
                      <span
                        className="card__count-label"
                        title={item.label}
                      >
                        {item.label}
                      </span>
                      {getIcon(item.icon)} {item.count}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
