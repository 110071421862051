import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import { Link } from '@reach/router';
import Chart from '../Admin/Chart';
import InfoWidget from '../../components/Widget/InfoWidget';
import ChartWidget from '../../components/Widget/ChartWidget';
import Loader from '../../components/loader';
import { useProgramOfStudies } from '../../hooks/useProgramOfStudies';
import { TabNavbar } from './TabNavbar';
import { t } from 'i18next';

export const QuestionsDatabase = () => {
  const client = useClient();
  const { firstLevelTaxonomies, selectedTaxonomy, changeSelectedTaxonomy, fetchTaxonomies, selectingTaxonomy, loading } = useProgramOfStudies();
  const [dashboardData, setDashboardData] = useState();
  const [loadingDashboard, setLoadingDashboard] = useState(true);

  const fetchDashboard = async () => {
    try {
      if (selectedTaxonomy) {
        const { data } = await client.fetch(`reports/dashboard_questions/${selectedTaxonomy.id}`);
        setDashboardData(data);
        setLoadingDashboard(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchDashboard();
  }, [selectedTaxonomy]);

  useEffect(() => fetchTaxonomies(), []);

  return (
    <>
      <main className="main-content main-content--block">
        <TabNavbar selectedTab="banco-de-questoes" />

        <div className="tab__pane">
          <div className="page page--wrap">
            <div className="page__aside">
              {selectingTaxonomy ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  to="#"
                  className="btn btn--outline btn--full u-mb-4"
                  style={{ borderColor: 'lightGray', color: 'gray' }}
                >
                  Gerenciar banco de questões
                </Link>
              ) : (
                <Link
                  to="/ementa/questoes-publicas"
                  className="btn btn--outline btn--full u-mb-4"
                >
                  Gerenciar banco de questões
                </Link>
              )}

              {loading ? (
                <Loader />
              ) : (
                <div className="taxonomies__list">
                  {firstLevelTaxonomies &&
                    selectedTaxonomy &&
                    firstLevelTaxonomies.map((taxonomy, index) => (
                      <div
                        key={index}
                        className={`taxonomies__item ${taxonomy.id === selectedTaxonomy.id ? 'active' : ''}`}
                        onClick={() => changeSelectedTaxonomy(taxonomy)}
                      >
                        <span className="taxonomies__text">{taxonomy.name}</span>
                      </div>
                    ))}
                </div>
              )}
            </div>

            <div className="page__content">
              {loadingDashboard && <Loader />}
              {dashboardData && (
                <>
                  <h3 className="page__title">{selectedTaxonomy.name}</h3>

                  <div className="widget-wrapper widget-wrapper--sm-space">
                    <InfoWidget
                      title="Índice de Acerto"
                      className="info-widget--light"
                    >
                      <InfoWidget.Value>{dashboardData.correctAnswerPercent}%</InfoWidget.Value>
                    </InfoWidget>

                    <InfoWidget
                      title={t('nps.totalResponses')}
                      className="info-widget--light"
                    >
                      <InfoWidget.Value>{dashboardData.countAnswer}</InfoWidget.Value>
                    </InfoWidget>

                    <InfoWidget
                      title={t('nps.totalQuestions')}
                      className="info-widget--light"
                    >
                      <InfoWidget.Value>{dashboardData.countQuestion}</InfoWidget.Value>
                    </InfoWidget>
                  </div>

                  <div className="widget-wrapper widget-wrapper--sm-space">
                    <InfoWidget
                      title="Respostas Fáceis"
                      className="info-widget--success"
                    >
                      <InfoWidget.Value>
                        {dashboardData.difficultyAnswers.easy.count} • {dashboardData.difficultyAnswers.easy.percent}%
                      </InfoWidget.Value>
                    </InfoWidget>

                    <InfoWidget
                      title="Respostas Médias"
                      className="info-widget--info"
                    >
                      <InfoWidget.Value>
                        {dashboardData.difficultyAnswers.mean.count} • {dashboardData.difficultyAnswers.mean.percent}%
                      </InfoWidget.Value>
                    </InfoWidget>

                    <InfoWidget
                      title="Respostas difíceis"
                      className="info-widget--danger"
                    >
                      <InfoWidget.Value>
                        {dashboardData.difficultyAnswers.hard.count} • {dashboardData.difficultyAnswers.hard.percent}%
                      </InfoWidget.Value>
                    </InfoWidget>
                  </div>

                  <div className="widget-wrapper widget-wrapper--chart">
                    <ChartWidget title="Rendimento dos alunos ao longo do tempo">
                      {dashboardData && (
                        <Chart
                          name="Rendimento"
                          color="#FDB462"
                          chartData={dashboardData.studentPerformance}
                          valueSuffix="%"
                        />
                      )}
                    </ChartWidget>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
