import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { FiChevronLeft, FiHelpCircle } from 'react-icons/fi';
import { useFormik } from 'formik';
import Loader from 'app/components/loader';
import ReportForm from 'app/components/ReportForm';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { AiOutlineTrophy } from 'react-icons/ai';
import { t } from 'i18next';

export default function SeducPiGeral() {
  const client = useClient();
  const [data, setData] = useState([]);
  const [fristItem, setFristItem] = useState(true);
  const [lastItem, setLastItem] = useState(true);
  const [openQuestionBooks, setOpenQuestionBooks] = useState([]);
  const [loading, setLoading] = useState(false);

  const filter = useFormik({
    initialValues: {
      openQuestionBook: 'blank'
    }
  });

  const getOpenQuestionBooks = async () => {
    const { data, error } = await client.fetch(`/question_books?filter[visibility]=open`);
    if (error) {
      toast.error('Falha ao carregar simulados');
    } else {
      setOpenQuestionBooks(data);
    }
  };

  const getData = async () => {
    setLoading(true);
    let url = `reports/user_registrations`;
    if (filter.values.openQuestionBook !== 'blank') {
      url = `reports/user_registrations?question_book_id=${filter.values.openQuestionBook}`;
    }
    const { data, error } = await client.fetch(url);

    if (error) {
      toast.error('Falha ao carregar dados');
    } else {
      setData(data);
      setFristItem(data.items[0]);
      setLastItem(data.items[data.items.length - 1]);
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    await Promise.all([getData(), getOpenQuestionBooks()]);
    setLoading(false);
  };

  const getTrophyIcon = index => {
    switch (index) {
      case 0:
        return <AiOutlineTrophy className="card__icon card__icon--golden" />;
      case 1:
        return <AiOutlineTrophy className="card__icon card__icon--silver" />;
      case 2:
        return <AiOutlineTrophy className="card__icon card__icon--bronze" />;
      default:
        return null;
    }
  };

  const getQuestionBookName = () => {
    if(filter.values.openQuestionBook !== 'blank') {
      return openQuestionBooks.find(item => item.id === parseInt(filter.values.openQuestionBook)).title
    }  
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getData();
  }, [filter.values.openQuestionBook]);

  useEffect(() => {}, [openQuestionBooks]);

  const kpis = [
    {
      id: 1,
      variation: 'report-kpi-card--check',
      title: `Total de estudantes cadastrados ${filter.values.openQuestionBook !== 'blank' ? getQuestionBookName() : ''}`,
      value: data.totalStudentsCount
    },
    {
      id: 2,
      variation: 'report-kpi-card--user',
      title: `Maior número de cadastros ${filter.values.openQuestionBook !== 'blank' ? getQuestionBookName() : ''}`,
      value: `${fristItem?.name} - ${fristItem?.studentsCount} cadastros`
    },
    {
      id: 3,
      variation: 'report-kpi-card--user',
      title: `Menor número de cadastros ${filter.values.openQuestionBook !== 'blank' ? getQuestionBookName() : ''}`,
      value: `${lastItem.name} - ${lastItem.studentsCount} cadastros`
    },
    {
      id: 4,
      variation: '',
      title: `Média geral ${filter.values.openQuestionBook !== 'blank' ? getQuestionBookName() : ''}`,
      value: data?.totalAverage?.toFixed(2)
    }
  ];

  return (
    <main className="report">
      <header className="report__header">
        <h1 className="report__title">
          <button
            className="card__icon-wrapper"
            aria-label="Voltar"
            title="Voltar"
            onClick={() => navigate(-1)}
          >
            <FiChevronLeft className="card__icon" />
          </button>
          Simulados
        </h1>
        <p className="report__description">Acompanhamento de simulados</p>
      </header>

      <div className="report-tab">
        <button className="report-tab__link report-tab__link--is-active">Geral</button>
      </div>

      <div className="report__body">
        <div className="report-filter">
          <ReportForm.Select
            label="Avaliação"
            title="Avaliação"
            blankLabel={'Selecione um simulado'}
            value={filter.values.openQuestionBook}
            onChange={e => filter.setFieldValue('openQuestionBook', e.target.value)}
            options={openQuestionBooks?.map(item => ({ id: item.id, name: item.title }))}
          />
        </div>

        <div className="report-widget">
          <div className="report-widget__header">
            <h3 className="report-widget__title">Visão geral</h3>
          </div>

          <div className="report-widget__body">
            <div className="report-kpi-card-wrapper">
              {kpis.map(kpi => (
                <div
                  className={`report-kpi-card ${kpi.variation}`}
                  key={kpi.id}
                >
                  {kpi.help && (
                    <>
                      <FiHelpCircle
                        className="report-kpi-card__help-icon"
                        data-tooltip-id={kpi.id}
                        data-tooltip-content={kpi.help}
                      />
                      <ReactTooltip
                        id={kpi.id}
                        className="tooltip"
                      />
                    </>
                  )}
                  <h3 className="report-kpi-card__title">{kpi.title}</h3>
                  <p
                    className="report-kpi-card__value"
                    style={{ fontSize: '16px' }}
                    title={kpi.value}
                  >
                    {loading ? t('loader.Loading') : kpi.value} {kpi.percentage ? <span>({kpi.percentage}%)</span> : null}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="report-widget">
          <div className="report-widget__header">
            <h3 className="report-widget__title">Ranking {filter.values.openQuestionBook !== 'blank' ? (`das GRES ${openQuestionBooks.find(item => item.id === parseInt(filter.values.openQuestionBook)).title}`) : 'geral das GRES'}</h3>
          </div>

          <div className="report-widget__body">
            <div className="round-dg-wrapper">
              <table
                className="round-dg round-dg--light"
                style={{ minWidth: '720px' }}
              >
                <thead className="round-dg__header">
                  <tr className="round-dg__row">
                    <th
                      className="round-dg__cell-header round-dg__cell-header--sm"
                      style={{ width: 80 }}
                    >
                      Posição
                    </th>
                    <th className="round-dg__cell-header round-dg__cell-header--sm">GRE</th>
                    <th
                      className="round-dg__cell-header round-dg__cell-header--sm"
                      style={{ width: 60 }}
                    />
                    <th
                      className="round-dg__cell-header round-dg__cell-header--sm"
                      style={{ width: 120 }}
                    >
                      Nota média
                    </th>
                    <th
                      className="round-dg__cell-header round-dg__cell-header--sm"
                      style={{ width: 100 }}
                    >
                      Cadastros
                    </th>
                    <th
                      className="round-dg__cell-header round-dg__cell-header--sm"
                      style={{ width: 144 }}
                    >
                      % do total de cadastros
                    </th>
                  </tr>
                </thead>

                <tbody className="round-dg__body">
                  {!loading &&
                    (data.items || []).map((item, index) => (
                      <tr
                        className="round-dg__row u-cursor-pointer"
                        onClick={() => navigate(`/relatorio/seduc-pi/geral/gre/${item.id}${filter.values.openQuestionBook !== 'blank' ? `/${filter.values.openQuestionBook}` : ''}`, {state : { regionalName: item.name, questionBookName: getQuestionBookName()}})}
                        key={index}
                      >
                        <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{index + 1}</th>
                        <td className="round-dg__cell round-dg__cell--sm">
                          <span
                            className="u-text-truncate"
                            title={item.name}
                          >
                            {item.name}
                          </span>
                        </td>
                        <td className="round-dg__cell round-dg__cell--sm">{getTrophyIcon(index)}</td>
                        <td className="round-dg__cell round-dg__cell--sm">{item.average.toFixed(2)}</td>
                        <td className="round-dg__cell round-dg__cell--sm">{item.studentsCount}</td>
                        <td className="round-dg__cell round-dg__cell--sm">{item.studentsPercentage.toString().replace('.', ',')}%</td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {loading && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
