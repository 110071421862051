import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import EmptyState from 'app/components/EmptyState';
import MessageCard from './MessageCard';
import { FiCalendar } from 'react-icons/fi';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import FormNameModal from './FormNameModal';
import MessageForm from './MessageForm';
import { useAuditorium } from 'app/hooks/useAuditorium';
import moment from 'moment';
import { t } from 'i18next';
import Loader from 'app/components/loader';
import getPermission from 'app/utils/getPermission';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSession } from 'app/hooks/useSession';

export default function LessonWidget({ messages, id, chats, setPage, auditorium }) {
  const { createMessage, lastLesson, combineMessages, hasMoreLessonComments } = useAuditorium();
  const [showModal, setShowModal] = useState(false);
  const [lessonName, setLessonName] = useState();

  const currentUser = useSession()?.session?.user;

  useEffect(() => {
    setLessonName(lastLesson?.name);
  }, [lastLesson]);

  const messageForm = useFormik({
    initialValues: {},
    onSubmit: values => {
      createMessage(id, values);
    }
  });

  function getMessageInfo(message, previousMessage, index) {
    const currentLessonId = message?.['auditorium-lesson']?.id;
    const previousLessonId = previousMessage?.['auditorium-lesson']?.id;
    const currentMessageDay = moment(message?.['created-at']).format('DD/MM/YYYY');
    const previousMessageDay = moment(previousMessage?.['created-at']).format('DD/MM/YYYY');
    const isNewLesson = previousLessonId !== undefined && previousLessonId !== currentLessonId;
    const isNewDay = currentMessageDay !== previousMessageDay;

    return {
      currentLessonId,
      previousLessonId,
      currentMessageDay,
      previousMessageDay,
      isNewLesson,
      isNewDay
    };
  }

  const isAdmin = !checkIncludeProfiles({ only: ['student'] });

  const shouldRenderOptions = useMemo(() => {
    if (auditorium?.['authored-by-user'] === true) {
      if (auditorium?.creator?.id !== currentUser.id) {
        return false;
      } else return true;
    }

    if (auditorium?.['authored-by-user'] === false) {
      if (isAdmin && getPermission('Interagir com monitoria', 'Monitorias')) {
        return true;
      } else return false;
    }
  }, [auditorium, isAdmin]);

  return (
    <div className="lesson-widget">
      <div className="lesson-widget__header">
        <h2 className="lesson-widget__title">{`${t('lessons.lesson')}: ${lessonName}`}</h2>
        {shouldRenderOptions ? (
          <button
            className="btn btn--small btn--wide btn--outline"
            onClick={() => setShowModal(true)}
          >
            {t('auditorium.buttonLessonName')}
          </button>
        ) : null}
      </div>

      <div className="lesson-widget__body">
        {messages.length > 0 ? (
          <div
            id="scrollableDiv"
            className="chat-widget__body"
            style={{
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse'
            }}
          >
            <InfiniteScroll
              dataLength={combineMessages.length}
              next={() => setPage(prevPage => prevPage + 1)}
              hasMore={hasMoreLessonComments}
              inverse={true}
              scrollableTarget="scrollableDiv"
            >
              {hasMoreLessonComments && <Loader />}

              <div className="lesson-widget__messages">
                {combineMessages?.map((message, index) => {
                  const previousMessage = combineMessages[index - 1];
                  const messageInfo = getMessageInfo(message, previousMessage, index);
                  const isFile = message.filename && !message.body;

                  return (
                    <React.Fragment key={message.id}>
                      {messageInfo.isNewLesson && !isFile && (
                        <p className="lesson-widget__divider">
                          <FiCalendar /> {moment(message?.['created-at']).format('DD/MM/YYYY')}
                        </p>
                      )}
                      {!messageInfo.isNewLesson && messageInfo.isNewDay && (
                        <p className="lesson-widget__divider">
                          <FiCalendar /> {moment(message?.['created-at']).format('DD/MM/YYYY')}
                        </p>
                      )}
                      <MessageCard
                        key={index}
                        message={message}
                        onlyFile={!message?.['auditorium-lesson']}
                        chats={chats}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </InfiniteScroll>
          </div>
        ) : (
          <>
            {!isAdmin ? (
              <EmptyState
                type="content"
                title={t('emptyState.titleStudentAuditorium')}
                text={t('emptyState.textStudentAuditorium')}
              />
            ) : (
              <EmptyState
                type="content"
                title={t('emptyState.titleAdminAuditorium')}
                text={t('emptyState.textAdminAuditorium')}
              />
            )}
          </>
        )}
      </div>

      {isAdmin && getPermission('Interagir com monitoria', 'Monitorias') ? (
        <MessageForm
          isAdmin={isAdmin}
          currentUser={currentUser}
          auditorium={auditorium}
          messageForm={messageForm}
          lessonId={lastLesson?.id}
          createMessage={createMessage}
          auditoriumId={id}
        />
      ) : null}

      <FormNameModal
        show={showModal}
        onClose={() => setShowModal(false)}
        messages={messages}
        setLessonName={setLessonName}
        id={id}
      />
    </div>
  );
}
