import React, { useEffect, useState } from 'react';
import LessonSidebar from './LessonSidebar';
import LessonContent from './LessonContent';
import { t } from 'i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

export default function CreateLesson(props) {
  const { step, contentId, lessonId, location, courseId } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const module = location?.state?.moduleId;
  const editLesson = location?.state?.editLesson;
  const { abilities } = usePlatformAbilities();
  const [isEditing, setIsEditing] = useState(false);
  const [moduleId, setModuleId] = useState();
  const [isSlide, setIsSlide] = useState(false);

  useEffect(() => {
    if (editLesson === true) {
      setIsEditing(true);
    }
    setModuleId(module);
  }, []);

  const steps = [
    {
      id: 0,
      route: 'nome',
      text: t('lessons.stepsLessonName')
    },
    {
      id: 1,
      route: 'videoaula',
      text: t('lessons.stepsLessonVideo')
    }
  ];

  if (abilities?.quiz && !isSlide) {
    steps.push({
      id: 2,
      route: 'perguntas',
      text: t('lessons.stepsLessonQuestions')
    });
  }

  return (
    <div className="main-screen">
      <LessonSidebar
        disciplineId={contentId}
        moduleId={moduleId}
        courseId={courseId}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
        edit={isEditing}
        lessonId={lessonId}
        isSlide={isSlide}
      />

      <main className="main-content">
        <LessonContent
          abilities={abilities}
          courseId={courseId}
          step={step}
          steps={steps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          learningSystemContentId={contentId}
          lessonId={lessonId}
          moduleId={moduleId}
          disciplineId={contentId}
          editLesson={isEditing}
          setIsSlide={setIsSlide}
          isSlide={isSlide}
        />
      </main>
    </div>
  );
}
