import React from 'react';
import styles from './ProfileBanner.module.scss';
import Avatar from 'app/components/Avatar';

export default function ProfileBanner() {
  return (
    <div className={styles.banner}>
      <Avatar
        src={null}
        className={`${styles.avatar} avatar avatar--xl`}
      />

      <div className={styles.content}>
        <h3 className={styles.title}>Felipe Brandão de Oliveira</h3>
        <p className={styles.text}>Área: Produto</p>
        <p className={styles.text}>Cargo: Vendedor</p>
      </div>
    </div>
  );
}
