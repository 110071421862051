import { useClient } from 'jsonapi-react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';

export default function TaskCard(props) {
  const { task } = props;
  const client = useClient();

  const checkAccomplishSlot = async () => {
    task.accomplished === true ? (task.accomplished = false) : (task.accomplished = true);

    const { error } = await client.mutate(['slots', task.id], {
      accomplished: task.accomplished
    });
    if (error) {
      toast.error('Erro ao atualizar tarefa');
    }
  };

  let categoryColor = '';
  let categoryName = '';

  switch (task.activity) {
    case 'revision':
      categoryName = 'Revisar';
      categoryColor = '#B622FC';
      break;
    case 'Descobrir':
      categoryName = 'Descobrir';
      categoryColor = '#FDB221';
      break;
    case 'learn':
      categoryName = 'Aprender';
      categoryColor = '#21C9FE';
      break;
    case 'train':
      categoryName = 'Treinar';
      categoryColor = '#E95852';
      break;
    default:
      break;
  }

  function handleTagManagement() {
    switch (task?.['management-type']) {
      case 'dynamic':
        return 'expanded-calendar__tag';

      case 'course':
        return 'expanded-calendar__tag expanded-calendar__tag--course';

      case 'classroom':
        return 'expanded-calendar__tag expanded-calendar__tag--class';

      case 'student':
        return 'expanded-calendar__tag expanded-calendar__tag--individual';

      default:
        return 'expanded-calendar__tag';
    }
  }

  return (
    <div
      className="calendar-task"
      style={{
        borderRightColor: categoryColor,
        minHeight: 'auto'
      }}
    >
      <input
        onClick={() => checkAccomplishSlot()}
        type="checkbox"
        name="checkbox"
        id="checkbox"
        defaultChecked={task.accomplished}
        className="calendar-task__check-input"
      />

      <div className="calendar-task__container">
        <div className="calendar-task__header">
          <span
            className="calendar-task__title"
            title="Resenha do texto"
          >
            {task?.title}
          </span>
        </div>

        <span
          className="calendar-task__description"
          title="Escreva uma resenha"
        >
          <span className="calendar-task__description-text">{task?.body}</span>
        </span>

        <span
          className={handleTagManagement()}
          title="Curso prático de física"
        >
          {task?.['relationship-tag']}
        </span>
      </div>
    </div>
  );
}
