import React, { useState } from 'react';
import Modal from 'app/components/Modal';
import { Link } from '@reach/router';
import moment from 'moment';
import { t } from 'i18next';

export default function ModuleModal(props) {
  const { show, onClose, course, courseModules } = props;

  const [searchTerm, setSearchTerm] = useState('');

  const filteredModules = courseModules?.filter(item => {
    if (searchTerm === '') {
      return item;
    }
    if (searchTerm != null && item.title.toLowerCase().includes(searchTerm.toLowerCase())) {
      return item;
    }
  });

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <div className="modal__simple-header">
        <h3 className="modal__simple-title modal__simple-title--with-btn">
          {t('lms.modulesOf')} {course?.title}
        </h3>
        <span className="widget__count">
          {courseModules?.length}
          <span> {t('textsCommon.modules')}</span>
        </span>
      </div>

      <div className="form__row">
        <input
          value={searchTerm}
          aria-label="Pesquisar"
          className="form__control form__control--search-with-icon"
          style={{ width: 'auto' }}
          placeholder={t('textsCommon.research')}
          type="search"
          name="search"
          id="search"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="round-dg-wrapper round-dg-wrapper--modal">
        <table className="round-dg round-dg--light">
          <thead className="round-dg__header">
            <tr className="round-dg__row">
              <th className="round-dg__cell-header">{t('textsCommon.module')}</th>
              <th
                className="round-dg__cell-header"
                style={{ width: '66px' }}
              >
                {t('textsCommon.lessons')}
              </th>
              <th
                className="round-dg__cell-header"
                style={{ width: '148px' }}
              >
                {t('metrics.totalStudents')}
              </th>
              <th
                className="round-dg__cell-header"
                style={{ width: '148px' }}
              >
                {t('courses.lastUpdate')}
              </th>
            </tr>
          </thead>

          <tbody className="round-dg__body">
            {filteredModules?.map((m, i) => (
              <tr
                className="round-dg__row"
                key={i}
              >
                <th className="round-dg__cell-header round-dg__cell-header--row">
                  <div className="round-dg__user">
                    <div className="round-dg__inner-user">
                      <h3 className="round-dg__title">{m.title}</h3>
                    </div>
                  </div>
                </th>
                <td className="round-dg__cell">{m?.['learning-system-items-count']}</td>
                <td className="round-dg__cell">{m?.['learning-system-users']?.length}</td>
                <td className="round-dg__cell">{moment(m?.['updated-at']).format('DD/MM/YYYY')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
