import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import Avatar from 'app/components/Avatar';
import { navigate } from '@reach/router';
import AccuracyRateTag from '../../AccuracyRateTag';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'app/components/loader';
import EndScroll from 'app/components/endScroll';

export default function StudentDataGrid(props) {
  const { challengeId, participants, hasMore, setPageNumber, orderByAccuracy, setOrderByAccuracy } = props;

  return (
    <div className="round-dg-wrapper">
      {participants && (
        <InfiniteScroll
          dataLength={participants?.length}
          next={() => setPageNumber(prevState => prevState + 1)}
          hasMore={hasMore}
          loader={<Loader />}
          endMessage={<EndScroll />}
        >
          {' '}
          <table
            className="round-dg round-dg--light"
            style={{ minWidth: '884px' }}
          >
            <thead className="round-dg__header">
              <tr className="round-dg__row">
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '80px' }}
                >
                  Posição
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '44px' }}
                />
                <th className="round-dg__cell-header round-dg__cell-header--sm">Estudante</th>
                <th className="round-dg__cell-header round-dg__cell-header--sm">Instituição</th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '180px' }}
                >
                  GRE
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm u-text-right"
                  style={{ width: '150px' }}
                >
                  Acerto em desafios{' '}
                  <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                    {orderByAccuracy === 'descendent' ? (
                      <FiChevronDown
                        className="card__icon"
                        onClick={() => setOrderByAccuracy('ascendent')}
                      />
                    ) : (
                      <FiChevronUp
                        className="card__icon"
                        onClick={() => setOrderByAccuracy('descendent')}
                      />
                    )}
                  </span>
                </th>
              </tr>
            </thead>

            <tbody className="round-dg__body">
              {participants?.map((item, i, self) => {
                const trophyPositions = [0, 1, 2];
                return (
                  <tr
                    className="round-dg__row u-cursor-pointer"
                    onClick={() => navigate(`/desafio-da-semana/ranking-por-participante/${item.id}/estudante/${challengeId}`, { state: { position: orderByAccuracy === 'ascendent' && trophyPositions.includes(i) ? i + 1 : null } })}
                    key={i}
                  >
                    <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{orderByAccuracy === 'ascendent' ? i + 1 : self.length - i}</th>
                    <td className="round-dg__cell round-dg__cell--sm">
                      {orderByAccuracy === 'ascendent' && i === 0 && <AiOutlineTrophy className="card__icon card__icon--golden" />}
                      {orderByAccuracy === 'ascendent' && i === 1 && <AiOutlineTrophy className="card__icon card__icon--silver" />}
                      {orderByAccuracy === 'ascendent' && i === 2 && <AiOutlineTrophy className="card__icon card__icon--bronze" />}
                    </td>
                    <td className="round-dg__cell round-dg__cell--sm">
                      <div className="round-dg__user">
                        <Avatar
                          className="avatar--xxs"
                          src={item?.image?.url}
                          alt="Imagem do usuário"
                        />

                        <div className="round-dg__user-inner">
                          <h3 className="round-dg__title">{item.name}</h3>
                        </div>
                      </div>
                    </td>
                    <td className="round-dg__cell round-dg__cell--sm">
                      <div
                        className="card__badges card__badges--one-line u-mb-0"
                        style={{ lineHeight: 1, alignItems: 'center' }}
                      >
                        {item?.institutions?.length >= 2 ? (
                          <>
                            <span
                              className="u-text-truncate"
                              title={item?.institutions?.[0]}
                            >
                              {item?.institutions?.[0]}
                            </span>
                            <span
                              className="badge badge--tiny badge--more"
                              title={item?.institutions?.slice(1)}
                            >
                              {'+' + item?.institutions?.slice(1)?.length}
                            </span>
                          </>
                        ) : (
                          <span
                            className="u-text-truncate"
                            title={item?.institutions?.[0]}
                          >
                            {item?.institutions?.[0]}
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="round-dg__cell round-dg__cell--sm">
                      <div
                        className="card__badges card__badges--one-line u-mb-0"
                        style={{ lineHeight: 1, alignItems: 'center' }}
                      >
                        {item?.regionals?.length >= 2 ? (
                          <>
                            <span
                              className="u-text-truncate"
                              title={item?.regionals?.[0]}
                            >
                              {item?.regionals?.[0]}
                            </span>
                            <span
                              className="badge badge--tiny badge--more"
                              title={item?.regionals?.slice(1)}
                            >
                              {'+' + item?.regionals?.slice(1).length}
                            </span>
                          </>
                        ) : (
                          <span
                            className="u-text-truncate"
                            title={item?.regionals?.[0]}
                          >
                            {item?.regionals?.[0]}
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="round-dg__cell u-text-right">
                      <AccuracyRateTag value={item['accuracy-rate']} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      )}
    </div>
  );
}
