import React, { useEffect, useState } from 'react';
import Modal from 'app/components/Modal';
import { useSession } from 'app/hooks/useSession';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function StepImage(props) {
  const { t } = useTranslation();
  const { form, handleChangeImage, imageLabel, file, defaultImage, showModal, setShowModal, selectedCourse } = props;
  const [selectedClassroom, setSelectedClassroom] = useState({});
  const { session } = useSession();

  useEffect(() => {
    if (form.values.course !== '' && selectedCourse) {
      if (form.values.classroom !== '') {
        setSelectedClassroom(selectedCourse.classrooms.find(c => c.id === +form.values.classroom));
      }
    }
  }, [selectedCourse]);

  return (
    <div className="u-mb-5">
      <div className="page__content fadeIn u-mb-3">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{form?.values?.title || t('projects.newProjects')}</h2>
        </div>

        <fieldset className="form__row">
          <legend className="form__label">{t('textsCommon.image')} (1020x252 pixels)</legend>

          <label className="form__custom-file-input">
            <input
              type="file"
              name="image"
              id="image"
              accept=".jpeg, .png, .jpg"
              onChange={handleChangeImage}
            />
            <span className="form__control">{imageLabel ? imageLabel : t('textsCommon.placeholderUploadImage')}</span>
          </label>
        </fieldset>

        {file ? (
          <div className="card__header-img-wrapper">
            <img
              className="card__header-img"
              src={file || defaultImage}
              alt="imagem do curso"
              style={{ height: '160px' }}
            />
          </div>
        ) : null}

        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          <div className="show-course-banner u-mb-4">
            <img
              src={file || defaultImage}
              alt="imagem do curso"
              className="show-course-banner__img"
            />

            <div className="show-course-banner__title">{form.values?.title}</div>
            <div className="show-course-banner__description">{t("projects.updatedIn")} {moment().format('LL')}</div>
            <p className="show-course-banner__by">{t("lms.by")} {session.user.name}</p>
          </div>

          {selectedCourse && !selectedClassroom && (
            <div className="u-mb-2">
              <span className="expanded-calendar__tag expanded-calendar__tag--course">{selectedCourse.title}</span>
            </div>
          )}

          {selectedClassroom && (
            <div className="u-mb-2">
              <span className="expanded-calendar__tag expanded-calendar__tag--course">{selectedClassroom.title}</span>
            </div>
          )}

          <div className="show-course-section__title">{t("button.aboutProject")}</div>
          <div className="show-course-section__description">{form.values?.description}</div>
        </Modal>
      </div>

      <button
        type="button"
        className="btn btn--link btn--wide"
        onClick={() => setShowModal(true)}
      >
        {t('projects.previewProject')}
      </button>
    </div>
  );
}
