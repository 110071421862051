import { t } from 'i18next';
import React from 'react';

function TabNavBar({ activeTab, setActiveTab }) {
  return (
    <div className="tab u-mb-2">
      <button
        className={activeTab === 'chat' ? 'tab__link active' : 'tab__link'}
        onClick={() => setActiveTab('chat')}
      >
        {'Chat'}
      </button>
      <button
        className={activeTab === 'arquivos' ? 'tab__link active' : 'tab__link'}
        onClick={() => setActiveTab('arquivos')}
      >
        {t('courses.toggleFiles')}
      </button>
    </div>
  );
}

export default TabNavBar;
