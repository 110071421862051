import React from 'react';
import IconVerified from '../Icons/IconVerified';

export default function GeneratedContent(props) {
  const { authorName, verified } = props;

  return (
    <div className="generated-content">
      {authorName && (
        <span
          className="generated-content__text"
          title={authorName}
        >
          {authorName}
        </span>
      )}
      {verified && <IconVerified />}
    </div>
  );
}
