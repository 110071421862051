import React from 'react';

function TabNavBar({ selectedTab, setSelectedTab }) {
  return (
    <div className="tab u-mb-4">
      <button
        className={selectedTab === 'redacoes' ? 'tab__link active' : 'tab__link'}
        onClick={() => setSelectedTab('redacoes')}
      >
        Redações
      </button>
      <button
        className={selectedTab === 'sobre' ? 'tab__link active' : 'tab__link'}
        onClick={() => setSelectedTab('sobre')}
      >
        Sobre
      </button>
    </div>
  );
}

export default TabNavBar;
