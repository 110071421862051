import EmptyState from 'app/components/EmptyState';
import { useClient } from 'jsonapi-react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import AverageAccessChart from './AverageAccessChart';
import { useTranslation } from 'react-i18next';

export default function AverageAccessWidget(props) {
  const { t } = useTranslation();
  const { title, timeFilterSelected } = props;
  const client = useClient();
  const [averageAccess, setAverageAccess] = useState([]);
  const [duration, setDuration] = useState(0);
  const [name, setName] = useState();

  const getAverageAnsweringTime = async () => {
    const { data, error } = await client.fetch(`reports/question_books/average_answering_time?week=${timeFilterSelected}`);

    if (error) {
      toast.error(t('toast.errorGetMetrics'));
    } else {
      setAverageAccess(data);
      setDuration((data?.items || []).map(i => i[1]).reduce((sum, i) => sum + i, 0));
    }
  };

  useEffect(() => {
    getAverageAnsweringTime();
  }, [timeFilterSelected]);

  return (
    <div className="widget u-h-100">
      <div className="widget__header">
        <div className="widget__title-with-btn">
          <h3 className="widget__title u-mb-2">{title || 'Média de acessos'}</h3>
        </div>
      </div>

      {duration > 0 ? (
        <div className="widget__body">
          <AverageAccessChart
            name={name}
            color="#442B8C"
            chartData={averageAccess}
            valueSuffix={false}
          />
        </div>
      ) : (
        <EmptyState
          type="register"
          text={t('emptyState.noAccessFoundForPeriod')}
        />
      )}
    </div>
  );
}
