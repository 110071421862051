import React, { useState, useEffect } from 'react';
import { FiInfo } from 'react-icons/fi';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useSession } from 'app/hooks/useSession';
import { useParams } from '@reach/router';
import MultiSelect from 'app/components/MultiSelect';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import { useTranslation } from 'react-i18next';
import { i18n } from 'app/components/i18n/i18n';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import { t } from 'i18next';

export default function StepName(props) {
  const { t } = useTranslation();
  const { step, form, taxonomies, question } = props;
  const { session } = useSession();
  const { id } = useParams();
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);
  const isAdmin = checkIncludeProfiles({ only: ['admin'] });

  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LOCALE);
  }, []);


  return (
    step === 'nome' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{form.values.code || t('exams.titleNewQuestion')}</h2>
        </div>

        <div className="new-questions__body">
          <p className="new-questions__subtitle">{t('exams.subtitleNewQuestion')}</p>

          <div className="form__row">
            <label
              className="form__label-wrapper"
              htmlFor="code"
            >
              <span className="form__label">{t('exams.formTitleQuestionName')}</span>
              <FiInfo
                id="info"
                data-tooltip-content={t('exams.tooltipContent')}
              />
              <ReactTooltip
                anchorId="info"
                className="tooltip"
              />
            </label>

            <input
              className="form__control"
              placeholder={t('exams.placeholderQuestionName')}
              id="code"
              name="code"
              type="text"
              value={form.values.code}
              onChange={form.handleChange}
            />
            {form?.touched?.code && form?.errors?.code && <span style={{ color: 'red' }}>{form?.errors?.code}</span>}
          </div>

          <fieldset className="form__row">
            <legend className="form__label">*{t('tasks.mattersRelated')}</legend>

            <MultiSelect
              selectedTaxonomies={form?.values?.selectedTaxonomies}
              setFieldValue={form.setFieldValue}
              openModal={() => setIsOpenModalTaxonomies(true)}
              placeholder={t('tasks.mattersAdd')}
            />
            {form?.touched?.selectedTaxonomies && form?.errors?.selectedTaxonomies && <span style={{ color: 'red' }}>{form?.errors?.selectedTaxonomies}</span>}
          </fieldset>

          {taxonomies.length > 0 && isOpenModalTaxonomies && (
            <ModalTaxonomies
              taxonomies={taxonomies}
              selectedTaxonomies={form?.values?.selectedTaxonomies}
              setFieldValue={form.setFieldValue}
              closeModal={() => setIsOpenModalTaxonomies(false)}
            />
          )}

          <div className="form__row">
            {(!id || question?.creator?.id === session?.user?.id) &&
              <div className="form__check form__switch">
                <input
                  className="form__check-input"
                  id="authorialContent"
                  name="authorialContent"
                  type="checkbox"
                  checked={form?.values?.['authored_by_user']}
                  onClick={() => {
                    form?.setFieldValue('authored_by_user', !form?.values?.['authored_by_user']);
                  }}
                />
                <label htmlFor="authorialContent">
                  Esta questão é de minha autoria e <b>somente eu posso editá-la.</b>
                </label>
              </div>
            }

            {isAdmin &&
              <div className="form__check form__switch">
                <input
                  className="form__check-input"
                  id="officialContent"
                  name="officialContent"
                  type="checkbox"
                  checked={form?.values?.['official_content']}
                  onClick={() => {
                    form?.setFieldValue('official_content', !form?.values?.['official_content']);
                  }}
                />
                <label htmlFor="officialContent">
                  Este conteúdo é <strong>oficial;</strong> apenas a equipe administrativa têm permissão para editá-lo.
                </label>
              </div>
            }
          </div>

          <span className="form__text">
            <strong>* {t('warning.requiredField')}</strong>
          </span>
        </div>
      </div>
    )
  );
}
