import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import navigationPng from '../../images/onboarding/mockQuestion/navigation.png';
import courseSelectPng from '../../images/onboarding/mockQuestion/course-select.png';
import cardsMockQuestionPng from '../../images/onboarding/mockQuestion/cardsMockQuestion.png';

export function MockQuestionBook() {
  const { t } = useTranslation();

  return (
    <section>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.testsMockQuestionsBookResults')}</h1>
        <p className="text-option-onboarding">{t('onboarding.testMockQuestionsBookResultsDesc')}</p>

        <img
        style={{ width: 300 }}
          src={navigationPng}
          className="w-auto"
        />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.filters')}</h1>
        <p className="text-option-onboarding">{t('onboarding.filtersDesc')}</p>

          <img style={{ width: 350 }}
          src={courseSelectPng} />
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.exploreCardMockQuestions')}</h1>
        <p className="text-option-onboarding">{t('onboarding.exploreCardMockQuestionsDesc')}</p>

        <img src={cardsMockQuestionPng} />
      </div>
    </section>
  );
}