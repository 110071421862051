import React, { useState } from 'react';
import { FiChevronUp } from 'react-icons/fi';

function chartPerformanceCard({ onClick, taxonomy, challenges }) {

  const lastResult = parseFloat(taxonomy?.last_result?.toFixed(1));
  let performanceClass = lastResult < 0 ? 'chart-performance__efficiency--down' : 'chart-performance__efficiency--up';
  if (lastResult === 0.0) {
    performanceClass = '';
  }

  return (
    <div
      className={`chart-performance ${taxonomy?.isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <span
        className="chart-performance__name"
        title={challenges ? taxonomy.taxonomy : taxonomy?.name}
      >
        {challenges ? taxonomy.taxonomy : taxonomy?.name}
      </span>
      <span className="chart-performance__bars">
        <span
          className="chart-performance__percentage-bar"
          style={{ width: `${challenges ? taxonomy['question-accuracy'] : taxonomy?.average_result}%` }}
        />
      </span>
      <span className="chart-performance__percentage-text">{challenges ? taxonomy['question-accuracy'] :taxonomy?.average_result?.toFixed(1)}%</span>
      <span className={`chart-performance__efficiency ${performanceClass}`}>
        {lastResult === 0.0 ? (
          `--`
        ) : (
          <>
            <FiChevronUp />
            {challenges ? taxonomy['question-accuracy'] :lastResult}%
          </>
        )}
      </span>
    </div>
  );
}

export default chartPerformanceCard;
