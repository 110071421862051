import { useTranslation } from 'react-i18next';
import React from 'react';
import { FiClock, FiCheck, FiTrendingUp, FiFileText } from 'react-icons/fi';

function KpiCard(props) {
  const { t } = useTranslation();
  const { cardIcon, cardTitle, cardValue, cardPercentage, cardPeriod } = props;
  function printIcon(value) {
    switch (value) {
      case 'clock':
        return <FiClock />;
      case 'check':
        return <FiCheck />;
      case 'arrow':
        return <FiTrendingUp />;
      default:
        return <FiFileText />;
    }
  }
  function printPeriod(value) {
    switch (value) {
      case 'dia':
        return t("questionBooks.lastDay");
      case 'semana':
        return t("questionBooks.lastWeek");
      case 'mes':
        return t("questionBooks.lastMonth");
      default:
        return t("questionBooks.lastMonth");
    }
  }

  function validatePercentage(value) {
    if (value < 0) {
      return t("questionBooks.less");
    } else {
      return t("questionBooks.more");
    }
  }
  return (
    <div className="kpi-card">
      <div className="kpi-card__icon">{printIcon(cardIcon)}</div>
      <div className="kpi-card__title">{cardTitle}</div>
      <div className="kpi-card__value">{cardValue}</div>
      <div className="kpi-card__metrics">
        <div className={cardPercentage > 0 ? 'kpi-card__metric positive' : 'kpi-card__metric negative'}>
          {cardPercentage}% {validatePercentage(cardPercentage)}
        </div>
        <div className="kpi-card__explanation">{t("questionBooks.what")} {printPeriod(cardPeriod)}</div>
      </div>
    </div>
  );
}
export default KpiCard;
