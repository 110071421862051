import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'app/utils/highchartsConfig';

export default function AverageAccessChart(props) {
  const { name, color, chartData, valueSuffix, chartType } = props;

  const toUnixTimestamp = dateString => {
    const dateArr = dateString.split('-').map(e => parseInt(e));
    dateArr[1] = dateArr[1] - 1; // month start with index 0
    return Date.UTC(...dateArr);
  };

  const chartOptions = {
    chart: {
      type: chartType || 'areaspline',
      height: 400
    },
    title: { text: null },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b'
      },
      title: { text: null }
    },
    yAxis: {
      title: { text: null },
      labels: {
        format: `{value}${valueSuffix || ''}`
      }
    },
    tooltip: {
      shared: true,
      headerFormat: '<b>{point.x:%e. %b}</b><br>',
      pointFormat: '<span style="color:{point.color}">●</span> {series.name}: {point.y}<br>',
      valueSuffix: valueSuffix
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5
      }
    },
    legend: {
      enabled: false
    },
    series: [
      {
        name: name,
        data: (chartData.items || []).map(data => [toUnixTimestamp(data[0]), data[1]]),
        color: color
      },
      {
        name: 'Média',
        data: (chartData.averageItems || []).map(data => [toUnixTimestamp(data[0]), data[1]]),
        type: 'line',
        color: '#32C9D3'
      }
    ]
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
}
