import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FiFileText } from 'react-icons/fi';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import ActivityCard from 'app/screens/ComplementaryActivity/ActivityCard';

export default function CourseClassroomActivities(props) {
  const { selectedItemId, activeTab, setEmptyTabs } = props;
  const client = useClient();
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState([]);

  const getActivities = async () => {
    setLoading(true);
    const url = `activities?filter[learning_system_items.id]=${selectedItemId}`;
    const { data, error } = await client.fetch(url);
    setLoading(false);
    if (error) {
      toast.error('Erro ao buscar atividades.');
    } else {
      setActivities(data);
      if (data.length > 0) {
        setEmptyTabs(false);
      }
    }
  };

  useEffect(() => {
    if (selectedItemId) {
      getActivities();
    }
  }, [selectedItemId]);

  return (
    activeTab && (
      <div className="tab__pane">
        <div className="tab__cards">
          {loading ? (
            <Loader />
          ) : activities.length > 0 ? (
            (activities || []).map((activity, i) => (
              <ActivityCard
                activity={activity}
                key={i}
              />
            ))
          ) : (
            <EmptyState
              type="content"
              title="Sem conteúdo"
            />
          )}
        </div>
      </div>
    )
  );
}
