import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function FilterMultiSelect(props) {
  const { t } = useTranslation();
  const { data, count, label, placeholder } = props;

  const [isOpen, setIsOpen] = useState(false);

  const menuRef = useRef();

  function handleOpen() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const closeOnEscapeKeyDown = event => event.key === 'Escape' && isOpen && setIsOpen(false);
    const closeOnClickOutside = event => !menuRef.current.contains(event.target) && setIsOpen(false);

    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    document.addEventListener('mouseup', closeOnClickOutside);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
      document.removeEventListener('mouseup', closeOnClickOutside);
    };
  });

  const checked = data.filter(check => check.value === true);

  const placeholderText = placeholder ? placeholder : t('filter.blankLabel');

  return (
    <fieldset className="filter-bar__row">
      <legend className="filter-bar__label">{label || t('filter.filters')}</legend>

      <div
        className="filter-multi-select"
        ref={menuRef}
      >
        <button
          type="button"
          className="filter-multi-select__toggle"
          onClick={handleOpen}
        >
          {!count && (
            <div className="filter-multi-select__badges">
              {checked.length === 0 && placeholderText}
              {checked.length > 0 &&
                checked.map(check => (
                  <span
                    className="badge badge--tiny badge--primary"
                    title={check.label}
                    key={check.label}
                  >
                    {check.label}
                  </span>
                ))}
            </div>
          )}

          {count && (
            <div className="filter-multi-select__badges">
              {checked.length} {checked.length > 1 ? 'selecionados' : 'selecionado'}
            </div>
          )}
        </button>

        <div className={`filter-multi-select__content ${isOpen ? 'active' : 'inactive'}`}>
          {data?.map(check => (
            <div
              className={check.value ? 'form__check checked' : 'form__check'}
              key={check.label}
            >
              <input
                className="form__check-input"
                type="checkbox"
                id={check.label}
                name={check.label}
                value={check.value}
                checked={check.value}
                onChange={check.onChange}
              />
              <label
                className="form__check-label"
                htmlFor={check.label}
              >
                {check.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </fieldset>
  );
}
