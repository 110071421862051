import React from 'react';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import { navigate, useLocation } from '@reach/router';
import { t } from 'i18next';
import { AiOutlineTrophy } from 'react-icons/ai';
import moreMedal from 'app/images/more-medal.svg';

export default function CourseCard(props) {
  const { course, journey, setShowModal, setSelectedCourse } = props;
  const location = useLocation();

  const handleClick = () => {
    setSelectedCourse(course);
    setShowModal(true);
  };

  const downloadCertificate = () => {
    window.open(`${process.env.REACT_APP_DEV_URL}//user_certificates/download/${course?.certificate?.hash_id}.pdf`, '_blank');
  };

  return (
    <div
      className="course-card"
      onClick={() => {
        !journey ? navigate(`/curso/acompanhamento/${course?.id}`) : handleClick();
      }}
    >
      <div className="course-card__body">
        <div className="course-card__img-wrapper">
          {/* <span className="badge badge--tiny badge--primary">{course?.knowledge ? course?.knowledge : ''}</span> */}
          <img
            className="course-card__img"
            src={course?.image?.url || defaultImage}
            alt=""
          />
        </div>

        <div className="course-card__inner-body">
          <h3 className="course-card__title">{course?.title}</h3>
          <p className="course-card__subtitle">
            <strong>{course?.['finished-learning-system-items']}</strong> / {course?.['learning-system-item-count']} {t('dashboard.completedLessons')}
          </p>

          {course?.progress === 100 && new Date(course?.['finished-at']) > new Date() ? (
            <>
              <span className="badge ">
                Aulas concluídas! <b>Continue atento a novos conteúdos.</b>
              </span>
              <span
                className="badge  badge--finished"
                title={`O certificado do curso estará disponível em ${new Date(course?.['finished-at']).toLocaleDateString('pt-br')}`}
              >
                O certificado do curso estará disponível em {new Date(course?.['finished-at']).toLocaleDateString('pt-br')}
              </span>
            </>
          ) : course?.progress === 100 && !journey ? (
            <>
              <span className="badge badge--tiny badge--success">Concluído</span>
              {course?.certificate?.hash_id && (
                <div>
                  <a
                    onClick={e => {
                      e.stopPropagation();
                      downloadCertificate();
                    }}
                    type="button"
                    className="btn btn--link u-p-0"
                  >
                    {t('button.certification')}
                  </a>
                </div>
              )}
            </>
          ) : (
            <div className="card__progress u-mb-0">
              <div className="card__progress-container">
                <div
                  className="card__progress-bar"
                  role="progressbar"
                  style={{ width: `${parseInt(course?.progress)}%` }}
                  aria-valuenow={parseInt(course?.progress)}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <div className="card__progress-text">{parseInt(course?.progress)}%</div>
            </div>
          )}
        </div>

        {course?.progress === 100 && (
          <div className="medal medal--sm">
            <img
              src={moreMedal}
              alt="nova medalha"
              className="medal__img"
            />
            <span className="medal__value">
              <AiOutlineTrophy />
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
