import React, { useMemo, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import Avatar from 'app/components/Avatar';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'app/components/loader';
import EndScroll from 'app/components/endScroll';

export default function StudentDataGrid({ students, hasMore, setPageNumber }) {
  const [order, setOrder] = useState('descendent');

  const orderedStudents = useMemo(() => {
    let newArr = [];
    if (order === 'ascendent') {
      newArr = [...students].sort((a, b) => a.score - b.score);
    } else {
      newArr = [...students].sort((a, b) => b.score - a.score);
    }

    return newArr;
  }, [order, students]);

  return (
    <div className="course-about__module fadeIn">
      <div className="round-dg-wrapper">
        <InfiniteScroll
          dataLength={orderedStudents.length}
          next={() => setPageNumber(prev => prev + 1)}
          hasMore={hasMore}
          loader={<Loader />}
          endMessage={<EndScroll />}
          className="infinite-scroll__full"
        >
          <table
            className="round-dg round-dg--light"
            style={{ minWidth: '480px' }}
          >
            <thead className="round-dg__header">
              <tr className="round-dg__row">
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '80px' }}
                >
                  Posição
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '44px' }}
                />
                <th className="round-dg__cell-header round-dg__cell-header--sm">Estudante</th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm u-text-right"
                  style={{ width: '124px' }}
                >
                  Pontuação total{' '}
                  <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                    {order === 'ascendent' ? (
                      <FiChevronDown
                        onClick={() => setOrder('descendent')}
                        className="card__icon"
                      />
                    ) : (
                      <FiChevronUp
                        onClick={() => setOrder('ascendent')}
                        className="card__icon"
                      />
                    )}
                  </span>
                </th>
              </tr>
            </thead>

            <tbody className="round-dg__body">
              {orderedStudents?.map((item, i) => (
                <tr
                  className="round-dg__row"
                  key={i}
                >
                  <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{i + 1}</th>
                  <td className="round-dg__cell round-dg__cell--sm">
                    {i === 0 && order === 'descendent' && <AiOutlineTrophy className="card__icon card__icon--golden" />}
                    {i === 1 && order === 'descendent' && <AiOutlineTrophy className="card__icon card__icon--silver" />}
                    {i === 2 && order === 'descendent' && <AiOutlineTrophy className="card__icon card__icon--bronze" />}
                  </td>
                  <td className="round-dg__cell round-dg__cell--sm">
                    <div className="round-dg__user">
                      <Avatar
                        className="avatar--xxs"
                        src={item?.image?.url}
                        alt="Imagem do usuário"
                      />

                      <div className="round-dg__user-inner">
                        <h3 className="round-dg__title">{item.name}</h3>
                      </div>
                    </div>
                  </td>
                  <td className="round-dg__cell u-text-right">{item.score} pontos</td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </div>
  );
}
