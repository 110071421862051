import React, { useState, useEffect } from 'react';
import QuestionModal from '../../QuestionModal';
import { t } from 'i18next';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';

export default function StepVisibility(props) {
  const { step, form } = props;

  const [showModal, setShowModal] = useState(false);
  const [check, setCheck] = useState(false);

  const handleVisibilityChange = event => {
    const visibilityValue = event.target.value;
    form.setFieldValue('visibility', visibilityValue);
  };

  return (
    step === 'visibilidade' && (
      <>
        <div className="page__content fadeIn u-mb-3">
          <div className="new-questions__header">
            <h2 className="new-questions__title">{form.values.code || t('exams.titleNewQuestion')}</h2>
          </div>

          <div className="new-questions__body">
            <p className="new-questions__subtitle">{t('exams.subtitleExclusivity')}</p>

            <div className="form__row">
              <label
                htmlFor="exclusivity"
                className="form__label"
              >
                *{t('exams.questionExclusivity')}
              </label>

              <select
                className="form__select"
                name="exclusivity"
                id="exclusivity"
                value={form?.values?.visibility}
                onChange={handleVisibilityChange}
                style={{ width: 'auto' }}
              >
                <option
                  value=""
                  disabled
                >
                  {t('exams.chooseExclusivity')}
                </option>
                <option value="train">{t('exams.nonExclusive')}</option>
                <option value="exam">{t('exams.exclusiveToExams')}</option>
                <option value="mock">{t('exams.exclusiveToSimulations')}</option>
                <option value="exam_and_mock">{t('exams.exclusiveToExamsAndSimulations')}</option>
              </select>
            </div>

            <fieldset className="form__row">
              <legend className="form__label">{t('exams.questionPublishing')}</legend>
              <div className="form__check form__check--inline form__switch">
                <input
                  className="form__check-input"
                  id="published"
                  type="checkbox"
                  checked={form?.values.published}
                  onChange={() => form?.setFieldValue('published', !form?.values.published)}
                />
                <label htmlFor="publish">{t('exams.labelAfterCreate')}</label>
              </div>
            </fieldset>

            <span className="form__text">
              <strong>* {t('warning.requiredField')}</strong>
            </span>
          </div>
        </div>

        <button
          type="button"
          className="btn btn--link btn--wide u-mb-5"
          onClick={() => setShowModal(true)}
          style={{ alignSelf: 'flex-start' }}
        >
          {t('exams.buttonPreview')}
        </button>

        <QuestionModal
          show={showModal}
          onClose={() => setShowModal(false)}
          form={form}
        />
      </>
    )
  );
}
