import EmptyState from 'app/components/EmptyState';
import { useClient } from 'jsonapi-react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import StepVideoCard from './VideoQuestionsCard';
import VideoQuestionModal from './VideoQuestionsModal';
import { t } from 'i18next';

const StepVideoQuestions = props => {
  const { step, createLessonForm, lessonId, setDeletedQuestions, deletedQuestions, editLesson } = props;
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState();
  const [lesson, setLesson] = useState();
  const [activeSelectedQuestion, setActiveSelectedQuestion] = useState();
  const [quizIndex, setQuizIndex] = useState();

  useEffect(() => {
    setSelectedQuestions(createLessonForm?.values?.quizItemsAttributes);
  }, [createLessonForm?.values?.quizItemsAttributes]);

  const getLesson = async () => {
    const { data, error } = await client.fetch(`learning_system_items/${lessonId}`);
    if (error) {
      toast.error('Erro ao buscar informações sobre o módulo');
    } else {
      setSelectedQuestions(data.quiz_items);
      setLesson(data);
    }
  };

  useEffect(() => {
    if (editLesson) {
      getLesson();
    }
  }, []);

  const removeQuestion = index => {
    const filteredQuestions = selectedQuestions?.filter((id, i) => {
      if (i !== index) return true;
      else setDeletedQuestions([...deletedQuestions, id]);
    });

    createLessonForm.setFieldValue('quizItemsAttributes', filteredQuestions);
  };

  return (
    step === 'perguntas' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{createLessonForm?.values.body || t('textsCommon.lessonName')}</h2>
        </div>

        <div className="new-questions__body">
          {/* <p className="new-questions__subtitle">Complemente a aula criada adicionando conteúdos. Quando terminar de adicionar todos os conteúdos, clique em finalizar.</p> */}

          <h3>{t('lessons.realtimeQuestionsTitle')}</h3>
          <p className="new-questions__subtitle">{t('lessons.realtimeQuestionsDescription')}</p>
        </div>

        <button
          type="button"
          className="btn btn--primary btn--full btn--outline-dashed"
          onClick={() => setShowModal(true)}
        >
          + {t('nps.buttonAddQuestions')}
        </button>

        <div className="round-dg-wrapper">
          <table className="round-dg round-dg--light">
            <tbody className="round-dg__body">
              {selectedQuestions?.map((item, index) => {
                return (
                  <StepVideoCard
                    openModal={() => {
                      setActiveSelectedQuestion(item);
                      setShowModal(true);
                      setQuizIndex(index);
                    }}
                    key={index}
                    videoQuestion={item}
                    removeQuestion={() => {
                      removeQuestion(index);
                    }}
                  />
                );
              })}
            </tbody>
          </table>
        </div>

        {showModal && (
          <VideoQuestionModal
            quizIndex={quizIndex}
            activeSelectedQuestion={activeSelectedQuestion}
            lesson={lesson}
            lessonId={lessonId}
            show={showModal}
            onClose={() => {
              setShowModal(false);
              setActiveSelectedQuestion(null);
              setQuizIndex(undefined);
            }}
            formikObject={createLessonForm}
          />
        )}
      </div>
    )
  );
};

export default StepVideoQuestions;
