export default function TipsSidebar(props) {
  const { step } = props;

  return (
    <>
      {step === 'nome' && <div className="page__aside" />}
      {step === 'sobre' && <div className="page__aside" />}
      {step === 'prazo' && <div className="page__aside" />}
    </>
  );
}
