import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useClient } from 'jsonapi-react';
import BtnGroup from 'app/components/BtnGroup';
import CustomSelect from 'app/components/CustomSelect';
import MatterBtn, { MatterBtnWrapper } from 'app/components/MatterBtn';
import { navigate, useParams } from '@reach/router';
import * as yup from 'yup';
import promiseRequest from 'app/utils/promiseToast';
import Loader from 'app/components/loader';
import Form from 'app/components/Form';

export default function WeekChallengeContent() {
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loadingInstitution, setLoadingInstitution] = useState(false);
  const [loadingClassroom, setLoadingClassroom] = useState(false);
  const [courses, setCourses] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [taxonomies, setTaxonomies] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [allClassrooms, setAllClassrooms] = useState([]);
  const [subTaxonomies, setSubTaxonomies] = useState([]);
  const [challenge, setChallenge] = useState(null);
  const [deletedQuestions, setDeletedQuestions] = useState([]);
  const [loadingChallenge, setLoadingChallenge] = useState(false);
  const { t } = useTranslation();
  const client = useClient();
  const { promiseToast } = promiseRequest();
  const { challengeId } = useParams();

  const createChallenge = async () => {
    const auxValues = { ...createWeekChallengeForm.values };

    if (auxValues.matters.filter(matter => matter['taxonomy-id']).length < 2) {
      toast.error('O desafio deve conter pelo menos duas matérias');
    } else if (
      auxValues.matters
        .filter(matter => matter['taxonomy-id'])
        .some(item => {
          return item.questions.length === 0;
        })
    ) {
      toast.error('Cada matéria deve conter pelo menos uma pergunta');
    } else {
      const { monday, friday } = getWeekDates(auxValues?.week);

      auxValues['initial-date'] = monday;
      auxValues['end-date'] = friday;

      delete auxValues.week;

      if (auxValues['course-ids'].length > 0 && auxValues['classroom-ids'].length === 0 && auxValues['institution-ids'].length === 0) {
        if (auxValues['course-ids'].some(item => item.value === 'blank')) {
          auxValues['course-ids'] = courses.map(item => item.id);
        } else {
          auxValues['course-ids'] = auxValues['course-ids'].map(item => item.value);
          auxValues['institution-ids'] = [];
          auxValues['classroom-ids'] = [];
        }
      }
      if (auxValues['course-ids'].length === 0 && auxValues['classroom-ids'].length === 0 && auxValues['institution-ids'].length > 0) {
        if (auxValues['institution-ids'].some(item => item.value === 'blank')) {
          auxValues['institution-ids'] = institutions.map(item => item.id);
        } else {
          auxValues['institution-ids'] = auxValues['institution-ids'].map(item => item.value);
          auxValues['course-ids'] = [];
          auxValues['classroom-ids'] = [];
        }
      }
      if (auxValues['course-ids'].length === 0 && auxValues['classroom-ids'].length > 0 && auxValues['institution-ids'].length === 0) {
        if (auxValues['classroom-ids'].some(item => item.value === 'blank')) {
          auxValues['classroom-ids'] = classrooms.map(item => item.id);
        } else {
          auxValues['classroom-ids'] = auxValues['classroom-ids'].map(item => item.value);
          auxValues['course-ids'] = [];
          auxValues['institution-ids'] = [];
        }
      }

      if (auxValues['course-ids'].length === 0 && auxValues['classroom-ids'].length > 0 && auxValues['institution-ids'].length > 0) {
        if (auxValues['classroom-ids'].some(item => item.value === 'blank')) {
          auxValues['classroom-ids'] = classrooms.map(item => item.id);
        } else {
          auxValues['classroom-ids'] = auxValues['classroom-ids'].map(item => item.value);
          auxValues['course-ids'] = [];
          auxValues['institution-ids'] = [];
        }
      }
      if (auxValues['course-ids'].length > 0 && auxValues['classroom-ids'].length > 0 && auxValues['institution-ids'].length > 0) {
        if (auxValues['classroom-ids'].some(item => item.value === 'blank')) {
          auxValues['classroom-ids'] = classrooms.map(item => item.id);
        } else {
          auxValues['classroom-ids'] = auxValues['classroom-ids'].map(item => item.value);
          auxValues['course-ids'] = [];
          auxValues['institution-ids'] = [];
        }
      }

      if (auxValues.availabilityBy === 'Público') {
        auxValues['course-ids'] = [];
        auxValues['institution-ids'] = [];
        auxValues['classroom-ids'] = [];
      }

      const allQuestions = auxValues.matters.reduce((acc, matter) => {
        const { 'taxonomy-id': taxonomyId, questions } = matter;
        return acc.concat(
          questions.map(question => {
            if (challengeId) {
              return { id: question.id, comment: question.comment, description: question.description, 'challenge-alternatives-attributes': question['question-alternatives'], 'taxonomy-id': taxonomyId };
            } else {
              return { comment: question.comment, description: question.description, 'challenge-alternatives-attributes': question['question-alternatives'], 'taxonomy-id': taxonomyId };
            }
          })
        );
      }, []);

      delete auxValues.availabilityBy;
      delete auxValues.matters;
      auxValues['challenge-questions-attributes'] = [...allQuestions, ...deletedQuestions];

      promiseToast({
        url: challengeId ? ['challenges', challengeId] : 'challenges',
        request: auxValues,
        successText: challengeId ? 'Desafio editado!' : 'Desafio criado!',
        errorText: challengeId ? 'Erro ao editar desafio' : 'Erro ao criar desafio'
      }).then(() => {
        navigate('/desafio-da-semana/desafios');
      });
    }
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required('campo obrigatório'),
    'course-ids': yup.array().when('availabilityBy', {
      is: 'Curso',
      then: yup.array().min(1, 'campo obrigatório'),
      otherwise: yup.array().notRequired()
    }),
    'institution-ids': yup.array().when('availabilityBy', {
      is: 'Instituição',
      then: yup.array().min(1, 'campo obrigatório'),
      otherwise: yup.array().notRequired()
    }),
    'classroom-ids': yup.array().when('availabilityBy', {
      is: 'Turma',
      then: yup.array().min(1, 'campo obrigatório'),
      otherwise: yup.array().notRequired()
    }),
    week: yup.string().required('campo obrigatório'),
    'taxonomy-id': yup.string().required('campo obrigatório'),
    matters: yup.array().min(8, 'campo obrigatório')
  });

  const createWeekChallengeForm = useFormik({
    initialValues: {
      title: '',
      'course-ids': [],
      'institution-ids': [],
      'classroom-ids': [],
      week: '',
      'taxonomy-id': '',
      matters: [
        { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 1', questions: [] },
        { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 2', questions: [] },
        { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 3', questions: [] },
        { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 4', questions: [] },
        { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 5', questions: [] },
        { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 6', questions: [] },
        { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 7', questions: [] },
        { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 8', questions: [] }
      ],
      availabilityBy: 'Curso'
    },
    onSubmit: createChallenge,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  function getWeekDates(dateStr) {
    const inputDateUTC = new Date(Date.UTC(parseInt(dateStr.split('-')[0], 10), parseInt(dateStr.split('-')[1], 10) - 1, parseInt(dateStr.split('-')[2], 10)));

    if (isNaN(inputDateUTC.getTime())) {
      throw new Error('Data inválida fornecida.');
    }
    const dayOfWeek = inputDateUTC.getUTCDay();
    const adjustedDayOfWeek = (dayOfWeek + 6) % 7;

    const mondayDate = new Date(inputDateUTC);
    mondayDate.setUTCDate(inputDateUTC.getUTCDate() - adjustedDayOfWeek);
    const fridayDate = new Date(mondayDate);
    fridayDate.setUTCDate(mondayDate.getUTCDate() + 4);

    return {
      monday: mondayDate.toISOString().split('T')[0],
      friday: fridayDate.toISOString().split('T')[0]
    };
  }

  const getCourses = async () => {
    setLoadingCourses(true);
    const { data, error } = await client.fetch('courses/simple');
    if (error) {
      toast.error(t('toast.errorGetCourse'));
    } else {
      setCourses(data);
    }
    setLoadingCourses(false);
  };

  const getTaxonomies = async () => {
    const { data, error } = await client.fetch('taxonomies');
    if (error) {
      toast.error('Erro ao buscar taxonomias');
    } else {
      setTaxonomies(data);
    }
  };

  const getInstitutions = async () => {
    setLoadingInstitution(true);
    const { data, error } = await client.fetch('institutions?simple=true');
    if (error) {
      toast.error('Erro ao buscar instituições');
    } else {
      setInstitutions(data);
    }
    setLoadingInstitution(false);
  };

  const getClassrooms = async () => {
    setLoadingClassroom(true);
    const { data, error } = await client.fetch(`classrooms/simple?`);
    if (error) {
      toast.error('Erro ao buscar instituições');
    } else {
      setAllClassrooms(data);
    }
    setLoadingClassroom(false);
  };

  useEffect(() => {
    getCourses();
    getTaxonomies();
    getInstitutions();
    getClassrooms();
  }, []);

  useEffect(() => {
    if (createWeekChallengeForm?.values?.['institution-ids'].length > 0 || createWeekChallengeForm?.values?.['course-ids'].length > 0 || createWeekChallengeForm?.values?.['classroom-ids'].length > 0) {
      const courseIds = createWeekChallengeForm?.values?.['course-ids'].map(item => item.value);
      const institutionIds = createWeekChallengeForm?.values?.['institution-ids'].map(item => item.value);
      const classroomIds = createWeekChallengeForm?.values?.['classroom-ids'].map(item => item.value);

      const filteredClassrooms = allClassrooms.filter(
        item =>
          ((courseIds.includes(item['course-id']) || (courseIds.length === 0 && institutionIds.length > 0)) && (institutionIds.includes(item['institution-id']) || (institutionIds.length === 0 && courseIds.length > 0))) ||
          classroomIds.includes(item.id)
      );
      setClassrooms(filteredClassrooms);
    }
  }, [createWeekChallengeForm?.values?.['institution-ids'], createWeekChallengeForm?.values?.['course-ids'], createWeekChallengeForm?.values?.['classroom-ids']]);

  const fetchChallenge = async () => {
    setLoadingChallenge(true);
    const { data, error } = await client.fetch(['/challenges', challengeId]);
    if (error) {
      toast.error('Erro ao buscar desafio');
    } else {
      setChallenge(data);
    }
    setLoadingChallenge(false);
  };

  useEffect(() => {
    if (challengeId) {
      fetchChallenge();
    }
  }, [challengeId]);

  useEffect(() => {
    if (challenge) {
      if (courses && institutions && classrooms) {
        const uniqueMatters = challenge['challenge-questions']
          .reduce((acc, current) => {
            if (!acc.find(item => item['taxonomy-id'] === current['taxonomy-id'])) {
              acc.push(current);
            }
            return acc;
          }, [])
          .map(m => {
            const matterQuestions = challenge['challenge-questions']
              .filter(q => q['taxonomy-id'] === m['taxonomy-id'])
              .map(item => {
                return { id: item.id, 'question-alternatives': item['challenge-alternatives'], comment: item.comment, description: item.description, taxonomy_id: item['taxonomy-id'], taxonomy_name: item['taxonomy-name'] };
              });
            return { 'taxonomy-id': m['taxonomy-id'], 'taxonomy-name': m['taxonomy-name'], questions: matterQuestions };
          });

        const completeArr = [];

        for (let i = 0; i < 8 - uniqueMatters.length; i++) {
          completeArr.push({ 'taxonomy-id': '', 'taxonomy-name': `Matéria ${uniqueMatters.length + i + 1}`, questions: [] });
        }

        createWeekChallengeForm.setValues({
          title: challenge.title,
          'course-ids': courses?.filter(item => challenge['course-ids']?.includes(item.id)),
          'classroom-ids': allClassrooms?.filter(item => challenge['classroom-ids']?.includes(item.id)),
          'institution-ids': institutions?.filter(item => challenge['institution-ids']?.includes(item.id)),
          week: challenge['initial-date'],
          'taxonomy-id': challenge.taxonomy.id,
          matters: [...uniqueMatters, ...completeArr],
          availabilityBy: challenge['course-ids'].length > 0 ? 'Curso' : challenge['institution-ids'].length > 0 ? 'Instituição' : challenge['classroom-ids'].length > 0 ? 'Turma' : 'Público'
        });
      }
    }
  }, [challenge, allClassrooms, courses, institutions]);

  useEffect(() => {
    const getTaxonomiesChildren = async () => {
      const { data, error } = await client.fetch(`taxonomies?filter[taxonomy_parent_id]=${createWeekChallengeForm.values['taxonomy-id']}`);
      if (error) {
        toast.error('Erro ao buscar taxonomias');
      } else {
        setSubTaxonomies(data);
      }
    };

    if (createWeekChallengeForm.values['taxonomy-id']) {
      getTaxonomiesChildren();
    }
  }, [createWeekChallengeForm.values['taxonomy-id']]);

  return (
    <div className="page page--wrap">
      <div className="page__col">
        <form
          className="form"
          method="post"
          onSubmit={createWeekChallengeForm.handleSubmit}
        >
          <div className="page__content fadeIn">
            <div className="new-questions__header">
              <h2 className="new-questions__title">Novo desafio</h2>
              <button
                type="submit"
                className="btn btn--wide btn--primary u-ml-auto"
                onClick={() => {}}
              >
                Salvar
              </button>
            </div>
            {loadingChallenge ? (
              <Loader />
            ) : (
              <div className="new-questions__body">
                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="name"
                  >
                    *Nome do desafio
                  </label>
                  {/* <input
                    className="form__control"
                    /> */}
                  <Form.Control
                    placeholder="Nomes criativos despertam a curiosidade dos estudantes"
                    id="name"
                    name="name"
                    type="text"
                    value={createWeekChallengeForm?.values?.title}
                    onChange={e => createWeekChallengeForm?.setFieldValue('title', e.target.value)}
                    counter={100}
                  />
                  {createWeekChallengeForm.errors.title && <span style={{ color: 'red' }}>{createWeekChallengeForm.errors.title}</span>}
                </div>

                <fieldset className="form__row">
                  <legend className="form__label">*Disponibilidade</legend>
                  <BtnGroup
                    options={['Curso', 'Instituição', 'Turma', 'Público']}
                    name={createWeekChallengeForm.values.availabilityBy}
                    onChange={alternative => createWeekChallengeForm.setFieldValue('availabilityBy', alternative)}
                    className="u-mb-2"
                  />

                  {createWeekChallengeForm.values.availabilityBy !== 'Público' && (
                    <>
                      <div className="filter-bar filter-bar--borderless u-mb-0">
                        <div className="filter-bar__inner u-w-100">
                          <div className="u-w-100">
                            <CustomSelect
                              className="filter-bar__multi-select u-w-100"
                              isMulti
                              isDisabled={loadingCourses}
                              value={createWeekChallengeForm.values['course-ids']}
                              options={courses}
                              placeholder="Selecione um curso"
                              onChange={e => {
                                createWeekChallengeForm.setFieldValue('course-ids', e);
                              }}
                            />
                            {createWeekChallengeForm.errors['course-ids'] && <span style={{ color: 'red' }}>{createWeekChallengeForm.errors['course-ids']}</span>}
                          </div>
                          {(createWeekChallengeForm.values.availabilityBy === 'Instituição' || createWeekChallengeForm.values.availabilityBy === 'Turma') && (
                            <div className="u-w-100">
                              <CustomSelect
                                className="filter-bar__multi-select u-w-100"
                                isMulti
                                isDisabled={loadingInstitution}
                                value={createWeekChallengeForm.values['institution-ids']}
                                options={institutions}
                                placeholder="Selecione uma instituição"
                                onChange={e => {
                                  createWeekChallengeForm.setFieldValue('institution-ids', e);
                                }}
                              />
                              {createWeekChallengeForm.errors['institution-ids'] && <span style={{ color: 'red' }}>{createWeekChallengeForm.errors['institution-ids']}</span>}
                            </div>
                          )}

                          {createWeekChallengeForm.values.availabilityBy === 'Turma' && (
                            <div className="u-w-100">
                              <CustomSelect
                                className="filter-bar__multi-select u-w-100"
                                isMulti
                                isDisabled={loadingClassroom || (createWeekChallengeForm.values?.['institution-ids'].length === 0 && !challengeId)}
                                value={createWeekChallengeForm.values['classroom-ids']}
                                options={classrooms}
                                placeholder="Selecione uma turma"
                                onChange={e => {
                                  createWeekChallengeForm.setFieldValue('classroom-ids', e);
                                }}
                              />
                              {createWeekChallengeForm.errors['classroom-ids'] && <span style={{ color: 'red' }}>{createWeekChallengeForm.errors['classroom-ids']}</span>}
                            </div>
                          )}

                          {createWeekChallengeForm.values.availabilityBy === 'Público' && (
                            <CustomSelect
                              className="filter-bar__multi-select u-w-100"
                              isMulti
                              options={[]}
                              placeholder="Selecione um público"
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </fieldset>

                <div className="form__row form__row--columns">
                  <div className="form__col">
                    <label
                      className="form__label"
                      htmlFor="week"
                    >
                      *Semana do desafio
                    </label>
                    <input
                      min={new Date().toISOString().split('T')[0]}
                      className="form__control"
                      id="week"
                      name="week"
                      type="date"
                      value={createWeekChallengeForm.values.week}
                      onChange={e => {
                        createWeekChallengeForm.setFieldValue('week', e.target.value);
                      }}
                    />
                    {createWeekChallengeForm.errors.week && <span style={{ color: 'red' }}>{createWeekChallengeForm.errors.week}</span>}
                  </div>

                  <div className="form__col">
                    <label
                      className="form__label"
                      htmlFor="theme"
                    >
                      *Tema
                    </label>
                    <select
                      disabled={Boolean(challengeId)}
                      className="form__select"
                      name="theme"
                      id="theme"
                      value={createWeekChallengeForm.values?.['taxonomy-id']}
                      onChange={e => {
                        createWeekChallengeForm.setFieldValue('taxonomy-id', e.target.value);
                        createWeekChallengeForm.setFieldValue('matters', [
                          { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 1', questions: [] },
                          { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 2', questions: [] },
                          { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 3', questions: [] },
                          { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 4', questions: [] },
                          { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 5', questions: [] },
                          { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 6', questions: [] },
                          { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 7', questions: [] },
                          { 'taxonomy-id': '', 'taxonomy-name': 'Matéria 8', questions: [] }
                        ]);
                      }}
                    >
                      <option value="">Selecione um tema</option>
                      {taxonomies?.map(item => (
                        <option
                          value={item.id}
                          key={item.id}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {createWeekChallengeForm.errors['taxonomy-id'] && <span style={{ color: 'red' }}>{createWeekChallengeForm.errors['taxonomy-id']}</span>}
                  </div>
                </div>

                {createWeekChallengeForm.values?.['taxonomy-id'] && (
                  <fieldset className="form__row">
                    <legend className="form__label">*Escolha a matéria e crie as perguntas</legend>
                    <p className="form__description">Quando todas as matérias estiverem preenchidas com pelo menos uma pergunta, seu desafio poderá ser publicado. Se precisar, você pode salvar e continuar depois clicando no botão acima.</p>

                    <MatterBtnWrapper>
                      {createWeekChallengeForm?.values?.['matters']?.map((t, i) => (
                        <MatterBtn
                          matter={t}
                          taxonomies={taxonomies}
                          setDeletedQuestions={setDeletedQuestions}
                          challenge={challenge}
                          form={createWeekChallengeForm}
                          subTaxonomies={subTaxonomies}
                          text={t['taxonomy-name']}
                          key={i}
                          matterIndex={i}
                        />
                      ))}
                    </MatterBtnWrapper>
                  </fieldset>
                )}

                <span className="form__text">
                  <strong>* Campo obrigatório!</strong>
                </span>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
