import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import exercisesPng from '../../images/onboarding/questions/exercises.png';
import esploreCardsPng from '../../images/onboarding/questions/esploreCards.png';
import replyQuestionsPng from '../../images/onboarding/questions/replyQuestions.png';
import resultsPng from '../../images/onboarding/questions/results.png';

export function QuestionsOnboarding() {
  const { t } = useTranslation();

  return (
    <section>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.analyzeYourResults')}</h1>
        <p className="text-option-onboarding">{t('onboarding.analyzeYourResultsDesc')}</p>

        <div className="container-shadow">
            <img src={exercisesPng} />
      </div>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.exploreHandoutsCard')}</h1>
        <p className="text-option-onboarding">{t('onboarding.exploreHandoutsCardDesc')}</p>

        <div style={{ width: 300 }}> <img src={esploreCardsPng} /> </div>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.answerQuestions')}</h1>
        <p className="text-option-onboarding">{t('onboarding.answerQuestionsDesc')}</p>

          <img src={replyQuestionsPng} />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.trackYourPerformance')}</h1>
        <p className="text-option-onboarding">{t('onboarding.trackYourPerformanceDesc')}</p>

        <div style={{  }}>
            <img src={resultsPng} /> 
        </div>
      </div>
    </section>
  );
}
