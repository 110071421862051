import React, { useState } from 'react';
import { t } from 'i18next';
import { FiPlus } from 'react-icons/fi';
import SchoolModal from './SchoolModal';
import SchoolCard from './SchoolCard';
import { useCourse } from 'app/hooks/useCourse';
import { useEffect } from 'react';
import { useAuditorium } from 'app/hooks/useAuditorium';
import Loader from 'app/components/loader';

export default function StepSchool(props) {
  const { courses } = useAuditorium();
  const { step, form } = props;

  const [showModal, setShowModal] = useState(false);

  const addCourse = course => {
    const courseIds = Array.isArray(form.values['course-ids']) ? form.values['course-ids'] : [];
    form.setFieldValue('course-ids', [...courseIds, course]);
  };

  const removeCourse = course => {
    form.setFieldValue(
      'course-ids',
      form?.values?.['course-ids'].filter(item => item !== course)
    );
  };

  useEffect(() => {
    form.setFieldValue('course-ids', form?.values?.['course-ids']);
  }, [form?.values?.['course-ids']]);

  return (
    step === 'escola' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{form?.values?.name || t('auditorium.newAuditorium')}</h2>
        </div>

        <div className="new-questions__body">
          <fieldset className="form__row">
            <legend className="form__label">*{t('textsCommon.courses')}</legend>
            <button
              type="button"
              className="btn btn--outline-dashed btn--small btn--full"
              onClick={() => setShowModal(true)}
            >
              <FiPlus className="btn__icon" /> {t('button.add')}
            </button>

            <SchoolModal
              addCourse={addCourse}
              removeCourse={removeCourse}
              selectedCourses={form?.values?.['course-ids']}
              show={showModal}
              onClose={() => setShowModal(false)}
              form={form}
              courses={courses}
            />
          </fieldset>

          <span className="form__text">
            <strong>* {t('auditorium.minCourses')}</strong>
          </span>

          <hr className="u-hr" />
          {form.touched?.['course-ids'] && form.errors?.['course-ids'] && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
          <div className="round-dg-wrapper">
            <table className="round-dg round-dg--light">
              <tbody className="round-dg__body">
                {courses?.length > 0 ? (
                  form?.values?.['course-ids']?.map((course, index) => (
                    <SchoolCard
                      addCourse={addCourse}
                      removeCourse={removeCourse}
                      key={index}
                      course={course}
                    />
                  ))
                ) : (
                  <Loader />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  );
}
