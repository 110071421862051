import React, { useState } from 'react';
import Modal from 'app/components/Modal';
import { useEffect } from 'react';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';

export default function CourseModal(props) {
  const { t } = useTranslation();
  const { show, onClose, courses } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCourses, setFilteredCourses] = useState(courses);

  useEffect(() => {
    if (searchTerm == '') {
      setFilteredCourses(courses);
    } else {
      setFilteredCourses([...courses?.filter(course => course.title != null && course.title.toLowerCase().includes(searchTerm.toLocaleLowerCase()))]);
    }
  }, [searchTerm, show]);

  useEffect(() => {
    if (show) {
      setSearchTerm('');
    }
  }, [show]);

  const authorityLevel = getAuthorityLevel();

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <div className="modal__simple-header">
        <h3 className="modal__simple-title modal__simple-title--with-btn">{t('journey.studentCourse')}</h3>
        <span className="widget__count">
          {filteredCourses?.length}
          <span>{t('journey.thCourses')}</span>
        </span>
      </div>

      <div className="form__row">
        <input
          value={searchTerm}
          aria-label={t('textsCommon.research')}
          className="form__control form__control--search-with-icon"
          style={{ width: 'auto' }}
          placeholder={t('textsCommon.research')}
          type="search"
          name="coursesSearch"
          id="coursesSearch"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="round-dg-wrapper round-dg-wrapper--modal">
        <table className="round-dg round-dg--light">
          <tbody className="round-dg__body">
            {filteredCourses?.length > 0 &&
              filteredCourses?.map((course, index) => (
                <tr
                  className="round-dg__row"
                  key={index}
                >
                  {/* <Link to={`/metricas/engajamento/${course.id}`}> */}
                  <th className="round-dg__cell-header round-dg__cell-header--row">
                    <div className="round-dg__user">
                      <div className="round-dg__inner-user">
                        <h3 className="round-dg__title">{authorityLevel !== 'student' ? <Link to={`/metricas/engajamento/${course.id}`}>{course.title}</Link> : <div>{course.title}</div>}</h3>
                      </div>
                    </div>
                  </th>
                  {/* </Link> */}
                  <td
                    className="round-dg__cell"
                    style={{ width: '150px' }}
                  >
                    {`${course.studentCount} ${t('metrics.students')}`}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
