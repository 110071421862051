import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import Progress from 'app/components/Progress';
import EndExamModal from './EndExamModal';
import { useMutation } from 'jsonapi-react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import SimulatedClock from './SimulatedClock';
import { useTranslation } from 'react-i18next';

export const Alternative = props => {
  const { alternative, question, selectedAlternativeId, setSelectedAlternativeId } = props;
  return (
    <div
      className="question-card__answer null"
      key={alternative.id}
    >
      <div className="question-card__answer-letter">{alternative.name}</div>
      <input
        type="radio"
        name={`question-${question?.id}`}
        id={alternative.id}
        checked={selectedAlternativeId === alternative.id}
        onChange={() => {
          setSelectedAlternativeId(alternative.id);
        }}
      />
      <label
        htmlFor={`${alternative?.id}`}
        dangerouslySetInnerHTML={{ __html: alternative.description }}
      />
    </div>
  );
};

export default function Content(props) {
  const { t } = useTranslation();
  const { selectQuestionBookTaxonomy, currentQuestion, setCurrentQuestion, questions, handleSelectedAnswersChange, examTime, questionBooksUser, updateLastAnswerSubmited, questionBook, taxonomies, activeGroupedQuestions } = props;

  const client = useClient();
  const [addAnswer] = useMutation('answers');
  const [selectedAlternativeId, setSelectedAlternativeId] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [question, setQuestion] = useState();
  const [activeAnswer, setActiveAnswer] = useState();
  const [elapsedTime, setElapsedTime] = useState(0);

  const submitAnswer = async alternativeId => {
    if (alternativeId !== null) {
      // try {
      let response;
      if (!activeAnswer) {
        response = await addAnswer({
          'alternative-id': alternativeId,
          'question-id': question.id,
          'question-books-user-id': questionBooksUser?.id
        });
      } else {
        response = await client.mutate(['answers', activeAnswer.id], {
          'alternative-id': alternativeId,
          'question-id': question.id,
          'question-books-user-id': questionBooksUser?.id
        });
      }

      if (response.error) {
        toast.error(response.error.title);
      } else {
        const newAnswer = {
          id: response.data.id,
          alternativeId: response.data['alternative-id'],
          questionId: response.data['question-id']
        };

        setActiveAnswer(newAnswer);
        updateLastAnswerSubmited(newAnswer);
      }
    } else {
      toast.error(t('toast.errorSelectAlternative'));
    }
  };

  const countCorrectAnswers = () => {
    return questionBooksUser.answers.reduce((accumulator, answer) => {
      accumulator += answer.is_correct ? 1 : 0;
      return accumulator;
    }, 0);
  };

  function formatDateTime(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${hours}h${minutes}${t('exams.minOfDay')} ${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
  }

  const formattedDate = formatDateTime(questionBook['finished-at']);

  const handleFinishExam = () => setShowModal(true);

  useEffect(() => {
    if (currentQuestion) {
      setQuestion(questions.find(q => q.id === currentQuestion));
    }
  }, [selectedAnswers, handleSelectedAnswersChange, currentQuestion]);

  useEffect(() => {
    const newActiveAnswer = taxonomies?.[activeGroupedQuestions]?.answers?.find(a => a?.questionId === question?.id);
    setActiveAnswer(newActiveAnswer);
    setSelectedAlternativeId(newActiveAnswer?.alternativeId);
  }, [question, taxonomies]);

  const prevQuestion = () => {
    const questionIndex = combinedArray.findIndex(qId => qId === currentQuestion) - 1;
    const taxonomyIndex = taxonomies.findIndex(t => t.questions.map(q => q.id).includes(combinedArray[questionIndex]));
    setCurrentQuestion(combinedArray[questionIndex]);
    selectQuestionBookTaxonomy(taxonomyIndex);
  };

  const handleNextQuestion = () => {
    const currentIndex = combinedArray.findIndex(qId => qId === question?.id);
    if (currentIndex <= combinedArray.length) {
      const taxonomyIndex = taxonomies.findIndex(t => t.questions.map(q => q.id).includes(combinedArray[currentIndex + 1]));
      setCurrentQuestion(combinedArray[currentIndex + 1]);
      selectQuestionBookTaxonomy(taxonomyIndex);
    }
    setSelectedAlternativeId(null);
  };

  const getCombinedArray = () => {
    const examQBT = questionBook?.['question-book-taxonomies'];
    if (!examQBT) {
      return [];
    }
    return examQBT.reduce((accumulator, taxonomy) => {
      return accumulator.concat(taxonomy.question_ids);
    }, []);
  };
  const combinedArray = getCombinedArray();

  function countAnswered() {
    return questionBook.taxonomies.reduce((accumulator, question) => {
      accumulator += question.answers.length;
      return accumulator;
    }, 0);
  }

  function sumQuestionIds(exam) {
    const taxonomyArray = exam['question-book-taxonomies']?.map(taxonomy => taxonomy.question_ids) ?? [];
    return taxonomyArray.reduce((accumulator, current) => accumulator + current.length, 0);
  }

  return (
    <main className="main-content">
      <div className="page page--wrap">
        <div className="page__col">
          <div className="page__content">
            <div className="question-card__introduction">
              {t('exams.questionTitle')} {combinedArray.indexOf(currentQuestion) + 1}
            </div>

            {question &&
              question['motivator-texts'] &&
              question['motivator-texts'].map((motivatorText, index) => (
                <div
                  className="question-card__motivator-text"
                  key={index}
                >
                  <div
                    className="question-card__title"
                    dangerouslySetInnerHTML={{ __html: motivatorText.title }}
                  />
                  <div dangerouslySetInnerHTML={{ __html: motivatorText.body }} />
                  <br />
                  <div
                    className="question-card__print"
                    dangerouslySetInnerHTML={{ __html: motivatorText.print }}
                  />
                </div>
              ))}

            <div
              className="question-card__statement"
              dangerouslySetInnerHTML={{ __html: question?.description }}
            />
            <div className="question-card__answers">
              {question?.alternatives &&
                question?.alternatives.map(alternative => (
                  <Alternative
                    submitAnswer={submitAnswer}
                    selectedAlternativeId={selectedAlternativeId}
                    setSelectedAlternativeId={setSelectedAlternativeId}
                    question={question}
                    alternative={alternative}
                    activeAnswer={activeAnswer}
                    key={alternative.id}
                  />
                ))}
            </div>

            {currentQuestion === combinedArray[combinedArray.length - 1] ? (
              <>
                <button
                  type="button"
                  className="btn btn--wide btn--primary"
                  onClick={() => {
                    submitAnswer(selectedAlternativeId);
                    setTimeout(handleFinishExam, 1000);
                  }}
                >
                  {t('exams.replyAndFinish')}
                </button>
                <div className="question-card__nav">
                  {currentQuestion !== combinedArray[0] && (
                    <button
                      type="button"
                      className="btn btn--wide btn--none"
                      onClick={prevQuestion}
                    >
                      <FiArrowLeft /> {t('exams.previous')}
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn--wide btn--primary"
                  disabled={!selectedAlternativeId}
                  onClick={() => {
                    submitAnswer(selectedAlternativeId);
                    handleSelectedAnswersChange(selectedAnswers);
                    handleNextQuestion();
                  }}
                >
                  {t('exams.reply')}
                </button>
                <div className="question-card__nav">
                  {currentQuestion !== combinedArray[0] && (
                    <button
                      type="button"
                      className="btn btn--wide btn--none"
                      onClick={prevQuestion}
                    >
                      <FiArrowLeft /> {t('exams.previous')}
                    </button>
                  )}

                  {currentQuestion !== combinedArray[combinedArray.length - 1] && (
                    <button
                      type="button"
                      className="btn btn--wide btn--none"
                      onClick={handleNextQuestion}
                    >
                      {t('exams.next')} <FiArrowRight />
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="page__aside">
          {currentQuestion > 3 ? (
            <button
              type="button"
              className="btn btn--full btn--outline u-mb-5"
              onClick={() => {
                if (currentQuestion > 3) {
                  // stopTimer();
                  setShowModal(true);
                }
              }}
            >
              {t('exams.sendExam')}
            </button>
          ) : (
            ''
          )}

          <SimulatedClock
            examTime={examTime}
            questionBooksUser={questionBooksUser}
            questionBook={questionBook}
            setElapsedTime={setElapsedTime}
            elapsedTime={elapsedTime}
          />

          <div className="widget">
            <div className="widget__header">
              <div className="widget__title-with-btn">
                <h3 className="widget__title">{t('textsCommon.questions')}</h3>
                <span className="widget__count">
                  <span>
                    {countAnswered()}/{sumQuestionIds(questionBook)}
                  </span>
                </span>
              </div>
            </div>

            <div className="widget__body">
              <Progress valueNow={Math.floor((countAnswered() / sumQuestionIds(questionBook)) * 100)} />
            </div>
          </div>
        </div>
      </div>
      <EndExamModal
        questionBooksUser={questionBooksUser}
        countCorrectAnswers={countCorrectAnswers}
        elapsedTime={elapsedTime}
        countAnswered={countAnswered}
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        formattedDate={formattedDate}
        questionBook={questionBook}
        selectedAnswers={selectedAnswers}
        questions={questions}
      />
    </main>
  );
}
