import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import EmptyState from 'app/components/EmptyState';

import CourseClassroomCommentCard from './CourseClassroomCommentCard';
import { useTranslation } from 'react-i18next';

export default function CourseClassroomComments(props) {
  const { t } = useTranslation();
  const { selectedItemId } = props;
  const client = useClient();
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [text, setText] = useState('');

  const createComment = async () => {
    const { error } = await client.mutate('comments', {
      attributes: {
        body: text,
        'learning-system-item-id': selectedItemId
      }
    });

    if (error) {
      toast.error(t('toast.errorCreateComment'));
    } else {
      toast.success(t('toast.successCreateComment'));
      getComments(selectedItemId);
    }
  };

  const getComments = async selectedItemId => {
    setLoading(true);
    const url = `comments?filter[learning_system_item_id]=${selectedItemId}`;
    const { data, error } = await client.fetch(url);

    setLoading(false);
    if (error) {
      toast.error(t('toast.errorGetComment'));
    } else {
      setComments(data);
    }
  };

  const deleteComment = async id => {
    const { error } = await client.delete(['comments', id]);

    if (error) {
      toast.error(t('toast.errorDeleteComment'));
    } else {
      toast.success(t('toast.successDeleteComment'));
      getComments(selectedItemId);
    }
  };

  useEffect(() => {
    if (selectedItemId) {
      getComments(selectedItemId);
    }
  }, [selectedItemId]);

  const commentsFiltered = comments.filter(comment => comment['comment-parent-id'] === null);

  return (
    <div className="new-questions__col">
      <div className="post">
        <div className="comment-form form">
          <input
            className="form__control"
            placeholder={t('textsCommon.placeholderSendYourComment')}
            student
            value={text}
            onChange={e => setText(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                createComment(text);
                setText('');
              }
            }}
          />
          <div className="textarea-instructions">{t('textsCommon.textPressEnter')}</div>
        </div>

        <div
          className="post__comments"
          style={{ borderTop: '0', marginBottom: '0' }}
        >
          {loading ? (
            <Loader />
          ) : commentsFiltered.length > 0 ? (
            commentsFiltered.map(c => (
              <CourseClassroomCommentCard
                key={c.id}
                comment={c}
                comments={comments}
                deleteComment={deleteComment}
                learningSystemItemId={selectedItemId}
              />
            ))
          ) : (
            <EmptyState
              type="content"
              text={t('emptyState.lmsCommentNot')}
            />
          )}
        </div>
      </div>
    </div>
  );
}
