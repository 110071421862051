import EmptyState from 'app/components/EmptyState';
import { useClient } from 'jsonapi-react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import AverageAccessChart from './AverageAccessChart';
import { useParams } from '@reach/router';
import { useTranslation } from 'react-i18next';

export default function AverageAccessWidget(props) {
  const { t } = useTranslation();
  const { title, moduleId, loading } = props;
  const client = useClient();
  const [averageAccess, setAverageAccess] = useState([]);
  const [totalAccess, setTotalAccess] = useState(0);
  const [timeFilterSelected, setTimeFilterSeleted] = useState(1);

  const userId = useParams().id;

  const getAverageNumbers = async () => {
    if (!loading) {
      let filters = '';
      if (userId) {
        filters += `&user_id=${userId}`;
      }
      if (moduleId) {
        filters += `&learning_system_id=${moduleId}`;
      }
      const { data, error } = await client.fetch(`/reports/learning_systems/average_access?week=${timeFilterSelected}${filters}`);
      if (error) {
        toast.error(t('toast.errorGetMetrics'));
      } else {
        setAverageAccess(data);
        setTotalAccess((data?.items || []).map(i => i[1]).reduce((sum, i) => sum + i, 0));
      }
    }
  };

  useEffect(() => {
    if (userId) {
      getAverageNumbers();
    }
  }, [timeFilterSelected, moduleId, userId]);

  const btnGroup = [
    {
      value: 1,
      id: 'ultima-semana-access',
      timeFilter: 'week',
      label: t('journey.lastWeek')
    },
    {
      value: 4,
      id: 'ultimo-mes-access',
      timeFilter: 'last_month',
      label: t('journey.lastmonth')
    },
    {
      value: 12,
      id: 'tres-meses-access',
      timeFilter: '3_month',
      label: t('journey.threeMonth')
    }
  ];

  return (
    <div className="widget u-h-100">
      <div className="widget__header">
        <div className="widget__title-with-btn">
          <h3 className="widget__title u-mb-2">{title || 'Média de acessos'}</h3>
          <span className="widget__count">
            {totalAccess} <span>{t('journey.totalAcess')}</span>
          </span>
        </div>

        <div
          className="btn-group"
          role="group"
          aria-label="Escolha o período"
        >
          {btnGroup.map((btn, index) => {
            return (
              <React.Fragment key={index}>
                <input
                  type="radio"
                  name="timeFilterAccess"
                  id={btn.id}
                  autoComplete="off"
                  checked={timeFilterSelected == btn.value}
                  onChange={() => setTimeFilterSeleted(btn.value)}
                />
                <label
                  htmlFor={btn.id}
                  className="btn btn--outline"
                >
                  {btn.label}
                </label>
              </React.Fragment>
            );
          })}
        </div>
      </div>

      {averageAccess?.items?.length > 0 ? (
        <div className="widget__body">
          <AverageAccessChart
            name={t('journey.accessesInClasses')}
            color="#442B8C"
            chartData={averageAccess}
            valueSuffix={false}
          />
        </div>
      ) : (
        <EmptyState
          type="register"
          text={t('emptyState.anyAccessForPeriod')}
        />
      )}
    </div>
  );
}
