import React from 'react';
import moment from 'moment';
import { FiCalendar } from 'react-icons/fi';
import { useSession } from 'app/hooks/useSession';
import { ReactComponent as UserAvatar } from 'app/images/user-avatar.svg';
import helpLogo from './img/help-logo.svg';
import { useTranslation } from 'react-i18next';

export default function HelpHeader() {
  const { t } = useTranslation();
  const { session } = useSession();

  return (
    <header className="help-header">
      <div className="help-header__logo">
        <img className="help-header__img" src={helpLogo} alt="" />
        <span className="help-header__separator">/</span>
        <h1 className="help-header__title">{t("helpCenter.helpCenterTitle")}</h1>
      </div>

      <div className="main-nav__user">
        {session?.user?.image?.url ? (
          <img
            className="main-nav__user-avatar"
            src={session?.user?.image?.url}
            alt="foto do usuário"
          />
        ) : (
          <UserAvatar className="main-nav__user-avatar" />
        )}

        <div className="main-nav__user-body">
          <div className="main-nav__user-name">
            {' '}
            {session && session?.user?.name}{' '}
          </div>

          <div className="main-nav__user-day">
            <div className="main-nav__user-day-icon">
              <FiCalendar />{' '}
            </div>
            <div className="main-nav__user-day-date">
              {moment().format(t('datesForm.formatDate'))}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
