import React, { useEffect, useState } from 'react';
import EmptyState from 'app/components/EmptyState';
import { Link } from '@reach/router';
import StudyGuideDataGrid from './StudyGuideDataGrid';
import StudyGuideModal from './StudyGuideModal';
import { useClient } from 'jsonapi-react';

export default function StudyGuide() {
  const [showModal, setShowModal] = useState(false);
  const [studyGuideData, setStudyGuideData] = useState([]);

  const client = useClient();

  const getStudyGuideData = async () => {
    const response = await client.fetch('study_plan_reports/study_guide_progress');
    return response.data;
  }

  useEffect(() => {
    getStudyGuideData().then((data) => {
      if (data.study_guide_progress) setStudyGuideData(data.study_guide_progress);
    });
  }, []);

  return (
    <div className="panorama-card">
      <div className="panorama-card__header">
        <h3 className="panorama-card__title">Guia de estudos</h3>
      </div>

      {studyGuideData.length === 0 && (
        <div className="panorama-card__body panorama-card__body--empty-state">
          <EmptyState
            type="data"
            title="Você ainda não iniciou o Guia"
            text={
              <span>
                Ir para o meu <Link to="/guia-de-estudos">Guia de Estudos</Link>
              </span>
            }
            bgless
          />
        </div>
      )}

      {studyGuideData.length > 0 && (
        <>
          <div className="panorama-card__body">
            <StudyGuideDataGrid data={studyGuideData.slice(0,6)} />
          </div>

          {studyGuideData.length > 6 && <div className="panorama-card__footer">
            <button
              type="button"
              className="btn btn--tiny btn--link"
              onClick={() => setShowModal(true)}
            >
              Ver mais
            </button>

            <StudyGuideModal
              show={showModal}
              onClose={() => setShowModal(false)}
              data={studyGuideData}
            />
          </div>}
        </>
      )}
    </div>
  );
}
