import { useContext } from 'react';
import { QuestionBooksContext } from '../providers/QuestionBooksProvider';

export const useQuestionBook = () => {
  const context = useContext(QuestionBooksContext);

  if (!context) {
    throw new Error('useQuestionBook must be used within a QuestionBooksProvider.');
  }

  return context;
};
