import React, { useEffect } from 'react';
import CanalEducacaoPrivacy from './Terms/CanalEducacaoPrivacy.js';
import DefaultTerm from './Terms/DefaultTerm.js';

const Privacy = props => {
  const { setCurrentPath } = props;
  const appName = process.env.REACT_APP_NAME;

  useEffect(() => {
    setCurrentPath('/politica-de-privacidade');
  }, []);

  switch (appName) {
    case 'canaleducacao':
    case 'maiseducacao':
    case 'egepi':
    case 'encceja':
      return (
        <div className="terms">
          <CanalEducacaoPrivacy />
        </div>
      );
      break;

    default:
      return (
        <div className="terms_background">
          <DefaultTerm />
        </div>
      );
  }
};

export default Privacy;
