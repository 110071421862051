import React from 'react';
import { navigate } from '@reach/router';
import { Img } from './Img';
import { useSession } from 'app/hooks/useSession';

export default function CompletedConquest(props) {
  const { onClose, achievementData } = props;
  const { session } = useSession();

  return (
    <div className={`completed-conquest ${'completed-conquest--primary'}`}>
      <Img
        points={achievementData?.base_score}
        className="completed-conquest__img"
      />

      <div className="completed-conquest__body">
        <h3 className="completed-conquest__title">{achievementData?.title}</h3>
        <p className="completed-conquest__description">{achievementData?.description}</p>
      </div>
      <div className="completed-conquest__footer">
        <button
          type="button"
          className="btn btn--link btn--small btn--full"
          onClick={() => {
            navigate(`/usuario/metricas/${session?.user?.id}`);
            onClose();
          }}
        >
          Veja mais conquistas
        </button>
      </div>
    </div>
  );
}
