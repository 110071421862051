import React, { useState } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { FiFileText, FiHeadphones, FiHeart, FiVideo } from 'react-icons/fi';
import { PiFilePdf } from 'react-icons/pi';

export default function MaterialCard({ material }) {
  const client = useClient();
  const [isFavorite, setIsFavorite] = useState(material?.was_favorited);

  async function handleFavorite(id) {
    await client
      .mutate(`/class_plans/${id}`, {
        favorite: !isFavorite
      })
      .then(() => {
        setIsFavorite(prev => !prev);
      })
      .catch(error => {
        console.log(error);
        toast.error(isFavorite ? 'Erro ao remover favorito' : 'Erro ao favoritar');
      });
  }

  const materialFileUrl = material?.file?.url

  const isPdf = materialFileUrl.includes('.pdf');
  const isVideo = materialFileUrl.includes('.mp4');
  const isAudio = materialFileUrl.includes('.mp3');

  function MaterialIcon() {
    if (isPdf) return <PiFilePdf className="card__icon" />;
    if (isVideo) return <FiVideo className="card__icon" />;
    if (isAudio) return <FiHeadphones className="card__icon" />;

    return <FiFileText className="card__icon" />;
  }

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
        <div className="round-dg__user">
          <div className="card__icon-wrapper">
            <MaterialIcon />
          </div>

          <div className="round-dg__user-inner">
            <h3
              className="round-dg__title"
              title={material?.title}
            >
              {material?.title}
            </h3>
          </div>
        </div>
      </th>

      <td className="round-dg__cell round-dg__cell--sm">{material?.shift}</td>

      <td className="round-dg__cell round-dg__cell--sm">{material?.month}</td>

      <td className="round-dg__cell round-dg__cell--sm">
        <a
          className="btn btn--tiny btn--full btn--outline"
          href={material?.file?.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download
        </a>
      </td>

      <td className="round-dg__cell round-dg__cell--sm">
        <FiHeart
          className={!isFavorite ? 'finder__favorite-icon' : 'finder__favorite-icon finder__favorite-icon--active'}
          onClick={() => handleFavorite(material?.id)}
        />
      </td>
    </tr>
  );
}
