import React, { useState, useEffect } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { useClient } from 'jsonapi-react';
import QuestionCard from './QuestionCard';
import AddQuestionModal from './AddQuestionModal';
import EmptyState from 'app/components/EmptyState';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { navigate, useParams } from '@reach/router';
import getPermission from 'app/utils/getPermission';
import { useSession } from 'app/hooks/useSession';

export default function Content(props) {
  const { selectedQuestions, setSelectedQuestions, filteredQuestions, selectedExam, selectedTaxonomy, loading, path } = props;
  const params = useParams();
  const client = useClient();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [questionbookId, setQuestionbookId] = useState(params?.questionbookId);
  const [taxonomyId, setTaxonomyId] = useState(selectedTaxonomy?.id);
  const currentUser = useSession().session.user;

  useEffect(() => {
    if (selectedTaxonomy) {
      setTaxonomyId(selectedTaxonomy?.id);
    }
    if (params) {
      setQuestionbookId(params?.questionbookId);
    }
  }, [params, selectedTaxonomy]);

  const addQuestion = async question => {
    question['question-book-taxonomy-questions'].push({ id: null, 'question-book-taxonomy-id': taxonomyId, sequence: null });
    const newQuestions = [question, ...selectedQuestions].filter((question, index, arr) => index === arr.findIndex(q => q.id === question.id));

    addOrRemoveQuestion(newQuestions);
  };

  const removeQuestion = question => {
    const newQuestions = [];
    selectedQuestions.map(item => {
      if (item.id === question.id) {
        item['question-book-taxonomy-questions'] = item['question-book-taxonomy-questions'].filter(q => q['question-book-taxonomy-id'] !== parseInt(taxonomyId));
      }
      newQuestions.push(item);
    });
    setSelectedQuestions(newQuestions);
    addOrRemoveQuestion(newQuestions);
  };

  const addOrRemoveQuestion = async questions => {
    const newFilteredQuestions = (questions || []).filter(item => {
      return item?.['question-book-taxonomy-questions']?.some(item => item?.['question-book-taxonomy-id'] == selectedTaxonomy?.id);
    });

    const requestParams = {
      'question-book-taxonomies-attributes': [
        {
          id: taxonomyId,
          'question-ids': newFilteredQuestions.map(item => item.id)
        }
      ]
    };

    const { error } = await client.mutate(`question_books/${questionbookId}`, requestParams);

    if (error) {
      toast.error('Houve um erro');
    } else {
      setSelectedQuestions(newFilteredQuestions);
    }
  };

  useEffect(() => {
    if (selectedExam && selectedExam?.['question-book-taxonomies'].length > 0 && !!params?.taxonomyId === false) {
      const navigateRoute = path.includes('/provas') ? 'provas' : path.includes('/simulados') ? 'simulados' : 'apostilas';
      const firstTaxonomyId = selectedExam?.['question-book-taxonomies']?.[0]?.id;

      navigate(`/${navigateRoute}/gerenciar/${selectedExam?.id}/${firstTaxonomyId}`);
    }
  }, [selectedExam, params]);

  const hasManagePermission = getPermission('Gerenciar provas', 'Provas e simulados');
  const isCreatorOrAdmin = !selectedExam?.['authored-by-user'] || (selectedExam?.['authored-by-user'] && currentUser.id === selectedExam?.creator.id) || currentUser?.profileNames.includes('Administrador');

  return (
    <div className="new-questions__content">
      <div className="new-questions__header">
        <div className="new-questions__group">
          <h1 className="new-questions__title">{t('textsCommon.questions')}</h1>
        </div>
      </div>

      <div className="new-questions__body">
        {!loading && selectedTaxonomy ? (
          <>
            {hasManagePermission && isCreatorOrAdmin && (
              <>
                <button
                  type="button"
                  className={'btn btn--outline-dashed btn--small btn--full u-mb-3'}
                  onClick={() => {
                    if (selectedTaxonomy) {
                      setShowModal(true);
                    } else {
                      toast.error(t('filter.selectSubject'));
                    }
                  }}
                >
                  <FiPlus className="btn__icon" /> {t('button.addQuestions')}
                </button>

                <AddQuestionModal
                  selectedTaxonomy={selectedTaxonomy}
                  filteredQuestions={filteredQuestions}
                  addQuestion={addQuestion}
                  removeQuestion={removeQuestion}
                  taxonomyId={taxonomyId}
                  selectedExam={selectedExam}
                  selectedQuestions={selectedQuestions}
                  setSelectedQuestions={setSelectedQuestions}
                  show={showModal}
                  setShowModal={setShowModal}
                />
              </>
            )}

            <div
              className="round-dg-wrapper"
              style={{ minWidth: '824px', overflowX: 'inherit' }}
            >
              {selectedTaxonomy && selectedExam && filteredQuestions?.length > 0 ? (
                <table className="round-dg round-dg--light">
                  <Droppable droppableId="droppable">
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="draggable-table"
                      >
                        {(filteredQuestions || []).map((q, i) => {
                          return (
                            <Draggable
                              key={i}
                              draggableId={q.id.toString()}
                              index={i}
                            >
                              {provided => (
                                <div
                                  key={i}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="draggable-table-cell"
                                >
                                  <tbody className="round-dg__body">
                                    <QuestionCard
                                      addQuestion={addQuestion}
                                      removeQuestion={removeQuestion}
                                      question={q}
                                      id={i}
                                      key={i}
                                      selectedExam={selectedExam}
                                    />
                                  </tbody>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </table>
              ) : (
                <EmptyState
                  type="data"
                  text={t('emptyState.noQuestionsAdd')}
                />
              )}
            </div>
          </>
        ) : (
          <EmptyState
            type="select"
            text={t('emptyState.subjectSelectText')}
          />
        )}
      </div>
    </div>
  );
}
