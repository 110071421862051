import React, { useState } from 'react';
import Select from 'react-select';
import Modal from 'app/components/Modal';
import UserJourney from 'app/components/UserJourney';
import womanWithComputer from 'app/images/woman-with-computer.svg';
import { useTranslation } from 'react-i18next';

function IndividualWidget(props) {
  const { t } = useTranslation();
  const { users } = props;
  const userList = users.map(u => {
    return { value: u.id, label: u.name };
  });
  const [activeUser, setActiveUser] = useState(null);

  const openModal = e => {
    setActiveUser(e);
  };

  return (
    <div className="individual-widget">
      <div className="form__row">
        <p className="form__label">
          Pesquise o nome do estudante para ver o acompanhamento individual
        </p>

        <Select
          options={userList}
          placeholder={t("textsCommon.research")}
          noOptionsMessage={() => 'Sem opções'}
          onChange={openModal}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
          }}
        />
      </div>
      <img src={womanWithComputer} alt="mulher com computador" />

      <Modal
        show={activeUser !== null}
        onClose={() => setActiveUser(null)}
        contentClassName="modal__content--full"
      >
        {activeUser && <UserJourney activeUser={activeUser} />}
      </Modal>
    </div>
  );
}

export default IndividualWidget;
