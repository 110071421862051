import React from 'react';
import BreadCrumbs from 'app/components/BreadCrumbs';
import CreateWeekChallenge from './CreateWeekChallenge';
import Challenges from './Challenges';
import RankingByRegion from './RankingByRegion';
import RankingByParticipant from './RankingByParticipant';
import ChallengeResult from './ChallengeResult';
import ChallengeTab from './ChallengeTab';
import { ViewChallenge } from './ChallengeResult/ViewChallenge';
import ViewParticipantResult from './ChallengeResult/ViewParticipantResult';

export function WeekChallenge(props) {
  const { selectedTab, uri } = props;

  const breadCrumbs = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: uri,
        name: 'Desafio da Semana',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <ChallengeTab selectedTab={selectedTab} />

      {selectedTab === 'desafios' && <Challenges />}
      {selectedTab === 'ranking-por-regiao' && <RankingByRegion />}
      {selectedTab === 'ranking-por-participante' && <RankingByParticipant />}
      {selectedTab === 'resultados' && <ChallengeResult />}
    </main>
  );
}

export { WeekChallenge as default, CreateWeekChallenge, ViewChallenge, ViewParticipantResult };
