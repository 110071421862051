import React, { useMemo } from 'react';
import { PiStudent } from 'react-icons/pi';
import { Link } from '@reach/router';
import Medal from 'app/components/Medal';
import AccuracyRateTag from '../AccuracyRateTag';

export default function ChallengeRow(props) {
  const { index, total, challenge } = props;

  const availabilityBody = useMemo(() => {
    let availability = [];
    if (challenge.classrooms?.length > 0) {
      availability = challenge.classrooms;
    } else if (challenge.courses?.length > 0) {
      availability = challenge.courses;
    } else if (challenge.institutions?.length > 0) {
      availability = challenge.institutions;
    }

    return availability;
  }, [challenge]);

  return (
    <tr className={total === index + 1 ? 'round-dg__row round-dg__row--last' : 'round-dg__row'}>
      <td className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
        <div className="round-dg__user">
          <div className="round-dg__user-inner">
            <h3
              className="round-dg__title"
              title={challenge.title}
            >
              {challenge.title}
            </h3>
          </div>
        </div>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <span
          className="badge badge--tiny badge--primary"
          title={challenge.taxonomy.name}
        >
          {challenge.taxonomy.name}
        </span>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        {challenge['initial-date'].toLocaleDateString('pt-br', { day: 'numeric' })} a {challenge['end-date'].toLocaleDateString('pt-br')}
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <div
          className="card__badges card__badges--one-line u-mb-0"
          style={{ lineHeight: 1, alignItems: 'center' }}
        >
          {availabilityBody.length > 1 ? (
            <>
              <span
                className="u-text-truncate"
                title={availabilityBody[0].name ?? availabilityBody[0].title}
              >
                {availabilityBody[0].name ?? availabilityBody[0].title}
              </span>
              <span
                className="badge badge--tiny badge--more"
                title={availabilityBody?.slice(1).map(item => item.name ?? item.title)}
              >
                +{availabilityBody?.slice(1).length}
              </span>
            </>
          ) : availabilityBody.length === 1 ? (
            <span
              className="u-text-truncate"
              title={availabilityBody[0].name || availabilityBody[0].title}
            >
              {availabilityBody[0].name || availabilityBody[0].title}
            </span>
          ) : (
            <span
              className="u-text-truncate"
              title={'Público'}
            >
              Público
            </span>
          )}
        </div>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <Medal value={<PiStudent />} />
          <span
            className="u-text-truncate"
            title={`${challenge?.['participation-count']} (${challenge['participants-percentage']}%)`}
          >
            {challenge?.['participation-count']} ({challenge['participants-percentage']}%)
          </span>
        </span>
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <AccuracyRateTag value={challenge?.['accuracy-rate']} />
      </td>
      <td className="round-dg__cell round-dg__cell--sm">
        <Link
          className="btn btn--full btn--outline"
          to={`/desafio-da-semana/resultados/${challenge?.id}`}
        >
          Detalhes
        </Link>
      </td>
    </tr>
  );
}
