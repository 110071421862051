import React from 'react';
import TaskCard from './TaskCard';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { t } from 'i18next';
import EmptyState from 'app/components/EmptyState';

export default function TaskWidget(props) {
  const { currentCourse } = props;
  const [tasks, setTasks] = useState([]);
  const client = useClient();

  const getTasks = async () => {
    const { data, error } = await client.fetch(`/slots?filter[master_slots.slotable_type]=Course&filter[master_slots.slotable_id]=${currentCourse?.id}`);
    if (error) {
      toast.error('Erro ao carregar tarefas');
    } else {
      setTasks(data);
    }
  };

  useEffect(() => {
    getTasks();
  }, []);

  if (tasks.length === 0) {
    return (
      <EmptyState
        type="data"
        title="Ainda não existem tarefas."
      />
    );
  }

  return (
    <div className="widget u-mb-4">
      <div className="widget__header">
        <h3 className="widget__title">{t('events.todayTasks')}</h3>
      </div>

      <div
        className="widget__body"
        style={{ display: 'flex', flexDirection: 'column', gap: '12px', overflowY: 'auto', overflowX: 'hidden', maxHeight: '280px', paddingRight: '8px', marginRight: '-8px' }}
      >
        {tasks.map(task => (
          <TaskCard
            task={task}
            key={task.id}
          />
        ))}
      </div>
    </div>
  );
}
