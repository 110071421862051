import React from 'react';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

function TabNavBar(props) {
  const { t } = useTranslation();
  const { selectedTab } = props;
  const { abilities } = usePlatformAbilities();

  return (
    <div className="tab">
      {abilities?.['avaliacoes-simulados'] && (
        <Link
          className={selectedTab === '/simulados' ? 'tab__link active' : 'tab__link'}
          to="/simulados"
        >
          {t('textsCommon.simulations')}
        </Link>
      )}

      {abilities?.['avaliacoes-provas'] && (
        <Link
          className={selectedTab === '/avaliacoes' ? 'tab__link active' : 'tab__link'}
          to="/avaliacoes"
        >
          {t('exams.toggleExams')}
        </Link>
      )}

      {abilities?.avaliacoes && (
        <Link
          className={selectedTab === '/avaliacoes/meus-resultados' ? 'tab__link active' : 'tab__link'}
          to="/avaliacoes/meus-resultados"
        >
          {t('exams.toggleMyResults')}
        </Link>
      )}
    </div>
  );
}

export default TabNavBar;
