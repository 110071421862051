import React, { useEffect } from 'react';
import { useProgramOfStudies } from 'app/hooks/useProgramOfStudies';
import { navigate } from '@reach/router';
import { Sidebar } from './Sidebar';
import { ListQuestions } from './ListQuestions';
import { ContentQuestion } from './ContentQuestion';

export const UpdatePublicQuestions = () => {
  const { selectedTaxonomy } = useProgramOfStudies();

  useEffect(() => {
    if (!selectedTaxonomy) {
      navigate('/ementa/banco-de-questoes');
    }
  }, []);

  if (!selectedTaxonomy) {
    return null;
  }

  return (
    <div className="main-screen">
      <Sidebar />

      <main className="main-content u-p-0">
        <div className="new-questions">
          <ListQuestions />

          <ContentQuestion />
        </div>
      </main>
    </div>
  );
};
