import { t } from 'i18next';
import { useClient } from 'jsonapi-react';
import React, { useState, useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea';

export default function StepAbout(props) {
  const { step, form, activity } = props;
  const [fileType, setFileType] = useState(t('activity.toggleFile'));

  useEffect(() => {
    if (activity?.['activity-file']?.url && !activity?.['activity-file]']?.file) {
      setFileType('URL');
    }
  }, [activity]);

  return (
    step === 'sobre' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title"> {form?.values?.title || t('textsCommon.newActivity')}</h2>
        </div>

        <div className="new-questions__body">
          <p className="new-questions__subtitle">{t('activity.aboutActivityDescription')}</p>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="description"
            >
              *{t('activity.aboutActivity')}
            </label>

            <TextareaAutosize
              placeholder={t('textsCommon.writeHere')}
              id="description"
              name="description"
              value={form?.values?.description}
              onChange={e => form?.setFieldValue('description', e.target.value)}
            />
            {form?.touched?.description && form?.errors?.description && <span style={{ color: 'red' }}>{form?.errors?.description}</span>}
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="about"
            >
              {t('button.supportMaterial')}
            </label>
            <div
              className="btn-group"
              role="group"
              aria-label="Insira o arquivo"
            >
              {[t('activity.toggleFile'), 'URL'].map((alternative, index) => (
                <React.Fragment key={index}>
                  <input
                    type="radio"
                    name="fileType"
                    autoComplete="off"
                    id={`fileType-${index}`}
                    checked={alternative === fileType}
                    onChange={() => {
                      if (alternative === t('activity.toggleFile')) {
                        form?.setFieldValue('activity-file-attributes.url', null);
                      } else {
                        form?.setFieldValue('activity-file-attributes.file', null);
                      }

                      setFileType(alternative);
                    }}
                  />
                  <label
                    className="btn btn--outline"
                    htmlFor={`fileType-${index}`}
                  >
                    {alternative}
                  </label>
                </React.Fragment>
              ))}
            </div>

            {fileType === t('activity.toggleFile') && (
              <fieldset className="form__row">
                <label className="form__custom-file-input">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    onChange={e => {
                      form?.setFieldValue('activity-file-attributes.file', e.target.files[0]);
                    }}
                  />
                  <span className="form__control">{form?.values?.['activity-file-attributes']?.file ? form.values?.['activity-file-attributes']?.file?.name : 'Clique aqui para fazer upload'}</span>
                </label>
              </fieldset>
            )}

            {fileType === 'URL' && (
              <div className="form__row">
                <input
                  className="form__control"
                  id="url"
                  name="url"
                  type="url"
                  placeholder={t('activity.placeholderSentUrl')}
                  onChange={e => form.setFieldValue('activity-file-attributes.url', e.target.value)}
                  value={form?.values?.['activity-file-attributes'].url}
                />
              </div>
            )}
          </div>
          <span className="form__text">
            <strong>* {t('warning.requiredField')}</strong>
          </span>
        </div>
      </div>
    )
  );
}
