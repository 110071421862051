import React from 'react';
import toast from 'react-hot-toast';
import * as Fi from 'react-icons/fi';

import CapabilityCard from './CapabilityCard';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { useState } from 'react';
import { useEffect } from 'react';
import Error404 from 'app/components/Error404';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import { navigate } from '@reach/router';
import promiseRequest from 'app/utils/promiseToast';

export default function ManageProfile(props) {
  useEffect(() => {
    getRolesGroups();
    getProfileSettings();
  }, []);
  const [roleGroups, setRolesGroups] = useState([]);
  const client = useClient();
  const [profileSettings, setProfileSettings] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { promiseToast, isSubmitting } = promiseRequest();

  useEffect(() => {
    getProfiles();
  }, []);

  const getProfileSettings = async () => {
    const { data, error } = await client.fetch(`profiles/${props.id}`);
    if (error) {
      toast.error('Falha ao carregar definições');
    } else {
      setProfileSettings(data);
      formik.setFieldValue('role-group-item-ids', data['role-group-item-ids']);
    }
  };

  const getProfiles = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`profiles?now=${new Date().getTime()}`);
    if (error) {
      toast.error('Falha ao carregar perfis');
    } else {
      setProfiles(data);
      setLoading(false);
    }
  };

  const Ids = profiles.map(item => item.id);

  const currentId = props.id;

  const profileId = Ids.includes(parseInt(currentId));

  const updateProfileSettings = async values => {
    const requestParams = {
      ...values
    };
    promiseToast({
      url: `profiles/${props.id}`,
      request: requestParams,
      successText: t('toast.successSaveProfile'),
      errorText: 'Erro ao editar perfil'
    }).then(() => {
      getProfileSettings();
    });
  };

  const getRolesGroups = async () => {
    const { data, error } = await client.fetch('role_groups');
    if (error) {
      toast.error('Falha ao carregar página');
    } else {
      setRolesGroups(data);
    }
  };

  const formik = useFormik({
    initialValues: {
      'role-group-item-ids': []
    },
    onSubmit: updateProfileSettings
  });

  function getIcon(icon) {
    switch (icon) {
      case 'FiEdit3':
        return <Fi.FiEdit3 />;
      case 'FiVideo':
        return <Fi.FiVideo />;
      case 'FiUsers':
        return <Fi.FiUsers />;
      case 'FiHome':
        return <Fi.FiHome />;
      case 'FiCoffee':
        return <Fi.FiCoffee />;
      case 'FiList':
        return <Fi.FiList />;
      case 'FiUser':
        return <Fi.FiUser />;
      case 'FiClipboard':
        return <Fi.FiClipboard />;
      case 'FiCalendar':
        return <Fi.FiCalendar />;
      case 'FiCheckCircle':
        return <Fi.FiCheckCircle />;
      case 'FiMessageSquare':
        return <Fi.FiMessageSquare/>;
      default:
        return <Fi.FiStar />;
    }
  }

  return (
    <>
      <main className="main-content main-content--block">
        <div className="filter-bar">
          <button
            onClick={() => navigate(-1)}
            className="filter-bar__back"
          >
            <span className="card__icon-wrapper">
              <Fi.FiChevronLeft className="card__icon" />
            </span>

            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{(profiles ?? []).find(item => item.id == currentId)?.name}</span>
            </span>
          </button>
        </div>

        <p>Marque as opções que se encaixam com o novo perfil</p>

        {loading && <Loader />}

        <form
          style={{ width: '100%' }}
          className="form"
          id="capabilityForm"
          method="post"
          onSubmit={formik.handleSubmit}
        >
          {!loading &&
            roleGroups?.map((item, i) => {
              return (
                <CapabilityCard
                  index={i}
                  formik={formik}
                  icon={getIcon(item.icon)}
                  title={item.name}
                  subtitle={item.description}
                  checkboxes={item['role-group-items']}
                />
              );
            })}

          <button
            disabled={isSubmitting}
            className="btn btn--primary btn--wide"
            type="submit"
          >
            Salvar perfil
          </button>
        </form>
      </main>
    </>
  );
}
