import React from 'react';
import styles from './ReportLegend.module.scss';

function ReportLegendWrapper(props) {
  const { children } = props;

  return <aside className={styles['report-legend-wrapper']}>{children}</aside>;
}

function ReportLegendFigure(props) {
  const { img, caption } = props;

  return (
    <figure className={styles['report-legend__figure']}>
      {img}
      <figcaption className={styles['report-legend__caption']}>{caption}</figcaption>
    </figure>
  );
}

function ReportLegend(props) {
  const { description, figures } = props;

  return (
    <ReportLegendWrapper>
      <div className={styles['report-legend']}>
        <div className={styles['report-legend__header']}>
          <h3 className={styles['report-legend__title']}>Legenda</h3>
        </div>

        <div className={styles['report-legend__body']}>
          {description && <p>{description}</p>}

          {figures?.map((figure, index) => (
            <ReportLegendFigure
              img={figure?.img}
              caption={figure?.caption}
              key={index}
            />
          ))}
        </div>
      </div>
    </ReportLegendWrapper>
  );
}

export { ReportLegend as default, ReportLegendWrapper };
