import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from 'app/components/loader';

export default function BarChart({ data, title }) {
  const chartComponentRef = useRef(null);
  const colors = ['#7B63BF', '#5DC9DD', '#8E7BC3', '#B4A7D6', '#DAD3E9', '#D0E0E3', '#A2C4C9', '#76A5AF', '#45808D', '#134E5B'];
  const series = data?.map(() => ({
    name: 'Acessos',
    data: data.map(item => item.count),
    colorByPoint: true
  }));

  const namesArray = data?.map(item => item?.name);

  useEffect(() => {
    const handleResize = () => {
      if (chartComponentRef.current) {
        chartComponentRef.current.chart.reflow();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const options = {
    chart: {
      type: 'bar',
      height: 300
    },
    credits: {
      enabled: false
    },
    title: {
      text: title
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: namesArray,
      title: {
        text: null
      },
      labels: {
        align: 'left',
        reserveSpace: true,
        style: {
          whiteSpace: 'nowrap',
          width: 112,
          textOverflow: 'ellipsis'
        }
      },
      gridLineWidth: 0,
      lineWidth: 0
    },
    yAxis: {
      title: {
        text: null
      },
      labels: {
        overflow: 'justify'
      },
      gridLineWidth: 0,
      lineWidth: 0,
      allowDecimals: false
    },
    series: series,
    plotOptions: {
      series: {
        pointPadding: 0.5, // Espaçamento entre as barras
        groupPadding: 0.5, // Espaçamento entre grupos de barras
        pointWidth: 30, // Largura das barras horizontalmente
        pointHeight: 80 // Altura das barras verticalmente (aumenta a espessura)
      }
    },
    colors: colors.slice(0, data?.length),
    pointWidth: 50 // Largura da barra
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
}
