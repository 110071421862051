import React, { useEffect, useState } from 'react';
import Content from './Content';
import Sidebar from './Sidebar';
import { useClient } from 'jsonapi-react';
import { useParams } from '@reach/router';
import toast from 'react-hot-toast';
import { t } from 'i18next';

export default function ManageCourse(props) {
  const [course, setCourse] = useState();
  const [loading, setLoading] = useState(false);
  const client = useClient();
  const [currentModuleId, setCurrentModuleId] = useState();
  const [count, setCount] = useState(0);
  const { courseId, moduleId } = useParams();
  const { selectedTab } = props;

  const getCourse = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/courses/${parseInt(courseId)}?detailed=true`);
    if (error) {
      toast.error(t('toast.errorLoadCourseData'));
    } else {
      setCourse(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCourse();
  }, [count]);

  useEffect(() => {
    if (moduleId !== 'm') {
      setCurrentModuleId(moduleId);
    }
  }, [course, moduleId]);

  return (
    <>
      <Sidebar
        selectedTab={selectedTab}
        setCount={setCount}
        loading={loading}
        currentModuleId={currentModuleId}
        setCurrentModuleId={setCurrentModuleId}
        course={course}
      />

      <main className="main-content u-p-0">
        <div className="new-questions">
          <Content
            selectedTab={selectedTab}
            courseId={courseId}
            currentModuleId={currentModuleId}
            course={course}
          />
        </div>
      </main>
    </>
  );
}
