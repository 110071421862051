import React, { useState, useCallback, useEffect, useRef } from 'react';

const MindMap = ({ data }) => {
  const divRef = useRef(null);
  const grouRef = useRef(null);
  const [divWidth, setDivWidth] = useState(0);
  const [divHeight, setDivHeight] = useState(0);
  const [viewBoxWidth, setViewBoxWidth] = useState(1000);

  useEffect(() => {
    if (divRef.current) {
      setDivWidth(divRef.current.offsetWidth);
      setDivHeight(divRef.current.offsetHeight);
    }
  }, [divRef]);

  useEffect(() => {
    if (grouRef.current) {
      setTimeout(() => setViewBoxWidth(grouRef?.current?.getBBox().width + 40), 400);
    }
  }, [viewBoxWidth]);

  const nodeWidth = 180;
  const minNodeHeight = 40;
  const minVerticalSpacing = 20;
  const maxTextWidth = 160;
  const lineHeight = 20;

  // Função recursiva para coletar todos os nós da árvore
  const getAllNodes = nodeList => {
    let nodes = new Set();
    nodeList.forEach(node => {
      nodes.add(node.node);
      if (node.children && node.children.length > 0) {
        const childNodes = getAllNodes(node.children);
        nodes = new Set([...nodes, ...childNodes]);
      }
    });
    return nodes;
  };

  // Estado inicial com todos os nós expandidos
  const [expandedNodes, setExpandedNodes] = useState(new Set());

  // UseEffect para inicializar o estado dos nós expandidos
  useEffect(() => {
    const allNodes = getAllNodes(data);
    allNodes.add('Mapa Mental');
    setExpandedNodes(allNodes);
  }, [data]);

  // Função recursiva para calcular a profundidade máxima da árvore
  const calculateMaxDepth = (node, depth = 0) => {
    if (!node.children || node.children.length === 0) {
      return depth;
    }
    const childrenDepths = node.children.map(child => calculateMaxDepth(child, depth + 1));
    return Math.max(...childrenDepths);
  };

  const maxDepth = calculateMaxDepth({ node: 'Mapa Mental', children: data });

  // Defina um multiplicador para o espaçamento horizontal
  const horizontalSpacingMultiplier = 0.75; // Ajuste este valor para controle fino
  const baseHorizontalSpacing = 180; // Espaçamento base
  const horizontalSpacing = baseHorizontalSpacing / (maxDepth * horizontalSpacingMultiplier);

  const calculateNodeDimensions = node => {
    const lines = wrapText(node.node, maxTextWidth);
    const height = Math.max(minNodeHeight, lines.length * lineHeight + 20);
    return { width: nodeWidth, height, lines };
  };

  const calculateTreeDimensions = useCallback(
    node => {
      const { height } = calculateNodeDimensions(node);
      if (!node.children || node.children.length === 0 || !expandedNodes.has(node.node)) {
        return { height, totalHeight: height };
      }
      const childrenDimensions = node.children.map(calculateTreeDimensions);
      const totalChildrenHeight = childrenDimensions.reduce((sum, dim) => sum + dim.totalHeight + minVerticalSpacing, 0) - minVerticalSpacing;
      const totalHeight = Math.max(height, totalChildrenHeight);
      return { height, totalHeight };
    },
    [expandedNodes]
  );

  const wrapText = (text, width) => {
    const words = text.split(/\s+/);
    const lines = [];
    let line = '';

    words.forEach(word => {
      const testLine = line + word + ' ';
      if (testLine.length * 7 > width) {
        lines.push(line);
        line = word + ' ';
      } else {
        line = testLine;
      }
    });
    lines.push(line);

    return lines;
  };

  const toggleNode = nodeName => {
    setExpandedNodes(prev => {
      const newSet = new Set(prev);
      if (newSet.has(nodeName)) {
        newSet.delete(nodeName);
      } else {
        newSet.add(nodeName);
      }
      return newSet;
    });
  };

  const renderTree = (node, x, y, level) => {
    const children = node.children || [];
    const { width, height, lines } = calculateNodeDimensions(node);
    const treeDimensions = calculateTreeDimensions(node);
    const isExpanded = expandedNodes.has(node.node);

    const elements = [];

    // Render current node
    elements.push(
      <g
        key={`node-${level}-${x}-${y}`}
        className="node"
        transform={`translate(${x},${y - height / 2})`}
        onClick={() => toggleNode(node.node)}
        style={{ cursor: 'pointer' }}
      >
        <rect
          width={width}
          height={height}
          rx="5"
          ry="5"
        />
        {lines.map((line, i) => (
          <text
            key={i}
            x={width / 2}
            y={height / 2 - ((lines.length - 1) * lineHeight) / 2 + i * lineHeight}
            dy=".35em"
            textAnchor="middle"
          >
            {line}
          </text>
        ))}
        {children.length > 0 && (
          <text
            x={width - 15}
            y={height - 10}
            fontSize="16"
            fontWeight="bold"
          >
            {isExpanded ? '-' : '+'}
          </text>
        )}
      </g>
    );

    // Render children and links if expanded
    if (children.length > 0) {
      let childY = y - treeDimensions.totalHeight / 2;
      children.forEach((child, index) => {
        const childDimensions = calculateTreeDimensions(child);
        const childNodeY = childY + childDimensions.totalHeight / 2;

        // Render link
        elements.push(
          <path
            key={`link-${level}-${index}`}
            d={`M${x + width},${y} C${x + width + horizontalSpacing / 2},${y} ${x + width + horizontalSpacing / 2},${childNodeY} ${x + width + horizontalSpacing},${childNodeY}`}
            className={`link ${isExpanded ? 'expanded' : ''}`}
          />
        );

        // Recursively render child and its subtree
        elements.push(
          <g
            key={`child-${level}-${index}`}
            className={`subtree ${isExpanded ? 'expanded' : ''}`}
          >
            {renderTree(child, x + width + horizontalSpacing, childNodeY, level + 1)}
          </g>
        );
        childY += childDimensions.totalHeight + minVerticalSpacing;
      });
    }

    return elements;
  };

  const treeDimensions = calculateTreeDimensions({ node: 'Mapa Mental', children: data });
  const svgHeight = Math.max(treeDimensions.totalHeight + 40 + minVerticalSpacing);
  const svgWidth = '100%';

  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        minHeight: svgHeight + 20,
        backgroundColor: '#f0f0f0'
      }}
    >
      <svg
        width={viewBoxWidth}
        height={svgHeight}

        viewBox={`0 0 ${viewBoxWidth} ${svgHeight}`}
        style={{ display: 'flex', justifyContent: 'center' }}

      >
        <g
          ref={grouRef}
          transform={`translate(20,${svgHeight / 2})`}
        >
          {renderTree({ node: 'Mapa Mental', children: data }, 0, 0, 0)}
        </g>
        <style>{`
          .node rect {
            fill: white;
            stroke: #008000;
            stroke-width: 2px;
          }
          .node text {
            font-size: 12px;
            pointer-events: none;
          }
          .link {
            fill: none;
            stroke: #0000FF;
            stroke-width: 1px;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
          }
          .link.expanded {
            opacity: 1;
          }
          .subtree {
            opacity: 0;
            transform: scale(0.8);
            transition: all 0.3s ease-in-out;
          }
          .subtree.expanded {
            opacity: 1;
            transform: scale(1);
          }
        `}</style>
      </svg>
    </div>
  );
};

export default MindMap;
