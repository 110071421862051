import React from 'react';
import { Link } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';
import IconVerified from '../Icons/IconVerified';

export default function BreadCrumbs(props) {
  const { data, actions, innerContent } = props;

  return (
    <div className="filter-bar filter-bar--bread-crumbs">
      {data?.title && <h1 className="filter-bar__hat">{data?.title}</h1>}

      <div className="filter-bar__inner">
        {data?.nav?.map((item, index, arr) => {
          const lastItem = index === arr.length - 1;
          return lastItem ? (
            <div
              className={!item?.isActive ? 'filter-bar__back filter-bar__back--disabled' : 'filter-bar__back'}
              style={{ cursor: 'default' }}
              title={item?.name}
              key={index}
            >
              <span className="filter-bar__back-container">
                <span className="filter-bar__title">
                  {item?.name} {item?.['official-content'] && <IconVerified />}
                </span>
              </span>
            </div>
          ) : (
            <Link
              className={!item?.isActive ? 'filter-bar__back filter-bar__back--disabled' : 'filter-bar__back'}
              to={item.route || '#'}
              title={item?.name}
              key={index}
            >
              <span className="filter-bar__back-container">
                <span className="filter-bar__title">
                  {item?.name} {item?.['official-content'] && <IconVerified />}
                </span>
              </span>
              <span style={{ padding: '4px', backgroundColor: '#ECE9F8' }}>
                <FiChevronLeft className="card__icon" />
              </span>
            </Link>
          );
        })}

        {innerContent && innerContent}
      </div>

      {actions && actions}
    </div>
  );
}
