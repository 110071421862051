import { useClient } from 'jsonapi-react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

const promiseRequest = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const client = useClient();

  const promiseToast = props => {
    const { url, request, onSuccess, onError, successText, errorText } = props;
    const myPromise = new Promise(async (resolve, reject) => {
      setIsSubmitting(true);
      const { data, error } = await client.mutate(url, request);
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });

    toast.promise(myPromise, {
      loading: () => {
        return 'Carregando';
      },
      success: () => {
        setIsSubmitting(false);
        onSuccess ? onSuccess() : null;
        return successText;
      },
      error: error => {
        console.log(error);
        setIsSubmitting(false);
        onError ? onError() : null;
        return errorText ?? error.title;
      }
    });
    return myPromise;
  };

  return { promiseToast, isSubmitting };
};

export default promiseRequest;
