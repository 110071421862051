import { useSession } from 'app/hooks/useSession';
import { ShowUserAdmin } from './ShowUserAdmin';
import { ShowUserStudent } from './ShowUserStudent';

export function ShowUser(props) {
  const { session } = useSession();

  if (session.user.profileNames.includes('Aluno')) {
    return <ShowUserStudent />;
  } else {
    return <ShowUserAdmin />;
  }
}
