import React from 'react';
import Progress from 'app/components/Progress';

function TopWidget(props) {
  const {topUsers} = props;

  return (
    <div className="top-widget">
      <div className="top-widget__header">
        <h4 className="top-widget__title">Nomes</h4>
        <h4 className="top-widget__title">Nº Acertos/Total</h4>
        <h4 className="top-widget__title u-text-center">Índice de Acerto</h4>
      </div>

      <div className="top-widget__body">
        {topUsers.map}
        {topUsers && topUsers.map((user, index) => (
          <div key={index} className="top-widget__row">
            <div className="top-widget__cell">{index+1}. {user.name}</div>
            <div className="top-widget__cell">{user.hits} / {user.total}</div>
            <div className="top-widget__cell top-widget__cell--center">
              {parseFloat(user.hitPercentage).toFixed(1)}%
              {/*<Progress.Circular valueNow={parseFloat(user.hitPercentage).toFixed(1)} />*/}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TopWidget;
