import React, { useState, useEffect } from 'react';
import { FiClock, FiCheck, FiEdit, FiTrendingUp, FiFileText } from 'react-icons/fi';
import AverageAccessWidget from './AverageAccessWidget';
import KpiCard from './KpiCard';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import StackedPercentageWidget from './StackedPercentageWidget';
import { useTranslation } from 'react-i18next';
import TabNavBar from './TabNavBar';

function QuestionBooksMetricsView(props) {
  const { selectedTab } = props;
  const { t } = useTranslation();
  const [timeFilterSelected, setTimeFilterSeleted] = useState(0);
  const [metrics, setMetrics] = useState([]);
  const client = useClient();
  const btnGroup = [
    {
      id: 'hoje-engajamento',
      timeFilter: 0,
      label: t('questionBooks.today')
    },
    {
      id: 'ultima-semana-engajamento',
      timeFilter: 1,
      label: t('questionBooks.weekCurrent')
    },
    {
      id: 'ultimo-mes-engajamento',
      timeFilter: 4,
      label: t('questionBooks.monthLast')
    },
    {
      id: 'tres-meses-engajamento',
      timeFilter: 12,
      label: t('questionBooks.monthThree')
    }
  ];

  async function getMetrics(week) {
    const { data, error } = await client.fetch(`reports/question_books/metrics${week === 0 ? '' : `?week=${week}`}`);

    if (error) {
      toast.error(t('toast.errorGetCard'));
    } else {
      setMetrics(data);
    }
  }

  useEffect(() => {
    getMetrics(timeFilterSelected);
  }, [timeFilterSelected]);

  return (
    <div className="tab__pane">
      <TabNavBar selectedTab={selectedTab} />

      <div className="tab__container">
        <div
          className="btn-group u-mb-3"
          role="group"
          aria-label="Escolha o tipo de evento"
        >
          {btnGroup.map(btn => {
            return (
              <React.Fragment key={btn.id}>
                <input
                  type="radio"
                  name="timeFilterEngagement"
                  id={btn.id}
                  autoComplete="off"
                  checked={timeFilterSelected === btn.timeFilter}
                  onChange={() => {
                    setTimeFilterSeleted(btn.timeFilter);
                  }}
                />
                <label
                  htmlFor={btn.id}
                  className="btn btn--outline"
                >
                  {btn.label}
                </label>
              </React.Fragment>
            );
          })}
        </div>
        <div className="widget-wrapper">
          <div className="kpi-cards">
            {metrics &&
              metrics.map(metric => (
                <KpiCard
                  cardIcon={metric.icon}
                  cardTitle={metric.label}
                  cardValue={metric.value}
                  cardPercentage={metric.percent}
                  cardPeriod={metric.period}
                />
              ))}
          </div>
        </div>
        <div className="widget-wrapper">
          <StackedPercentageWidget timeFilterSelected={timeFilterSelected} />
        </div>
        <div className="widget-wrapper">
          <AverageAccessWidget
            title={t('questionBooks.answeringAverageTime')}
            timeFilterSelected={timeFilterSelected}
          />
        </div>
      </div>
    </div>
  );
}

export default QuestionBooksMetricsView;
