import React, { useState } from 'react';
import { FiMinimize2, FiHeadphones, FiVideo, FiFileText } from 'react-icons/fi';
import Progress from 'app/components/Progress';
import Modal from 'app/components/Modal';
import { useTranslation } from 'react-i18next';

export default function AccessedMaterialsModal(props) {
  const { t } = useTranslation();
  const { onClose, material } = props;
  // const [studentListModal, setStudentListModal] = useState(false);

  const icon = type => {
    switch (type) {
      case 'Áudio':
        return <FiHeadphones />;
      case 'Vídeo':
        return <FiVideo />;
      default:
        return <FiFileText />;
    }
  };

  return (
    <>
      <div className="modal__simple-header">
        <h3 className="modal__simple-title modal__simple-title--with-btn">{material?.name}</h3>
        <button
          className="modal__close-btn"
          onClick={onClose}
        >
          <FiMinimize2 />
        </button>
      </div>

      <div className="accessed-materials-widget">
        {material.files.map((file, index) => (
          <p
            className="accessed-materials-widget__row"
            key={index}
          >
            <span className="accessed-materials-widget__icon">{icon(file.type)}</span>
            <span
              className="accessed-materials-widget__title"
              title={file.title}
            >
              {file.title}
            </span>
            {file.access} {t('textsCommon.accesses')}
          </p>
        ))}
      </div>
    </>
  );
}
