import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

export default function DataGridGenerated({ questions, setShowModal, setSelectedQuestion, questionbooks }) {
  const handleOpenModal = question => {
    setSelectedQuestion(question);
    setShowModal(true);
  };

  return (
    <div className="round-dg-wrapper">
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: 800 }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th
              style={{ width: 500 }}
              className="round-dg__cell-header "
            >
              Pergunta
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2"> */}
              {/* {questionOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setQuestionOrder('ascendent');
                      setParam('question');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('question');
                      setQuestionOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th
              style={{ width: 140 }}
              className="round-dg__cell-header round-dg__cell-header--sm"
            >
              Total de respostas
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {totalAnswersOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setTotalAnswersOrder('ascendent');
                      setParam('total-answers');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('total-answers');
                      setTotalAnswersOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th
              style={{ width: 140 }}
              className="round-dg__cell-header round-dg__cell-header--sm"
            >
              Quantidade de erros
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {totalErrorsOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setTotalErrorsOrder('ascendent');
                      setParam('total-errors');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('total-errors');
                      setTotalErrorsOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th
              style={{ width: 140 }}
              className="round-dg__cell-header round-dg__cell-header--sm"
            >
              Quantidade de acertos
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {rightAnswersOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setRightAnswersOrder('ascendent');
                      setParam('right-answers');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('right-answers');
                      setRightAnswersOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th
              style={{ width: 140 }}
              className="round-dg__cell-header round-dg__cell-header--sm"
            >
              Tópico
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {topicOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setTopicOrder('ascendent');
                      setParam('topic');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('topic');
                      setTopicOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th
              style={{ width: 140 }}
              className="round-dg__cell-header round-dg__cell-header--sm"
            >
              Cadernos de questões
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {questionBookOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setQuestionBookOrder('ascendent');
                      setParam('question-book');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('question-book');
                      setQuestionBookOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {questions?.map((item, key) => {
            return (
              <tr
                className="round-dg__row u-cursor-pointer"
                key={key}
                onClick={() => handleOpenModal(item)}
              >
                <td
                  dangerouslySetInnerHTML={{ __html: item.description.length > 100 ? item.description.slice(0, 120) + '...' : item.description }}
                  className="round-dg__cell-header round-dg__cell-header--sm"
                />
                <td className="round-dg__cell round-dg__cell--sm">{item['total-answers']}</td>
                <td className="round-dg__cell round-dg__cell--sm">{item['total-incorrect-answers']}</td>
                <td className="round-dg__cell round-dg__cell--sm">{item['total-correct-answers']}</td>
                <td className="round-dg__cell round-dg__cell--sm">{item.topics.map(item => item.label).join(' ')}</td>
                <td className="round-dg__cell round-dg__cell--sm">{item.questionbooks.map(item => item.label).join(' ')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
