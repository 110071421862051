import React, { useState, useEffect } from 'react';
import { FiMenu } from 'react-icons/fi';

import '../styles.scss';

const conceptsData = [
  { id: 'latossolo', name: 'Latossolo' },
  { id: 'loes', name: 'Loes' },
  { id: 'litossolo', name: 'Litossolo' },
  { id: 'massape', name: 'Massapê' }
];

const meaningsData = [
  { id: 'latossolo', meaning: 'Alta permeabilidade à água, podendo ser trabalhados em grande amplitude de umidade' },
  { id: 'loes', meaning: 'Costumam estar posicionados diretamente sobre a rocha formadora. São solos inférteis.' },
  { id: 'litossolo', meaning: 'Solo fértil formado argila, cálcio, feldspato, quartzo e outros minerais.' },
  { id: 'massape', meaning: 'Solo de cor bem escura, quase preta, encontrado na região litorânea do nordeste brasileiro' }
];

function shuffleArray(array) {
  return array.sort(() => Math.random() - 0.5);
}

function Arrow(props) {
  return (
    <svg
      width={17}
      height={146}
      viewBox="0 0 17 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="arrow"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.521 71.506L.523 1.262 3.477.738l12.545 70.756L3.48 145.75l-2.958-.5V71.506z"
        fill="#fafafa"
      />
      <path
        d="M2 1l12.5 70.5-12.5 74"
        stroke="#ECE9F8"
        strokeWidth={3}
      />
    </svg>
  );
}

function CheckSuccess(props) {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        width={17}
        height={17}
        rx={8.5}
        fill="#6FCF97"
      />
      <path
        d="M12 5.25l-5.5 5.958L4 8.5"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ConceptMatchingGame({ conceptsData, meaningsData }) {
  const [shuffledConcepts, setShuffledConcepts] = useState([]);
  const [shuffledMeanings, setShuffledMeanings] = useState([]);
  const [matchedConcepts, setMatchedConcepts] = useState({});
  const [dragging, setDragging] = useState(null);
  const [showCongrats, setShowCongrats] = useState(false);
  const [shakeConcept, setShakeConcept] = useState(null);

  useEffect(() => {
    // Shuffle concepts and meanings when the component mounts
    setShuffledConcepts(shuffleArray([...conceptsData]));
    setShuffledMeanings(shuffleArray([...meaningsData]));
  }, []);

  const handleDragStart = conceptId => {
    setDragging(conceptId);
  };

  const handleDrop = meaningId => {
    if (dragging) {
      // Find the current position of the dragged concept and the concept aligned with the meaning
      const conceptIndex = shuffledConcepts.findIndex(concept => concept.id === dragging);
      const meaningIndex = shuffledMeanings.findIndex(meaning => meaning.id === meaningId);

      if (dragging === meaningId) {
        // If it's the correct concept, swap positions
        const newShuffledConcepts = [...shuffledConcepts];
        const temp = newShuffledConcepts[meaningIndex];
        newShuffledConcepts[meaningIndex] = newShuffledConcepts[conceptIndex];
        newShuffledConcepts[conceptIndex] = temp;

        setShuffledConcepts(newShuffledConcepts);

        // Mark the concept as correctly connected
        setMatchedConcepts(prev => ({
          ...prev,
          [dragging]: true
        }));
      } else {
        // If incorrect, add the error class
        setShakeConcept(dragging);
        setTimeout(() => setShakeConcept(null), 500); // Remove the error class after the animation
      }

      setDragging(null);
      document.querySelectorAll('.droppable').forEach(el => el.classList.remove('highlight-dropzone'));
    }
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.currentTarget.classList.add('highlight-dropzone');
  };

  const handleDragLeave = e => {
    e.currentTarget.classList.remove('highlight-dropzone');
  };

  useEffect(() => {
    if (Object.keys(matchedConcepts).length === conceptsData.length) {
      setShowCongrats(true);
    }
  }, [matchedConcepts]);

  return (
    <div className="game-container">
      <div className={`instructions instructions-container`}>Arraste os conceitos para conectá-los com seus respectivos significados</div>
      <div className="game-content">
        {shuffledMeanings.map((meaning, index) => (
          <div
            key={meaning.id}
            className={matchedConcepts[shuffledConcepts[index].id] ? 'concept-meaning-row concept-meaning-row--matching' : 'concept-meaning-row'}
          >
            {matchedConcepts[shuffledConcepts[index].id] && <CheckSuccess className="concept-meaning-row__icon" />}
            <span className="concept-meaning-number">{index + 1}</span>
            <div
              className={`droppable ${matchedConcepts[shuffledConcepts[index].id] ? 'droppable--matching' : ''}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={() => handleDrop(meaning.id)}
            >
              <Arrow className="droppable__arrow" />

              {meaning.meaning}
            </div>
            <div className="concept-wrapper">
              <div
                className={`draggable ${shakeConcept === shuffledConcepts[index].id ? 'shake flash-red' : ''} ${matchedConcepts[shuffledConcepts[index].id] ? 'draggable--matching' : ''}`}
                draggable={!matchedConcepts[shuffledConcepts[index].id]}
                onDragStart={() => handleDragStart(shuffledConcepts[index].id)}
              >
                <Arrow className="droppable__arrow" />

                <p>{shuffledConcepts[index].name}</p>
                {!matchedConcepts[shuffledConcepts[index].id] && <FiMenu />}
              </div>
            </div>
          </div>
        ))}
      </div>
      {showCongrats && <div className="congrats-message">Parabéns! Você conectou todos os conceitos corretamente!</div>}
    </div>
  );
}

export default ConceptMatchingGame;
