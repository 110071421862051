import React from 'react';
import { FiMoreHorizontal, FiClock } from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import { useTranslation } from 'react-i18next';

export default function ActivityCard(props) {
  const { t } = useTranslation();
  const { title, modulus, unit, isRequired, hours, deleteActivity } = props;

  return (
    <div className={isRequired ? 'card card--danger' : 'card'}>
      <header className="card__header">
        <div className="card__inner-header">
          <h3
            className="card__title"
            title={title}
          >
            {title}
          </h3>

          <Dropdown>
            <Dropdown.Toggle>
              <FiMoreHorizontal />
            </Dropdown.Toggle>

            <Dropdown.Content>
              <Dropdown.Item>{t('button.edit')}</Dropdown.Item>
              <Dropdown.Item onClick={deleteActivity}>{t('button.delete')}</Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </div>

        <div className="card__badges">
          <span className={`badge badge--tiny badge--warning`}>0 para avaliar</span>

          {isRequired ? (
            <span className="badge badge--tiny badge--danger">Obrigatório</span>
          ) : (
            <span
              style={{ opacity: 0 }}
              className="badge badge--tiny badge--danger"
            >
              Obrigatório
            </span>
          )}
        </div>
      </header>

      <div className="card__body">
        <div className="card__columns">
          <p className="card__info">{t('textsCommon.module')} {modulus}</p>
          <p className="card__info">Unidade {unit}</p>
        </div>
        <p
          className="card__info"
          title={`${hours} hora`}
          aria-label={`${hours} hora`}
        >
          <FiClock className="card__icon" />
          <span className="card__icon card__icon--semicircle">
            <FiClock />
          </span>
        </p>
      </div>

      <footer className="card__footer">
        <button className="btn btn--outline btn--small btn--full">{t('button.access')}</button>
      </footer>
    </div>
  );
}
