import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from '@reach/router';
import { CSSTransition } from 'react-transition-group';
import Progress from 'app/components/Progress';

function Widget(props) {
  const {
    show,
    onClose,
    title = 'Widget title',
    description = 'Widget description',
    taxonomies = []
  } = props;

  function closeOnEscapeKeyDown(event) {
    if (event.key === 'Escape') onClose();
  }

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
      document.body.style.overflow = 'visible';
    };
  }, []);

  useEffect(() => {
    if (show) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [show]);

  const nodeRef = React.useRef(null); //Fix react-transition-group

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      unmountOnExit
      nodeRef={nodeRef}
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal" onClick={onClose} ref={nodeRef}>
        <div
          className="modal__content"
          onClick={event => event.stopPropagation()}
        >
          <header className="widget__header">
            <h3 className="widget__title">{title}</h3>
            <p className="widget__description">{description}</p>
          </header>
          <div className="widget__body">
            <div className="hit-widget">
              <div className="hit-widget__header">
                <h4 className="hit-widget__title">Nº Acertos/Total</h4>
              </div>
              <div className="hit-widget__body">
                {taxonomies && taxonomies.map((taxonomy, index) => (
                  <React.Fragment key={index}>
                    <span className="hit-widget__label">{taxonomy.name}</span>
                    <div className="hit-widget__bars">
                      <div
                        className="hit-widget__percentage-bar"
                        style={{ width: `${taxonomy.hitPercentage}%` }}
                      >
                        <span className="u-visually-hidden">{parseFloat(taxonomy.hitPercentage).toFixed(1)}%</span>
                      </div>
                    </div>
                    <span className="hit-widget__numbers">{taxonomy.hits} / {taxonomy.total}</span>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById('modal_root'),
  );
}

export default Widget;
