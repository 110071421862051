import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function MetricsChart(props) {
  const { name, color, chartData, valueSuffix } = props;

  const chartOptions = {
    chart: {
      zoomType: 'xy',
      height: 200,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    xAxis: [
      {
        categories: (chartData?.categories || []),
        crosshair: true,
        title: { text: null },
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        title: {
          text: null,
          style: {
            color: '#32C9D3',
          },
        },
        labels: {
          style: {
            color: '#32C9D3',
          },
        },
      },
      {
        // Secondary yAxis
        title: {
          text: null,
          style: {
            color: '#442B8C',
          },
        },
        labels: {
          style: {
            color: '#442B8C',
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: 'Tarefas criadas',
        type: 'column',
        color: '#32C9D3',
        data: (chartData?.slotsUndone || []),
      },
      {
        name: 'Tarefas feitas',
        type: 'spline',
        color: '#442B8C',
        data: (chartData?.slotsDone || []),
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
}

export default MetricsChart;
