import React, { useEffect, useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
import HeaderPrint from './HeaderPrint';
import mockImg from './img/mockImg.png';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';

export default function EssayPrint({ essayId }) {
  const [essay, setEssay] = useState();
  const client = useClient();

  const getEssay = async () => {
    const { data, error } = await client.fetch(`/essays/${essayId}`);
    if (error) {
      toast.error('Erro ao carregar redação');
    } else {
      setEssay(data);
    }
  };

  useEffect(() => getEssay(), []);

  const romanNumbers = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX, X'];
  return (
    <main className="printed-exam">
      {/* <HeaderPrint />

      <div className="printed-exam__form">
        <p className="printed-exam__form-control">Nome</p>
        <div className="printed-exam__form-row">
          <p className="printed-exam__form-control">Nº</p>
          <p className="printed-exam__form-control">Serie/Curso</p>
          <p className="printed-exam__form-control">Turma/Módulo</p>
          <p className="printed-exam__form-control">Turno</p>
        </div>
        <div className="printed-exam__form-row">
          <p className="printed-exam__form-control printed-exam__form-control--50">Professor</p>
          <p className="printed-exam__form-control printed-exam__form-control--50">Disciplina</p>
        </div>
      </div>

      <div className="printed-exam__info">
        <h2 className="printed-exam__title">$Nome da redação</h2>

        <div className="printed-exam__meta">
          <p className="printed-exam__meta-item">
            <FiCalendar className="printed-exam__icon" />
            <span>
              Entrega até <strong>{new Date(essay?.['delivery-date']).toLocaleDateString()}</strong>
            </span>
          </p>
        </div>

        <p className="printed-exam__description">{essay?.statement}</p>
      </div>

      <div className="course-about__body">
        <h2 className="course-about__title">Textos motivadores</h2>
        {essay?.['essay-motivator-texts'].map((item, index) => {
          return (
            <article>
              <h3 className="course-about__subtitle">Texto {romanNumbers[index]}</h3>
              <p
                className="course-about__description"
                dangerouslySetInnerHTML={{ __html: item.body }}
              />

              <p className="course-about__description">Disponível em: https://brasilescola.uol.com.br/portugues/preconceito-linguistico.htm. Acesso em: 19 ago. 2022 (adaptado).</p>
            </article>
          );
        })}
      </div>

      <div style={{ pageBreakBefore: 'always' }} /> */}

      {/* <HeaderPrint /> */}

      <ul className="printed-exam__sheet">
        {[...Array(31)].map((line, index) => {
          const renderLines = () => {
            if (essay?.['title-defined-by-student'] && index === 0) {
              return <div className="printed-exam__sheet-line" />;
            } else if (index !== 0) {
              return <div className="printed-exam__sheet-line" />;
            }
          };

          return (
            <li
              className="printed-exam__sheet-item"
              key={index}
            >
              {/* {index !== 0 && <div className="printed-exam__sheet-number">{index}</div>} */}
              {renderLines()}
            </li>
          );
        })}
      </ul>
    </main>
  );
}
