import React from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import Modal from 'app/components/Modal';
import { navigate } from '@reach/router';
import moment from 'moment';

export default function ScheduleModal(props) {
  const { show, onClose, onUpdate, navigateLink, auditorium } = props;

  const form = useFormik({
    initialValues: {
      'published-date': moment(auditorium?.['published-at']).format('YYYY-MM-DD'),
      'published-time': moment(auditorium?.['published-at']).format('HH:mm')
    },
    onSubmit: values => submitForm(values)
  });

  const submitForm = async values => {
    const dateTime = `${values['published-date']} ${values['published-time']}`;
    await onUpdate(dateTime);
    toast.success('Agendamento realizado com sucesso');
    navigate(navigateLink);
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
    >
      <form
        action="post"
        className="form"
        onSubmit={form.handleSubmit}
      >
        <h2 className="modal__simple-title">Agendar publicação</h2>

        <div className="form__row form__row--columns">
          <div className="form__col">
            <label
              className="form__label"
              htmlFor="published-date"
            >
              Data
            </label>
            <input
              className="form__control"
              id="published-date"
              name="published-date"
              type="date"
              onChange={form.handleChange}
              defaultValue={form.values['published-date']}
              min={moment().add(0, 'day').format('YYYY-MM-DD')}
            />
          </div>
          <div className="form__col">
            <label
              className="form__label"
              htmlFor="published-time"
            >
              Hora
            </label>
            <input
              className="form__control"
              id="published-time"
              name="published-time"
              type="time"
              onChange={form.handleChange}
              defaultValue={form.values['published-time']}
            />
          </div>
          <div className="form__col" />
        </div>

        <button
          type="submit"
          className="btn btn--wide btn--primary"
        >
          Agendar
        </button>
      </form>
    </Modal>
  );
}
