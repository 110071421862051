import React, { useState } from 'react';
import sendIcon from '../images/icons/send-icon.svg';
import loadingIcon from '../images/icons/loading-icon.svg';
import TextareaAutosize from 'react-autosize-textarea';
import MagicLink from './magicLink';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { searchTextLink } from '../services/linkable.js';
import api from '../services/api';
import { useTranslation } from 'react-i18next';

function SharePost({ addPosts, selectedClassroomId }) {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [magicLinks, setMagicLinks] = useState([]);
  const [magicLinkRemoved, setMagicLinkRemoved] = useState(false);
  const [checkingLink, setCheckingLink] = useState(false);
  const [showMagicLink, setShowMagicLink] = useState(true);
  const client = useClient();

  const createPost = e => {
    e.preventDefault();
    if (!checkingLink) {
      const params = {
        data: {
          type: 'Post',
          attributes: {
            body: text,
            'classroom-id': selectedClassroomId === 'blank' || !selectedClassroomId?.value ? null : selectedClassroomId?.value
          },

          relationships: { 'magic-links': { data: showMagicLink ? magicLinks : [] } }
        }
      };
      client
        .mutate('posts', undefined, { body: JSON.stringify(params) })
        .then(response => {
          const { data, error } = response;
          if (error) {
            toast.error(error.title);
          } else {
            setText('');
            setMagicLinks([]);
            setMagicLinkRemoved(false);
            toast.success(t('toast.successSendMessage'));
            addPosts(data);
          }
        })
        .catch(() => {
          toast.error(t('toast.error'));
        });
    }
  };

  const getLinkInfo = async url => {
    return new Promise(function (resolve, reject) {
      api
        .get(`link_infos/search?url=${url}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.error(error);
          setCheckingLink(false);
        });
    });
  };

  const checkHasLink = e => {
    if (!magicLinkRemoved) {
      let bodyText = e.target.value + ' ';
      let links = searchTextLink(bodyText);
      if (links.length > 0) {
        setCheckingLink(true);
        setMagicLinkRemoved(true);
        getLinkInfo(links[0]).then(magicLink => {
          setMagicLinks([magicLink]);
          setCheckingLink(false);
        });
      }
    }
  };

  return (
    <div className="post-form form">
      <TextareaAutosize
        placeholder={t('feed.textareaPlaceholder')}
        onKeyUp={e => checkHasLink(e)}
        onBlur={e => checkHasLink(e)}
        onPaste={e => checkHasLink(e)}
        onChange={e => setText(e.target.value)}
        value={text}
      />
      <div
        className={`btn btn--primary btn--send ${checkingLink ? 'loading' : ''}`}
        alt="Enviar post"
        onClick={e => createPost(e)}
      >
        <img
          alt=""
          src={checkingLink ? loadingIcon : sendIcon}
        />
      </div>

      {showMagicLink &&
        magicLinks.map((magicLink, index) => (
          <MagicLink
            showClose={true}
            setShowMagicLink={setShowMagicLink}
            key={index}
            magicLink={magicLink.attributes}
          />
        ))}
    </div>
  );
}

export default SharePost;
