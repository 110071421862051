import React, { useState, useEffect } from 'react';
import TaxonomyItem from '../components/reports/journeyTaxonomyItem';
import TaxonomyByDataChart from '../components/reports/taxonomyByDataChart';
import TaxonomyByDificultyChart from '../components/reports/taxonomyByDificultyChart';
import EmptyState from '../components/EmptyState';
import Loader from 'app/components/loader';

import api from '../services/api.js';
import moment from 'moment';
import commingSoon from '../images/coming-soon-ilustraded-estudologia 1.svg';
import { useTranslation } from 'react-i18next';

function UserJourney(props) {
  const { t } = useTranslation();
  const { activeUser } = props;
  const [taxonomiesReport, setTaxonomiesReport] = useState([]);
  const [dateCategories, setDateCategories] = useState([]);
  const [dificultyCategories, setDificultyCategories] = useState([]);
  const [taxonomy, setTaxonomy] = useState();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const initialDate = moment().startOf('year').format('YYYY-MM-DD');
    const endDate = moment().startOf('month').subtract(1, 'days').format('YYYY-MM-DD');
    api
      .get(`reports/journey?user_id=${activeUser.value}&initial_date=${initialDate}&end_date=${endDate}`)
      .then(response => {
        setDateCategories(response.date_categories);
        setDificultyCategories(response.difficulty_categories);
        setTaxonomiesReport(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <div className="main-content main-content--no-margin">
      {taxonomiesReport.length === 0 ? (
        <div className="side-content side-content--journey">
          <Loader />
        </div>
      ) : (
        <>
          <div className="side-content side-content--journey">
            <div className="side-content__title">Rendimento por assunto</div>
            <div className="side-content__subtitle"></div>
            <div className="subject-list">
              {taxonomiesReport.map((taxonomy, i) => (
                <TaxonomyItem
                  key={i}
                  taxonomy={taxonomy}
                  setTaxonomy={setTaxonomy}
                />
              ))}
            </div>
          </div>

          <div className="side-content side-content--transparent">
            {taxonomy ? (
              <>
                <div className="side-content__title">{taxonomy.name}</div>
                <div className="chart-content">
                  <div className="chart-content__title">Marcações</div>
                  <div className="chart-content__image">
                    <TaxonomyByDataChart
                      categories={dateCategories}
                      data={taxonomy.date_data}
                    />
                  </div>
                </div>
                <div className="chart-content">
                  <div className="chart-content__title">Marcações por dificuldade</div>
                  <div className="chart-content__image">
                    <TaxonomyByDificultyChart
                      categories={dificultyCategories}
                      data={taxonomy.difficulty_data}
                    />
                  </div>
                </div>
              </>
            ) : (
              <EmptyState
                type="select"
                title={t('emptyState.selectOptionTitle')}
                text={t('emptyState.selectSubjectPerformanceText')}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default UserJourney;
