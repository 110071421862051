import React, { useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { FiTrash2 } from 'react-icons/fi';
import { t } from 'i18next';

export default function StepStatement(props) {
  const { step, form, setDeletedTexts } = props;

  const handleMotivatorTextChange = (data, index) => {
    const motivatorText = data.getData();
    form.setFieldValue(form.setFieldValue(`essay_motivator_texts_attributes.${index}.body`, motivatorText));
  };

  return (
    step === 'enunciado' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">Nova redação</h2>
        </div>

        <div className="new-questions__body">
          <div className="form__row">
            <label
              className="form__label"
              htmlFor="topic"
            >
              *Tema
            </label>
            <input
              className="form__control"
              placeholder="Digite um tema para a redação"
              id="topic"
              name="topic"
              type="text"
              value={form?.values?.topic}
              onChange={e => form?.setFieldValue('topic', e.target.value)}
            />
            {form.touched.topic && form.errors.topic && <span style={{ color: 'red' }}>{form.errors.topic}</span>}
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="statement"
            >
              *Enunciado
            </label>

            <TextareaAutosize
              placeholder="Dê instruções aos estudantes sobre a redação"
              id="statement"
              name="statement"
              value={form?.values?.statement}
              onChange={e => form.setFieldValue('statement', e.target.value)}
            />
            {form.touched.statement && form.errors.statement && <span style={{ color: 'red' }}>{form.errors.statement}</span>}
          </div>

          {form?.values?.essay_motivator_texts_attributes?.map((mt, i) => {
            const removeMotivatorText = () => {
              const motivatorTexts = form.values.essay_motivator_texts_attributes.filter((item, index) => {
                if (index !== i) {
                  return true;
                } else {
                  if (item.id) {
                    setDeletedTexts(prevState => [...prevState, { ...item, _destroy: true }]);
                  }
                }
              });

              form.setFieldValue('essay_motivator_texts_attributes', motivatorTexts);
            };

            return (
              <div
                className="form__row form__row--columns"
                key={i}
              >
                <fieldset className="form__col">
                  <legend
                    className="form__label"
                    htmlFor="statement"
                  >
                    *Texto motivador
                  </legend>

                  <CKEditor
                    data={form?.values?.essay_motivator_texts_attributes?.[i]?.body}
                    onChange={(event, editor) => {
                      handleMotivatorTextChange(editor, i);
                    }}
                    editor={Editor}
                  />
                  {form?.touched.essay_motivator_texts_attributes && form?.errors?.essay_motivator_texts_attributes?.[i]?.body && <span style={{ color: 'red' }}>{form?.errors?.essay_motivator_texts_attributes?.[i]?.body}</span>}
                </fieldset>

                {form?.values?.essay_motivator_texts_attributes?.length > 1 && (
                  <FiTrash2
                    className="list-alternatives__icon"
                    onClick={removeMotivatorText}
                  />
                )}
              </div>
            );
          })}

          <div className="form__row">
            <button
              type="button"
              className="btn btn--link"
              onClick={() => form?.setFieldValue('essay_motivator_texts_attributes', [...form?.values?.essay_motivator_texts_attributes, { body: '' }])}
            >
              Adicionar outro texto motivador
            </button>
          </div>

          <span className="form__text">
            <strong>* {t('warning.requiredField')}</strong>
          </span>
        </div>
      </div>
    )
  );
}
