// import React from "react";
import React, { useState, useEffect } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import TaxonomyItem from '../components/reports/journeyTaxonomyItem';
import TaxonomyByDataChart from '../components/reports/taxonomyByDataChart';
import TaxonomyByDificultyChart from '../components/reports/taxonomyByDificultyChart';
import EmptyState from '../components/EmptyState';
import Loader from 'app/components/loader';

import api from '../services/api.js';
import moment from 'moment';
import commingSoon from '../images/coming-soon-ilustraded-estudologia 1.svg';
import { useTranslation } from 'react-i18next';

function Journey() {
  const { t } = useTranslation();
  const [rangeDates, setRangeDates] = useState([new Date(), new Date(new Date().setDate(new Date().getDate() + 30))]);
  const [taxonomiesReport, setTaxonomiesReport] = useState([]);
  const [dateCategories, setDateCategories] = useState([]);
  const [dificultyCategories, setDificultyCategories] = useState([]);
  const [taxonomy, setTaxonomy] = useState();

  const fetchData = () => {
    const formattedStartDate = moment(rangeDates[0]).format('YYYY-MM-DD');
    const formattedEndDate = moment(rangeDates[1]).format('YYYY-MM-DD');

    api
      .get(`reports/journey?initial_date=${formattedStartDate}&end_date=${formattedEndDate}`)
      .then(response => {
        setDateCategories(response.date_categories);
        setDificultyCategories(response.difficulty_categories);
        setTaxonomiesReport(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  useEffect(() => fetchData(), [rangeDates]);

  return (
    <div className="main-screen">
      <div className="main-content">
        {/*
        <div className="comming-soon">
          <h2 className="comming-soon__title">Em breve você vai poder acompanhar o seu <br/><b>rendimento e histórico de interações</b>.</h2>
          <div className="comming-soon__image"><img alt="" src={commingSoon}/></div>
        </div>
      */}
        {taxonomiesReport.length === 0 ? (
          <div className="side-content side-content--journey">
            <Loader />
          </div>
        ) : (
          <>
            <div className="side-content side-content--journey">
              <DateRangePicker
                onChange={setRangeDates}
                value={rangeDates}
                clearIcon={null}
                locale="pt-BR"
              />

              <div
                className="side-content__title"
                style={{ marginTop: 30 }}
              >
                {t('metrics.performanceSubject')}
              </div>
              <div className="side-content__subtitle">Selecione a matéria para mais detalhes do rendimento</div>
              <div className="subject-list">
                {taxonomiesReport.map((taxonomy, i) => (
                  <TaxonomyItem
                    key={i}
                    taxonomy={taxonomy}
                    setTaxonomy={setTaxonomy}
                  />
                ))}
              </div>
            </div>

            <div className="side-content side-content--transparent">
              {taxonomy ? (
                <>
                  <div className="side-content__title">{taxonomy.name}</div>
                  <div className="chart-content">
                    <div className="chart-content__title">Marcações</div>
                    <div className="chart-content__image">
                      <TaxonomyByDataChart
                        categories={dateCategories}
                        data={taxonomy.date_data}
                      />
                    </div>
                  </div>
                  <div className="chart-content">
                    <div className="chart-content__title">Marcações por dificuldade</div>
                    <div className="chart-content__image">
                      <TaxonomyByDificultyChart
                        categories={dificultyCategories}
                        data={taxonomy.difficulty_data}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <EmptyState
                  type="select"
                  title={t("emptyState.selectOptionTitle")}
                  text={t("emptyState.selectSubjectPerformanceText")}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Journey;
