import { useClient } from 'jsonapi-react';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const QuizModal = props => {
  const { t } = useTranslation();
  const { finishVideoModal, currentQuiz, answered, setAnswered, setCurrentQuiz, setCanCloseModal, setUnansweredQuestions, unansweredQuestions, resetQuizState } = props;
  const client = useClient();
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState(false);
  const letters = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

  const isLastQuestion = unansweredQuestions?.[unansweredQuestions?.length - 1]?.id === currentQuiz?.id;

  const sendAnswer = async () => {
    setLoading(true);
    const requestParams = {
      'quiz-item-alternative-id': selectedAnswer
    };
    const { data, error } = await client.mutate('quiz_answers', requestParams);
    if (error) {
      toast.error(t('toast.errorSendAnswer'));
    } else {
      if (!finishVideoModal) {
        setUnansweredQuestions(prevState => prevState.filter(item => item.id !== currentQuiz.id));
      }
      if (isLastQuestion) {
        setCanCloseModal(true);
      }
      setAnswered(true);
      setResponse(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      setButtonText(t('lms.buttonTextSendingAnswer'));
    }
    if (!answered) {
      setButtonText(t('button.sendAnswer'));
    }
    if (answered && !loading) {
      if (response?.['is-correct']) {
        setButtonText(t('button.textAnswerCorret'));
      } else {
        const correctLetterPosition = currentQuiz?.['quiz-item-alternatives']?.map(item => item.id)?.indexOf(response?.['correct-quiz-item-alternative-id']);
        setButtonText(`${t('button.textAnswerIncorrect')} ${letters[correctLetterPosition]}`);
      }
    }
  }, [loading, answered]);

  const buttonClass = () => {
    if (!answered) {
      return 'btn btn--primary';
    }
    if (answered) {
      if (response?.['is-correct']) {
        return 'btn btn--rightAnswer';
      } else {
        return 'btn btn--danger';
      }
    }
  };
  const currentQuestionIndex = unansweredQuestions.findIndex(item => item?.id === currentQuiz?.id);

  const handleNextQuestion = () => {
    if (answered) {
      setCurrentQuiz(unansweredQuestions[currentQuestionIndex + 1]);
      setAnswered(false);
      setSelectedAnswer(null);
    }
  };

  return (
    <>
      {finishVideoModal && <span>Pergunta {currentQuestionIndex + 1 + '/' + unansweredQuestions.length}</span>}

      <div className="card__badges card__badges--one-line">
        {(currentQuiz?.taxonomies || []).map(item => (
          <span className="badge badge--primary">{item.name}</span>
        ))}
      </div>
      <div className="question-card__introduction">{t('lms.titleQuestionLms')}</div>
      <div className="question-card__statement">{currentQuiz?.body}</div>
      <div className="question-card__answers">
        {currentQuiz?.['quiz-item-alternatives']?.map((alternative, index) => (
          <div className="question-card__answer">
            <div className="question-card__answer-letter">{letters[index]}</div>
            <input
              name="answers"
              type="radio"
              id={`alternative-${index}`}
              value={alternative.id}
              onChange={() => setSelectedAnswer(alternative.id)}
              checked={selectedAnswer === alternative.id}
            />
            <label htmlFor={`alternative-${index}`}>{alternative.title}</label>
          </div>
        ))}
      </div>
      <div
        className="form__row"
        style={{ gap: 20, display: 'flex' }}
      >
        <button
          className={buttonClass()}
          onClick={sendAnswer}
        >
          {buttonText}
        </button>

        {finishVideoModal ? (
          <button
            disabled={!answered}
            className="btn btn--primary"
            onClick={() => {
              if (isLastQuestion && answered) {
                console.log('resetQuizState')
                resetQuizState()
              }
              else {
                handleNextQuestion()
              }
            }}
          >
            {isLastQuestion ? t('button.finish') : t('button.nextQuestion')}
          </button>
        ) : null}
      </div>
    </>
  );
};

export default QuizModal;
