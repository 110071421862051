import React, { useState } from 'react';
import { useFormik } from 'formik';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import EmptyState from 'app/components/EmptyState';
import NoteCard from './NoteCard';
import RichTextEditor from './RichTextEditor';
import { useSession } from 'app/hooks/useSession';
import { t } from 'i18next';
import { Tooltip } from 'react-tooltip';
import Loader from '../loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndScroll from '../endScroll';
import { useEffect } from 'react';
import { useNote } from 'app/hooks/useNote';

export default function ClassroomNotes(props) {
  const { viewClassroom, setViewClassroom, setSearchTerm, classrooms, setClassroomData, notes, searchTerm, activeNote, setActiveNote } = props;

  const { updateNewClassroomNotes, hasMoreNewClassroomNotes } = useNote();

  const [pageNumber, setPageNumber] = useState(1);

  const { session } = useSession();

  const filters = useFormik({
    initialValues: {
      filter: 'blank'
    }
  });

  const options = [
    { name: 'Minhas anotações', id: 1 },
    { name: 'Anotações importantes', id: 2 },
    { name: 'Novas anotações', id: 3 }
  ];

  const filterNotes = notes => {
    let filteredNotes = notes;

    if (searchTerm !== '') {
      filteredNotes = filteredNotes.filter(a => a?.title != null && a?.title.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    if (filters.values.filter === '1') {
      const currentUser = session.user.name;
      filteredNotes = filteredNotes.filter(note => note.user.name === currentUser);
    } else if (filters.values.filter === '2') {
      filteredNotes = filteredNotes.filter(note => note.bookmarked === true);
    } else if (filters.values.filter === '3') {
      filteredNotes = filteredNotes.filter(note => note.new_note === true);
    }

    return filteredNotes;
  };

  const filteredNotes = filterNotes(notes);

  useEffect(() => {
    if (pageNumber !== 1) {
      updateNewClassroomNotes(pageNumber);
    }
  }, [pageNumber]);

  if (!classrooms?.length > 0)
    return (
      <EmptyState
        type="register"
        title={'Você ainda não está enturmado'}
        text={'Para fazer anotações em uma turma, você precisa fazer parte dela. Fale com seu coordenador para enturmar você.'}
        cta={{ text: 'Falar com o coordenador', onClick: () => console.log('Ação de contato com o coordenador') }}
      />
    );

  if (!viewClassroom) {
    return (
      <InfiniteScroll
        dataLength={classrooms.length}
        next={() => setPageNumber(pageNumber + 1)}
        hasMore={hasMoreNewClassroomNotes}
        loader={<Loader />}
        // endMessage={<EndScroll />}
        className="u-w-100"
      >
        <div className="notes__body">
          {!viewClassroom && (
            <div
              className="tab__cards u-w-100"
              style={{ padding: '16px 0', alignSelf: 'flex-start' }}
            >
              {classrooms ? (
                classrooms.map((classroom, index) => (
                  <div
                    className="card card--group"
                    key={index}
                  >
                    <div
                      className="card__header"
                      style={{ flexGrow: 1 }}
                    >
                      <div className="card__inner-header">
                        <h3 className="card__title">{classroom?.institution?.name}</h3>
                      </div>

                      <Tooltip id="institution" />
                      <p
                        className="u-text-truncate text-classroom-note"
                        data-tooltip-id="institution"
                        data-tooltip-content={classroom?.course?.title}
                      >
                        {classroom?.course?.title}
                      </p>

                      <Tooltip id="course" />
                      <p
                        className="u-text-truncate text-classroom-note"
                        data-tooltip-id="course"
                        data-tooltip-content={classroom?.title}
                      >
                        {classroom?.title}
                      </p>

                      {classroom['has-new-note'] && <span className="badge badge--warning badge--tiny">Novas anotações</span>}
                      <span className="badge badge--warning badge--tiny"></span>
                    </div>
                    <div className="card__footer">
                      <button
                        type="button"
                        className="btn btn--small btn--outline"
                        onClick={() => {
                          setViewClassroom(true);
                          setClassroomData(classroom);
                        }}
                      >
                        {t('button.access')}
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <Loader />
              )}
            </div>
          )}
        </div>
      </InfiniteScroll>
    );
  }

  return (
    <div className="notes__body">
      {viewClassroom && (
        <>
          <aside className="notes__aside">
            <input
              className="form__control form__control--search-with-icon u-mb-2"
              aria-label={'Pesquisar'}
              placeholder={t('textsCommon.research')}
              type="search"
              name="search"
              id="search"
              onChange={e => setSearchTerm(e.target.value)}
            />
            <FilterSelectionBox
              blankLabel={'Selecione um filtro'}
              label={null}
              value={filters.values.filter}
              onChange={e => filters.setFieldValue('filter', e.target.value)}
              options={options}
            />
            <nav className="notes__nav">
              {filteredNotes.map(note => (
                <NoteCard
                  note={note}
                  index={note.id}
                  key={note.id}
                  activeNote={activeNote}
                  setActiveNote={setActiveNote}
                  user={{ image: note.user.image.url, name: note.user.name, id: note.user.id }}
                />
              ))}
            </nav>
          </aside>

          <div className="notes__view">
            <RichTextEditor
              note={activeNote}
              emptyStateTitle={t('emptyState.titleViewClassNotes')}
              emptyStateText={t('emptyState.textViewClassNotes')}
            />
          </div>
        </>
      )}
    </div>
  );
}
