import React, { useEffect, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { useParams } from '@reach/router';

import MainSidebar from '../../../components/Sidebar';
import Modal from '../../../components/Modal';
import { UpdateQuestionBookContext } from './provider';
import Loader from '../../../components/loader';
import { useTranslation } from 'react-i18next';

export const Sidebar = () => {
  const { t } = useTranslation();
  const { taxonomiesLoading, firstLevelTaxonomies, getTaxonomies, selectedTaxonomy, selectedQuestionBookTaxonomies, setSelectedTaxonomy, addTaxonomyToQuestionBook } = useContext(UpdateQuestionBookContext);

  const [show, setShow] = useState(false);

  const { questionBookId } = useParams();

  const addTaxonomyToQuestionBookForm = useFormik({
    initialValues: {
      taxonomy: null
    },
    onSubmit: values => {
      addTaxonomyToQuestionBook(values);
      setShow(false);
    },
    validate: values => {
      const errors = {};

      if (!values.taxonomy) {
        toast.error('Selecione uma taxonomia.');
        errors.taxonomy = 'Required';
      }

      return errors;
    }
  });

  useEffect(() => getTaxonomies(questionBookId), []);

  return (
    <MainSidebar>
      <nav className="notebook-sidebar__nav">
        <button
          type="button"
          onClick={() => history.back()}
          className="btn btn--primary btn--full u-mb-4"
        >
          {t('button.back')}
        </button>

        {taxonomiesLoading ? (
          <Loader />
        ) : (
          <>
            <button
              type="button"
              onClick={() => setShow(true)}
              className="btn btn--full btn--outline u-mb-4"
            >
              Adicionar Matéria
            </button>

            {selectedQuestionBookTaxonomies.map((t, i) => (
              <div
                key={i}
                className={`taxonomies__item${selectedTaxonomy.id === t.id ? ' active' : ''}`}
                onClick={() => setSelectedTaxonomy(t)}
              >
                <span
                  className="notebook-sidebar__link-name"
                  title={t.name}
                >
                  {t.name}
                </span>
              </div>
            ))}
          </>
        )}
      </nav>

      <Modal
        show={show}
        onClose={() => setShow(false)}
      >
        {!taxonomiesLoading && firstLevelTaxonomies && (
          <form
            method="post"
            className="form"
            onSubmit={addTaxonomyToQuestionBookForm.handleSubmit}
          >
            <h2 className="form__title">{t('textsCommon.buttonAddMatters')}</h2>

            <div className="form__row">
              <label
                className="form__label"
                htmlFor="matter"
              >
                Selecione a matéria
              </label>
              <select
                className="form__select"
                name="matter"
                id="matter"
                onChange={e => addTaxonomyToQuestionBookForm.setFieldValue('taxonomy', parseInt(e.target.value))}
              >
                <option>Selecione uma taxonomia</option>

                {firstLevelTaxonomies
                  .filter(t => !selectedQuestionBookTaxonomies.map(i => i.id).includes(t.id))
                  .map(t => (
                    <option
                      key={t.id}
                      value={t.id}
                    >
                      {t.name}
                    </option>
                  ))}
              </select>
            </div>

            <button
              className="btn btn--primary"
              type="submit"
            >
              {t('button.saveContinue')}
            </button>
          </form>
        )}
      </Modal>
    </MainSidebar>
  );
};
