import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import QuestionBookSidebar from './QuestionBookSidebar';
import QuestionBookContent from './QuestionBookContent';
import { t } from 'i18next';

export default function CreateQuestionBook(props) {
  const { id, step } = props;
  const client = useClient();
  const [courses, setCourses] = useState([]);
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [editingQuestionBook, setEditingQuestionBook] = useState(null);

  const steps = [
    {
      id: 1,
      route: 'nome',
      text: t('exams.stepsTextNameQuestionBook')
    },
    {
      id: 2,
      route: 'materias',
      text: t('exams.stepsTextAddSubjects')
    }
  ];

  const getCourses = async () => {
    setCoursesLoading(true);
    const { data, error } = await client.fetch('courses/simple');
    if (error) {
      toast.error('Erro ao carregar cursos');
    } else {
      setCourses(data);
      setCoursesLoading(false);
    }
  };

  const getQuestionBook = async () => {
    const { data, error } = await client.fetch(`question_books/${id}`);
    if (error) {
      toast.error(t('toast.errorGetQuestionBooks'));
    } else {
      setEditingQuestionBook(data);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      getQuestionBook();
    }
  }, [id]);

  return (
    <div className="main-screen">
      <QuestionBookSidebar
        steps={steps}
        edit={false}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
      <main className="main-content">
        <QuestionBookContent
          step={step}
          courses={courses}
          setCurrentStep={setCurrentStep}
          coursesLoading={coursesLoading}
          editingQuestionBook={editingQuestionBook}
        />
      </main>
    </div>
  );
}
