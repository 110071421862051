import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FiFileText } from 'react-icons/fi';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';

export default function CourseClassroomFiles(props) {
  const { t } = useTranslation();
  const { selectedItemId } = props;
  const client = useClient();
  const [loading, setLoading] = useState(true);
  const [learnSystemFiles, setLearnSystemFiles] = useState([]);

  const getLearningSystemFiles = async () => {
    setLoading(true);
    const url = `learning_system_files?filter[learning_system_items.id]=${selectedItemId}`;
    const { data, error } = await client.fetch(url);

    setLoading(false);
    if (error) {
      toast.error(t('toast.errorGetMaterials'));
    } else {
      setLearnSystemFiles(data);
    }
  };

  useEffect(() => {
    if (selectedItemId) {
      getLearningSystemFiles();
    }
  }, [selectedItemId]);

  return (
    <div className="u-mb-4">
      <div className="tab__cards tab__cards--h">
        {loading ? (
          <Loader />
        ) : learnSystemFiles.length > 0 ? (
          (learnSystemFiles || []).map((document, i) => (
            <div
              className="card card--h"
              key={i}
            >
              <header
                className="card__header"
                style={{ flexGrow: '1' }}
              >
                <div className="card__inner-header">
                  <div className="card__icon-wrapper">
                    <FiFileText className="card__icon" />
                  </div>
                  <h2 className="card__title">{document.title}</h2>
                </div>
              </header>

              <div
                className="card__body"
                style={{ display: 'none' }}
              >
                {document.taxonomies.length > 0 && (
                  <div className="card__badges">
                    {document.taxonomies.length <= 3 &&
                      document.taxonomies.map(t => (
                        <span
                          className="badge badge--primary"
                          key={t.id}
                          title={t.name}
                        >
                          {t.name}
                        </span>
                      ))}

                    {document.taxonomies.length > 3 && (
                      <>
                        {document.taxonomies.slice(0, 3).map(t => (
                          <span
                            className="badge badge--primary"
                            key={t.id}
                            title={t.name}
                          >
                            {t.name}
                          </span>
                        ))}
                        <span
                          className="badge badge--more"
                          title={document.taxonomies.slice(3).map(t => ` ${t.name}`)}
                        >
                          +{document.taxonomies.length - 3}
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>

              <div className="card__footer">
                <a
                  href={document.file.url ? document.file.url : document.url}
                  target="_blank"
                  className="btn btn--outline btn--small btn--wide"
                  rel="noreferrer"
                >
                  {t('button.view')}
                </a>
              </div>
            </div>
          ))
        ) : (
          <></>
          // <EmptyState
          //   type="content"
          //   title="Sem conteúdo"
          // />
        )}
      </div>
    </div>
  );
}
