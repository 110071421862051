import { t } from 'i18next';
import React from 'react';
import { useState } from 'react';
import { FiZap } from 'react-icons/fi';
import { useSwiper } from 'swiper/react';

const Flashcard = props => {
  const { setCorrectQuestionsIndex, correctQuestionsIndex, restart, setContRight, contRight, question, isSubmitting, handleSubmitQuestion, handleSkipQuestion, responsePercentage, setCurrentQuestion, currentQuestion, cicleQuestions } = props;

  const swiper = useSwiper();
  const [isTurned, setIsTurned] = useState(false);
  const [iDontKnow, setIDontKnow] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  async function handleRight() {
    if (isSubmitted) return;

    setIsSubmitted(true);
    setIsTurned(!isTurned);

    if (responsePercentage < 100 && !restart) {
      await handleSubmitQuestion(question, true, true, true);
      setContRight(contRight + 1);
    }

    if (responsePercentage < 100) {
      setTimeout(() => {
        swiper.enable();
        swiper.slideNext(2000);
        swiper.disable();
      }, 500);
    }

    if (responsePercentage === 100 && !restart) {
      const newCorrectQuestionsIndex = [...correctQuestionsIndex, currentQuestion].sort((a, b) => a - b);
      setCorrectQuestionsIndex(newCorrectQuestionsIndex);

      setTimeout(() => {
        let nextQuestionIndex = -1;
        let isLastQuestion = currentQuestion === cicleQuestions.length - 1;
        let hasAnsweredAllQuestions = newCorrectQuestionsIndex.length === cicleQuestions.length;
        if (hasAnsweredAllQuestions) {
          return;
        }

        cicleQuestions.forEach((_, index) => {
          if (!newCorrectQuestionsIndex.includes(index)) {
            if (isLastQuestion) {
              if (nextQuestionIndex === -1) {
                nextQuestionIndex = index;
              }
            } else {
              if (index > currentQuestion && index <= cicleQuestions.length - 1 && nextQuestionIndex === -1) {
                nextQuestionIndex = index;
              }
            }
          }
          return;
        });

        if (nextQuestionIndex > -1 && nextQuestionIndex !== currentQuestion) {
          setCurrentQuestion(nextQuestionIndex);
          swiper.enable();
          swiper.slideTo(nextQuestionIndex, 2000);
          swiper.disable();
        }
      }, 500);
    } else {
      if (currentQuestion + 1 < cicleQuestions.length) {
        setCurrentQuestion(prevState => prevState + 1);
      }
    }

    if (responsePercentage === 100 && restart) {
      setTimeout(() => {
        swiper.slideNext(2000);
      }, 500);
    }
  }

  async function handleMissedQuestion() {
    if (isSubmitted) return;
    setIsSubmitted(true);
    handleNextCard(question, true, false, true);
  }

  function handleNextWithoutAnswer() {
    handleNextCard(false, false, false);
    handleSkipQuestion();
  }

  async function handleNextCard(question, knowAnswer, acceptedAnswer, answered) {
    setIsTurned(!isTurned);

    if (responsePercentage < 100 && !restart) {
      await handleSubmitQuestion(question, knowAnswer, acceptedAnswer, answered);
      setCurrentQuestion(prevState => prevState + 1);
      setTimeout(() => {
        swiper.enable();
        swiper.slideNext(2000);
        swiper.disable();
      }, 500);
    }

    if (responsePercentage === 100 && !restart) {
      setTimeout(() => {
        let nextQuestionIndex = -1;
        let isLastQuestion = currentQuestion === cicleQuestions.length - 1;
        let hasAnsweredAllQuestions = correctQuestionsIndex.length === cicleQuestions.length;

        if (hasAnsweredAllQuestions) {
          return;
        }

        cicleQuestions.forEach((_, index) => {
          if (!correctQuestionsIndex.includes(index)) {
            if (isLastQuestion) {
              if (nextQuestionIndex === -1) {
                nextQuestionIndex = index;
              }
            } else {
              if (index > currentQuestion && index <= cicleQuestions.length - 1 && nextQuestionIndex === -1) {
                nextQuestionIndex = index;
              }
            }
          }
          return;
        });

        if (nextQuestionIndex > -1 && nextQuestionIndex !== currentQuestion && !restart) {
          setCurrentQuestion(nextQuestionIndex);
          swiper.enable();
          swiper.slideTo(nextQuestionIndex, 2000);
          swiper.disable();
        }
      }, 500);
    }

    if (responsePercentage === 100 && restart) {
      setTimeout(() => {
        swiper.slideNext(2000);
      }, 500);
    }

    setIDontKnow(false);
  }

  return (
    <div className="flip-wrapper">
      <div className={isTurned ? 'flip flip--turned' : 'flip'}>
        <div className="flip__front">
          <div className="flashcard">
            <div className="flashcard__header">
              <p className="flashcard__count">
                <FiZap /> {question?.position}
              </p>
            </div>

            <div className="flashcard__body">
              <h4 className="flashcard__title">{question?.question}</h4>
            </div>

            <div className="flashcard__footer">
              <button
                className="btn btn--wide btn--outline"
                onClick={() => setIsTurned(!isTurned)}
              >
                {t('flashCard.buttonIKnow')}
              </button>
              <button
                className="btn btn--wide btn--outline"
                onClick={() => {
                  setIsTurned(!isTurned);
                  setIDontKnow(true);
                }}
              >
                {t('flashCard.buttonIDontKnow')}
              </button>
            </div>
          </div>
        </div>

        <div className="flip__back">
          <div className="flashcard flashcard--back">
            <div className="flashcard__header">
              <p className="flashcard__count">
                <FiZap /> {question?.position}
              </p>
            </div>

            <div className="flashcard__body">
              <h4 className="flashcard__title">{isTurned && question?.answer}</h4>
              {!iDontKnow && <p className="flashcard__text">{t('flashCard.basedPreviousAnswer')}</p>}
            </div>

            <div className="flashcard__footer">
              {!iDontKnow ? (
                <>
                  <button
                    disabled={isSubmitting}
                    className="btn btn--wide btn--outline"
                    onClick={handleRight}
                  >
                    {t('flashCard.buttonRight')}
                  </button>
                  <button
                    disabled={isSubmitting}
                    className="btn btn--wide btn--outline"
                    onClick={handleMissedQuestion}
                  >
                    {t('flashCard.buttonWrong')}
                  </button>
                </>
              ) : (
                <button
                  className="btn btn--wide btn--outline"
                  disabled={isSubmitting}
                  onClick={() => handleNextCard(question, false, false, false)}
                >
                  {t('flashCard.buttonNextCard')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flashcard;
