import React from 'react';
import TabNavBar from '../TabNavBar';
import { Link } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function Notifications(props) {
  const { uri } = props;

  const client = useClient();

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    const { data, error } = await client.fetch('platform_settings/1');
    if (error) {
      toast.error('Falha ao carregar configurações');
    } else {
      formik.setValues(data);
    }
  };

  const updateSettings = async fields => {
    const requestParams = {
      attributes: {
        id: 1,
        ...fields
      }
    };
    const response = await client.mutate('platform_settings/1', requestParams);
    if (response.error) {
      toast.error('Falha ao atualizar notificações');
    } else {
      toast.success(t('toast.successUpdateNotifications'));
    }
  };

  const handleChange = e => {
    formik.handleChange(e);
    updateSettings({
      [e.target.name]: e.target.checked
    });
  };

  const formik = useFormik({
    initialValues: {
      'notify-by-email': false,
      'notify-by-wpp': false,
      'notify-deadline-for-student': false,
      'notify-event-start-for-student': false,
      'notify-there-are-inactivity-for-student': false,
      'notify-tasks-completed-for-admin': false,
      'notify-survey-completed-for-admin': false,
      'notify-activities-to-check-for-admin': false,
      'notify-there-are-inactivity-for-admin-profiles': false,
      'notify-there-are-activities-to-check': false
    },
    onSubmit: () => {}
  });

  const breadCrumbs = {
    title: 'Organização da Plataforma',
    nav: [
      {
        route: uri,
        name: 'Usuários',
        isActive: true
      }
    ]
  };

  return (
    <>
      <main className="main-content main-content--block">
        <BreadCrumbs
          data={breadCrumbs}
          borderless
        />

        <TabNavBar selectedTab="notificacoes" />

        <div className="filter-bar filter-bar--borderless">
          <Link
            to="/participantes/notificacoes/extrato"
            className="btn btn--primary btn--wide btn--tab-side u-ml-auto"
          >
            Extrato de notificações
          </Link>
        </div>

        <div className="profile-page__notifications">
          <div className="profile-page__box">
            <form>
              <p className="profile-page__header">Notificações de engajamento</p>

              <div className="form__row">
                <div className="form__check">
                  <input
                    className="form__check-input"
                    name="notify-by-email"
                    {...formik.getFieldProps('notify-by-email')}
                    checked={formik?.values?.['notify-by-email']}
                    onChange={handleChange}
                    type="checkbox"
                    id="notify-by-email"
                  />
                  <label htmlFor="notify-by-email">Quero enviar notificações por email.</label>
                </div>
              </div>
              <div className="form__row">
                <div className="form__check">
                  <input
                    className="form__check-input"
                    checked={formik?.values?.['notify-by-wpp']}
                    name="notify-by-wpp"
                    {...formik?.getFieldProps('notify-by-wpp')}
                    onChange={handleChange}
                    type="checkbox"
                    id="notify-by-wpp"
                  />
                  <label htmlFor="notify-by-wpp">Quero enviar notificações por WhatsApp.</label>
                </div>
              </div>

              {(formik.values?.['notify-by-email'] || formik.values?.['notify-by-wpp']) && (
                <>
                  <p className="profile-page__header">Notificar usuários quando:</p>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 10
                    }}
                  >
                    <div className="form__check form__switch">
                      <input
                        className="form__check-input"
                        id="notify-deadline-for-student"
                        type="checkbox"
                        name="notify-deadline-for-student"
                        {...formik.getFieldProps('notify-deadline-for-student')}
                        onChange={handleChange}
                        checked={formik.values['notify-deadline-for-student']}
                      />
                      <label htmlFor="notify-deadline-for-student">O prazo de envio de atividades complementares, tarefas e questionário de percepção estiver próximo.</label>
                    </div>

                    <div className="form__check form__switch">
                      <input
                        className="form__check-input"
                        id="notify-event-start-for-student"
                        type="checkbox"
                        name="notify-event-start-for-student"
                        {...formik.getFieldProps('notify-event-start-for-student')}
                        onChange={handleChange}
                        checked={formik.values['notify-event-start-for-student']}
                      />
                      <label htmlFor="event">Eventos estiverem prestes a começar.</label>
                    </div>

                    <div className="form__check form__switch">
                      <input
                        className="form__check-input"
                        id="notify-there-are-inactivity-for-student"
                        type="checkbox"
                        name="notify-there-are-inactivity-for-student"
                        {...formik.getFieldProps('notify-there-are-inactivity-for-student')}
                        onChange={handleChange}
                        checked={formik.values['notify-there-are-inactivity-for-student']}
                      />
                      <label htmlFor="inactivity">Houver inatividade.</label>
                    </div>

                    <br />
                    <p className="profile-page__header">Notificar usuários com perfis administrativos quando:</p>

                    {/* <div className="form__check form__switch">
                      <input
                        className="form__check-input"
                        id="notify-tasks-completed-for-admin"
                        type="checkbox"
                        name="notify-tasks-completed-for-admin"
                        {...formik.getFieldProps(
                          'notify-tasks-completed-for-admin',
                        )}
                        onChange={handleChange}
                        checked={
                          formik.values['notify-tasks-completed-for-admin']
                        }
                      />
                      <label htmlFor="task">
                        Sempre que o estudante concluir uma tarefa
                      </label>
                    </div> */}

                    <div className="form__check form__switch">
                      <input
                        className="form__check-input"
                        id="notify-survey-completed-for-admin"
                        type="checkbox"
                        name="notify-survey-completed-for-admin"
                        {...formik.getFieldProps('notify-survey-completed-for-admin')}
                        onChange={handleChange}
                        checked={formik.values['notify-survey-completed-for-admin']}
                      />
                      <label htmlFor="survey">Sempre que o estudante concluir um questionários de percepção.</label>
                    </div>

                    <div className="form__check form__switch">
                      <input
                        className="form__check-input"
                        id="notify-activities-to-check-for-admin"
                        type="checkbox"
                        name="notify-activities-to-check-for-admin"
                        {...formik.getFieldProps('notify-activities-to-check-for-admin')}
                        onChange={handleChange}
                        checked={formik.values['notify-activities-to-check-for-admin']}
                      />
                      <label htmlFor="activity">Houver atividades complementares para avaliar.</label>
                    </div>

                    <br />
                    <p className="profile-page__header">Notificar usuários com perfil de Administrador quando:</p>

                    <div className="form__check form__switch">
                      <input
                        className="form__check-input"
                        id="notify-there-are-inactivity-for-admin-profiles"
                        type="checkbox"
                        name="notify-there-are-inactivity-for-admin-profiles"
                        {...formik.getFieldProps('notify-there-are-inactivity-for-admin-profiles')}
                        onChange={handleChange}
                        checked={formik.values['notify-there-are-inactivity-for-admin-profiles']}
                      />
                      <label htmlFor="activity">Houver inatividade de usuário com algum perfil administrativo.</label>
                    </div>
                    <div className="form__check form__switch">
                      <input
                        className="form__check-input"
                        id="notify-there-are-activities-to-check"
                        type="checkbox"
                        name="notify-there-are-activities-to-check"
                        {...formik.getFieldProps('notify-there-are-activities-to-check')}
                        onChange={handleChange}
                        checked={formik.values['notify-there-are-activities-to-check']}
                      />
                      <label htmlFor="activity">Houver atividades complementares pendentes de avaliação.</label>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </main>
    </>
  );
}
