import React, { useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import Modal from 'app/components/Modal';
import QuestionForm from './QuestionForm';
import QuestionCard from './QuestionCard';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import Loader from 'app/components/loader';
import { QuestionBookForm } from 'app/screens/ProgramOfStudies/QuestionBookForm';

export default function QuestionTab(props) {
  const { lessonId, showListLessons } = props;
  const [showModal, setShowModal] = useState(false);
  const [showNotebooks, setShowNotebooks] = useState([]);
  const [key, setKey] = useState(1);
  const [loading, setLoading] = useState();
  const [currentQuestionBook, setCurrentQuestionBook] = useState();
  const [showModalEditQuestionBook, setShowModalEditQuestionBook] = useState(false);
  const [relationshipsLoading, setRelationshipsLoading] = useState(true);
  const [relationships, setRelationships] = useState({
    courses: [],
    classrooms: [],
    contents: []
  });

  const client = useClient();

  const currentLesson = showListLessons.find(item => {
    return item.id == lessonId;
  });

  const getNotebooks = async () => {
    setLoading(true);

    const { data, error } = await client.fetch(`/question_books?filter[learning_system_items.id]=${lessonId}`);

    setLoading(false);

    if (error) {
      toast.error('Falha ao carregar cadernos');
    } else {
      setShowNotebooks(data);
    }
  };

  const deleteNotebook = async id => {
    try {
      await client.delete(`/learning_system_question_books/${id}`);
      setKey(key + 1);
    } catch {
      console.error(e);
      toast.error('Falha ao deletar caderno');
    }
  };

  const editNotebook = async item => {
    const { data, error } = await client.fetch(`question_books/${item.question_book_id}`);

    if (error) {
      toast.error('Erro ao carregar caderno');
    } else {
      getRelationships();
      setCurrentQuestionBook(data);
      setShowModalEditQuestionBook(true);
    }
  };

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses');
    const { data: classrooms } = await client.fetch('classrooms');
    const { data: contents } = await client.fetch('contents');

    setRelationships({
      courses: courses || [],
      classrooms: classrooms || [],
      contents: contents || []
    });
    setRelationshipsLoading(false);
  };

  useEffect(() => {
    getNotebooks();
  }, [currentLesson, key]);

  return (
    <>
      <div className="filter-bar">
        <button
          onClick={() => history.back()}
          className="filter-bar__back"
        >
          <FiChevronLeft />

          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{currentLesson?.body}</span>
          </span>
        </button>

        <button
          className="btn btn--primary btn--wide u-ml-auto"
          onClick={() => setShowModal(true)}
        >
          {t('textsCommon.questionBook')}
        </button>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          {showNotebooks.length == 0 ? (
            <EmptyState
              type="select"
              title="Caderno de questões"
              text="Crie ou adicione cadernos de questões às aulas"
            />
          ) : (
            <div className="tab__cards fadeIn">
              {showNotebooks.map(item => {
                return (
                  <QuestionCard
                    deleteNotebook={() => deleteNotebook(item['learning-system-question-books'][0]?.id)}
                    title={item.title}
                    taxonomies={item.taxonomies}
                    questionCount={item['question-count']}
                    isRequired={item['learning-system-question-books'][0]?.['is_required']}
                    editNotebook={() => editNotebook(item['learning-system-question-books'][0])}
                  />
                );
              })}
            </div>
          )}
        </>
      )}

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        contentClassName="modal__content--md"
      >
        <QuestionForm
          deleteNotebook={deleteNotebook}
          onSuccess={() => {
            setKey(key + 1);
          }}
          lessonId={lessonId}
          setShowModal={setShowModal}
          questionListedIds={showNotebooks.map(n => n.id)}
        />
      </Modal>

      <Modal
        show={showModalEditQuestionBook}
        onClose={() => {
          setShowModalEditQuestionBook(false);
          setCurrentQuestionBook(null);
        }}
      >
        <QuestionBookForm
          relationshipsLoading={relationshipsLoading}
          relationships={relationships}
          currentQuestionBook={currentQuestionBook}
        />
      </Modal>
    </>
  );
}
