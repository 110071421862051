import React, { useState, useEffect } from 'react';
import { FiFolder, FiMaximize2, FiChevronUp } from 'react-icons/fi';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import EmptyState from 'app/components/EmptyState';
import { useClient } from 'jsonapi-react';
import Modal from 'app/components/Modal';
import AccessedMaterialsModal from './AccessedMaterialsModal';
import { useTranslation } from 'react-i18next';

export default function AccessedMaterialsWidget(props) {
  const { t } = useTranslation();
  const { classroomId, userId, courseId } = props;
  const client = useClient();
  const [materialList, setMaterialList] = useState([...Array(0)]);
  const [timeFilterSelected, setTimeFilterSeleted] = useState(1);
  const [currentMaterial, setCurrentMaterial] = useState();

  const btnGroup = [
    {
      id: 'ultima-semana-acesso-material',
      name: 'timeFilterAccessedMaterials',
      timeFilter: 1,
      label: t('journey.lastWeek')
    },
    {
      id: 'ultimo-mes-acesso-material',
      name: 'timeFilterAccessedMaterials',
      timeFilter: 4,
      label: t('journey.lastmonth')
    },
    {
      id: 'tres-meses-acesso-material',
      name: 'timeFilterAccessedMaterials',
      timeFilter: 12,
      label: t('journey.threeMonth')
    }
  ];

  const getMaterialData = async () => {
    if (timeFilterSelected) {
      let pathRoute = '';
      if (courseId && courseId !== 'blank') {
        pathRoute = `&course_id=${courseId}`;
      }
      if (classroomId && classroomId !== 'blank') {
        pathRoute = `&classroom_id=${classroomId}`;
      }
      if (userId && userId !== 'blank') {
        pathRoute = `&user_id=${userId}`;
      }
      try {
        const { data } = await client.fetch(`accessed_contents?week=${timeFilterSelected}${pathRoute}`);
        setMaterialList(data?.content_collections || []);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const amountMaterials = materialList?.map(item => item?.files?.length).reduce((a, b) => a + b, 0);

  useEffect(() => {
    getMaterialData();
  }, [timeFilterSelected, classroomId, courseId, userId]);

  return (
    <>
      <div className="widget">
        <div className="widget__header">
          <div className="widget__title-with-btn">
            <h3 className="widget__title u-mb-2">{t('textsCommon.accessedMaterials')}</h3>
            <span className="widget__count">
              {amountMaterials} <span>{amountMaterials > 1 ? t('journey.materials') : 'Material'}</span>
            </span>
          </div>

          <div
            className="btn-group"
            role="group"
            aria-label="Escolha o período"
          >
            {btnGroup.map(btn => {
              return (
                <React.Fragment key={btn.id}>
                  <input
                    type="radio"
                    name={btn.name}
                    id={btn.id}
                    autoComplete="off"
                    checked={timeFilterSelected === btn.timeFilter}
                    onChange={() => setTimeFilterSeleted(btn.timeFilter)}
                  />
                  <label
                    htmlFor={btn.id}
                    className="btn btn--outline"
                  >
                    {btn.label}
                  </label>
                </React.Fragment>
              );
            })}
          </div>
        </div>

        <div className="widget__body">
          <div className="accessed-materials-widget-wrapper">
            {materialList.length == 0 ? (
              <EmptyState
                type="register"
                text={t('emptyState.anyFindedMaterial')}
              />
            ) : (
              materialList.map((material, index) => (
                <div
                  className="accessed-materials-widget"
                  key={index}
                >
                  <p className="accessed-materials-widget__row">
                    <span className="accessed-materials-widget__icon">
                      <FiFolder />
                    </span>
                    <span
                      className="accessed-materials-widget__title"
                      title={material.name}
                    >
                      {material.name}{' '}
                      <span className="accessed-materials-widget__counter">
                        {material.files.reduce((result, val) => result + val.access,0)} {t('textsCommon.accesses')}
                      </span>
                    </span>
                    <button
                      className="accessed-materials-widget__btn"
                      onClick={() => setCurrentMaterial(material)}
                    >
                      <FiMaximize2 />
                    </button>
                    <span className="accessed-materials-widget__access accessed-materials-widget__access--up">
                      <FiChevronUp />
                      {material.percent}%
                    </span>
                  </p>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <Modal
        show={currentMaterial}
        onClose={() => setCurrentMaterial(null)}
      >
        {currentMaterial && (
          <AccessedMaterialsModal
            material={currentMaterial}
            onClose={() => setCurrentMaterial(null)}
          />
        )}
      </Modal>
    </>
  );
}
