import React, { useState } from 'react';
import StarRating from './StarRating';

export default function SurveyCard(props) {
  const { data, changeRating, representation } = props;
  const [rating, setRating] = useState(1);
  const [hover, setHover] = useState(0);
  const [body, setBody] = useState('');

  const handleChangeRating = (rating) => {
    setRating(rating.rate);
    changeRating(data, rating, null);
  }

  const handleChangeBody = (e) => {
    setBody(e.target.value);
    changeRating(data, null, e.target.value);
  }

  return (
    <div className="survey-card">
      <div className="survey-card__header">
        <h4 className="survey-card__title">{data.question}</h4>
      </div>

      {representation === 'stars' ?
        <div className="survey-card__body">
          <StarRating
            rating={rating}
            changeRating={handleChangeRating}
            hover={hover}
            setHover={setHover}
            data={data}
          />
        </div> :
        <div className="form__row">
          <input
            className='form__control'
            placeholder='Escreva aqui sua resposta'
            name="body"
            value={body}
            onChange={handleChangeBody}
          />
        </div>
      }

      {representation === 'stars' &&
        <div className="survey-card__footer">
          {(data.items || data['survey-items'])?.map((item) => (
            (hover || rating) == item.rate && (
              <p className="survey-card__legend">{item.subtitle}</p>
            )
          ))}
        </div>}
    </div>
  );
}