import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { Link } from '@reach/router';
import CourseCover from 'app/images/placeholders/show-course.jpg';
import { useParams } from '@reach/router';
import Loader from '../../components/loader';
import ProjectSchedule from './ProjectSchedule';
import { ProjectsProvider } from './provider';
import moment from 'moment';
import { AvatarStack } from 'app/components/Avatar';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';

export function ShowProject(props) {
  const { uri, groupId } = props;

  const { t } = useTranslation();
  const client = useClient();
  const { projectId } = useParams();
  const [project, setProject] = useState([]);
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [mentors, setMentors] = useState([]);

  const isStudent = checkIncludeProfiles({ only: ['student'] });

  const getProject = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`projects/${projectId}`);

    setLoading(false);
    if (error) {
      toast.error('Erro ao buscar projeto.');
    } else {
      setProject(data);

      if (data?.users && data?.users.length > 0) {
        setStudents(data.users.filter(u => u.profiles.includes('Aluno') && !u.profiles.includes('Professor') && !u.profiles.includes('Administrador')));

        setMentors(data.users.filter(u => u.profiles.includes('Professor') || u.profiles.includes('Administrador')));
      }
    }
  };

  const createProjectUser = async () => {
    const { data, error } = await client.mutate('project_users', {
      'project-id': projectId
    });
  };

  useEffect(() => {
    getProject();
  }, []);

  const studentBreadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: '/projetos',
        name: 'Projetos',
        isActive: false
      },
      {
        route: uri,
        name: 'Detalhamento',
        isActive: true
      }
    ]
  };

  const adminBreadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: '/projetos/grupos',
        name: 'Projetos',
        isActive: false
      },
      {
        route: `/projetos/grupos/${groupId}`,
        name: project?.name,
        isActive: false
      },
      {
        route: uri,
        name: 'Detalhamento',
        isActive: true
      }
    ]
  };

  const breadCrumbs = isStudent ? studentBreadCrumbs : adminBreadCrumbs;

  return (
    <>
      <main className="main-content main-content--block">
        <BreadCrumbs data={breadCrumbs} />

        {loading ? (
          <Loader />
        ) : (
          <div className="widget widget--full ">
            <div className="show-course fadeIn">
              <div
                className="show-course-banner"
                style={{
                  backgroundImage: `url(${project.image?.url || CourseCover})`
                }}
              >
                <div className="show-course-banner__title">{project && project.name}</div>
                <div className="show-course-banner__description">{`${t('projects.updatedIn')} ${moment(project['updated-at']).format(`MMMM [${t('projects.in')}] YYYY`)} | ${t('projects.by')} ${project.user?.name}`}</div>
                <div className="show-course-banner__cta">
                  <Link
                    to={`/projetos/${projectId}`}
                    className="btn btn--primary btn--wide"
                    onClick={() => createProjectUser()}
                  >
                    {t('button.accessProject')}
                  </Link>
                </div>
              </div>

              <div className="show-course-main-content">
                {/* <div className="tag-list">
                <div className="tag">Tag name</div>
              </div> */}

                <div className="show-course-section">
                  <div className="show-course-section__title">{t('button.aboutProject')}</div>
                  <div className="show-course-section__description">{project && project.about}</div>
                </div>

                <div className="show-course-section">
                  <div className="show-course-section__title">{t('projects.titleProjectParticipants')}</div>
                  <div className="show-course-section__description">
                    <div className="card__columns">
                      <div className="card__avatar">
                        <h4 className="card__avatar-title">{t('projects.titleTeachers')}</h4>
                        {mentors?.length > 0 && (
                          <AvatarStack
                            data={mentors}
                            modalTitle={t('projects.titleProjectTeacher')}
                            className="avatar--md"
                          />
                        )}
                      </div>
                      <div className="card__avatar">
                        <h4 className="card__avatar-title">{t('projects.titleStudents')}</h4>
                        {students?.length > 0 && (
                          <AvatarStack
                            data={students}
                            modalTitle={t('projects.titleProjectStudents')}
                            className="avatar--md"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="show-course-sidebar">
                <section className="new-questions__widget">
                  <ProjectsProvider>
                    <ProjectSchedule />
                  </ProjectsProvider>
                </section>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
}
