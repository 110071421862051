import React from 'react';

const cleanPercentage = percentage => {
  const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
  const isTooHigh = percentage > 100;

  return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

const Circle = ({ colour, percentage, size, strokeWidth, strokeLineCap, ...restProps }) => {
  const center = +size / 2;
  const radius = center - strokeWidth / 2;
  const dashArray = 2 * Math.PI * radius;
  const strokePercentage = ((100 - percentage) * dashArray) / 100; // where stroke will start, e.g. from 15% to 100%.

  return (
    <circle
      r={radius}
      cx={center}
      cy={center}
      fill="transparent"
      stroke={colour}
      strokeWidth={strokeWidth}
      strokeDasharray={dashArray}
      strokeDashoffset={percentage ? strokePercentage : 0}
      strokeLinecap={strokeLineCap}
      {...restProps}
    />
  );
};

const Text = ({ text }) => {
  return (
    <text
      x="50%"
      y="50%"
    >
      {text}
    </text>
  );
};

const Pie = ({ value, text = '', colour = 'var(--color-secondary)', size = 200, strokeWidth = 4, strokeLineCap = 'butt' }) => {
  const percentage = cleanPercentage(value);

  return (
    <svg
      className="nav-week__progress"
      width={size}
      height={size}
    >
      <g className="nav-week__circles">
        <Circle
          size={size}
          strokeWidth={strokeWidth}
          strokeLineCap={strokeLineCap}
          className="nav-week__track"
        />
        <Circle
          colour={colour}
          percentage={percentage}
          size={size}
          strokeWidth={strokeWidth}
          strokeLineCap={strokeLineCap}
          className="nav-week__indicator"
        />
      </g>

      <Text text={text} />
    </svg>
  );
};

export default Pie;
