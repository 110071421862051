import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useFormik } from 'formik';
import MainTabNavBar from 'app/components/MainTabNavBar';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import EmptyState from 'app/components/EmptyState';
import MaterialCard from './MaterialCard';
import TreeItem from './TreeItem';
import { useQuery } from 'jsonapi-react';
import Loader from 'app/components/loader';
import BreadCrumbs from 'app/components/BreadCrumbs';

export function arraysAreEqual(array1, array2) {
  if (!array1 || !array2) return false;
  if (array1?.length !== array2?.length) {
    return false;
  }

  for (let i = 0; i < array1?.length; i++) {
    if (array1?.[i] !== array2?.[i]) {
      return false;
    }
  }

  return true;
}

const tabs = [
  {
    name: 'Plano de aulas',
    url: '/plano-de-aulas/inicio',
    slug: 'inicio',
    order: 1
  },
  {
    name: 'Favoritos',
    url: '/plano-de-aulas/favoritos',
    slug: 'favoritos',
    order: 2
  }
];

const quarter = [
  {
    id: 1,
    name: '1º trimestre'
  },
  {
    id: 2,
    name: '2º trimestre'
  },
  {
    id: 3,
    name: '3º trimestre'
  },
  {
    id: 4,
    name: '4º trimestre'
  } 
];

const shifts = [
  {
    id: 1,
    name: 'Manhã'
  },
  {
    id: 2,
    name: 'Tarde'
  },
  {
    id: 3,
    name: 'Noite'
  },
  {
    id: 4,
    name: 'Integral'
  }
];

export default function LessonPlan({ selectedTab, uri }) {
  const { data: stages, isLoading } = useQuery(selectedTab === 'inicio' ? '/educational_stages' : '/educational_stages?favorite=true');

  const [currentFolder, setCurrentFolder] = useState(null);
  const [idOpenedFolder, setIdOpenedFolder] = useState(null);
  const [idOpenedSubFolder, setIdOpenedSubFolder] = useState(null);

  const filters = useFormik({
    initialValues: {
      seachTerm: '',
      shift: 'blank',
      quarter: 'blank'
    }
  });

  useEffect(() => {
    setIdOpenedFolder(null);
    setCurrentFolder(null);
    setIdOpenedSubFolder(null);
  }, [selectedTab]);

  const getQuarter = (quarter) => {
    switch (quarter) {
      case "Q1":
        return "1º trimestre"
      case "Q2":
        return "2º trimestre"
      case "Q3":
        return "3º trimestre"
      case "Q4":
        return "4º trimestre"
    }
  }

  console.log(stages)

  const filteredStages = stages
    ?.filter(stage => stage?.title?.toLowerCase()?.includes(filters.values.seachTerm) ?? true)
    ?.filter(stage => filters.values.quarter === 'blank' || stage?.['class-plans']?.some(item => getQuarter(item.quarter) ===  filters.values.quarter))
    ?.filter(stage => filters.values.shift === 'blank' || stage?.['class-plans']?.some(item => item.shift === filters.values.shift));

  const handleClickFolder = folder => {
    setIdOpenedFolder(prev => (prev === folder.id ? prev : folder.id));
    setCurrentFolder(prev => (arraysAreEqual(prev, folder?.['class-plans']) ? prev : folder?.['class-plans']));
    setIdOpenedSubFolder(null);
  };

  const breadCrumbs = {
    title: 'Cursos e Aulas',
    nav: [
      {
        route: uri,
        name: 'Plano de Aulas',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--column">
      <BreadCrumbs data={breadCrumbs} />

      <MainTabNavBar
        tabs={tabs}
        selectedTab={selectedTab}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="filter-bar filter-bar--borderless">
            <div className="filter-bar__inner">
              <div className="filter-bar__row">
                <label
                  htmlFor="search"
                  className="filter-bar__label"
                >
                  Pesquisa
                </label>
                <input
                  className="form__control form__control--search-with-icon"
                  placeholder={t('textsCommon.research')}
                  type="search"
                  name="search"
                  id="search"
                  onChange={e => filters.setFieldValue('seachTerm', e.target.value)}
                />
              </div>
              <FilterSelectionBox
                blankLabel="Trimestre"
                label="Selecione um período"
                value={filters.values.quarter}
                onChange={e => filters.setFieldValue('quarter', e.target.value)}
                className="filter-bar__select--calendar"
                options={quarter.map(o => ({
                  id: o.name,
                  name: o.name
                }))}
              />
              <FilterSelectionBox
                blankLabel="Selecione um turno"
                label="Selecione um turno"
                value={filters.values.shift}
                onChange={e => filters.setFieldValue('shift', e.target.value)}
                options={shifts.map(o => ({
                  id: o.name,
                  name: o.name
                }))}
              />
            </div>
          </div>

          <div className="finder">
            <aside className="finder__tree">
              {filteredStages?.map(folder => (
                <TreeItem
                  key={folder.id}
                  folder={folder}
                  isOpenFolder={idOpenedFolder === folder.id}
                  idOpenedSubFolder={idOpenedSubFolder}
                  currentFolder={currentFolder}
                  setCurrentFolder={setCurrentFolder}
                  setIdOpenedSubFolder={setIdOpenedSubFolder}
                  setIdOpenedFolder={setIdOpenedFolder}
                  onClick={() => handleClickFolder(folder)}
                />
              ))}

              {filteredStages?.length === 0 && (
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <EmptyState
                    type="data"
                    text="Sem dados para visualizar"
                    bgless
                  />
                </div>
              )}
            </aside>

            <div className="finder__content">
              {(!currentFolder || currentFolder?.length === 0) && (
                <EmptyState
                  type="select"
                  text="Selecione uma opção para visualizar"
                  bgless
                />
              )}

              {currentFolder?.length > 0 && (
                <div className="round-dg-wrapper">
                  <table
                    className="round-dg round-dg--light"
                    style={{ minWidth: '500px' }}
                  >
                    <thead className="round-dg__header">
                      <tr className="round-dg__row">
                        <th className="round-dg__cell-header round-dg__cell-header--sm">Série e modalidade</th>
                        <th
                          className="round-dg__cell-header round-dg__cell-header--sm"
                          style={{ width: '76px' }}
                        >
                          Turno
                        </th>
                        <th
                          className="round-dg__cell-header round-dg__cell-header--sm"
                          style={{ width: '76px' }}
                        >
                          Mês
                        </th>
                        <th
                          className="round-dg__cell-header round-dg__cell-header--sm"
                          style={{ width: '130px' }}
                        />
                        <th
                          className="round-dg__cell-header round-dg__cell-header--sm"
                          style={{ width: '40px' }}
                        />
                      </tr>
                    </thead>

                    <tbody className="round-dg__body">
                      {currentFolder?.map((item, i) => (
                        <MaterialCard
                          key={item?.id}
                          material={item}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </main>
  );
}
