import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import ChallengeRow from './ChallengeRow';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useCallback } from 'react';
import Swal from 'sweetalert2';

export default function ChallengeDataGrid({ challenges, setChallenges, setParam, orderByPeriod, orderByParticipants, setOrderByPeriod, setOrderByParticipants }) {
  const client = useClient();

  const handlePublishChallenge = (id, value) => {
    return client.mutate(['/challenges', id], {
      published: value
    });
  };

  const badgeColor = useCallback(challenge => {
    switch (challenge.status) {
      case 'expirado':
        return 'danger';
      case 'vigente':
        return 'success';
      case 'agendado':
        return 'warning';
    }
  }, []);

  const deleteChallenge = async id => {
    const { error } = await client.delete(['challenges', id]);
    if (error) {
      toast.error('Erro ao excluir desafio');
    } else {
      toast.success('Desafio excluído!');
      setChallenges(prevState => prevState.filter(item => item.id !== id));
    }
  };

  const handleDeleteChallenge = id =>
    Swal.fire({
      title: 'Deseja mesmo excluir este desafio?',
      text: 'Esta ação não poderá ser desfeita',
      confirmButtonText: 'Sim',
      showDenyButton: true,
      denyButtonText: 'Não',
      showCloseButton: true
    }).then(async res => {
      if (res.isConfirmed) {
        deleteChallenge(id);
      }
    });

  return (
    <div className="round-dg-wrapper">
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: 1230 }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th className="round-dg__cell-header round-dg__cell-header--sm">Desafio</th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 140 }}
            >
              Tema
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 180 }}
            >
              Professor
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 120 }}
            >
              Período{' '}
              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                {orderByPeriod === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setOrderByPeriod('ascendent');
                      setParam('period');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setOrderByPeriod('descendent');
                      setParam('period');
                    }}
                    className="card__icon"
                  />
                )}
              </span>
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 160 }}
            >
              Disponibilidade
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 160 }}
            >
              Participantes{' '}
              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                {orderByParticipants === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setOrderByParticipants('ascendent');
                      setParam('participants');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setOrderByParticipants('descendent');
                      setParam('participants');
                    }}
                    className="card__icon"
                  />
                )}
              </span>
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 98 }}
            >
              Status
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 110 }}
            ></th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 48 }}
            ></th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {challenges.map((item, key) => (
            <ChallengeRow
              handleDeleteChallenge={handleDeleteChallenge}
              badgeColor={badgeColor}
              handlePublishChallenge={handlePublishChallenge}
              challenge={item}
              total={challenges.length}
              index={key}
              key={key}
              setChallenges={setChallenges}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
