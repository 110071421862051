import React from 'react';
import styles from './styles.module.scss';

function TabNavBar({ selectedTab, setSelectedTab, essayStatus }) {
  return (
    <div className="tab u-mb-4">
      <button
        className={selectedTab === 'correcao' ? 'tab__link active' : 'tab__link'}
        onClick={() => setSelectedTab('correcao')}
      >
        Correção
      </button>
      {essayStatus !== 'annulled' && (
        <button
          className={selectedTab === 'competencias' ? 'tab__link active' : `tab__link ${styles['tab-only-xxl']}`}
          onClick={() => setSelectedTab('competencias')}
        >
          Competências
        </button>
      )}
      <button
        className={selectedTab === 'sobre' ? 'tab__link active' : 'tab__link'}
        onClick={() => setSelectedTab('sobre')}
      >
        Sobre
      </button>
    </div>
  );
}

export default TabNavBar;
