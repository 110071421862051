import React, { useState, createContext } from 'react';
import { navigate } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { t } from 'i18next';

export const MaterialsContext = createContext();

export const MaterialsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [materialsFolders, setMaterialsFolders] = useState([]);
  const [selectedMaterialsFolder, setSelectedMaterialsFolder] = useState(null);
  const [isMaterialsFolderEditOpen, setIsMaterialsFolderEditOpen] =
    useState(false);

  const client = useClient();

  const getMaterialsFolders = async () => {
    try {
      setLoading(true);
      const { data } = await client.fetch('content_collections');

      setMaterialsFolders(data);
      setLoading(false);
    } catch (e) {
      toast.error(t('toast.errorGetMaterials'));
    }
  };

  const createMaterialsFolder = async ({ name }) => {
    setLoading(true);
    const { error } = await client.mutate('content_collections', { name });

    if (error) {
      toast.error(t('toast.errorCreateMaterial'));
      setLoading(false);
    } else {
      getMaterialsFolders();
      setLoading(false);
      toast.success(t('toast.successCreateMaterial'))
    }
  };

  const updateMaterialsFolder = async ({ id, name }) => {
    setLoading(true);
    try {
      await client.mutate(['content_collections', id], {
        name,
      });
    } catch (e) {
      toast.error('Erro ao editar material.');
      setLoading(false);
    } finally {
      getMaterialsFolders();
      setLoading(false);
      toast.success(t('toast.successEditMaterial'))
    }
  };

  const deleteMaterialsFolder = async id => {
    try {
      setLoading(true);
      await client.delete(['content_collections', id]);

      getMaterialsFolders();
      toast.success(t('toast.successDeleteMaterial'))
    } catch (e) {
      toast.error(t('toast.errorDeleteMaterial'));
    }
  };

  const deleteMaterialDocument = async id => {
    try {
      setLoading(true);
      setSelectedMaterialsFolder(null);

      await client.delete(['contents', id]);
    } catch (e) {
      toast.error(t('toast.errorDeleteMaterial'));
    } finally {
      const { data } = await client.fetch('content_collections');
      setSelectedMaterialsFolder(
        data.find(folder => folder.id === selectedMaterialsFolder.id),
      );
      setLoading(false);
      toast.success(t('toast.successDeleteMaterial'))
    }
  };

  return (
    <MaterialsContext.Provider
      value={{
        materialsFolders,
        loading,
        getMaterialsFolders,
        createMaterialsFolder,
        updateMaterialsFolder,
        deleteMaterialsFolder,
        selectedMaterialsFolder,
        setSelectedMaterialsFolder,
        isMaterialsFolderEditOpen,
        setIsMaterialsFolderEditOpen,
        deleteMaterialDocument,
      }}
    >
      {children}
    </MaterialsContext.Provider>
  );
};
