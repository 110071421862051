import { t } from 'i18next';
import React, { useEffect } from 'react';

export default function StepOrganization(props) {
  const { form } = props;

  return (
    <div className="page__content fadeIn u-mb-5">
      <div className="new-questions__header">
        <h2 className="new-questions__title">{form.values.title || t('projects.newProjects')}</h2>
      </div>

      <fieldset className="form__row">
        <legend className="form__label">*{t('projects.projectOrganization')}</legend>

        {[
          { name: 'group', label: t('projects.labelRandomGroup') },
          { name: 'individual', label: t('projects.labelIndividual')},
          { name: 'custom', label: t('projects.labelCustomized') }
        ].map((item, index) => (
          <div
            className="form__check form__check--inline"
            key={index}
          >
            <input
              className="form__check-input"
              type="radio"
              name={item.name}
              id={`${item.name}-${index}`}
              value={item.name}
              checked={form.values.organization === item.name}
              onChange={e => form.setFieldValue('organization', e.target.value)}
            />
            <label htmlFor={`${item.name}-${index}`}>{item.label}</label>
          </div>
        ))}
      </fieldset>
      {form.touched.organization && form.errors.organization && <span style={{ color: 'red' }}>{form.errors.organization}</span>}

      <span className="form__text u-mt-3">
        <strong>* {t('warning.requiredField')}</strong>
      </span>
    </div>
  );
}
