import React, { useState, useEffect } from 'react';
import { FiHeart, FiMessageCircle } from 'react-icons/fi';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Chart from './Chart';
import InfoWidget from 'app/components/Widget/InfoWidget';
import ChartWidget from 'app/components/Widget/ChartWidget';
import Widget from 'app/components/Widget';
import TopWidget from './TopWidget';
import HitWidget from './HitWidget';
import IndividualWidget from './IndividualWidget';
import Loader from 'app/components/loader';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const DashboardAdmin = () => {
  const { t } = useTranslation();
  const client = useClient();
  const [rangeDates, setRangeDates] = useState([null, null]);
  const [dashboardData, setDashboardData] = useState();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [rangeDates]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchData = async () => {
    try {
      let filterParams = '';
      if (rangeDates && rangeDates.filter(a => a).length == 2) {
        const [initialDate, endDate] = rangeDates.map(d => d.toISOString().split('T')[0]);
        filterParams = `?initial_date=${initialDate}&end_date=${endDate}`;
      }

      const { data } = await client.fetch(`admin${filterParams}`);
      setDashboardData(data);
      setIsLoading(false);
    } catch (e) {
      toast.error(t("toast.error"));
      console.error(e);
    }
  };

  const fetchUsers = async () => {
    try {
      const { data } = await client.fetch(`users?simple=true`);
      setUsers(data);
    } catch (e) {
      toast.error(t("toast.error"));
      console.error(e);
    }
  };

  return (
    <>
      {isLoading ? (
        <main className="main-content main-content--block">
          <Loader />
        </main>
      ) : (
        <main className="main-content main-content--block">
          <div className="filter-bar">
            <div className="filter-bar__row">
              <div className="filter-bar__label">Selecione um Período</div>
              <DateRangePicker
                onChange={setRangeDates}
                value={rangeDates}
              />
            </div>

            <button
              className="btn btn--primary btn--wide"
              onClick={() => setRangeDates([null, null])}
            >
              Limpar filtro
            </button>
          </div>

          <div className="widget-wrapper">
            <InfoWidget title="Índice de Acerto">
              <InfoWidget.Value>{dashboardData.hitPercentage}%</InfoWidget.Value>
            </InfoWidget>

            <InfoWidget title="Estudantes Totais">
              <InfoWidget.Value>{dashboardData.totalUsers}</InfoWidget.Value>
            </InfoWidget>

            <InfoWidget title="Nº Total Questões Respondidas">
              <InfoWidget.Value>{dashboardData.totalAnswers}</InfoWidget.Value>
            </InfoWidget>

            <InfoWidget title="Dados de Engajamento">
              <InfoWidget.Value>
                <FiHeart /> {dashboardData.commentsCount}
              </InfoWidget.Value>
              <InfoWidget.Value>
                <FiMessageCircle /> {dashboardData.likesCount}
              </InfoWidget.Value>
            </InfoWidget>
          </div>

          <div className="widget-wrapper widget-wrapper--chart">
            <ChartWidget title="Dados de Engajamento">
              {dashboardData && (
                <Chart
                  name="Engajamento"
                  color="#442B8C"
                  chartData={dashboardData.usersGrowth}
                />
              )}
            </ChartWidget>

            <ChartWidget title="Inscritos no OPEN">
              {dashboardData && (
                <Chart
                  name="Inscritos"
                  color="#32C9D3"
                  chartData={dashboardData.subscriptionsGrowth}
                />
              )}
            </ChartWidget>

            <ChartWidget title="Cadernos de questões">
              {dashboardData && (
                <Chart
                  name="Cadernos"
                  color="#FDB462"
                  chartData={dashboardData.questionBooksGrowth}
                />
              )}
            </ChartWidget>
          </div>

          <div className="widget-wrapper widget-wrapper--col">
            <div className="widget-wrapper__col">
              <Widget
                title="Aproveitamento Individual"
                description="Rendimento comparativo dos estudantes"
              >
                {users && users.length > 0 && <IndividualWidget users={users} />}
              </Widget>

              <Widget
                title="Acertos por Disciplina"
                description="Número de questões acertadas por matéria"
              >
                {dashboardData && <HitWidget hitsByTaxonomy={dashboardData.hitsByTaxonomy} />}
              </Widget>
            </div>

            <div className="widget-wrapper__col">
              <Widget
                title="Top 50 Estudantes"
                description="Rendimento individual dos estudantes"
              >
                {dashboardData && <TopWidget topUsers={dashboardData.topUsers} />}
              </Widget>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default DashboardAdmin;
