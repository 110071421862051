import Loader from 'app/components/loader';
import { useAuditorium } from 'app/hooks/useAuditorium';
import { t } from 'i18next';
import React from 'react';
import { FiSend } from 'react-icons/fi';

export default function CommentForm(props) {
  const { messageIds, setMessageIds } = useAuditorium();
  const { commentForm } = props;
  let reply = messageIds ? true : false;

  const handleSubmit = e => {
    e.preventDefault();
    commentForm.handleSubmit();

    if (commentForm.values.body) {
      setTimeout(() => {
        commentForm.setFieldValue('body', '');
        setMessageIds(null);
      }, 200);
    }
  };

  const extractTextFromHTML = html => {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || '';
  };

  return (
    <div className={reply ? 'chat-widget__footer chat-widget__footer--reply' : 'chat-widget__footer'}>
      {reply && messageIds ? (
        <p
          className="chat-widget__original-text"
          dangerouslySetInnerHTML={{ __html: messageIds.messageBody.length > 30 ? `${extractTextFromHTML(messageIds.messageBody).substring(0, 60)}...` : messageIds.messageBody }}
        ></p>
      ) : null}
      <form
        className="chat-widget__comment-form"
        onSubmit={handleSubmit}
      >
        <input
          className="form__control"
          type="text"
          name="body"
          id="body"
          placeholder={t('activity.placeholderWriteComment')}
          value={commentForm.values.body}
          onChange={commentForm.handleChange}
        />
        <button
          className="btn btn--primary btn--send"
          type="submit"
        >
          <FiSend />
        </button>
      </form>
    </div>
  );
}
