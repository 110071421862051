import React from 'react';
import MatterRow from './MatterRow';

export default function MatterDataGrid(props) {
  const { data } = props;

  return (
    <div className="round-dg-wrapper">
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: 800 }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th className="round-dg__cell-header">Matéria</th>
            <th className="round-dg__cell-header">Submatéria</th>
            <th
              className="round-dg__cell-header"
              style={{ width: 160 }}
            />
            <th
              className="round-dg__cell-header"
              style={{ width: 160 }}
            >
              Ações
            </th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {data.map(matter => (
            <MatterRow
              matter={matter}
              key={matter.id}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
