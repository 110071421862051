import React from 'react';
import { SimpleModal } from 'app/components/Modal';
import QuizDataGrid from './QuizDataGrid';

export default function QuizModal(props) {
  const { show, onClose, data } = props;

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      contentClassName="simple-modal__content--lg"
      hideCloseButton
    >
      <h3 className="modal__simple-title">Rendimento nos quizzes das aulas</h3>

      <QuizDataGrid
        data={data}
        height={366}
      />
    </SimpleModal>
  );
}
