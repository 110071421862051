import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';
import React, { useEffect, useRef } from 'react';

function CountdownTimer(props) {
  let intervalRef = useRef();

  const { counter, setCounter, start } = useWeeklyChallenge();

  useEffect(() => {
    if (start) {
      intervalRef.current = setInterval(() => {
        if (counter > 0) setCounter(counter - 1);
      }, 1000);

      return () => clearInterval(intervalRef.current);
    }
  }, [counter, start]);

  return (
    <svg
      width={36}
      height={48}
      viewBox="0 0 36 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={17.5}
        cy={25.5}
        r={16}
        fill="url(#paint0_linear_409_1601)"
        stroke="#442B8C"
        strokeWidth={3}
      />
      <path
        fill="#442B8C"
        stroke="#442B8C"
        d="M15.5 4.5H20.5V8.5H15.5z"
      />
      <g filter="url(#filter0_d_409_1601)">
        <rect
          x={12}
          width={12}
          height={5}
          rx={1}
          fill="#5DC9DD"
        />
      </g>
      <path
        transform="rotate(45 30.286 11.707)"
        fill="#442B8C"
        stroke="#442B8C"
        d="M30.2856 11.7071H31.70995V13.93957H30.2856z"
      />
      <rect
        x={30.8572}
        y={7}
        width={7.27306}
        height={4.04059}
        rx={1}
        transform="rotate(45 30.857 7)"
        fill="#5DC9DD"
      />
      <text
        fill="#777"
        style={{
          whiteSpace: 'pre'
        }}
        fontFamily="Nunito"
        fontSize={15}
        fontWeight={500}
        letterSpacing="0em"
      >
        <tspan
          x={9.50586}
          y={31.435}
        >
          {counter.toString().padStart(2, '0')}
        </tspan>
      </text>
      <ellipse
        cx={17}
        cy={46.5}
        rx={17}
        ry={1.5}
        fill="#D9D9D9"
      />
      <defs>
        <filter
          id="filter0_d_409_1601"
          x={10}
          y={0}
          width={16}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity={0}
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={1} />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix values="0 0 0 0 0.2375 0 0 0 0 0.23651 0 0 0 0 0.23651 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_409_1601"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_409_1601"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_409_1601"
          x1={25.9766}
          y1={64.1694}
          x2={39.5792}
          y2={52.3335}
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={0.0544214}
            stopColor="#fff"
          />
          <stop
            offset={1}
            stopColor="#F2F2F2"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CountdownTimer;
