import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import { FiChevronDown, FiChevronLeft } from 'react-icons/fi';
import MainNav from 'app/components/mainNav';
import { FilterCheckBox } from 'app/components/FilterCheckBox';
import getPermission from 'app/utils/getPermission';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { ProjectsProvider } from '../../provider';
import { ProjectsContext } from '../../provider';
import ProjectsCard from '../../ProjectsCard';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { AvatarStack } from 'app/components/Avatar';
import { Link } from '@reach/router';
import Dropdown from 'app/components/Dropdown';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

export function ViewGroupProject(props) {
  return (
    <ProjectsProvider>
      <ScreenContentGroupProject {...props} />
    </ProjectsProvider>
  );
}

export function ScreenContentGroupProject(props) {
  const { t } = useTranslation();
  const client = useClient();
  const { id, uri } = props;
  const [groupedProject, setGroupedProject] = useState({});
  const [participantsQuantity, setParticipantsQuantity] = useState();
  const [loading, setLoading] = useState(true);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const getGroupProject = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`project_groups/${id}`);
    if (error) {
      toast.error('Erro ao buscar informações sobre o Grupo de Projetos.');
    } else {
      setGroupedProject(data);
      setLoading(false);
      setParticipantsQuantity(data['user-count']);
    }
  };

  const filters = useFormik({
    initialValues: {
      approvedProjects: false,
      projectsInProgress: false
    }
  });

  useEffect(() => {
    getGroupProject();
  }, []);

  useEffect(() => {
    let newProjects = groupedProject.projects;
    if (filters.values.approvedProjects === true) {
      newProjects = newProjects.filter(p => p.approved === true);
    }
    if (filters.values.projectsInProgress === true) {
      newProjects = newProjects.filter(p => p.approved === null || p.approved === false);
    }
    setFilteredProjects(newProjects);
  }, [filters.values, groupedProject]);

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: '/projetos/grupos',
        name: 'Projetos',
        isActive: false
      },
      {
        route: uri,
        name: `Grupos de ${groupedProject?.projects?.[0]?.name}`,
        isActive: true
      }
    ]
  };

  return (
    <ProjectsProvider>
      <div className="main-screen">
        <MainNav />

        {groupedProject && getPermission('Visualizar lista de projetos', 'Projetos') && (
          <main className="main-content main-content--block">
            <BreadCrumbs data={breadCrumbs} />

            <div className="filter-bar">
              <div className="filter-bar__inner">
                <FilterCheckBox
                  label={t('metrics.approvedProjects')}
                  value={filters.values.approvedProjects}
                  onChange={() => {
                    filters.setFieldValue('approvedProjects', !filters.values.approvedProjects);
                    filters.setFieldValue('projectsInProgress', false);
                  }}
                />
                <FilterCheckBox
                  label={t('metrics.projectsInProgress')}
                  value={filters.values.projectsInProgress}
                  onChange={() => {
                    filters.setFieldValue('projectsInProgress', !filters.values.projectsInProgress);
                    filters.setFieldValue('approvedProjects', false);
                  }}
                />
              </div>

              <div className="filter-bar__box">
                <span>
                  <strong>
                    {groupedProject?.projects?.length} {t('projects.sidebarGroups')}
                  </strong>
                  <br />
                  {participantsQuantity} {t('sideBar.participants')}
                </span>
              </div>
            </div>

            {loading && <Loader />}

            {filteredProjects?.length === 0 && !loading && (
              <EmptyState
                type="project"
                title={t('projects.newProjects')}
                text={t('emptyState.createNewProjectInteract')}
              />
            )}

            {!loading && (
              <div className="tab__cards">
                {filteredProjects?.map(p => (
                  <div
                    className="card"
                    key={p.id}
                  >
                    <div className="card__header">
                      <div className="card__inner-header">
                        <h3 className="card__title">{p.name}</h3>
                      </div>

                      {p.approved ? <span className="badge badge--success badge--tiny">{t('exams.statusInApproved')}</span> : <span className="badge badge--primary badge--tiny">{t('exams.inProgress')}</span>}
                    </div>

                    <div className="card__body">
                      <div className="card__columns">
                        <div className="card__avatar">
                          <h4 className="card__avatar-title">{t('projects.participants')}</h4>
                          <AvatarStack
                            data={p.users}
                            modalTitle={`${t('projects.titleProjectParticipants')} ${p.name}`}
                            className="avatar--xs"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card__footer">
                      <div
                        className="btn-group btn-group--with-dropdown"
                        role="group"
                        aria-label="Ações"
                      >
                        {p['project-users']?.some(projectUser => projectUser.user_id === session.user.id) ? (
                          <Link
                            to={`/projetos/${p.id}`}
                            type="button"
                            className="btn btn--outline btn--small"
                          >
                            {t('button.accessProject')}
                          </Link>
                        ) : (
                          <Link
                            to={`/projetos/grupos/${id}/${p.id}/sobre`}
                            type="button"
                            className="btn btn--outline btn--small"
                          >
                            {t('button.detailsProject')}
                          </Link>
                        )}
                        {getPermission('Editar projetos', 'Projetos') || getPermission('Excluir projetos', 'Projetos') ? (
                          <Dropdown className="btn--small btn btn--outline">
                            <Dropdown.Toggle>
                              <FiChevronDown />
                            </Dropdown.Toggle>

                            <Dropdown.Content>
                              {getPermission('Excluir projetos', 'Projetos') && (
                                <Dropdown.Item
                                  onClick={() => {
                                    Swal.fire({
                                      title: t('projects.warningDeleteProject'),
                                      text: t('projects.warningDeleteCannotBeUndoneText'),
                                      confirmButtonText: t('button.yes'),
                                      showDenyButton: true,
                                      denyButtonText: t('button.no'),
                                      showCloseButton: true
                                    }).then(res => {
                                      if (res.isConfirmed) {
                                        deletePersonalizedProject(id);
                                      }
                                    });
                                  }}
                                >
                                  {t('textsCommon.delete')}
                                </Dropdown.Item>
                              )}
                            </Dropdown.Content>
                          </Dropdown>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </main>
        )}
      </div>
    </ProjectsProvider>
  );
}
