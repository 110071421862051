import React, { useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import Create from './Create';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { useSurvey } from 'app/hooks/useSurvey';
import { t } from 'i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

export function QuizCreate(props) {
  const { representation, id, uri } = props;
  const { currentSurvey, setCurrentSurvey } = useSurvey();
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const [surveyData, setSurveyData] = useState([]);

  useEffect(() => {
    getSurveyData();
  }, []);

  const getSurveyData = async () => {
    const { data, error } = await client.fetch(`surveys/${id}`);
    if (error) {
      toast.error('Erro ao buscar dados do questionário em questão.');
    } else {
      setSurveyData(data);
    }
  };

  const breadCrumbs = {
    title: 'Ferramentas de Avaliação',
    nav: [
      {
        route: '/questionario',
        name: 'Questionário de Percepção',
        isActive: false
      },
      {
        route: uri,
        name: surveyData?.title,
        isActive: true
      }
    ]
  };

  return (
    <>
      <main
        className="main-content main-content--block fadeIn"
        style={{ overflow: 'initial' }}
      >
        <BreadCrumbs data={breadCrumbs} />

        <div
          className="page"
          style={{ alignItems: 'stretch' }}
        >
          <Create
            representation={representation}
            surveyData={surveyData}
          />

          <aside className="page__aside">
            <div
              className="new-questions__info"
              style={{ top: '90px' }}
            >
              <p>{t('nps.everythingBeingSaving')}</p>
            </div>
          </aside>
        </div>
      </main>
    </>
  );
}
