import { useParams } from '@reach/router';
import { t } from 'i18next';
import { useClient } from 'jsonapi-react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FiSend } from 'react-icons/fi';

export default function CommentForm(props) {
  const client = useClient();
  const params = useParams();
  const [loading, setLoading] = useState(false)
  const { commentForm } = props;

  const handleSubmit = async e => {
    e.preventDefault();
    if (loading) return
    try {
      setLoading(true)
      await client.mutate(`/live_classrooms/${params.liveClassId}/live_classroom_messages`, {
        body: commentForm.values.body
      });

      commentForm.resetForm();
    } catch (error) {
      console.log(error);
      toast.error('Erro ao criar comentário!');
    }
    finally {
      setLoading(false)
    }
  };

  return (
    <div className="chat-widget__footer">
      <form
        className="chat-widget__comment-form"
        onSubmit={handleSubmit}
      >
        <input
          className="form__control"
          type="text"
          name="body"
          id="body"
          placeholder={t('activity.placeholderWriteComment')}
          value={commentForm.values.body}
          onChange={commentForm.handleChange}
        />
        <button
          className="btn btn--primary btn--send"
          type="submit"
          disabled={loading}
        >
          <FiSend />
        </button>
      </form>
    </div>
  );
}
