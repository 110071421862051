import React from 'react';

function TabNavBar({ selectedTab, setSelectedTab }) {
  return (
    <div className="tab">
      <button
        className={selectedTab === 'atualizacoes' ? 'tab__link active' : 'tab__link'}
        onClick={() => setSelectedTab('atualizacoes')}
      >
        Atualizações
      </button>
      <button
        className={selectedTab === 'sobre' ? 'tab__link active' : 'tab__link'}
        onClick={() => setSelectedTab('sobre')}
      >
        Sobre
      </button>
    </div>
  );
}

export default TabNavBar;
