export const mockManagers = [
  {
    id: 186,
    name: 'Professor Teste',
    profiles: ['Professor'],
    email: 'professor@estudologia.com.br',
    image: {
      url: 'https://estudologia-bucket.s3.amazonaws.com/uploads/user/image/186/thumb_image.jpeg'
    }
  },
  {
    id: 147,
    name: 'Administrador',
    profiles: ['Administrador'],
    email: 'admin@estudologia.com.br',
    image: {
      url: 'https://estudologia-bucket.s3.amazonaws.com/uploads/user/image/147/thumb_image.jpeg'
    }
  }
];

export const mockStudents = [
  {
    id: 1,
    name: 'David',
    profiles: ['Aluno'],
    email: 'david.leandro@estudologia.com.br',
    image: {
      url: 'https://estudologia-bucket.s3.amazonaws.com/uploads/user/image/1/thumb_image.jpeg'
    }
  },
  {
    id: 213,
    name: 'Bruno Chagas Estudante',
    profiles: ['Aluno'],
    email: 'bruno.chagas@estudologia.com.br',
    image: {
      url: 'https://estudologia-bucket.s3.amazonaws.com/uploads/user/image/213/thumb_image.jpeg'
    }
  },
  {
    id: 32,
    name: 'Igor',
    profiles: ['Aluno'],
    email: 'iygorsilva@gmail.com',
    image: {
      url: 'https://estudologia-bucket.s3.amazonaws.com/uploads/user/image/32/thumb_image.jpeg'
    }
  },
  {
    id: 148,
    name: 'Aluno Teste',
    profiles: ['Aluno'],
    email: 'aluno@estudologia.com.br',
    image: {
      url: 'https://estudologia-bucket.s3.amazonaws.com/uploads/user/image/148/thumb_image.jpeg'
    }
  },
  {
    id: 200,
    name: 'Alvaro',
    profiles: ['Aluno'],
    email: 'alvaros2001@gmail.com',
    image: {
      url: 'https://estudologia-bucket.s3.amazonaws.com/uploads/user/image/200/thumb_image.jpeg'
    }
  }
];
