import { useSession } from '../hooks/useSession';

export default function getPermission(name, groupName) {
  const { session } = useSession();
  const roleItems = session?.user?.roleGroupItems;

  if (roleItems) {
    const permission = roleItems?.find(item => name?.includes(item.name) && groupName?.includes(item.roleGroupName));
    return !!permission;
  }
  return false;
}
