import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import MultiSelect from 'app/components/MultiSelect';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export default function MaterialForm(props) {
  const { t } = useTranslation();
  const { setShowModal, lessonId, getMaterial, selectedMaterial } = props;
  const client = useClient();
  const [fileType, setFileType] = useState('Arquivo');
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);
  const [taxonomies, setTaxonomies] = useState([]);

  const createOrUpdateLearningSystemFiles = async values => {
    setShowModal(false);

    const parsedForm = {
      title: values.name,
      url: undefined,
      'learning-system-item-ids': [lessonId],
      taxonomy_ids: values.selectedTaxonomies.map(item => item.id),
      file: undefined
    };

    if (values.files.length > 0) {
      parsedForm.file = await toBase64(values.files[0]);
    }

    if (values.url && values.url !== '') {
      parsedForm.url = values.url;
    }

    if (selectedMaterial) {
      const { error } = await client.mutate(['learning_system_files', selectedMaterial.id], parsedForm);

      if (error) {
        toast.error('Erro ao editar material');
      } else {
        toast.success(t('toast.successEditMaterial'));
        getMaterial();
      }
    } else {
      const { error, data } = await client.mutate('learning_system_files', parsedForm);
      if (error) {
        toast.error(t('toast.errorCreateMaterial'));
      } else {
        toast.success(t('toast.successCreateMaterial'));
        getMaterial();
      }
    }
  };

  const form = selectedMaterial
    ? useFormik({
        initialValues: {
          name: selectedMaterial.title,
          files: [],
          url: selectedMaterial.file?.url || selectedMaterial.url,
          selectedTaxonomies: selectedMaterial.taxonomies
        },
        onSubmit: createOrUpdateLearningSystemFiles
      })
    : useFormik({
        initialValues: {
          name: '',
          files: [],
          url: null,
          selectedTaxonomies: []
        },
        onSubmit: createOrUpdateLearningSystemFiles
      });

  const fetchTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetTags'));
    }
  };

  useEffect(() => {
    if (selectedMaterial) {
      setFileType('URL');
    }
  }, [selectedMaterial]);

  useEffect(() => {
    fetchTaxonomies();
  }, []);

  return (
    <form
      method="post"
      className="form"
      onSubmit={form.handleSubmit}
    >
      <h2 className="form__title">{selectedMaterial ? 'Editar material' : 'Novo material'}</h2>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="fileName"
        >
          {t('taxonomyAndMaterials.fileName')}
        </label>
        <input
          className="form__control"
          id="fileName"
          name="name"
          type="text"
          placeholder={t('taxonomyAndMaterials.placeholderFileName')}
          onChange={form.handleChange}
          defaultValue={form.values.name}
        />
      </div>

      <fieldset className="form__row">
        <legend className="form__label">Upload do vídeo</legend>

        <div
          className="btn-group u-mb-2"
          role="group"
          aria-label="Insira o vídeo"
        >
          {['Arquivo', 'URL'].map((alternative, index) => (
            <React.Fragment key={index}>
              <input
                type="radio"
                name="fileType"
                autoComplete="off"
                id={`fileType-${index}`}
                checked={alternative === fileType}
                onChange={() => {
                  if (alternative === 'Arquivo') {
                    form.setFieldValue('url', null);
                  } else {
                    form.setFieldValue('files', []);
                  }

                  setFileType(alternative);
                }}
              />
              <label
                className="btn btn--outline"
                htmlFor={`fileType-${index}`}
              >
                {alternative}
              </label>
            </React.Fragment>
          ))}
        </div>

        {fileType === 'Arquivo' && (
          <label className="form__custom-file-input">
            <input
              type="file"
              name="file"
              id="file"
              onChange={e => form.setFieldValue('files', e.target.files)}
            />
            <span className="form__control">{form.values.files.length > 0 ? form.values.files[0].name : t('activity.placeholderUploadFile')}</span>
          </label>
        )}

        {fileType === 'URL' && (
          <input
            className="form__control"
            id="url"
            name="url"
            type="url"
            placeholder={t('activity.placeholderSentUrl')}
            value={form.values.url}
            onChange={form.handleChange}
          />
        )}
      </fieldset>

      <fieldset className="form__row">
        <legend className="form__label">{t('tasks.mattersRelated')}</legend>

        <MultiSelect
          selectedTaxonomies={form?.values?.selectedTaxonomies}
          setFieldValue={form.setFieldValue}
          openModal={() => setIsOpenModalTaxonomies(true)}
          placeholder={t('tasks.mattersAdd')}
        />
      </fieldset>

      <button
        className="btn btn--primary btn--wide"
        type="submit"
      >
        {selectedMaterial ? 'Editar material' : 'Criar material'}
      </button>

      {taxonomies.length > 0 && isOpenModalTaxonomies && (
        <ModalTaxonomies
          taxonomies={taxonomies}
          selectedTaxonomies={form?.values?.selectedTaxonomies}
          setFieldValue={form.setFieldValue}
          closeModal={() => setIsOpenModalTaxonomies(false)}
        />
      )}
    </form>
  );
}
