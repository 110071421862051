import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useFormik } from 'formik';
import { useCourse } from 'app/hooks/useCourse';
import ReportForm from 'app/components/ReportForm';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import DataGridGenerated from './DataGridGenerated';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import xlsx from 'json-as-xlsx';
import { navigate } from '@reach/router';

export default function CourseProgressReport() {
  const client = useClient();
  const [isLoading, setIsLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [report, setReport] = useState({});
  const [nameOrder, setNameOrder] = useState('descendent');
  const [dateOrder, setDateOrder] = useState('descendent');
  const [param, setParam] = useState('name');
  const [emptyReport, setEmptyReport] = useState(false);

  const xlsxStructure = [
    {
      sheet: 'Usuários',
      columns: [
        { label: 'Nome', value: 'Nome' },
        { label: 'Email', value: 'Email' },
        { label: 'CPF', value: 'CPF' },
        { label: 'GRE', value: 'GRE' },
        { label: 'Instituição', value: 'Instituição' },
        { label: 'Turma', value: 'Turma' },
        { label: 'Último Acesso', value: 'Último Acesso' },
        { label: '% de Conclusão', value: '% de Conclusão' }
      ],
      content: report?.users?.map(item => ({
        Nome: item.name,
        Email: item.email,
        CPF: item.cpf,
        Instituição: item.institution,
        GRE: item.regional_name,
        Turma: item.classroom,
        'Último Acesso': item['last-access'] ? new Date(item['last-access']).toLocaleDateString('pt-br') : '-',
        '% de Conclusão': `${parseInt(item['course-progress'])}%`
      }))
    }
  ];

  console.log({report})
  const handleDownloadCSV = () => {
    try {
      xlsx(xlsxStructure);
    } catch (e) {
      console.log(e);
    }
  };

  const filters = useFormik({
    initialValues: {
      course: 'blank'
    }
  });

  const { courses, getSimpleCourses } = useCourse();

  useEffect(() => {
    if (courses.length === 0) {
      getSimpleCourses();
    }
  }, []);

  const orderByName = useCallback(
    (a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameOrder === 'descendent') {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      }
    },
    [nameOrder]
  );

  const orderByLastAccess = (a, b) => {
    const lastAccessA = new Date(a['last-access']);
    const lastAccessB = new Date(b['last-access']);
    if (dateOrder === 'descendent') {
      if (lastAccessA < lastAccessB) {
        return -1;
      }
      if (lastAccessA > lastAccessB) {
        return 1;
      }

      return 0;
    } else {
      if (lastAccessA > lastAccessB) {
        return -1;
      }
      if (lastAccessA < lastAccessB) {
        return 1;
      }

      return 0;
    }
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    setHasData(false);
    setEmptyReport(false);
    const { data, error } = await client.fetch(`/courses/${filters.values.course}/progress_report`);
    if (error) {
      toast.error('Erro ao gerar relatório');
    } else {
      if (data.users.length > 0) {
        setReport({ ...data, users: data.users.sort(orderByName) });
        setNameOrder('descendent');
        setDateOrder('descendent');
        setHasData(true);
      } else {
        setEmptyReport(true);
      }
    }
    setIsLoading(false);
  };

  const orderedReport = useMemo(() => {
    let newArr = [];
    if (report.users && param === 'name' && nameOrder === 'descendent') {
      newArr = report.users.sort(orderByName);
    }
    if (report.users && param === 'name' && nameOrder === 'ascendent') {
      newArr = report.users.sort(orderByName);
    }
    if (report.users && param === 'last-access' && dateOrder === `descendent`) {
      newArr = report.users.sort(orderByLastAccess);
    }
    if (report.users && param === 'last-access' && dateOrder === `ascendent`) {
      newArr = report.users.sort(orderByLastAccess);
    }
    return { ...report, users: newArr };
  }, [param, nameOrder, dateOrder, report]);

  return (
    <main className="report">
      <header className="report__header">
        <h1 className="report__title">
          <a
            className="card__icon-wrapper"
            onClick={() => navigate(-1)}
            style={{ cursor: 'pointer' }}
          >
            <FiChevronLeft className="card__icon" />
          </a>
          Progresso dos Cursos
        </h1>
        <p className="report__description">Acompanhe o andamento dos estudantes nos cursos</p>
      </header>
      <div className="report__body">
        <div className="report-widget">
          <div className="report-widget__header">
            <h3 className="report-widget__title">Progresso dos cursos</h3>

            <div className="report-widget__filter">
              <ReportForm.Select
                blankLabel="Curso"
                label="Curso"
                value={filters.values.course}
                onChange={e => filters.setFieldValue('course', e.target.value)}
                options={courses.map(c => ({ id: c.id, name: c.title }))}
              />
              <button
                className="btn btn--outline"
                type="button"
                disabled={filters.values.course === 'blank' || isLoading}
                onClick={handleGenerate}
              >
                Gerar relatório
              </button>
              <button
                className="btn btn--outline"
                type="button"
                disabled={!hasData || isLoading}
                onClick={handleDownloadCSV}
              >
                Download do relatório
              </button>
            </div>
          </div>

          <div className="report-widget__body">
            {!hasData && !isLoading && !emptyReport && (
              <EmptyState
                type="add"
                title="Busque por um curso e depois clique em “Gerar relatório” para ver os dados"
                text=" "
                bgless
              />
            )}

            {emptyReport && (
              <EmptyState
                type="content"
                title="Nenhum dado encontrado"
                text="Tente buscar por outro curso"
                bgless
              />
            )}

            {isLoading && !hasData && <Loader.Spinner />}

            {hasData && (
              <DataGridGenerated
                setParam={setParam}
                dateOrder={dateOrder}
                setDateOrder={setDateOrder}
                nameOrder={nameOrder}
                setNameOrder={setNameOrder}
                users={orderedReport.users ?? []}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
