import React, { useState } from 'react';
import Avatar from '.';
import Modal from 'app/components/Modal';
import UserInfo from 'app/components/UserInfo';

export function AvatarStack(props) {
  const { data, className, modalTitle, truncateIn, ...restProps } = props;
  const [showModal, setShowModal] = useState(false);

  function avatarStackModifier(className) {
    switch (className) {
      case 'avatar--xs':
        return 'avatar-stack--sm';
      case 'avatar--md':
        return 'avatar-stack--md';

      default:
        break;
    }
  }

  function avatarImageMemorized(avatar) {
    const avatarImage = avatar?.image?.url;
    // const avatarImageMemo = React.useMemo(() => ({ avatarImage }), [avatarImage]);
    // return avatarImageMemo;
    return avatarImage;
  }

  return (
    <>
      <ul
        className={`avatar-stack ${avatarStackModifier(className)}`}
        onClick={() => setShowModal(true)}
        {...restProps}
      >
        {data?.length <= (truncateIn || 3) &&
          data?.map(avatar => (
            <li
              className="avatar-stack__item"
              key={avatar?.id}
            >
              <Avatar
                src={avatarImageMemorized(avatar)}
                alt={avatar?.name}
                className={className}
              />
            </li>
          ))}

        {data?.length > (truncateIn || 3) && (
          <>
            {data?.slice(0, truncateIn || 3).map(avatar => (
              <li
                className="avatar-stack__item"
                key={avatar?.id}
              >
                <Avatar
                  src={avatar?.image?.url}
                  alt={avatar?.name}
                  className={className}
                />
              </li>
            ))}
            <li className="avatar-stack__item">
              <span
                className={`avatar ${className}`}
                title={data?.slice(truncateIn || 3).map(avatar => ` ${avatar?.name}`)}
              >
                +{data?.length - (truncateIn || 3)}
              </span>
            </li>
          </>
        )}
      </ul>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        {modalTitle && <h2 className="form__title">{modalTitle}</h2>}

        <div
          className="u-overflow-y"
          style={{ minHeight: '56px', maxHeight: '88vh' }}
        >
          {data?.map(user => (
            <div
              className="student-card"
              key={user?.id}
            >
              <header className="student-card__header">
                <UserInfo
                  className={className}
                  user={user}
                />
              </header>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}
