import React, { useState, useEffect } from 'react';
import { Link, useParams, navigate } from '@reach/router';
import { FiChevronLeft, FiCalendar, FiClock, FiCheckCircle } from 'react-icons/fi';
import CourseCover from 'app/images/placeholders/show-course.jpg';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import Loader from 'app/components/loader';

export default function AboutExam() {
  const { t } = useTranslation();
  const client = useClient();
  const { id } = useParams();
  const [exam, setExam] = useState([]);
  const [courses, setCourses] = useState([]);
  const [taxonomies, setTaxonomies] = useState([]);
  const [notAllowed, setNotAllowed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);

  const getCourses = async () => {
    try {
      const { data } = await client.fetch('courses');
      setCourses(data);
    } catch {
      toast.error(t('toast.errorGetCourse'));
    }
  };

  const getResults = async () => {
    setIsLoading(true);
    const { data } = await client.fetch(`question_books_users?filter[current_user]=true&filter[question_book_id]=${id}`);

    setResults(data);
    setIsLoading(false);
  };

  const getExam = async () => {
    const { data, error } = await client.fetch(`question_books/${id}`);
    if (error) {
      toast.error(t('toast.errorGetExam'));
      setNotAllowed(true);
    } else {
      setExam(data);
      setTaxonomies(data.taxonomies);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getCourses();
    getExam();
    getResults();
  }, []);

  const approved = results.filter(result => result?.status === 'approved');

  function sumQuestionIds(exam) {
    const taxonomyArray = exam['question-book-taxonomies']?.map(taxonomy => taxonomy.question_ids) ?? [];
    return taxonomyArray.reduce((accumulator, current) => accumulator + current.length, 0);
  }

  const now = new Date();

  const isAvaliable = (new Date(exam?.['published-at']) <= now && now <= new Date(exam?.['finished-at'])) || (!exam?.['finished-at'] && new Date(exam?.['published-at']) <= now);

  const courseName = courses.filter(x => x.id === exam?.['course-ids']?.[0]);

  function formatDateTime(dateTimeString) {
    if (!dateTimeString) return '';

    const dateTime = new Date(dateTimeString);
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    return `${hours}h${minutes}`;
  }

  const hours = exam?.['resolution-time'] ? parseInt(exam['resolution-time'].split(':')[0], 10) : 0;

  const warningStart = () => {
    Swal.fire({
      title: t('warning.warningStartExams', { resolutionTime: exam?.['resolution-time'] }),
      text: t('warning.warningStartExams2'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        navigate(`/provas/${id}`);
      }
    });
  };

  return !notAllowed ? (
    <>
      <main className="main-content main-content--block">
        <Link
          to="/avaliacoes"
          className="filter-bar__back u-mb-3"
        >
          <span className="card__icon-wrapper">
            <FiChevronLeft className="card__icon" />
          </span>
          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{t('exams.thExams')}</span>
          </span>
        </Link>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div
              className="show-course-banner u-mb-5"
              style={{
                backgroundImage: `url(${false || CourseCover})`
              }}
            >
              <div className="show-course-banner__title">{exam.title}</div>
              <div className="show-course-banner__description">
                {approved.length > 0 && !exam?.['able-to-retry'] ? ( //Se o aluno ja foi aprovado e não esta disponivel para repetir
                  <>
                    <p>Esta avaliação ja foi concluida e não pode ser repetida.</p>
                    <p>Veja o seu resultado no botão abaixo!</p>
                  </>
                ) : (
                  //Não realizou a prova ainda ou foi reprovado ou esta disponivel para repetir
                  <>
                    <p>{t('exams.examBannerDescription')}</p>
                    <p>{t('exams.examBannerDescriptionWaterAndBathroom')}</p>
                  </>
                )}
              </div>

              {isAvaliable && exam?.['is-question-book-done'] && (exam?.['able-to-retry'] || approved.length === 0) ? ( //Aluno reprovado ou aprovado com opção de repetir
                <div className="show-course-banner__cta">
                  <button
                    className="btn btn--primary btn--wide"
                    questionBookName={exam?.title}
                    onClick={warningStart}
                  >
                    Refazer prova
                  </button>
                </div>
              ) : isAvaliable && !exam?.['is-question-book-done'] ? ( //Prova ainda não realizada
                <div className="show-course-banner__cta">
                  <button
                    className="btn btn--primary btn--wide"
                    questionBookName={exam?.title}
                    onClick={warningStart}
                  >
                    {t('button.startExam')}
                  </button>
                </div>
              ) : (
                isAvaliable && (
                  //Se o aluno ja foi aprovado e não esta disponivel para repetir
                  <Link
                    className="btn btn--primary"
                    to={`/avaliacoes/meus-resultados`}
                  >
                    {t('exams.thResults')}
                  </Link>
                )
              )}
            </div>

            <div className="page">
              <div className="page__content">
                {exam?.['is-question-book-done'] && approved.length === 0 && <span className="badge badge--danger">Você não foi aprovado, Refaça a prova para melhorar seu desempenho</span>}
                <div className="show-course-section">
                  <span className="expanded-calendar__tag expanded-calendar__tag--course u-mb-2">{courseName[0]?.title}</span>
                  <div className="show-course-section__title">{t('exams.titleDescription')}</div>
                  <div className="show-course-section__description">{exam.description}</div>

                  {exam?.['published-at'] && exam?.['finished-at'] && (
                    <p className="card__count u-mb-3">
                      <FiCalendar className="card__icon" />{' '}
                      <span>
                        {t('exams.from')}
                        <strong> {moment(exam?.['published-at']).format(t('datesForm.formatDate'))}</strong> {t('exams.at')} <strong>{formatDateTime(exam?.['published-at'])}</strong> {t('exams.to')}
                        <strong> {moment(exam?.['finished-at']).format(t('datesForm.formatDate'))}</strong> {t('exams.at')} <strong>{formatDateTime(exam?.['finished-at'])}</strong>
                      </span>
                    </p>
                  )}
                  <div className="card__columns">
                    <p className="card__count">
                      <FiClock className="card__icon" />

                      {hours > 1 ? (
                        <span>
                          <div dangerouslySetInnerHTML={{ __html: t('exams.hoursDuration', { hours: hours }) }}></div>
                        </span>
                      ) : (
                        <span>
                          <div dangerouslySetInnerHTML={{ __html: t('exams.hourDuration', { hours: hours }) }}></div>
                        </span>
                      )}
                    </p>
                    <p className="card__count">
                      <FiCheckCircle className="card__icon" />
                      <span>
                        <strong>{exam?.['question-count']}</strong> {t('exams.questions')}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <aside className="page__aside">
                <div className="widget">
                  <div className="widget__header">
                    <h3 className="widget__title">{t('exams.titleMaterialsDistribuition')}</h3>
                    <p className="widget__subtitle">
                      {exam?.['question-count']} {t('exams.questions')}
                    </p>
                  </div>
                  <div className="widget__body">
                    <div className="accordion-widget">
                      {taxonomies.map(taxonomy => (
                        <div className="accordion-widget__item">
                          <div
                            className="accordion-widget__header"
                            style={{ justifyContent: 'space-between', cursor: 'default' }}
                            key={taxonomy.id}
                            title={taxonomy.name}
                          >
                            {taxonomy.name.length > 25 ? (
                              <span
                                data-tooltip-content="teste"
                                className="badge badge--primary"
                              >
                                {taxonomy.name.slice(0, 25)}...
                              </span>
                            ) : (
                              <span
                                data-tooltip-content="teste"
                                className="badge badge--primary"
                              >
                                {taxonomy.name}
                              </span>
                            )}

                            <span className="widget__text">
                              {taxonomy.questionCount} {t('exams.questions')}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </aside>
            </div>
          </>
        )}
      </main>
    </>
  ) : (
    <div className="main-content main-content--block">
      <EmptyState
        type="data"
        title={t('emptyState.routeNoAccessTitle')}
        text={t('emptyState.contactAnAdministratorText')}
      />
    </div>
  );
}
