import { SimpleModal } from 'app/components/Modal';
import { useSession } from 'app/hooks/useSession';
import InputMask from 'react-input-mask';
import toast from 'react-hot-toast';
import promiseRequest from 'app/utils/promiseToast';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { validateCPF } from 'app/utils/validateCPF';

export default function CpfModal({ showModal, setShowModal }) {
  const { promiseToast, isSubmitting } = promiseRequest();
  const requiredCPF = process.env.REACT_APP_REQUIRED_CPF === 'true';
  const { session, setSession, sanitizeResponse } = useSession();
  const [cpf, setCpf] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (!session?.user?.cpfCnpj && requiredCPF) {
      setTimeout(() => setShowModal(true), 500);
    }
  }, []);

  const updateUser = async e => {
    e.preventDefault();
    if (validateCPF(cpf)) {
      promiseToast({
        url: ['users', session.user.id],
        request: { cpfCnpj: cpf },
        successText: t('toast.successUpdateProfile'),
        errorText: t('toast.error')
      })
        .then(data => {
          const newObject = sanitizeResponse(data);
          setTimeout(() => setShowModal(false), 500);
          setSession({ user: newObject });
        })
        .catch(error => {
          toast.error(`Ops, ${error.id} ${error.title}`);
        });
    } else {
      toast.error('CPF inválido');
    }
  };

  return (
    <SimpleModal
      hideCloseButton
      show={showModal}
    >
      <form onSubmit={e => updateUser(e)}>
        <div className="simple-modal__description simple-modal__description--cpf">
          <span
            className="form__label"
            style={{ textAlign: 'center' }}
          >
            Você ainda não cadastrou seu cpf
          </span>
          <label
            htmlFor="cpf"
            className="course-about__description u-mb-0"
            style={{ textAlign: 'center' }}
          >
            Digite apenas os números do seu CPF no campo abaixo para continuar navegando na plataforma
          </label>

          <InputMask
            className="form__control"
            mask="999.999.999-99"
            name="cpf"
            id="cpf"
            value={cpf}
            onChange={e => {
              console.log(e.target.value);
              setCpf(e.target.value);
            }}
          />
          <button
            disabled={isSubmitting}
            type="submit"
            className="btn btn--outline btn--full u-mb-4"
          >
            Cadastrar
          </button>
        </div>
      </form>
    </SimpleModal>
  );
}
