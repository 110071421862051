import React from 'react';
import styles from './PositionsCompatibility.module.scss';

export default function PositionsCompatibility() {
  return (
    <div className="report-widget">
      <div className="report-widget__header">
        <h3 className="report-widget__title report-widget__title--sm">Compatível com os cargos de:</h3>
      </div>

      <div className="report-widget__body">
        <div className={styles.wrapper}>
          <div className={styles.card}>
            <span className={`${styles.tag} square-tag square-tag--success`}>98%</span> <h3 className={styles.title}>Diretor de vendas</h3>
          </div>
          <div className={styles.card}>
            <span className={`${styles.tag} square-tag square-tag--warning`}>58%</span> <h3 className={styles.title}>Vendedor</h3>
          </div>
          <div className={styles.card}>
            <span className={`${styles.tag} square-tag square-tag--warning`}>55%</span> <h3 className={styles.title}>Vendedor de produtos de tecnologia</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
