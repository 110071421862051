import React from 'react';
import MainSidebar from 'app/components/Sidebar';
import { useProgramOfStudies } from 'app/hooks/useProgramOfStudies';
import { useTranslation } from 'react-i18next';

export const Sidebar = () => {
  const { t } = useTranslation();
  const { firstLevelTaxonomies, changeSelectedTaxonomy, selectedTaxonomy } =
    useProgramOfStudies();

  return (
    <MainSidebar>
      <nav className="notebook-sidebar__nav">
        <button
          type="button"
          onClick={() => history.back()}
          className="btn btn--primary btn--full u-mb-4"
        >
          {t('button.back')}
        </button>

        {firstLevelTaxonomies.map((t, i) => (
          <div
            key={i}
            className={`taxonomies__item${
              selectedTaxonomy.id === t.id ? ' active' : ''
            }`}
            onClick={() => changeSelectedTaxonomy(t)}
          >
            <span className="notebook-sidebar__link-name" title={t.name}>
              {t.name}
            </span>
            {/** <span className="notebook-sidebar__count">4/10</span> * */}
          </div>
        ))}
      </nav>
    </MainSidebar>
  );
};

