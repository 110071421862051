import React, { useEffect, useState } from 'react';
import CommentCard from '../components/postComment';
import api from '../services/api.js';
import { useClient, useMutation } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSession } from 'app/hooks/useSession';
import { FiChevronLeft, FiMessageCircle } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import CommentsModal from './CommentsModal';
import Swal from 'sweetalert2';
import getAuthorityLevel, { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import moment from 'moment';

function QuestionCard(props) {
  const { t } = useTranslation();
  const [question, setQuestion] = useState(props.question);
  const [selectedAlternative, setSelectedAlternative] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [recentComment, setRecentComment] = useState(null);
  const [addAnswer] = useMutation('answers');
  const [addComment] = useMutation('comments');
  const { session } = useSession();
  const client = useClient();
  const authorityLevel = getAuthorityLevel();

  const isAdmin = !checkIncludeProfiles({ only: ['student'] });

  const submitAnswer = () => {
    if (!answer && selectedAlternative !== null) {
      addAnswer({
        'alternative-id': selectedAlternative,
        'question-id': question.id
      })
        .then()
        .then(response => {
          if (response.error) {
            toast.error(response.error.title);
          } else {
            setAnswer(response.data);
            props.updateLastAnswerSubmited(response.data?.id);
          }
        })
        .catch(error => {
          toast.error(t('toast.error'));
          console.error(error);
        });
    } else {
      toast.error(t('toast.errorSelectAlternative'));
    }
  };

  let questionButton;
  if (answer) {
    if (answer['is-correct']) {
      questionButton = (
        <div
          className="alert alert--success"
          role="alert"
        >
          {t('button.textAnswerCorret')}
        </div>
      );
    } else {
      questionButton = (
        <div
          className="alert alert--danger"
          role="alert"
        >
          <span>
            {t('activity.buttonWarning')}
            <strong>{question.modality === 'right_wrong' ? <> {answer['correct-alternative']?.description}</> : <> {answer['correct-alternative']?.name}</>}</strong>
          </span>
        </div>
      );
    }
  } else {
    questionButton = (
      <div
        className="btn btn--secondary"
        onClick={submitAnswer}
      >
        {t('button.sendAnswer')}
      </div>
    );
  }

  const createComment = e => {
    e.preventDefault();
    addComment({ body: commentText, question_id: question.id })
      .then(response => {
        if (response.error) {
          toast.error(response.error.title);
        } else {
          setCommentText('');
          let auxQuestion = question;
          auxQuestion.comments.push(response.data);
          setQuestion({ ...auxQuestion });

          const newRecentComment = getRecentComment(auxQuestion.comments);
          setRecentComment(newRecentComment);
        }
      })
      .catch(error => {
        toast.error(t('toast.error'));
        console.error(error);
      });
  };

  const deleteComment = comment => {
    Swal.fire({
      title: 'Deseja mesmo excluir este comentário?',
      text: 'Esta ação não poderá ser desfeita',
      confirmButtonText: 'Sim',
      showDenyButton: true,
      denyButtonText: 'Não',
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        api
          .delete(`comments/${comment.id}`)
          .then(response => {
            let auxQuestion = question;
            auxQuestion.comments = auxQuestion.comments.filter(c => c.id !== comment.id);
            setQuestion({ ...auxQuestion });
          })
          .catch(error => {
            console.error(error);
          });
      }
    });
  };

  const alternativeLetter = index => {
    switch (index) {
      case 0:
        return 'A';
      case 1:
        return 'B';
      case 2:
        return 'C';
      case 3:
        return 'D';
      case 4:
        return 'E';
      case 5:
        return 'F';
    }
  };

  const getRecentComment = comments => {
    if (comments.length === 0) {
      return null;
    }

    const pinnedComment = comments.find(comment => comment.pinned);
    if (pinnedComment) {
      return pinnedComment;
    }

    const adminComment = comments.filter(comment => !comment.user['profile-names'].includes('Aluno'));

    if (adminComment.length > 0) {
      return adminComment.reduce((recentComment, currentComment) => {
        if (moment(currentComment['created-at']).isAfter(moment(recentComment['created-at']))) {
          return currentComment;
        } else {
          return recentComment;
        }
      });
    } else {
      return comments.reduce((recentComment, currentComment) => {
        if (moment(currentComment['created-at']).isAfter(moment(recentComment['created-at']))) {
          return currentComment;
        } else {
          return recentComment;
        }
      });
    }
  };

  const setPinnedComment = async commentId => {
    const pinnedComment = question.comments.find(comment => comment.pinned);

    if (pinnedComment && pinnedComment.id !== commentId) {
      await client.mutate(`comments/${pinnedComment.id}`, { pinned: false });
    }

    if (commentId) {
      await client.mutate(`comments/${commentId}`, { pinned: true });
    }

    setQuestion({
      ...question,
      comments: question.comments.map(item => {
        return { ...item, pinned: item.id === commentId };
      })
    });
  };

  useEffect(() => {
    const pinnedCommentId = question?.comments.find(comment => comment.pinned);
    const newRecentComment = getRecentComment(question.comments);
    setRecentComment(newRecentComment);
  }, [question?.comments]);

  return (
    <>
      <div
        className="question-card__statement"
        dangerouslySetInnerHTML={{ __html: question?.description }}
      />
      <div className="question-card__answers">
        {question?.alternatives &&
          question?.alternatives
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((alternative, index) => {
              const handleChecked = authorityLevel !== 'student' ? alternative?.id === question?.['correct-alternative']?.id : selectedAlternative === alternative.id;
              return (
                <div
                  className={`question-card__answer ${answer && alternative['is-correct'] && 'question-card__answer--correct'}`}
                  key={alternative.id}
                >
                  <div className="question-card__answer-letter">{alternativeLetter(index)}</div>
                  <input
                    type="radio"
                    id={alternative.id}
                    checked={handleChecked}
                    onChange={() => setSelectedAlternative(alternative.id)}
                  />
                  <label
                    htmlFor={alternative.id}
                    dangerouslySetInnerHTML={{ __html: alternative.description }}
                  />
                </div>
              );
            })}
      </div>

      {!isAdmin ? <div className="question-card__cta">{questionButton}</div> : null}

      {(answer || isAdmin) && (
        <>
          <div className="answer-comment">
            <div className="answer-comment__header">
              <h3 className="answer-comment__title">Comentários</h3>
              <button
                onClick={() => setShowModal(true)}
                className="card__icon-wrapper"
              >
                <FiMessageCircle className="card__icon" /> {question?.comments?.length}
              </button>
            </div>

            <div className="answer-comment__body">
              {question?.comments?.length === 0 && (
                <EmptyState
                  type="register"
                  title={t('emptyState.titleComments')}
                  text={
                    <span>
                      <strong
                        onClick={() => setShowModal(true)}
                        style={{ textDecoration: 'underline' }}
                        className="u-cursor-pointer"
                      >
                        {t('emptyState.textChildrenClickHere')}
                      </strong>{' '}
                      {t('emptyState.textChildrenToSendComment')}
                    </span>
                  }
                  bgless
                />
              )}
              {(isAdmin || (answer && question.comments && question.comments.length > 0)) && (
                <>
                  <div className="question-card__comment">
                    {question?.comments
                      ?.sort((a, b) => new Date(b['created-at']) - new Date(a['created-at']))
                      .map((comment, index) => {
                        if (recentComment && comment.id === recentComment.id) {
                          return (
                            <CommentCard
                              index={index}
                              key={comment.id}
                              comment={comment}
                              comments={question.comments}
                              deleteComment={comment => deleteComment(comment)}
                              pinned={recentComment}
                              setPinnedComment={setPinnedComment}
                            />
                          );
                        }
                        return null;
                      })}
                  </div>

                  <button
                    className="btn btn--wide btn--outline"
                    onClick={() => setShowModal(true)}
                  >
                    {t('textsCommon.sendComment')}
                  </button>
                </>
              )}

              <CommentsModal
                show={showModal}
                onClose={() => setShowModal(false)}
                createComment={createComment}
                commentText={commentText}
                setCommentText={setCommentText}
                isAdmin={isAdmin}
                answer={answer}
                comments={question?.comments}
                deleteComment={deleteComment}
                setPinnedComment={setPinnedComment}
                recentComment={recentComment}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default QuestionCard;
