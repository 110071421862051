import React, { useState, createContext } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const QuestionBooksContext = createContext();

export const QuestionBooksProvider = ({ children }) => {
  const { t } = useTranslation();
  const [questionBooks, setQuestionBooks] = useState([]);
  const [questionBook, setQuestionBook] = useState();
  const [currentQuestionBook, setCurrentQuestionBook] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState(0);

  const [loading, setLoading] = useState(true);
  const client = useClient();

  const getQuestionBooks = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('question_books?filter[visibility]=train');
    if (error) {
      toast.error(t('toast.errorGetQuestionBooks'));
    } else {
      setLoading(false);
      setQuestionBooks(data);
      // setQuestionBooks(data.filter(q => q['learning-system-ids'].length === 0));
    }
  };

  const getQuestionBook = async id => {
    setLoading(true);
    const { data, error } = await client.fetch(`question_books/${id}`);
    if (error) {
      toast.error(t('toast.errorGetQuestionBooks'));
    } else {
      setQuestionBook(data);
      setLoading(false);
    }
  };

  const createQuestionBook = async form => {
    setLoading(true);
    const { error } = await client.mutate('question_books', form);

    if (error) {
      switch (error.title) {
        case 'Não existem questões para esse filtro':
          toast.error(t('toast.errorGetFilteredQuestion'));
          break;
        default:
          toast.error(t('toast.errorCreateQuestionBook'));
      }
    } else {
      toast.success(t('toast.successCreateQuestionBook'));
    }

    await getQuestionBooks();
  };

  const deleteQuestionBook = async id => {
    setLoading(true);
    const { error } = await client.delete(['question_books', id]);

    if (error) {
      toast.error(t('toast.errorDeleteQuestionBook'));
    } else {
      getQuestionBooks();
      toast.success(t('toast.successDeleteQuestionBook'));
    }
  };

  return (
    <QuestionBooksContext.Provider
      value={{
        loading,
        questionBooks,
        setQuestionBooks,
        getQuestionBooks,
        createQuestionBook,
        deleteQuestionBook,
        currentQuestionBook,
        setCurrentQuestionBook,
        correctAnswers,
        setCorrectAnswers,
        questionBook,
        getQuestionBook
      }}
    >
      {children}
    </QuestionBooksContext.Provider>
  );
};
