import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from 'app/components/loader';
import toast from 'react-hot-toast';
import { t } from 'i18next';

export default function BarChart(props) {
  const { matterId, client } = props;
  const [mattersData, setMattersData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getMatter = async () => {
    setLoading(true);
    try {
      const { data } = await client.fetch(`/reports/study_plans/taxonomies?taxonomy_parent_id=${matterId}`);
      setMattersData(data?.['chart-data']);
      setLoading(false);
    } catch {
      toast.error(t('Erro ao buscar matérias'));
      setLoading(false);
    }
  };

  useEffect(() => {
    getMatter();
  }, [matterId]);

  const colors = ['#7B63BF', '#5DC9DD', '#8E7BC3', '#B4A7D6', '#DAD3E9', '#D0E0E3', '#A2C4C9', '#76A5AF', '#45808D', '#134E5B'];
  const series = mattersData?.slice(0, 3)?.map((data, index) => ({
    name: data['question-book'].title,
    color: colors[index],
    data: data?.data?.map(item => parseFloat(item.last_result.toFixed(2)))
  }));

  const namesArray = mattersData[0]?.data.map(item => item.name);

  const options = {
    chart: {
      type: 'bar'
    },
    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: namesArray,
      title: {
        text: null
      },
      labels: {
        align: 'left',
        reserveSpace: true,
        style: {
          whiteSpace: 'nowrap',
          width: 112,
          textOverflow: 'ellipsis'
        }
      },
      gridLineWidth: 0,
      lineWidth: 0
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: null
      },
      labels: {
        overflow: 'justify',
        format: '{value}%'
      },
      gridLineWidth: 1,
      lineWidth: 0
    },
    tooltip: {
      valueSuffix: '%'
    },

    series: series
  };

  return !loading ? (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  ) : (
    <Loader />
  );
}
