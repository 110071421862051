import React, { useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import Loader from '../../components/loader';
import { useProgramOfStudies } from '../../hooks/useProgramOfStudies';

export const ListQuestions = () => {
  const [questionsSearch, setQuestionsSearch] = useState('');
  const [searchTermTimeout, setSearchTermTimeout] = useState();
  const { selectedTaxonomy, selectedQuestion, setSelectedQuestion, selectingTaxonomy, questionsPageNumber, setQuestionsPageNumber, searchTerm, setSearchTerm } = useProgramOfStudies();

  const searchQuestions = query => {
    clearTimeout(searchTermTimeout);
    setQuestionsSearch(query);

    const timeout = setTimeout(() => {
      setSearchTerm(query);
    }, 500);
    setSearchTermTimeout(timeout);
  };

  return (
    <div className="new-questions__sidebar">
      <input
        aria-label="Buscar questões"
        className="form__control form__control--search-with-icon u-mb-2"
        placeholder="Pesquisar questões"
        type="search"
        name="questionsSearch"
        id="questionsSearch"
        value={questionsSearch}
        onChange={e => searchQuestions(e.target.value)}
      />

      {selectedTaxonomy.questions.length === 0 ? (
        <p className="new-questions__empty-state-text">Cria a primeira questão para essa matéria</p>
      ) : (
        <>
          <nav
            aria-label="Navegue entre as questões"
            className="pagination-wrapper"
          >
            <ul className="pagination">
              <li className="pagination__item">
                <button
                  className="pagination__link"
                  type="button"
                  onClick={() => {
                    if (questionsPageNumber > 1) {
                      setQuestionsPageNumber(questionsPageNumber - 1);
                    }
                  }}
                >
                  {t('exams.previous')}
                </button>
              </li>
              <li className="pagination__item active">
                <button
                  className="pagination__link"
                  type="button"
                >
                  {questionsPageNumber}
                </button>
              </li>
              <li className="pagination__item">
                <button
                  className="pagination__link"
                  type="button"
                  onClick={() => {
                    setQuestionsPageNumber(questionsPageNumber + 1);
                  }}
                >
                  {t('button.next')}
                </button>
              </li>
            </ul>
          </nav>

          {selectingTaxonomy ? (
            <Loader />
          ) : (
            <>
              {/* <input */}
              {/*   className="form__control form__control--small form__control--search u-mb-4" */}
              {/*   type="search" */}
              {/*   name="search" */}
              {/*   id="search" */}
              {/*   placeholder="Pesquisar questão" */}
              {/* /> */}

              {selectedTaxonomy.questions.map(question => (
                <div
                  onClick={() => {
                    setSelectedQuestion(question);
                  }}
                  key={question.id}
                  className={`taxonomies__item${question.id === selectedQuestion.id ? ' active' : ''}`}
                >
                  <span className="taxonomies__text">Questão #{question.id}</span>
                  <button
                    className="taxonomies__btn"
                    type="button"
                  >
                    <FiMoreHorizontal />
                  </button>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};
