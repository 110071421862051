import React from 'react';
import { useSession } from 'app/hooks/useSession';
import { AdminMetrics } from './AdminMetrics';
import { UserMetrics } from './UserMetrics';

export default function ExamMetrics() {
  const { session } = useSession();

  const sessionUser = session.user.profileNames[0];

  return sessionUser === 'Aluno' ? <UserMetrics /> : <AdminMetrics />;
}
