import React, { useEffect, useMemo, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { Link } from '@reach/router';
import BreadCrumbs from 'app/components/BreadCrumbs';
import ChallengeTab from '../../ChallengeTab';
import StudentDataGrid from './StudentDataGrid';
import HitsByMatterColumnChart from './HitsByMatterColumnChart';
import BannerChallenge from './BannerChallenge';
import ProfileChallenge from './ProfileChallenge';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

export function ViewChallenge(props) {
  const { selectedTab, challengeId, uri } = props;
  const [challenge, setChallenge] = useState([]);
  const [challengeData, setChallengeData] = useState();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [challengeParticipants, setChallengeParticipants] = useState([]);
  const [orderByAccuracy, setOrderByAccuracy] = useState('ascendent');
  const client = useClient();

  const fetchData = () => {
    const fetchChallenge = client.fetch(`challenges/${challengeId}`);
    const fetchChallengeData = client.fetch(`/challenges/${challengeId}/question_accuracy`);
    setLoading(true);
    Promise.allSettled([fetchChallengeData, fetchChallenge]).then(([dataResponse, challengeResponse]) => {
      if (dataResponse.value.error) {
        toast.error('Erro ao buscar dados do desafio');
      } else {
        setChallengeData(dataResponse.value.data.data.map(item => ({ label: item.taxonomy, value: item['question-accuracy'] })));
      }
      if (challengeResponse.value.error) {
        toast.error('Erro ao buscar desafio');
      } else {
        setChallenge(challengeResponse.value.data);
      }
    });
    setLoading(false);
  };

  const fetchChallengeRanking = async () => {
    const { data, error } = await client.fetch(`/challenges/${challengeId}/student_results?page[number]=${pageNumber}&page[size]=10`);
    if (error) {
      toast.error('Erro ao buscar participantes');
      setHasMore(false);
    } else {
      if (data.data.length === 0) {
        setHasMore(false);
      }
      setChallengeParticipants(prevState => [...prevState, ...data.data]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchChallengeRanking();
  }, [pageNumber]);

  const filters = useFormik({
    initialValues: {
      searchTerm: ''
    }
  });

  const breadCrumbs = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: uri,
        name: 'Desafio da Semana',
        isActive: true
      }
    ]
  };

  const filteredParticipants = useMemo(() => {
    const newArr = challengeParticipants?.filter(item => !filters.values.searchTerm || item.name.toLowerCase().includes(filters.values.searchTerm.toLowerCase()));
    return newArr;
  }, [challengeParticipants, filters.values.searchTerm]);

  const orderedParticipants = useMemo(() => {
    let newArr;
    if (orderByAccuracy === 'descendent') {
      newArr = filteredParticipants.sort((a, b) => a['accuracy-rate'] - b['accuracy-rate']);
    } else {
      newArr = filteredParticipants.sort((a, b) => b['accuracy-rate'] - a['accuracy-rate']);
    }

    return newArr;
  }, [orderByAccuracy, filteredParticipants]);

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <ChallengeTab selectedTab={selectedTab} />

      <div className="filter-bar">
        <div className="filter-bar__inner">
          <Link
            className="filter-bar__back"
            to="/desafio-da-semana/resultados"
          >
            <span className="card__icon-wrapper card__icon-wrapper--sm">
              <FiChevronLeft className="card__icon" />
            </span>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{challenge?.title}</span>
            </span>
          </Link>
        </div>
      </div>

      <BannerChallenge challenge={challenge} />

      <div className="widget-wrapper">
        <ProfileChallenge data={challenge} />

        <div
          className="widget"
          style={{ flexGrow: 4 }}
        >
          <div className="widget__header">
            <h3 className="widget__title">Índice de acerto por matéria</h3>
          </div>

          <div className="widget__body">
            <HitsByMatterColumnChart chartData={challengeData} />
          </div>
        </div>
      </div>

      <div className="widget">
        <div className="widget__header">
          <div className="widget__title-with-btn">
            <h3 className="widget__title">Participantes</h3>
            <span className="widget__cont">
              <span>Total de {challengeParticipants.length}</span>
            </span>
          </div>
        </div>

        <div className="widget__body">
          <div className="filter-bar filter-bar--borderless">
            <div className="filter-bar__inner">
              <div className="filter-bar__row">
                <label
                  className="filter-bar__label"
                  htmlFor="search"
                >
                  {t('textsCommon.research')}
                </label>
                <input
                  className="form__control form__control--search-with-icon"
                  placeholder="Buscar por estudante"
                  type="search"
                  name="search"
                  id="search"
                  value={filters.values.searchTerm}
                  onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
                  style={{ width: '400px' }}
                />
              </div>
            </div>
          </div>

          <StudentDataGrid
            setOrderByAccuracy={setOrderByAccuracy}
            orderByAccuracy={orderByAccuracy}
            hasMore={hasMore}
            setPageNumber={setPageNumber}
            participants={orderedParticipants}
            challengeId={challengeId}
          />
        </div>
      </div>
    </main>
  );
}
