import React, { useState } from 'react';
import { FiVideo, FiMaximize2 } from 'react-icons/fi';
import * as Fi from 'react-icons/fi';
import AverageAccessWidget from 'app/screens/LmsStudent/LessonMetrics/AverageAccessWidget';
import ModuleResultWidget from 'app/screens/LmsStudent/LessonMetrics/ModuleResultWidget';
import TaskSolutionWidget from './TaskSolutionWidget';
import AccessedMaterialsWidget from './AccessedMaterialsWidget';
import CourseModal from './CourseModal';
import ClassroomModal from './ClassroomModal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import IncomeBySubject from './IncomeBySubjectWidget';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

export default function userMetricsView(props) {
  const { t } = useTranslation();
  const { isStudent, userMetrics, isAdmin, isCurrentUser, user, id, loading } = props;
  const [showModal, setShowModal] = useState({
    course: false,
    classroom: false
  });

  const { abilities } = usePlatformAbilities();

  function getIcon(icon) {
    switch (icon) {
      case 'FiThumbsUp':
        return <Fi.FiThumbsUp />;
      case 'FiMessageCircle':
        return <Fi.FiMessageCircle />;
      case 'FiFolder':
        return <Fi.FiFolder />;
      case 'FiVideo':
        return <Fi.FiVideo />;
      case 'FiEdit':
        return <Fi.FiSend />;
      case 'FiMousePointer':
        return <Fi.FiMousePointer />;
      default:
        return <Fi.FiUser />;
    }
  }
  function getTooltip(label) {
    switch (label) {
      case 'Estudantes':
        return 'Número de estudantes';
      case 'Cursos':
        return 'Número de cursos';
      case 'Turmas':
        return 'Número de turmas';
      case t('textsCommon.likes'):
        return t('textsCommon.numberLikes');
      case t('textsCommon.comments'):
        return t('textsCommon.numberComments');
      case 'Posts':
        return t('textsCommon.numberPosts');
      case 'Acessos':
        return 'Número total de acessos únicos por dis na última semana';
      case t('textsCommon.accessedClasses'):
        return t('textsCommon.numberAccessedClasses');
      case t('textsCommon.accessedMaterials'):
        return t('textsCommon.numberAccessedMaterials');
    }
  }

  return (
    <>
      {isStudent && (isAdmin || isCurrentUser) && (
        <div className="round-dg-wrapper">
          <table className="round-dg round-dg--light u-mb-4">
            <thead className="round-dg__header">
              <tr className="round-dg__row">
                <th className="round-dg__cell-header">{t('journey.thCourses')}</th>
                <th className="round-dg__cell-header">{t('journey.thClasses')}</th>
                <th className="round-dg__cell-header">{t('journey.thLastLmsWatched')}</th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '120px' }}
                >
                  {t('journey.thLastAccess')}
                </th>
              </tr>
            </thead>

            <tbody className="round-dg__body">
              <tr className="round-dg__row">
                <th className="round-dg__cell-header round-dg__cell-header--row">
                  {userMetrics?.courses?.[0]?.title}{' '}
                  {userMetrics?.courses?.length > 1 && (
                    <span
                      className="badge badge--tiny badge--more"
                      style={{ verticalAlign: 'sub' }}
                    >
                      + {userMetrics?.courses?.length - 1}
                    </span>
                  )}
                  <button
                    className="dg-widget__btn u-ml-2"
                    onClick={() => {
                      setShowModal({ ...showModal, course: true });
                    }}
                  >
                    <FiMaximize2 />
                  </button>
                </th>
                <td className="round-dg__cell">
                  {userMetrics?.classrooms?.[0]?.title}{' '}
                  {userMetrics?.classrooms?.length > 1 && (
                    <span
                      className="badge badge--tiny badge--more"
                      style={{ verticalAlign: 'sub' }}
                    >
                      + {userMetrics?.classrooms?.length - 1}
                    </span>
                  )}
                  <button
                    className="dg-widget__btn u-ml-2"
                    onClick={() => {
                      setShowModal({ ...showModal, classroom: true });
                    }}
                  >
                    <FiMaximize2 />
                  </button>
                </td>
                <td className="round-dg__cell">
                  <FiVideo
                    className="u-mr-1"
                    style={{ width: '16px' }}
                  />
                  {userMetrics?.lastClassAttended}
                </td>
                <td className="round-dg__cell">{userMetrics?.lastAccess}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {isStudent && (isAdmin || isCurrentUser) ? (
        <div className="widget u-mb-4">
          <div className="widget__body">
            <div className="widget-wrapper">
              {userMetrics?.items.map(info => (
                <div
                  className="info-widget info-widget--t2"
                  id={info.label}
                  data-tooltip-content={getTooltip(info.label)}
                  key={info.label}
                >
                  <ReactTooltip
                    anchorId={info.label}
                    className="tooltip"
                  />

                  <div className="info-widget__header">
                    <h3 className="info-widget__title">{info.label}</h3>
                  </div>
                  <div className="info-widget__body">
                    <p className="info-widget__value">
                      {getIcon(info.icon)} {info.count}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}

      {isStudent && (isAdmin || isCurrentUser) ? (
        <div className="widget-wrapper">
          <ModuleResultWidget
            loading={loading}
            userMetrics={userMetrics}
            filter={{
              course: 'blank',
              classroom: 'blank',
              student: user?.id
            }}
            userId={user?.id}
          />
        </div>
      ) : null}

      {isStudent && (isAdmin || isCurrentUser) ? (
        <div className="widget-wrapper">
          <div
            className="widget-wrapper__col"
            style={{ flexGrow: '1' }}
          >
            <AverageAccessWidget title={t('journey.averageAccessStudentTitle')} />
          </div>

          {/* <RecentActivitiesWidget userId={id} /> */}
        </div>
      ) : null}

      {isStudent && (isAdmin || isCurrentUser) ? (
        <div className="widget-wrapper">
          {abilities.tarefas && <TaskSolutionWidget userId={id} />}

          <AccessedMaterialsWidget userId={id} />
        </div>
      ) : null}

      {isStudent && (isAdmin || isCurrentUser) ? (
        <div className="widget-wrapper">
          <IncomeBySubject userId={id} />
        </div>
      ) : null}

      <CourseModal
        courses={userMetrics?.courses}
        show={showModal.course}
        onClose={() => {
          setShowModal({ ...showModal, course: false });
        }}
      />

      <ClassroomModal
        classrooms={userMetrics?.classrooms}
        show={showModal.classroom}
        onClose={() => {
          setShowModal({ ...showModal, classroom: false });
        }}
      />
    </>
  );
}
