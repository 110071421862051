import React, { useState } from 'react';
import UserInfo from 'app/components/UserInfo';
import { t } from 'i18next';

export default function ParticipantItemCard(props) {
  const { allUsers, addParticipantToClassroom, deleteParticipantFromClassroom, classroom } = props;

  const isIncluded = classroom['user-ids'].includes(allUsers.id);

  return (
    <div className="card card--h u-mb-2">
      <UserInfo user={allUsers} />

      <div className="card__footer">
        <button
          type="button"
          onClick={() => {
            if (!isIncluded) {
              addParticipantToClassroom(allUsers.id);
            } else {
              deleteParticipantFromClassroom(allUsers.id);
            }
          }}
          className={isIncluded ? 'btn btn--wide btn--outline active' : 'btn btn--wide btn--outline'}
        >
          {isIncluded ? t('button.remove') : t('button.add')}
        </button>
      </div>
    </div>
  );
}
