import React, { useState } from 'react';
import Modal from 'app/components/Modal/Widget';

function HitWidget(props) {
  const {hitsByTaxonomy} = props;
  const [currentTaxonomy, setCurrentTaxonomy] = useState(null);

  return (
    <div className="hit-widget">
      <div className="hit-widget__header">
        <h4 className="hit-widget__title">Nº Acertos/Total</h4>
      </div>
      <div className="hit-widget__body">
        {hitsByTaxonomy && hitsByTaxonomy.map((taxonomy, index) => (
          <React.Fragment key={index}>
            <span className="hit-widget__label" onClick={() => setCurrentTaxonomy(taxonomy)}>
              {taxonomy.name}
            </span>
            <div className="hit-widget__bars" onClick={() => setCurrentTaxonomy(taxonomy)}>
              <div
                className="hit-widget__percentage-bar"
                style={{ width: `${taxonomy.hitPercentage}%` }}
              >
                <span className="u-visually-hidden">{parseFloat(taxonomy.hitPercentage).toFixed(1)}%</span>
              </div>
            </div>
            <span className="hit-widget__numbers" onClick={() => setCurrentTaxonomy(taxonomy)}>
              {taxonomy.hits} / {taxonomy.total}
            </span>
          </React.Fragment>
        ))}
      </div>

      <Modal
        show={currentTaxonomy !== null}
        onClose={() => setCurrentTaxonomy(null)}
        title={currentTaxonomy?.name}
        description="Número de questões com detalhamento "
        taxonomies={currentTaxonomy?.children}
      />
    </div>
  );
}

export default HitWidget;
