import React, { useState, useEffect } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { TfiMedall } from 'react-icons/tfi';
import { Link, useLocation, useParams } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import CourseCover from 'app/images/placeholders/show-course.jpg';
import ClassModuleItem from 'app/screens/LmsStudent/ClassModuleItem';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import moreMedal from 'app/images/more-medal.svg';
import TabNavBar from './TabNavBar';
import TabAbout from './TabAbout';
import TabFiles from './TabFiles';
import TabActivities from './TabActivities';
import TabBooklet from './TabBooklet';
import { useSession } from 'app/hooks/useSession';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import { navigate } from '@reach/router';
import getPermission from 'app/utils/getPermission';
import BreadCrumbs from 'app/components/BreadCrumbs';
import IconVerified from 'app/components/Icons/IconVerified';

export default function ModuleAbout(props) {
  const { uri } = props;

  const params = useParams();
  const client = useClient();
  const { session } = useSession();
  const authorityLevel = getAuthorityLevel();
  const [currentModule, setCurrentModule] = useState([]);
  const [loading, setLoading] = useState(false);

  const userId = session?.user?.id;

  const getCurrentModule = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/learning_systems/${params.moduleId}?detailed=true`);
    if (error) {
      toast.error('Falha ao carregar módulo');
    } else {
      setCurrentModule(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCurrentModule();
  }, []);

  const downloadCertificate = async () => {
    window.open(`${process.env.REACT_APP_DEV_URL}//user_certificates/download/${currentModule?.['user-certificate-hash-id']}.pdf`, '_blank');
  };

  const isModuleEnabled = currentModule?.['learning-system-requirements']?.every(item => item.percent === 100);

  const breadCrumbs = {
    title: 'Cursos e Aulas',
    nav: [
      {
        route: '/curso',
        name: 'Cursos',
        isActive: false
      },
      {
        route: authorityLevel !== 'student' ? `/curso/admin/${currentModule?.course?.id}` : `/curso/acompanhamento/${currentModule?.course?.id}`,
        name: currentModule?.course?.title,
        isActive: false
      },
      {
        route: uri,
        name: currentModule?.title,
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      {loading ? (
        <Loader />
      ) : (
        <>
          <BreadCrumbs
            data={breadCrumbs}
            actions={
              authorityLevel !== 'student' && getPermission('Gerenciar conteúdo', 'Cursos') ? (
                <div className="filter-bar__actions">
                  <Link
                    className="btn btn--wide btn--small btn--outline"
                    to={`/curso/admin/gerenciar/${currentModule?.course?.id}/${currentModule.id}/disciplinas-e-aulas/undefined`}
                  >
                    {t('button.manageContent')}
                  </Link>
                </div>
              ) : null
            }
          />

          <div className="course-about u-mb-4">
            <div
              className="show-course-banner"
              style={{
                backgroundImage: `url(${currentModule?.image?.url || CourseCover})`
              }}
            >
              <div className="show-course-banner__inner">
                <div className="show-course-banner__container">
                  <span className="show-course-banner__hat">
                  <div className="admin-course-card--flex-gap-5">
                    {currentModule?.['official-content'] && <IconVerified />}
                    {currentModule?.course?.title}</div>
                  </span>{' '}
                  
                  {authorityLevel === 'student' && !currentModule?.['learning-system-users']?.find(item => item?.['user-id'] === parseInt(userId)) && <span className="badge badge--tiny badge--warning">{t('lms.tagNew')}</span>}
                  {currentModule?.progress === 100 && <span className="badge badge--tiny badge--success">Concluído</span>}
                  <div className="show-course-banner__title">{currentModule?.title}</div>
                  {authorityLevel === 'student' && (
                    <div className="card__progress card__progress--cover u-mb-0">
                      <div className="card__progress-container">
                        <div
                          className="card__progress-bar"
                          style={{ width: `${currentModule?.progress ? parseInt(currentModule?.progress) : 0}%` }}
                        />
                      </div>
                      <div className="card__progress-text">{currentModule?.progress ? parseInt(currentModule?.progress) : 0}%</div>
                    </div>
                  )}
                  {authorityLevel === 'student' && isModuleEnabled ? (
                    <div className="show-course-banner__cta">
                      <Link
                        className="btn btn--wide btn--outline"
                        to={`/show-course/${currentModule?.id}/classroom`}
                      >
                        {currentModule.progress === 100 ? 'Visualizar conteúdo' : currentModule?.['learning-system-users']?.find(item => item?.['user-id'] === parseInt(userId)) ? t('lms.buttonContinue') : t('lms.buttonStartModule')}
                      </Link>
                      {currentModule?.['user-certificate-hash-id'] ? (
                        <button
                          className="btn btn--wide btn--outline-secondary"
                          onClick={() => {
                            downloadCertificate();
                          }}
                        >
                          {t('button.certification')}
                        </button>
                      ) : null}
                    </div>
                  ) : null}
                </div>

                {currentModule?.progress === 100 && (
                  <div className="medal">
                    <img
                      src={moreMedal}
                      alt="nova medalha"
                      className="medal__img"
                    />
                    <span className="medal__value">
                      <TfiMedall />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="u-mb-4">
            <TabNavBar
              selectedTab={props.selectedTab}
              courseId={props.courseId}
              moduleId={props.moduleId}
              questionbooksCount={currentModule?.['learning-system-question-books']?.length}
            />
          </div>

          {props.selectedTab === 'sobre' && <TabAbout currentModule={currentModule} />}
          {props.selectedTab === 'arquivos' && <TabFiles currentModule={currentModule} />}
          {props.selectedTab === 'atividades' && <TabActivities currentModule={currentModule} />}
          {props.selectedTab === 'apostilas' && <TabBooklet currentModule={currentModule} />}

          {/* <div className="show-course-sidebar">
                <div className="class-modules">
                  {(learnSystemContents || []).map((learnSystemContent, index) => {
                    const learningSystemItems = learnSystemContent['learning-system-items'] || [];

                    if (learningSystemItems.some(learningSystemItem => learningSystemItem.unrequired)) {
                      learnSystemContent['is-allowed'] = true;
                    }

                    return (
                      <ClassModuleItem
                        learnSystemContents={learnSystemContents}
                        learnigSystem={learnigSystem}
                        learningSystemItems={learningSystemItems}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        selectedContent={selectedContent}
                        setSelectedContent={setSelectedContent}
                        learnSystemContent={learnSystemContent}
                        blocked={blocked}
                        setBlocked={setBlocked}
                        active={active}
                        setActive={setActive}
                        authorityLevel={authorityLevel}
                        index={index}
                        key={index}
                      />
                    );
                  })}
                </div>
              </div> */}
        </>
      )}
    </main>
  );
}
