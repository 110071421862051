import { useContext } from 'react';
import { ProgramOfStudiesContext } from 'app/providers/ProgramOfStudiesProvider';

export const useProgramOfStudies = () => {
  const context = useContext(ProgramOfStudiesContext);

  if (!context) {
    throw new Error(
      'useProgramOfStudies must be used within a ProgramOfStudiesProvider.',
    );
  }

  return context;
};
