import React, { createContext, useEffect, useState } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { toBase64 } from 'app/utils/toBase64';

export const AuditoriumContext = createContext();

export const AuditoriumProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [auditorium, setAuditorium] = useState({});
  const [auditoriums, setAuditoriums] = useState([]);
  const [messages, setMessages] = useState([]);
  const [files, setFiles] = useState([]);
  const [chats, setChats] = useState([]);
  // const [istitutions, setIstitutions] = useState([]);
  // const [regionals, setRegionals] = useState([]);
  const [lastMessage, setLastMessage] = useState(null);
  const client = useClient();
  const [lastLesson, setLastLesson] = useState();
  const [messageIds, setMessageIds] = useState();
  const [pageState, setPageState] = useState(0)
  const [combineMessages, setcombineMessages] = useState([]);
  const [courses, setCourses] = useState([]);
  const [hasMoreLessonComments, setHasMoreLessonComments] = useState(true);

  const getAuditorium = async id => {
    setLoading(true);
    const { data, error } = await client.fetch(`auditoriums/${id}`);
    if (error) {
      toast.error(t('toast.errorSearchAuditorium'));
    } else {
      setPageState(0)
      setAuditorium(data);
    }
    setLoading(false);
  };

  const getAuditoriums = async id => {
    setLoading(true);
    const { data, error } = await client.fetch('auditoriums');
    if (error) {
      toast.error(t('toast.errorSearchAuditoriums'));
    } else {
      setAuditoriums(
        data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
    }
    setLoading(false);
  };

  const getMessages = async (id, page) => {
    if (page === pageState) return
    setPageState(page)
    setLoading(true);
    let filter = `auditoriums/${id}/auditorium_messages?page[number]=${page}&page[size]=10&order=created_at desc`;
    const { data, error } = await client.fetch(filter);

    if (error) {
      toast.error(t('toast.errorSearchMessages'));
    } else {
      if (data.length === 0) {
        setHasMoreLessonComments(false);
        return;
      }

      if (data.length < 10) {
        setHasMoreLessonComments(false);
      }
        
      setMessages(prevState => [...data, ...prevState]);
    }

    setLoading(false); 
  };

  const getFiles = async (id, date) => {
    setLoading(true);
    let filter = `auditoriums/${id}/auditorium_files?filter[auditorium_message_id]`;
    const { data, error } = await client.fetch(filter);
    if (error) {
      toast.error(t('toast.errorSearchFiles'));
    } else {
      setFiles(data);
    }
    setLoading(false);
  };

  const getCourses = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('courses/simple');
    if (error) {
      toast.error(t('toast.errorGetCourse'));
    } else {
      setCourses(data);
    }
    setLoading(false);
  };

  const createLesson = async (id, form) => {
    setLoading(true);
    const { data, error } = await client.mutate(`auditoriums/${id}`, {
      'auditorium-lessons-attributes': [form]
    });
    if (error) {
      toast.error(t('toast.errorCreateLesson'));
    } else {
      setAuditorium(data);
    }
    setLoading(false);
  };

  const createMessage = async (id, form, auditoriumId) => {
    let fileContent = null;
    let filename = null;
    if (form.file) {
      fileContent = await toBase64(form.file);
      filename = form.file.name;
    }
    if (!form.body) {
      const { data, error } = await client.mutate(`auditoriums/${auditoriumId}/auditorium_files`, {
        filename: filename,
        file: fileContent,
        'auditorium-lesson-id': lastLesson?.id
      });
      if (error) {
        toast.error(t('toast.errorCreateFile'), error);
      }
      // else {
      //   setFiles(prevFiles => [...prevFiles, data]);
      // }
    } else {
      const messageRequest = {
        body: form.body,
        'auditorium-lesson-id': lastLesson?.id
      };
      if (fileContent) {
        messageRequest['auditorium-files-attributes'] = [
          {
            filename: filename,
            file: fileContent,
            'auditorium-lesson-id': lastLesson?.id
          }
        ];
      }
      const { data, error } = await client.mutate(`auditoriums/${auditoriumId}/auditorium_messages`, messageRequest);
      if (error) {
        toast.error(t('toast.errorCreateMessage'), error);
      }
      // else {
      //   setMessages(prevMessages => [...prevMessages, data]);
      // }
    }
  };

  const createChat = async (id, form) => {
    let requestData = {
      body: form.body,
      'auditorium-lesson-id': lastLesson.id
    };
    if (messageIds) {
      requestData = {
        body: form.body,
        'auditorium-lesson-id': messageIds.lessonId,
        'auditorium-message-id': messageIds.messageId
      };
    }
    const { data, error } = await client.mutate(`auditoriums/${id}/auditorium_chat_messages`, requestData);
    if (error) {
      toast.error(t('toast.errorCreateChatMessage'));
    } else {
      // setChats([...chats, data]);
      // getChats(id);
    }
  };

  const createAuditorium = async (form, courseIds) => {
    setLoading(true);
    const { data, error } = await client.mutate(`auditoriums`, {
      ...form,
      'course-ids': courseIds
    });
    if (error) {
      toast.error(t('toast.errorCreateAuditorium'));
    } else {
      setAuditoriums(...data);
      toast.success(t('toast.successCreateAuditorium'));
    }
    setLoading(false);
  };

  const deleteAuditorium = async id => {
    setLoading(true);
    const { error } = await client.delete(`auditoriums/${id}`);
    if (error) {
      toast.error(t('toast.errorDeleteAuditorium'));
    } else {
      setAuditoriums(prevState => prevState.filter(item => item.id !== id));
      toast.success(t('toast.successDeleteAuditorium'));
    }
    setLoading(false);
  };

  const deleteMessage = async (id, message, isFile) => {
    let filter = `auditoriums/${id}/auditorium_messages/${message.id}`;
    if (isFile) {
      filter = `auditoriums/${id}/auditorium_files/${message.id}`;
    }
    const { error } = await client.delete(filter);
    if (error) {
      toast.error(t('toast.errorDeleteMessage'));
    } else {
      getMessages(id);
      getFiles(id);

      toast.success(t('toast.successDeleteMessage'));
    }
  };

  const deleteFile = async (id, file) => {
    setLoading(true);
    const { error } = await client.delete(`auditoriums/${id}/auditorium_files/${file.id}`);
    if (error) {
      toast.error(t('toast.errorDeleteFile'));
    } else {
      getFiles(id);
    }
    setLoading(false);
  };

  const updateAuditorium = async (form, id) => {
    const { error } = await client.mutate(`auditoriums/${id}`, form);
    if (error) {
      toast.error(t('toast.errorEditAuditorium'));
    } else {
      toast.success(t('toast.successUpdateAuditorium'));
    }
  };

  useEffect(() => {
    setLoading(true);
    const getLastLesson = () => {
      return auditorium?.['auditorium-lesson'];
    };
    setLastLesson(getLastLesson());
    setLoading(false);
  }, [auditorium]);

  useEffect(() => {
    const getLastMessage = messages?.[messages?.length - 1];
    setLastMessage(getLastMessage || null);
  }, [messages]);

  useEffect(() => {
    setLoading(true);
    const list = [...messages, ...files].sort((a, b) => {
      const dateA = new Date(a['created-at']);
      const dateB = new Date(b['created-at']);
      return dateA - dateB;
    });

    setcombineMessages(list);
    setLoading(false);
  }, [messages, files]);

  return (
    <AuditoriumContext.Provider
      value={{
        getAuditoriums,
        getAuditorium,
        auditoriums,
        setAuditoriums,
        auditorium,
        setAuditorium,
        getMessages,
        messages,
        getFiles,
        files,
        createLesson,
        lastMessage,
        setLastMessage,
        createMessage,
        deleteMessage,
        deleteAuditorium,
        lastLesson,
        combineMessages,
        deleteFile,
        // getChats,
        chats,
        createChat,
        setMessageIds,
        messageIds,
        // deleteChat,
        createAuditorium,
        updateAuditorium,
        loading,
        getCourses,
        courses,
        // getInstitutions,
        // istitutions,
        // regionals,
        // getRegionals,
        setMessages,
        setChats,
        setFiles,
        setCourses,
        setLoading,
        hasMoreLessonComments
      }}
    >
      {children}
    </AuditoriumContext.Provider>
  );
};
