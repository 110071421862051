import React, { useState, useEffect } from 'react';
import ExamMetricsUserListItem from './ExamMetricsUserListItem';
import { navigate, useParams } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';

import ExamMetricsKpis from './ExamMetricsKpis';
import { useClient } from 'jsonapi-react';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import Loader from 'app/components/loader';
import toast from 'react-hot-toast';

export function AdminMetrics() {
  const { t } = useTranslation();
  const client = useClient();
  const examId = useParams().id;
  const [examStudents, setExamEstudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exam, setExam] = useState([]);

  const listItem = examStudents.map(item => {
    return (
      <ExamMetricsUserListItem
        key={item.id}
        examStudent={item}
        exam={exam}
      />
    );
  });

  const getExamStudents = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`question_books_users/reports?filter[question_book_id]=${examId}`);
    if (error) {
      toast.error('Erro ao carregar dados da prova');
    } else {
      setExamEstudents(data);
    }
    setLoading(false);
  };

  const getExam = async () => {
    try {
      const { data } = await client.fetch(`question_books/${examId}`);
      setExam(data);
    } catch {
      toast.error(t('toast.errorGetExams'));
    }
  };

  useEffect(() => {
    getExam();
    getExamStudents();
  }, []);

  let tableHeader = (
    <tr className="round-dg__row">
      <th
        class="round-dg__cell-header"
        style={{ width: '300px' }}
      >
        {t('exams.thParticipant')}
      </th>
      {exam?.visibility !== 'open' && (
        <th
          class="round-dg__cell-header"
          style={{ width: '140px' }}
        >
          {t('exams.thResults')}
        </th>
      )}
      <th
        class="round-dg__cell-header"
        style={{ width: '150px' }}
      >
        {t('exams.thResolutionDate')}
      </th>
      <th
        class="round-dg__cell-header"
        style={{ width: '100px' }}
      >
        {t('exams.thScore')}
      </th>
    </tr>
  );

  return (
    <>
      <div className="filter-bar">
        <button
          onClick={() => navigate(-1)}
          className="filter-bar__back"
        >
          <span className="card__icon-wrapper">
            <FiChevronLeft className="card__icon" />
          </span>

          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{exam?.title}</span>
          </span>
        </button>
      </div>

      {exam && !loading && examStudents.length > 0 && (
        <ExamMetricsKpis
          currentExam={exam}
          examStudents={examStudents}
        />
      )}

      {loading && <Loader />}

      {examStudents.length === 0 && !loading && (
        <EmptyState
          type="data"
          title={t('emptyState.noStudentAnswered')}
          text={t('emptyState.noAnyResponses')}
        />
      )}

      {examStudents.length > 0 && !loading && (
        <div className="widget-wrapper">
          <div className="round-dg-wrapper">
            <table className="round-dg round-dg--light">
              <thead class="round-dg__header">{tableHeader}</thead>

              <tbody className="round-dg__body">{listItem}</tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
