import React, { useEffect, useState } from 'react';
import Folder from './Folder';

export default function TreeItem(props) {
  const { setCurrentFolder, folder, onClick, isOpenFolder = null, idOpenedSubFolder, setIdOpenedSubFolder, setIdOpenedFolder, currentFolder } = props;

  const [isExpanded, setIsExpanded] = useState(null);
  const [uniqueShifts, setUniqueShifts] = useState([]);

  useEffect(() => {
    if (folder?.['class-plans']) {
      const uniqueShifts = folder['class-plans'].reduce((result, item) => {
       if (!result.some(i => i.shift === item.shift)) {
        result.push(item)
       } 
       return result
      },[]).map(item => item.shift) 
         
      setUniqueShifts(uniqueShifts);
    }
  }, [folder]);

  const handleClickSubFolder = shift => {
    const filteredFolders = folder?.['class-plans'].filter(item => item.shift === shift);
    const subId = `${shift}-${folder.id}`;

    setIdOpenedSubFolder(subId);
    setCurrentFolder(filteredFolders);
    setIdOpenedFolder(null);
  };

  return (
    <div className="finder__tree-item">
      <Folder
        name={folder.title}
        subFolders={folder?.['class-plans']}
        isExpanded={isExpanded}
        folderId={folder.id}
        setIsExpanded={setIsExpanded}
        setCurrentFolder={setCurrentFolder}
        setIdOpenedSubFolder={setIdOpenedSubFolder}
        isOpenFolder={isOpenFolder}
        onClick={onClick}
      />

      {uniqueShifts?.map(item => (
        <div
          key={item}
          className="finder__subfolder fadeIn"
          style={{ display: isExpanded ? 'block' : 'none' }}
        >
          <Folder
            name={item}
            subFolders={[]}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            isSubFolder={true}
            isOpenFolder={idOpenedSubFolder === `${item}-${folder.id}`}
            onClick={() => handleClickSubFolder(item)}
          />
        </div>
      ))}
    </div>
  );
}
