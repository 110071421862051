import React, { useState, useEffect, useContext } from 'react';
import { navigate } from '@reach/router';

const DropdownContext = React.createContext();

function Dropdown(props) {
  const { className } = props;

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    try {
      document.addEventListener('mouseup', event => {
        const target = event.target;

        if (isOpen) {
          !target.matches('.c-dropdown__content *') && setIsOpen(!isOpen);
          target.matches('.c-dropdown__content *') &&
            setTimeout(() => setIsOpen(!isOpen), 100);
        }
      });
      document.addEventListener('keyup', event => {
        if (event.key === 'Escape' && isOpen) setIsOpen(!isOpen);
      });
    } catch (error) {
      console.error(error);
    }
  }, [isOpen]);

  function handleOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <DropdownContext.Provider value={{ isOpen, handleOpen }}>
      <div
        className={`c-dropdown ${className ? className : ''}`}
        onClick={event => event.stopPropagation()}
      >
        {props.children}
      </div>
    </DropdownContext.Provider>
  );
}

export default Dropdown;

export function DropdownToggle(props) {
  const { handleOpen } = useContext(DropdownContext);

  return (
    <button
      type="button"
      onClick={handleOpen}
      className="c-dropdown__toggle"
      {...props}
    >
      {props.children}
    </button>
  );
}
Dropdown.Toggle = DropdownToggle;

export function DropdownContent(props) {
  const { isOpen } = useContext(DropdownContext);

  return isOpen && <ul className="c-dropdown__content">{props.children}</ul>;
}
Dropdown.Content = DropdownContent;

export function DropdownItem(props) {
  return props.to ? (
    <li
      className="c-dropdown__item"
      onClick={() => navigate(props.to)}
      {...props}
    >
      {props.children}
    </li>
  ) : (
    <li className="c-dropdown__item" {...props}>
      {props.children}
    </li>
  );
}
Dropdown.Item = DropdownItem;
