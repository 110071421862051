import React, { useState } from 'react';
import Modal from 'app/components/Modal';
import UserInfo from 'app/components/UserInfo';
import { useEffect } from 'react';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';
import EmptyState from 'app/components/EmptyState';

export default function ManagersModal(props) {
  const { t } = useTranslation();
  const { show, onClose, selectedManagers, removeManager, loading, addManager, managers } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredManagers, setFilteredManagers] = useState([]);

  useEffect(() => {
    let newManagers = managers;
    if (searchTerm !== '') {
      newManagers = newManagers.filter(m => m.name != null && m.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
    }
    setFilteredManagers(newManagers);
  }, [searchTerm, managers]);

  return (
    <Modal
      show={show}
      onClose={() => {
        onClose()
        setSearchTerm('')
        }
      }
      contentClassName="modal__content--md"
    >
      <div className="modal__simple-header">
        <h3 className="modal__simple-title modal__simple-title--with-btn">{t('projects.addManager')}</h3>
      </div>

      <div className="form__row">
        <label
          htmlFor="managersSearch"
          className="form__label"
        >
          {t('textsCommon.research')}
        </label>

        <input
          aria-label="Buscar gestor"
          className="form__control form__control--search-with-icon"
          style={{ width: 'auto' }}
          placeholder={t('projects.placeholderManager')}
          type="search"
          name="managersSearch"
          id="managersSearch"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>

      <table
        className="dg-widget"
        style={{ tableLayout: 'auto' }}
      >
        <tbody
          className="dg-widget__body"
          style={{ maxHeight: '73vh' }}
        >
          {loading && <Loader />}
          {filteredManagers.length <= 0 && <EmptyState type="data" text={t("emptyState.registerTitle")} />}
          {!loading &&
            filteredManagers.map((manager, index) => {
              const selectedItem = selectedManagers?.find(m => m.id === manager.id);
              return (
                <tr
                  className="dg-widget__row"
                  key={index}
                >
                  <th
                    className="dg-widget__cell-header"
                    scope="row"
                  >
                    <UserInfo user={manager} />
                  </th>
                  <td className="dg-widget__cell">{manager.email}</td>
                  <td
                    className="dg-widget__cell"
                    style={{ width: '140px' }}
                  >
                    {selectedItem ? (
                      <button
                        className="btn btn--outline btn--full active"
                        onClick={() => {
                          removeManager(manager);
                        }}
                      >
                        {t('button.remove')}
                      </button>
                    ) : (
                      <button
                        className="btn btn--outline btn--full"
                        onClick={() => {
                          addManager(manager);
                        }}
                      >
                       {t('button.add')}
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Modal>
  );
}
