import { navigate } from '@reach/router';
import EmptyState from 'app/components/EmptyState';
import UserInfo from 'app/components/UserInfo';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { FiChevronLeft, FiInfo } from 'react-icons/fi';

export default function ViewImports(props) {
  const { importId } = props;
  const client = useClient();
  const [spreadsheetImport, setSpreadsheetImport] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSpredsheetImport = async () => {
    const { data, error } = await client.fetch(`spreadsheet_imports/${importId}`);
    if (error) {
      toast.error('Erro ao buscar informações da importação');
    } else {
      setSpreadsheetImport(data);
      setLoading(false);
    }
  };

  const getImportError = (error) => {
    switch (error) {
      case 'Incorrect or missing values':
        return t('toast.errorIncorrectOrMissingValues')
      case 'Incorrect columns':
        return t('toast.errorIncorrectColumns')
      case 'Empty spreadsheet':
        return t('toast.errorEmptySpreadsheet')
      case 'Course does not have this class':
        return t('toast.errorCourseDoesNotHaveThisClass')
      case 'Course does not exists':
        return t('toast.errorCourseDoesNotExist')
      case 'Institution does not have this class':
        return t('toast.errorInstitutionDoesNotHaveThisClass')
      case 'Institution does not exists':
        return t('toast.errorInstitutionDoesNotExist')
      case 'Register or grouped user fail':
        return t('toast.errorRegisterOrGroupedUserFail')
      default:
        break;
    }
  }

  useEffect(() => {
    getSpredsheetImport();
  }, [importId]);

  return (
    <main className="main-content main-content--block">
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <button
            className="filter-bar__back"
            onClick={() => navigate(-1)}
          >
            <span className="card__icon-wrapper">
              <FiChevronLeft className="card__icon" />
            </span>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{spreadsheetImport?.filename}</span>
            </span>
          </button>
        </div>
      </div>
      {loading && <Loader />}
      {!loading && spreadsheetImport.users.length === 0 && spreadsheetImport.status === 'import_success' && <EmptyState type="data" />}
      {!loading && spreadsheetImport.status === 'import_failed' && (
        <EmptyState type="disapproved" title='Erro ao importar planilha:' text={`${getImportError(spreadsheetImport.failure)}`}/>
      )}
      {!loading && spreadsheetImport.status === 'import_created' && (
        <div className='card card--simple' style={{width: 'fit-content', margin: '0 auto'}}>
        <span>
          Processamento da planilha em andamento...
        </span>
      </div>
      )}
      {!loading && spreadsheetImport.users.length > 0 && (
        <table className="round-dg round-dg--light">
          <thead className="round-dg__header">
            <tr className="round-dg__row">
              <th className="round-dg__cell-header">{t('textsCommon.name')}</th>
              <th className="round-dg__cell-header">{t('profile.labelPhone')}</th>
              <th className="round-dg__cell-header">{t('textsCommon.class')}</th>
              <th
                className="round-dg__cell-header"
                style={{ width: '160px' }}
              >
                {t('journey.thLastAccess')}
              </th>
            </tr>
          </thead>

          <tbody className="round-dg__body">
            {spreadsheetImport?.users?.map((user, index) => (
              <tr
                className="round-dg__row"
                key={index}
              >
                <th className="round-dg__cell-header round-dg__cell-header--row">
                  <UserInfo
                    user={user}
                    viewEmail
                  />
                </th>
                <td className="round-dg__cell">{user.phone}</td>
                <td className="round-dg__cell">{spreadsheetImport?.classroom?.title}</td>
                {user['last_access'] !== null && <td className="round-dg__cell">{moment(user['last_access']).format('DD/MM/YYYY')}</td>}
                {user['last_access'] === null && (
                  <td className="round-dg__cell">
                    <span className="badge badge--tiny badge--warning">{t('participants.tagRegistrationSent')}</span>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </main>
  );
}
