import React, { useEffect, useState } from 'react';
import EmptyState from 'app/components/EmptyState';
import DiagnosticTestDataGrid from './DiagnosticTestDataGrid';
import { useClient } from 'jsonapi-react';

export default function DiagnosticTest() {
  const [diagnosticData, setDiagnosticData] = useState([]);
  const client = useClient();

  const getDiagnosticData = async () => {
    const response = await client.fetch('study_plan_reports/question_books_list');
    return response.data;
  }

  useEffect(() => {
    getDiagnosticData().then((data) => {
      if (data.question_books_list) setDiagnosticData(data.question_books_list);
    });
  }, []);


  return (
    <div className="panorama-card">
      <div className="panorama-card__header">
        <h3 className="panorama-card__title">Rendimento em prova diagnóstica</h3>
      </div>

      {diagnosticData.length === 0 && (
        <div className="panorama-card__body panorama-card__body--empty-state">
          <EmptyState
            type="data"
            title="Você ainda não respondeu uma prova diagnóstica"
            text=" "
            bgless
          />
        </div>
      )}

      {diagnosticData.length > 0 && (
        <div className="panorama-card__body">
          <DiagnosticTestDataGrid data={diagnosticData} />
        </div>
      )}
    </div>
  );
}
