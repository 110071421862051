import { t } from 'i18next';
import { useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import { useSession } from 'app/hooks/useSession';
import moment from 'moment';

const NameStep = props => {
  const { values, setFieldValue, createModuleForm, currentCourse, currentModule } = props;

  const courseDeadline = new Date(currentCourse?.['finished-at']).toLocaleDateString();
  const isAdmin = checkIncludeProfiles({ only: ['admin'] });

  const currentUser = useSession()?.session?.user;
  const hasModulePermission = () => {
    if (currentModule && (currentUser.id === currentModule?.user?.id || currentUser?.profileNames.includes('Administrador'))) {
      return true;
    } else if (!currentModule) {
      return true;
    }
  };

  return (
    <div className="fadeIn">
      <div className="form__row">
        <label
          className="form__label"
          htmlFor="title"
        >
          * {t('lms.moduleName')}
        </label>
        <input
          className="form__control"
          placeholder={t('lms.placeholderModuleName')}
          id="title"
          name="title"
          type="text"
          value={values.title}
          onChange={e => setFieldValue('title', e.target.value)}
        />
        {createModuleForm.touched.title && createModuleForm.errors.title && <span style={{ color: 'red' }}>{createModuleForm.errors.title}</span>}
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="shortDescription"
        >
          * {t('lms.shortDescription')}
        </label>
        <input
          className="form__control"
          placeholder={t('lms.placeholderShortDescription')}
          id="shortDescription"
          name="shortDescription"
          type="text"
          value={values.shortDescription}
          onChange={e => setFieldValue('shortDescription', e.target.value)}
        />
        {createModuleForm.touched.shortDescription && createModuleForm.errors.shortDescription && <span style={{ color: 'red' }}>{createModuleForm.errors.shortDescription}</span>}
      </div>
      <div className="form__row">
        <label
          className="form__label"
          htmlFor="description"
        >
          * {t('lms.aboutModule')}
        </label>

        <TextareaAutosize
          placeholder={t('lms.placeholderAboutModule')}
          id="description"
          name="description"
          value={values.description}
          onChange={e => setFieldValue('description', e.target.value)}
        />
        {createModuleForm.touched.description && createModuleForm.errors.description && <span style={{ color: 'red' }}>{createModuleForm.errors.description}</span>}
      </div>

      <div className="form__row form__row--columns">
        <div className="form__col">
          <label
            className="form__label"
            htmlFor="workload"
          >
            {t('lms.workload')}
          </label>

          <input
            className="form__control"
            placeholder={t('lms.typeAnumber')}
            id="workload"
            name="workload"
            type="text"
            maxLength={5}
            value={values.workload}
            onChange={e => setFieldValue('workload', e.target.value.replace(/[^\d]/g, ''))}
          />
        </div>

        <div className="form__col">
          <label
            className="form__label"
            htmlFor="order"
          >
            ** Ordem do módulo
          </label>

          <input
            className="form__control"
            placeholder={t('lms.typeAnumber')}
            id="order"
            name="order"
            type="text"
            value={values.order}
            onChange={e => setFieldValue('order', e.target.value.replace(/[^\d]/g, ''))}
          />
        </div>
      </div>

      <div className="form__row form__row--columns">
        <div className="form__col">
          <label
            className="form__label"
            htmlFor="finished-at"
          >
            *Selecione uma data para a conclusão do módulo
          </label>
          <input
            className="form__control"
            placeholder={t('lms.typeAnumber')}
            id="finished-at"
            name="finished-at"
            type="date"
            value={values?.['finished-at'] ? moment(values?.['finished-at']).format('YYYY-MM-DD') : ''}
            onChange={e => setFieldValue('finished-at', e.target.value)}
          />
          {createModuleForm.errors['finished-at'] && <span style={{ color: 'red' }}>{createModuleForm.errors['finished-at']}</span>}
        </div>

        <div className="form__col" />
      </div>

      {hasModulePermission() && (
        <div className="form__row">
          <div className="form__check form__switch">
            <input
              className="form__check-input"
              id="authorialContent"
              name="authorialContent"
              type="checkbox"
              checked={values?.['authored-by-user']}
              onClick={() => {
                setFieldValue('authored-by-user', !values?.['authored-by-user']);
              }}
            />
            <label htmlFor="authorialContent">
              Este módulo é de minha autoria e <strong>somente eu posso editá-lo.</strong>
            </label>
          </div>

          {isAdmin && (
            <div className="form__check form__switch">
              <input
                className="form__check-input"
                id="officialContent"
                name="officialContent"
                type="checkbox"
                checked={values?.['official-content']}
                onClick={() => {
                  setFieldValue('official-content', !values?.['official-content']);
                }}
              />
              <label htmlFor="officialContent">
                Este módulo é <strong>oficial;</strong> apenas a equipe administrativa têm permissão para editá-lo.
              </label>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NameStep;
