import React, { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft } from 'react-icons/fi';
import { navigate } from '@reach/router';
import moment from 'moment';
import EmptyState from 'app/components/EmptyState';
import ReportFilter from 'app/components/ReportFilter';
import BoxWidget from './BoxWidget';
import { WarningModal } from 'app/components/Modal/WarningModal';
import Loader from 'app/components/loader';

export default function AccessFunnelReport() {
  const client = useClient();
  const { t } = useTranslation();

  const [currentFilters, setCurrentFilters] = useState([]);
  const [studentsData, setStudentsData] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [regionals, setRegionals] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(false);

  //filtros
  const [selectedRegionals, setSelectedRegionals] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [filterTypes, setFilterTypes] = useState([]);

  const fetchCourses = async (url = '/courses/simple') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('toast.errorGetCourse'));
    } else {
      setCourses(data.map(item => ({ value: item.id, label: item.title })));
    }
  };

  const fetchModules = async (url = '/learning_systems') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Falha ao carregar módulos');
    } else {
      setModules(data.map(item => ({ value: item.id, label: item.title })));
    }
  };

  const fetchInstitutions = async (url = '/institutions') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Falha ao carregar instituições');
    } else {
      setInstitutions(data.map(item => ({ value: item.id, label: item.name })));
    }
  };
  const fetchRegionals = async (url = '/regionals') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Falha ao carregar regionais');
    } else {
      setRegionals(data.map(item => ({ value: item.id, label: item.name })));
    }
  };

  useEffect(() => {
    fetchCourses();
    fetchModules();
    fetchInstitutions();
    fetchRegionals();
  }, []);

  const data = useMemo(() => {
    //atualiza os seletores de acordo com os filtros aplicados
    return [
      {
        placeholder: 'Digite o nome da Regional',
        name: 'Regional',
        options: regionals
      },
      {
        placeholder: 'Digite o nome da instituição',
        name: 'Instituição',
        options: institutions
      },
      {
        placeholder: 'Digite o nome do curso',
        name: 'Curso',
        options: courses
      },
      {
        placeholder: 'Digite o nome do módulo',
        name: 'Módulo',
        options: modules
      }
    ];
  }, [institutions, regionals, courses, modules]);

  const getStudentsAccess = async url => {
    setLoading(true);
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Falha ao carregar dados');
    } else {
      setStudentsData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    //traz os dados do componente do funil
    const filterTypes = currentFilters.map(item => item.type);
    const queryParams = [];
    setFilterTypes(filterTypes);
    if (currentFilters.length > 0) {
      if (filterTypes.includes('regional')) {
        queryParams.push(`regional_id=${selectedRegionals.map(item => item.value)}`);
      }
      if (filterTypes.includes('institution')) {
        queryParams.push(`institution_id=${selectedInstitutions.map(item => item.value)}`);
      }
      if (filterTypes.includes('course')) {
        queryParams.push(`course_id=${selectedCourses.map(item => item.value)}`);
      }
      if (filterTypes.includes('module')) {
        queryParams.push(`learning_system_id=${selectedModules.map(item => item.value)}`);
      }
      getStudentsAccess(`/reports/funnels?${queryParams.join('&')}`);
    }
  }, [currentFilters]);

  useEffect(() => {
    setCurrentFilters([...selectedRegionals, ...selectedModules, ...selectedInstitutions, ...selectedCourses]);
  }, [selectedRegionals, selectedModules, selectedInstitutions, selectedCourses]);

  return (
    <div className="report">
      <header className="report__header">
        <h1 className="report__title">
          <button
            className="card__icon-wrapper"
            aria-label="Voltar"
            title="Voltar"
            onClick={() => navigate(-1)}
          >
            <FiChevronLeft className="card__icon" />
          </button>
          Funil de acesso
        </h1>
        <p className="report__description">Funil indica a movimentação dos alunos em cursos e módulos. No funil também podemos ver índices de inatividade, enturmação e inscrição.</p>
      </header>

      <div className="report-tab">
        <button className="report-tab__link report-tab__link--is-active">Estudantes</button>
      </div>

      <main className="report__body">
        <div className="report__filter report__filter--space report__filter--multi-select">
          <ReportFilter
            setSelectedRegionals={setSelectedRegionals}
            selectedRegionals={selectedRegionals}
            setSelectedCourses={setSelectedCourses}
            selectedCourses={selectedCourses}
            setSelectedInstitutions={setSelectedInstitutions}
            selectedInstitutions={selectedInstitutions}
            setSelectedModules={setSelectedModules}
            selectedModules={selectedModules}
            fetchModules={fetchModules}
            fetchInstitutions={fetchInstitutions}
            currentFilters={currentFilters}
            setCurrentFilters={setCurrentFilters}
            data={data}
          />

          {/* <div className="report-form__datepicker">
            <input
              className="report-form__control"
              id="start-date"
              name="start-date"
              type="date"
              value={moment(filters.values['start-date']).format('YYYY-MM-DD')}
              onChange={e => filters.setFieldValue('start-date', e.target.value)}
              title="Data de início"
              aria-label="Data de início"
            />
            <input
              className="report-form__control"
              id="start-date"
              name="start-date"
              type="date"
              value={moment(filters.values['end-date']).format('YYYY-MM-DD')}
              onChange={e => filters.setFieldValue('end-date', e.target.value)}
              min={moment(filters.values['start-date']).format('YYYY-MM-DD')}
              title="Data de fim"
              aria-label="Data de fim"
            />
          </div> */}
        </div>

        <div className="report-widget">
          <div className="report-widget__header">
            <h3 className="report-widget__title">Qualidade de acesso dos estudantes</h3>
          </div>

          <div className="report-widget__body">
            {currentFilters.length === 0 && (
              <EmptyState
                type="add"
                title="Selecione um filtro para começar a ver os dados"
                text=" "
                bgless
              />
            )}

            {currentFilters.length > 0 && (
              <>
                {loading && <Loader />}
                {studentsData && !loading ?  (
                  <BoxWidget
                    selectedRegionals={selectedRegionals}
                    selectedInstitutions={selectedInstitutions}
                    selectedCourses={selectedCourses}
                    selectedModules={selectedModules}
                    filterTypes={filterTypes}
                    studentsData={studentsData}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </main>

      <WarningModal
        show={showWarning}
        titleFirstButton="Não"
        titleSecondButton="Sim"
        onClose={() => setShowWarning(false)}
        title={'Você está fazendo uma requisição que precisa processar muitos dados. Deseja continuar?'}
        description={'Esse processamento dura em média 2 minutos'}
        onSubmitFirstButton={() => setShowWarning(false)}
        onSubmitSecondButton={() => console.log('submit')}
      />
    </div>
  );
}
