import React, { useState } from 'react';
import Modal from 'app/components/Modal';
import { useClient } from 'jsonapi-react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next';

export default function HelpForm() {
  const { t } = useTranslation();
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [isTouchedTitle, setIsTouchedTitle] = useState(true);
  const [isTouchedBody, setIsTouchedBody] = useState(true);

  const resetForm = () => {
    createSuportContactForm.setValues({
      title: '',
      body: ''
    });
    setIsTouchedTitle(false);
    setIsTouchedBody(false);
  }

  const createSuportContact = async (values) => {
    const { error } = await client.mutate('create_suport_contact', values);

    if (error) {
      toast.error(t("toast.error"));
    } else {
      toast.success(t("toast.successAsk"));
      resetForm();
    }
  }

  const createSuportContactForm = useFormik({
    initialValues: {
      title: '',
      body: ''
    },
    validationSchema: yup.object({
      title: yup.string().required(t("warning.requiredField")),
      body: yup.string().required(t("warning.requiredField")),
    }),
    onSubmit: createSuportContact
  });

  return (
    <form
      action="#"
      method="post"
      className="form"
      onSubmit={createSuportContactForm.handleSubmit}
    >
      <h3 className="form__title">
        {t("helpFooter.asksSend")}
      </h3>

      <div className="form__row">
        <label className="form__label" htmlFor="title">
          {t("helpFooter.eventTitle")}
        </label>
        <input
          className="form__control"
          id="title"
          name="title"
          type="text"
          placeholder={t("helpFooter.eventPlaceholder")}
          value={createSuportContactForm.values.title}
          onChange={e => {
            createSuportContactForm.setFieldValue('title', e.target.value);
            setIsTouchedTitle(true);
          }}
          defaultValue={''}
        />
        {createSuportContactForm.touched.title && createSuportContactForm.errors.title && isTouchedTitle &&
          <span style={{ color: 'red' }}>
            {createSuportContactForm.errors.title}
          </span>
        }
      </div>

      <div className="form__row">
        <label className="form__label" htmlFor="body">
          {t("helpFooter.ask")}
        </label>
        <textarea
          id="body"
          name="body"
          value={createSuportContactForm.values.body}
          onChange={e => {
            createSuportContactForm.setFieldValue('body', e.target.value);
            setIsTouchedBody(true);
          }}
          defaultValue={''}
          placeholder={t("helpFooter.askPlaceholder")}
        />
        {createSuportContactForm.touched.body && createSuportContactForm.errors.body && isTouchedBody &&
          <span style={{ color: 'red' }}>
            {createSuportContactForm.errors.body}
          </span>
        }
      </div>

      <button type="submit" className="btn btn--primary btn--wide">
      {t("button.submit")}
      </button>

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <h3 className="modal__dialog-title">{t("helpFooter.emailSended")}</h3>
        <p className="u-text-center">
          {t("helpFooter.emailReceived")}
        </p>
      </Modal>
    </form>
  );
}
