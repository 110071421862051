import React, { useState, useContext } from 'react';
import Modal from 'app/components/Modal';
import { FiCheckCircle } from 'react-icons/fi';
import SubmittedAnswersModal from './SubmittedAnswersModal';
import { QuestionBooksContext } from 'app/providers/QuestionBooksProvider';
import { useTranslation } from 'react-i18next';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

export default function EndExamModal(props) {
  const { questionBooksUser, show, onClose, questionBook, questions, countAnswered, countCorrectAnswers, finishQuestionBook, elapsedTime, formattedDate } = props;
  const { t } = useTranslation();
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const { setCorrectAnswers } = useContext(QuestionBooksContext);

  const handleFinalizeExam = async () => {
    const { error } = await client.mutate(['question_books_users', questionBooksUser?.id], { done: true });
    if (error) {
      toast.error(response.error.title);
    } else {
      setShowModal(true);
    }
  };

  const handleSaveAnswers = async () => {
    setCorrectAnswers(countCorrectAnswers());
  };
  function convertToMinutes(elapsedTime) {
    return Math.floor(elapsedTime / 60);
  }

  const elapsedTimeInMinutes = Math.floor(elapsedTime / 60);

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <h3 className="modal__simple-title">{t('exams.submitExam')}</h3>

      <p className="modal__subtitle">{t('exams.subtitleSubmitExam')}</p>

      <p
        className="card__count u-mb-3"
        style={{ justifyContent: 'left', paddingLeft: '16px' }}
      >
        <FiCheckCircle className="card__icon" />{' '}
        <span>
          <strong>
            {countAnswered()}/{questions.length}
          </strong>{' '}
          - {t('exams.submitedQuestions')}
        </span>
      </p>

      <div className="card__columns card__columns--mobile">
        <button
          className="btn btn--wide btn--outline"
          onClick={onClose}
        >
          {t('button.notFinish')}
        </button>

        <button
          className="btn btn--wide btn--primary"
          onClick={handleFinalizeExam}
        >
          {t('button.submitAndFinish')}
        </button>
      </div>

      <SubmittedAnswersModal
        formattedDate={formattedDate}
        elapsedTimeInMinutes={elapsedTimeInMinutes}
        countAnswered={countAnswered}
        show={showModal}
        onSave={handleSaveAnswers}
        questionBook={questionBook}
        questions={questions}
        finishQuestionBook={finishQuestionBook}
      />
    </Modal>
  );
}
