import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import TextareaAutosize from 'react-autosize-textarea';
import Modal from 'app/components/Modal';
import promiseRequest from 'app/utils/promiseToast';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export default function LiveClassForm(props) {
  const { show, onClose, editingLiveClass, currentLive, weekDays, setWeekDays } = props;

  const { t } = useTranslation();
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [courses, setCourses] = useState([]);
  const { promiseToast, isSubmitting } = promiseRequest();

  const createLive = async () => {
    const auxValues = { ...form.values };
    auxValues['course-ids'] = auxValues['course-ids'].map(item => item.value);
    auxValues['initial-date'] = auxValues['initial-date'] + ' ' + auxValues['start-hour'] + ':' + auxValues['start-minute'];
    auxValues['end-date'] = form.values['initial-date'] + ' ' + auxValues['end-hour'] + ':' + auxValues['end-minute'];

    delete auxValues['start-hour'];
    delete auxValues['start-minute'];
    delete auxValues['end-hour'];
    delete auxValues['end-minute'];

    if (editingLiveClass) {
      promiseToast({
        url: `live_classrooms/${currentLive.id}`,
        request: auxValues,
        errorText: t('toast.errorEditLive'),
        successText: t('toast.successEditLive')
      }).then(data =>
        setWeekDays(
          weekDays.map(item => {
            if (item.id !== data.id) {
              return item;
            }
            if (item.id === data.id) {
              return data;
            }
          })
        )
      );
    } else {
      promiseToast({
        url: `live_classrooms`,
        request: auxValues,
        successText: t('toast.successCreateLive'),
        errorText: t('toast.errorCreateLive')
      }).then(data => {
        setWeekDays([...weekDays, data].sort((a, b) => new Date(a['initial-date']) - new Date(b['initial-date'])));
      });
    }
  };

  const schema = Yup.object({
    title: Yup.string().required(t('warning.requiredField')),
    url: Yup.string().required(t('warning.requiredField')),
    'course-ids': Yup.array().of(Yup.object()).min(1, t('warning.minSubject')),
    'initial-date': Yup.string().required(t('warning.requiredField')),
    'start-hour': Yup.string().required(t('warning.requiredField')),
    'start-minute': Yup.string().required(t('warning.requiredField')),
    'end-hour': Yup.string().required(t('warning.requiredField')),
    'end-minute': Yup.string().required(t('warning.requiredField'))
  });

  const form = useFormik({
    initialValues: {
      title: '',
      about: '',
      url: '',
      'end-hour': '',
      'end-minute': '',
      'start-hour': '',
      'start-minute': '',
      'initial-date': '',
      'course-ids': []
    },
    validationSchema: schema,
    onSubmit: () => createLive()
  });

  useEffect(() => {
    if (currentLive && Object.keys(currentLive).length > 0) {
      form.setValues({
        title: currentLive?.title,
        about: currentLive?.about,
        url: currentLive?.url,
        'start-hour': new Date(currentLive?.['initial-date']).getHours().toString().padStart(2, '0'),
        'start-minute': new Date(currentLive?.['initial-date']).getMinutes().toString().padStart(2, '0'),
        'end-hour': new Date(currentLive?.['end-date']).getHours().toString().padStart(2, '0'),
        'end-minute': new Date(currentLive?.['end-date']).getMinutes().toString().padStart(2, '0'),
        'initial-date': new Date(currentLive?.['initial-date']).toISOString().split('T')[0],
        'course-ids': currentLive?.courses.map(course => ({ value: course.id, label: course.name }))
      });
    }

    if (!show) {
      form.resetForm();
    }
  }, [currentLive, show]);

  const client = useClient();
  const animatedComponents = makeAnimated();

  const getCourses = async () => {
    setLoadingCourses(true);
    try {
      const { data } = await client.fetch('courses/simple');
      setCourses(data);
    } catch {
      toast.error(t('toast.errorGetCourse'));
    }
    setLoadingCourses(false);
  };

  const parsedCourses = courses?.map(item => {
    return { value: item.id, label: item.title };
  });

  useEffect(() => {
    getCourses();
  }, []);

  let hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(`${String(i).padStart(2, '0')}`);
  }

  let minutes = [];
  for (let i = 0; i < 60; i++) {
    minutes.push(String(i).padStart(2, '0'));
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
    >
      <form
        action="post"
        className="form"
        onSubmit={form.handleSubmit}
      >
        <h2 className="modal__simple-title">{editingLiveClass ? 'Editar aula ao vivo' : 'Nova aula ao vivo'}</h2>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="title"
          >
            *{t('liveClass.titleClass')}
          </label>
          <input
            className="form__control"
            id="title"
            name="title"
            type="text"
            placeholder={t('liveClass.placeholderTitle')}
            onChange={form.handleChange}
            defaultValue={form.values.title}
          />
          {form.touched.title && form.errors.title && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
        </div>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="url"
          >
            *URL
          </label>
          <input
            className="form__control"
            id="url"
            name="url"
            type="url"
            placeholder={t('liveClass.placeholderUrl')}
            onChange={form.handleChange}
            defaultValue={form.values.url}
          />
          {form.touched.url && form.errors.url && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
        </div>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="course-ids"
          >
            *{t('liveClass.selectCourse')}
          </label>
          <Select
            classNamePrefix="react-multi-select"
            openMenuOnFocus
            noOptionsMessage={() => 'Sem opções'}
            isDisabled={loadingCourses}
            value={form?.values?.['course-ids']}
            placeholder={loadingCourses ? t('loader.Loading') : t('liveClass.selectTheCourse')}
            onChange={item => form.setFieldValue('course-ids', item)}
            components={{
              animatedComponents,
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
            closeMenuOnSelect={false}
            isSearchable={true}
            options={parsedCourses}
            isMulti
          />
          {form.touched?.['course-ids'] && form.errors?.['course-ids'] && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
        </div>

        <div className="form__row form__row--columns">
          <div className="form__col">
            <label
              className="form__label"
              htmlFor="initial-date"
            >
              *{t('liveClass.date')}
            </label>
            <input
              className="form__control"
              id="initial-date"
              name="initial-date"
              type="date"
              onChange={form.handleChange}
              defaultValue={form.values['initial-date']}
            />
            {form.touched['initial-date'] && form.errors['initial-date'] && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
          </div>
        </div>

        <div className="form_row" style={{width: '250px'}}>
          <div className='form__columns'>

          <div className="form__col">
            <legend className="form__label">*Horário de início</legend>

            <div className="form__columns">
              <div className="form__col">
                <select
                  className="form__select"
                  name="start-hour"
                  id="start-hour"
                  aria-label="Hora início"
                  onChange={form.handleChange}
                  value={form.values['start-hour']}
                >
                  <option value="">{'Horas'}</option>
                  {hours.map(i => (
                    <option
                      key={i}
                      value={i}
                    >
                      {i}
                    </option>
                  ))}
                </select>
                {form.touched['start-hour'] && form.errors['start-hour'] && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
              </div>

              <div className="form__col">
                <select
                  className="form__select"
                  name="start-minute"
                  id="start-minute"
                  aria-label="Hora fim"
                  onChange={form.handleChange}
                  value={form.values['start-minute']}
                >
                  <option value="">{'Minutos'}</option>
                  {minutes.map(i => (
                    <option
                      key={i}
                      value={i}
                    >
                      {i}
                    </option>
                  ))}
                </select>
                {form.touched['start-minute'] && form.errors['start-minute'] && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
              </div>
            </div>
          </div>    

     
  

          </div>
        </div>

        <div className='form__row' style={{width: '250px'}}>
        <div className="form__col">
            <legend className="form__label">*Horário de término</legend>

            <div className="form__columns">
              <div className="form__col">
                <select
                  className="form__select"
                  name="end-hour"
                  id="end-hour"
                  aria-label="Hora início"
                  onChange={form.handleChange}
                  value={form.values['end-hour']}
                >
                  <option value="">{'Horas'}</option>
                  {hours.map(i => (
                    <option
                      key={i}
                      value={i}
                    >
                      {i}
                    </option>
                  ))}
                </select>
                {form.touched['end-hour'] && form.errors['end-hour'] && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
              </div>

              <div className="form__col">
                <select
                  className="form__select"
                  name="end-minute"
                  id="end-minute"
                  aria-label="Hora fim"
                  onChange={form.handleChange}
                  value={form.values['end-minute']}
                >
                  <option value="">{'Minutos'}</option>
                  {minutes.map(i => (
                    <option
                      key={i}
                      value={i}
                    >
                      {i}
                    </option>
                  ))}
                </select>
                {form.touched['end-minute'] && form.errors['end-minute'] && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
              </div>
            </div>
          </div>
        </div>
        
        <div className="form__row">
          <label
            className="form__label"
            htmlFor="about"
          >
            {t('liveClass.aboutClass')}
          </label>

          <TextareaAutosize
            id="about"
            name="about"
            placeholder={t('liveClass.aboutDescription')}
            onChange={form.handleChange}
            defaultValue={form.values.about}
          />
        </div>

        <button
          type="submit"
          className="btn btn--wide btn--primary"
          disabled={isSubmitting}
        >
          {editingLiveClass ? t('liveClass.buttonEdit') : t('liveClass.buttonCreate')}
        </button>
      </form>
    </Modal>
  );
}
