import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FiChevronLeft, FiX } from 'react-icons/fi';
import DataGridGenerated from './DataGridGenerated';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import xlsx from 'json-as-xlsx';
import { navigate } from '@reach/router';
import QuestionsReportFilter from './ReportFilter';
import { t } from 'i18next';
import ReportFilter from 'app/components/ReportFilter';
import BarChart from './BarChart';
import QuestionsReportModal from './QuestionsReportModal';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';

export default function QuestionReports() {
  const client = useClient();
  const [report, setReport] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [loading, setLoading] = useState(false);
  const today = new Date();
  today.setMonth(new Date().getMonth() - 1);

  //data dos filtros
  const [currentFilters, setCurrentFilters] = useState([]);
  const [exams, setExams] = useState([]);
  const [regionals, setRegionals] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [booklets, setBooklets] = useState([]);
  const [startDate, setStartDate] = useState(today.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [topics, setTopics] = useState([]);

  //filtros
  const [selectedRegionals, setSelectedRegionals] = useState([]);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const [selectedExams, setSelectedExams] = useState([]);
  const [selectedBooklets, setSelectedBooklets] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);

  const fetchBooklets = async (url = '/question_books/simple?filter[visibility]=train') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('Erro ao buscar exercicios'));
    } else {
      setBooklets(data.map(item => ({ value: item.id, label: item.title })));
    }
  };

  const fetchExams = async (url = '/question_books/simple?filter[visibility]=exam') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('toast.errorGetCourse'));
    } else {
      setExams(data.map(item => ({ value: item.id, label: item.title })));
    }
  };

  const fetchInstitutions = async (url = '/institutions?simple=true') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Falha ao carregar instituições');
    } else {
      setInstitutions(data.map(item => ({ value: item.id, label: item.name })));
    }
  };
  const fetchRegionals = async (url = '/regionals') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Falha ao carregar regionais');
    } else {
      setRegionals(data.map(item => ({ value: item.id, label: item.name })));
    }
  };

  const fetchTopics = async () => {
    const { data, error } = await client.fetch('/taxonomies?simple=true');
    if (error) {
      toast.error('Erro ao buscar tópicos');
    } else {
      setTopics(data.map(item => ({ value: item.id, label: item.name })));
    }
  };

  const fetchReports = async () => {
    setLoading(true);
    let url = '/reports/question_reports?';
    let params = [];

    if (startDate && endDate) {
      params.push(`initial_date=${startDate}&final_date=${endDate}`);
    }
    if (selectedRegionals.length > 0) {
      params.push(`regional_ids=${selectedRegionals.map(item => item.value)}`);
    }
    if (selectedBooklets.length > 0 || selectedExams.length > 0) {
      params.push(`question_book_ids=${[...selectedBooklets.map(item => item.value), ...selectedExams.map(item => item.value)]}`);
    }
    if (selectedInstitutions.length > 0) {
      params.push(`institution_ids=${selectedInstitutions.map(item => item.value)}`);
    }
    if (selectedTopics.length > 0) {
      params.push(`taxonomy_ids=${selectedTopics.map(item => item.value)}`);
    }

    const { data, error } = await client.fetch(url + params.join('&'));
    if (error) {
      toast.error('Erro ao buscar relatório');
    } else {
      setReport(data);
    }
    setLoading(false);
  };

  const parsedTableData = useMemo(() => {
    const questionbooks = [...exams, ...booklets];
    const tableData = report.questions;

    const parsedTableData = tableData?.map(td => {
      const newObj = { ...td };

      delete newObj['question-book-ids'];
      delete newObj['taxonomy-ids'];

      const parsedQuestionbooks = questionbooks.filter(qb => td['question-book-ids'].includes(qb.value));
      const parsedTopics = topics.filter(t => td['taxonomy-ids'].includes(t.value));
      return {
        ...newObj,
        questionbooks: parsedQuestionbooks,
        topics: parsedTopics
      };
    });

    return parsedTableData;
  }, [report, exams, booklets, topics]);

  useEffect(() => {
    if (selectedBooklets.length > 0 || selectedExams.length > 0 || selectedInstitutions.length > 0 || selectedTopics.length > 0 || selectedRegionals.length > 0) {
      fetchReports();
    }
  }, [currentFilters, startDate, endDate]);

  useEffect(() => {
    fetchExams();
    fetchInstitutions();
    fetchRegionals();
    fetchBooklets();
    fetchTopics();
  }, []);

  const data = useMemo(() => {
    //atualiza os seletores de acordo com os filtros aplicados
    return [
      {
        placeholder: 'Digite o nome da GRE',
        name: 'GRE',
        options: regionals
      },
      {
        placeholder: 'Digite o nome da escola',
        name: 'Escola',
        options: institutions
      },
      {
        placeholder: 'Digite o nome da Prova',
        name: 'Prova',
        options: exams
      },
      {
        placeholder: 'Digite o nome do Exercício',
        name: 'Exercício',
        options: booklets
      },
      {
        placeholder: 'Digite o nome do Tópico',
        name: 'Tópicos',
        options: topics
      }
    ];
  }, [institutions, regionals, exams, booklets, topics]);

  const removeHtmlTags = str => str.replace(/<\/?[^>]+>/gi, '');

  const xlsxStructure = [
    {
      sheet: 'Perguntas',
      columns: [
        { label: 'Pergunta', value: 'Pergunta' },
        { label: 'Total de respostas', value: 'Total de respostas' },
        { label: 'Quantidade de erros', value: 'Quantidade de erros' },
        { label: 'Quantidade de acertos', value: 'Quantidade de acertos' },
        { label: 'Tópico', value: 'Tópico' },
        { label: 'Cadernos de questões', value: 'Cadernos de questões' }
      ],
      content: parsedTableData?.map(item => ({
        Pergunta: removeHtmlTags(item.description),
        'Total de respostas': item['total-answers'],
        'Quantidade de erros': item['total-incorrect-answers'],
        'Quantidade de acertos': item['total-correct-answers'],
        Tópico: item.topics.map(item => item.label).join(' '),
        'Cadernos de questões': item.questionbooks.map(item => item.label).join(' ')
      }))
    }
  ];

  const handleDownloadCSV = () => {
    try {
      xlsx(xlsxStructure);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setCurrentFilters([...selectedRegionals, ...selectedInstitutions, ...selectedExams, ...selectedBooklets, ...selectedTopics]);
  }, [selectedRegionals, selectedInstitutions, selectedExams, selectedBooklets, selectedTopics]);

  const handleRemoveFilterItem = item => {
    //remover itens do filtro
    switch (item.type) {
      case 'exam':
        setSelectedExams(prevState => prevState.filter(c => c.value !== item.value));
        break;
      case 'booklet':
        setSelectedBooklets(prevState => prevState.filter(m => m.value !== item.value));
        break;
      case 'institution':
        setSelectedInstitutions(prevState => prevState.filter(i => i.value !== item.value));
        break;
      case 'regional':
        setSelectedRegionals(prevState => prevState.filter(r => r.value !== item.value));
      case 'topic':
        setSelectedTopics(prevState => prevState.filter(t => t.value !== item.value));
        break;
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedQuestion(null);
  };

  const mock = [
    {
      name: 'AUGUSTINHO BRANDÃO',
      'total-answers': 6,
      performance: 16.67
    },
    {
      name: 'CEEP LEONARDO DAS DORES - ESPERANTINA',
      'total-answers': 6,
      performance: 99.67
    },
    {
      name: 'U E  FRANCISCA MARLUCE NUNES QUEIROZ - MORRO DO CHAPEU DO PIAUI',
      'total-answers': 6,
      performance: 60
    },
    {
      name: 'CEJA JONAS CORREIA - PARNAIBA',
      'total-answers': 6,
      performance: 96.67
    },
    {
      name: 'CEJA JOSE LOPES DE ARAUJO - LUZILANDIA',
      'total-answers': 6,
      performance: 66.67
    }
  ];

  return (
    <main className="report">
      <header className="report__header">
        <h1 className="report__title">
          <a
            className="card__icon-wrapper"
            onClick={() => navigate(-1)}
            style={{ cursor: 'pointer' }}
          >
            <FiChevronLeft className="card__icon" />
          </a>
          Relatório de perguntas
        </h1>
        <p className="report__description">
          Objetivo: Avaliar a dificuldade de cada questão permitindo ao professor identificar os pontos de maior atenção durante a explicação, além de verificar quais escolas estão apresentando mais erros ou acertos.
        </p>
      </header>
      <div className="report__body">
        <div className="report__filter report__filter--space report__filter--multi-select">
          <QuestionsReportFilter
            setSelectedTopics={setSelectedTopics}
            selectedTopics={selectedTopics}
            setSelectedBooklets={setSelectedBooklets}
            setSelectedRegionals={setSelectedRegionals}
            selectedRegionals={selectedRegionals}
            setSelectedExams={setSelectedExams}
            selectedExams={selectedExams}
            setSelectedInstitutions={setSelectedInstitutions}
            selectedInstitutions={selectedInstitutions}
            fetchInstitutions={fetchInstitutions}
            currentFilters={currentFilters}
            handleRemoveFilterItem={handleRemoveFilterItem}
            data={data}
          />

          <div className="report-widget__filter">
            <div className="report-form__datepicker">
              <input
                className="report-form__control"
                id="start-date"
                name="start-date"
                type="date"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                title="Data de início"
                aria-label="Data de início"
              />
              <input
                className="report-form__control"
                id="end-date"
                name="end-date"
                type="date"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                title="Data de fim"
                aria-label="Data de fim"
              />
            </div>
          </div>
        </div>
        <div className="report-widget">
          <div className="report-widget__body">
            {!loading && parsedTableData?.length > 0 && (selectedBooklets?.length > 0 || selectedExams?.length > 0) ? (
              <>
                <div className="report-kpi-card-wrapper">
                  <div
                    className="report-kpi-card"
                    style={{ width: '500px' }}
                  >
                    <BarChart
                      data={report?.['most-accessed-institutions']}
                      title="Escolas mais presentes (Números de respostas)"
                    />
                  </div>
                  <div
                    className="report-kpi-card"
                    style={{ width: '500px' }}
                  >
                    <BarChart
                      data={report?.['best-performance-institutions']}
                      title="Escolas com maior aproveitamento (Percentil de aproveitamento)"
                      type="institution-performance"
                    />
                  </div>
                </div>
                {report?.['best-performance-by-taxonomy'].length > 1 && (
                  <div
                    className="report-kpi-card-wrapper "
                    style={{ padding: '32px 0' }}
                  >
                    <div className="report-kpi-card">
                      <BarChart
                        data={report?.['best-performance-by-taxonomy']}
                        title="Tópicos com melhor desempenho (Percentil de aproveitamento)"
                        type="taxonomy"
                      />
                    </div>
                  </div>
                )}
                <div
                  className="report-widget__header"
                  style={{ justifyContent: 'flex-end', marginBottom: '24px' }}
                >
                  <div className="report-widget__filter">
                    <button
                      className="btn btn--outline"
                      type="button"
                      disabled={false}
                      onClick={handleDownloadCSV}
                    >
                      Download do relatório
                    </button>
                  </div>
                </div>
                <DataGridGenerated
                  setShowModal={setShowModal}
                  setSelectedQuestion={setSelectedQuestion}
                  questions={parsedTableData}
                  questionbooks={[...exams, ...booklets]}
                />
              </>
            ) : !loading && parsedTableData?.length === 0 && (selectedBooklets?.length > 0 || selectedExams?.length > 0) ? (
              <EmptyState
                type="data"
                title="Não há dados para o período selecionado"
                text=" "
              />
            ) : selectedBooklets?.length === 0 && selectedExams?.length === 0 ? (
              <EmptyState
                type="add"
                title="Selecione um exercício ou uma prova para ver os dados"
                text=" "
                bgless
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>

      <QuestionsReportModal
        startDate={startDate}
        endDate={endDate}
        selectedRegionals={selectedRegionals}
        selectedBooklets={selectedBooklets}
        selectedInstitutions={selectedInstitutions}
        selectedTopics={selectedTopics}
        selectedExams={selectedExams}
        selectedQuestion={selectedQuestion}
        currentFilters={currentFilters}
        onClose={closeModal}
        showModal={showModal}
      />
    </main>
  );
}
