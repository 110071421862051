import React, { useMemo, useState } from 'react';
import { FiChevronDown, FiPrinter } from 'react-icons/fi';
import { Link, navigate } from '@reach/router';
import Dropdown from '../Dropdown';
import getPermission from 'app/utils/getPermission';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { FiCheckCircle } from 'react-icons/fi';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import AnswersGuide from 'app/screens/ExamMetrics/AnswersGuide';
import Modal from 'app/components/Modal';
import { t } from 'i18next';
import { Badge } from '../Badge';
import { useSession } from 'app/hooks/useSession';
import IconVerified from '../Icons/IconVerified';

function MockQuestionBookCard(props) {
  const { mockQuestionBook, setFilteredMockQuestionBooks } = props;
  const client = useClient();
  const authorityLevel = getAuthorityLevel();
  const [showModal, setShowModal] = useState(false);

  const isStudent = authorityLevel === 'student';

  const mockQuestionBookForm = useFormik({
    initialValues: {
      id: mockQuestionBook?.id,
      published: mockQuestionBook?.published
    }
  });

  const updateMockQuestionBookPublicity = async values => {
    const { error } = await client.mutate(['question_books', mockQuestionBook.id], {
      published: !values.published
    });
    if (error) {
      toast.error(t('toast.errorPublishTask'));
    } else {
      if (!values.published === true) {
        toast.success(t('toast.successPublish'));
      } else {
        toast.success(t('toast.successUnpublish'));
      }
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: t('warning.warningDeleteSimulation'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteMockQuestionBookCard();
      }
    });
  };

  const deleteMockQuestionBookCard = async () => {
    const { error } = await client.delete(['question_books', mockQuestionBook?.id]);
    if (error) {
      toast.error(t('toast.errorDeleteSimulation'));
    } else {
      toast.success(t('toast.successSimulationDeleted'));
      setFilteredMockQuestionBooks(prevState => prevState.filter(item => item.id !== mockQuestionBook?.id));
    }
  };

  const isAcceptingAnswers = mockQuestionBook.status === 'accepting_answers';
  const isExamDone = mockQuestionBook.status === 'approved' || mockQuestionBook.status === 'disapproved';
  const isExamNotRealized = !mockQuestionBook.status;
  const isInAnalisys = mockQuestionBook.status === 'review';

  const actualDate = moment();
  const deadline = moment(mockQuestionBook?.['finished-at']);
  const isAfter = actualDate.isAfter(deadline);
  const isRealizedInAdmin = isAfter && !isStudent;

  const currentUser = useSession()?.session?.user;

  const shouldRenderOptions = useMemo(() => {
    if (mockQuestionBook?.['authored-by-user'] === true) {
      if (mockQuestionBook?.creator?.id === currentUser.id || currentUser.profileNames.includes('Administrador')) {
        return true;
      } else return false;
    }

    if (mockQuestionBook?.['authored-by-user'] === false) {
      if (getPermission('Editar simulados', 'Provas e simulados') || getPermission('Excluir simulados', 'Provas e simulados')) {
        return true;
      } else return false;
    }
  }, [mockQuestionBook]);

  return (
    <div className="card">
      {!isStudent && (
        <div className="card__inner-header">
          <div
            className="form__check form__switch u-mb-0"
            style={{ flexGrow: '1' }}
          >
            {(mockQuestionBook?.['authored-by-user'] === false || currentUser.id === mockQuestionBook.creator.id || currentUser.profileNames.includes('Administrador')) && (
              <>
                <input
                  className="form__check-input"
                  id={`publish-${mockQuestionBook?.id}`}
                  name={`publish-${mockQuestionBook?.id}`}
                  type="checkbox"
                  checked={mockQuestionBookForm.values.published}
                  onClick={() => {
                    mockQuestionBookForm.setFieldValue('published', !mockQuestionBookForm.values.published);
                    updateMockQuestionBookPublicity(mockQuestionBookForm.values);
                  }}
                />
                <label htmlFor="">{t('exams.publish')}</label>
              </>
            )}
          </div>
          {mockQuestionBook?.['question-count'] > 0 && mockQuestionBook?.['question-count'] <= 100 && (
            <button
              className="card__print-btn"
              title={t('button.printExam')}
              onClick={() => navigate(`/avaliacoes/simulados/impressao/${mockQuestionBook?.id}`)}
            >
              <FiPrinter />
            </button>
          )}
        </div>
      )}

      <div className="card__inner-header">
        {mockQuestionBook?.['official-content'] && <IconVerified />}
        <h2
          className="card__title"
          title={mockQuestionBook?.title}
        >
          {mockQuestionBook?.title}
        </h2>
      </div>

      {isExamNotRealized && isStudent && (
        <Badge
          type="danger"
          title={t('exams.statusUnrealized')}
        />
      )}

      {isExamDone && isStudent && <Badge title={t('exams.statusDone')} />}

      {isRealizedInAdmin && <Badge title={t('exams.statusDone')} />}

      {isAcceptingAnswers && isStudent && (
        <Badge
          type="warning"
          title={isStudent ? 'Aceitando respostas' : t('exams.inProgress')}
        />
      )}

      {isInAnalisys && isStudent && (
        <Badge
          type="warning"
          title={t('exams.statusInAnalysis')}
        />
      )}

      <div className="card__badges card__badges--one-line">
        {mockQuestionBook?.taxonomies.length > 0 &&
          mockQuestionBook?.taxonomies.map((requirement, index) => {
            if (index == 0) {
              return (
                <span
                  className="badge badge--primary"
                  title={requirement.name}
                  key={index}
                >
                  {requirement.name}
                </span>
              );
            }
            if (index == 1) {
              return (
                <span
                  className="badge badge--primary"
                  title={mockQuestionBook?.taxonomies
                    .map((r, index) => {
                      if (index > 0) return r.name;
                    })
                    .join(',')
                    .replace(',', '')}
                  key={index}
                >
                  {`+${mockQuestionBook?.taxonomies.length - 1}`}
                </span>
              );
            }
          })}
        {mockQuestionBook?.taxonomies.length === 0 && (
          <span
            className="badge badge--info"
            title="Sem matérias"
          >
            Sem matérias
          </span>
        )}
      </div>

      <div className="card__body">
        <div className="card__columns">
          <p
            className="card__count"
            title="Questões"
            aria-label="Questões"
          >
            {t('textsCommon.questions')}:<div className="clearfix"></div>
            <FiCheckCircle className="card__icon" />
            {mockQuestionBook?.['question-count']}
          </p>
          <p
            className="card__count"
            title="Aplicação em"
            aria-label="Aplicação em"
          >
            {t('exams.labelPublishedAt')}
            <br />
            {moment(mockQuestionBook?.['published-at']).format('DD/MM/YYYY')}
          </p>
        </div>
      </div>

      <div className="card__footer">
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          {isStudent ? (
            <>
              {isAcceptingAnswers && (
                <Link
                  to={`/simulados/sobre/${mockQuestionBook?.id}`}
                  className={'btn btn--outline btn--small'}
                >
                  {t('button.access')}
                </Link>
              )}

              {isExamDone && (
                <button
                  onClick={() => setShowModal(true)}
                  className={isInAnalisys ? 'btn btn--outline btn--small disabled' : 'btn btn--outline btn--small '}
                >
                  {t('button.answerKey')}
                </button>
              )}
            </>
          ) : (
            <>
              {getPermission('Gerenciar simulados', 'Provas e simulados') && (
                <Link
                  to={`/simulados/gerenciar/${mockQuestionBook?.id}`}
                  className="btn btn--outline btn--small"
                >
                  {t('button.manage')}
                </Link>
              )}

              {shouldRenderOptions ? (
                <Dropdown className="btn btn--outline btn--small">
                  <Dropdown.Toggle>
                    <FiChevronDown />
                  </Dropdown.Toggle>
                  <Dropdown.Content>
                    {mockQuestionBook?.['question-count'] > 0 && mockQuestionBook?.['question-count'] <= 100 && getPermission('Editar simulados', 'Provas e simulados') && (
                      <Dropdown.Item to={`/avaliacoes/simulados/offline/${mockQuestionBook?.id}`}>{t('button.offlineExam')}</Dropdown.Item>
                    )}
                    {getPermission('Editar simulados', 'Provas e simulados') && <Dropdown.Item to={`/simulados/criar/curso/${mockQuestionBook?.id}`}>{t('button.edit')}</Dropdown.Item>}
                    {getPermission('Excluir simulados', 'Provas e simulados') && <Dropdown.Item onClick={() => handleDelete(mockQuestionBook)}>{t('button.delete')}</Dropdown.Item>}
                  </Dropdown.Content>
                </Dropdown>
              ) : null}
            </>
          )}
        </div>
      </div>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        contentClassName="modal__content--md"
      >
        <AnswersGuide
          examId={mockQuestionBook.id}
          amountQuestions={mockQuestionBook['question-count']}
          title="Gabarito do simulado"
        />
      </Modal>
    </div>
  );
}

export default MockQuestionBookCard;
