import React from 'react';
import { useTranslation } from 'react-i18next';

function ActivityCardHeader(props) {
  const { t } = useTranslation();
  const { activity, activityStudent, showStatus } = props;
  const activityStatusClass = () => {
    switch (activityStudent['status']) {
      case 'Avaliação realizada':
        return 'activity-card__status--success';
        break;
      default:
        return 'activity-card__status--default';
        break;
    }
  }
  return (
    activity && <div className="activity-card__header">
      <div className="activity-card__group">
        {showStatus && <span
          className={`activity-card__status ${activityStatusClass()}`}
          style={{position: 'relative', left: '0', marginBottom: '8px'}}
        >
          {activityStudent['status']}
        </span>}

        <h2 className="activity-card__title activity-card__title--md">
          {activity.title}
        </h2>
        <span className="activity-card__info">
        {t("textsCommon.module")} {activity.modulus } | {t("textsCommon.unit")} {activity.unit} | {activity['class-hours'] < 2 ? (`${activity['class-hours']} hora`) : (`${activity['class-hours']} ${t("tasks.hours")}`)}
        </span>
      </div>
    </div>
  );
}

export default ActivityCardHeader;
