import React from 'react';
import { FiFlag } from 'react-icons/fi';
import { TfiMedallAlt } from 'react-icons/tfi';
import Slider from 'react-slider';
import moreMedal from 'app/images/more-medal.svg';
import moment from 'moment';
import { t } from 'i18next';

const StudyPlanTimeline = ({ currentWeek, studyStatus, onWeekChange, totalWeeks, endDate, initialDate, weekStatus, nowWeekIndex }) => {
  const handleWeekChange = week => {
    onWeekChange(week);
  };
  const initialDateFormated = moment(initialDate).format(t('datesForm.formatDate'));
  const endDateFormated = moment(endDate).format(t('datesForm.formatDate'));

  const renderProgressBarCircles = () => {
    const circles = [];
    for (let i = 0; i < totalWeeks; i++) {
      let circleStatus = 'default';
      if (weekStatus[i] === 'complete') {
        circleStatus = 'completed';
      } else if (weekStatus[i] === 'incomplete' && i <= nowWeekIndex) {
        circleStatus = 'incomplete';
      } else {
        circleStatus = 'default';
      }

      circles.push(
        <div
          className={`progress-circle ${circleStatus}`}
          key={i}
          style={{
            left: `${(i / (totalWeeks - 1)) * 97.5}%`
          }}
          onClick={() => handleWeekChange(i)}
        >
          {/* {i} */}
        </div>
      );
    }
    return circles;
  };

  return (
    <div className="study-plan-timeline">
      <p className="study-plan-timeline__date">
        <FiFlag className="study-plan-timeline__icon" /> {initialDateFormated}
      </p>
      <div className="slider-wrapper">
        {renderProgressBarCircles()}
        <Slider
          className="week-slider"
          value={currentWeek}
          min={0}
          max={totalWeeks - 1}
          onChange={handleWeekChange}
          renderThumb={(props, state) => {
            let status = '';
            if (weekStatus[state.value] === 'complete') {
              status = 'completed';
            } else if (weekStatus[state.value] === 'incomplete' && state.value <= nowWeekIndex) {
              status = 'incomplete';
            }
            return (
              <div
                {...props}
                className={`week-slider-thumb ${status}`}
              >
                <div className={`tooltip ${status}`}>
                  {t('studyPlan.week')} {state.value + 1}
                </div>
              </div>
            );
          }}
        />
      </div>
      <p className="study-plan-timeline__date">
        <div className="medal medal--sm">
          <img
            src={moreMedal}
            alt="nova medalha"
            className="medal__img"
          />
          <span className="medal__value">
            <TfiMedallAlt />
          </span>
        </div>
        {endDateFormated}
      </p>
    </div>
  );
};

export default StudyPlanTimeline;
