export default function reorder(arr, param, order = 'asc', type = 'number') {
  const reorderedArr = [...arr];


  const compare = (a, b) => {
    const valueA = a[param];
    const valueB = b[param];

    const typeOfValues = typeof valueA === typeof valueB && typeof valueA === 'number';

    if ((!valueA || !valueB) && !typeOfValues) return 0;

    switch (type) {
      case 'date':
        return new Date(valueA) - new Date(valueB);
      case 'string':
        return valueA.localeCompare(valueB);
      default:
        return valueA - valueB;
    }
  };

  reorderedArr.sort((a, b) => (order === 'asc' ? compare(a, b) : compare(b, a)));

  return reorderedArr;
}
