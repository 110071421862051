import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function BarChart(props) {
  const { data, title, type } = props;
  const chartComponentRef = useRef(null);

  const colors = ['#7B63BF', '#5DC9DD', '#8E7BC3', '#B4A7D6', '#DAD3E9', '#D0E0E3', '#A2C4C9', '#76A5AF', '#45808D', '#134E5B'];
  const series = data?.map(() => ({
    name: type === 'taxonomy' || type === 'institution-performance' ? 'Aproveitamento' : 'Repostas',
    data: data?.map(item => {
      if (type === 'taxonomy' || type === 'institution-performance') {
        return item.performance;
      } else {
        return item['total-answers'];
      }
    }),
    colorByPoint: true
  }));

  useEffect(() => {
    const handleResize = () => {
      if (chartComponentRef.current) {
        chartComponentRef.current.chart.reflow();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const namesArray = data?.map(item => {
    if (type === 'taxonomy') {
      return item['taxonomy-name'];
    } else {
      return item.name;
    }
  });

  const options = {
    chart: {
      type: 'bar',
      height: 300
    },
    credits: {
      enabled: false
    },
    title: {
      text: title
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: namesArray,
      title: {
        text: null
      },
      labels: {
        align: 'left',
        reserveSpace: true,
        style: {
          whiteSpace: 'nowrap',
          width: 112,
          textOverflow: 'ellipsis'
        }
      },
      gridLineWidth: 0,
      lineWidth: 0
    },
    yAxis: {
      max: type === 'taxonomy' || type === 'institution-performance' ? 100 : null,
      title: {
        text: null
      },
      labels: {
        overflow: 'justify',
        format: type === 'taxonomy' || type === 'institution-performance' ? '{value}%' : ''
      },
      gridLineWidth: 0,
      lineWidth: 0,
      allowDecimals: false
    },
    tooltip: {
      valueSuffix: type === 'taxonomy' || type === 'institution-performance' ? '%' : ''
    },

    series: series,
    plotOptions: {
      series: {
        pointPadding: 0.5,
        groupPadding: 0.5,
        pointWidth: 30,
        pointHeight: 80
      }
    },
    colors: colors.slice(0, data?.length),
    pointWidth: 50
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
}
