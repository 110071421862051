import { useCallback } from 'react';
import { FiFilter, FiMonitor, FiMousePointer, FiUsers } from 'react-icons/fi';

export default function MetricsShortcuts({ title, link, icon }) {
  const renderIcon = useCallback(icon => {
    switch (icon) {
      case 'monitor':
        return (
          <FiMonitor
            size={20}
            color="#442B8C"
          />
        );
      case 'funil':
        return (
          <FiFilter
            size={20}
            color="#442B8C"
          />
        );
      case 'users':
        return (
          <FiUsers
            size={20}
            color="#442B8C"
          />
        );
      case 'pointer':
        return (
          <FiMousePointer
            size={20}
            color="#442B8C"
          />
        );
    }
  }, []);

  return (
    <div className="metrics-shortcut-wrapper">
      <div className="metrics-shortcut-wrapper__left">
        <h3 className='kpi-widget__title'>{title}</h3>
        <a
          className="btn--link"
          href={link}
        >
          Acessar relatório
        </a>
      </div>
      <span style={{height: 'fit-content', backgroundColor: '#F1F5FA'}} className="card__icon-wrapper">{renderIcon(icon)}</span>
    </div>
  );
}
