import React from 'react';
import ScatterChart from '../../../ScatterChartWidget/ScatterChart';

export default function SkillTree() {
  return (
    <div className="report-widget">
      <div className="report-widget__header">
        <h3 className="report-widget__title report-widget__title--sm">Árvore de habilidades</h3>
      </div>

      <div className="report-widget__body">
        <ScatterChart />
      </div>
    </div>
  );
}
