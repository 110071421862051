import React from 'react';

export const FilterCheckBox = ({ onChange, value, label, className, ...rest }) => {
  let classFilterCheckBox = 'filter-bar__check';
  if (className) classFilterCheckBox = [classFilterCheckBox, className].join(' ');

  return (
    <div
      className={classFilterCheckBox}
      onChange={onChange}
    >
      <input
        {...rest}
        className="filter-bar__input"
        type="checkbox"
        id={label}
        name={label}
        value={value}
        defaultChecked={value}
      />
      <label
        className="filter-bar__label"
        htmlFor={label}
      >
        {label}
      </label>
    </div>
  );
};
