const { useQuery } = require('jsonapi-react');

export function useFilteredQuery(url, values) {
  let filter = {};

  if (values?.course && values?.course !== 'blank') {
    filter.course_id = values.course;
  }

  if (values?.['start-date'] && values?.['end-date']) {
    filter.date_between = {
      initial: values?.['start-date'],
      final: values?.['end-date']
    };
  }

  const query = useQuery([
    url,
    {
      filter
    }
  ]);

  return { ...query };
}
