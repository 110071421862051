import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { FiChevronLeft, FiCheck, FiFile, FiStar, FiChevronDown } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import AverageAccessChart from './AverageAccessChart';
import useReportAbilities from 'app/hooks/useReportAbilities';

export default function LiveClassesReport() {
  const [relationships, setRelationships] = useState({
    courses: []
  });
  const { reportAbilities, getReportAbilities } = useReportAbilities();

  const client = useClient();

  const filters = useFormik({
    initialValues: {
      course: 'blank'
    }
  });

  const satisfactionFilters = useFormik({
    initialValues: {
      question: 'blank',
      'start-date': new Date(),
      'end-date': new Date()
    }
  });

  const rankingFilters = useFormik({
    initialValues: {
      question: 'blank',
      matter: 'blank'
    }
  });

  const questions = [
    {
      id: 1,
      title: 'A qualidade de vídeo e áudio foi satisfatória?'
    },
    {
      id: 2,
      title: 'O conteúdo apresentado foi claro e compreensível?'
    },
    {
      id: 3,
      title: 'A plataforma foi fácil de usar durante a transmissão ao vivo?'
    },
    {
      id: 4,
      title: 'Você acha que o que aprendeu na aula ao vivo será útil para você?'
    },
    {
      id: 5,
      title: 'Como você avaliaria sua satisfação geral com a aula ao vivo?'
    }
  ];

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses');
    setRelationships({
      courses: courses || []
    });
  };

  useEffect(() => {
    getRelationships();
    getReportAbilities();
  }, []);

  const kpis = [
    {
      id: 1,
      icon: <FiCheck />,
      title: 'Total de questionários enviados',
      value: '3.000'
    },
    {
      id: 2,
      icon: <FiFile />,
      title: 'Percentual de questionários respondidos',
      value: '68%'
    },
    {
      id: 3,
      icon: <FiFile />,
      title: 'Quantidade questionários respondidos',
      value: '4587'
    },
    {
      id: 4,
      icon: <FiStar />,
      title: 'Satisfação geral',
      value: '3.5'
    }
  ];

  const today = moment().format(t('datesForm.formatDate'));

  return (
    <main className="main-content main-content--block">
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <button
            className="filter-bar__back"
            onClick={() => navigate(-1)}
          >
            <span className="card__icon-wrapper">
              <FiChevronLeft className="card__icon" />
            </span>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">Avaliação das aulas ao vivo</span>
            </span>
          </button>
        </div>
      </div>

      <div className="filter-bar filter-bar--borderless">
        <div className="filter-bar__inner">
          <FilterSelectionBox
            label={t('exams.selectCourse')}
            value={filters.values.course}
            onChange={e => filters.setFieldValue('course', e.target.value)}
            options={relationships.courses.map(o => ({
              id: o.id,
              name: o.title
            }))}
          />
        </div>
      </div>

      <div className="widget-wrapper">
        <div className="kpi-cards">
          {kpis.map(kpi => (
            <div
              className="kpi-card"
              key={kpi.id}
            >
              <div className="kpi-card__icon">{kpi.icon}</div>
              <div className="kpi-card__title">{kpi.title}</div>
              <div className="kpi-card__value">{kpi.value}</div>
            </div>
          ))}
        </div>
      </div>

      {reportAbilities?.['aulas-ao-vivo']['media-de-satisfacao'] && (
        <div className="widget-wrapper">
          <div className="widget">
            <div className="widget__header">
              <h3 className="widget__title u-mb-2">Média de satisfação das aulas ao vivo</h3>

              <div className="filter-bar filter-bar--borderless">
                <div className="filter-bar__inner">
                  <FilterSelectionBox
                    label={'Selecione uma pergunta'}
                    value={satisfactionFilters.values.question}
                    onChange={e => satisfactionFilters.setFieldValue('question', e.target.value)}
                    options={questions.map(o => ({
                      id: o.id,
                      name: o.title
                    }))}
                  />

                  <div className="filter-bar__row">
                    <label
                      htmlFor="start-date"
                      className="filter-bar__label"
                    >
                      Selecione um período
                    </label>
                    <div className="form__columns">
                      <div className="form__col">
                        <input
                          className="form__control"
                          id="start-date"
                          name="start-date"
                          type="date"
                          min={today}
                          value={moment(satisfactionFilters.values['start-date']).format('YYYY-MM-DD')}
                          onChange={e => satisfactionFilters.setFieldValue('start-date', e.target.value)}
                          placeholder="Data de início"
                        />
                      </div>
                      <div className="form__col">
                        <input
                          className="form__control"
                          id="end-date"
                          name="end-date"
                          type="date"
                          min={today}
                          value={moment(satisfactionFilters.values['end-date']).format('YYYY-MM-DD')}
                          onChange={e => satisfactionFilters.setFieldValue('end-date', e.target.value)}
                          placeholder="Data de fim"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="widget__body">
              <AverageAccessChart />
            </div>
          </div>
        </div>
      )}

      {reportAbilities?.['aulas-ao-vivo']['ranking'] && (
        <div className="widget-wrapper">
          <div className="widget">
            <div className="widget__header">
              <h3 className="widget__title u-mb-2">Ranking das aulas</h3>

              <div className="filter-bar filter-bar--borderless">
                <div className="filter-bar__inner">
                  <FilterSelectionBox
                    label={'Selecione uma pergunta'}
                    value={rankingFilters.values.question}
                    onChange={e => rankingFilters.setFieldValue('question', e.target.value)}
                    options={questions.map(o => ({
                      id: o.id,
                      name: o.title
                    }))}
                  />
                  <FilterSelectionBox
                    label={'Selecione uma matéria'}
                    value={rankingFilters.values.matter}
                    onChange={e => rankingFilters.setFieldValue('matter', e.target.value)}
                    options={[]}
                  />
                </div>
              </div>
            </div>

            <div className="widget__body">
              <div className="round-dg-wrapper">
                <table
                  className="round-dg round-dg--light"
                  style={{ minWidth: '820px' }}
                >
                  <thead className="round-dg__header">
                    <tr className="round-dg__row">
                      <th
                        className="round-dg__cell-header round-dg__cell-header--sm"
                        style={{ width: '80px' }}
                      >
                        Posição
                      </th>
                      <th className="round-dg__cell-header round-dg__cell-header--sm">Título</th>
                      <th
                        className="round-dg__cell-header round-dg__cell-header--sm"
                        style={{ width: '64px' }}
                      />
                      <th
                        className="round-dg__cell-header round-dg__cell-header--sm"
                        style={{ width: '120px' }}
                      >
                        Matéria
                      </th>
                      <th
                        className="round-dg__cell-header round-dg__cell-header--sm"
                        style={{ width: '200px' }}
                      >
                        Curso
                      </th>
                      <th
                        className="round-dg__cell-header round-dg__cell-header--sm"
                        style={{ width: '100px' }}
                      >
                        Avaliação{' '}
                        <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                          <FiChevronDown className="card__icon" />
                        </span>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="round-dg__body">
                    {[...Array(10)].map((item, i) => (
                      <tr
                        className="round-dg__row"
                        key={i}
                      >
                        <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{i + 1}</th>
                        <td className="round-dg__cell round-dg__cell--sm">
                          <div className="round-dg__user">
                            <div className="round-dg__user-inner">
                              <h3 className="round-dg__title">Números complexos números complexos Números complexos</h3>
                            </div>
                          </div>
                        </td>
                        <td className="round-dg__cell round-dg__cell--sm">
                          {i === 0 && <AiOutlineTrophy className="card__icon card__icon--golden" />}
                          {i === 1 && <AiOutlineTrophy className="card__icon card__icon--silver" />}
                          {i === 2 && <AiOutlineTrophy className="card__icon card__icon--bronze" />}
                        </td>
                        <td className="round-dg__cell round-dg__cell--sm">
                          <span className="badge badge--tiny badge--primary">Matemática</span>
                        </td>
                        <td className="round-dg__cell round-dg__cell--sm">
                          3º Ano Manhã{' '}
                          <span
                            className="badge badge--tiny badge--more"
                            title={['Curso 1', 'Curso 2'].map(course => course)}
                            style={{ verticalAlign: 'sub' }}
                          >
                            +2
                          </span>
                        </td>
                        <td className="round-dg__cell round-dg__cell--sm">
                          <FiStar className="card__icon card__icon--star" /> 4.5
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
