import React, { useContext, useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link } from '@reach/router';
import { useClient } from 'jsonapi-react';
import { useSession } from 'app/hooks/useSession';
import { ReactComponent as UserAvatar } from 'app/images/user-avatar.svg';
import TabNavBar1 from './TabNavBar1';
import MetricsCalendar from './MetricsCalendar';
import MetricsJourney from './MetricsJourney';
import { useParams } from '@reach/router';
import { MetricsContext, MetricsProvider } from './provider/MetricsProvider';
import MetricsGeneral from './MetricsGeneral';

export const MetricsProfile = () => (
  <MetricsProvider>
    <ScreenContentMetricsProfile />
  </MetricsProvider>
);

const ScreenContentMetricsProfile = () => {
  const { session } = useSession();
  const client = useClient();
  const { id } = useParams();
  const [activeTabs, setActiveTabs] = useState('calendario-de-tarefas');
  const [user, setUser] = useState();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const { data } = await client.fetch(`users/${id}`);
    setUser(data);
  };

  return (
    <>
      <main className="main-content main-content--block fadeIn">
        <div className="filter-bar">
          <Link
            to="/metricas"
            className="filter-bar__back"
          >
            <FiChevronLeft />

            {user?.image?.url ? (
              <img
                className="filter-bar__img"
                src={user?.image?.url}
                alt="foto do usuário"
              />
            ) : (
              <UserAvatar className="filter-bar__img" />
            )}

            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{user?.name}</span>
              <span className="filter-bar__description">{id}</span>
            </span>
          </Link>
        </div>

        <TabNavBar1
          activeTabs={activeTabs}
          setActiveTabs={setActiveTabs}
        />

        {user && activeTabs === 'calendario-de-tarefas' && (
          <MetricsCalendar
            userId={id}
            user={user}
          />
        )}
        {user && activeTabs === 'rendimento-por-materia' && (
          <MetricsJourney
            userId={id}
            user={user}
          />
        )}
        {user && activeTabs === 'metricas-por-materia' && (
          <MetricsGeneral
            userId={id}
            user={user}
          />
        )}
      </main>
    </>
  );
};
