import { t } from 'i18next';
import { FiTrash2 } from 'react-icons/fi';

const ObjectivesStep = props => {
  const { values, errors, createModuleForm, trashIcon, setFieldValue, setRemovedSubjects } = props;

  const deleteSubject = index => {
    let newSystemSubjects = [];
  
      values.systemSubjects.map((item, i) => {
        if (i !== index) {
          newSystemSubjects.push(item);
        } else {
          if (item.id) {
            setRemovedSubjects(prev => [...prev, item]);
          }
        }
      });
      createModuleForm.setFieldValue('systemSubjects', newSystemSubjects);
    };


  return (
    <div className="page__content fadeIn u-mb-5">
      <div className="new-questions__header">
        <h2 className="new-questions__title">{values?.title}</h2>
      </div>

      <fieldset className="form__row">
        <legend className="form__label">* {t('lms.objectiveModuleTitle')}</legend>
        <span className="form__description">{t('lms.objectiveModuleDescription')}</span>

        {errors.systemSubjects && !Array.isArray(errors.systemSubjects) && <span style={{ color: 'red' }}>{errors.systemSubjects}</span>}

        {values?.systemSubjects.map((lo, i) => (
          <div
            className="form__check"
            key={i}
          >
            <span
              style={{
                position: 'absolute',
                inset: 'auto auto auto 5px'
              }}
            >
              •
            </span>
            <span className="form__check-label">
              <input
                value={lo.body}
                onChange={e => {
                  setFieldValue(`systemSubjects.${i}.body`, e.target.value);
                }}
                type="text"
                className="form__control form__control--small"
                placeholder={lo.placeholder}
              />
            </span>
            <button
              type="button"
              style={trashIcon}
              onClick={() => deleteSubject(i)}
            >
              <FiTrash2 />
            </button>

            {createModuleForm.touched.systemSubjects && errors.systemSubjects && <span style={{ color: 'red' }}>{errors.systemSubjects[0]?.body}</span>}
          </div>
        ))}

        <div className="form__check">
          <span className="form__check-label">
            <button
              className="btn btn--link"
              type="button"
              onClick={() => createModuleForm.setFieldValue('systemSubjects', [...values.systemSubjects, { body: '', value: values.systemSubjects.length }])}
            >
              {t('lms.addAItem')}
            </button>
          </span>
        </div>
      </fieldset>

      <span className="form__text">
        <strong>* {t('warning.requiredField')}</strong>
      </span>
    </div>
  );
};

export default ObjectivesStep;
