import React from 'react';
import { useFormik } from 'formik';
import { navigate, useParams } from '@reach/router';
import toast from 'react-hot-toast';
import StepCourse from './StepCourse';
import StepName from './StepName';
import * as yup from 'yup';
import StepDuration from './StepDuration';
import StepFeedback from './StepFeedback';
import { useClient } from 'jsonapi-react';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import getPermission from 'app/utils/getPermission';

export default function ExamContent(props) {
  const { t } = useTranslation();
  const { step, steps, currentStep, setCurrentStep } = props;
  const client = useClient();
  const [courses, setCourses] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [modules, setModules] = useState([]);
  const [exam, setExam] = useState();
  const [loadingClassrooms, setLoadingClassrooms] = useState(false);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loadingInstitutions, setLoadingInstitutions] = useState(false);
  const [association, setAssociation] = useState(t('exams.toggleClass'));
  const examId = useParams().id;
  const [loadingModules, setLoadingModules] = useState(false);
  const [loadingExam, setLoadingExam] = useState(false);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const [classroomsFetched, setClassroomsFetched] = useState(false);

  const getRelationships = () => {
    setLoadingCourses(true);
    setLoadingModules(true);
    setLoadingInstitutions(true);
    const coursesFetch = client.fetch('courses/simple');
    const modulesFetch = client.fetch('learning_systems');
    const institutionsFetch = client.fetch('institutions');

    Promise.allSettled([institutionsFetch, coursesFetch, modulesFetch]).then(([institutionsResponse, coursesResponse, modulesResponse]) => {
      if (institutionsResponse.status === 'rejected') {
        toast.error('Erro ao buscar instituições');
      } else {
        setInstitutions(institutionsResponse.value.data.map(item => ({ value: item.id, label: item.name })));
      }
      if (coursesResponse.status === 'rejected') {
        toast.error(t('toast.errorGetCourse'));
      } else {
        setCourses(coursesResponse.value.data);
      }
      if (modulesResponse.status === 'rejected') {
        toast.error(t('toast.errorGetModule'));
      } else {
        setModules(modulesResponse.value.data);
      }
      setLoadingCourses(false);
      setLoadingModules(false);
      setLoadingInstitutions(false);
    });
  };

  const getClassrooms = async () => {
    setLoadingClassrooms(true);
    const { data, error } = await client.fetch('classrooms/simple');
    if (error) {
      toast.error(t('toast.errorGetClasses'));
    } else {
      setClassrooms(data);
    }
    setLoadingClassrooms(false);
  };

  const getExam = async () => {
    if (examId) {
      setLoadingExam(true);
      try {
        const { data } = await client.fetch(`question_books/${examId}`);
        if (data?.['learning-system-ids'].length > 0) {
          setAssociation(t('exams.toggleModule'));
        }
        const evaluationStart = { date: null, hour: null, minute: null };
        const evaluationEnd = { date: null, hour: null, minute: null };
        if (data?.['published-at']) {
          evaluationStart.date = data['published-at'];
          evaluationStart.hour = new Date(data['published-at']).getHours();
          evaluationStart.minute = new Date(data['published-at']).getMinutes();
        }
        if (data?.['finished-at']) {
          evaluationEnd.date = data['finished-at'];
          evaluationEnd.hour = new Date(data['finished-at']).getHours();
          evaluationEnd.minute = new Date(data['finished-at']).getMinutes();
        }
        const exam = {
          ...data,
          evaluationStart,
          evaluationEnd
        };
        delete exam['finished-at'];
        delete exam['published-at'];
        setExam(exam);
      } catch {
        toast.error('Erro ao carregar dados da prova');
      }
      setLoadingExam(false);
    }
  };

  useEffect(() => {
    getRelationships();
    getExam();
  }, []);

  const createExamForm = useFormik({
    initialValues: {
      'able-to-retry': false,
      title: '',
      resolutionTime: '',
      visibility: 'exam',
      'course-ids': [],
      'classroom-ids': [],
      'learning-system-ids': [],
      evaluationStart: {
        date: '',
        hour: '00',
        minute: '00'
      },
      evaluationEnd: {
        date: '',
        hour: '00',
        minute: '00'
      },
      description: '',
      passingScore: '',
      correctionFactor: '',
      selectedTaxonomies: [],
      published: false,
      association: association,
      official_content: false,
      authored_by_user: false
    },
    validationSchema: yup.object({
      'course-ids': yup.array().min(1, t('warning.requiredField')).nullable(),
      'classroom-ids': yup.array().when('association', {
        is: t('exams.toggleClass'),
        then: yup.array().min(1, t('warning.requiredField')).nullable()
      }),
      'learning-system-ids': yup.array().when('association', {
        is: t('exams.toggleModule'),
        then: yup.array().min(1, t('warning.requiredField')).required()
      }),
      title: yup.string().required(t('warning.requiredField')),
      selectedTaxonomies: yup.array().min(1, t('warning.selectTaxonomy')),
      description: yup.string().required(t('warning.requiredField')),
      resolutionTime: yup.string().required(t('warning.requiredField')),
      evaluationStart: yup.object({
        date: yup.string().required(t('warning.requiredField')),
        hour: yup.string().required(t('warning.requiredField')),
        minute: yup.string().required(t('warning.requiredField'))
      }),
      evaluationEnd: yup.object({
        date: yup.string().required(t('warning.requiredField')),
        hour: yup.string().required(t('warning.requiredField')),
        minute: yup.string().required(t('warning.requiredField'))
      }),
      passingScore: yup.string().required(t('warning.requiredField')),
      correctionFactor: yup.string().required(t('warning.requiredField'))
    }),
    onSubmit: () => handleSubmit
  });

  useEffect(() => {
    if (!classroomsFetched && createExamForm.values['course-ids'].length > 0 && selectedInstitutions.length > 0) {
      getClassrooms();
      setClassroomsFetched(true);
    }
  }, [createExamForm.values['course-ids'], selectedInstitutions, classroomsFetched]);

  useEffect(() => {
    createExamForm.setFieldValue('association', association);
    if (association === t('exams.toggleClass') && !examId) {
      createExamForm.setValues({
        title: '',
        resolutionTime: '',
        visibility: 'exam',
        'course-ids': createExamForm.values['course-ids'],
        'classroom-ids': [],
        evaluationStart: {
          date: '',
          hour: '00',
          minute: '00'
        },
        evaluationEnd: {
          date: '',
          hour: '00',
          minute: '00'
        },
        description: '',
        passingScore: '',
        correctionFactor: '',
        selectedTaxonomies: [],
        published: false,
        association: association,
        official_content: false,
        authored_by_user: false
      });
    }
    if (association === t('exams.toggleModule') && !examId) {
      createExamForm.setValues({
        title: '',
        resolutionTime: '',
        visibility: 'exam',
        'course-ids': createExamForm.values['course-ids'],
        'learning-system-ids': [],
        evaluationStart: {
          date: '',
          hour: '00',
          minute: '00'
        },
        evaluationEnd: {
          date: '',
          hour: '00',
          minute: '00'
        },
        description: '',
        passingScore: '',
        correctionFactor: '',
        selectedTaxonomies: [],
        published: false,
        association: association,
        official_content: false,
        authored_by_user: false
      });
    }
  }, [association]);

  const { values, setFieldValue, errors, touched } = createExamForm;

  useEffect(() => {
    if (exam) {
      getClassrooms();
      setClassroomsFetched(true);
      createExamForm.setFieldValue('title', exam.title);
      createExamForm.setFieldValue('resolutionTime', exam?.['resolution-time']);
      createExamForm.setFieldValue('visibility', 'exam');
      createExamForm.setFieldValue('able-to-retry', exam?.['able-to-retry']);
      createExamForm.setFieldValue('evaluationStart', { date: moment(exam?.evaluationStart.date).format('YYYY-MM-DD'), hour: parseInt(exam?.evaluationStart?.hour), minute: parseInt(exam?.evaluationStart?.minute) });
      createExamForm.setFieldValue('evaluationEnd', { date: moment(exam?.evaluationEnd.date).format('YYYY-MM-DD'), hour: parseInt(exam?.evaluationEnd?.hour), minute: parseInt(exam?.evaluationEnd?.minute) });
      createExamForm.setFieldValue('description', exam.description);
      createExamForm.setFieldValue('passingScore', exam?.['passing-score']);
      createExamForm.setFieldValue('correctionFactor', exam?.['correction-factor']);
      createExamForm.setFieldValue('selectedTaxonomies', exam?.taxonomies);
      createExamForm.setFieldValue('published', exam?.published);
      createExamForm.setFieldValue('association', association);
      createExamForm.setFieldValue('official_content', exam?.['official-content']);
      createExamForm.setFieldValue('authored_by_user', exam?.['authored-by-user']);
    }
  }, [exam]);

  const canManageExams = getPermission('Gerenciar provas', 'Provas e simulados');

  const createExam = async () => {
    const auxValues = { ...createExamForm.values };

    if (auxValues['learning-system-ids']) {
      auxValues['learning-system-ids'] = auxValues['learning-system-ids'].map(item => item.value);
    }

    auxValues['taxonomy-ids'] = auxValues?.selectedTaxonomies.map(t => t.id);
    auxValues['learning-system-ids'] = Array.isArray(auxValues['learning-system-ids']) ? auxValues['learning-system-ids'] : [auxValues['learning-system-ids']];
    auxValues['course-ids'] = Array.isArray(auxValues['course-ids']) ? auxValues['course-ids'] : [auxValues['course-ids']];

    if (auxValues['classroom-ids']?.length !== 0) {
      auxValues['classroom-ids'] = auxValues['classroom-ids'].map(item => item.value);
      const initialDate = auxValues.evaluationStart.date;
      const initialTime = `${auxValues.evaluationStart.hour}:${auxValues.evaluationStart.minute} `;
      auxValues['published-at'] = `${initialDate} ${initialTime}`;

      const finishDate = auxValues.evaluationEnd.date;
      const finishTime = `${auxValues.evaluationEnd.hour}:${auxValues.evaluationEnd.minute}`;
      auxValues['finished-at'] = `${finishDate} ${finishTime}`;
    } else {
      auxValues['published-at'] = null;
      auxValues['finished-at'] = null;
    }

    delete auxValues.selectedTaxonomies;
    delete auxValues.evaluationStart;
    delete auxValues.evaluationEnd;
    delete auxValues.association;
    delete auxValues['course-ids'];

    const path = examId ? ['question_books', examId] : 'question_books';
    const { data, error } = await client.mutate(path, auxValues);
    if (error) {
      if (!examId) {
        toast.error(t('toast.errorCreateExam'));
      } else {
        toast.error(t('toast.errorEditExam'));
      }
    } else {
      if (!examId) {
        toast.success(t('toast.successCreateExam'));
        if (canManageExams) {
          navigate(`/provas/gerenciar/${data.id}`, { state: { activeTabs: 'provas' } });
        } else {
          navigate('/avaliacoes/provas');
        }
      } else {
        toast.success(t('toast.successEditExam'));
        if (canManageExams) {
          navigate(`/provas/gerenciar/${data.id}`, { state: { activeTabs: 'provas' } });
        } else {
          navigate('/avaliacoes/provas');
        }
      }
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    switch (step) {
      case 'curso':
        if (examId) {
          createExamForm.setFieldTouched('course-ids', true);
          createExamForm.setFieldTouched('classroom-ids', true);
          createExamForm.setFieldTouched('learning-system-ids', true);
          if (association === t('exams.toggleModule') ? !createExamForm.errors['learning-system-ids'] : !createExamForm.errors['course-ids'] && !createExamForm.errors['classroom-ids']) {
            navigate(`/provas/criar/nome/${examId}`);
            setCurrentStep(1);
          }
          break;
        } else {
          createExamForm.setFieldTouched('course-ids', true);
          createExamForm.setFieldTouched('classroom-ids', true);
          createExamForm.setFieldTouched('learning-system-ids', true);
          if (association === t('exams.toggleModule') ? !createExamForm.errors['learning-system-ids'] : !createExamForm.errors['course-ids'] && !createExamForm.errors['classroom-ids']) {
            navigate(`/provas/criar/nome`);
            setCurrentStep(1);
          }
          break;
        }
      case 'nome':
        if (examId) {
          createExamForm.setFieldTouched('title', true);
          createExamForm.setFieldTouched('description', true);
          createExamForm.setFieldTouched('selectedTaxonomies', true);
          if (!createExamForm.errors.title && !createExamForm.errors.description && !createExamForm.errors.selectedTaxonomies) {
            navigate(`/provas/criar/duracao/${examId}`);
            setCurrentStep(2);
          }
          break;
        } else {
          createExamForm.setFieldTouched('title', true);
          createExamForm.setFieldTouched('description', true);
          createExamForm.setFieldTouched('selectedTaxonomies', true);
          if (!createExamForm.errors.title && !createExamForm.errors.description && !createExamForm.errors.selectedTaxonomies) {
            navigate(`/provas/criar/duracao`);
            setCurrentStep(2);
          }
          break;
        }
      case 'duracao':
        if (examId) {
          createExamForm.setFieldTouched('resolutionTime', true);
          createExamForm.setFieldTouched('evaluationStart', true);
          createExamForm.setFieldTouched('evaluationStart.date', true);
          createExamForm.setFieldTouched('evaluationStart.hour', true);
          createExamForm.setFieldTouched('evaluationStart.minute', true);
          createExamForm.setFieldTouched('evaluationEnd', true);
          createExamForm.setFieldTouched('evaluationEnd.date', true);
          createExamForm.setFieldTouched('evaluationEnd.hour', true);
          createExamForm.setFieldTouched('evaluationEnd.minute', true);
          createExamForm.setFieldTouched('passingScore', true);
          createExamForm.setFieldTouched('correctionFactor', true);

          const hasModule = (createExamForm?.values?.['learning-system-ids']?.length || 0) > 0;

          if (!createExamForm.errors.resolutionTime && (hasModule || (!createExamForm.errors.evaluationStart && !createExamForm.errors.evaluationEnd))) {
            createExam();
          }
          break;
        } else {
          createExamForm.setFieldTouched('resolutionTime', true);
          createExamForm.setFieldTouched('evaluationStart', true);
          createExamForm.setFieldTouched('evaluationStart.date', true);
          createExamForm.setFieldTouched('evaluationStart.hour', true);
          createExamForm.setFieldTouched('evaluationStart.minute', true);
          createExamForm.setFieldTouched('evaluationEnd', true);
          createExamForm.setFieldTouched('evaluationEnd.date', true);
          createExamForm.setFieldTouched('evaluationEnd.hour', true);
          createExamForm.setFieldTouched('evaluationEnd.minute', true);
          createExamForm.setFieldTouched('passingScore', true);
          createExamForm.setFieldTouched('correctionFactor', true);
          const hasModule = (createExamForm?.values?.['learning-system-ids']?.length || 0) > 0;

          if (!createExamForm.errors.resolutionTime && (hasModule || (!createExamForm.errors.evaluationStart && !createExamForm.errors.evaluationEnd))) {
            createExam();
          }
          break;
        }
    }
  };

  const btnsStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  };

  return (
    <div className="page page--wrap">
      <div className="page__col">
        <form
          className="form form--step-by-step"
          method="post"
          onSubmit={handleSubmit}
        >
          <StepCourse
            loadingModules={loadingModules}
            loadingExam={loadingExam}
            examId={examId}
            exam={exam}
            loadingCourses={loadingCourses}
            loadingClassrooms={loadingClassrooms}
            loadingInstitutions={loadingInstitutions}
            institutions={institutions}
            association={association}
            setAssociation={setAssociation}
            values={values}
            touched={touched}
            courses={courses}
            classrooms={classrooms}
            modules={modules}
            step={step}
            form={createExamForm}
            selectedInstitutions={selectedInstitutions}
            setSelectedInstitutions={setSelectedInstitutions}
          />
          <StepName
            values={values}
            touched={touched}
            exam={exam}
            step={step}
            form={createExamForm}
          />
          <StepDuration
            values={values}
            touched={touched}
            exam={exam}
            step={step}
            form={createExamForm}
          />

          <div style={btnsStyles}>
            {step !== 'curso' && (
              <button
                type="button"
                className="btn btn--outline btn--wide"
                onClick={() => {
                  navigate(examId ? `/provas/criar/${steps?.[currentStep - 1]?.route}/${examId}` : `/provas/criar/${steps?.[currentStep - 1]?.route}`);
                  setCurrentStep(currentStep - 1);
                }}
              >
                {t('exams.previous')}
              </button>
            )}

            <button
              type="submit"
              className="btn btn--primary btn--wide"
            >
              {step !== 'duracao' ? t('button.next') : t('button.finish')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
