import React, { useState } from 'react';
import { ReactComponent as Star } from 'app/images/icons/fi-star.svg';

export default function StarRating(props) {
  const {rating, changeRating, hover, setHover, data } = props

  return (
    <div className="star-rating">
      {(data.items || data['survey-items']).map((item) => {
        return (
          <button
            type="button"
            key={item.id}
            className={
              item.rate <= (hover || rating)
                ? 'star-rating__btn on'
                : 'star-rating__btn off'
            }
            onClick={() => changeRating(item)}
            onMouseEnter={() => setHover(item.rate)}
            onMouseLeave={() => setHover(rating)}
          >
            <Star className="star-rating__star" />
          </button>
        );
      })}
    </div>
  );
}
