import React from 'react';
import styles from './CompatibilityCard.module.scss';
import Avatar from 'app/components/Avatar';

function CompatibilityCardWrapper(props) {
  const { children } = props;

  return <div className={styles.wrapper}>{children}</div>;
}

function CompatibilityCard(props) {
  const { setShowModal } = props;

  return (
    <div
      className={styles.card}
      onClick={() => setShowModal(true)}
    >
      <span className={`${styles.tag} square-tag square-tag--success`}>98%</span>

      <Avatar
        src={null}
        style={{ width: 80, height: 80 }}
      />

      <div className={styles.header}>
        <h3
          className={`${styles.title} u-text-truncate`}
          title={'Felipe Brandão de Oliveira'}
        >
          Felipe Brandão de Oliveira
        </h3>
      </div>

      <div className={styles.footer}>
        <button
          type="button"
          className="btn btn--small btn--link"
        >
          Mais detalhes
        </button>
      </div>
    </div>
  );
}

export { CompatibilityCard as default, CompatibilityCardWrapper };
