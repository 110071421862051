import { t } from 'i18next';
import React, { useState } from 'react';
import { FiCheckCircle } from 'react-icons/fi';

export default function MatterCard(props) {
  const { matter, isSelected, addMatter, removeMatter, addedMatters, showModal, countType } = props;
  const isExamOrOpen = countType === 'exam' || countType === 'open';
  const questionCount = isExamOrOpen 
  ? (matter?.['question-count-type']?.[countType] || 0) + (matter?.['question-count-type']?.['train'] || 0) + (matter?.['question-count-type']?.['exam_and_mock'] || 0) 
  : (matter?.['question-count-type']?.[countType] || 0) + (matter?.['question-count-type']?.['train'] || 0);

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <div className="card__header u-mb-0">
          <div className="card__inner-header">
            <div className="card__icon-wrapper">
              <FiCheckCircle className="card__icon" />
            </div>
            <h3 className="card__title">{matter.name}</h3>
          </div>
        </div>
      </th>
      <td
        className="round-dg__cell"
        style={{ width: '132px' }}
      >
        {questionCount} {t('exams.questions')}
      </td>
      <td
        className="round-dg__cell"
        style={{ width: '140px' }}
      >
        <button
          type="button"
          className={`btn btn--full ${isSelected ? 'btn--primary' : 'btn--outline'}`}
          onClick={() => {
            if (!isSelected) {
              addMatter(matter);
              if (showModal) {
                addedMatters(matter);
              }
            } else {
              removeMatter(matter);
            }
          }}
        >
          {isSelected ? t('button.remove') : t('button.add')}
        </button>
      </td>
    </tr>
  );
}
