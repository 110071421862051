import React from 'react';

function Notebook() {
  return (
    <nav>
      <div className="simulated__questions">
        <div className="simulated__questions-name" title="Crimes contra a vida">
          Crimes contra a vida
        </div>
        <div className="simulated__count">4/10</div>
      </div>
    </nav>
  );
}

export default Notebook;
