import React, { useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, navigate } from '@reach/router';
import InfoWidget from 'app/components/Widget/InfoWidget';
import userAvatar from 'app/images/user-avatar.svg';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import 'moment/locale/pt';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'app/components/loader';
import EndScroll from 'app/components/endScroll';

export default function QuizDataOpen(props) {
  const { t } = useTranslation();
  const { id } = props;
  const client = useClient();
  const [loading, setLoading] = useState(true);
  const [surveyData, setSurveyData] = useState();
  const [studentData, setStudentData] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const isHeightLarge = window.innerHeight >= 1060;

  const getSurveyData = async () => {
    try {
      setLoading(true);
      const { data } = await client.fetch(['surveys', id]);
      setSurveyData(data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      toast.error('Erro ao buscar dados do questionário.');
    }
  };

  const getStudentData = async () => {
    try {
      setLoading(true);
      const { data } = await client.fetch(`survey_answers?page[number]=1&page[size]=${isHeightLarge ? '15' : '10'}?filter[survey_id]=${id}`);
      setStudentData(data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      toast.error('Erro ao buscar respostas.');
    }
  };

  const updateStudentData = async () => {
    try {
      const { data } = await client.fetch(`survey_answers?page[number]=${pageNumber}&page[size]=10?filter[survey_id]=${id}`);
      if (data.length === 0) {
        setHasMore(false);
      }

      setStudentData(oldState => [...oldState, ...data]);
    } catch (e) {
      toast.error('Erro ao buscar respostas.');
    }
  };

  useEffect(() => {
    getSurveyData();
    getStudentData();
  }, []);

  useEffect(() => {
    if (pageNumber !== 1) {
      updateStudentData();
    }
  }, [pageNumber]);

  return (
    <main className="main-content main-content--block fadeIn">
      <div className="filter-bar">
        <button
          onClick={() => navigate(-1)}
          className="filter-bar__back"
        >
          <span className="card__icon-wrapper">
            <FiChevronLeft className="card__icon" />
          </span>

          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{t('textsCommon.data')}</span>
          </span>
        </button>
      </div>

      <div className="chart-widget fadeIn u-mb-3">
        <div
          className="chart-widget__container u-mb-4"
          style={{ alignItems: 'center' }}
        >
          <div
            className="chart-widget__group"
            style={{ gap: '0' }}
          >
            <div className="chart-widget__meta">
              {surveyData?.about?.status === 'Ativo' ? (
                <span className={`activity-card__status activity-card__status--success u-mr-2`}>Ativo</span>
              ) : (
                <span className={`activity-card__status activity-card__status--danger u-mr-2`}>Inativo</span>
              )}
            </div>
            <h3 className="chart-widget__title chart-widget__title--lg">{surveyData?.title}</h3>
            <span className="activity-card__scale">Resposta aberta</span>
          </div>
          <div className="widget-wrapper widget-wrapper--sm-space">
            <InfoWidget
              title={t('nps.totalResponses')}
              className="info-widget--light"
            >
              <InfoWidget.Value>{surveyData?.about?.['answers-count']}</InfoWidget.Value>
            </InfoWidget>

            <InfoWidget
              title={t('nps.totalQuestions')}
              className="info-widget--light"
            >
              <InfoWidget.Value>{surveyData?.about?.['questions-count']}</InfoWidget.Value>
            </InfoWidget>
          </div>
        </div>

        <div className="round-dg-wrapper">
          {loading && <Loader />}

          {studentData && !loading && (
            <InfiniteScroll
              dataLength={studentData?.length}
              next={() => setPageNumber(pageNumber + 1)}
              hasMore={hasMore}
              loader={<Loader />}
              // endMessage={<EndScroll />}
            >
              <table className="round-dg round-dg--light">
                <thead className="round-dg__header">
                  <tr className="round-dg__row">
                    <th className="round-dg__cell-header">{t('exams.thParticipant')}</th>
                    <th className="round-dg__cell-header">{t('exams.thCourse')}</th>
                    <th className="round-dg__cell-header">{t('textsCommon.class')}</th>
                    <th className="round-dg__cell-header">{t('textsCommon.dateResponse')}</th>
                    <th className="round-dg__cell-header" />
                  </tr>
                </thead>

                <tbody className="round-dg__body">
                  {studentData?.map((student, index) => (
                    <tr
                      className="round-dg__row"
                      key={index}
                    >
                      <th className="round-dg__cell-header round-dg__cell-header--row">
                        <div className="round-dg__user">
                          <img
                            className="avatar avatar--sm"
                            src={student?.user?.image?.url || userAvatar}
                            alt={student.user.name}
                          />

                          <div className="round-dg__user-inner">
                            <h3
                              className="round-dg__title"
                              title={student.user.name}
                            >
                              {student.user.name}
                            </h3>
                            {/* <p className="round-dg__subtitle">email</p> */}
                          </div>
                        </div>
                      </th>
                      <td className="round-dg__cell">
                        {student.user.courses.length > 0 && (
                          <>
                            {`${student.user.courses[0]} `}
                            {student.user.courses.length > 1 && (
                              <span
                                className="badge badge--tiny badge--more"
                                title={student.user.courses.map(course => course)}
                                style={{ verticalAlign: 'sub' }}
                              >
                                +{student.user.courses.length - 1}
                              </span>
                            )}
                          </>
                        )}
                      </td>
                      <td className="round-dg__cell">
                        {student.user.classrooms.length > 0 && (
                          <>
                            {`${student.user.classrooms[0]} `}
                            {student.user.classrooms.length > 1 && (
                              <span
                                className="badge badge--tiny badge--more"
                                title={student.user.classrooms.map(classroom => classroom)}
                                style={{ verticalAlign: 'sub' }}
                              >
                                +{student.user.classrooms.length - 1}
                              </span>
                            )}
                          </>
                        )}
                      </td>
                      <td className="round-dg__cell">{moment(student['updated-at']).format(t('datesForm.formatDate'))}</td>
                      <td
                        className="round-dg__cell round-dg__cell--no-wrap"
                        style={{ width: '150px' }}
                      >
                        <Link
                          to={`/questionario/aberto/${id}/estudante/${student.id}`}
                          className="btn btn--outline btn--full"
                        >
                          Ver respostas
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </InfiniteScroll>
          )}

          {surveyData?.about && surveyData?.about['answers-count'] === 0 && (
            <EmptyState
              type="content"
              text="Sem respostas por enquanto."
            />
          )}
        </div>
      </div>
    </main>
  );
}
