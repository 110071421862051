import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useSession } from 'app/hooks/useSession';
import { useTranslation } from 'react-i18next';
import { CourseOnboarding } from './Course';
import { FeedOnboarding } from './Feed';
import { DashboardOnboarding } from './Dashboard';
import { AvaliationsOnboarding } from './Avaliations';
import { QuestionsOnboarding } from './Questions';
import { JourneyOnboarding } from './Journey';
import { AuditoriumOnboarding } from './Auditorium';
import { StudyPlanOnboarding } from './StudyPlan';
import { ProfileOnboarding } from './Profile';
import { MyTasksOnboarding } from './MyTasks';
import { ProjectsOnboarding } from './Projects';
import { MockQuestionBook } from './MockQuestionbook';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import homeIcon from '../../images/onboarding/dashboard/homeIcon.png';
import iconStudyPlan from '../../images/onboarding/studyPlan/iconStudyPlan.png';
import journeyIcon from '../../images/onboarding/journey/journeyIcon.png';
import courseIcon from '../../images/onboarding/courses/courseIcon.png';
import avaliationsIcon from '../../images/onboarding/avaliations/avaliationsIcon.png';
import projectIcon from '../../images/onboarding/projects/projectIcon.png';
import questionsIcon from '../../images/onboarding/questions/questionsIcon.png';
import mockQuestionIcon from '../../images/onboarding/mockQuestion/mockQuestionIcon.png';
import monitoringIcon from '../../images/onboarding/auditorium/monitoringIcon.png';
import forumIcon from '../../images/onboarding/feed/forumIcon.png';
import profileIcon from '../../images/onboarding/profile/profileIcon.png';

export default function Onboarding(props) {
  const { t } = useTranslation();
  const { show, setShowOnboarding, onClose, profiles, title, path } = props;
  const client = useClient();
  const [display, setDisplay] = useState(false);
  const { session, sanitizeResponse, setSession } = useSession();
  const studentId = session?.user?.id;

  useEffect(() => {
    const userDetail = session?.user?.userDetail;
    if (userDetail) {
      setDisplay(!userDetail[activeRoute()?.route]);
      setShowOnboarding(userDetail[activeRoute()?.route]);
    }
  }, [session]);

  function activeRoute() {
    switch (path) {
      case '/':
        return { route: 'showTutorialDashboard', image: homeIcon };
      case '/feed':
        return { route: 'showTutorialFeed', image: forumIcon };
      case '/minhas-tarefas':
        return 'showTutorialTasks';
      case '/lms':
        return 'showTutorialClasses';
      case '/materiais':
        return 'showTutorialMaterials';
      case '/projetos':
        return { route: 'showTutorialProjects', image: projectIcon };
      case '/atividade-complementar':
        return 'showTutorialComplementaryActivities';
      case '/metricas-gerais':
        return 'showTutorialGeneralMetrics';
      case '/curso':
        return { route: 'showTutorialCourses', image: courseIcon };
      case '/usuario/:selectedTab/:id':
        return { route: 'showTutorialJourney', image: journeyIcon };
      case '/avaliacoes':
        return { route: 'showrTutorialQuestionBooks', image: avaliationsIcon };
      case '/simulados':
        return { route: 'showTutorialMockQuestionBooks', image: mockQuestionIcon };
      case '/avaliacoes/meus-resultados':
        return 'showTutorialQuestionBooks';
      case '/questions/:selectedTab':
        return { route: 'showTutorialQuestions', image: questionsIcon };
      case '/auditorio':
        return { route: 'showTutorialAuditoriums', image: monitoringIcon };
      case '/plano-de-estudos':
        return { route: 'showTutorialStudyPlans', image: iconStudyPlan };
      case '/profile':
        return { route: 'showTutorialProfiles', image: profileIcon };
      case '/usuario/:selectedTab/:id':
        return 'showTutorialUsers';
    }
  }

  const showTutorial = async notDisplay => {
    const { error, data } = await client.mutate(`/users/${studentId}`, {
      'user-detail-attributes': {
        id: session.user.userDetail.id,
        [activeRoute()
          ?.route?.replace(/([a-z])([A-Z])/g, '$1-$2')
          .toLowerCase()]: !notDisplay
      }
    });
    if (error) {
      toast.error('Erro');
    } else {
      const newObject = sanitizeResponse(data);
      setSession({ user: newObject });
    }
  };

  const isOpen = profiles.includes('Aluno') && show;

  function handleClose() {
    setShowOnboarding(!show);
    showTutorial(!display);
    setDisplay(!display);
  }

  if (!profiles.includes('Aluno')) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="onboarding">
      <div className={isOpen ? 'onboarding__inner onboarding__inner--open' : 'onboarding__inner onboarding__inner--close'}>
        <button
          className="onboarding__toggle"
          onClick={handleClose}
        >
          Como funciona? {isOpen ? <FiChevronDown /> : <FiChevronUp />}
        </button>

        <div className="onboarding__content">
          <div className="card__header">
            <div
              className="card__inner-header header-line"
              style={{ gap: 6 }}
            >
              <img
                style={{ width: 28, marginBottom: 5 }}
                src={activeRoute()?.image}
              />
              <h3 className="card__title">{title}</h3>
            </div>
          </div>

          {path === '/' && <DashboardOnboarding />}

          {path === '/feed' && <FeedOnboarding />}

          {path === '/curso' && <CourseOnboarding />}

          {path === '/minhas-tarefas' && <MyTasksOnboarding />}

          {path === '/avaliacoes' && <AvaliationsOnboarding />}
          {path === '/simulados' && <MockQuestionBook />}
          {path === '/avaliacoes/meus-resultados' && <AvaliationsOnboarding />}

          {path === '/questions/:selectedTab' && <QuestionsOnboarding />}

          {path === '/projetos' && <ProjectsOnboarding />}

          {path === '/usuario/:selectedTab/:id' && <JourneyOnboarding />}

          {path === '/auditorio' && <AuditoriumOnboarding />}

          {path === '/plano-de-estudos' && <StudyPlanOnboarding />}

          {path === '/profile' && <ProfileOnboarding />}

          {/**
            {videoId && (
              <figure className="u-ratio u-ratio--16x9">
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${videoId || 'eHrcRqu_Es4'}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </figure>
            )}
          */}
        </div>
      </div>

      {isOpen && (
        <div
          className="onboarding__backdrop"
          onClick={handleClose}
        />
      )}
    </div>,
    document.getElementById('modal_root')
  );
}
