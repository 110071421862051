import { navigate, useLocation } from '@reach/router';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import { t } from 'i18next';
import React from 'react';

function TabNavBar({ currentModuleId, courseId, selectedTab }) {
  const { abilities } = usePlatformAbilities();

  return (
    <div className="tab">
      <button
        className={selectedTab === 'disciplinas-e-aulas' ? 'tab__link active' : 'tab__link'}
        onClick={() => navigate(`/curso/admin/gerenciar/${courseId}/${currentModuleId}/disciplinas-e-aulas/d`)}
      >
        {t('courses.toggleDisciplineLessons')}
      </button>

      {abilities?.['cursos-ativ-complementar'] && (
        <button
          className={selectedTab === 'atividades' ? 'tab__link active' : 'tab__link'}
          onClick={() => {
            navigate(`/curso/admin/gerenciar/${courseId}/${currentModuleId}/atividades/d`);
            // window.history.pushState({ moduleId: currentModuleId }, '', `/curso/admin/gerenciar/${courseId}/${currentModuleId}`);
          }}
        >
          {t('courses.toggleActivity')}
        </button>
      )}

      {abilities?.['cursos-materiais'] && (
        <button
          className={selectedTab === 'materiais' ? 'tab__link active' : 'tab__link'}
          onClick={() => navigate(`/curso/admin/gerenciar/${courseId}/${currentModuleId}/materiais/d`)}
        >
          {t('courses.toggleMaterials')}
        </button>
      )}

      {abilities?.['avaliacoes-apostilas'] && (
        <button
          className={selectedTab === 'apostilas' ? 'tab__link active' : 'tab__link'}
          onClick={() => navigate(`/curso/admin/gerenciar/${courseId}/${currentModuleId}/apostilas/d`)}
        >
          {t('courses.toggleQuestionBooks')}
        </button>
      )}
    </div>
  );
}

export default TabNavBar;
