import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { useFormik } from 'formik';
import { useSession } from '../../hooks/useSession';
import { FiPlus, FiX } from 'react-icons/fi';
import ModalTaxonomies from '../../components/modalTaxonomies';
import TextareaAutosize from 'react-autosize-textarea';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import * as yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function ActivityForm(props) {
  const { t } = useTranslation();
  const { onClose, editingActivity, activities, setActivities } = props;
  const client = useClient();
  const { session } = useSession();
  const profiles = session.user?.profileNames || (session.user && session.user['profile-names']) || [];
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);
  const [firstLevelTaxonomies, setFirstLevelTaxonomies] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const activityAlternatives = [
    { id: 2, name: t('textsCommon.class') },
    { id: 0, name: t('events.togglePublic') },
    { id: 1, name: t('events.toggleCourse') }
  ];
  const [selectedAlternative, setSelectedAlternative] = useState(activityAlternatives[0]?.id);
  const [relationshipsLoading, setRelationshipsLoading] = useState(true);
  const [relationships, setRelationships] = useState({
    courses: [],
    classrooms: [],
    contents: []
  });

  const hours = [0.5, 1.0, 1.5, 2.0, 2.5, 3.0];
  const defaultFileType = editingActivity && editingActivity['activity-file']?.url ? 'URL' : t('activity.toggleFile');
  const [fileType, setFileType] = useState(defaultFileType);
  const [selectedCourse, setSelectedCourse] = useState(true);
  const [selectedClassroom, setSelectedClassroom] = useState(true);

  const form = useFormik({
    initialValues: {
      title: editingActivity?.title || '',
      description: editingActivity?.description || '',
      modulus: editingActivity?.modulus || '',
      unit: editingActivity?.unit || '',
      file: null,
      url: (editingActivity && editingActivity['activity-file']?.url) || '',
      selectedTaxonomies: editingActivity?.taxonomies || [],
      'course-id': editingActivity ? editingActivity['course-id'] : null,
      'classroom-id': editingActivity ? editingActivity['classroom-id'] : null,
      'class-hours': (editingActivity && editingActivity['class-hours']) || 0.5,
      'publish-at': (editingActivity && editingActivity['publish-at']) || new Date()
      // 'finish-at': (editingActivity && editingActivity['finish-at']) || new Date()
    },
    validationSchema: yup.object({
      'course-id': yup
        .string()
        .nullable()
        .when('selectedAlternative', {
          is: () => selectedAlternative === 1,
          then: yup
            .string()
            .nullable()
            .test('test', t('warning.requiredField'), value => value && value !== '0'),
          otherwise: yup.string().nullable().notRequired()
        }),
      'classroom-id': yup
        .string()
        .nullable()
        .when('selectedAlternative', {
          is: () => selectedAlternative === 2,
          then: yup
            .string()
            .nullable()
            .test('test', t('warning.requiredField'), value => value && value !== '0'),
          otherwise: yup.string().nullable().notRequired()
        })
    }),
    onSubmit: values => submitForm(values)
  });

  const fetchTaxonomies = async () => {
    const { data, error } = await client.fetch('taxonomies');

    if (error) {
      toast.error(t('toast.errorGetFirstLvlTaxonomy'));
    } else {
      setFirstLevelTaxonomies(data);
    }
  };

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses');
    const { data: classrooms } = await client.fetch('classrooms');

    setRelationships({
      courses: courses || [],
      classrooms: classrooms || []
    });
    setRelationshipsLoading(false);
  };

  useEffect(() => {
    fetchTaxonomies();
    getRelationships();
  }, []);

  useEffect(() => {
    if (editingActivity) {
      if (editingActivity['course-id']) {
        setSelectedAlternative(1);
      } else if (editingActivity['classroom-id']) {
        setSelectedAlternative(2);
      }
    }
  }, [editingActivity]);

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const submitForm = async values => {
    if (isSubmitting) {
      return false;
    }
    setIsSubmitting(true);

    const parsedForm = {
      ...values,
      file: undefined,
      url: undefined,
      'taxonomy-ids': values.selectedTaxonomies.map(t => t.id),
      selectedTaxonomies: undefined,
      'activity-file-attributes': { url: null, file: null }
    };

    if (values.file && values.file !== '') {
      parsedForm['activity-file-attributes'].file = await toBase64(values.file);
    }

    if (values.url && values.url !== '') {
      parsedForm['activity-file-attributes'].url = values.url;
    }

    if (editingActivity?.id) {
      parsedForm['activity-file-attributes'].id = editingActivity['activity-file']?.id;
    }

    if (editingActivity?.id) {
      const { data, error } = await client.mutate(['activities', editingActivity.id], parsedForm);
      if (error) {
        toast.error(`${error.id} ${error.title}`);
        setIsSubmitting(false);
      } else {
        toast.success(t('toast.successEditComplementaryActivity'));
        const newActivities = activities.map(e => (e.id === editingActivity.id ? data : e));
        setActivities(newActivities);
        setIsSubmitting(false);
        onClose();
      }
    } else {
      const { data, error } = await client.mutate('activities', parsedForm);
      if (error) {
        toast.error(`${error.id} ${error.title}`);
        setIsSubmitting(false);
      } else {
        toast.success(t('toast.successCreateComplementaryActivity'));
        const newActivities = [...activities, data];
        setActivities(newActivities);
        setIsSubmitting(false);
        onClose();
      }
    }
  };

  const today = moment().format(t('datesForm.formatDate'));

  return (
    <form
      method="post"
      className="form"
      onSubmit={form.handleSubmit}
    >
      <h2 className="form__title">
        {editingActivity?.title ? t('activity.editingTitle') : t('activity.newTitle')} {t('activity.activityTitle')}
      </h2>

      <div className="form__row">
        <div
          className="btn-group"
          role="group"
          aria-label="Escolha o tipo de atividade complementar"
        >
          {activityAlternatives.map((alternative, index) => (
            <React.Fragment key={index}>
              <input
                type="radio"
                name="activityType"
                id={alternative.id}
                autoComplete="off"
                checked={alternative.id === selectedAlternative}
                onChange={() => {
                  if (alternative.name === 'Público') {
                    form.setFieldValue('course-id', null);
                    form.setFieldValue('classroom-id', null);
                    form.setFieldValue('is-public', true);
                  } else if (['Curso', 'Turma'].includes(alternative.name)) {
                    form.setFieldValue('is-public', false);
                  }
                  setSelectedAlternative(alternative.id);
                }}
              />
              <label
                htmlFor={alternative.id}
                className="btn btn--outline"
              >
                {alternative.name}
              </label>
            </React.Fragment>
          ))}
        </div>
      </div>

      {!relationshipsLoading && selectedAlternative === 1 && (
        <div className="form__row form__row--hr">
          <select
            className="form__select"
            name="courses"
            id="courses"
            aria-label="Escolha o curso"
            value={form.values['course-id']}
            onBlur={form.handleBlur}
            onChange={e => {
              setSelectedCourse(e.target.value == '0');
              form.setFieldValue('course-id', e.target.value);
              form.setFieldValue('classroom-id', undefined);
            }}
          >
            <option
              key={0}
              id={0}
              value={0}
            >
              {t('filter.labelSelectTheCourse')}
            </option>
            {relationships.courses.map(c => (
              <option
                key={c.id}
                value={c.id}
              >
                {c.title}
              </option>
            ))}
          </select>
          {form.touched['course-id'] && form.errors['course-id'] && selectedCourse && <span style={{ color: 'red' }}>{form.errors['course-id']}</span>}
        </div>
      )}

      {!relationshipsLoading && selectedAlternative === 2 && (
        <div className="form__row form__row--hr">
          <select
            className="form__select"
            name="classrooms"
            id="classrooms"
            aria-label="Escolha a turma"
            value={form.values['classroom-id']}
            onChange={e => {
              setSelectedClassroom(e.target.value == '0');
              form.setFieldValue('classroom-id', e.target.value);
              form.setFieldValue('course-id', undefined);
            }}
          >
            <option
              key={0}
              id={0}
              value={0}
            >
              {t('filter.labelSelectTheClassroom')}
            </option>
            {relationships.classrooms.map(c => (
              <option
                key={c.id}
                id={c.id}
                value={c.id}
              >
                {c.title}
              </option>
            ))}
          </select>
          {form.touched['classroom-id'] && form.errors['classroom-id'] && selectedClassroom && <span style={{ color: 'red' }}>{form.errors['classroom-id']}</span>}
        </div>
      )}
      <div className="form__row">
        <label
          className="form__label"
          htmlFor="published-at"
        >
          {t('nps.resolutionPeriod')}
        </label>

        <div className="form__columns">
          <div className="form__col">
            <input
              className="form__control"
              id="published-at"
              name="published-at"
              type="date"
              min={today}
              value={moment(form.values['publish-at']).format('YYYY-MM-DD')}
              onChange={e => form.setFieldValue('publish-at', e.target.value)}
            />
          </div>
          {/* <div className="form__col">
            <input
              className="form__control"
              id="finished-at"
              name="finished-at"
              type="date"
              min={today}
              value={moment(form.values['finish-at']).format('YYYY-MM-DD')}
              onChange={e => form.setFieldValue('finish-at', e.target.value)}
            />
          </div> */}
        </div>
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="title"
        >
          {t('textsCommon.title')}
        </label>
        <input
          className="form__control"
          id="title"
          name="title"
          type="text"
          placeholder={t('activity.placeholderActivityTitle')}
          onChange={form.handleChange}
          defaultValue={form.values.title}
        />
      </div>

      {/* <div className="form__row form__row--columns">
        <div className="form__col">
          <label htmlFor="modulus" className="form__label">
            Módulo
          </label>
          <input
            className="form__control"
            id="modulus"
            name="modulus"
            type="text"
            placeholder="Ex: 1"
            onChange={form.handleChange}
            defaultValue={form.values.modulus}
          />
        </div>

        <div className="form__col">
          <label htmlFor="unit" className="form__label">
            Unidade
          </label>
          <input
            className="form__control"
            id="unit"
            name="unit"
            type="text"
            placeholder="Ex: 1"
            onChange={form.handleChange}
            defaultValue={form.values.unit}
          />
        </div>

        <div className="form__col">
          <label htmlFor="hoursClasses" className="form__label">
            Horas/Aula
          </label>
          <select
            className="form__select"
            name="class-hours"
            id="class-hours"
            aria-label="Hora início"
            onChange={form.handleChange}
            defaultValue={form.values['class-hours']}
          >
            {hours.map((hour, i) => (
              <option key={i} value={hour}>
                {hour} hora
              </option>
            ))}
          </select>
        </div>
      </div> */}

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="description"
        >
          {t('tasks.description')}
        </label>

        <TextareaAutosize
          id="description"
          name="description"
          placeholder={t('activity.placeholderActivityDescription')}
          onChange={form.handleChange}
          defaultValue={form.values.description}
        />
      </div>

      <div
        className="btn-group"
        role="group"
        aria-label="Insira o material de apoio"
      >
        {[t('activity.toggleFile'), 'URL'].map((alternative, index) => (
          <React.Fragment key={index}>
            <input
              type="radio"
              name="fileType"
              autoComplete="off"
              id={`fileType-${index}`}
              checked={alternative === fileType}
              onChange={() => {
                if (alternative === t('activity.toggleFile')) {
                  form.setFieldValue('url', null);
                } else {
                  form.setFieldValue('file', null);
                }

                setFileType(alternative);
              }}
            />
            <label
              className="btn btn--outline"
              htmlFor={`fileType-${index}`}
            >
              {alternative}
            </label>
          </React.Fragment>
        ))}
      </div>

      {fileType === t('activity.toggleFile') && (
        <fieldset className="form__row">
          <label className="form__custom-file-input">
            <input
              type="file"
              name="file"
              id="file"
              onChange={e => form.setFieldValue('file', e.target.files[0])}
            />
            <span className="form__control">{form.values.file ? form.values.file?.name : t('activity.placeholderUploadFile')}</span>
          </label>
        </fieldset>
      )}

      {fileType === 'URL' && (
        <div className="form__row">
          <input
            className="form__control"
            id="url"
            name="url"
            type="url"
            placeholder={t('activity.placeholderSentUrl')}
            value={form.values.url}
            onChange={form.handleChange}
          />
        </div>
      )}

      <div className="form__row">
        <span className="form__label">{t('tasks.mattersRelated')}</span>

        <div className="multi-select">
          <div className="multi-select__value-container">
            {form.values.selectedTaxonomies.length === 0 && <span className="multi-select__placeholder">{t('tasks.mattersAdd')}</span>}

            {form.values.selectedTaxonomies.length > 0 &&
              form.values.selectedTaxonomies.map(t => (
                <span className="badge badge--primary">
                  {t.name}
                  <button
                    onClick={() => {
                      const newSelectedTaxonomies = form.values.selectedTaxonomies.filter(t2 => t.id !== t2.id);
                      form.setFieldValue('selectedTaxonomies', newSelectedTaxonomies);
                    }}
                    type="button"
                    className="badge__btn"
                  >
                    <FiX />
                  </button>
                </span>
              ))}
          </div>

          <button
            type="button"
            className="multi-select__indicator"
            onClick={() => setIsOpenModalTaxonomies(true)}
            title={form.values.selectedTaxonomies.length > 0 ? 'Editar matérias' : 'Adicionar matérias'}
            aria-label={form.values.selectedTaxonomies.length > 0 ? 'Editar matérias' : 'Adicionar matérias'}
          >
            <FiPlus />
          </button>
        </div>
      </div>

      <button
        className="btn btn--primary"
        type="submit"
      >
        {t('activity.buttonSaveActivity')}
      </button>

      {firstLevelTaxonomies.length > 0 && isOpenModalTaxonomies && (
        <ModalTaxonomies
          taxonomies={firstLevelTaxonomies}
          selectedTaxonomies={form.values.selectedTaxonomies}
          setFieldValue={form.setFieldValue}
          closeModal={() => setIsOpenModalTaxonomies(false)}
        />
      )}
    </form>
  );
}

export default ActivityForm;
