import Card from 'app/components/Card';
import { FiPlusCircle } from 'react-icons/fi';
import styles from './styles.module.scss';
import { statusBadgeText } from './utils/BadgeEssay';
import moment from 'moment';
import { navigate } from '@reach/router';
import { useSession } from 'app/hooks/useSession';

export default function Timeline({ submissions, activeSubmission, setActiveSubmission, essayId }) {
  const { session } = useSession();

  const validSubmissions = submissions?.filter(submission => submission.status !== 'minimum_length_not_satisfied');

  const checkScore = score => {
    if (score <= 333) {
      return 'danger';
    } else if (score > 333 && score <= 666) {
      return 'warning';
    } else if (score > 666) {
      return 'success';
    }
  };

  return (
    <>
      <div className={styles['timeline-header']}>
        <span className={styles['timeline-title']}>Envios</span>
        {/* Aguardar definição da regra de quantidade de envios */}
        <span>{validSubmissions?.length}/2</span>
      </div>
      <div className={styles['timeline']}>
        {validSubmissions?.map((item, i) => (
          <div
            className={`${styles['timeline-card']} ${activeSubmission === item?.id && styles['timeline-card--active']}`}
            onClick={() => {
              setActiveSubmission(item?.id);
              const link = session.user.profileNames.includes('Aluno') ? `/redacao/consulta/${essayId}/${item.id}` : `/redacao/correcao/${item.id}/${essayId}`;
              navigate(link);
            }}
          >
            <span className={styles['timeline-card-position']}>{item?.['submission_order']}º</span>
            <div className={styles['timeline-card-description']}>
              <span>
                <strong>{item?.['submission_order']}º envio</strong>
              </span>
              <span className="badge badge--tiny">{statusBadgeText[item?.status]}</span>
              <span>em {moment(item?.['submission_date']).format('DD/MM/YYYY')}</span>
            </div>
            <div className={styles['timeline-card-score']}>
              <span>
                <strong className={styles[`timeline-card-score--${checkScore(item.grade)}`]}>{item.status === 'annulled' ? 'Anulada' : item?.grade}</strong>pontos
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
