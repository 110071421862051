import React, { useState, useEffect } from 'react';
import BtnGroup from 'app/components/BtnGroup';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useClient } from 'jsonapi-react';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import { useSession } from 'app/hooks/useSession';

export default function StepCourse(props) {
  const { step, loadingExam, exam, form, courses, classrooms, modules, institutions, loadingInstitutions, loadingModules, association, setAssociation, loadingCourses, loadingClassrooms, selectedInstitutions, setSelectedInstitutions } = props;
  const { t } = useTranslation();
  const client = useClient();
  const animatedComponents = makeAnimated();
  const parsedSelectedCourses = form?.values?.['course-ids']?.map(item => parseInt(item.value));
  const isAdmin = checkIncludeProfiles({ only: ['admin'] });
  const { session } = useSession();

  useEffect(() => {
    //padroniza a resposta da API da chave de cursos para o padrão do react select.
    if (exam && courses) {
      const persistedCourses = courses?.filter(item => exam?.['course-ids']?.includes(item?.id)).map(item => ({ value: item?.id, label: item.title }));

      form.setFieldValue('course-ids', persistedCourses);
    }
  }, [courses, exam]);

  useEffect(() => {
    //padroniza a resposta da API da chave de instituições para o padrão do react select.
    if (exam && classrooms && institutions && courses) {
      const institutionsIds = classrooms?.filter(item => exam?.['classroom-ids']?.includes(item?.id)).map(item => item['institution-id']);

      const persistedInstitutions = institutions?.filter(item => institutionsIds.includes(item?.value)).map(item => ({ value: item?.value, label: item?.label }));
      setSelectedInstitutions(persistedInstitutions);

      const persistedClassrooms = classrooms
        ?.filter(item => exam?.['classroom-ids']?.includes(item.id))
        .map(item => {
          const institutionName = institutions.find(i => item?.['institution-id'] === i.value)?.label;
          const courseTitle = courses.find(c => c.id === item['course-id'])?.title;
          return institutionName && courseTitle
            ? {
                value: item.id,
                label: `${item.title} - ${institutionName} - ${courseTitle}`
              }
            : null;
        });
      form.setFieldValue('classroom-ids', persistedClassrooms);
    }
  }, [classrooms, exam, institutions, courses]);

  useEffect(() => {
    //padroniza a resposta da API da chave de módulos para o padrão do react select.
    if (modules && exam) {
      const persistedModules = modules
        ?.filter(module => exam['learning-system-ids'].includes(module?.id))
        .map(module => {
          const moduleCourse = courses.find(course => course?.id === module?.['course-id']);

          return { value: module.id, label: `${module.title} - ${moduleCourse?.title}` };
        });

      form.setFieldValue('learning-system-ids', persistedModules);
    }
  }, [modules, exam]);

  const filteredClassrooms = classrooms //filtra as opções de turmas de acordo com cursos e instituições escolhidos
    ?.filter(item => {
      return parsedSelectedCourses?.includes(item?.['course-id']) && selectedInstitutions?.map(item => item.value)?.includes(item?.['institution-id']);
    })
    ?.map(item => ({ value: item.id, label: `${item.title} - ${institutions.find(i => i.value === item['institution-id']).label} - ${courses.find(c => c.id === item['course-id']).title}` }));

  const filteredModules = modules //filtra as opções de módulos de acordo com o curso escolhido
    ?.filter(item => parsedSelectedCourses?.includes(item['course-id']))
    .map(module => {
      const moduleCourse = courses.find(item => item.id === module?.['course-id']);
      return { value: module.id, label: `${module.title} - ${moduleCourse.title}` };
    });

  useEffect(() => {
    if (form?.values?.['learning-system-ids']?.length > 0) {
      form.setFieldValue('classroom-ids', '');
    }
    if (form?.values?.['classroom-ids']?.length > 0) {
      form.setFieldValue('learning-system-ids', '');
    }
  }, [form?.values?.['learning-system-ids'], form?.values?.['classroom-ids']]);

  const parsedCourses = courses?.map(item => {
    return { value: item.id, label: item.title };
  });

  const showAuthoredToggle = !exam || exam?.['authored-by-user'] === false || (exam?.['authored-by-user'] === true && session?.user?.id === exam?.creator?.id) || session?.user?.profileName?.includes('Administrador');

  return (
    step === 'curso' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{form?.values?.title}</h2>
        </div>

        <div className="new-questions__body">
          <p className="new-questions__subtitle">{t('exams.detailsDescription')}</p>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="course-ids"
            >
              *{t('filter.labelSelectCourse')}
            </label>

            <Select
              classNamePrefix="react-multi-select"
              openMenuOnFocus
              noOptionsMessage={() => 'Sem opções'}
              isDisabled={loadingCourses || loadingExam}
              value={form?.values?.['course-ids']}
              placeholder={loadingCourses || loadingExam ? 'Carregando...' : 'Selecione o curso'}
              onChange={item => form.setFieldValue('course-ids', item)}
              components={{
                animatedComponents,
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              closeMenuOnSelect={false}
              isSearchable={true}
              options={parsedCourses}
              isMulti
            />
            {form?.touched?.['course-ids'] && form?.errors?.['course-ids'] && <span style={{ color: 'red' }}>{form?.errors['course-ids']}</span>}
          </div>

          <div className="form__row">
            <BtnGroup
              options={[t('exams.toggleClass'), t('exams.toggleModule')]}
              name={association}
              onChange={item => setAssociation(item)}
              className="u-mb-2"
            />

            {association === t('exams.toggleClass') && (
              <>
                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="course-ids"
                  >
                    *Selecione a instituição
                  </label>

                  <Select
                    classNamePrefix="react-multi-select"
                    openMenuOnFocus
                    noOptionsMessage={() => 'Sem opções'}
                    isDisabled={loadingInstitutions || loadingExam}
                    value={selectedInstitutions}
                    placeholder={loadingInstitutions || loadingExam ? 'Carregando...' : 'Selecione uma instituição'}
                    onChange={item => setSelectedInstitutions(item)}
                    components={{
                      animatedComponents,
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null
                    }}
                    closeMenuOnSelect={false}
                    isSearchable={true}
                    options={institutions}
                    isMulti
                  />
                </div>

                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="course-ids"
                  >
                    *Selecione a turma
                  </label>

                  <Select
                    classNamePrefix="react-multi-select"
                    openMenuOnFocus
                    noOptionsMessage={() => 'Sem opções'}
                    isDisabled={loadingClassrooms || loadingExam}
                    value={form?.values?.['classroom-ids']}
                    placeholder={loadingClassrooms || loadingExam ? 'Carregando...' : 'Selecione uma turma'}
                    onChange={item => form.setFieldValue('classroom-ids', item)}
                    components={{
                      animatedComponents,
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null
                    }}
                    closeMenuOnSelect={false}
                    isSearchable={true}
                    options={filteredClassrooms}
                    isMulti
                  />
                </div>
              </>
            )}
            {form?.touched?.['classroom-ids'] && form?.errors?.['classroom-ids'] && <span style={{ color: 'red' }}>{form?.errors['classroom-ids']}</span>}

            {association === t('exams.toggleModule') && (
              <fieldset className="form__row">
                <legend className="form__label">*Selecione um módulo</legend>
                <Select
                  className="u-mb-2"
                  classNamePrefix="react-multi-select"
                  openMenuOnFocus
                  noOptionsMessage={() => 'Sem opções'}
                  isDisabled={loadingModules || loadingExam}
                  value={form?.values?.['learning-system-ids']}
                  placeholder={loadingModules || loadingExam ? 'Carregando...' : 'Selecione um módulo'}
                  onChange={item => form.setFieldValue('learning-system-ids', item)}
                  components={{
                    animatedComponents,
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null
                  }}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  options={filteredModules}
                  isMulti
                />

                {form?.touched?.['learning-system-ids'] && form?.errors?.['learning-system-ids'] && <span style={{ color: 'red' }}>{form?.errors['learning-system-ids']}</span>}
              </fieldset>
            )}
          </div>
          <div className="form__row">
            <div className="form__check form__check--inline form__switch">
              <label htmlFor="published">Permitir que os alunos refaçam a prova</label>
              <input
                className="form__check-input"
                id="published"
                type="checkbox"
                checked={form?.values['able-to-retry']}
                onChange={() => form?.setFieldValue('able-to-retry', !form?.values['able-to-retry'])}
              />
            </div>
          </div>

          <div className="form__row">
            {showAuthoredToggle && (
              <div className="form__check form__switch">
                <input
                  className="form__check-input"
                  id="authorialContent"
                  name="authorialContent"
                  type="checkbox"
                  checked={form?.values?.authored_by_user}
                  onClick={() => {
                    form?.setFieldValue('authored_by_user', !form?.values?.authored_by_user);
                  }}
                />
                <label htmlFor="authorialContent">
                  Esta prova é de minha autoria e <strong>somente eu posso editá-lo.</strong>
                </label>
              </div>
            )}

            {isAdmin && (
              <div className="form__check form__switch">
                <input
                  className="form__check-input"
                  id="officialContent"
                  name="officialContent"
                  type="checkbox"
                  checked={form?.values?.official_content}
                  onClick={() => {
                    form?.setFieldValue('official_content', !form?.values?.['official_content']);
                  }}
                />
                <label htmlFor="officialContent">
                  Este conteúdo é <strong>oficial;</strong> apenas a equipe administrativa têm permissão para editá-lo.
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
}
