import React from 'react';

export default function SemiCircle({ value = 0, style }) {
  return (
    <div className="semi-circle-progress" style={style}>
      <div
        className="semi-circle-progress__bar"
        style={{
          transform:
            value <= 100 ? `rotate(${45 + value * 1.8}deg)` : 'rotate(225deg)',
        }}
      />
      <span className="semi-circle-progress__value">{value}%</span>
    </div>
  );
}
