import React, { useState } from 'react';
import { t } from 'i18next';

export default function SchoolCard(props) {
  const { course, addCourse, removeCourse, selectedCourses, isSelected, show, selectedItem } = props;

  // const institutionsData = () => {
  //   const filteredInstitutions = istitutions?.filter(item => item.id === course?.['institution-id']);
  //   return filteredInstitutions.map(item => item.name);
  // };

  // const alunoCount = () => {
  //   const alunoUsers = course?.users?.filter(user => user['profile-names'].includes('Aluno'));
  //   return alunoUsers?.length;
  // };

  return (
    <tr className="round-dg__row">
      {/* <th className="round-dg__cell-header round-dg__cell-header--row">
        <h3 className="card__title">{institutionsData()}</h3>
      </th> */}
      <td className="round-dg__cell">{course?.title}</td>
      {/* <td className="round-dg__cell">{`${alunoCount()} ${t('textsCommon.students')}`}</td> */}
      <td
        className="round-dg__cell"
        style={{ width: '140px' }}
      >
        <button
          type="button"
          className={`btn btn--full ${isSelected || !show ? 'btn--primary' : 'btn--outline'}`}
          onClick={() => {
            if (isSelected) {
              removeCourse(course);
            } else if (!show) {
              removeCourse(course);
            } else {
              addCourse(course);
            }
          }}
        >
          {isSelected || !show ? t('button.remove') : t('button.add')}
        </button>
      </td>
    </tr>
  );
}
