import React, { useState, useEffect } from 'react';
import { FiChevronLeft, FiMoreHorizontal } from 'react-icons/fi';
import { Link, useParams } from '@reach/router';
import { useClient } from 'jsonapi-react';
import Dropdown from 'app/components/Dropdown';
import MainSidebar from 'app/components/Sidebar';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import { AvatarStack } from 'app/components/Avatar';
import { navigate } from '@reach/router';
import GroupModal from './CreateProject/GroupModal';
import { useTranslation } from 'react-i18next';

export default function ProjectsSidebar(props) {
  const { t } = useTranslation();
  const { projectId } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [courses, setCourses] = useState([]);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [currentCourse, setCurrentCourse] = useState('');
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const client = useClient();

  const getCourses = async () => {
    setCoursesLoading(true);
    const { data, error } = await client.fetch('courses');
    if (error) {
      toast.error(t('toast.errorGetCourse'));
    } else {
      setCourses(data);
      setCoursesLoading(false);
    }
  };

  const getProjects = async () => {
    setProjectsLoading(true);
    const { data, error } = await client.fetch('projects');
    if (error) {
      toast.error(t('toast.errorGetProject'));
    } else {
      setProjects(data);
      setProjectsLoading(false);
    }
  };

  function filtrarProjetosPorNomeUsuarioEProjeto(projects, searchTerm) {
    return projects.filter(project => {
      const projectName = project.name != null ? project.name.toLowerCase() : '';
      const usersNameMatches = project.users.some(user => user.name.toLowerCase().includes(searchTerm.toLowerCase()));
      return projectName.includes(searchTerm.toLowerCase()) || usersNameMatches;
    });
  }

  useEffect(() => {
    getCourses();
    getProjects();
  }, []);

  useEffect(() => {
    let newProjects = projects;
    if (currentCourse !== '') {
      const selectedCourse = courses.find(c => c.id === +currentCourse);
      const selectedCourseClassrooms = selectedCourse?.classrooms?.map(c => c.id);
      const courseProjects = newProjects.filter(p => p['projectable-type'] === 'Course' && p['projectable-id'] === +currentCourse);
      const classroomProjects = newProjects.filter(p => p['projectable-type'] === 'Classroom' && selectedCourseClassrooms?.includes(p['projectable-id']));
      newProjects = [...courseProjects, ...classroomProjects];
    }
    if (searchTerm !== '') {
      newProjects = filtrarProjetosPorNomeUsuarioEProjeto(newProjects, searchTerm);
    }
    setFilteredProjects(newProjects);
  }, [searchTerm, projects, currentCourse]);

  return (
    <MainSidebar>
      <nav className="notebook-sidebar__nav">
        <Link
          to="/projetos/grupos"
          className="filter-bar__back"
        >
          <div className="card__icon-wrapper">
            <FiChevronLeft />
          </div>
          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{t('courses.projects')}</span>
          </span>
        </Link>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="courseId"
          >
            {t('filter.labelSelectCourse')}
          </label>

          <select
            className="form__select"
            name="courseId"
            id="courseId"
            value={currentCourse}
            onChange={e => setCurrentCourse(e.target.value)}
          >
            {coursesLoading && <option value="">{t('exams.loading')}</option>}
            {!coursesLoading && <option value="">{t('filter.labelSelectCourse')}</option>}
            {!coursesLoading &&
              courses?.map(item => {
                return <option value={item.id}>{item.title}</option>;
              })}
          </select>
        </div>

        <div className="form__row">
          <input
            aria-label={t('textsCommon.research')}
            className="form__control form__control--search-with-icon"
            placeholder={t('projects.placeholderSearchProjects')}
            type="search"
            name="search"
            id="search"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>

        <ul className="module-list">
          {projectsLoading && <Loader />}
          {!projectsLoading &&
            filteredProjects.map(p => {
              return (
                <li
                  onClick={() => {
                    navigate(`/projetos/${p.id}`);
                  }}
                  className={p.id === +projectId ? 'module-list__item active' : 'module-list__item'}
                >
                  <h3 className="module-list__title">{p.name}</h3>
                  <div className="module-list__avatars">
                    <GroupModal
                      data={p.users}
                      className="avatar--xs"
                      i={p.id}
                    />
                  </div>
                </li>
              );
            })}
        </ul>
      </nav>
    </MainSidebar>
  );
}
