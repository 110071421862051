import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function PositionsPieChart(props) {
  const { data = [], setSelectedPositions } = props;

  const pieColors = (function () {
    let colors = [],
      base = '#442b8c',
      i;

    for (i = 0; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      colors.push(
        Highcharts.color(base)
          .brighten(i / 7)
          .get()
      );
    }
    return colors;
  })();

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    credits: { enabled: false },
    title: { text: null },
    legend: {
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      symbolWidth: 12,
      symbolHeight: 12,
      symbolRadius: 8
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        borderWidth: 2,
        radius: '100%',
        cursor: 'pointer',
        // colors: pieColors,
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      },
      series: {
        point: {
          events: {
            click: function () {
              setSelectedPositions && setSelectedPositions(prevState => [...prevState, { value: this?.value, label: this?.name, type: 'position' }]);
              window.scroll({
                top: 0,
                behavior: 'smooth'
              });
            }
          }
        }
      }
    },
    series: [
      {
        name: 'Respostas',
        innerSize: '40%',
        colorByPoint: true,
        data: data
      }
    ]
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}
