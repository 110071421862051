import React from 'react';
import MainTabNavBar from 'app/components/MainTabNavBar';

export default function ChallengeTab(props) {
  const { selectedTab } = props;

  const tabs = [
    {
      name: 'Desafio',
      url: '/desafio-da-semana/desafios',
      slug: 'desafios',
      order: 1
    },
    {
      name: 'Ranking por região',
      url: '/desafio-da-semana/ranking-por-regiao',
      slug: 'ranking-por-regiao',
      order: 2
    },
    {
      name: 'Ranking por participante',
      url: '/desafio-da-semana/ranking-por-participante',
      slug: 'ranking-por-participante',
      order: 3
    },
    {
      name: 'Resultados',
      url: '/desafio-da-semana/resultados',
      slug: 'resultados',
      order: 4
    }
  ];

  return (
    <MainTabNavBar
      tabs={tabs}
      selectedTab={selectedTab}
    />
  );
}
