import React, { useEffect } from 'react';
import { navigate } from '@reach/router';

function MainTabNavBar({ tabs, selectedTab }) {
  return (
    <div className="tab">
      {tabs
        ?.sort((a, b) => a.order - b.order)
        ?.map((item, index) => {
          const action = item.callback ? item.callback : () => navigate(item.url);
          return (
            <button
              key={index}
              className={`tab__link ${selectedTab === item.slug ? 'active' : ''}`}
              onClick={action}
          
            >
              {item.name}
            </button>
          );
        })}
    </div>
  );
}

export default MainTabNavBar;
