import React from 'react';
import { AiOutlineTrophy } from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';

export default function GreTable() {
  return (
    <div className="round-dg-wrapper">
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: '720px' }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '80px' }}
            >
              Posição
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '44px' }}
            />
            <th className="round-dg__cell-header round-dg__cell-header--sm">GRE</th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm u-text-right"
              style={{ width: '132px' }}
            >
              Total de inscritos{' '}
              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                <FiChevronDown className="card__icon" />
              </span>
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm u-text-right"
              style={{ width: '176px' }}
            >
              Solicitação de isenção{' '}
              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                <FiChevronDown className="card__icon" />
              </span>
            </th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {[...Array(23)].map((item, i) => (
            <tr
              className="round-dg__row"
              key={i}
            >
              <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{i + 1}</th>
              <td className="round-dg__cell round-dg__cell--sm">
                {i === 0 && <AiOutlineTrophy className="card__icon card__icon--golden" />}
                {i === 1 && <AiOutlineTrophy className="card__icon card__icon--silver" />}
                {i === 2 && <AiOutlineTrophy className="card__icon card__icon--bronze" />}
              </td>
              <td className="round-dg__cell round-dg__cell--sm">
                <div className="round-dg__user">
                  <div className="round-dg__user-inner">
                    <h3 className="round-dg__title">1ª Gerência Regional - PARNAÍBA - </h3>
                  </div>
                </div>
              </td>
              <td className="round-dg__cell round-dg__cell--sm u-text-right">95</td>
              <td className="round-dg__cell round-dg__cell--sm u-text-right">85 (76,61%)</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
