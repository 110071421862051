import React, { useState } from 'react';
import { FiRefreshCcw, FiRefreshCw } from 'react-icons/fi';

const FlipCard = props => {
  const { concept, explanation } = props;

  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  return (
    <div
      className={flipped ? 'flip-card flip-card--flipped' : 'flip-card'}
      onClick={handleFlip}
    >
      <div className="flip-card__inner">
        <div className="flip-card__front">
          <p>{concept}</p>
          <FiRefreshCw className="flip-card__icon" />
        </div>
        <div className="flip-card__back">
          <p title={explanation}>{explanation}</p>
          <FiRefreshCcw className="flip-card__icon" />
        </div>
      </div>
    </div>
  );
};

const FlipCardWrapper = ({flip_cards}) => {

  return (
    <div className="flip-card-wrapper">
      {flip_cards.map((card, index) => (
        <FlipCard
          key={index}
          concept={card.front}
          explanation={card.back}
        />
      ))}
    </div>
  );
};

export default FlipCardWrapper;
