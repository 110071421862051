import React from 'react';
import ProgressLvlTag from './ProgressLvlTag';

export default function StudyGuideDataGrid(props) {
  const { data, height = 'auto' } = props;

  return (
    <div
      className="round-dg-wrapper"
      style={{ height: height }}
    >
      <table className="round-dg round-dg--light">
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th className="round-dg__cell-header">Matéria</th>
            <th
              className="round-dg__cell-header last-cell"
              style={{ width: '70%' }}
            >
              Progresso
            </th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {data?.map((item, key) => (
            <tr
              className="round-dg__row"
              key={key}
            >
              <td
                className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row"
                style={{ paddingTop: 8, paddingBottom: 8 }}
              >
                <span
                  className="u-text-truncate"
                  title={item.taxonomy}
                >
                  {item.taxonomy}
                </span>
              </td>
              <td
                className="round-dg__cell round-dg__cell--sm last-cell"
                style={{ paddingTop: 8, paddingBottom: 8 }}
              >
                <ProgressLvlTag
                  percentage={item.progress}
                  id={key}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
