import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FiX } from 'react-icons/fi';
import { CSSTransition } from 'react-transition-group';
import SimpleModal from './SimpleModal';
import PlaceholderModal from './PlaceholderModal.jsx';

function Modal(props) {
  const { show, onClose, children, contentClassName, hideCloseButton, id } = props;

  function closeOnEscapeKeyDown(event) {
    if (event.key === 'Escape') onClose();
  }

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
      document.body.style.overflow = 'visible';
    };
  }, []);

  useEffect(() => {
    if (show) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [show]);

  const nodeRef = React.useRef(null); //Fix react-transition-group

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      unmountOnExit
      nodeRef={nodeRef}
      timeout={{ enter: 0, exit: 300 }}
    >
      <div
        id={id}
        className={`modal ${show ? 'active' : ''}`}
        onClick={onClose}
        ref={nodeRef}
      >
        <div
          className={`modal__content ${contentClassName}`}
          onClick={event => event.stopPropagation()}
        >
          {!hideCloseButton && (
            <button
              className="card__icon-wrapper card__icon-wrapper--sm u-ml-auto u-mb-2"
              onClick={onClose}
            >
              <FiX className="card__icon" />
            </button>
          )}

          {children}
        </div>
      </div>
    </CSSTransition>,
    document.getElementById('modal_root')
  );
}

export { Modal as default, SimpleModal, PlaceholderModal };
