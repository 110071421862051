import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import QuizCard from './QuizCard';
import { useSurvey } from 'app/hooks/useSurvey';
import { useClient } from 'jsonapi-react';
import Modal from '../../components/Modal';
import { SurveyForm } from './SurveyForm';
import EmptyState from 'app/components/EmptyState';
import getPermission from 'app/utils/getPermission';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function Quiz(props) {
  const { uri } = props;

  const { t } = useTranslation();
  const { getSurvey, deleteSurvey, surveys, loading } = useSurvey();
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [activeSurvey, setActiveSurvey] = useState(null);
  const [relationshipsLoading, setRelationshipsLoading] = useState(true);

  const [relationships, setRelationships] = useState({
    courses: []
  });

  const filters = useFormik({
    initialValues: {
      course: 'blank'
    }
  });

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses/simple');

    setRelationships({
      courses: courses || []
    });
    setRelationshipsLoading(false);
  };

  useEffect(() => {
    getRelationships();
  }, []);

  useEffect(() => {
    getSurvey(filters.values);
  }, [filters.values]);

  const reverseSurveys = surveys.reverse();

  const breadCrumbs = {
    title: 'Ferramentas de Avaliação',
    nav: [
      {
        route: uri,
        name: 'Questionário de Percepção',
        isActive: true
      }
    ]
  };

  return (
    <>
      {getPermission('Visualizar respostas e dados', 'Questionário de Percepção') && (
        <main className="main-content main-content--block fadeIn">
          <BreadCrumbs data={breadCrumbs} />

          <div className="filter-bar">
            <FilterSelectionBox
              label={t('filter.labelSelectCourse')}
              value={filters.values.course}
              onChange={e => filters.setFieldValue('course', e.target.value)}
              options={relationships.courses.map(o => ({
                id: o.id,
                name: o.title
              }))}
            />

            {getPermission('Criar questionários', 'Questionário de Percepção') && (
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="btn btn--primary btn--wide"
              >
                {t('nps.buttonNewQuiz')}
              </button>
            )}
          </div>

          {loading && <Loader />}

          {reverseSurveys.length === 0 && !loading ? (
            <div className="center-empty-state">
              <EmptyState
                type="content"
                title={t('emptyState.noQuest')}
              />
            </div>
          ) : (
            <div className="tab__cards">
              {reverseSurveys.map((survey, i) => (
                <QuizCard
                  relationshipsLoading={relationshipsLoading}
                  relationships={relationships}
                  activeSurvey={activeSurvey}
                  key={i}
                  survey={survey}
                  onDelete={() => deleteSurvey(survey.id)}
                />
              ))}
            </div>
          )}
        </main>
      )}

      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setActiveSurvey(null);
        }}
      >
        <SurveyForm
          relationshipsLoading={relationshipsLoading}
          relationships={relationships}
          activeSurvey={activeSurvey}
          onClose={() => {
            setShowModal(false);
            setActiveSurvey(null);
          }}
        />
      </Modal>
    </>
  );
}

export * from './QuizData';
export * from './QuizData/QuizDataProposal';
export * from './QuizData/QuizDataOpenAnswers';
export * from './QuizCreate';
