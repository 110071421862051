import React, { useMemo, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';

export default function RegionalDataGrid(props) {
  const { setShow, regionals, setSelectedRegional } = props;
  const [order, setOrder] = useState('descendent');

  const handleSelectRegional = regional => {
    setShow({
      regional: false,
      institution: true,
      student: false
    });

    setSelectedRegional(regional);
  };

  const orderedRegionals = useMemo(() => {
    let newArr = [];

    if (order === 'ascendent') {
      newArr = [...regionals].sort((a, b) => a.score - b.score);
    } else {
      newArr = [...regionals].sort((a, b) => b.score - a.score);
    }

    return newArr;
  }, [order]);

  return (
    <div className="round-dg-wrapper">
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: '480px' }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '80px' }}
            >
              Posição
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '44px' }}
            />
            <th className="round-dg__cell-header round-dg__cell-header--sm">GRE</th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm u-text-right"
              style={{ width: '124px' }}
            >
              Pontuação total{' '}
              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                {order === 'ascendent' ? (
                  <FiChevronDown
                    className="card__icon"
                    onClick={() => setOrder('descendent')}
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => setOrder('ascendent')}
                    className="card__icon"
                  />
                )}
              </span>
            </th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {orderedRegionals?.map((item, i) => (
            <tr
              className={i === 999 ? 'round-dg__row round-dg__row--highlight u-cursor-pointer' : 'round-dg__row u-cursor-pointer'} //revisar
              onClick={() => handleSelectRegional(item)}
              key={i}
            >
              <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{i + 1}</th>
              <td className="round-dg__cell round-dg__cell--sm">
                {i === 0 && order ==='descendent' && <AiOutlineTrophy className="card__icon card__icon--golden" />}
                {i === 1 && order ==='descendent' && <AiOutlineTrophy className="card__icon card__icon--silver" />}
                {i === 2 && order ==='descendent' && <AiOutlineTrophy className="card__icon card__icon--bronze" />}
              </td>
              <td className="round-dg__cell round-dg__cell--sm">
                <div className="round-dg__user">
                  <div className="round-dg__user-inner">
                    <h3 className="round-dg__title">{item.name}</h3>
                  </div>
                </div>
              </td>
              <td className="round-dg__cell u-text-right">{item.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
