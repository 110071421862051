import { Link } from '@reach/router';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiLock, FiUnlock, FiCheckCircle, FiEdit3, FiThumbsUp, FiCheck, FiChevronUp, FiMessageCircle, FiTarget } from 'react-icons/fi';

export default function ClassModuleItem(props) {
  const {
    learnigSystem,
    learningSystemItems,
    learnSystemContents,
    setSelectedItem,
    selectedItem,
    setSelectedContent,
    selectedContent,
    learnSystemContent,
    active,
    setActive,
    authorityLevel,
    index,
    changeTab,
    updateLearningSystemItems,
    updateLearningSystemUserItems,
    getLearnSystemContents
  } = props;

  const [blocked, setBlocked] = useState(false)
  

  const hasNewMessageInStudent = learningSystemItems.find(learningSystemItem => learningSystemItem['learning-system-user-items'][0]?.has_new_message);
  
  const blockModuleItem = () => {
    setBlocked(!blocked)
    setActive(learnSystemContent?.id)
  }

  useEffect(()=>{
      if(isNaN(parseInt(active))) setActive(selectedContent?.id)
      const initBlockVar = active === learnSystemContent?.id
      setBlocked(initBlockVar)

  }, [])

  return (
    <div className="class-module">
      <div
        className="class-module__header"
        onClick={blockModuleItem}
      >
        {authorityLevel !== 'student' && learnSystemContent['has-new-message'] && (
          <div className="class-module__new-message-notification">
            <FiMessageCircle />
          </div>
        )}
        {authorityLevel === 'student' && hasNewMessageInStudent && (
          <div className="class-module__new-message-notification">
            <FiMessageCircle />
          </div>
        )}
        <div className="class-module__body">
          {authorityLevel !== 'student' ? (
            <>
              <div className="class-module__icon">
                <FiUnlock />
              </div>
              <div className="class-module__title">{learnSystemContent.body} </div>
            </>
          ) : (
            <>
              {learnSystemContent.finished ? (
                <>
                  <div className="class-module__icon done">
                    <FiCheck />
                  </div>
                  <div className="class-module__title">{learnSystemContent.body} </div>
                </>
              ) : learnSystemContent['is-allowed'] ? (
                <>
                  <div className="class-module__icon">
                    <FiUnlock />
                  </div>
                  <div className="class-module__title">{learnSystemContent.body} </div>
                </>
              ) : (
                <>
                  <div className="class-module__icon disabled">
                    <FiLock />
                  </div>
                  <div className="class-module__title disabled">{learnSystemContent.body} </div>
                </>
              )}
            </>
          )}
        </div>

        <div className="class-module__actions">
          <div className="class-module__count">
            {learningSystemItems.length} {t('lms.lms')}
          </div>
          {blocked ? (
            <div className="class-module__down-icon">
              <FiChevronDown />
            </div>
          ) : (
            <div
              className="class-module__down-icon"
            >
              <FiChevronUp />
            </div>
          )}
        </div>
      </div>

      <div className={`class-module__content-list fadeInDown ${blocked ? 'active' : ''}`}>
        {authorityLevel == 'student' &&
          learningSystemItems.map((learningSystemItem, i) => {
            const active = selectedItem?.id === learningSystemItem?.id ? 'active' : '';
            const previousItem = learningSystemItems[i - 1] ? learningSystemItems[i - 1] : learningSystemItems[i];
            const previousContent = learnSystemContents[index - 1];
            const isEnabled = previousItem
              ? learningSystemItem.unrequired || previousItem['learning-system-user-items']?.some(a => a.finished)
              : index === 0 || previousContent['learning-system-items'].findLast(() => true)['learning-system-user-items']?.some(a => a.finished);

            return (
              <React.Fragment key={i}>
                <div
                  className={`class-module__content-item ${active} ${(learnSystemContent['is-allowed'] && isEnabled) || learningSystemItem.order === 0 ? '' : 'disabled'}`}
                  onClick={() => {
                    if ((learnSystemContent['is-allowed'] && isEnabled) || learningSystemItem.order === 0) {
                      setSelectedItem(learningSystemItem);
                      setSelectedContent(learnSystemContent);
                    }
                    if (learningSystemItem['learning-system-user-items'][0]?.has_new_message) {
                      updateLearningSystemUserItems(learningSystemItem['learning-system-user-items'][0]?.id);
                      getLearnSystemContents();
                    }
                  }}
                >
                  {learningSystemItem['learning-system-user-items'][0] && learningSystemItem['learning-system-user-items'][0].finished ? (
                    <div className="class-module__content-item-icon done">
                      <FiCheck />
                    </div>
                  ) : (
                    <div className="class-module__content-item-icon"></div>
                  )}
                  <div className="class-module__content-item-title">{learningSystemItem.body}</div>
                  {learningSystemItem['learning-system-user-items'][0]?.has_new_message && (
                    <div className="class-module__content-item-messages new">
                      <FiMessageCircle /> {learningSystemItem['message-count']}
                    </div>
                  )}
                  {!learningSystemItem['learning-system-user-items'][0]?.has_new_message && (
                    <div className="class-module__content-item-messages">
                      <FiMessageCircle /> {learningSystemItem['message-count']}
                    </div>
                  )}
                </div>

                {active && (learningSystemItem?.requirements || []).length > 0 && (
                  <>
                    {learningSystemItem?.requirements.some(requirement => requirement.type === 'QuestionBook') && (
                      <div
                        onClick={() => changeTab('courseClassroomQuestionBooks')}
                        className="class-module__content-item class-module__content-item--requirement"
                      >
                        <div className="class-module__content-item-icon class-module__content-item-icon--requirement">
                          <FiCheckCircle />
                        </div>
                        <div className="class-module__content-item-title">Apostila</div>
                      </div>
                    )}

                    {learningSystemItem?.requirements.some(requirement => requirement.type === 'Activity') && (
                      <div
                        onClick={() => changeTab('courseClassroomActivities')}
                        className="class-module__content-item class-module__content-item--requirement"
                      >
                        <div className="class-module__content-item-icon class-module__content-item-icon--requirement">
                          <FiEdit3 />
                        </div>
                        <div className="class-module__content-item-title">Atividade complementar</div>
                      </div>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}

        {authorityLevel !== 'student' &&
          learningSystemItems.map((learningSystemItem, i) => {
            const active = selectedItem?.id === learningSystemItem?.id ? 'active' : '';
            return (
              <React.Fragment key={i}>
                <div
                  className={`class-module__content-item ${active}`}
                  onClick={() => {
                    setSelectedItem(learningSystemItem);
                    setSelectedContent(learnSystemContent);
                    if (learningSystemItem['has-new-message']) {
                      updateLearningSystemItems(learningSystemItem?.id);
                      getLearnSystemContents();
                    }
                  }}
                >
                  <div className="class-module__content-item-icon"></div>

                  <div className="class-module__content-item-title">{learningSystemItem.body}</div>
                  {learningSystemItem['has-new-message'] && (
                    <div className="class-module__content-item-messages new">
                      <FiMessageCircle /> {learningSystemItem['message-count']}
                    </div>
                  )}
                  {!learningSystemItem['has-new-message'] && (
                    <div className="class-module__content-item-messages">
                      <FiMessageCircle /> {learningSystemItem['message-count']}
                    </div>
                  )}
                </div>

                {active && (learningSystemItem?.requirements || []).length > 0 && (
                  <>
                    {learningSystemItem?.requirements.some(requirement => requirement.type === 'QuestionBook') && (
                      <div
                        onClick={() => changeTab('courseClassroomQuestionBooks')}
                        className="class-module__content-item class-module__content-item--requirement"
                      >
                        <div className="class-module__content-item-icon class-module__content-item-icon--requirement">
                          <FiCheckCircle />
                        </div>
                        <div className="class-module__content-item-title">Apostila</div>
                      </div>
                    )}

                    {learningSystemItem?.requirements.some(requirement => requirement.type === 'Activity') && (
                      <div
                        onClick={() => changeTab('courseClassroomActivities')}
                        className="class-module__content-item class-module__content-item--requirement"
                      >
                        <div className="class-module__content-item-icon class-module__content-item-icon--requirement">
                          <FiEdit3 />
                        </div>
                        <div className="class-module__content-item-title">Atividade complementar</div>
                      </div>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
      </div>

      {index === learnSystemContents?.length - 1 &&
        (learnigSystem?.['question-books'] || []).map(qb =>
          learnSystemContent.finished ? (
            <Link
              to={`/avaliacoes/sobre/${qb.id}`}
              class="class-module__content-item class-module__content-item--requirement"
            >
              <div class="class-module__content-item-icon class-module__content-item-icon--requirement">
                <FiTarget />
              </div>
              <div class="class-module__content-item-title">{qb.title}</div>
            </Link>
          ) : (
            <Link
              to={`/avaliacoes/sobre/${qb.id}`}
              class="class-module__content-item class-module__content-item--requirement"
            >
              <div class="class-module__content-item class-module__content-item--requirement ">
                <div class="class-module__content-item-icon class-module__content-item-icon--requirement">
                  <FiTarget />
                </div>
                <div class="class-module__content-item-title">{qb.title}</div>
              </div>
            </Link>
          )
        )}
    </div>
  );
}
