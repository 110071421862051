import React, { useState, useEffect } from 'react';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import Error404 from 'app/components/Error404';
import EssaySidebar from './EssaySidebar';
import EssayContent from './EssayContent';

export default function CreateEssay(props) {
  const { step, essayId } = props;

  const [currentStep, setCurrentStep] = useState(0);

  const authorityLevel = getAuthorityLevel();

  const isAdmin = authorityLevel !== 'student';

  const steps = [
    {
      id: 0,
      route: 'curso',
      text: 'Comece escolhendo o curso e a turma'
    },
    {
      id: 1,
      route: 'enunciado',
      text: 'Crie um enunciado e acrescente o texto motivador'
    },
    {
      id: 2,
      route: 'titulo',
      text: 'Defina um prazo e escolha o modo de correção'
    }
  ];

  function getStep() {
    switch (step) {
      case 'curso':
        setCurrentStep(0);
        break;
      case 'enunciado':
        setCurrentStep(1);
        break;
      case 'titulo':
        setCurrentStep(2);
        break;
      default:
        setCurrentStep(0);
        break;
    }
  }

  useEffect(() => getStep());

  if (!isAdmin) return <Error404 />;

  return (
    <div className="main-screen">
      <EssaySidebar
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
      />

      <main className="main-content">
        <EssayContent
          essayId={parseInt(essayId)}
          step={step}
          steps={steps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </main>
    </div>
  );
}
