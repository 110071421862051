import React from 'react';

export default function AnswerKey(props) {
  const { index, question } = props;

  const rightWrongAlternatives = ['C', 'E'];

  const multipleChoiceAlternatives = ['A', 'B', 'C', 'D', 'E'];

  return (
    <div className="answer-key">
      <div className="answer-key__number">{index + 1}</div>

      {(question?.alternatives?.length < 4 ? rightWrongAlternatives?.slice(0, 2) : multipleChoiceAlternatives).map((alternative, i) => (
        <div
          className="answer-key__alternative"
          key={i}
        >
          {alternative}
        </div>
      ))}
      {/* <div className="answer-key__id">#{question?.id}</div> */}
    </div>
  );
}
