import { useContext } from 'react';
import { AgendaContext } from 'app/providers/AgendaProvider';

export const useAgenda = () => {
	const context = useContext(AgendaContext);

	if (!context) {
		throw new Error(
			'useAgenda must be used within a AgendaProvider.',
		);
	}

	return context;
};
