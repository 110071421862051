import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { SimpleModal } from '../Modal';

export default function MatterModal(props) {
  const { show, onClose, text, setShowModal, setHasMatterSelected, form, subTaxonomies, matterIndex } = props;

  const [isAddMatter, setIsAddMatter] = useState(false);

  const handleChange = event => {
    const selectedOption = subTaxonomies.find(item => item.id == event.target.value);

    form.setFieldValue(`matters.[${matterIndex}]taxonomy-id`, selectedOption.id);
    form.setFieldValue(`matters.[${matterIndex}]taxonomy-name`, selectedOption.name);
    !form.values?.[`matters.taxonomy-id[${matterIndex}]`] && toast.success('Matéria adicionada com sucesso');
    !form.values?.[`matters.taxonomy-id[${matterIndex}]`] && setHasMatterSelected(true);
    !form.values?.[`matters.taxonomy-id[${matterIndex}]`] && setShowModal(false);
  };

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      contentClassName="simple-modal__content--md simple-modal__content--br-md"
      hideCloseButton
    >
      <h3 className="simple-modal__title">{text}</h3>

      {!isAddMatter && (
        <div className="form__row">
          <label
            className="form__label"
            htmlFor="relatedMatter"
          >
            *Matéria relacionada
          </label>
          <select
            className="form__select"
            name="relatedMatter"
            id="relatedMatter"
            value={form.values.relatedMatter}
            onChange={handleChange}
          >
            <option value="">Selecione uma matéria</option>
            {subTaxonomies.map(item => (
              <option
                value={item.id}
                key={item.id}
              >
                {item.name}
              </option>
            ))}
          </select>
          <p className="form__text u-text-center">
            Não encontrou a matéria que procurava?
            <button
              className="btn btn--link"
              style={{ padding: '0' }}
              type="button"
              onClick={() => setIsAddMatter(true)}
            >
              Crie uma nova matéria
            </button>
          </p>
        </div>
      )}

      {isAddMatter && (
        <div className="filter-bar filter-bar--borderless">
          <div className="filter-bar__row u-fg-1">
            <label
              htmlFor="newMatter"
              className="filter-bar__label"
            >
              Nova matéria
            </label>
            <input
              className="form__control u-w-100"
              placeholder="Digite aqui o nome da matéria"
              type="text"
              name="newMatter"
              id="newMatter"
              value={form.values.newMatter}
              onChange={e => form.setFieldValue('newMatter', e.target.value)}
            />
          </div>

          <button
            className="btn btn--outline"
            type="button"
            onClick={() => {
              form.setFieldValue('relatedMatter', form.values.newMatter);
              toast.success('Matéria cadastrada com sucesso');
              setIsAddMatter(false);
              setHasMatterSelected(true);
              setShowModal(false);
            }}
          >
            Criar nova matéria
          </button>
        </div>
      )}
    </SimpleModal>
  );
}
