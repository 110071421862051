import React, { useState, createContext } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { toBase64 } from '../../../utils/toBase64';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import promiseRequest from 'app/utils/promiseToast';

export const ProjectsContext = createContext();

export const ProjectsProvider = ({ children }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [events, setEvents] = useState([]);
  const [editingEvent, setEditingEvent] = useState();
  const { promiseToast } = promiseRequest();

  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectVersion, setSelectedProjectVersion] = useState(null);

  const [allStudents, setAllStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);

  const client = useClient();

  const getProjects = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('projects');
    if (error) {
      toast.error(t('toast.errorGetProject'));
    } else {
      setProjects(data);
    }
    setLoading(false);
  };

  const getProject = async (firstLoad, projectId) => {
    setLoading(true);
    const { data, error } = await client.fetch(['projects', projectId]);
    if (error) {
      toast.error(t('toast.errorGetProject'));
    } else {
      setSelectedProject(data);

      if (firstLoad && data) {
        if (data['project-versions'].length > 0) {
          getProjectVersion(data['project-versions'][data['project-versions'].length - 1].id);
        } else {
          setSelectedProjectVersion(null);
        }
      }
      setLoading(false);
    }
  };

  const getProjectVersion = async projectVersionId => {
    setLoading(true);
    const { data, error } = await client.fetch(`project_versions/${projectVersionId}`);
    if (error) {
      toast.error(t('toast.errorGetProject'));
    } else {
      setSelectedProjectVersion(data);
    }
    setLoading(false);
  };

  const createProject = async values => {
    const { data, error } = await client.mutate('projects', values);
    var created = false;
    if (error) {
      toast.error(t('toast.errorCreateProject'));
    } else {
      toast.success(t('toast.successCreateProject'));
      created = true;
    }
    return { created: created, data: data };
  };

  const deleteProject = async id => {
    const { error } = await client.delete(['projects', id]);
    if (error) {
      toast.error(t('toast.errorDeleteProject'));
    } else {
      toast.success(t('toast.successDeleteProject'));
      const newProjects = projects;
      const deletedProject = projects.find(item => item.id === id);
      const projectIndex = projects.indexOf(deletedProject);
      newProjects.splice(projectIndex, 1);
      setSelectedProject(projects[projects.length - 1]);
      await getProjects();
    }
  };

  const editProject = async (id, values) => {
    const { data, error } = await client.mutate(['projects', id], values);
    var edited = false;

    if (error) {
      toast.error(t('toast.errorEditProject'));
    } else {
      toast.success(t('toast.sucessEditProject'));
      edited = true;
    }
    return { data: data, edited: edited };
  };

  const createProjectVersion = async (files, filename) => {
    const payload = {
      'project-id': selectedProject?.id,
      file: await toBase64(files[0]),
      version: selectedProject['project-versions'].length + 1,
      filename: filename
    };

    promiseToast({
      url: 'project_versions',
      request: payload,
      successText: t('toast.successCreatedVersion'),
      errorText: t('toast.errorCreatedVersion')
    }).then(data => {
      getProject(true, data.project.id);
    });
  };

  const confirmApproveProject = () => {
    Swal.fire({
      title: t('warning.warningApproveProject'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('button.approve'),
      showCloseButton: true
    }).then(result => {
      if (result.isConfirmed) {
        approveProject();
      }
    });
  };

  const approveProject = async () => {
    const { error } = await client.mutate(['projects', selectedProject.id], {
      approved: true
    });

    if (error) {
      toast.error('Erro ao aprovar projeto.');
    } else {
      toast.success(t('toast.successApproveProject'));
      await getProjects();
      setSelectedProject({
        ...selectedProject,
        approved: true
      });
    }
  };

  const approveProjectVersion = async () => {
    const { error } = await client.mutate(['project_versions', selectedProjectVersion.id], {
      approved: true
    });
    setLoading(true);

    if (!error) {
      getProject(true, selectedProjectVersion.project.id);
    }
    setLoading(false);
  };

  const createProjectVersionComment = async message => {
    const { data, error } = await client.mutate('comments', {
      'project-version-id': selectedProjectVersion.id,
      body: message
    });

    if (error) {
      toast.error('Erro ao comentar.');
    } else {
      setSelectedProjectVersion({
        ...selectedProjectVersion,
        comments: selectedProjectVersion.comments ? [...selectedProjectVersion.comments, data] : [data]
      });
    }
  };

  const deleteProjectVersionComment = async commentId => {
    const { error } = await client.delete(['comments', commentId]);

    if (error) {
      toast.error('Erro ao comentar.');
    } else {
      toast.success(t('toast.successDeleteComment'));
      setSelectedProjectVersion({
        ...selectedProjectVersion,
        comments: selectedProjectVersion.comments.filter(c => c.id !== commentId)
      });
    }
  };

  const deleteProjectVersion = async projectVersion => {
    const { error } = await client.delete(['project_versions', projectVersion.id]);

    if (error) {
      toast.error(t('toast.errorDeleteVersion'));
    } else {
      toast.success(t('toast.successDeleteVersion'));
      getProject(true, projectVersion.project.id);
    }
  };

  const addStudentToSelectedProject = async studentId => {
    setLoading(true);
    const users = selectedProject.users.map(u => u.id);
    const newUsers = [...users, studentId];
    const { data, error } = await client.mutate(['projects', selectedProject.id], {
      'user-ids': newUsers
    });
    if (error) {
      toast.error('Erro ao adicionar participante.');
    } else {
      setSelectedProject(data);
      setLoading(false);
    }
  };

  const deleteStudentFromSelectedProject = async studentId => {
    setLoading(true);
    const users = selectedProject.users.map(u => u.id);
    const newUsers = users.filter(u => u !== studentId);
    const { data, error } = await client.mutate(['projects', selectedProject.id], {
      'user-ids': newUsers
    });
    if (error) {
      toast.error('Erro ao retirar integrante.');
    } else {
      setSelectedProject(data);
      setLoading(false);
    }
  };

  const getAllStudents = async allProfiles => {
    try {
      const { data } = await client.fetch(`users?filter[profile]=${allProfiles}`);

      setAllStudents(filterNonParticipantStudents(data));
      setFilteredStudents(filterNonParticipantStudents(data));
    } catch (e) {
      toast.error(t('toast.errorGetStudents'));
    }
  };

  const studentFilter = searchField => {
    if (!searchField) {
      setFilteredStudents(allStudents);
    } else {
      const newStudentList = allStudents?.filter(student => {
        const includesText = !searchField || student.name.toLowerCase().includes(searchField.toLowerCase());

        if (includesText) {
          return student;
        }
      });
      setFilteredStudents(newStudentList);
    }
  };

  const filterNonParticipantStudents = students => {
    const newStudentList = students.filter(student => {
      const isOnSelectedProject = selectedProject && selectedProject.users.map(u => u.id).includes(student.id);
      if (!isOnSelectedProject) {
        return student;
      }
    });
    return newStudentList;
  };

  const createEvent = async values => {
    const { data, error } = await client.mutate('events', values);
    if (error) {
      toast.error(t('toast.errorCreateEvent'));
    } else {
      toast.success(t('events.toastEventSuccessCreated'));
    }
    await getEvents();
  };

  const updateEvent = async values => {
    const { data, error } = await client.mutate(['events', editingEvent.id], values);
    if (error) {
      toast.error('Erro ao editar evento.');
    } else {
      toast.success(t('toast.successEditEvent'));
      const newEvents = events.map(e => (e.id === editingEvent.id ? data : e));
      setEvents(newEvents);
    }
  };

  const getEvents = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`events?filter[project_id]=${selectedProject?.id}`);
    if (error) {
      toast.error('Erro ao buscar eventos do projeto.');
    } else {
      setEvents(data);
      setLoading(false);
    }
  };

  return (
    <ProjectsContext.Provider
      value={{
        loading,
        projects,
        setProjects,
        getProjectVersion,
        selectedProjectVersion,
        setSelectedProjectVersion,
        getProjects,
        selectedProject,
        setSelectedProject,
        createProject,
        deleteProject,
        editProject,
        createProjectVersion,
        confirmApproveProject,
        getAllStudents,
        studentFilter,
        addStudentToSelectedProject,
        filteredStudents,
        createProjectVersionComment,
        deleteProjectVersionComment,
        deleteProjectVersion,
        deleteStudentFromSelectedProject,
        getProject,
        createEvent,
        updateEvent,
        events,
        setEvents,
        getEvents,
        editingEvent,
        setEditingEvent,
        approveProjectVersion
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
