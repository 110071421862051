import { useClient } from 'jsonapi-react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import SelectComponent from './select';
import Loader from 'app/components/loader';

export default function StepCourse(props) {
  const { step, form, essayId, essay, classroomError } = props;

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const client = useClient();

  const getCourses = async () => {
    const { data, error } = await client.fetch('courses?simple=true');
    if (error) {
      toast.error('Erro ao buscar cursos');
    } else {
      setCourses(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (essay && courses.length > 0) {
      const newCourseIds = [];
      const newClassroomIds = [];
      essay?.['courses-ids']?.forEach(id => {
        const classrooms = courses?.find(item => item.id === id)?.classrooms?.filter(c => essay?.['classrooms-ids']?.includes(c.id));
        newCourseIds.push(id);
        newClassroomIds.push(
          classrooms?.map(item => ({
            value: item.id,
            label: item.title
          }))
        );
      });
      form.setFieldValue('course_ids', newCourseIds);
      form.setFieldValue('classroom_ids', newClassroomIds);
    }
  }, [essay, courses]);

  return (
    step === 'curso' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">Nova redação</h2>
        </div>

        <div className="new-questions__body">
          <p className="new-questions__subtitle">Escolha pelo menos uma turma para direcionar a redação</p>
          {loading && <Loader />}
          {courses?.length > 0 &&
            form?.values.classroom_ids?.map((classroom, i, array) => {
              return (
                <SelectComponent
                  array={array}
                  form={form}
                  i={i}
                  courses={courses}
                />
              );
            })}
          {classroomError && <span style={{ color: 'red' }}>Campo obrigatório</span>}
          {courses.length > 0 && (
            <div className="form__row">
              <button
                type="button"
                className="btn btn--link"
                onClick={() => form.setFieldValue('classroom_ids', [...form.values.classroom_ids, []])}
              >
                Adicionar um curso
              </button>
            </div>
          )}
        </div>
      </div>
    )
  );
}
