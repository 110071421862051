import { useTranslation } from 'react-i18next';
import { ShadowContainer } from './ShadowContainer';
import TopBarPng from '../../images/onboarding/dashboard/topBar.png';

export function TopBar() {
  const { t } = useTranslation();

  return (
    <div className="mb-50">
      
      <h1 className="title-option-onboarding">{t('onboarding.topBar')}</h1>
      <p className="text-option-onboarding">{t('onboarding.topBarDescription')}</p>

      <div >
        <img src={TopBarPng} />
      </div>
    </div>
  );
}
