import React from 'react';
import { Link } from '@reach/router';
import { FiEdit, FiMessageCircle } from 'react-icons/fi';
import userAvatar from 'app/images/user-avatar.svg';
import { t } from 'i18next';

export const StudentCard = ({ id, name, email, answers, comments, lessons, performance, image }) => (
  <div className="students-card">
    <header className="students-card__header">
      <img
        src={image || userAvatar}
        alt="Foto do aluno"
        className="students-card__img"
      />

      <div className="students-card__data">
        <h3 className="students-card__name">{name}</h3>
        <p className="students-card__id">{email}</p>
      </div>
    </header>

    <div className="students-card__body">
      <div className="students-card__widget">
        <p className="students-card__widget-title">Índice de engajamento</p>
        <div className="students-card__widget-container">
          <span className="students-card__widget-value">
            <FiEdit /> {answers}
          </span>
          <span className="students-card__widget-value">
            <FiMessageCircle /> {comments}
          </span>
        </div>
      </div>

      <div className="students-card__columns">
        <div className="students-card__widget">
          <p className="students-card__widget-title">{t('courses.activities')}</p>
          <div className="students-card__widget-container">
            <span className="students-card__widget-value">{lessons}</span>
          </div>
        </div>
        <div className="students-card__widget">
          <p className="students-card__widget-title">Performance</p>
          <div className="students-card__widget-container">
            <span className="students-card__widget-value">{performance === 100 ? 100 : performance.toFixed(2)}%</span>
          </div>
        </div>
      </div>
    </div>

    <div className="students-card__footer">
      <Link
        to={`/metricas/perfil/${id}`}
        className="btn btn--outline btn--small btn--full"
      >
        Perfil completo
      </Link>
    </div>
  </div>
);
