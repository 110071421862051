import React, { useState, useEffect, useRef } from 'react';
import { FiBell } from 'react-icons/fi';
import Notification from './Notification';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import ActionCableConsumer from 'app/utils/actionCableConsumer';
import { useSession } from 'app/hooks/useSession';
import { useNotification } from 'app/hooks/useNotification';

export default function Notifications() {
  const { getNotificationsFeed, setNotificationsFeed, notificationsFeed, notificationFeedCount, setNotificationFeedCount } = useNotification();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { session } = useSession();

  const client = useClient();
  const menuRef = useRef();

  function handleReadBtn() {
    notificationsFeed.map(async n => {
      if (n.unread) {
        n.unread = false;
        const { data, error, errors } = await client.mutate(['notifications', n.id], {
          unread: false
        });

        if (error) toast.error(t('toast.errorNotification'));
      }
    });
    setNotificationFeedCount(0);
  }

  useEffect(() => {
    const closeOnEscapeKeyDown = event => event.key === 'Escape' && isOpen && setIsOpen(false);
    const closeOnClickOutside = event => !menuRef.current.contains(event.target) && setIsOpen(false);

    document.addEventListener('keydown', closeOnEscapeKeyDown);
    document.addEventListener('mouseup', closeOnClickOutside);

    getNotificationsFeed();

    return () => {
      document.removeEventListener('keydown', closeOnEscapeKeyDown);
      document.removeEventListener('mouseup', closeOnClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className="notifications"
      aria-label="Notificações"
      ref={menuRef}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="notifications__toggle"
      >
        <FiBell />
        {notificationFeedCount >= 1 && <span className="notifications__badge">{notificationFeedCount}</span>}
      </button>

      {isOpen && (
        <div className="notifications__content">
          <div className="notifications__header">
            <h3 className="notifications__title">{t('header.notificationTitle')}</h3>
            {notificationsFeed?.length > 0 && (
              <button
                onClick={handleReadBtn}
                className="notifications__read-btn"
              >
                {t('header.notificationRead')}
              </button>
            )}
          </div>

          <div className="notifications__body">
            {notificationsFeed.length === 0 && (
              <EmptyState
                type="notification"
                title={t('emptyState.notificationTitle')}
              />
            )}

            {notificationsFeed.map((notification, index) => (
              <Notification
                key={index}
                notification={notification}
                notifications={notificationsFeed}
                setNotificationCount={setNotificationFeedCount}
                notificationCount={notificationFeedCount}
                setNotifications={setNotificationsFeed}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
