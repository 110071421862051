import React, { useEffect } from 'react';
import EmptyState from 'app/components/EmptyState';
import { Link } from '@reach/router';
import LessonCard from './LessonCard';
import { useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Loader from 'app/components/loader';
import { useClient } from 'jsonapi-react';
import { t } from 'i18next';

export default function Content(props) {
  const { selectedDiscipline, disciplines, publish, lessons, loading, learningSystemId, selectedModule, manageModules, getLessons } = props;
  const [key, setKey] = useState(1);
  const client = useClient();

  const onSuccess = () => {
    setKey(key + 1);
  };

  const moduleStatusClass = publish => {
    switch (publish) {
      case true:
        return 'badge--success';
      case false:
        return 'badge--danger';
    }
  };

  const moduleText = publish => {
    switch (publish) {
      case true:
        return 'Publicado';
      case false:
        return 'Não publicado';
    }
  };

  return (
    <div className="new-questions__content">
      <div className="new-questions__header">
        <div className="new-questions__group">
          <span className={`badge badge--tiny ${moduleStatusClass(publish?.publish)}`}>{moduleText(publish?.publish)}</span>
          {selectedDiscipline && <h1 className="new-questions__title">Aulas - {disciplines[disciplines.findIndex(object => object.id == selectedDiscipline.id)]?.body}</h1>}
        </div>
        {selectedDiscipline && (
          <Link
            to={`/lms/criar/aula/${selectedDiscipline.id}/nome`}
            className="btn btn--primary btn--wide"
            state={{ disciplineId: selectedDiscipline.id, moduleId: selectedModule.id, editLesson: false }}
          >
            {t('button.createLesson')}
          </Link>
        )}
      </div>

      <div className="new-questions__body">
        {manageModules && (
          <EmptyState
            type="content"
            title="Selecione uma disciplina ao lado"
          />
        )}

        {loading && !manageModules && <Loader />}

        {!loading && selectedModule && selectedDiscipline && lessons.length === 0 && (
          <EmptyState
            type="content"
            title="Crie aulas com o botão acima."
          />
        )}

        {!loading && selectedModule && !selectedDiscipline && lessons.length === 0 && (
          <EmptyState
            type="content"
            title="Selecione uma disciplina ao lado."
          />
        )}

        {!loading && (
          <Droppable droppableId="droppable">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <div className="tab__cards tab__cards--h">
                  {lessons.map((item, i) => {
                    return (
                      <Draggable
                        key={i}
                        draggableId={item.id.toString()}
                        index={i}
                      >
                        {provided => (
                          <div
                            key={i}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <LessonCard
                              index={i}
                              onSuccess={getLessons}
                              disciplineId={selectedDiscipline?.id}
                              lessonId={item.id}
                              title={item.body}
                              taxonomies={item.taxonomies}
                              messageCount={item.message_count}
                              files={item.learning_system_files.length}
                              questions={item.learning_system_question_books_count}
                              activities={item.learning_system_activities_count}
                              learningSystemId={learningSystemId}
                              lesson={item}
                              quizItemsCount={item.quiz_items.length}
                              selectedDisciplineId={selectedDiscipline?.id}
                              selectedModuleId={selectedModule?.id}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              </div>
            )}
          </Droppable>
        )}
      </div>
    </div>
  );
}
