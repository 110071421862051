import React, { useState, useEffect } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, navigate } from '@reach/router';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import TextareaAutosize from 'react-autosize-textarea';
import toast from 'react-hot-toast';
import moment from 'moment';
import Modal from 'app/components/Modal';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';
import promiseRequest from 'app/utils/promiseToast';
import BreadCrumbs from 'app/components/BreadCrumbs';

export function ActivitySingle(props) {
  const { uri } = props;

  const { t } = useTranslation();
  const client = useClient();
  const { promiseToast, isSubmitting } = promiseRequest();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activity, setActivity] = useState();
  const [fileType, setFileType] = useState(t('activity.toggleFile'));

  useEffect(() => {
    getActivity();
  }, []);

  const getActivity = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.fetch(['activities', props.id]);
      setActivity(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      toast.error(t('toast.errorGetActivity'));
    }
  };

  const form = useFormik({
    initialValues: { file: null, url: null, comment: '' },
    onSubmit: () => confirmSubmit()
  });

  const confirmSubmit = () => {
    setShowModal(true);
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const submitForm = async () => {
    if (isSubmitting) {
      return false;
    }

    const { values } = form;
    const parsedForm = {
      ...values,
      file: undefined,
      url: undefined,
      comment: undefined,
      'comments-attributes': [{ body: values?.comment }],
      'activity-file-attributes': { url: null, file: null }
    };

    if (values.file && values.file !== '') {
      parsedForm['activity-file-attributes'].file = await toBase64(values.file);
    }

    if (values.url && values.url !== '') {
      parsedForm['activity-file-attributes'].url = values.url;
    }

    const onSuccess = () => {
      setShowModal(false);
      navigate(-1);
    };

    promiseToast({
      url: `activities/${props.id}/activity_students`,
      request: parsedForm,
      onSuccess,
      successText: t('toast.successSubmitActivity'),
      errorText: t('toast.errorSubmitActivity')
    });
  };

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: '/atividade-complementar',
        name: 'Atividade Complementar',
        isActive: false
      },
      {
        route: uri,
        name: activity?.title,
        isActive: true
      }
    ]
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <main className="main-content main-content--block fadeIn">
          <BreadCrumbs data={breadCrumbs} />

          <div className="page">
            <div className="page__content">
              <div className="activity-card__header">
                <div className="activity-card__group">
                  {/* <span className="activity-card__tag">
                    {t('textsCommon.availableUntil')}: {moment(activity['finish-at']).format(t('datesForm.formatDate'))}
                  </span> */}
                  <h2 className="activity-card__title activity-card__title--md">{activity.title}</h2>

                  <span className="activity-card__info">
                    {t('textsCommon.module')} {activity.modulus} | {t('textsCommon.unit')} {activity.unit} | {activity['class-hours'] < 2 ? `${activity['class-hours']} ${t('tasks.hour')}` : `${activity['class-hours']} ${t('tasks.hour')}`}
                  </span>
                </div>
                {activity['activity-file'] && (
                    <a
                        href={activity['activity-file']?.url || activity['activity-file']?.file}
                        target="_blank"
                        className="btn btn--outline"
                      >
                        {t('button.supportMaterial')}
                    </a>   
                )}
              </div>

              <div className="activity-card__body">
                <p className="activity-card__description">{activity.description}</p>

                <form
                  method="post"
                  className="form"
                  onSubmit={form.handleSubmit}
                >
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Insira o arquivo"
                  >
                    {[t('activity.toggleFile'), 'URL'].map((alternative, index) => (
                      <React.Fragment key={index}>
                        <input
                          type="radio"
                          name="fileType"
                          autoComplete="off"
                          id={`fileType-${index}`}
                          checked={alternative === fileType}
                          onChange={() => {
                            if (alternative === t('activity.toggleFile')) {
                              form.setFieldValue('url', null);
                            } else {
                              form.setFieldValue('file', null);
                            }

                            setFileType(alternative);
                          }}
                        />
                        <label
                          className="btn btn--outline"
                          htmlFor={`fileType-${index}`}
                        >
                          {alternative}
                        </label>
                      </React.Fragment>
                    ))}
                  </div>

                  {fileType === t('activity.toggleFile') && (
                    <fieldset className="form__row">
                      <label className="form__custom-file-input">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          onChange={e => form.setFieldValue('file', e.target.files[0])}
                        />
                        <span className="form__control">{form.values.file ? form.values.file.name : t('activity.placeholderUploadFile')}</span>
                      </label>
                    </fieldset>
                  )}

                  {fileType === 'URL' && (
                    <div className="form__row">
                      <input
                        className="form__control"
                        id="url"
                        name="url"
                        type="url"
                        placeholder={t('activity.placeholderSentUrl')}
                        onChange={form.handleChange}
                        defaultValue={form.values.url}
                      />
                    </div>
                  )}

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="comment"
                    >
                      *Realize um comentário para enviar com sucesso
                    </label>

                    <TextareaAutosize
                      id="comment"
                      name="comment"
                      placeholder={t('activity.placeholderWriteComment')}
                      onChange={form.handleChange}
                      defaultValue={form.values.comment}
                    />
                  </div>

                  <button
                    className="btn btn--primary btn--wide"
                    type="submit"
                  >
                    {t('button.submit')}
                  </button>
                </form>
              </div>
            </div>

            <div className="page__aside" />
          </div>

          <Modal
            show={showModal}
            onClose={() => setShowModal(false)}
          >
            <h3 className="modal__dialog-title">{t('activity.warningSubmit')}</h3>

            <div className="form__columns">
              <div className="form__col u-text-right">
                <button
                  onClick={() => setShowModal(false)}
                  className="btn btn--outline btn--wide"
                  type="button"
                >
                  {t('button.cancel')}
                </button>
              </div>
              <div className="form__col">
                <button
                  onClick={() => submitForm()}
                  className={`btn btn--primary btn--wide ${isSubmitting && 'disabled'}`}
                  type="button"
                >
                  {t('button.submit')}
                </button>
              </div>
            </div>
          </Modal>
        </main>
      )}
    </>
  );
}
