function EmptyState(props) {
  const {options, donate, changeAction, index} = props

  return(
    <label htmlFor={index}>
      <div className="donate-card">
        <input type="radio" value={options.price} name="donation" id={index} checked={donate === options.price} onChange={(e) => changeAction(options.price)} />
        <div className="donate-card__title">{options.title}</div>
        <div className="donate-card__price">R$ {options.price},00</div>
        <div className="donate-card__description">{options.description}</div>
      </div>
    </label>
  )}

export default EmptyState
