import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { navigate } from '@reach/router';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import Modal from 'app/components/Modal';
import TaxonomiesForm from './TaxonomiesForm';
import { t } from 'i18next';
import { i18n } from 'app/components/i18n/i18n';
import promiseRequest from 'app/utils/promiseToast';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import { useSession } from 'app/hooks/useSession';

export default function QuestionBookContent(props) {
  const { step, courses, setCurrentStep, coursesLoading, editingQuestionBook } = props;
  const client = useClient();
  const { promiseToast, isSubmitting } = promiseRequest();
  const [showModal, setShowModal] = useState(false);
  const [taxonomies, setTaxonomies] = useState([]);
  const [modules, setModules] = useState([]);
  const animatedComponents = makeAnimated();
  const isAdmin = checkIncludeProfiles({ only: ['admin'] });
  const { session } = useSession();
  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LOCALE);
  }, []);

  const createQuestionbook = async () => {
    const form = createQuestionBookForm.values;
    delete form.selectedTaxonomies;

    const requestParams = {
      title: form.title,
      visibility: form.visibility,
      'course-ids': form.courseId.map(item => item.value),
      'learning-system-ids': form.learningSystemId,
      'taxonomy-ids': form.taxonomyIds,
      authored_by_user: form.authored_by_user,
      official_content: form.official_content
    };
    const path = editingQuestionBook?.id ? ['/question_books', editingQuestionBook.id] : '/question_books';

    promiseToast({
      url: path,
      request: requestParams,
      successText: t('toast.successSaving'),
      errorText: t('toast.errorSaveQuestionBook')
    }).then(data => {
      navigate(`/apostilas/gerenciar/${data.id}`);
    });
  };

  const createQuestionBookForm = useFormik({
    initialValues: {
      title: '',
      visibility: 'train',
      courseId: [],
      learningSystemId: '',
      taxonomyIds: [],
      selectedTaxonomies: [],
      authored_by_user: false,
      official_content: false
    },
    onSubmit: createQuestionbook
  });

  const getTaxonomies = async () => {
    const { data, error } = await client.fetch('taxonomies');
    if (error) {
      toast.error(t('toast.errorGetSubjects'));
    } else {
      setTaxonomies(data);
    }
  };

  const getModules = async id => {
    const queryParams = id.length > 0 ? id.map(i => i.value).join(',') : id;
    const { data, error } = await client.fetch(`learning_systems?filter[course_id]=${queryParams}`);
    if (error) {
      toast.error(t('toast.errorGetModule'));
    } else {
      setModules(data);
    }
  };

  const getSelectedCourses = () => {
    return editingQuestionBook['course-ids']?.map(courseId => {
      const course = courses.find(c => c.id === courseId);
      return { value: course.id, label: course.title };
    });
  };

  const addTaxonomy = t => {
    createQuestionBookForm.setFieldValue('taxonomyIds', [...createQuestionBookForm.values.taxonomyIds, t.id]);
    createQuestionBookForm.setFieldError('selectedTaxonomies', [...createQuestionBookForm.values.selectedTaxonomies, t]);
  };

  const removeTaxonomy = t => {
    const newTaxonomieIds = createQuestionBookForm.values.taxonomyIds.filter(a => a !== t.id);
    const newTaxonomies = createQuestionBookForm.values.selectedTaxonomies.filter(a => a.id !== t.id);
    createQuestionBookForm.setFieldValue('taxonomyIds', newTaxonomieIds);
    createQuestionBookForm.setFieldValue('selectedTaxonomies', newTaxonomies);
  };

  useEffect(() => {
    getTaxonomies();
  }, []);

  useEffect(() => {
    if (createQuestionBookForm.values.courseId !== '' && createQuestionBookForm.values.courseId !== undefined) {
      getModules(createQuestionBookForm.values.courseId);
    } else {
      setModules([]);
    }
  }, [createQuestionBookForm.values.courseId]);

  useEffect(() => {
    if (editingQuestionBook) {
      createQuestionBookForm.setValues({
        title: editingQuestionBook.title,
        courseId: getSelectedCourses(),
        learningSystemId: editingQuestionBook['learning-system-ids']?.[0],
        taxonomyIds: editingQuestionBook['taxonomy-ids'],
        selectedTaxonomies: editingQuestionBook.taxonomies,
        authored_by_user: editingQuestionBook['authored-by-user'],
        official_content: editingQuestionBook.official_content
      });
    }
  }, [editingQuestionBook]);

  const btnsStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  };

  const [submited, setSubmited] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [courseError, setCourseError] = useState('');
  const [taxonomyError, setTaxonomyError] = useState('');

  useEffect(() => {
    if (submited) {
      if (!Boolean(createQuestionBookForm?.values?.title)) {
        setErrorTitle('Campo obrigatório');
      }
      if (createQuestionBookForm?.values?.courseId?.length === 0) {
        setCourseError('Selecione ao menos um curso');
      }
      if (createQuestionBookForm?.values?.selectedTaxonomies?.length === 0) {
        setTaxonomyError('Selecione ao menos uma matéria');
      }
    }
  }, [createQuestionBookForm.values, submited]);

  const handleSubmit = e => {
    setSubmited(true);
    e.preventDefault();

    if (step === 'nome' && !editingQuestionBook) {
      if (Boolean(createQuestionBookForm.values.courseId) && Boolean(createQuestionBookForm.values.title)) {
        setSubmited(false);
        setCurrentStep(2);
        navigate('/apostilas/criar/materias');
      }
      return;
    } else if (step === 'nome' && editingQuestionBook) {
      if (Boolean(createQuestionBookForm.values.courseId) && Boolean(createQuestionBookForm?.values?.title)) {
        setCurrentStep(2);
        navigate(`/apostilas/criar/materias/${editingQuestionBook.id}`);
      }
      return;
    } else if (step === 'materias') {
      if (createQuestionBookForm?.values?.taxonomyIds?.length > 0) {
        createQuestionbook();
      }
      return;
    }
  };

  return (
    <div className="page page--wrap">
      <div className="page__col">
        <form
          className="form form--step-by-step"
          method="post"
          onSubmit={handleSubmit}
        >
          {step === 'nome' && (
            <div className="page__content fadeIn u-mb-5">
              <div className="new-questions__header">
                <h2 className="new-questions__title">{t('exams.newQuestionBook')}</h2>
              </div>
              <div className="fadeIn">
                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="title"
                  >
                    * {t('exams.questionBookName')}
                  </label>
                  <input
                    className="form__control"
                    placeholder={t('exams.placeholderQuestionBookName')}
                    id="title"
                    name="title"
                    type="text"
                    value={createQuestionBookForm?.values?.title}
                    onChange={e => createQuestionBookForm.setFieldValue('title', e.target.value)}
                  />
                  {submited && !createQuestionBookForm?.values?.title && <span style={{ color: 'red' }}>{errorTitle}</span>}
                </div>
                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="courseId"
                  >
                    * {t('exams.selectCourse')}
                  </label>

                  <Select
                    classNamePrefix="react-multi-select"
                    openMenuOnFocus
                    noOptionsMessage={() => 'Sem opções'}
                    isDisabled={coursesLoading}
                    value={createQuestionBookForm?.values?.courseId}
                    placeholder={coursesLoading ? 'Carregando...' : 'Selecione o curso'}
                    onChange={item => createQuestionBookForm.setFieldValue('courseId', item)}
                    components={{
                      animatedComponents,
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null
                    }}
                    closeMenuOnSelect={false}
                    isSearchable={true}
                    options={courses?.map(item => {
                      return { value: item?.id, label: item?.title };
                    })}
                    isMulti
                  />
                  {submited && createQuestionBookForm?.values?.courseId?.length === 0 && <span style={{ color: 'red' }}>{courseError}</span>}
                </div>
                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="learningSystemId"
                  >
                    {t('exams.selectModule')}
                  </label>
                  <select
                    className="form__select"
                    name="learningSystemId"
                    id="learningSystemId"
                    value={createQuestionBookForm.values.learningSystemId}
                    onChange={e => createQuestionBookForm.setFieldValue('learningSystemId', e.target.value)}
                    disabled={createQuestionBookForm.values.courseId === ''}
                  >
                    <option value="blank">{t('exams.selectModule')}</option>
                    {modules &&
                      modules?.map(module => {
                        const moduleCourse = courses?.find(item => item.id === module['course-id']);
                        return (
                          <option
                            key={module.id}
                            value={module.id}
                          >
                            {module?.title + ' - ' + moduleCourse?.title}
                          </option>
                        );
                      })}
                  </select>
                  {createQuestionBookForm.touched.learningSystemId && createQuestionBookForm.errors.learningSystemId && <span style={{ color: 'red' }}>{createQuestionBookForm.errors.learningSystemId}</span>}
                </div>

                <div className="form__row">
                  {(!editingQuestionBook?.id || editingQuestionBook?.creator?.id === session?.user?.id || isAdmin) && (
                    <div className="form__check form__switch">
                      <input
                        className="form__check-input"
                        id="authorialContent"
                        name="authorialContent"
                        type="checkbox"
                        checked={createQuestionBookForm?.values?.authored_by_user}
                        onClick={() => {
                          createQuestionBookForm?.setFieldValue('authored_by_user', !createQuestionBookForm?.values?.authored_by_user);
                        }}
                      />
                      <label htmlFor="authorialContent">
                        Esta apostila é de minha autoria e <strong>somente eu posso editá-la.</strong>
                      </label>
                    </div>
                  )}
                  {isAdmin && (
                    <div className="form__check form__switch">
                      <input
                        className="form__check-input"
                        id="officialContent"
                        name="officialContent"
                        type="checkbox"
                        checked={createQuestionBookForm?.values?.official_content}
                        onClick={() => {
                          createQuestionBookForm?.setFieldValue('official_content', !createQuestionBookForm?.values?.['official_content']);
                        }}
                      />
                      <label htmlFor="officialContent">
                        Este conteúdo é <strong>oficial;</strong> apenas a equipe administrativa têm permissão para editá-lo.
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {step === 'materias' && (
            <div className="page__content fadeIn u-mb-5">
              <div className="new-questions__header">
                <h2 className="new-questions__title">{t('exams.newQuestionBook')}</h2>
              </div>
              <div className="new-questions__body">
                <p className="new-questions__subtitle">{t('exams.chooseSubject')}</p>
                <h3>* {t('textsCommon.matters')}</h3>
                <button
                  type="button"
                  className="btn btn--primary btn--full btn--outline-dashed"
                  onClick={() => setShowModal(true)}
                >
                  + {t('textsCommon.buttonAddMatters')}
                </button>

                <div className="round-dg-wrapper">
                  <table className="round-dg round-dg--light">
                    <tbody className="round-dg__body">
                      {(createQuestionBookForm.values.selectedTaxonomies || []).map((item, index) => {
                        const isSelected = createQuestionBookForm.values.selectedTaxonomies.find(s => s.id === item.id);
                        return (
                          <div className="card card--h u-mb-2">
                            <div
                              className="card__body"
                              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                            >
                              <h3 className="card__title">{item.name}</h3>
                              <h4 className="card__subtitle">{typeof item?.questionCount === 'number' && `${item?.questionCount} ${t('exams.questions')}`}</h4>
                            </div>
                            <div className="card__footer">
                              <button
                                onClick={() => {
                                  if (isSelected) {
                                    removeTaxonomy(item);
                                  } else {
                                    addTaxonomy(item);
                                  }
                                }}
                                className={isSelected ? 'btn btn--outline btn--wide active' : 'btn btn--outline btn--wide'}
                              >
                                {isSelected ? t('button.remove') : t('button.add')}
                              </button>
                            </div>
                          </div>
                        );
                      })}
                      {submited && createQuestionBookForm?.values?.taxonomyIds?.length === 0 && <span style={{ color: 'red' }}>{taxonomyError}</span>}
                    </tbody>
                  </table>
                </div>
              </div>

              <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                contentClassName="modal__content-md"
              >
                <TaxonomiesForm
                  taxonomies={taxonomies}
                  form={createQuestionBookForm}
                />
              </Modal>
            </div>
          )}
          <div style={btnsStyles}>
            {step !== 'nome' && (
              <button
                type="button"
                className="btn btn--outline btn--wide u-mr-4"
                onClick={() => {
                  if (editingQuestionBook) {
                    navigate(`/apostilas/criar/nome/${editingQuestionBook.id}`);
                    setCurrentStep(1);
                  } else {
                    navigate('/apostilas/criar/nome');
                    setCurrentStep(1);
                  }
                }}
              >
                {t('exams.previous')}
              </button>
            )}

            <button
              type="submit"
              className={isSubmitting ? 'btn btn--primary btn--wide disabled' : 'btn btn--primary btn--wide'}
            >
              {step !== 'materias' ? t('button.next') : t('button.finish')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
