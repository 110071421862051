import React, { useEffect } from 'react';
import MainSidebar from 'app/components/Sidebar';
import { Link } from '@reach/router';

export default function Sidebar(props) {
  const { currentQuestion, setCurrentQuestion, taxonomies, exam, selectQuestionBookTaxonomy } = props;
  return (
    <MainSidebar hasBrand={false}>
      <div className="student-response">
        <div className="student-response__header">
          <h2
            className="student-response__title"
            title={exam?.title}
          >
            {exam?.title}
          </h2>
        </div>

        <div className="student-response__body">
          <nav className="questions-menu">
            {exam?.['question-book-taxonomies']?.map((qbTaxonomy, i) => {
              const taxonomy = taxonomies.find(taxonomy => taxonomy.id === qbTaxonomy.taxonomy_id);
              return (
                <div key={i}>
                  <h3 className="questions-menu__title">{taxonomy?.name}</h3>
                  <div className="questions-menu__section">
                    <div className="questions-menu__list">
                      {qbTaxonomy.question_ids.map((questionId, j) => {
                        const index = j;

                        const className = currentQuestion === questionId ? `questions-menu__link active` : exam.taxonomies[i].answers.find(q => q.questionId === questionId) ? 'questions-menu__link disabled' : 'questions-menu__link';

                        const questionNumber = index < 9 ? `0${index + 1}` : `${index + 1}`;

                        return (
                          <button
                            className={className}
                            onClick={() => {
                              setCurrentQuestion(questionId);
                              selectQuestionBookTaxonomy(i);
                            }}
                            key={questionId}
                          >
                            {questionNumber}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    </MainSidebar>
  );
}
