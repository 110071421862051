import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import TabNavBar from './TabNavBar';
import GeneralData from './GeneralData';
import CourseData from './CourseData';
import ClassData from './ClassData';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';

export function ActivityData(props) {
  const { t } = useTranslation();
  const client = useClient();
  const [activeTabs, setActiveTabs] = useState('geral');
  const [isLoading, setIsLoading] = useState(true);
  const [activity, setActivity] = useState();

  useEffect(() => {
    getActivity();
  }, []);

  const getActivity = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.fetch(['activities', props.id]);
      setActivity(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      toast.error(t('toast.errorGetActivity'));
    }
  };

  return (
    <>
      <main className="main-content main-content--block fadeIn">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <TabNavBar
              activeTabs={activeTabs}
              setActiveTabs={setActiveTabs}
            />

            {activeTabs === 'geral' && <GeneralData activity={activity} />}
            {activeTabs === 'curso' && <CourseData activity={activity} />}
            {activeTabs === 'turma' && <ClassData activity={activity} />}
          </>
        )}
      </main>
    </>
  );
}
