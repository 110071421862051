import React, { useEffect, useState } from 'react';
import { FiChevronLeft, FiStar } from 'react-icons/fi';
import StarRating from './StarRating';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { t } from 'i18next';

export function QuizDataProposal(props) {
  const { surveyId, questionId } = props;
  const client = useClient();
  const [surveyData, setSurveyData] = useState([]);
  const [surveyQuestion, setSurveyQuestion] = useState([]);

  useEffect(() => {
    getSurveyData();
    getSurveyQuestion();
  }, []);

  const getSurveyData = async () => {
    try {
      const { data } = await client.fetch(['surveys', surveyId]);
      setSurveyData(data);
    } catch (e) {
      console.log(e);
      toast.error('Erro ao buscar dados do questionário.');
    }
  };

  const getSurveyQuestion = async () => {
    const { data } = await client.fetch(['survey_questions', questionId]);
    setSurveyQuestion(data);
  };

  const quizStatusClass = () => {
    switch (surveyData?.about?.status) {
      case 'Inativo':
        return 'activity-card__status--danger';
        break;
      case 'Ativo':
        return 'activity-card__status--success';
        break;
    }
  };

  return (
    <>
      <main className="main-content main-content--block fadeIn">
        <div className="filter-bar">
          <button
            onClick={() => history.back()}
            className="filter-bar__back"
          >
            <FiChevronLeft />

            <span className="filter-bar__back-container">
              <span className="filter-bar__title"> {t('nps.buttonIntervention')}</span>
            </span>
          </button>
        </div>

        <div className="page">
          <div className="page__content">
            <div
              className="chart-widget__container u-mb-5"
              style={{ alignItems: 'center' }}
            >
              <div
                className="chart-widget__group"
                style={{ gap: '0' }}
              >
                <div className="chart-widget__meta">
                  <span className={`activity-card__status ${quizStatusClass()} u-mr-2`}>{surveyData?.about?.status}</span>
                </div>

                <h3 className="chart-widget__title chart-widget__title--lg">{surveyData.title}</h3>
                <span className="activity-card__scale">
                  {t('nps.graphicScale')}: <FiStar />
                </span>
              </div>
            </div>

            <h3 className="intervention-proposal__title">{surveyQuestion.question}</h3>

            {surveyQuestion['survey-items'] &&
              surveyQuestion['survey-items'].map((items, index) => (
                <div
                  key={index}
                  className="intervention-proposal"
                >
                  <StarRating rating={items.rate} />
                  <h4 className="intervention-proposal__reply">{items.subtitle}</h4>
                  <p className="intervention-proposal__text">{items.proposal}</p>
                </div>
              ))}
          </div>
        </div>
      </main>
    </>
  );
}
