import React from 'react';
import Modal from 'app/components/Modal';
import QuestionCard from './QuestionCard';

export default function ViewQuestionModal(props) {
  const { show, onClose, question } = props;

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <table className="round-dg round-dg--light u-mb-3">
        <tbody className="round-dg__body">
          <QuestionCard
            hideActions
            question={question}
          />
        </tbody>
      </table>

      <div className="question-card__motivator-text">
        <div
          dangerouslySetInnerHTML={{ __html: question?.['motivator-texts']?.[0]?.title }}
          className="question-card__title"
        />
        <div dangerouslySetInnerHTML={{ __html: question?.['motivator-texts']?.[0]?.body }} />
        <div
          dangerouslySetInnerHTML={{ __html: question?.['motivator-texts']?.[0]?.title }}
          className="question-card__title"
        />
        <div
          dangerouslySetInnerHTML={{ __html: question?.['motivator-texts']?.[0]?.print }}
          className="question-card__print"
        />
      </div>

      <div
        dangerouslySetInnerHTML={{ __html: question?.description }}
        className="question-card__statement"
      />

      {question?.alternatives?.map((item, index) => {
        return (
          <div
            key={index}
            className="question-card__answers"
          >
            <div className="question-card__answer null">
              <div
                dangerouslySetInnerHTML={{ __html: item.name }}
                className="question-card__answer-letter"
              />
              <input
                checked={item['is-correct']}
                type="radio"
                id="235"
              />
              <label
                dangerouslySetInnerHTML={{ __html: item.description }}
                htmlFor="235"
              />
            </div>
          </div>
        );
      })}
    </Modal>
  );
}
