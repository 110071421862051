import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import moment from 'moment';
import reorder from '../../../utils/reorder';

export default function DataGridGenerated({ report, setReport }) {
  const [arrows, setArrows] = useState({
    date: {
      order: 'asc',
      type: 'date',
      column: 'access-date'
    },
    name: {
      order: 'asc',
      type: 'string',
      column: 'name'
    },
    email: {
      order: 'asc',
      type: 'string',
      column: 'email'
    },
    institution: {
      order: 'asc',
      type: 'string',
      column: 'institution'
    },
    inep: {
      order: 'asc',
      type: 'string',
      column: 'inep'
    },
    gre: {
      order: 'asc',
      type: 'string',
      column: 'gre'
    }
  });

  const [sortColumn, setSortColumn] = useState('access-date');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortType, setSortType] = useState('date');
  const [sortedData, setSortedData] = useState(report?.logs);

  useEffect(() => {
    const sortedData = reorder(report?.logs, sortColumn, sortOrder, sortType);
    setSortedData(sortedData);
  }, [report?.logs, sortColumn, sortOrder, sortType]);

  const handleSortClick = (column, type) => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newOrder);
    setSortType(type);

    setArrows(prevState => ({
      ...prevState,
      [column]: {
        ...prevState[column],
        order: newOrder
      }
    }));
  };

  return (
    <div className="round-dg-wrapper">
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: 800 }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '120px' }}
              onClick={() => handleSortClick('access-date', 'date')}
            >
              Data
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2"
                style={{ transform: sortOrder === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)' }}
              >
                <FiChevronDown />
              </span>
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              onClick={() => handleSortClick('live-classroom-title', 'string')}
            >
              Aula
              <span
                className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2"
                style={{ transform: sortOrder === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)' }}
              >
                <FiChevronDown />
              </span>
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              Aluno
              {arrows.name.order === 'asc' ? (
                <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                  <FiChevronDown onClick={() => handleSortClick('name', 'string')} />
                </span>
              ) : (
                <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                  <FiChevronUp onClick={() => handleSortClick('name', 'string')} />
                </span>
              )}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              E-mail
              {arrows.email.order === 'asc' ? (
                <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                  <FiChevronDown onClick={() => handleSortClick('email', 'string')} />
                </span>
              ) : (
                <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                  <FiChevronUp onClick={() => handleSortClick('email', 'string')} />
                </span>
              )}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              Escola
              {arrows.institution.order === 'asc' ? (
                <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                  <FiChevronDown onClick={() => handleSortClick('institution', 'string')} />
                </span>
              ) : (
                <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                  <FiChevronUp onClick={() => handleSortClick('institution', 'string')} />
                </span>
              )}
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '100px' }}
            >
              INEP
              {arrows.inep.order === 'asc' ? (
                <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                  <FiChevronDown onClick={() => handleSortClick('inep', 'string')} />
                </span>
              ) : (
                <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                  <FiChevronUp onClick={() => handleSortClick('inep', 'string')} />
                </span>
              )}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              GRE
              {arrows.gre.order === 'asc' ? (
                <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                  <FiChevronDown onClick={() => handleSortClick('gre', 'string')} />
                </span>
              ) : (
                <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                  <FiChevronUp onClick={() => handleSortClick('gre', 'string')} />
                </span>
              )}
            </th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {sortedData?.map((item, key) => (
            <tr
              className="round-dg__row"
              key={key}
            >
              <td className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{moment(item?.['access-date']).format('DD/MM/YYYY')}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.['live-classroom-title']}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.name}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.email}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.institution}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.inep}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.gre}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
