import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap';

HighchartsHeatmap(Highcharts);

export default function MostAccessedTimesChart({ dayMetrics }) {
  const chartOptions = {
    chart: {
      type: 'heatmap',
      marginTop: 40,
      marginBottom: 40
    },
    title: {
      text: null
    },
    credits: {
      enabled: false
    },
    xAxis: {
      opposite: true,
      categories: ['00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h', '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'],
      title: {
        text: null
      },
      gridLineWidth: 0
    },
    yAxis: {
      categories: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      title: null,
      reversed: true,
      title: {
        text: null
      },
      gridLineWidth: 0
    },
    accessibility: {
      point: {
        descriptionFormat: '{(add index 1)}. ' + '{series.xAxis.categories.(x)} acessos ' + '{series.yAxis.categories.(y)}, {value}.'
      }
    },
    colorAxis: {
      min: 0,
      minColor: '#ECE9F8',
      maxColor: '#110B3E'
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      margin: 0,
      verticalAlign: 'top',
      y: 25,
      symbolHeight: 280
    },
    series: [
      {
        name: 'Acessos por dia',
        borderWidth: 4,
        borderRadius: 16,
        borderColor: '#fff',
        tooltip: {
          headerFormat: 'Acessos<br/>',
          pointFormat: '<b>{point.value}</b>'
        },
        data: dayMetrics,
        dataLabels: {
          enabled: false,
          color: '#000000'
        }
      }
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            yAxis: {
              labels: {
                format: '{substr value 0 1}'
              }
            }
          }
        }
      ]
    }
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
}
