import React, { useEffect } from 'react';
import { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const SelectComponent = ({ i, courses, form }) => {
  const animatedComponents = makeAnimated();
  const [allSelected, setAllSelected] = useState(false)

  const allClassroomsOption = { value: 'all', label: 'Todas as turmas' };

  const filteredClassroomByCourse = courses?.find(item => item.id === form.values.course_ids[i])?.classrooms.filter(item => item.institution.id !== null).map(item => ({value: item.id, label: item.title + ' - ' + item.institution.name}))

  if (filteredClassroomByCourse) {
    filteredClassroomByCourse.unshift(allClassroomsOption);
  }

  const getSelectedClassroom = () => {
    const classroomIds = form.values.classroom_ids[i]
    if (!classroomIds) return []

    return classroomIds.map(id => {
      const classroom = filteredClassroomByCourse.find(classroom => classroom.value === id.value)
      return classroom ? {value: classroom.value, label: classroom.label} : null
    })
  }

  const handleClassroomChange = (selectedOption) => {
    if(selectedOption && selectedOption.some(option => option.value === 'all')) {
      const allClassrooms = filteredClassroomByCourse.filter(item => item.value !== 'all')
      form.setFieldValue(`classroom_ids.${i}`, allClassrooms)
      setAllSelected(true)
    } else {
      form.setFieldValue(`classroom_ids.${i}`, selectedOption)
      setAllSelected(false)
    }    
  }

  const handleCourseChange = (e) => {
    const actualValue = [...form.values.course_ids];
    actualValue[i] = parseInt(e.target.value);
    form.setFieldValue('course_ids', actualValue);
    form.setFieldValue(`classroom_ids.${i}`, []);
  }

  const deleteRow = () => {
    const classrooms = form.values.classroom_ids;
    classrooms.splice(i, 1);
    form.setFieldValue('classroom_ids', classrooms);

    const courses = form.values.course_ids;
    courses.splice(i, 1);
    form.setFieldValue('course_ids', courses);
  };

  useEffect(() => {
    if (allSelected && form.values.classroom_ids[i].length !== filteredClassroomByCourse.length - 1) {
      setAllSelected(false);
    }
  }, [form.values.classroom_ids, i, filteredClassroomByCourse, allSelected]);

  return (
    <div
      className="form__row form__row--columns"
      key={i}
    >
      <div className="form__col">
        <label
          htmlFor=""
          className="form__label"
        >
          Curso
        </label>
        <select
          value={form.values.course_ids[i]}
          className="form__select"
          name="course"
          id="course"
          onChange={handleCourseChange}
        >
          <option value="blank"> Selecione um curso</option>
          {courses.map(c => (
            <option
              value={c.id}
              key={c.id}
            >
              {c.title}
            </option>
          ))}
        </select>
      </div>

      <fieldset className="form__col">
        <legend className="form__label">*Turma</legend>
        <Select
          classNamePrefix="react-multi-select"
          openMenuOnFocus
          noOptionsMessage={() => 'Sem opções'}
          isDisabled={false}
          value={allSelected ? [allClassroomsOption] : getSelectedClassroom()}
          placeholder={'Selecione as turmas'}
          onChange={handleClassroomChange}
          components={{
            animatedComponents,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null
          }}
          closeMenuOnSelect={false}
          isSearchable={true}
          options={filteredClassroomByCourse}
          isMulti
        />
      </fieldset>

      {form.values.classroom_ids?.length > 1 && (
        <FiTrash2
          className="list-alternatives__icon"
          onClick={() => deleteRow()}
        />
      )}
    </div>
  );
};

export default SelectComponent;
