import React from 'react';
import Modal from 'app/components/Modal';
import { useEffect, useState } from 'react';
import { t } from 'i18next';

export default function CreationIndividualModal(props) {
  const { show, onClose, form, selectedCourse, handleSubmit } = props;
  const [students, setStudents] = useState([]);

  function filterUsersByClassroom(classroomId, classrooms, users) {
    const classroom = classrooms.find(c => c.id === classroomId);
    if (!classroom) {
      return [];
    }
    const userIds = classroom['user-ids'];
    return users.filter(u => userIds.includes(u.id));
  }

  useEffect(() => {
    if (form.values.course !== '' && form.values.classroom !== '' && Object.keys(selectedCourse).length > 0) {
      const usersInClassroom = filterUsersByClassroom(+form.values.classroom, selectedCourse?.classrooms, selectedCourse?.users);
      let studentsInClassroom = usersInClassroom.filter(u => u['profile-names'].includes('Aluno'));
      setStudents(studentsInClassroom);
    }
  }, [form.values.classroom, form.values.course]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <h3 className="modal__simple-title">{t('textsCommon.participantDistribution')}</h3>

      <div
        className="kpi-widget kpi-widget--bright u-mb-3"
        style={{ width: '80%' }}
      >
        <div className="kpi-widget__header">
          <span className="kpi-widget__hat">Projeto</span>
          <p className="kpi-widget__action-title">{form?.values?.title}</p>
          <h4 className="kpi-widget__count">
            {students.length} {t('textsCommon.projects')}
          </h4>
        </div>

        <div className="kpi-widget__count-in-body u-mb-2">
          <span>Será criado um projeto para cada participante</span>
        </div>
      </div>

      <p>Você confirma a criação dos grupos para o projeto?</p>

      <div className="card__columns">
        <button
          type="button"
          className="btn btn--outline btn--full"
          onClick={onClose}
        >
          Não
        </button>
        <button
          type="button"
          className="btn btn--primary btn--full"
          onClick={() => handleSubmit()}
        >
          Sim
        </button>
      </div>
    </Modal>
  );
}
