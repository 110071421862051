import MultiSelect from 'app/components/MultiSelect';
import { useClient } from 'jsonapi-react';
import { useEffect } from 'react';
import { useState } from 'react';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import { toast } from 'react-hot-toast';
import { t } from 'i18next';

export default function StepName(props) {
  const { step, form, titleError } = props;
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);
  const [taxonomies, setTaxonomies] = useState([]);
  const client = useClient();

  const getTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch {
      toast.error('Erro ao carregar taxonomias');
    }
  };

  useEffect(() => {
    getTaxonomies();
  }, []);

  return (
    step === 'nome' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{t('activity.newActivityTitle')}</h2>
        </div>

        <div className="new-questions__body">
          <p className="new-questions__subtitle">{t('activity.newActivityDescription')}</p>
          <div className="form__row">
            <label
              className="form__label"
              htmlFor="title"
            >
              *{t('activity.activityName')}
            </label>
            <input
              className="form__control"
              placeholder={t('activity.placeholderActivityName')}
              id="title"
              name="title"
              type="text"
              value={form?.values?.title}
              onChange={e => form?.setFieldValue('title', e.target.value)}
            />
            {titleError && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="classCourse"
            >
              {t('tasks.mattersRelated')}
            </label>

            <MultiSelect
              selectedTaxonomies={form?.values.selectedTaxonomies}
              setFieldValue={form?.setFieldValue}
              openModal={() => setIsOpenModalTaxonomies(true)}
              placeholder={t('tasks.mattersAdd')}
            />
            {form?.touched?.selectedTaxonomies && form?.errors?.selectedTaxonomies && <span>{form?.errors?.selectedTaxonomies}</span>}
          </div>
        </div>

        <span className="form__text">
          <strong>* {t('warning.requiredField')}</strong>
        </span>
        {taxonomies?.length > 0 && isOpenModalTaxonomies && (
          <ModalTaxonomies
            taxonomies={taxonomies}
            selectedTaxonomies={form?.values?.selectedTaxonomies}
            setFieldValue={form?.setFieldValue}
            closeModal={() => setIsOpenModalTaxonomies(false)}
          />
        )}
      </div>
    )
  );
}
