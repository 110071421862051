import React from 'react';
import { useFormik } from 'formik';
import EmptyState from 'app/components/EmptyState';
import CommentCard from './CommentCard';
import FileCard from './FileCard';
import CommentForm from './CommentForm';
import { useAuditorium } from 'app/hooks/useAuditorium';
import { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function ChatWidget(props) {
  const { combineMessages, createChat, loading, files, messages } = useAuditorium();
  const client = useClient();
  const { activeTab, id, messageChatChannel, setPage, messageChats, setChats, hasMoreComments } = props;
  const [teacherLoading, setTeacherLoading] = useState(false);
  const [filteredFiles, setFilteredFiles] = useState([]);

  const commentForm = useFormik({
    initialValues: {},
    onSubmit: handleComment
  });

  const filters = useFormik({
    initialValues: {
      teacher: ''
    }
  });

  function handleComment(values) {
    createChat(id, values);
  }

  const teacherSet = new Set();

  const teachers = files.reduce((uniqueTeachers, file) => {
    if (file.user && file.user.id && !teacherSet.has(file.user.id)) {
      teacherSet.add(file.user.id);
      uniqueTeachers.push(file.user);
    }
    return uniqueTeachers;
  }, []);

  useEffect(() => {
    const { teacher } = filters.values;

    const filteredFiles = files.filter(item => {
      let filterResult = true;
      if (teacher !== '' && teacher !== 'blank') {
        filterResult = filterResult && item.user && item.user.id === parseInt(teacher);
      }
      return filterResult;
    });

    setFilteredFiles(filteredFiles);
  }, [filters.values, files]);

  const messagesWithFiles = messages.filter(message => message?.['auditorium-files']?.length > 0);

  const filesFromMessages = messagesWithFiles.flatMap(message => {
    return message?.['auditorium-files']?.map(item => {
      return { ...item, user: message.user };
    });
  });

  const mergedFiles = [...files, ...filesFromMessages];

  const sortedFilesFromMessages = mergedFiles.slice().sort((a, b) => {
    const dateA = new Date(a['created-at']);
    const dateB = new Date(b['created-at']);
    return dateA - dateB;
  });

  useEffect(() => {
    if (teachers.length === 0) {
      setTeacherLoading(true);
    } else {
      setTeacherLoading(false);
    }
  }, [teachers]);

  return activeTab === 'chat' ? (
    <div className="chat-widget">
      <div
        id="scrollableDiv1"
        className="chat-widget__body"
        style={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse'
        }}
      >
        <InfiniteScroll
          dataLength={messageChats.length}
          next={() => setPage(prevPage => prevPage + 1)}
          hasMore={hasMoreComments}
          inverse={true}
          scrollableTarget="scrollableDiv1"
        >
          {hasMoreComments && <Loader />}

          {messageChats.map(chat => (
            <CommentCard
              chat={chat}
              id={id}
              key={chat.id}
              messageChats={messageChats}
              setChats={setChats}
            />
          ))}
        </InfiniteScroll>
      </div>

      <CommentForm commentForm={commentForm} />
    </div>
  ) : (
    <div className="chat-widget">
      {!teacherLoading && (
        <div className="filter-bar filter-bar--borderless u-mb-1">
          <FilterSelectionBox
            label={t('filter.selectTeacher')}
            blankLabel={t('filter.blankLabelAll2')}
            value={filters.values.teacher}
            onChange={e => {
              filters.setFieldValue('teacher', e.target.value);
            }}
            options={teachers}
          />
        </div>
      )}

      <div className="chat-widget__body">
        {sortedFilesFromMessages?.length === 0 && (
          <EmptyState
            type="content"
            title={t('emptyState.titleNotFile')}
            text={t('emptyState.textNotFile')}
            bgless
          />
        )}

        {sortedFilesFromMessages.map((f, i) => (
          <FileCard
            file={f}
            key={i}
            id={id}
          />
        ))}
      </div>
    </div>
  );
}
