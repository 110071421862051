import React from 'react';

function Widget(props) {
  const { title = 'Widget title', description, children } = props;

  return (
    <div className="widget">
      <header className="widget__header">
        <h3 className="widget__title">{title}</h3>
        {description && <p className="widget__description">{description}</p>}
      </header>
      <div className="widget__body">{children}</div>
    </div>
  );
}

export default Widget;
