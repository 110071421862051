import React, { useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import TabNavBar from './TabNavBar';
import GeneralTab from './GeneralTab';
import StudentListTab from './StudentListTab';
import QuizDataOpen from './QuizDataOpen';
import { t } from 'i18next';
import { navigate } from '@reach/router';

export function QuizData({ id, representation }) {
  const client = useClient();
  const [activeTabs, setActiveTabs] = useState('geral');
  const [loading, setLoading] = useState(true);
  const [surveyData, setSurveyData] = useState([]);

  useEffect(() => {
    getSurveyData();
  }, []);

  const getSurveyData = async () => {
    try {
      setLoading(true);
      const { data } = await client.fetch(['surveys', id]);
      setSurveyData(data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      toast.error('Erro ao buscar dados do questionário.');
    }
  };

  return (
    <>
      {representation === 'estrela' && (
        <main className="main-content main-content--block fadeIn">
          <div className="filter-bar">
            <button
              onClick={() => navigate(-1)}
              className="filter-bar__back"
            >
              <span className="card__icon-wrapper">
                <FiChevronLeft className="card__icon" />
              </span>

              <span className="filter-bar__back-container">
                <span className="filter-bar__title">{t('textsCommon.data')}</span>
              </span>
            </button>
          </div>

          <TabNavBar
            activeTabs={activeTabs}
            setActiveTabs={setActiveTabs}
          />

          {activeTabs === 'geral' && surveyData && (
            <GeneralTab
              surveyData={surveyData}
              loading={loading}
            />
          )}
          {activeTabs === 'lista-de-alunos' && surveyData && (
            <StudentListTab
              surveyData={surveyData}
              loading={loading}
            />
          )}
        </main>
      )}

      {representation === 'aberto' && <QuizDataOpen id={id} />}
    </>
  );
}
