import React, { useState } from 'react';
import Pie from './Pie';
import { Link } from '@reach/router';
import moment from 'moment';

export default function NavWeek({ studyGuide }) {
  const [active, setActive] = useState();

  const videosByDate = studyGuide['study-plan-videos'].reduce((acc, video) => {
    const formattedDate = moment(video.date).format('YYYY-MM-DD');
    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push(video);
    return acc;
  }, {});

  const sortedDates = Object.keys(videosByDate).sort();

  const calculateCompletionPercentage = videos => {
    const total = videos.length;
    const completed = videos.filter(video => video.done).length;
    return total > 0 && completed > 0 ? (completed / total) * 100 : 0.1;
  };

  const days = sortedDates
    .filter(date => {
      const dayOfWeek = moment(date).day();
      return dayOfWeek >= 1 && dayOfWeek <= 5;
    })
    .map((date, index) => {
      const dayName = moment(date).format('dddd');
      const completionPercentage = calculateCompletionPercentage(videosByDate[date]);

      return {
        name: dayName.slice(0, dayName.indexOf('-')),
        value: completionPercentage
      };
    });

  const currentDay = moment().format('dddd').split('-')[0];

  return (
    <ul className="nav-week">
      {days?.map(day => (
        <li
          className="nav-week__day"
          key={day?.name}
        >
          <a
            href={`#${day?.name.toLowerCase()}`}
            className={currentDay.toLowerCase() === day?.name.toLowerCase() ? 'nav-week__link nav-week__link--is-active' : 'nav-week__link'}
          >
            <Pie
              value={day?.value}
              text={day?.name.slice(0, 3)}
              size={44}
            />
          </a>
        </li>
      ))}
    </ul>
  );
}
