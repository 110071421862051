import React, { useState, useEffect } from 'react';
import { useQuestionBook } from 'app/hooks/useQuestionBook';
import { CardArchive } from 'app/components/Questions';
import { useFormik } from 'formik';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import Modal from 'app/components/Modal';
import StudentQuestionBookForm from './StudentQuestionBookForm';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';
import TabNavBar from './TabNavBar';

function QuestionBooks(props) {
  const { uri, selectedTab } = props;

  const { t } = useTranslation();
  const { questionBooks, getQuestionBooks, loading } = useQuestionBook();
  const [showModal, setShowModal] = useState(false);
  const [filteredQuestionBooks, setFilteredQuestionBooks] = useState([]);

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      classroom: 'blank',
      visibility: {
        public: false,
        own: false
      }
    }
  });

  function filterQuestionBooksByCourse(id, questionBooks) {
    return questionBooks.filter(function (qb) {
      return qb['course-ids'].includes(id);
    });
  }

  function filterQuestionBooksByClassroom(id, questionBooks) {
    return questionBooks.filter(function (qb) {
      return qb['classroom-ids'].includes(id);
    });
  }

  useEffect(() => {
    let newQuestionBooks = questionBooks;
    if (filters.values.course !== 'blank') {
      const id = +filters.values.course;
      newQuestionBooks = filterQuestionBooksByCourse(id, questionBooks);
    }
    if (filters.values.classroom !== 'blank') {
      const id = +filters.values.classroom;
      newQuestionBooks = filterQuestionBooksByClassroom(id, questionBooks);
    }
    if (filters.values.visibility.public === true) {
      newQuestionBooks = newQuestionBooks.filter(qb => qb['creator-profile'] !== 'Aluno');
    }
    if (filters.values.visibility.own === true) {
      newQuestionBooks = newQuestionBooks.filter(qb => qb['creator-profile'] === 'Aluno');
    }
    setFilteredQuestionBooks(newQuestionBooks);
  }, [filters.values, questionBooks]);

  useEffect(() => {
    getQuestionBooks();
  }, []);

  const breadCrumbs = {
    title: 'Avaliações e Questões',
    nav: [
      {
        route: uri,
        name: 'Exercícios',
        isActive: true
      }
    ]
  };

  return (
    <>
      <div className="tab__pane">
        <BreadCrumbs data={breadCrumbs} />
      </div>

      <TabNavBar selectedTab={selectedTab} />

      <div className="filter-bar filter-bar--borderless">
        <div className="filter-bar__inner"></div>

        <div className="filter-bar__actions">
          {/* <FilterMultiSelect
            data={[
              {
                label: t('filter.public'),
                value: filters.values.visibility.public,
                onChange: () => {
                  filters.setFieldValue('visibility', {
                    public: !filters.values.visibility.public,
                    private: false,
                    own: false
                  });
                }
              },
              {
                label: 'Minhas apostilas',
                value: filters.values.visibility.own,
                onChange: () => {
                  filters.setFieldValue('visibility', {
                    public: false,
                    private: false,
                    own: !filters.values.visibility.own
                  });
                }
              }
            ]}
          /> */}

          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="btn btn--wide btn--primary btn--tab-side"
          >
            {t('questionBooks.buttonNewQuestionBook')}
          </button>
        </div>
      </div>

      {loading && <Loader />}

      {!loading && !filteredQuestionBooks.length > 0 && <EmptyState type="data" />}

      {!loading && (
        <div className="tab__cards fadeIn">
          {filteredQuestionBooks?.map(qb => (
            <CardArchive
              key={qb.id}
              book={qb}
            />
          ))}
        </div>
      )}

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <StudentQuestionBookForm setShowModal={setShowModal} />
      </Modal>
    </>
  );
}

export default QuestionBooks;
