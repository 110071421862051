import React from 'react';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import Error404 from 'app/components/Error404';
import WeekChallengeSidebar from './WeekChallengeSidebar';
import WeekChallengeContent from './WeekChallengeContent';

export default function CreateWeekChallenge() {
  const isAdmin = !checkIncludeProfiles({ only: ['student'] });

  if (!isAdmin) return <Error404 />;

  return (
    <div className="main-screen">
      <WeekChallengeSidebar />

      <main className="main-content main-content--admin-layout">
        <WeekChallengeContent />
      </main>
    </div>
  );
}
