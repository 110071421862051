import React from 'react';
import { FiFileText, FiLink, FiVideo } from 'react-icons/fi';
import { t } from 'i18next';
import { useClient } from 'jsonapi-react';

export default function MaterialCard({ material, moduleLessons, currentModule }) {
  const fileLesson = moduleLessons?.find(item => item.id === parseInt(material?.['learning-system-item-ids']));
  const client = useClient();

  const handleButtonClick = async () => {
    await client.mutate('route_logs', {
      uri: `/materiais/${material?.id}/${currentModule?.id}`,
      path: '/materiais/:materialId/:moduleId'
    });
  };

  const materialUrl = material?.url;
  const materialFileUrl = material?.file?.url;

  const isPdf = materialUrl?.includes('.pdf') || materialFileUrl?.includes('.pdf');
  const isFileLink = materialFileUrl && !materialFileUrl?.includes('.pdf');
  const isVideo = materialUrl?.includes('.mp4');
  const isAudio = materialUrl?.includes('.mp3');

  function IconPdf() {
    return (
      <svg
        width="18"
        height="23"
        viewBox="0 0 18 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 11V3.0684C1 2.52592 1.21071 2.00565 1.58579 1.62205C1.96086 1.23845 2.46957 1.02295 3 1.02295H11L17 7.15931V11"
          stroke="var(--color-primary)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 1.02295V7.15931H17"
          stroke="var(--color-primary)"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.57778 21C1.39121 21 1.24676 20.9444 1.14445 20.8331C1.04815 20.7153 1 20.5549 1 20.352V14.6381C1 14.4287 1.05116 14.2716 1.15347 14.1669C1.25579 14.0556 1.40023 14 1.58681 14H3.76252C4.46067 14 4.99934 14.1898 5.37851 14.5694C5.75768 14.949 5.94726 15.4792 5.94726 16.1599C5.94726 16.8406 5.75768 17.374 5.37851 17.7602C4.99934 18.1398 4.46067 18.3296 3.76252 18.3296H2.15556V20.352C2.15556 20.5549 2.10742 20.7153 2.01112 20.8331C1.91482 20.9444 1.77038 21 1.57778 21ZM2.15556 17.338H3.58196C3.99123 17.338 4.30118 17.2398 4.51183 17.0435C4.72248 16.8406 4.82781 16.546 4.82781 16.1599C4.82781 15.7737 4.72248 15.4825 4.51183 15.2861C4.30118 15.0898 3.99123 14.9916 3.58196 14.9916H2.15556V17.338Z"
          fill="var(--color-primary)"
        />
        <path
          d="M7.50315 20.9215C7.30454 20.9215 7.15107 20.8658 7.04273 20.7546C6.94042 20.6367 6.88926 20.4698 6.88926 20.2539V14.6676C6.88926 14.4516 6.94042 14.288 7.04273 14.1767C7.15107 14.0589 7.30454 14 7.50315 14H9.25456C10.3198 14 11.1414 14.2978 11.7192 14.8934C12.303 15.489 12.5949 16.3432 12.5949 17.4558C12.5949 18.0122 12.5196 18.5063 12.3692 18.9383C12.2247 19.3637 12.008 19.7237 11.7192 20.0182C11.4363 20.3128 11.0872 20.5386 10.6719 20.6957C10.2627 20.8462 9.79021 20.9215 9.25456 20.9215H7.50315ZM8.04482 19.8808H9.18233C9.55548 19.8808 9.87748 19.8317 10.1483 19.7335C10.4252 19.6288 10.6539 19.4783 10.8344 19.2819C11.021 19.079 11.1594 18.827 11.2497 18.5259C11.34 18.2183 11.3851 17.8616 11.3851 17.4558C11.3851 16.6442 11.2016 16.0388 10.8344 15.6396C10.4673 15.2403 9.9166 15.0407 9.18233 15.0407H8.04482V19.8808Z"
          fill="var(--color-primary)"
        />
        <path
          d="M14.3618 21C14.1752 21 14.0277 20.9444 13.9194 20.8331C13.8171 20.7153 13.7659 20.5484 13.7659 20.3324V14.6676C13.7659 14.4516 13.8171 14.288 13.9194 14.1767C14.0277 14.0589 14.1812 14 14.3798 14H17.5306C17.687 14 17.8044 14.0425 17.8826 14.1276C17.9609 14.2127 18 14.3338 18 14.4909C18 14.6545 17.9609 14.7821 17.8826 14.8738C17.8044 14.9589 17.687 15.0014 17.5306 15.0014H14.9215V16.9551H17.341C17.4914 16.9551 17.6058 16.9977 17.684 17.0827C17.7683 17.1678 17.8104 17.2889 17.8104 17.446C17.8104 17.6096 17.7683 17.7373 17.684 17.8289C17.6058 17.914 17.4914 17.9565 17.341 17.9565H14.9215V20.3324C14.9215 20.7775 14.7349 21 14.3618 21Z"
          fill="var(--color-primary)"
        />
      </svg>
    );
  }

  function MaterialIcon() {
    if (isPdf) return <IconPdf />;
    if (isFileLink) return <FiLink size={24} />;
    if (isVideo) return <FiVideo size={24} />;
    if (isAudio) return <FiHeadphones size={24} />;

    return <FiFileText size={24} />;
  }

  return (
    <tr className="round-dg__row">
      <th
        className="round-dg__cell-header round-dg__cell-header--row"
        style={{ width: '100%' }}
      >
        <div className="card__header">
          <div className="card__inner-header">
            <div
              className="card__icon-wrapper"
              style={{ width: 32, height: 32 }}
            >
              <MaterialIcon />
            </div>
            <h2
              className="card__title"
              style={{ flexGrow: 'initial' }}
            >
              {material?.title}
            </h2>
          </div>
        </div>
      </th>

      <td
        className="round-dg__cell"
        style={{ width: '100px' }}
      ></td>
      <td
        className="round-dg__cell"
        style={{ width: '132px' }}
      >
        <span
          className="expanded-calendar__tag expanded-calendar__tag--bright u-text-truncate"
          style={{ display: 'inline-block' }}
        >
          {fileLesson?.body}
        </span>
      </td>
      <td
        className="round-dg__cell"
        style={{ width: '132px' }}
      ></td>

      <td
        className="round-dg__cell last-cell"
        style={{ width: '128px' }}
      >
        <a href={material?.file?.url ? material?.file?.url : material?.url}>
          <button
            type="button"
            className="btn btn--outline btn--small"
            onClick={handleButtonClick}
          >
            {t('button.view')}
          </button>
        </a>
      </td>
    </tr>
  );
}
