import * as React from 'react';

function IconExercises(props) {
  return (
    <svg
      stroke="currentColor"
      width={18}
      height={16}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3 1H4.7a1 1 0 00-1 1v1.136h1.593a.5.5 0 010 1H3.7V7.5h1.593a.5.5 0 010 1H3.7v3.364h1.593a.5.5 0 010 1H3.7V14a1 1 0 001 1h2.6V1zm1 0v14h6.85a1 1 0 001-1V2a1 1 0 00-1-1H8.3zM2.7 8.5v3.364H1.39a.5.5 0 000 1H2.7V14a2 2 0 002 2h10.45a2 2 0 002-2V2a2 2 0 00-2-2H4.7a2 2 0 00-2 2v1.136H1.39a.5.5 0 000 1H2.7V7.5H1.39a.5.5 0 000 1H2.7z"
        fill="#442B8C"
      />
    </svg>
  );
}

export default IconExercises;
