import { t } from 'i18next';
import React from 'react';

function TabNavBar({ activeTabs, setActiveTabs }) {
  return (
    <div className="tab">
      <div
        className={`tab__link ${activeTabs === 'geral' ? 'active' : ''}`}
        onClick={() => setActiveTabs('geral')}
      >
        {t('nps.toggleAll')}
      </div>
      <div
        className={`tab__link ${activeTabs === 'curso' ? 'active' : ''}`}
        onClick={() => setActiveTabs('curso')}
      >
        {t('exams.thCourse')}
      </div>
      <div
        className={`tab__link ${activeTabs === 'turma' ? 'active' : ''}`}
        onClick={() => setActiveTabs('turma')}
      >
        {t('events.toggleClass')}
      </div>
    </div>
  );
}

export default TabNavBar;
