import React from 'react';
import { FiMoreHorizontal, FiCheckCircle, FiFileText, FiMessageCircle } from 'react-icons/fi';
import { Link } from '@reach/router';
import Dropdown from 'app/components/Dropdown';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

export default function DisciplineCard(props) {
  const { t } = useTranslation();
  const { deleteLesson, moduleId, disciplineId, item, editLesson, hasNewMessage } = props;

  return (
    <div className="card">
      <div className="card__header">
        <div className="card__inner-header">
          <h3 className="card__title">{item.body}</h3>
          <Dropdown>
            <Dropdown.Toggle>
              <FiMoreHorizontal />
            </Dropdown.Toggle>

            <Dropdown.Content>
              <Dropdown.Item onClick={() => editLesson(item)}>{t('button.edit')}</Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  Swal.fire({
                    title: t('projects.warningDeleteLesson'),
                    confirmButtonText: t('button.yes'),
                    showDenyButton: true,
                    denyButtonText: t('button.no'),
                    showCloseButton: true
                  }).then(res => {
                    if (res.isConfirmed) {
                      deleteLesson(item.id);
                    }
                  });
                }}
              >
                {t('button.delete')}
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </div>
      </div>

      <div className="card__body">
        <div className="card__badges card__badges--one-line">
          {item.taxonomies.slice(0, 3).map(item => {
            return (
              <span
                className="badge badge--primary"
                title="Servidor"
              >
                {item?.name}
              </span>
            );
          })}

          {item.taxonomies.length - 3 >= 0 ? (
            <span
              className="badge badge--more"
              title={item.taxonomies.slice(3).map(item => item.name)}
            >
              +{item.taxonomies.length - 3}
            </span>
          ) : null}
        </div>

        {hasNewMessage && (
          <div className="card__badges">
            <span className="badge badge--tiny badge--warning">{t('lms.tagNewMessage')}</span>
          </div>
        )}

        <div className="card__columns">
          <p
            className="card__count"
            title={item.learning_system_files.length + ' atividades/materiais'}
          >
            <FiFileText className="card__icon" /> {item.learning_system_files.length}
          </p>
          <p
            className="card__count"
            title="00 questões"
            aria-label="00 questões"
          >
            <FiCheckCircle className="card__icon" /> {item?.learning_system_question_books?.length}
          </p>
        </div>
        <p className="card__count">
          <h3
            className="card__count-label"
            title="Mensagens"
          >
            Mensagens
          </h3>
          <FiMessageCircle className="card__icon" /> {item.message_count}
        </p>
      </div>

      <div className="card__footer">
        <Link
          className="btn btn--outline"
          to={`/lms/visualizar/modulo/${moduleId}/disciplina/${disciplineId}/aula/${item.id}`}
        >
          {t('button.access')}
        </Link>
      </div>
    </div>
  );
}
