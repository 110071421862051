import * as React from 'react';

function IconExams(props) {
  return (
    <svg
      stroke="currentColor"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.102 2.102a2.625 2.625 0 011.856-.769h7a2.625 2.625 0 012.625 2.625v3.15a4.739 4.739 0 00-.875-.344V3.958a1.75 1.75 0 00-1.75-1.75h-7a1.75 1.75 0 00-1.75 1.75v7a1.75 1.75 0 001.75 1.75h2.806c.086.303.201.596.344.875h-3.15a2.625 2.625 0 01-2.625-2.625v-7c0-.696.277-1.364.77-1.856zm4.92 2.513a.438.438 0 01-.129.31l-1.532 1.53a.437.437 0 01-.604.014l-.656-.6a.438.438 0 01.59-.645l.347.316 1.236-1.235a.438.438 0 01.747.31zm-.034 4.207a.437.437 0 00-.714-.142L5.038 9.916 4.691 9.6a.437.437 0 00-.59.645l.656.6a.437.437 0 00.605-.013l1.531-1.532a.437.437 0 00.095-.477zm1.036-3.86a.438.438 0 00.31.746h2.187a.437.437 0 100-.875H8.333a.438.438 0 00-.309.128zm6.156 9.218a3.937 3.937 0 10-5.568-5.569 3.937 3.937 0 005.568 5.569zm-4.726-1.164a.41.41 0 00.243.067c.113 0 .197-.026.253-.078a.791.791 0 00.17-.264l.214-.498h1.74l.218.498c.05.118.106.204.17.26.066.055.16.082.279.082.095 0 .172-.02.232-.062a.263.263 0 00.105-.182.42.42 0 00-.047-.259L11.67 9.67a.583.583 0 00-.195-.254.45.45 0 00-.27-.083.45.45 0 00-.268.083.615.615 0 00-.2.254l-1.351 2.91a.409.409 0 00-.048.254.306.306 0 00.116.182zm1.152-1.4l.59-1.38h.01l.593 1.38h-1.193z"
        fill="#442B8C"
      />
    </svg>
  );
}

export default IconExams;
