import React from 'react';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';

export default function TabNavBar({ selectedTab }) {
  const { t } = useTranslation();
  return (
    <div className="tab">
      <Link
        className={selectedTab === 'lms' ? 'tab__link active' : 'tab__link'}
        to="/lms"
      >
        {t("lms.toggleLms")}
      </Link>
      <Link
        className={
          selectedTab === 'certificados' ? 'tab__link active' : 'tab__link'
        }
        to="/lms/certificado"
      >
        {t("lms.toggleCertifications")}
      </Link>
    </div>
  );
}
