import React, { useEffect, useState } from 'react';
import { FiZap } from 'react-icons/fi';
import FlashcardModal from './FlashcardModal';
import { useClient } from 'jsonapi-react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

export default function FlashcardBox({ taxonomyName, studyVideoId, studyVideo }) {
  const client = useClient();
  const { t } = useTranslation();
  const { abilities } = usePlatformAbilities();
  const [showModal, setShowModal] = useState(false);
  const [questionsAnswered, setQuestionsAnswerd] = useState(0);
  const [flashcardData, setFlashcardData] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [flashcardQuestions, setFlashcardQuestions] = useState([]);
  const [quantityFlashcardsAccepted, setQuantityFlashcardsAccepted] = useState(0);
  const [restart, setRestart] = useState();
  const [advice, setAdvice] = useState(false);

  const fetchFlashCardData = async () => {
    const { data, error } = await client.mutate('/user_flashcards', {
      'study-plan-video-id': studyVideoId
    });

    if (error) {
      console.log(error);
      toast.error(t('toast.errorStartFlashCard'));
      return;
    }

    const flashcards = data?.flashcards.map((val, index) => {
      return {
        ...val,
        position: index + 1
      };
    });

    const flashcardsAccepted = flashcards.filter(item => data['user-answer-flashcards'].find(item2 => item.id === item2['flashcard-id'] && item2['accepted-answer'] && item2['know-answer']));

    setQuantityFlashcardsAccepted(flashcardsAccepted?.length);
    setFlashcardQuestions(
      data?.flashcards.filter(f => {
        if (restart) {
          return true;
        } else {
          const answeredFlashCardsIds = data?.['user-answer-flashcards'].map(item => item?.['flashcard-id']);
          return !answeredFlashCardsIds.includes(f.id);
        }
      })
    );
    setPercentage(parseFloat(data.percentage).toFixed(0));
    setFlashcardData(data);
  };

  useEffect(() => {
    fetchFlashCardData();
  }, []);

  useEffect(() => {
    if (restart) {
      fetchFlashCardData();
    }
  }, [restart]);

  const handleCloseModal = () => {
    setShowModal(false);
    setRestart(false);
    setAdvice(false);
  };

  return (
    <div className="flashcard-box u-cursor-pointer">
      <div className="flashcard-box__header">
        <h3 className="flashcard-box__title">{t('flashCard.letReview')}</h3>
      </div>

      <div className="flashcard-box__body">
        <p className="flashcard-box__description">{t('flashCard.boxDescription')}</p>

        <div className="card__progress">
          <div className="card__progress-container">
            <div
              className="card__progress-bar"
              role="progressbar"
              style={{ width: `${percentage}%` }}
              aria-valuenow={percentage}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
          <div className="card__progress-text">{percentage}%</div>
        </div>
      </div>

      <div className="card__todo-header">
        {abilities?.flashcards && studyVideo?.video?.['flashcards-count'] ? (
          <button
            className="btn btn--full btn--review"
            onClick={() => setShowModal(prevShow => !prevShow)}
          >
            <FiZap className="btn__icon" /> {t('flashCard.startReview')}
          </button>
        ) : null}

        {flashcardData && (
          <FlashcardModal
            advice={advice}
            setAdvice={setAdvice}
            setRestart={setRestart}
            restart={restart}
            show={showModal}
            onClose={() => handleCloseModal()}
            questionsAnswered={questionsAnswered}
            setQuestionsAnswerd={setQuestionsAnswerd}
            taxonomyName={taxonomyName}
            flashcardData={flashcardData}
            questions={flashcardQuestions}
            allQuestionsQuantity={flashcardData?.flashcards?.length}
            quantityFlashcardsAccepted={quantityFlashcardsAccepted}
            onChangePercentage={setPercentage}
          />
        )}
      </div>
    </div>
  );
}
