import React, { useState } from 'react';
import { AvatarStack } from 'app/components/Avatar';
import GroupModal from './GroupModal';
import { t } from 'i18next';

export default function GroupCard(props) {
  const { i, data } = props;

  return (
    <div className="card card--simple card--clickable">
      <div className="card__header">
        <h3 className="card__title">{t('projects.sidebarGroup')} {i + 1}</h3>
        <p className="card__subtitle">{data.length} {t('sideBar.participants')}</p>
      </div>
      <div className="card__body">
        <GroupModal
          data={data}
          className="avatar--xs"
          i={i}
        />
      </div>
    </div>
  );
}
