import React, { useState, useEffect, useMemo, Suspense } from 'react';
import { navigate } from '@reach/router';
import moment from 'moment';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { FiChevronLeft } from 'react-icons/fi';
import ReportFilter from 'app/components/ReportFilter';
import Loader from 'app/components/loader';
import { init, use } from 'i18next';
import { SimpleModal } from 'app/components/Modal';

const OverviewWidget = React.lazy(() => import('./OverviewWidget'));
const RadarChartWidget = React.lazy(() => import('./RadarChartWidget'));
const SubmittedEssaysWidget = React.lazy(() => import('./SubmittedEssaysWidget'));
const AverageYieldWidget = React.lazy(() => import('./AverageYieldWidget'));

export default function EssayReport() {
  const [currentFilters, setCurrentFilters] = useState([]);
  const [regionals, setRegionals] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [essays, setEssays] = useState([]);

  //filtros
  const [selectedRegionals, setSelectedRegionals] = useState([]);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedEssays, setSelectedEssays] = useState([]);

  const [reportData, setReportData] = useState(null);

  const filters = useFormik({
    initialValues: {
      'start-date': moment().subtract(1, 'month').format('YYYY-MM-DD'),
      'end-date': moment().format('YYYY-MM-DD')
    }
  });

  const client = useClient();

  useEffect(() => {
    setCurrentFilters([...selectedRegionals, ...selectedInstitutions, ...selectedCourses, ...selectedEssays]);
  }, [selectedRegionals, selectedInstitutions, selectedCourses, selectedEssays]);

  const fetchRegionals = async (url = '/regionals') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Falha ao carregar regionais');
    } else {
      setRegionals(data.map(item => ({ value: item.id, label: item.name })));
    }
  };

  const fetchInstitutions = async (url = '/institutions') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Falha ao carregar instituições');
    } else {
      setInstitutions(data.map(item => ({ value: item.id, label: item.name })));
    }
  };

  const fetchCourses = async (url = '/courses/simple') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Falha ao carregar cursos');
    } else {
      setCourses(data.map(item => ({ value: item.id, label: item.title })));
    }
  };

  const fetchEssays = async (url = '/essays') => {
    const { data, error } = await client.fetch(url);

    if (error) {
      toast.error('Falha ao carregar redações');
    } else {
      setEssays(data.map(item => ({ value: item.id, label: item.topic, allowMultipleSents: item?.['allow-multiple-submissions'] ?? false })));
    }
  };

  const getReportData = async () => {
    setReportData(null);
    const filter = {
      regional_ids: selectedRegionals.length ? selectedRegionals.map(r => r.value).join(',') : null,
      institution_ids: selectedInstitutions.length ? selectedInstitutions.map(i => i.value).join(',') : null,
      course_ids: selectedCourses.length ? selectedCourses.map(c => c.value).join(',') : null,
      essay_ids: selectedEssays.length ? selectedEssays.map(e => e.value).join(',') : null,
      initial_date: filters.values['start-date'] ? filters.values['start-date'] : null,
      final_date: filters.values['end-date'] ? filters.values['end-date'] : null
    };

    Object.keys(filter).forEach(key => filter[key] === null && delete filter[key]);

    const { data, error } = await client.fetch(['/reports/essays', filter]);

    if (error) {
      toast.error('Falha ao carregar dados do relatório');
    } else {
      const kpis = setKpis(data);
      if (data?.['average-by-competence'].length === 0) {
        [...Array(5)].forEach((_, i) => {
          const index = i + 1;
          data['average-by-competence'].push({
            competence: 'c' + index,
            average: 0
          });
        });
      }
      setReportData({ ...data, kpis });
    }
  };

  useEffect(() => {
    getReportData();
  }, [currentFilters, filters.values]);

  const setKpis = data => {
    return [
      {
        id: 1,
        title: 'Redações enviadas',
        value: data?.['total-unique-sends'] ?? 0,
        help: 'Redações enviadas.',
        variation: 'report-kpi-card--edit'
      },
      {
        id: 2,
        title: 'Redações corrigidas por professores',
        value: data?.['total-corrected-by-teacher'] ?? 0,
        help: 'Redações corrigidas por professores.',
        variation: 'report-kpi-card--file'
      },
      {
        id: 3,
        title: 'Pontuação média',
        value: data?.['average-grade'] ?? 0,
        help: 'Pontuação média.',
        variation: 'report-kpi-card--file'
      }
    ];
  };

  useEffect(() => {
    Promise.all([fetchRegionals(), fetchInstitutions(), fetchCourses(), fetchEssays()]).then(() => {
      getReportData();
    });
  }, []);

  const data = useMemo(() => {
    //atualiza os seletores de acordo com os filtros aplicados
    return [
      {
        placeholder: 'Digite o nome da Regional',
        name: 'Regional',
        options: regionals
      },
      {
        placeholder: 'Digite o nome da instituição',
        name: 'Instituição',
        options: institutions
      },
      {
        placeholder: 'Digite o nome do curso',
        name: 'Curso',
        options: courses
      },
      {
        placeholder: 'Digite o nome da redação',
        name: 'Redação',
        options: essays
      }
    ];
  }, [institutions, regionals, courses, essays]);

  return (
    <main className="report">
      <header className="report__header">
        <h1 className="report__title">
          <button
            className="card__icon-wrapper"
            aria-label="Voltar"
            title="Voltar"
            onClick={() => navigate(-1)}
          >
            <FiChevronLeft className="card__icon" />
          </button>
          Redação
        </h1>
        {/* <p className="report__description">Descrição do relatório</p> */}
      </header>

      <div className="report__body">
        <div className="report__filter report__filter--space report__filter--multi-select">
          <ReportFilter
            currentFilters={currentFilters}
            data={data}
            selectedRegionals={selectedRegionals}
            setSelectedRegionals={setSelectedRegionals}
            fetchInstitutions={fetchInstitutions}
            setSelectedInstitutions={setSelectedInstitutions}
            setSelectedCourses={setSelectedCourses}
            setSelectedEssays={setSelectedEssays}
          />

          <div className="report-form__datepicker">
            <input
              className="report-form__control"
              id="start-date"
              name="start-date"
              type="date"
              value={moment(filters.values['start-date']).format('YYYY-MM-DD')}
              onChange={e => filters.setFieldValue('start-date', e.target.value)}
              title="Data de início"
              aria-label="Data de início"
            />
            <input
              className="report-form__control"
              id="start-date"
              name="start-date"
              type="date"
              value={moment(filters.values['end-date']).format('YYYY-MM-DD')}
              onChange={e => filters.setFieldValue('end-date', e.target.value)}
              min={moment(filters.values['start-date']).format('YYYY-MM-DD')}
              title="Data de fim"
              aria-label="Data de fim"
            />
          </div>
        </div>

        <Suspense fallback={<Loader />}>
          {reportData ? (
            <>
              {reportData?.kpis && <OverviewWidget kpis={reportData.kpis} />}
              {/* <SubmittedEssaysWidget /> */}
              {reportData && <RadarChartWidget apiData={reportData?.['average-by-competence']} />}
              <AverageYieldWidget
                selectedRegionals={selectedRegionals}
                selectedInstitutions={selectedInstitutions}
                selectedCourses={selectedCourses}
                selectedEssays={selectedEssays}
                setSelectedRegionals={setSelectedRegionals}
                setSelectedInstitutions={setSelectedInstitutions}
                setSelectedCourses={setSelectedCourses}
                data={reportData?.['map-grap']}
                users={reportData?.['users']}
              />
            </>
          ) : (
            <Loader />
          )}
        </Suspense>
      </div>
    </main>
  );
}
