import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FiChevronDown, FiChevronLeft, FiChevronUp } from 'react-icons/fi';
import { useFormik } from 'formik';
import { useCourse } from 'app/hooks/useCourse';
import ReportForm from 'app/components/ReportForm';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import xlsx from 'json-as-xlsx';
import { navigate } from '@reach/router';
import ReportFilter from 'app/components/ReportFilter';
import moment from 'moment';
import BarChart from './BarChart';
import QuizReportFilter from './ReportFilter';
import DataGrid from './DataGrid';

export default function RecordClassesReport() {
  const client = useClient();
  const [isLoading, setIsLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [report, setReport] = useState({});
  const [studentOrder, setStudentOrder] = useState('descendent');
  const [emailOrder, setEmailOrder] = useState('descendent');
  const [institutionOrder, setInstitutionOrder] = useState('descendent');
  const [greOrder, setGreOrder] = useState('descendent');
  const [totalErrorsOrder, setTotalErrorsOrder] = useState('descendent');
  const [rightAnswersOrder, setRightAnswersOrder] = useState('descendent');
  const [profileOrder, setProfileOrder] = useState('descendent');
  const [inepOrder, setInepOrder] = useState('descendent');
  const [performanceOrder, setPerformanceOrder] = useState('descendent');
  const [param, setParam] = useState('');
  const [emptyReport, setEmptyReport] = useState(false);
  const { courses, getSimpleCourses } = useCourse();

  //data dos filtros
  const [currentFilters, setCurrentFilters] = useState([]);
  const [exams, setExams] = useState([]);
  const [booklets, setBooklets] = useState([]);

  const [selectedExams, setSelectedExams] = useState([]);
  const [selectedBooklets, setSelectedBooklets] = useState([]);
  const [questionBookIds, setQuestionBookIds] = useState([]);

  const filters = useFormik({
    initialValues: {
      'initial-date': moment().subtract(1, 'months').format('YYYY-MM-DD'),
      'final-date': moment().format('YYYY-MM-DD')
    }
  });

  const fetchExams = async (url = '/question_books?filter[visibility]=exam') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('Erro ao buscar provas'));
    } else {
      setExams(data.map(item => ({ value: item.id, label: item.title })));
    }
  };

  const fetchBooklets = async (url = '/question_books?filter[visibility]=train') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('Erro ao buscar exercicios'));
    } else {
      setBooklets(data.map(item => ({ value: item.id, label: item.title })));
    }
  };

  const getReport = async () => {
    setIsLoading(true);
    const formatedIds = questionBookIds.join(',');

    let url = `/reports/student_answers?initial_date=${filters.values?.['initial-date']}&final_date=${filters.values?.['final-date']}&question_book_ids=${formatedIds}`;

    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('Erro ao buscar exercicios'));
    } else {
      setReport(data);
      if (data.table.length <= 0) {
        setEmptyReport(true);
      } else {
        setEmptyReport(false);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const bookletsIds = selectedBooklets.map(item => item.value);
    const examsIds = selectedExams.map(item => item.value);
    setQuestionBookIds([...bookletsIds, ...examsIds]);
  }, [selectedBooklets, selectedExams]);

  useEffect(() => {
    if (questionBookIds.length !== 0 && filters.values['final-date'] !== '' && filters.values['initial-date']) {
      setHasData(true);
      getReport();
    } else {
      setHasData(false);
      setEmptyReport(false);
    }
  }, [filters.values, questionBookIds]);

  const xlsxStructure = [
    {
      sheet: 'Repostas de Avaliações',
      columns: [
        { label: 'Aluno', value: 'Aluno' },
        { label: 'Email', value: 'Email' },
        { label: 'INEP', value: 'INEP' },
        { label: 'GRE', value: 'GRE' },
        { label: 'Quantidade de Acertos', value: 'Quantidade de Acertos' },
        { label: 'Quantidade de Erros', value: 'Quantidade de Erros' },
        { label: '% Aproveitamento', value: '% Aproveitamento' }
      ],
      content: report?.table?.map(item => ({
        Aluno: item?.['user-name'],
        Email: item?.['user-email'],
        Instituição: item.institution,
        INEP: item.inep,
        GRE: item.gre,
        'Quantidade de Acertos': item?.['num-of-correct-answers'],
        'Quantidade de Erros': item?.['num-of-wrong-answers'],
        '% Aproveitamento': item?.['accuracy-rate']
      }))
    }
  ];

  const handleDownloadCSV = () => {
    try {
      xlsx(xlsxStructure);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (courses.length === 0) {
      getSimpleCourses();
    }
    fetchExams();
    fetchBooklets();
  }, []);

  const orderByField = useCallback(
    (field, order) => (a, b) => {
      const valueA = a[field]?.toUpperCase?.() || '';
      const valueB = b[field]?.toUpperCase?.() || '';

      if (order === 'descendent') {
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      } else {
        if (valueA > valueB) return -1;
        if (valueA < valueB) return 1;
        return 0;
      }
    },
    []
  );

  // const orderByDate = (a, b) => {
  //   const dateA = new Date(a['last-access']);
  //   const dateB = new Date(b['last-access']);
  //   if (dateOrder === 'descendent') {
  //     if (dateA < dateB) {
  //       return -1;
  //     }
  //     if (dateA > dateB) {
  //       return 1;
  //     }

  //     return 0;
  //   } else {
  //     if (dateA > dateB) {
  //       return -1;
  //     }
  //     if (dateA < dateB) {
  //       return 1;
  //     }

  //     return 0;
  //   }
  // };

  const orderedReport = useMemo(() => {
    let newArr = [];
    if (report.data) {
      switch (param) {
        case 'student':
          newArr = report.users.sort(orderByField('student', studentOrder));
          break;
        case 'total-errors':
          newArr = report.users.sort(orderByField('total-errors', totalErrorsOrder));
          break;
        case 'right-answers':
          newArr = report.users.sort(orderByField('right-answers', rightAnswersOrder));
          break;
        case 'email':
          newArr = report.users.sort(orderByField('email', emailOrder));
          break;
        case 'institution':
          newArr = report.users.sort(orderByField('institution', institutionOrder));
          break;
        case 'gre':
          newArr = report.users.sort(orderByField('gre', greOrder));
          break;
        case 'profile':
          newArr = report.users.sort(orderByField('profile', profileOrder));
          break;
        case 'gre':
          newArr = report.users.sort(orderByField('gre', greOrder));
          break;
        case 'performance':
          newArr = report.users.sort(orderByField('performance', performanceOrder));
          break;
        default:
          newArr = report.users;
      }
    }
    return { ...report, data: newArr };
  }, [param, studentOrder, totalErrorsOrder, rightAnswersOrder, greOrder, institutionOrder, performanceOrder, report]);

  const data = useMemo(() => {
    //atualiza os seletores de acordo com os filtros aplicados
    return [
      {
        placeholder: 'Digite o nome da Prova',
        name: 'Prova',
        options: exams
      },
      {
        placeholder: 'Digite o nome do Exercício',
        name: 'Exercício',
        options: booklets
      }
    ];
  }, [booklets, exams]);

  useEffect(() => {
    setCurrentFilters([...selectedExams, ...selectedBooklets]);
  }, [selectedExams, selectedBooklets]);

  const handleRemoveFilterItem = item => {
    //remover itens do filtro
    switch (item.type) {
      case 'exam':
        setSelectedExams(prevState => prevState.filter(c => c.value !== item.value));
        break;
      case 'booklet':
        setSelectedBooklets(prevState => prevState.filter(m => m.value !== item.value));
        break;
    }
  };

  return (
    <main className="report">
      <header className="report__header">
        <h1 className="report__title">
          <a
            className="card__icon-wrapper"
            onClick={() => navigate(-1)}
            style={{ cursor: 'pointer' }}
          >
            <FiChevronLeft className="card__icon" />
          </a>
          Relatório de respostas de avaliações
        </h1>
        <p className="report__description">Objetivo: Identificar quais alunos, escolas e GRE responderam ás avaliações </p>
      </header>

      <div className="report__body">
        <div className="report__filter report__filter--space report__filter--multi-select">
          <QuizReportFilter
            setSelectedBooklets={setSelectedBooklets}
            setSelectedExams={setSelectedExams}
            selectedExams={selectedExams}
            currentFilters={currentFilters}
            handleRemoveFilterItem={handleRemoveFilterItem}
            data={data}
          />

          <div className="report-form__datepicker">
            <input
              className="report-form__control"
              id="initial-date"
              name="initial-date"
              type="date"
              value={moment(filters.values['initial-date']).format('YYYY-MM-DD')}
              onChange={e => filters.setFieldValue('initial-date', e.target.value)}
              title="Data de início"
              aria-label="Data de início"
            />
            <input
              className="report-form__control"
              id="final-date"
              name="final-date"
              type="date"
              value={moment(filters.values['final-date']).format('YYYY-MM-DD')}
              onChange={e => filters.setFieldValue('final-date', e.target.value)}
              min={moment(filters.values['initial-date']).format('YYYY-MM-DD')}
              title="Data de fim"
              aria-label="Data de fim"
            />
          </div>
        </div>

        <div className="report-widget__body">
          {!hasData && !isLoading && !emptyReport && (
            <EmptyState
              type="add"
              title="Selecione uma prova ou exercício e depois o período desejado"
              text=" "
              bgless
            />
          )}
          {emptyReport && hasData && (
            <EmptyState
              type="content"
              title="Nenhum dado encontrado"
              text="Tente buscar por outro curso"
              bgless
            />
          )}
          {isLoading && !hasData && <Loader.Spinner />}

          {hasData && !emptyReport && !isLoading && (
            <div className="round-dg-wrapper">
              <div
                className="report-kpi-card-wrapper "
                style={{ padding: '32px 0' }}
              >
                {report?.kpis?.['total-number-of-students'] >= 0 && (
                  <div className="report-kpi-card">
                    <span className="report-kpi-card__title">Quantidade total de respostas</span>
                    <span
                      className="report-kpi__value"
                      style={{ color: '#5D6A86', marginTop: '24px' }}
                    >
                      <b>{report?.kpis?.['total-number-of-students']}</b>
                    </span>
                  </div>
                )}
                {report?.kpis?.['participation-rate'] >= 0 && (
                  <div className="report-kpi-card">
                    <span className="report-kpi-card__title">Taxa de participação</span>
                    <span
                      className="report-kpi__value"
                      style={{ color: '#5D6A86', marginTop: '24px' }}
                    >
                      <b>{report?.kpis?.['participation-rate']}</b>
                    </span>
                  </div>
                )}
                {report?.kpis?.['average-accuracy-rate'] >= 0 && (
                  <div className="report-kpi-card">
                    <span className="report-kpi-card__title">Média de acertos / aproveitamento</span>
                    <span
                      className="report-kpi__value"
                      style={{ color: '#5D6A86', marginTop: '24px' }}
                    >
                      <b>{report?.kpis?.['average-accuracy-rate']}</b>
                    </span>
                  </div>
                )}
                {report?.kpis?.['students-with-over-60-accuracy'] >= 0 && (
                  <div className="report-kpi-card">
                    <span className="report-kpi-card__title">Número de alunos que acertaram mais de 60%</span>
                    <span
                      className="report-kpi__value"
                      style={{ color: '#5D6A86', marginTop: '24px' }}
                    >
                      <b>{report?.kpis?.['students-with-over-60-accuracy']}</b>
                    </span>
                  </div>
                )}
              </div>
              <div
                className="report-kpi-card-wrapper "
                style={{ padding: '32px 0' }}
              >
                <div
                  className="report-kpi-card"
                  style={{ width: '500px' }}
                >
                  <BarChart
                    data={report?.graphs?.['institution-answers']}
                    title="Escolas com maior participação (Número de participantes)"
                  />
                </div>
                <div
                  className="report-kpi-card"
                  style={{ width: '500px' }}
                >
                  <BarChart
                    data={report?.graphs?.['gre-answers']}
                    title="GREs mais presentes (Números de respostas)"
                  />
                </div>
              </div>
              <div
                className="report-widget__header"
                style={{ justifyContent: 'flex-end', marginBottom: '24px' }}
              >
                <div className="report-widget__filter">
                  <button
                    className="btn btn--outline"
                    type="button"
                    disabled={false}
                    onClick={handleDownloadCSV}
                  >
                    Download do relatório
                  </button>
                </div>
              </div>
              <DataGrid
                setParam={setParam}
                studentOrder={studentOrder}
                setStudentOrder={setStudentOrder}
                emailOrder={emailOrder}
                setEmailOrder={setEmailOrder}
                profileOrder={profileOrder}
                setProfileOrder={setProfileOrder}
                inepOrder={inepOrder}
                setInepOrder={setInepOrder}
                greOrder={setGreOrder}
                rightAnswersOrder={rightAnswersOrder}
                setRightAnswersOrder={setRightAnswersOrder}
                institutionOrder={institutionOrder}
                setInstitutionOrder={setInstitutionOrder}
                totalErrorsOrder={totalErrorsOrder}
                setTotalErrorsOrder={setTotalErrorsOrder}
                performanceOrder={performance}
                setPerformanceOrder={setPerformanceOrder}
                orderedReport={orderedReport}
                data={report.table}
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
