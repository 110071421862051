import { t } from 'i18next';
import React from 'react';

export default function QuestionBookCard(props) {
  const { questionbook, addQuestionbook, removeQuestionbook, isSelected, setRequireds, isRequired } = props;

  return (
    <div className="card card--h u-mb-2">
      <div
        className="card__body"
        style={{ flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <div className="card__inner-header">
          <h3
            className="card__title"
            title={questionbook.title}
          >
            {questionbook.title}
          </h3>
        </div>
        <div className="card__badges">
          {questionbook.taxonomies.slice(0, 3).map(taxonomy => {
            return (
              <span
                className="badge badge--primary"
                title={taxonomy.name}
              >
                {taxonomy.name}
              </span>
            );
          })}

          {questionbook.taxonomies.length > 3 ? (
            <span
              className="badge badge--more"
              title={`mais ${questionbook.taxonomies.length - 3} taxonomias`}
            >
              +{questionbook.taxonomies.length - 3}
            </span>
          ) : null}
        </div>
      </div>

      <div className="card__footer">
        <div className="form__check">
          <input
            className="form__check-input"
            id={`requiredResponse${questionbook.id}`}
            type="checkbox"
            checked={isRequired}
            onChange={event => {
              setRequireds(questionbook, event.target.checked);
            }}
          />
          <label
            className="form__check-label"
            htmlFor={`requiredResponse${questionbook.id}`}
          >
            Resposta obrigatória
          </label>
        </div>

        <button
          onClick={() => {
            if (isSelected) {
              removeQuestionbook(questionbook.id);
            } else {
              addQuestionbook({
                'question-book-id': questionbook.id,
                'is-required': isRequired
              });
            }
          }}
          className={isSelected ? 'btn btn--outline btn--wide active' : 'btn btn--outline btn--wide'}
        >
          {isSelected ? t('button.remove') : t('button.add')}
        </button>
      </div>
    </div>
  );
}
