import EmptyState from 'app/components/EmptyState';
import { useClient } from 'jsonapi-react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import AnswerPerformanceChart from './AnswerPerformanceChart';
import { useTranslation } from 'react-i18next';

export default function StackedPercentageWidget(props) {
  const { t } = useTranslation();
  const { timeFilterSelected } = props;
  const client = useClient();
  const [duration, setDuration] = useState();
  const [answeringPerformance, setAnsweringPerformance] = useState([]);

  const getAnsweringPerformance = async () => {
    const { data, error } = await client.fetch(`reports/question_books/answer_performance?week=${timeFilterSelected}`);
    if (error) {
      toast.error(t("toast.errorGetMetrics"));
    } else {
      setAnsweringPerformance(data);
    }
  };

  useEffect(() => {
    getAnsweringPerformance();
  }, [timeFilterSelected]);

  return (
    <div className="widget u-h-100">
      <div className="widget__header">
        <div className="widget__title-with-btn">
          <h3 className="widget__title u-mb-2">{t("questionBooks.exploitation")}</h3>
        </div>
      </div>
      <div className="widget__body">
        <AnswerPerformanceChart
          color="#442B8C"
          chartData={answeringPerformance}
          valueSuffix={false}
          timeFilterSelected={timeFilterSelected}
        />
      </div>
    </div>
  );
}
