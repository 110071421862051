import React from 'react';

function InfoWidget(props) {
  const { title = 'Widget title', children, className } = props;

  let classInfo = 'info-widget';
  if (className) classInfo = [classInfo, className].join(' ');

  return (
    <div className={classInfo}>
      <header className="info-widget__header">
        <h3 className="info-widget__title">{title}</h3>
      </header>
      <div className="info-widget__body">{children}</div>
    </div>
  );
}

export default InfoWidget;

function Value(props) {
  const { children } = props;

  return <p className="info-widget__value">{children}</p>;
}
InfoWidget.Value = Value;
