import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import closeIcon from '../images/icons/close-icon.svg';
import negativeIcon from '../images/icons/negative-icon.svg';
import plusIcon from '../images/icons/plus-icon.svg';
import arrowIcon from '../images/icons/arrow-icon.svg';
import backArrowIcon from '../images/icons/back-arrow-icon.svg';
import capIcon from '../images/icons/cap-icon.svg';
import { useTranslation } from 'react-i18next';

function modalTaxonomies(props) {
  const { t } = useTranslation();
  const { closeModal, selectedTaxonomies = [], setFieldValue } = props;
  const [taxonomies, setTaxonomies] = useState(props.taxonomies);
  const [topics, setTopics] = useState([]);
  const [subTopics, setSubTopics] = useState([]);
  const [isWrapActive, setIsWrapActive] = useState(false);

  const getTaxonomyParents = (allList, currentList, taxonomyId) => {
    const items = [];
    currentList.map(t => {
      if (t['taxonomy-children']) {
        if (t['taxonomy-children'].map(({ id }) => id).includes(taxonomyId)) {
          items.push(t);
          items.push(getTaxonomyParents(allList, allList, t.id));
        } else {
          items.push(getTaxonomyParents(allList, t['taxonomy-children'], taxonomyId));
        }
      }
    });
    return items;
  };

  const selectTaxonomy = (taxonomy, currentList, nextList) => {
    deactivateTaxonomies(currentList);
    taxonomy.isActive = true;

    let newTopics = taxonomy['taxonomy-children'];
    deactivateTaxonomies(nextList);
    selectedTaxonomies.forEach(item => {
      if (newTopics.includes(item)) {
        let index = newTopics.indexOf(item);
        let topic = newTopics[index];
        topic.isActive = true;
      }
    });

    setTaxonomies([...taxonomies]);
    if (nextList === 'subTopics') {
      setSubTopics([...newTopics]);
    } else {
      setTopics([...newTopics]);
    }
    setIsWrapActive(nextList === 'subTopics');
  };

  const selectTopic = topic => {
    const selectedTopic = selectedTaxonomies?.filter(t => t.id === topic.id).length === 1;

    if (!selectedTopic) {
      const parents = getTaxonomyParents(props.taxonomies, props.taxonomies, topic.id).flat(20);
      const allItems = [...selectedTaxonomies, topic, ...parents];
      const uniqueItems = Array.from(new Set(allItems.map(item => item.id))).map(id => allItems.find(item => item.id === id));
      setFieldValue('selectedTaxonomies', uniqueItems);
    } else {
      setFieldValue(
        'selectedTaxonomies',
        selectedTaxonomies?.filter(t => t.id !== topic.id)
      );
    }

    setTaxonomies([...taxonomies]);
  };

  const deactivateTaxonomies = listName => {
    if (listName === 'topics') {
      topics.forEach(i => {
        i.isActive = false;
      });
      setSubTopics([...topics]);
    } else {
      taxonomies.forEach(i => {
        i.isActive = false;
      });
      setTaxonomies([...taxonomies]);
    }
  };

  const unWrap = () => {
    setIsWrapActive(false);
  };

  return ReactDOM.createPortal(
    <section className="modal_taxonomy modal_taxonomy--overlay">
      <div className="modal_taxonomy__content">
        <h2 className="modal_taxonomy__title">{t('textsCommon.titleChooseSubject')}</h2>
        <div
          className="modal_taxonomy__close"
          onClick={closeModal}
        >
          <img
            alt=""
            src={closeIcon}
          />
        </div>
        <div className="clearfix"></div>

        <div className="modal_taxonomy__body">
          <div className={`modal_taxonomy__wrap ${isWrapActive ? 'active' : ''}`}>
            <div className="modal_taxonomy__collumn modal_taxonomy__body-collumn">
              <div className="modal_taxonomy__header">{t('textsCommon.matter')}</div>
              {taxonomies &&
                taxonomies.map(taxonomy => (
                  <div
                    key={taxonomy.id}
                    className={`modal_taxonomy__list-item ${taxonomy.isActive ? 'active' : ''}`}
                  >
                    <div
                      className="modal_taxonomy__list-item__icon"
                      onClick={() => selectTopic(taxonomy)}
                    >
                      <img
                        alt=""
                        src={selectedTaxonomies?.filter(t => t.id === taxonomy.id).length === 1 ? negativeIcon : plusIcon}
                      />
                    </div>
                    <div
                      className="modal_taxonomy__list-item__text"
                      onClick={() => selectTaxonomy(taxonomy, 'taxonomies', 'topics')}
                    >
                      <div className={`modal_taxonomy__list-item__name ${taxonomy.isActive ? 'active' : ''}`}>{taxonomy.name}</div>
                      <div className="modal_taxonomy__list-item__arrow">
                        <img
                          alt=""
                          src={arrowIcon}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="modal_taxonomy__collumn modal_taxonomy__body-collumn">
              <div
                className={`modal_taxonomy__back-button ${isWrapActive ? 'active' : ''}`}
                onClick={() => unWrap()}
              >
                <img
                  alt=""
                  src={backArrowIcon}
                />
              </div>
              <div className="modal_taxonomy__header">{t('textsCommon.topic')}</div>
              {topics && topics.length > 0 ? (
                topics.map(topic => (
                  <div
                    key={topic.id}
                    className={`modal_taxonomy__list-item ${topic.isActive ? 'active' : ''}`}
                  >
                    <div
                      className="modal_taxonomy__list-item__icon"
                      onClick={() => selectTopic(topic)}
                    >
                      <img
                        alt=""
                        src={selectedTaxonomies.filter(t => t.id === topic.id).length === 1 ? negativeIcon : plusIcon}
                      />
                    </div>
                    <div
                      className="modal_taxonomy__list-item__text"
                      onClick={() => selectTaxonomy(topic, 'topics', 'subTopics')}
                    >
                      <div className={`modal_taxonomy__list-item__name ${topic.isActive ? 'active' : ''}`}>{topic.name.substring(0, 35)}</div>
                      <div className="modal_taxonomy__list-item__arrow">
                        <img
                          alt=""
                          src={arrowIcon}
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="modal_taxonomy__collumn__advice">
                  <div className="modal_taxonomy__collumn__advice-icon">
                    <img
                      alt=""
                      src={capIcon}
                    />
                  </div>
                  <div className="modal_taxonomy__collumn__advice-text">{t('textsCommon.textSelectMatterToAdd')}..</div>
                </div>
              )}
            </div>

            <div className="modal_taxonomy__collumn modal_taxonomy__body-collumn">
              <div className="modal_taxonomy__header">Subtópico</div>
              {subTopics && subTopics.length > 0 ? (
                subTopics.map(topic => (
                  <div
                    key={topic.id}
                    className={`modal_taxonomy__list-item ${topic.isActive ? 'active' : ''}`}
                    onClick={() => selectTopic(topic)}
                  >
                    <div className="modal_taxonomy__list-item__icon">
                      <img
                        alt=""
                        src={selectedTaxonomies.filter(t => t.id === topic.id).length === 1 ? negativeIcon : plusIcon}
                      />
                    </div>
                    <div className={`modal_taxonomy__list-item__name ${topic.isActive ? 'active' : ''}`}>{topic.name.substring(0, 40)}</div>
                  </div>
                ))
              ) : (
                <div className="modal_taxonomy__collumn__advice">
                  <div className="modal_taxonomy__collumn__advice-icon">
                    <img
                      alt=""
                      src={capIcon}
                    />
                  </div>
                  <div className="modal_taxonomy__collumn__advice-text">{t('textsCommon.textSelectMatterToAdd')}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="modal_taxonomy__cta">
          <button
            type="submit"
            name="button"
            className="btn btn--primary btn--small btn--green"
            onClick={closeModal}
          >
            {t('button.confirm')}
          </button>
        </div>
      </div>
    </section>,
    document.getElementById('modal_root')
  );
}

export default modalTaxonomies;
