import React, { useEffect, useState } from 'react';
import { FiCheck, FiChevronDown, FiUser, FiUsers, FiHelpCircle, FiChevronUp } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import Loader from 'app/components/loader';

export default function ViewGRE({ institutions, loading, error }) {
  const [sortDirection, setSortDirection] = useState('desc');

  const ordenedInstitutions = institutions?.sort((a, b) => {
    if (sortDirection === 'asc') {
      return a.score - b.score;
    }
    if (sortDirection === 'desc') {
      return b.score - a.score;
    }
  });

  useEffect(() => {
    if (error) toast.error('Erro ao carregar ranking');
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="course-about__module fadeIn">
      <div className="round-dg-wrapper">
        <table
          className="round-dg round-dg--light"
          style={{ minWidth: '720px' }}
        >
          <thead className="round-dg__header">
            <tr className="round-dg__row">
              <th
                className="round-dg__cell-header round-dg__cell-header--sm"
                style={{ width: '80px' }}
              >
                Posição
              </th>
              <th
                className="round-dg__cell-header round-dg__cell-header--sm"
                style={{ width: '44px' }}
              />
              <th className="round-dg__cell-header round-dg__cell-header--sm">Instituição</th>
              <th
                className="round-dg__cell-header round-dg__cell-header--sm u-text-right"
                style={{ width: '124px' }}
              >
                Pontuação total{' '}
                <span
                  onClick={() => setSortDirection(prev => (prev === 'desc' ? 'asc' : 'desc'))}
                  className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
                >
                  {sortDirection === 'desc' ? <FiChevronDown className="card__icon" /> : <FiChevronUp className="card__icon" />}
                </span>
              </th>
            </tr>
          </thead>

          <tbody className="round-dg__body">
            {ordenedInstitutions?.map((item, i) => (
              <tr
                className="round-dg__row"
                key={i}
              >
                <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{i + 1}</th>
                <td className="round-dg__cell round-dg__cell--sm">
                  {i === 0 && <AiOutlineTrophy className="card__icon card__icon--golden" />}
                  {i === 1 && <AiOutlineTrophy className="card__icon card__icon--silver" />}
                  {i === 2 && <AiOutlineTrophy className="card__icon card__icon--bronze" />}
                </td>
                <td className="round-dg__cell round-dg__cell--sm">
                  <div className="round-dg__user">
                    <div className="round-dg__user-inner">
                      <h3 className="round-dg__title">{item.name}</h3>
                    </div>
                  </div>
                </td>
                <td className="round-dg__cell u-text-right">{item.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
