import { t } from 'i18next';
import React from 'react';

function TabNavBar({ activeTabs, setActiveTabs }) {
  return (
    <div className="tab">
      <div
        className={`tab__link ${activeTabs === 'geral' ? 'active' : ''}`}
        onClick={() => setActiveTabs('geral')}
      >
        {t('nps.toggleAll')}
      </div>
      <div
        className={`tab__link ${
          activeTabs === 'lista-de-alunos' ? 'active' : ''
        }`}
        onClick={() => setActiveTabs('lista-de-alunos')}
      >
        {t('nps.toggleStudentList')}
      </div>
    </div>
  );
}

export default TabNavBar;
