import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import 'moment/locale/pt';

function Chart(props) {
  const { name, color, chartData, valueSuffix, chartType } = props;

  const toUnixTimestamp = dateString => {
    const dateArr = dateString.split('-').map(e => parseInt(e));
    dateArr[1] = dateArr[1] - 1; // month start with index 0
    return Date.UTC(...dateArr);
  };

  const subscribersOptions = {
    chart: {
      type: chartType || 'areaspline',
      height: 200,
    },
    title: { text: null },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        // don't display the dummy year
        month: '%e. %b',
        year: '%b',
      },
      title: { text: null },
    },
    yAxis: {
      title: { text: null },
      labels: {
        format: `{value}${valueSuffix || ''}`,
      },
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%e. %b}: {point.y}',
      valueSuffix: valueSuffix,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5,
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: name,
        data: chartData.map(data => [toUnixTimestamp(data[0]), data[1]]),
        color: color,
      },
    ],
  };

  return (
    <HighchartsReact highcharts={Highcharts} options={subscribersOptions} />
  );
}

export default Chart;
