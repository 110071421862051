import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';

export default function AnswerPerformanceChart(props) {
  const { t } = useTranslation();
  const { chartData, timeFilteredSelected } = props;

  const chartOptions = {
    chart: {
      type: 'column'
    },
    colors: ['#FF726C', '#6FCF97'],
    title: {
      text: ''
    },
    xAxis: {
      categories: chartData.map(c => c.label)
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      },
      labels: {
        format: `{value}${'%'}`
      }
    },
    credits: {
      enabled: false
    },
    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true
    },
    plotOptions: {
      column: {
        stacking: 'percent'
      }
    },
    series: [
      {
        name: t('questionBooks.mistakes'),
        data: chartData.map(c => c.wrong)
      },
      {
        name: t('questionBooks.hits'),
        data: chartData.map(c => c.right)
      }
    ]
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
}
