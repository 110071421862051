import React, { useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FiMoreHorizontal } from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import multipleChoice from 'app/images/icons/multiple-choice.svg';
import rightOrWrong from 'app/images/icons/right-or-wrong.svg';
import dragAndDrop from 'app/images/icons/drag-and-drop.svg';
import ViewQuestionModal from './ViewQuestionModal';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import GeneratedContent from 'app/components/GeneratedContent';
import getPermission from 'app/utils/getPermission';
import { useSession } from 'app/hooks/useSession';

export default function QuestionCard(props) {
  const { t } = useTranslation();
  const { id, addBtn, hideActions, question, isSelected, selectedExam, addQuestion, removeQuestion } = props;
  const [showModal, setShowModal] = useState(false);
  const currentUser = useSession().session.user;

  const hasEditQuestionPermission = getPermission('Editar questões', 'Questões e exercícios');
  const isQuestionCreatorOrAdmin = !question?.['authored-by-user'] || (question?.['authored-by-user'] && currentUser.id === question?.creator.id) || currentUser?.profileNames.includes('Administrador');

  const hasExamManagePermission = getPermission('Gerenciar provas', 'Provas e simulados');
  const isExamCreatorOrAdmin = !selectedExam?.['authored-by-user'] || (selectedExam?.['authored-by-user'] && currentUser.id === selectedExam?.creator.id) || currentUser?.profileNames.includes('Administrador');

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
        <div className="card__header u-mb-0">
          <div className="card__inner-header">
            {!hideActions && (
              <img
                src={dragAndDrop}
                alt="ícone de arrastar"
                style={{ width: '10px', cursor: 'move', cursor: 'grab' }}
              />
            )}
            <div
              className="card__icon-wrapper"
              style={{ height: '32px', display: 'grid', placeContent: 'center' }}
              id={`question-type-${id}`}
              data-tooltip-content={question?.modality === 'multiple_choice' ? t('exams.selectMultipleChoice') : t('exams.selectTrueOrFalse')}
            >
              <img
                className="card__icon"
                src={question?.modality === 'multiple_choice' ? multipleChoice : rightOrWrong}
                alt="multitpla escolha"
                style={{ width: '16px' }}
              />
            </div>
            <ReactTooltip
              anchorId={`question-type-${id}`}
              className="tooltip"
            />
            <div className="round-dg__user-inner">
              <h3 className="round-dg__title">{question?.code}</h3>
              <p
                dangerouslySetInnerHTML={{ __html: question?.description }}
                className="round-dg__subtitle"
              />
            </div>
          </div>
        </div>
      </th>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '80px' }}
      >
        <span className="expanded-calendar__tag expanded-calendar__tag--bright">Q{question?.id}</span>
      </td>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '150px' }}
      >
        {question?.taxonomies?.length > 0 && (
          <div className="card__badges card__badges--one-line u-mb-0">
            {question?.taxonomies?.length <= 1 &&
              question?.taxonomies?.map(t => (
                <span
                  className="badge badge--primary"
                  key={t.id}
                  title={t.name}
                >
                  {t.name.slice(0, 20)}
                </span>
              ))}

            {question?.taxonomies?.length > 1 && (
              <>
                {question?.taxonomies.slice(0, 1).map(t => (
                  <span
                    className="badge badge--primary"
                    key={t.id}
                    title={t.name}
                  >
                    {t.name.length > 20 ? t.name.slice(0, 20) + '...' : t.name}
                  </span>
                ))}
                <span
                  className="badge badge--more"
                  title={question?.taxonomies.slice(1).map(t => ` ${t.name}`)}
                >
                  +{question?.taxonomies.length - 1}
                </span>
              </>
            )}
          </div>
        )}
      </td>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '120px' }}
      >
        <span
          className="badge badge--tiny badge--primary"
          style={{ backgroundColor: '#4F6BCC' }}
        >
          {t('exams.tagUnreleasedQuestion')}
        </span>
      </td>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '140px' }}
      >
        <GeneratedContent
          authorName={question?.creator?.name}
          verified={question?.['official-content']}
        />
      </td>
      {addBtn && (
        <td
          className="round-dg__cell round-dg__cell--sm"
          style={{ width: '120px' }}
        >
          <button
            type="button"
            className={`btn btn--small btn--full ${isSelected ? 'btn--primary' : 'btn--outline'}`}
            onClick={() => {
              if (isSelected) {
                removeQuestion(question);
              } else {
                addQuestion(question);
              }
            }}
          >
            {isSelected ? t('button.remove') : t('button.add')}
          </button>
        </td>
      )}
      {!hideActions && (
        <td
          className="round-dg__cell round-dg__cell--sm"
          style={{ width: '48px' }}
        >
          <Dropdown>
            <Dropdown.Toggle>
              <FiMoreHorizontal />
            </Dropdown.Toggle>

            <Dropdown.Content>
              {hasEditQuestionPermission && isQuestionCreatorOrAdmin && (
                <Dropdown.Item
                  onClick={() => {
                    navigate(`/questoes/criar/nome/${question.id}`);
                  }}
                >
                  {t('button.edit')}
                </Dropdown.Item>
              )}
              {hasExamManagePermission && isExamCreatorOrAdmin && (
                <Dropdown.Item
                  onClick={() => {
                    removeQuestion(question);
                  }}
                >
                  {t('button.delete')}
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => setShowModal(true)}>{t('button.view')}</Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>

          <ViewQuestionModal
            question={question}
            show={showModal}
            onClose={() => setShowModal(false)}
          />
        </td>
      )}
    </tr>
  );
}
