import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';

export default function NavInRankingByRegion(props) {
  const { show, setShow, selectedRegional, selectedInstitution } = props;

  return (
    <div className="filter-bar">
      <div className="filter-bar__inner">
        <button
          className={show.regional ? 'filter-bar__back' : 'filter-bar__back filter-bar__back--disabled'}
          style={{ alignSelf: 'center' }}
          onClick={() =>
            setShow({
              regional: true,
              institution: false,
              student: false
            })
          }
        >
          <span className="filter-bar__back-container">
            <span className="filter-bar__title">GREs</span>
          </span>
        </button>

        {(show.institution || show.student) && (
          <button
            className={show.student ? 'filter-bar__back filter-bar__back--disabled' : 'filter-bar__back'}
            onClick={() =>
              setShow({
                regional: true,
                institution: false,
                student: false
              })
            }
          >
            <span className="card__icon-wrapper card__icon-wrapper--sm">
              <FiChevronLeft className="card__icon" />
            </span>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{selectedRegional.name}</span>
            </span>
          </button>
        )}

        {show.student && (
          <button
            className="filter-bar__back"
            onClick={() =>
              setShow({
                regional: false,
                institution: true,
                student: false
              })
            }
          >
            <span className="card__icon-wrapper card__icon-wrapper--sm">
              <FiChevronLeft className="card__icon" />
            </span>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{selectedInstitution.name}</span>
            </span>
          </button>
        )}
      </div>
    </div>
  );
}
