import React from 'react';
import { Link } from '@reach/router';
import { t } from 'i18next';

function TabNavBar(props) {
  const { selectedTab } = props;
  return (
    <div className="tab">
      <Link
        className={selectedTab === 'turmas' ? 'tab__link active' : 'tab__link'}
        to="/turmas"
      >
        {t('courses.classes')}
      </Link>
      <Link
        className={selectedTab === 'convites' ? 'tab__link active' : 'tab__link'}
        to="/turmas/convites"
      >
        {t('classes.toggleInvitations')}
      </Link>
      <Link
        className={selectedTab === 'importar' ? 'tab__link active' : 'tab__link'}
        to="/turmas/importar"
      >
        {t('classes.toggleImportSpreadsheet')}
      </Link>
    </div>
  );
}

export default TabNavBar;
