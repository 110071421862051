import React from 'react';
import { AiOutlineTrophy } from 'react-icons/ai';

export default function PositionCard(props) {
  const { title = 'Título', data = [], lowTitle, highTitle, setSelectedPositions } = props;

  function handleClick(position) {
    if (setSelectedPositions) {
      setSelectedPositions(prevState => [...prevState, { value: position.id, label: position.name, type: 'position' }]);
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  return (
    <div className="report-position-card">
      <div className="report-position-card__header">
        <h3 className={`report-position-card__title ${lowTitle && 'report-position-card__title--danger'} ${highTitle && 'report-position-card__title--success'}`}>{title}</h3>
      </div>

      <div className="report-position-card__body">
        {data.map((position, index) => (
          <p
            className="report-position-card__item"
            onClick={() => handleClick(position)}
            key={position?.id}
          >
            <span className="report-position-card__id">#{position?.id}</span>
            <span className="report-position-card__name">{position?.name}</span>
            {(index === 0 || index === 1 || index === 2) && <AiOutlineTrophy className={`card__icon ${index === 0 && 'card__icon--golden'} ${index === 1 && 'card__icon--silver'} ${index === 2 && 'card__icon--bronze'}`} />}
          </p>
        ))}
      </div>
    </div>
  );
}
