import React, { useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import GroupCard from './GroupCard';
import { useEffect } from 'react';
import { t } from 'i18next';

export default function StepParticipants(props) {
  const { form, classrooms, courses, setCount, selectedCourse } = props;

  const [showGroups, setShowGroups] = useState(false);
  const [courseStudents, setCourseStudents] = useState([]);
  const [classroomStudents, setClassroomStudents] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  function groupRandomizer(users, groupSize) {
    let selectedUsers = users;
    users = users?.map(u => u.id);
    const groups = [];
    const selectedGroups = [];
    const sorting = Math.random() - 0.5;
    const scrambledUsers = users?.sort(() => sorting);
    const scrambledSelectedusers = selectedUsers?.sort(() => sorting);
    let currentGroup = [];
    let currentSelectedGroup = [];

    for (let i = 0; i < scrambledUsers.length; i++) {
      currentGroup.push(scrambledUsers[i]);

      if (currentGroup.length === groupSize) {
        groups.push(currentGroup);
        currentGroup = [];
      }
    }
    for (let i = 0; i < scrambledSelectedusers.length; i++) {
      currentSelectedGroup.push(scrambledSelectedusers[i]);

      if (currentSelectedGroup.length === groupSize) {
        selectedGroups.push(currentSelectedGroup);
        currentSelectedGroup = [];
      }
    }

    if (currentGroup.length > 0) {
      groups.push(currentGroup);
    }
    if (currentSelectedGroup.length > 0) {
      selectedGroups.push(currentSelectedGroup);
    }
    setGroups(groups);
    setSelectedGroups(selectedGroups);
    form.setFieldValue('students', groups);
    setCount(prevState => prevState + 1);
  }

  useEffect(() => {
    if (selectedCourse) {
      if (form.values.classroom.value !== '') {
        const courseUsers = selectedCourse?.users;
        const selectedClassroom = selectedCourse?.classrooms?.find(c => c?.id === +form?.values?.classroom?.value);
        const classroomUsers = courseUsers?.filter(u => selectedClassroom?.['user-ids'].includes(u.id));
        setClassroomStudents(classroomUsers?.filter(u => u?.['profile-names']?.includes('Aluno')));
      }

      setCourseStudents(selectedCourse?.users?.filter(u => u?.['profile-names'].includes('Aluno')));
    }
  }, [selectedCourse, form.values.classroom]);

  useEffect(() => {
    if (form.values.students.length > 0) {
      setGroups(form.values.students);
      setShowGroups(true);
    }
  }, []);

  return (
    <div className="page__content fadeIn u-mb-5">
      <div className="new-questions__header">
        <h2 className="new-questions__title">{form.values.title || t('projects.newProjects')}</h2>
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="groupSize"
        >
          *{t('projects.enterNumberParticipants')}
        </label>

        <input
          className="form__control"
          placeholder="Digite um número"
          id="groupSize"
          name="groupSize"
          type="number"
          value={form.values.groupSize}
          onChange={e => form.setFieldValue('groupSize', e.target.value)}
        />
        {form.touched.groupSize && form.errors.groupSize && <span style={{ color: 'red' }}>{form.errors.groupSize}</span>}

        <div
          className="alert alert--bgless u-mt-2 u-mb-4"
          role="alert"
        >
          <FiAlertCircle className="alert__icon" />
          <span>{t('projects.tipRandomParticipants')}</span>
        </div>

        <button
          type="button"
          className="btn btn--wide btn--primary"
          disabled={form.values.groupSize == undefined || form.values.groupSize == 0}
          onClick={() => {
            if (form.values.classroom !== '') {
              groupRandomizer(classroomStudents, +form.values.groupSize);
            } else {
              groupRandomizer(courseStudents, +form.values.groupSize);
            }
            setTimeout(() => setShowGroups(true), 1000);
          }}
        >
          {t('projects.buttonCreateGroups')}
        </button>
      </div>

      <span className="form__text u-mt-3">
        <strong>* {t('warning.requiredField')}</strong>
      </span>

      {showGroups && (
        <div className="card-wrapper card-wrapper--mh fadeIn">
          {selectedGroups.map((c, i) => {
            return (
              <GroupCard
                data={c}
                key={i}
                i={i}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
