import React from 'react';
import { navigate, Link } from '@reach/router';
import userAvatar from 'app/images/user-avatar.svg';
import moment from 'moment';
import { statusBadgeColor, statusBadgeText } from '../utils/BadgeEssay';

export default function EssayStudentCard({ student, essayId, currentUser }) {
  const statusClass = statusBadgeColor?.[student.status];
  const badgeText = statusBadgeText?.[student.status];

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <div
          className="round-dg__user u-cursor-pointer"
          onClick={() => navigate(`/usuario/metricas/${student?.user?.id}`)}
        >
          <img
            className="avatar avatar--xs"
            src={student?.user?.image || userAvatar}
            alt="Imagem do usuário"
          />

          <div className="round-dg__user-inner">
            <h3
              className="round-dg__title"
              title={student?.user?.name}
            >
              {student?.user?.name}
            </h3>
            {/* <p className="round-dg__subtitle">email</p> */}
          </div>
        </div>
      </th>

      <td className="round-dg__cell">{student?.['delivery-date'] ? moment(student?.['delivery-date']).format('DD/MM/YYYY') : '-'}</td>

      <td className="round-dg__cell">{student?.grade}</td>

      <td className="round-dg__cell" >
        <span
          title={badgeText}
          className={`badge badge--tiny ${statusClass}`}
        >
          {badgeText}
        </span>
      </td>

      <td className="round-dg__cell">{student?.corrector?.name}</td>

      <td className="round-dg__cell">
        {(student?.id && ['reviewed', 'unreviewed', 'annulled'].includes(student.status)) || (student.corrector && student.corrector.id === currentUser) ? (
          <Link
            className="btn btn--tiny btn--full btn--outline"
            to={`/redacao/correcao/${student?.id}/${essayId}`}
          >
            Acessar redação
          </Link>
        ) : null}
      </td>
    </tr>
  );
}
