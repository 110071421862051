import React, { useState, createContext } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useSession } from 'app/hooks/useSession';

export const TeacherDashboardContext = createContext();

export const TeacherDashboardProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);

  const { session } = useSession();
  const client = useClient();

  const getDashboardData = async (classroomId) => {
    setLoading(true);

    const { data, error } = await client.fetch(`dashboards?filter[classrooms.id]=${classroomId}`)

    if (error) {
      toast.error('Erro ao buscar informações do dashboard.');
    } else {
      setDashboardData(data)

      setLoading(false);
    }
  };

  return (
    <TeacherDashboardContext.Provider
      value={{
        loading,
        getDashboardData,
        dashboardData
      }}
    >
      {children}
    </TeacherDashboardContext.Provider>
  );
};
