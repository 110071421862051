import React, { useEffect, useState } from 'react';
import { PlaceholderModal } from 'app/components/Modal';
import Confetti from 'app/components/Confetti';
import CompletedConquest from './CompletedConquest';

export default function PtsModal(props) {
  const { setAchievementData, achievementData } = props;
  const [showPtsModal, setShowPtsModal] = useState(false);

  const onClose = () => {
    setShowPtsModal(false);
    setAchievementData(null);
  };

  useEffect(() => {
    if (achievementData) {
      setShowPtsModal(true);
    }
  }, [achievementData]);

  return (
    <PlaceholderModal
      show={showPtsModal}
      onClose={onClose}
    >
      <CompletedConquest
        achievementData={achievementData}
        onClose={onClose}
      />

      <Confetti />
    </PlaceholderModal>
  );
}
