import React from 'react';
import logo from '../../images/canaleducacao/logo-oros.svg';

const CanalEducacaoPrivacy = () => {
  return (
    <div className="terms__screen">
      <div className="terms__brand">
        <img
          alt="Logo Oros"
          src={logo}
        />
      </div>
      <div className="terms__container">
        <h1> HF TECNOLOGIA</h1>
        <h1>POLÍTICA DE PRIVACIDADE</h1>
        <h1>DOCUMENTO CRIADO EM 24 DE AGOSTO DE 2023.</h1>
        <p>
          A presente Política de Privacidade tem por objetivo esclarecer sobre as práticas de coleta, retenção e uso dos dados pessoais de visitantes e usuários dos nossos canais de comunicação digital, bem como sobre o compartilhamento dessas
          informações com fornecedores, parceiros de negócios e demais organizações com quem a HF TECNOLOGIA, mantém ou, porventura, venha a ter relação de negócio.
        </p>
        <p>
          A atuação da HF TECNOLOGIApor este termo é baseada na legislação que trata do assunto, especialmente Lei n.º 13.709, de 14 de agosto de 2018, denominada <strong>Lei Geral de Proteção de Dados (LGPD),</strong> assim como na
          <em> General Data Protection Regulation</em> (EU) 2016/679, ou <strong>Regulamento Geral de Proteção de Dados – GDPR.</strong>{' '}
        </p>
        <p>
          A HF TECNOLOGIA, atenta à necessidade de proteger os direitos fundamentais de liberdade e de privacidade, bem como o livre desenvolvimento da personalidade da pessoa natural, protege os dados pessoais de seus clientes, em consonância com os
          princípios da boa-fé, da finalidade, adequação, necessidade, livre acesso, qualidade dos dados, transparência, segurança, prevenção, não discriminação, e responsabilização e prestação de contas.
        </p>
        <p>A aceitação dos termos desta Política de Privacidade dar-se-á no momento em que o usuário consentir o seu conteúdo, o que indicará sua ciência e total concordância com as práticas aqui descritas.</p>
        <p>Os itens a seguir descreverão de forma clara e objetiva:</p>
        <p>
          <ul>
            <li> 1. Quais dados serão coletados;</li>
            <li> 2. De que forma estes dados serão utilizados;</li>
            <li> 3. Com quem os dados poderão ser compartilhados;</li>
            <li> 4. De que forma os dados serão tratados;;</li>
            <li> 5. Atualizações da Política de Privacidade.</li>
          </ul>
        </p>
        <p>
          <strong>1. Quais dados serão coletados?</strong>
        </p>
        <p>Os dados são coletados de formas distintas e se dividem em: </p>
        <p>
          <strong>Dados Fornecidos</strong>
        </p>
        <p>
          Dados informados pelo próprio usuário, por meio do preenchimento dos formulários de contato do website, aplicativo e landing pages.Podem incluir: nome, sobrenome, telefone, e-mail, conteúdo da mensagem, a entidade à qual o usuário está
          ligado, ramo de atividade, cargo, função ou escolaridade, dados de matrícula, objetivos pessoais, acadêmicos e/ou profissionais.
        </p>
        <p>Estes dados também poderão ser recebidos pelo aplicativo Whataspp e por meio das redes sociais Facebook, Instagram, Twitter, LinkedIn ou qualquer outra na qual a HF TECNOLOGIA possua perfil corporativo. </p>
        <strong>Dados Capturados Automaticamente</strong>
        <p>
          Ao navegar no sites ou aplicativo, são coletados automaticamente registros que incluem o endereço IP do dispositivo, a data e a hora do acesso, em cumprimento à Lei 12.965⁄2014, e seu compartilhamento dar-se-á somente com autorização
          expressa ou por meio de ordem judicial.
        </p>
        <p>
          Outros dados, ligados ao comportamento de navegação do usuário, como metadados, <em>cookies</em>, origem do acesso ao site, dados do navegador, páginas visitadas, tempo de permanência e ações realizadas, desempenho acadêmico na plataforma,
          poderão ser coletados pela HF TECNOLOGIA por meio de <em>scripts</em> e <em>softwares</em> de monitoramento.
        </p>
        <strong>2. De que forma estes dados serão utilizados?</strong>
        <p>Os dados e informações obtidos são tratados como confidenciais e seu uso se fará somente mediante o consentimento do usuário e para os seguintes fins:</p>
        <ul>
          <li>a) Comunicar e fazer propaganda sobre produtos, serviços, promoções, notícias, atualizações, eventos e demais assuntos de interesse do usuário;</li>
          <li>b) Enviar mensagens de serviços, notificações e atualizações, inclusive mediante e-mail ou Whatsapp;</li>
          <li>
            c) Construir listas para a realização de campanhas de <em>remarketing</em>;
          </li>
          <li>d) Personalizar nossos serviços de acordo com os interesses dos usuários;</li>
          <li>e) Desenvolver outros serviços, produtos e funcionalidades;</li>
          <li>
            f) Detectar e prevenir fraudes, <em>spam</em> e outros incidentes relacionados com segurança;
          </li>
          <li>g) Verificar ou autenticar as informações fornecidas, inclusive comparando com dados de outras fontes;</li>
          <li>h) Conhecer os comportamentos de navegação dos usuários e construir perfis comportamentais baseados em estatísticas;</li>
          <li>i) Desenvolver e/ou aprimorar algoritmos que podem ou não utilizar de inteligência artificial com o intuitode direcionar a jornada acadêmica do usuário de forma personalizada;</li>
          <li>
            j) Desenvolver estratégias e ações de comunicação e <em>marketing</em>, baseadas em estatísticas e perfis comportamentais;
          </li>
          <li>k) Cumprir obrigações legais e atender demandas judiciais;</li>
          <li>l) Para qualquer outro fim não previsto nesta política, desde que autorizado, no momento da coleta dos dados.</li>
        </ul>
        <p>
          A qualquer momento, o usuário poderá requerer a exclusão dos seus dados dos nossos servidores. Após a solicitação do usuário os dados serão apagados, salvo quando sua manutenção for necessária para o cumprimento de exigências legais ou para
          resguardar os direitos da HF TECNOLOGIA.
        </p>
        <p>
          <strong>3. Com quem os dados poderão ser compartilhados?</strong>
        </p>
        <p>
          Os dados fornecidos pelos usuários comporão a base de dados da HF TECNOLOGIA e poderão ser compartilhados com organizações parceiras, canais de revenda, empresas ligadas à ISG Participações S.A e demais entidades com as quais mantiver
          relação comercial.
        </p>
        <p>
          Todos os dados, informações e conteúdos poderão ser considerados ativos, em casos de negociações em que a HF TECNOLOGIA estiver envolvida, estando resguardado o direito de incluir estes dados no rol de ativos da empresa caso esta venha a
          ser vendida, total ou parcialmente, adquirida ou fundida com outra. Por meio desta Política, o usuário concorda e está ciente desta possibilidade.
        </p>
        <p>Ademais, a HF TECNOLOGIA se reserva no direito de fornecer dados e informações, incluindo interações, caso solicitada judicialmente.</p>
        <p>
          <strong>4. De que forma os dados serão tratados?</strong>
        </p>
        <p>Todos os dados obtidos pela HF TECNOLOGIA são confidenciais e somente pessoas devidamente autorizadas poderão acessá-los.</p>
        <p>Qualquer uso destas informações estará de acordo com a presente Política. Serão envidados todos os esforços para garantir a segurança dos nossos canais e dos dados dos usuários. </p>
        <p>
          A qualquer momento, o usuário poderá requisitar acesso aos seus dados armazenados, assim como atualizações e correções. O usuário poderá também requerer a exclusão dos seus dados dos cadastros de mala direta e <em>newsletter</em>.
        </p>
        <p>Os dados serão mantidos, pelo período necessário para os fins previstos nesta Política, pelo tempo exigido em lei ou para assegurar os direitos da HF TECNOLOGIA.</p>
        <p>Dúvidas, solicitações e qualquer questão ligada a esta Política poderão ser tratadas através do e-mail suporte@suporte-plataforma.com.br.</p>
        <p>
          <strong>5. Atualizações da Política de Privacidade</strong>
        </p>
        <p>Esta política poderá sofrer alterações e, portanto, ser atualizada, a fim de dar publicidade aos novos termos, sempre visando garantir maior segurança, conveniência, além de melhorar a experiência dos seus usuários</p>
      </div>
    </div>
  );
};

export default CanalEducacaoPrivacy;
