import { navigate } from '@reach/router';
import { t } from 'i18next';
import moment from 'moment';
import React from 'react';

export default function StudyPlanCard(props) {
  const { plan, title } = props;
  const initialDate = moment(plan['initial-date']).format(t('datesForm.formatDate'));
  const endDate = moment(plan['end-date']).format(t('datesForm.formatDate'));

  return (
    <div className="card card--p-md">
      <div className="card__header">
        <h3
          className="card__title"
          title={title}
        >
          {title}
        </h3>
        <p
          className="card__subtitle u-text-truncate"
          title={`Gerado a partir de ${plan.title}`}
        >
          {t('studyPlan.generate')} {plan.title}
        </p>
      </div>

      <div className="card__body">
        <div className="card__body">
          <div className="card__progress">
            <div className="card__progress-container">
              <div
                className="card__progress-bar"
                role="progressbar"
                style={{
                  width: `${plan.percentage}%`
                }}
                aria-valuenow={plan.percentage}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
            <div className="card__progress-text">{plan.percentage.toFixed(2)}%</div>
          </div>
        </div>
      </div>

      <div className="card__footer">
        <button
          className="btn btn--small btn--wide btn--outline"
          style={{ flexGrow: '0' }}
          onClick={() =>
            navigate(`/plano-de-estudos/${plan.id}`, {
              state: {
                initialDate: plan['initial-date'],
                endDate: plan['end-date'],
                title: plan['title']
              }
            })
          }
        >
          {t('button.access')}
        </button>
      </div>
    </div>
  );
}
