import React from 'react';
import userAvatar from 'app/images/user-avatar.svg';

export default function StudentTable() {
  return (
    <div className="round-dg-wrapper">
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: 800 }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th className="round-dg__cell-header round-dg__cell-header--sm">Nome e e-mail</th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">Série</th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 160 }}
            >
              Turma
            </th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {[...Array(6)].map((item, key) => (
            <tr
              className="round-dg__row"
              key={key}
            >
              <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
                <div className="round-dg__user">
                  <img
                    className="avatar avatar--xs"
                    src={false || userAvatar}
                    alt="Imagem do usuário"
                  />

                  <div className="round-dg__user-inner">
                    <h3 className="round-dg__title">Felipe Brandão de Oliveira</h3>
                    <p className="round-dg__subtitle">oliveira.brandao@email.com</p>
                  </div>
                </div>
              </th>
              <td className="round-dg__cell round-dg__cell--sm">
                <span className="u-text-truncate">3º série manhã</span>
              </td>
              <td className="round-dg__cell round-dg__cell--sm">
                <span className="u-text-truncate">A</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
