import React from 'react';
import { Link } from '@reach/router';
import userAvatar from 'app/images/user-avatar.svg';
import moment from 'moment';
import Avatar from '../Avatar';
import UserInfo from '../UserInfo';
import { t } from 'i18next';

function ActivityStudentCard(props) {
  const { activity, activityStudent } = props;
  const sent = activityStudent.comments.some(comment => !comment.user.includes('Aluno'));

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <div className="round-dg__user">
          <UserInfo user={activityStudent.user} />
        </div>
      </th>

      {sent ? (
        <>
          <td
            className="round-dg__cell"
            style={{ width: '180px' }}
          >
            <span className="badge badge--tiny badge--success">{t('activity.evaluationCompleted')}</span>
          </td>

          <td
            className="round-dg__cell"
            style={{ width: '200px' }}
          >
            <Link
              to={`/atividade-complementar/avaliacao/${activity?.id}/${activityStudent?.id}`}
              className="btn btn--small btn--outline btn--full"
            >
              {t('activity.viewEvaluation')}
            </Link>
          </td>
        </>
      ) : (
        <>
          <td
            className="round-dg__cell"
            style={{ width: '180px' }}
          >
            <span className="badge badge--tiny badge--warning">{t('activity.waitingEvaluation')}</span>
          </td>

          <td
            className="round-dg__cell"
            style={{ width: '200px' }}
          >
            <Link
              to={`/atividade-complementar/avaliar/${activity?.id}/${activityStudent.id}`}
              className="btn btn--small btn--outline btn--full"
            >
              {t('activity.evaluateActivity')}
            </Link>
          </td>
        </>
      )}
    </tr>
  );
}

export default ActivityStudentCard;
