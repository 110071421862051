import React from 'react';
import { navigate } from '@reach/router';
import { useSession } from 'app/hooks/useSession';
import { checkIncludeProfiles } from '../../../utils/getAuthorityLevel';
import getPermission from 'app/utils/getPermission';
import { t } from 'i18next';

export const TabNavbar = ({ selectedTab }) => {
  const { session } = useSession();
  const profiles = session.user?.profileNames || (session?.user && session?.user['profile-names']) || [];
  if (selectedTab === 'taxonomies' && profiles.includes('Professor')) {
    window.location.pathname = '/ementa/banco-de-questoes';
  }

  return (
    <div className="tab">
      {getPermission('Visualizar lista de ementas, tópicos e subtópicos', 'Ementas') && (
        <div
          className={`tab__link ${selectedTab === 'taxonomies' ? 'active' : ''}`}
          onClick={() => navigate('/ementa/materias')}
        >
          {t('taxonomyAndMaterials.toggleTaxonomies')}
        </div>
      )}

      {getPermission('Visualizar lista de arquivos', 'Arquivos e atividades complementares') && (
        <div
          className={`tab__link ${selectedTab === 'materiais' ? 'active' : ''}`}
          onClick={() => navigate('/ementa/materiais')}
        >
          {t('taxonomyAndMaterials.toggleMaterials')}
        </div>
      )}
    </div>
  );
};
