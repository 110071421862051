import React, { useMemo, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import { PiStudent } from 'react-icons/pi';
import { navigate } from '@reach/router';
import Avatar from 'app/components/Avatar';
import Medal from 'app/components/Medal';
import AccuracyRateTag from '../AccuracyRateTag';
import Loader from 'app/components/loader';
import EndScroll from 'app/components/endScroll';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function TeacherDataGrid({ filteredParticipants, hasMore, setPageNumber }) {
  const [order, setOrder] = useState('ascendent');
  const [param, setParam] = useState('accuracy');

  const orderedParticipants = useMemo(() => {
    let newArr = [];

    if (param === 'accuracy') {
      if (order === 'ascendent') {
        newArr = [...filteredParticipants].sort((a, b) => a['accuracy-rate'] - b['accuracy-rate']);
      } else {
        newArr = [...filteredParticipants].sort((a, b) => b['accuracy-rate'] - a['accuracy-rate']);
      }
    }

    if (param === 'participation') {
      if (order === 'ascendent') {
        newArr = [...filteredParticipants].sort((a, b) => a['participation-count'] - b['participation-count']);
      } else {
        newArr = [...filteredParticipants].sort((a, b) => b['participation-count'] - a['participation-count']);
      }
    }

    return newArr;
  }, [filteredParticipants, order, param]);

  const handleChange = (param, order) => {
    setParam(param);
    setOrder(order);
  };

  return (
    <div className="round-dg-wrapper">
      <InfiniteScroll
        dataLength={orderedParticipants.length}
        next={() => setPageNumber(prev => prev + 1)}
        hasMore={hasMore}
        loader={<Loader />}
        endMessage={<EndScroll />}
        className="infinite-scroll__full"
      >
        <table
          className="round-dg round-dg--light"
          style={{ minWidth: '1080px' }}
        >
          <thead className="round-dg__header">
            <tr className="round-dg__row">
              <th
                className="round-dg__cell-header round-dg__cell-header--sm"
                style={{ width: '80px' }}
              >
                Posição
              </th>
              <th
                className="round-dg__cell-header round-dg__cell-header--sm"
                style={{ width: '44px' }}
              />
              <th className="round-dg__cell-header round-dg__cell-header--sm">Professor</th>
              <th className="round-dg__cell-header round-dg__cell-header--sm">Instituição</th>
              <th
                className="round-dg__cell-header round-dg__cell-header--sm"
                style={{ width: '180px' }}
              >
                GRE
              </th>
              <th
                className="round-dg__cell-header round-dg__cell-header--sm"
                style={{ width: '140px' }}
              >
                Participação{' '}
                <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                  {order === 'descendent' && param === 'participation' ? (
                    <FiChevronDown
                      onClick={() => handleChange('participation', 'ascendent')}
                      className="card__icon"
                    />
                  ) : (
                    <FiChevronUp
                      onClick={() => handleChange('participation', 'descendent')}
                      className="card__icon"
                    />
                  )}
                </span>
              </th>
              <th
                className="round-dg__cell-header round-dg__cell-header--sm u-text-right"
                style={{ width: '150px' }}
              >
                Acerto em desafios{' '}
                <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                  {order === 'descendent' && param === 'accuracy' ? (
                    <FiChevronDown
                      onClick={() => handleChange('accuracy', 'ascendent')}
                      className="card__icon"
                    />
                  ) : (
                    <FiChevronUp
                      onClick={() => handleChange('accuracy', 'descendent')}
                      className="card__icon"
                    />
                  )}
                </span>
              </th>
            </tr>
          </thead>

          <tbody className="round-dg__body">
            {orderedParticipants?.map((item, i, self) => (
              <tr
                className="round-dg__row u-cursor-pointer"
                onClick={() => navigate(`/desafio-da-semana/ranking-por-participante/${item.id}/professor/challenge`)}
                key={i}
              >
                <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{order === 'ascendent' ? i + 1 : self.length - (i + 1)}</th>
                <td className="round-dg__cell round-dg__cell--sm">
                  {i === 0 && order === 'ascendent' && <AiOutlineTrophy className="card__icon card__icon--golden" />}
                  {i === 1 && order === 'ascendent' && <AiOutlineTrophy className="card__icon card__icon--silver" />}
                  {i === 2 && order === 'ascendent' && <AiOutlineTrophy className="card__icon card__icon--bronze" />}
                </td>
                <td className="round-dg__cell round-dg__cell--sm">
                  <div className="round-dg__user">
                    <Avatar
                      className="avatar--xxs"
                      src={item?.image?.url}
                      alt="Imagem do usuário"
                    />

                    <div className="round-dg__user-inner">
                      <h3 className="round-dg__title">{item.name}</h3>
                    </div>
                  </div>
                </td>
                <td className="round-dg__cell round-dg__cell--sm">
                  <div
                    className="card__badges card__badges--one-line u-mb-0"
                    style={{ lineHeight: 1, alignItems: 'center' }}
                  >
                    <span
                      className="u-text-truncate"
                      title={item.institutions.map(item => item)}
                    >
                      {item.institutions.length > 1 ? item.institutions.map(item => item) : item.institutions[0]}
                    </span>
                    {item.institutions.length > 1 && (
                      <span
                        className="badge badge--tiny badge--more"
                        title={item.institutions.slice(1).map(item => item)}
                      >
                        {`+${item.institutions.slice(1).length}`}
                      </span>
                    )}
                  </div>
                </td>
                <td className="round-dg__cell round-dg__cell--sm">
                  <div
                    className="card__badges card__badges--one-line u-mb-0"
                    style={{ lineHeight: 1, alignItems: 'center' }}
                  >
                    <span
                      className="u-text-truncate"
                      title={item.regionals.map(item => item)}
                    >
                      {item.regionals.map(item => item)}
                    </span>

                    {item.regionals.length > 1 && (
                      <span
                        className="badge badge--tiny badge--more"
                        title={item.regionals.slice(1).map(item => item)}
                      >
                        {`+${item.regionals.slice(1).length}`}
                      </span>
                    )}
                  </div>
                </td>
                <td className="round-dg__cell round-dg__cell--sm">
                  <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <Medal value={<PiStudent />} />
                    <span
                      className="u-text-truncate"
                      title={item['participation-count']}
                    >
                      {item['participation-count']}
                    </span>
                  </span>
                </td>
                <td className="round-dg__cell u-text-right">
                  <AccuracyRateTag value={item['accuracy-rate']} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
}
