import React, { useState, useEffect } from 'react';
import ExamMetricsExamListItemForAdmin from '../ExamMetrics/ExamMetricsExamListItemForAdmin';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';

export default function ExamMetrics(props) {
  const { t } = useTranslation();
  const { exams, courses, loading } = props;

  const filters = useFormik({
    initialValues: {
      course: 'blank'
    }
  });

  const options = courses?.map(item => {
    return { title: item.title, id: item.id };
  });

  const filteredExams = exams?.filter(item => {
    if (filters.values.course === 'blank') {
      return item;
    } else {
      return item?.['course-ids'].includes(parseInt(filters.values.course));
    }
  });

  const listItem = filteredExams?.map((exam, index) => {
    return (
      <ExamMetricsExamListItemForAdmin
        courses={courses}
        exam={exam}
        key={exam.id}
      />
    );
  });

  return (
    <div>
      <div className="filter-bar">
        <FilterSelectionBox
          label={t('filter.labelSelectCourse')}
          blankLabel={t('filter.blankLabelAll')}
          value={filters.values.course}
          onChange={e => {
            filters.setFieldValue('course', e.target.value);
          }}
          options={options}
        />
      </div>
      {loading && listItem.length === 0 ? (
        <Loader />
      ) : !loading && listItem.length === 0 ? (
        <EmptyState type="content" />
      ) : (
        <div className="round-dg-wrapper">
          <table className="round-dg round-dg--light">
            <thead class="round-dg__header">
              <tr className="round-dg__row">
                <th
                  class="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '200px' }}
                >
                  {t('exams.thExams')}
                </th>
                <th
                  class="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '200px' }}
                >
                  {t('exams.thCourse')}
                </th>
                <th
                  class="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '160px' }}
                >
                  {t('exams.thResolutionDate')}
                </th>
                <th
                  class="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '92px' }}
                >
                  {t('exams.thAvarageScore')}
                </th>
                <th
                  class="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '92px' }}
                >
                  {t('exams.thParticipants')}
                </th>
                <th
                  class="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '132px' }}
                >
                  {/* {t('exams.thActions')} */}
                </th>
              </tr>
            </thead>

            <tbody className="round-dg__body">{listItem}</tbody>
          </table>
        </div>
      )}
    </div>
  );
}
