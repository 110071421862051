import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from '@reach/router';
import { SessionContext } from '../../../providers/SessionProvider';
import { useMutation } from 'jsonapi-react';
import { useClient } from 'jsonapi-react';
import getPermission from 'app/utils/getPermission';
import Error404 from 'app/components/Error404';

function PrivateRoute(parentProps) {
  const [loading, setLoading] = useState(true);
  const [logged, setLogged] = useState(true);
  const [addRouteLog] = useMutation('route_logs');
  const state = useContext(SessionContext);
  const { as: Comp, roleName, setCurrentPath, roleGroup, enabled, ...props } = parentProps;
  const client = useClient();

  useEffect(() => {
    createRequestLog();
    setCurrentPath(parentProps.path);
    updateNotifications();
  }, []);

  const updateNotifications = async () => {
    const pathTargetObject = [
      { entity: ['LearningSystem'], paths: ['/show-course/:id/classroom'], idKey: 'id' },
      { entity: ['Slot'], paths: ['/minhas-tarefas/tarefa/:taskId'], idKey: 'taskId' },
      { entity: ['Project', 'ProjectVersion'], paths: ['/projetos/:projectId'], idKey: 'projectId' },
      { entity: ['Post'], paths: ['/posts/:slug'], idKey: 'slug' },
      { entity: ['Activity'], paths: ['/atividade-complementar', '/atividade-complementar/atividade/:id', '/atividade-complementar/avaliar-atividades/:id'], idKey: 'id' },
      { entity: ['Content'], paths: ['/materiais/:materialId'], idKey: 'materialId' },
      { entity: ['Event'], paths: ['/evento/:eventId'], idKey: 'eventId' },
      { entity: ['LearningSystemItem'], paths: ['/lms/visualizar/modulo/:id/disciplina/:disciplineId/aula/:lessonId', '/show-course/:id/classroom'], idKey: 'id' },
      // { entity: ['LearningSystemUserItem'], paths: ['/lms/certificado'], idKey: 'id' },
      { entity: ['QuestionBook'], paths: ['/questions/:id'], idKey: 'id' },
      { entity: ['Survey'], paths: ['/questionario/:representation/:id'], idKey: 'id' }
    ];

    const notificableType = pathTargetObject.find(e => e.paths.includes(parentProps.path));
    if (notificableType) {
      await client.mutate(['update_all/notifications'], {
        'notificable-id': parentProps[notificableType.idKey],
        'notificable-type': notificableType.entity
      });
    }
  };

  const createRequestLog = async () => {
    if (state.session && Object.keys(state.session).length !== 0) {
      const expirationDate = state.session?.user?.tokenExpirationDate || (state.session?.user && state.session?.user['token-expiration-date']);
      const tokenExpirationDate = new Date(expirationDate);
      if (tokenExpirationDate < new Date()) {
        setLogged(false);
        localStorage.setItem('session', null);
        localStorage.setItem('redirectUrl', location.pathname);
        setLoading(false);
      } else {
        addRouteLog({ path: parentProps.path, uri: parentProps.uri }).then((response, b, c) => {
          if (response?.error?.title === 'Not Authorized') {
            setLogged(false);
            localStorage.setItem('session', null);
            localStorage.setItem('redirectUrl', location.pathname);
          }
        });
        setLoading(false);
      }
    } else {
      setLogged(false);
      localStorage.setItem('session', null);
      localStorage.setItem('redirectUrl', location.pathname);
      setLoading(false);
    }
  };

  let compChild = undefined;
  if (loading) {
    compChild = <></>;
  } else if (enabled === false) {
    compChild = <Error404 />;
  } else if (logged && roleName && roleGroup && !getPermission(roleName, roleGroup)) {
    compChild = <Error404 />;
  } else if (logged) {
    compChild = <Comp {...props} />;
  } else {
    compChild = (
      <Redirect
        noThrow
        to="/login"
      />
    );
  }
  return compChild;
}

export default PrivateRoute;
