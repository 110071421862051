import React from 'react';
import Medal from 'app/components/Medal';
import stars from './img/stars.svg';

export default function MedalStack(props) {
  const { value = 1 } = props;

  const number = value >= 3 ? 2 : value - 1;

  return (
    <ul className="medal-stack">
      {value > 1 &&
        [...Array(number)].map((medal, i) => (
          <li className="medal-stack__item" key={i}>
            <Medal size="md" />
          </li>
        ))}
      <li className="medal-stack__item">
        <Medal size="md" value={value} />
        <img className="medal-stack__img" src={stars} alt="estrelas" />
      </li>
    </ul>
  );
}
