import React from 'react';
import CourseCard from './CourseCard';

export default function InitiatedCourses() {
  return (
    <div className="widget u-mb-4">
      <div className="course-wrapper course-wrapper--list">
        <CourseCard status="finalized" />
        {[...Array(4)].map((course, index) => (
          <CourseCard key={index} />
        ))}
      </div>
    </div>
  );
}
