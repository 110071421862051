import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from '@reach/router';
import { SimpleModal } from 'app/components/Modal';
import userAvatar from 'app/images/user-avatar.svg';
import moment from 'moment';
import Avatar from 'app/components/Avatar';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import Progress from 'app/components/Progress';
import xlsx from 'json-as-xlsx';
import Loader from 'app/components/loader';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

export default function BoxWidget(props) {
  const { t } = useTranslation();
  const { studentsData, filterTypes, selectedRegionals, selectedInstitutions, selectedCourses, selectedModules } = props;
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [studentList, setStudentList] = useState([]);
  const [currentCard, setCurrentCard] = useState();
  const [loading, setLoading] = useState(false);
  const boxHeight = 420;

  const getStudentsList = async id => {
    setLoading(true);
    const queryParams = [];

    if (filterTypes.includes('regional')) {
      queryParams.push(`regional_id=${selectedRegionals.map(item => item.value)}`);
    }
    if (filterTypes.includes('institution')) {
      queryParams.push(`institution_id=${selectedInstitutions.map(item => item.value)}`);
    }
    if (filterTypes.includes('course')) {
      queryParams.push(`course_id=${selectedCourses.map(item => item.value)}`);
    }
    if (filterTypes.includes('module')) {
      queryParams.push(`learning_system_id=${selectedModules.map(item => item.value)}`);
    }

    const { data, error } = await client.fetch(`/reports/funnels/${id}?${queryParams.join('&')}`);
    if (error) {
      toast.error('Erro ao buscar dados');
    } else {
      setStudentList(data);
    }
    setLoading(false);
  };

  const openModal = selectedCard => {
    setCurrentCard(selectedCard);
    getStudentsList(selectedCard.id);
    setShowModal(true);
  };

  const Card = ({ percentage, count, label, danger, key, currentCard }) => {
    return (
      <div
        className={`box-widget ${danger && 'box-widget--danger'}`}
        style={{ height: key === 0 ? `${boxHeight}px` : `${percentage}%` }}
        onClick={() => openModal(currentCard)}
      >
        <div className="box-widget__body">
          <p className="box-widget__text">
            {count} <span>({percentage}%)</span>
          </p>
        </div>
        <div className="box-widget__header">
          <h3 className="box-widget__title">{label}</h3>
        </div>
      </div>
    );
  };

  const renderItems = useCallback(arr => {
    if (arr.length === 1) return arr[0];
    if (arr.length > 1) {
      return (
        <span style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <span>{arr[0]}</span>
          <span
            className="badge badge--more badge--tiny"
            title={arr
              .slice(1)
              .map(item => item)
              .join(', ')}
          >
            +{arr.length - 1}
          </span>
        </span>
      );
    }
  }, []);

  const xlsxStructure = [
    {
      sheet: 'Usuários',
      columns: [
        { label: 'Nome', value: 'Nome' },
        { label: 'Email', value: 'Email' },
        { label: 'GRE', value: 'GRE' },
        { label: 'Instituição', value: 'Instituição' },
        { label: 'Série', value: 'Série' },
        { label: 'Turma', value: 'Turma' },
        { label: 'Último Acesso', value: 'Último Acesso' },
        { label: 'Progresso', value: 'Progresso' },
        { label: 'Status', value: 'Status' }
      ],
      content: studentList?.users?.map(item => ({
        Nome: item.name,
        Email: item.email,
        Instituição: item.institutions?.map(item => item).join(', '),
        GRE: item.regionals?.map(item => item).join(', '),
        Turma: item.classrooms?.map(item => item).join(', '),
        Série: item.courses?.map(item => item).join(', '),
        'Último Acesso': item['last-access'] ? new Date(item['last-access']).toLocaleDateString('pt-br') : '-',
        '% de Conclusão': `${parseInt(item.progress)}%`,
        Status: item.status
      }))
    }
  ];

  const handleDownloadCSV = () => {
    try {
      xlsx(xlsxStructure);
    } catch (e) {
      console.log(e);
    }
  };

  const allColumns = useMemo(() => {
    const firstColumn = [];
    const secondColumn = [];
    const thirdColumn = [];
    const fourthColumn = [];

    studentsData.forEach(item => {
      if (item.column === 1) {
        firstColumn.push(item);
      }
      if (item.column === 2) {
        secondColumn.push(item);
      }
      if (item.column === 3) {
        thirdColumn.push(item);
      }
      if (item.column === 4) {
        fourthColumn.push(item);
      }
    });

    return [firstColumn, secondColumn, thirdColumn, fourthColumn];
  }, [studentsData]);

  const [dateOrder, setDateOrder] = useState('descendent');
  const [progressOrder, setProgressOrder] = useState('descendent');
  const [param, setParam] = useState('date');

  const orderByLastAccess = useCallback(
    (a, b) => {
      const lastAccessA = new Date(a['last-access']);
      const lastAccessB = new Date(b['last-access']);
      if (dateOrder === 'descendent') {
        return lastAccessB - lastAccessA;
      } else {
        return lastAccessA - lastAccessB;
      }
    },
    [dateOrder]
  );

  const orderByProgress = useCallback(
    (a, b) => {
      if (progressOrder === 'descendent') {
        return b.progress - a.progress;
      } else {
        return a.progress - b.progress;
      }
    },
    [progressOrder]
  );

  const orderedStudents = useMemo(() => {
    let arr = [];
    if (param === 'date') {
      arr = studentList?.users?.sort(orderByLastAccess);
    }
    if (param === 'progress') {
      arr = studentList?.users?.sort(orderByProgress);
    }
    return arr;
  }, [param, studentList, dateOrder, progressOrder]);

  return (
    <div className="box-widget-wrapper box-widget--funnel">
      {allColumns.map(column => {
        if (column.length > 0)
          return (
            <div className="box-widget__col">
              {column.map((card, index) => (
                <Card
                  currentCard={card}
                  key={index}
                  danger={card.danger}
                  id={card.id}
                  percentage={card.percentage}
                  count={card.count}
                  label={card.label}
                />
              ))}
            </div>
          );
      })}

      <SimpleModal
        show={showModal}
        onClose={() => {
          setSearchTerm('');
          setShowModal(false);
        }}
        contentClassName="simple-modal__content--full simple-modal__content--br-full"
      >
        <div className="modal__simple-header">
          <h3 className="modal__simple-title modal__simple-title--with-btn">{currentCard?.label}</h3>
          <span className="widget__count">
            {currentCard?.count}{' '}
            <span>
              ({currentCard?.percentage})% {t('metrics.student')}s
            </span>
          </span>
        </div>

        <div className="form__row">
          <label
            htmlFor="participantsSearch"
            className="form__label"
          >
            {t('textsCommon.research')}
          </label>

          <input
            aria-label="Buscar estudante"
            className="form__control form__control--search-with-icon"
            style={{ width: 'auto' }}
            placeholder={t('filter.placeholderStudentName')}
            type="search"
            name="studentsSearch"
            id="studentsSearch"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <div
          className="round-dg-wrapper"
          style={{ maxHeight: 490 }}
        >
          {loading ? (
            <Loader />
          ) : (
            <table
              className="round-dg round-dg--light"
              style={{ tableLayout: 'auto', minWidth: 1100 }}
            >
              <thead className="round-dg__header">
                <tr className="round-dg__row">
                  <th
                    className="round-dg__cell-header"
                    scope="col"
                  >
                    {t('metrics.student')}
                  </th>
                  <th
                    className="round-dg__cell-header"
                    scope="col"
                  >
                    GRE
                  </th>
                  <th
                    className="round-dg__cell-header"
                    scope="col"
                  >
                    Instituição
                  </th>
                  <th
                    className="round-dg__cell-header"
                    scope="col"
                    style={{ width: '135px' }}
                  >
                    Série
                  </th>
                  <th
                    className="round-dg__cell-header"
                    scope="col"
                    style={{ width: '135px' }}
                  >
                    Turma
                  </th>
                  <th
                    className="round-dg__cell-header round-dg__cell-header--sm"
                    scope="col"
                    style={{ width: '120px' }}
                  >
                    Último acesso
                    <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                      {dateOrder === 'descendent' ? (
                        <FiChevronDown
                          onClick={() => {
                            setDateOrder('ascendent');
                            setParam('date');
                          }}
                        />
                      ) : (
                        <FiChevronUp
                          onClick={() => {
                            setDateOrder('descendent');
                            setParam('date');
                          }}
                        />
                      )}
                    </span>
                  </th>
                  <th
                    className="round-dg__cell-header round-dg__cell-header--sm"
                    scope="col"
                    style={{ width: '120px' }}
                  >
                    Progresso
                    <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                      {progressOrder === 'descendent' ? (
                        <FiChevronDown
                          onClick={() => {
                            setProgressOrder('ascendent');
                            setParam('progress');
                          }}
                        />
                      ) : (
                        <FiChevronUp
                          onClick={() => {
                            setProgressOrder('descendent');
                            setParam('progress');
                          }}
                        />
                      )}
                    </span>
                  </th>
                  <th
                    className="round-dg__cell-header round-dg__cell-header--sm"
                    scope="col"
                  >
                    Status
                  </th>
                </tr>
              </thead>

              <tbody className="round-dg__body round-dg__body--funnel">
                {orderedStudents
                  ?.filter(u => {
                    if (searchTerm == '') {
                      return u;
                    }
                    if (u.name != null && u.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                      return u;
                    }
                  })
                  .map((student, index) => (
                    <tr
                      className="round-dg__row"
                      key={index}
                    >
                      <th
                        className="round-dg__cell-header "
                        scope="row"
                      >
                        <Link
                          to={`/usuario/metricas/${student?.id}`}
                          state={{ isRedirected: true }}
                          style={{ display: 'flex' }}
                        >
                          <Avatar
                            src={student.image?.url}
                            className="round-dg__avatar"
                          />
                          <span
                            title={student.name}
                            className="accessed-materials-widget__title accessed-materials-widget__title--auto u-text-truncate"
                          >
                            {student.name}
                            <span
                              title={student.email}
                              className="accessed-materials-widget__datetime u-w-100"
                            >
                              {student.email}
                            </span>
                          </span>
                        </Link>
                      </th>
                      <td className="round-dg__cell ">{renderItems(student?.regionals)}</td>
                      <td
                        className="round-dg__cell "
                        style={{ alignContent: 'center' }}
                      >
                        {renderItems(student?.institutions)}
                      </td>
                      <td className="round-dg__cell ">{renderItems(student?.courses)}</td>
                      <td className="round-dg__cell ">{renderItems(student?.classrooms)}</td>
                      <td
                        style={{ paddingLeft: student['last-access'] ? 0 : 45 }}
                        className={`round-dg__cell  ${student.status === 'Ativo' ? '' : ' round-dg__cell--danger'}`}
                      >
                        {student['last-access'] ? moment(student['last-access']).format('DD/MM/YYYY HH:mm') : '-'}
                      </td>
                      <td className="round-dg__cell ">
                        {
                          <Progress.Card
                            toFixed={0}
                            percentage={parseInt(student?.progress)}
                          />
                        }
                      </td>
                      <td className="round-dg__cell round-dg__cell--sm">
                        <span className={`badge badge--tiny ${student.status === 'Ativo' ? 'badge--success' : 'badge--warning'}`}>{student.status}</span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <div
          className=" btn btn--primary btn--wide u-mt-4"
          onClick={handleDownloadCSV}
        >
          Baixar lista
        </div>
      </SimpleModal>
    </div>
  );
}
