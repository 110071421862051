import React, { useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import styles from './AnswerKey.module.scss';
import AnswerKeyRow from './AnswerKeyRow';
import GeneratedContent from 'app/components/GeneratedContent';

export default function AccordionItem() {
  const [active, setActive] = useState(false);

  const accordionContent = useRef(null);

  return (
    <div className={active ? 'accordion__item active' : 'accordion__item'}>
      <div
        className="accordion__header"
        onClick={() => setActive(!active)}
      >
        <AnswerKeyRow />

        <button className="card__icon-wrapper card__icon-wrapper--sm u-ml-auto">
          <FiChevronDown className="card__icon" />
        </button>
      </div>

      <div
        className="accordion__collapse"
        style={{
          height: active ? `${accordionContent.current.scrollHeight}px` : '0'
        }}
      >
        <div
          className="accordion__content"
          ref={accordionContent}
        >
          <div className={styles.content}>
            <div className={styles.textWrapper}>
              <div className={styles.header}>
                <h3 className={styles.title}>Enunciado:</h3>

                <GeneratedContent
                  authorName={'Maria da Conceição'}
                  verified={true}
                />
              </div>
              <div className={styles.body}>
                <p className={styles.text}>
                  No decorrer dos primeiros anos do governo Vargas, verificou-se forte crescimento do setor industrial brasileiro, o que pode ser explicado, entre outros fatores, pela crise internacional que permitiu a substituição da importação por
                  ações de proteção à indústria e a medidas cambiais.
                </p>
              </div>
            </div>

            <div className={styles.textWrapper}>
              <div className={styles.header}>
                <h3 className={styles.title}>Resposta do estudante</h3>
              </div>
              <div className={styles.body}>
                <p className={styles.text}>
                  No decorrer dos primeiros anos do governo Vargas, verificou-se forte crescimento do setor industrial brasileiro, o que pode ser explicado, entre outros fatores, pela crise internacional que permitiu a substituição da importação por
                  ações de proteção à indústria e a medidas cambiais.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
