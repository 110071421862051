import React from 'react';

export default function AnswerKeyHeader() {
  return (
    <div
      className="round-dg-wrapper u-mb-2"
      style={{ width: '100%', paddingRight: 56 }}
    >
      <table style={{ width: '100%', minWidth: 552 }}>
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '25%' }}
            >
              ID da questão
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '25%' }}
            >
              Resposta esperada
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '25%' }}
            >
              Resposta do estudante
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '25%' }}
            >
              Nível do estudante
            </th>
          </tr>
        </thead>
      </table>
    </div>
  );
}
