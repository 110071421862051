import api from './api.js';

export const searchTextLink = (text) => {
  let links = [];
  let urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._,+~#=]{2,256}\.[a-z]{2,6}\b([-A-zÀ-ú0-9@:%_,+.~#?&//=]*)([ \n])/g;
  text.replace(urlRegex, function(url) {
    links.push(url.replace(/\s+$/, ''));
  });
  return links;
}

export const getLinkInfo = async (url) => {
  return new Promise(function(resolve, reject) {
    api.get(`link_infos/search?url=${url}`,).then(response => {
      resolve(response.data)
    }).catch(error => {
      console.error(error)
    });
  });
}
