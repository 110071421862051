import React, { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { Link, useLocation } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';
import BreadCrumbs from 'app/components/BreadCrumbs';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import EmptyState from 'app/components/EmptyState';
import AccordionItem from './AccordionItem';
import Loader from 'app/components/loader';
import ChallengeHeader from './ChallengeHeader';
import ChallengeResultBanner from './ChallengeResultBanner';
import ChallengeTab from '../../ChallengeTab';
import { navigate } from '@reach/router';

export default function ViewParticipantResult(props) {
  const { challengeId, selectedTab, participantProfile, uri, studentId } = props;
  const { state } = useLocation();

  const client = useClient();

  const [loading, setLoading] = useState(false);
  const [challenges, setChallenges] = useState([]);
  const [studentChallenge, setStudentChallenge] = useState();
  const [param, setParam] = useState('period');
  const [orderByPeriod, setOrderByPeriod] = useState('descendent');
  const [orderByAccuracy, setOrderByAccuracy] = useState('descendent');

  const filters = useFormik({
    initialValues: {
      theme: ''
    }
  });

  const orderedChallenges = useMemo(() => {
    let newArr;

    if (param === 'period' && orderByPeriod === 'descendent') {
      newArr = [...challenges].sort((a, b) => {
        return a.challenge['initial-date'] - b.challenge['initial-date'];
      });
    }
    if (param === 'period' && orderByPeriod === 'ascendent') {
      newArr = [...challenges].sort((a, b) => {
        return b.challenge['initial-date)'] - a.challenge['initial-date'];
      });
    }
    if (param === 'accuracy' && orderByAccuracy === 'descendent') {
      newArr = challenges.sort((a, b) => a['student-accuracy'] - b['student-accuracy']);
    }
    if (param === 'accuracy' && orderByAccuracy === 'ascendent') {
      newArr = challenges.sort((a, b) => b['student-accuracy'] - a['student-accuracy']);
    }

    return newArr;
  }, [param, orderByPeriod, orderByAccuracy, challenges]);

  function createDate(dateString) {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  const fetchChallenges = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/challenges/student_results/${studentId}/question_accuracy`);
    if (error) {
      toast.error('Erro ao buscar desafios');
    } else {
      setChallenges(
        data.data.map(item => {
          return { ...item, challenge: { ...item.challenge, 'initial-date': createDate(item.challenge['initial-date']), 'end-date': createDate(item.challenge['end-date']) } };
        })
      );
    }

    setLoading(false);
  };

  const fetchStudentChallenge = async () => {
    const { data, error } = await client.fetch(`/challenges/student_results/${studentId}`);
    if (error) {
      toast.error('Erro ao buscar desafios');
    } else {
      setStudentChallenge(data.data);
    }
  };

  useEffect(() => {
    fetchChallenges();
    fetchStudentChallenge();
  }, []);

  const breadCrumbs = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: '/desafio-da-semana/resultados',
        name: 'Desafio da Semana',
        isActive: false
      },
      {
        route: `/desafio-da-semana/resultados/${challengeId}`,
        name: challenges[0]?.challenge?.title,
        isActive: false
      },
      {
        route: uri,
        name: studentChallenge?.user?.name,
        isActive: true
      }
    ]
  };

  const breadCrumbsInRank = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: '/desafio-da-semana/resultados',
        name: 'Desafio da Semana',
        isActive: true
      }
    ]
  };

  const hasChallenges = orderedChallenges?.length > 0;

  const isStudent = participantProfile === 'estudante';

  return (
    <main className="main-content main-content--block">
      {selectedTab == 'ranking-por-participante' ? <BreadCrumbs data={breadCrumbsInRank} /> : <BreadCrumbs data={breadCrumbs} />}

      {selectedTab == 'ranking-por-participante' && <ChallengeTab selectedTab={selectedTab} />}

      {selectedTab == 'ranking-por-participante' && (
        <div className="filter-bar">
          <div className="filter-bar__inner">
            <button
              className="filter-bar__back"
              onClick={() => navigate(-1)}
            >
              <span className="card__icon-wrapper card__icon-wrapper--sm">
                <FiChevronLeft className="card__icon" />
              </span>
              <span className="filter-bar__back-container">
                <span className="filter-bar__title">{studentChallenge?.user?.name} </span>
              </span>
            </button>
          </div>
        </div>
      )}

      <ChallengeResultBanner
        showTrophy={state?.position}
        studentchallenge={studentChallenge}
        isStudent={isStudent}
        selectedTab={selectedTab}
      />

      {isStudent && (
        <div className="filter-bar filter-bar--borderless">
          <div className="filter-bar__inner">
            <FilterSelectionBox
              optionBlank={''}
              label={'Tema'}
              blankLabel={'Selecione um tema'}
              value={filters.values.theme}
              onChange={e => filters.setFieldValue('theme', e.target.value)}
              // options={themes}
            />
          </div>
        </div>
      )}

      <ChallengeHeader
        setParam={setParam}
        setOrderByAccuracy={setOrderByAccuracy}
        setOrderByPeriod={setOrderByPeriod}
        orderByAccuracy={orderByAccuracy}
        orderByPeriod={orderByPeriod}
        selectedTab={selectedTab}
        isStudent={isStudent}
      />

      {loading ? (
        <Loader />
      ) : hasChallenges ? (
        <div className="accordion">
          {orderedChallenges?.map(item => (
            <AccordionItem
              taxonomies={item?.['accuracy-by-taxonomy']}
              studentAccuracy={item?.['student-accuracy']}
              challenge={item.challenge}
              isStudent={isStudent}
              selectedTab={selectedTab}
              key={item.id}
            />
          ))}
        </div>
      ) : (
        <EmptyState type="data" />
      )}
    </main>
  );
}
