import React from 'react';
import doneMedal from '../../images/doneMedal.svg';
import brilhoDireita from '../../images/icons/rightBtnIcon.svg';
import brilhoEsquerda from '../../images/icons/leftBtnIcon.svg';
import { navigate } from '@reach/router';

const redirectByAchievements = title => {
  const routes = {
    '/guia-de-estudos': ['Estudioso', 'Maratonista do Conhecimento', 'Memória de Aço', 'Explorador Completo'],
    '/curso': ['Aluno Dedicado', 'Mestre do Simulado'],
    '/feed': ['Participante Ativo'],
    '/desafio-da-semana/desafios': ['Desafiante da Semana', 'Conector de Ideias', 'Guerreiro dos Desafios', 'Campeão Consistente'],
    '/redacao': ['Participante Ativo'],
    '/auditorio': ['Aluno Atento'],
    '/simulados': ['Mestre do Simulado']
  };

  for (const [route, titles] of Object.entries(routes)) {
    if (titles.includes(title)) {
      navigate(route);
      return;
    }
  }
};

function Card({ title, done = false, description, currentCount, totalCount, points, buttonClass }) {
  const getButtonStyle = type => {
    switch (type) {
      case 'achievements':
        return 'btn--review';
      case 'missions':
        return 'btn--orange';
      case 'merit':
        return 'btn--turbo';
    }
  };

  const percentage = (currentCount / totalCount) * 100;

  return (
    <div
      className="card card--milestone"
      style={{ position: 'relative' }}
    >
      <span className="card__title">{title}</span>
      <div className="metrics__card">
        {done && (
          <img
            className="metrics__card--done-medal"
            src={doneMedal}
            alt="concluído"
          />
        )}
        <p style={{ fontSize: 14, width: 250 }}>{description}</p>
        <div
          className="card__progress u-mb-0"
          style={{ width: '150px' }}
        >
          <div className="card__progress-container">
            <div
              className="card__progress-bar"
              role="progressbar"
              style={{ width: `${percentage}%`, backgroundColor: '#6FCF97' }}
              aria-valuenow={50}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
          <div className="card__progress-text">
            {currentCount}/{totalCount}
          </div>
        </div>
        <button
          className={`btn ${done ? 'btn--done' : getButtonStyle(buttonClass)} `}
          onClick={() => {
            if (done) return;
            redirectByAchievements(title);
          }}
        >
          {done === false && (
            <img
              src={brilhoEsquerda}
              alt="brilho a esquerda"
              className="metrics__card--btn-icon"
            />
          )}
          Ganhe {points} pontos
          {done === false && (
            <img
              src={brilhoDireita}
              alt="brilho a direita"
              className="metrics__card--btn-icon"
            />
          )}
        </button>
      </div>
    </div>
  );
}

export default Card;
