import React, { useState } from 'react';
import { FiChevronDown, FiMoreHorizontal, FiUsers } from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import { Link } from '@reach/router';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import Swal from 'sweetalert2';
import Modal from 'app/components/Modal';
import { useFormik } from 'formik';
import getPermission from 'app/utils/getPermission';
import { AvatarStack } from 'app/components/Avatar';
import { useTranslation } from 'react-i18next';

export default function ProfileCard(props) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const { name, profileId, onSuccess, userCount, users } = props;

  const client = useClient();

  const editProfile = async () => {
    const requestParams = {
      name: newProfileForm.values.title
    };
    const { error } = await client.mutate(`profiles/${profileId}`, requestParams);
    if (error) {
      toast.error('Falha ao atualizar perfil');
    } else {
      onSuccess();
      setShowModal(false);
      toast.success(t('toast.successEditProfile'));
    }
  };

  const deleteProfile = async () => {
    const { error } = await client.delete(`profiles/${profileId}`);
    if (error) {
      toast.error(t('toast.errorDeleteProfile'));
    } else {
      onSuccess();
      toast.success(t('toast.successDeleteProfile'));
    }
  };

  const handleDelete = profileId => {
    Swal.fire({
      title: t('projects.warningDeleteProfile'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteProfile(profileId);
      }
    });
  };

  const newProfileForm = useFormik({
    initialValues: {
      title: name
    },
    onSubmit: async () => {
      editProfile();
    }
  });

  return (
    <>
      <div className="card">
        <div className="card__header">
          <div className="card__inner-header">
            <span className="avatar">{name[0] + name[1]}</span>

            <h3
              className="card__title"
              style={{ whiteSpace: 'normal' }}
            >
              {name}
            </h3>
          </div>
        </div>

        <div className="card__body">
          <div className="card__columns">
            <div className="card__count">
              <span className="card__count-label">{t('classes.participants')}</span>
              <FiUsers className="admin-course-card__icon" /> {userCount}
            </div>
          </div>
        </div>

        <div className="card__footer">
          <div
            className="btn-group btn-group--with-dropdown"
            role="group"
            aria-label="Ações"
          >
            <Link
              className="btn btn--outline btn--small"
              to={`/participantes/perfis-administrativos/${profileId}`}
            >
              {t('button.access')}
            </Link>

            {getPermission('Editar perfis', 'Participantes e perfis administrativos') || getPermission('Excluir perfis', 'Participantes e perfis administrativos') ? (
              <Dropdown className="btn--small btn btn--outline">
                <Dropdown.Toggle>
                  <FiChevronDown />
                </Dropdown.Toggle>

                <Dropdown.Content>
                  {getPermission('Editar perfis', 'Participantes e perfis administrativos') && (
                    <Dropdown.Item
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      {t('button.edit')}
                    </Dropdown.Item>
                  )}
                  {getPermission('Excluir perfis', 'Participantes e perfis administrativos') && (
                    <Dropdown.Item
                      onClick={() => {
                        handleDelete();
                      }}
                    >
                      {t('button.delete')}
                    </Dropdown.Item>
                  )}
                </Dropdown.Content>
              </Dropdown>
            ) : null}
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <form
          method="post"
          className="form"
          onSubmit={e => {
            e.preventDefault();
            newProfileForm.submitForm();
          }}
        >
          <h2 className="modal__simple-title">Nova perfil</h2>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="profileTitle"
            >
              Nome do perfil
            </label>
            <input
              className="form__control"
              id="profileTitle"
              name="profileTitle"
              type="text"
              placeholder={t('projects.placeholderProfile')}
              value={newProfileForm.values.title}
              onChange={e => newProfileForm.setFieldValue('title', e.target.value)}
            />
          </div>

          <button
            className="btn btn--primary"
            type="submit"
          >
            {t('button.saveContinue')}
          </button>
        </form>
      </Modal>
    </>
  );
}
