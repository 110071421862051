import { useClient } from 'jsonapi-react';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import ParticipantItemCard from './ParticipantItemCard';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndScroll from 'app/components/endScroll';

export default function ParticipantsForm(props) {
  const { setShowModal, classroom, addParticipantToClassroom, deleteParticipantFromClassroom } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [otherUsers, setOtherUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const client = useClient();

  const getOtherUsers = async () => {
    setLoading(true);
    let endpoint = `users/?page[number]=${pageNumber}&page[size]=20`;

    if (searchTerm && searchTerm !== '') {
      endpoint += `&q[q_cont_any]=${searchTerm}`;
    }

    const { data, error } = await client.fetch(endpoint);
    if (error) {
      toast.error(t('toast.errorGetParticipants'));
      setHasMore(false);
    } else {
      if (data.length < 20) {
        setHasMore(false);
      }
      if (searchTerm) {
        setOtherUsers(prev => [...prev, ...data]);
      } else {
        setOtherUsers(prev => [...prev, ...data]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (hasMore) {
      getOtherUsers();
    }
  }, [pageNumber]);

  const debounceTimer = useRef(null);

  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      getOtherUsers();
    }, 900);

    if (searchTerm === '') {
      setHasMore(true);
    }
  }, [searchTerm]);

  useEffect(() => {
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, []);

  return (
    <form
      method="post"
      className="form"
      onSubmit={e => {
        e.preventDefault();
        setShowModal(false);
      }}
    >
      <h2 className="modal__simple-title">{t('courses.addParticpants')}</h2>

      <input
        style={{ position: 'sticky', top: '0' }}
        className="form__control u-mb-3"
        type="search"
        placeholder={t('courses.searchParticipants')}
        onChange={e => {
          setPageNumber(1);
          setSearchTerm(e.target.value);
          setOtherUsers([]);
        }}
      />
      <div
        id="modal"
        className="form__row u-overflow-y"
        style={{
          minHeight: '56px',
          maxHeight: '75vh',
          padding: '16px',
          margin: '0 -16px 1.5em -16px'
        }}
      >
        <InfiniteScroll
          scrollableTarget="modal"
          dataLength={otherUsers.length}
          next={() => {
            setPageNumber(pageNumber + 1);
          }}
          hasMore={hasMore}
          loader={<Loader />}
          endMessage={<EndScroll />}
          className="infinite-scroll__full"
        >
          {otherUsers.map(user => (
            <ParticipantItemCard
              user={user}
              key={user.id}
              addParticipantToClassroom={addParticipantToClassroom}
              deleteParticipantFromClassroom={deleteParticipantFromClassroom}
              classroom={classroom}
            />
          ))}
        </InfiniteScroll>
      </div>
    </form>
  );
}
