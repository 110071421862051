import React from 'react';
import { AiOutlineTrophy } from 'react-icons/ai';
import { Link } from '@reach/router';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import StarRating from 'app/components/StarRating';
import { t } from 'i18next';

export default function RecommendedCourseCard() {
  return (
    <div className="recommended-course-card">
      <div className="recommended-course-card__img-wrapper">
        <img
          className="recommended-course-card__img"
          src={defaultImage}
          alt=""
        />
      </div>

      <div className="recommended-course-card__container">
        <div className="recommended-course-card__header">
          <span className="badge badge--primary">Tecnologia</span>
        </div>

        <div className="recommended-course-card__body">
          <div className="recommended-course-card__infos">
            <h3 className="recommended-course-card__title">Desenvolvimento de Games em VR e AR para usuários com baixa visão</h3>
            <div className="u-mb-3">
              <StarRating
                staticRating
                rating={4}
              />
            </div>
            <div className="u-mb-3">
              <div className="course-card__tag">
                <AiOutlineTrophy /> Certificado Online
              </div>
            </div>
            <Link
              className="btn btn--small btn--wide btn--outline-white"
              to={`/curso/${1}`}
            >
              {t('button.view')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
