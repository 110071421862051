import React, { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../providers/SessionProvider';
import userAvatar from '../images/user-avatar.svg';
import trashIcon from '../images/icons/trash-icon.svg';
import moment from 'moment';
import 'moment/locale/pt';
import { Link } from '@reach/router';
import { useClient } from 'jsonapi-react';
import { useSession } from 'app/hooks/useSession';
import { AnswerCommentForm } from './AnswerCommentForm';
import toast from 'react-hot-toast';

function PostCommentCard(props) {
  const { comment, pinned, comments, setPinnedComment, hidePinnedBtn } = props;
  const client = useClient();
  const state = useContext(SessionContext);
  const { session } = useSession();
  const isAdmin = session.user.profileNames.includes('Administrador');
  const [isOpenAnswer, setIsOpenAnswer] = useState(false);
  const [answers, setAnswers] = useState([]);

  const findAnswers = () => {
    const commentsWithAnswers = comments?.filter(comment => comment?.['comment-parent-id'] !== null);
    const answersByCommentId = commentsWithAnswers?.filter(item => item?.['comment-parent-id'] === comment?.id).reverse();

    setAnswers(answersByCommentId);
  };

  const handleCreateAnswer = async value => {
    try {
      const response = await client.mutate('/comments', {
        'comment-parent-id': comment?.id,
        body: value,
        'post-id': comment['post-id']
      });

      setAnswers(prev => [response.data, ...prev]);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao responder');
    }
  };

  const handleDeleteAnswer = async answerId => {
    const { error } = await client.delete(`/comments/${answerId}`);

    if (!error) {
      setAnswers(prev => prev.filter(item => item.id !== answerId));
    } else {
      console.log(error);
      toast.error('Erro ao deletar resposta');
    }
  };

  useEffect(() => {
    findAnswers();
  }, [comments]);

  return (
    <div className={pinned ? 'post-comment post-comment--pinned' : 'post-comment'}>
      {pinned ? (
        <span className="expanded-calendar__tag expanded-calendar__tag--bright">Comentário fixado</span>
      ) : (
        isAdmin &&
        !hidePinnedBtn && (
          <button
            className="btn btn--tiny btn--outline"
            onClick={() => setPinnedComment(comment?.id)}
          >
            Fixar comentário
          </button>
        )
      )}

      <Link
        to={`/usuario/metricas/${comment?.user?.id}`}
        state={{ isRedirected: true }}
      >
        <div
          className="post-comment__avatar"
          style={{
            backgroundImage: `url(${comment?.user?.image?.url || userAvatar})`
          }}
        ></div>
      </Link>
      <div className="post-comment__body w-full">
        <div className="post-comment__header">
          <Link
            to={`/usuario/metricas/${comment?.user?.id}`}
            state={{ isRedirected: true }}
          >
            <div className="post-comment__header-name">{comment?.user?.name}</div>
          </Link>
          <div className="post-comment__header-time">{moment(comment['created_at'] || comment['created-at']).fromNow()}</div>
        </div>
        <div className="post-comment__description">
          <div className="post-comment__description-text">{comment.body}</div>
        </div>

        <AnswerCommentForm
          answers={answers}
          onSubmit={handleCreateAnswer}
          isOpenAnswer={isOpenAnswer}
          onDelete={handleDeleteAnswer}
        />
      </div>

      <div>
        <button
          className="btn--answers"
          style={{ opacity: isOpenAnswer ? 0.5 : 1, paddingTop: 30 }}
          onClick={() => setIsOpenAnswer(prev => !prev)}
        >
          Responder
        </button>

        <div
          className="post-comment__actions"
          style={{ alignSelf: isAdmin ? 'flex-end' : 'flex-start' }}
        >
          {state.session && state.session.user?.id === comment?.user?.id && (
            <div
              className="post-comment__action"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <div
                className="post-comment__action-icon"
                onClick={() => props.deleteComment(comment)}
                style={{ margin: 0 }}
              >
                <img
                  alt=""
                  src={trashIcon}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PostCommentCard;
