import React from 'react';
import { SimpleModal } from 'app/components/Modal';
import Avatar from 'app/components/Avatar';
import tiaAvatar from './img/tia-avatar.png';

export default function BlockModal(props) {
  const { show, onClose, onSubmit } = props;

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      contentClassName="simple-modal__content--br-md"
    >
      <div className="schedule-alert schedule-alert--tia">
        <Avatar
          className="avatar--md"
          src={tiaAvatar}
          alt="T.I.A"
        />

        <div className="schedule-alert__content">
          <div className="schedule-alert__header">
            <h3 className="schedule-alert__title">Quer bloquear esta matéria?</h3>
          </div>

          <div className="schedule-alert__body">
            <p className="u-mb-0">Se fizer isso, ela será removida do seu guia de estudos e só voltará quando você desbloquear.</p>
          </div>

          <div className="schedule-alert__footer">
            <button
              className="btn btn--primary"
              onClick={onSubmit}
            >
              Sim
            </button>
            <button
              className="btn btn--outline-danger"
              onClick={onClose}
            >
              Não
            </button>
          </div>
        </div>
      </div>
    </SimpleModal>
  );
}
