import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import Loader from 'app/components/loader';

export default function RadarChart(props) {
  const { radarScore } = props;
  HC_more(Highcharts);

  if (radarScore?.['chart-data']?.data.length > 0) {
    const seriesData = [
      {
        color: '#7B63BF',
        data: radarScore?.['chart-data']?.data.map(entry => parseFloat(entry.average_result.toFixed(2))),
        pointPlacement: 'on'
      }
    ];

    const xAxisCategories = radarScore['chart-data'].data.map(entry => entry.name);

    const options = {
      chart: {
        polar: true,
        type: 'line',
        height: 500
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      xAxis: {
        categories: xAxisCategories,
        tickmarkPlacement: 'on',
        lineWidth: 0
        // labels: {
        //   style: {
        //     whiteSpace: 'nowrap',
        //     width: 260,
        //     textOverflow: 'ellipsis'
        //   }
        // }
      },
      yAxis: {
        gridLineInterpolation: 'polygon'
      },
      series: seriesData,
      legend: {
        enabled: false,
        align: 'left'
      }
    };

    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    );
  }

  return <Loader />;
}
