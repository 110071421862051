import React, { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { FilterCheckBox } from 'app/components/FilterCheckBox';
import ChallengeDataGrid from './ChallengeDataGrid';
import { useSession } from 'app/hooks/useSession';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import toast from 'react-hot-toast';

export default function ChallengeResult() {
  const [challenges, setChallenges] = useState([]);
  const [themes, setThemes] = useState([]);
  const [orderByPeriod, setOrderByPeriod] = useState('descendent');
  const [orderByParticipants, setOrderByParticipants] = useState('descendent');
  const [orderByAccuracy, setOrderByAccuracy] = useState('descendent');
  const [param, setParam] = useState('period');
  const [loading, setLoading] = useState(false);

  const { session } = useSession();

  const filters = useFormik({
    initialValues: {
      searchTerm: '',
      theme: '',
      'my-challenges': false
    }
  });

  const client = useClient();

  function createDate(dateString) {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  const fetchChallenges = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('/challenges/results');
    if (error) {
      toast.error('Erro ao buscar desafios');
    } else {
      setChallenges(
        data.map(item => {
          return { ...item, 'initial-date': createDate(item['initial-date']), 'end-date': createDate(item['end-date']) };
        })
      );
      setThemes(data.map(item => ({ id: item.taxonomy.id, name: item.taxonomy.name })).filter((value, index, self) => index === self.findIndex(t => t.id === value.id && t.name === value.name)));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchChallenges();
  }, []);

  const filteredChallenges = useMemo(() => {
    const filterByText = challenges.filter(item => filters.values.searchTerm === '' || item.title.toLowerCase().includes(filters.values.searchTerm.toLowerCase()));

    const filterByTheme = filterByText.filter(item => filters.values.theme === '' || item.taxonomy.id === parseInt(filters.values.theme));

    const filterByAuthorship = filterByTheme.filter(item => {
      if (filters.values['my-challenges']) {
        return item.user.id === session.user.id;
      } else return true;
    });

    return filterByAuthorship;
  }, [challenges, filters.values]);

  const orderedChallenges = useMemo(() => {
    let newChallenges;
    if (param === 'participants' && orderByParticipants === 'ascendent') {
      newChallenges = filteredChallenges.sort((a, b) => a?.['participation-count'] - b?.['participation-count']);
    }
    if (param === 'participants' && orderByParticipants === 'descendent') {
      newChallenges = filteredChallenges.sort((a, b) => b?.['participation-count'] - a?.['participation-count']);
    }
    if (param === 'period' && orderByPeriod === 'ascendent') {
      newChallenges = filteredChallenges.sort((a, b) => new Date(a['end-date']) - new Date(b['end-date']));
    }
    if (param === 'period' && orderByPeriod === 'descendent') {
      newChallenges = filteredChallenges.sort((a, b) => new Date(b['end-date']) - new Date(a['end-date']));
    }
    if (param === 'accuracy' && orderByAccuracy === 'ascendent') {
      newChallenges = filteredChallenges.sort((a, b) => a?.['accuracy-rate'] - b['accuracy-rate']);
    }
    if (param === 'accuracy' && orderByAccuracy === 'descendent') {
      newChallenges = filteredChallenges.sort((a, b) => b?.['accuracy-rate'] - a['accuracy-rate']);
    }
    return newChallenges;
  }, [orderByPeriod, orderByParticipants, filteredChallenges, orderByAccuracy, param]);

  return (
    <>
      <div className="filter-bar filter-bar--borderless">
        <div className="filter-bar__inner">
          <div className="filter-bar__row">
            <label
              className="filter-bar__label"
              htmlFor="search"
            >
              {t('textsCommon.research')}
            </label>
            <input
              className="form__control form__control--search-with-icon"
              placeholder={'Nome do desafio'}
              type="search"
              name="search"
              id="search"
              value={filters.values.searchTerm}
              onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
              style={{ width: 400 }}
            />
          </div>

          <FilterSelectionBox
            optionBlank={''}
            label={'Tema'}
            blankLabel={'Selecione um tema'}
            value={filters.values.theme}
            onChange={e => filters.setFieldValue('theme', e.target.value)}
            options={themes}
          />

          <FilterCheckBox
            label="Meus desafios"
            value={filters.values['my-challenges']}
            onChange={() => filters.setFieldValue('my-challenges', !filters.values['my-challenges'])}
          />
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : challenges.length > 0 ? (
        <ChallengeDataGrid
          setParam={setParam}
          orderByAccuracy={orderByAccuracy}
          setOrderByAccuracy={setOrderByAccuracy}
          orderByParticipants={orderByParticipants}
          orderByPeriod={orderByPeriod}
          setOrderByParticipants={setOrderByParticipants}
          setOrderByPeriod={setOrderByPeriod}
          challenges={orderedChallenges}
          setChallenges={setChallenges}
        />
      ) : (
        <EmptyState type="data" />
      )}
    </>
  );
}
