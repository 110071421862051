import { Link } from '@reach/router';
import React from 'react';
import Avatar from '../Avatar';

const UserInfo = props => {
  const { user, viewEmail } = props;

  const avatarImage = user?.image?.url;
  const avatarImageMemo = React.useMemo(() => avatarImage, [avatarImage]);

  return (
    <div className="profile-resume">
      <Link
        to={`/usuario/metricas/${user?.id}`}
        state={{ isRedirected: true }}
      >
        <Avatar
          src={avatarImageMemo}
          alt={user?.name}
          className="profile-resume__avatar"
        />
      </Link>
      <div className="profile-resume__body">
        <Link
          to={`/usuario/metricas/${user?.id}`}
          state={{ isRedirected: true }}
        >
          <div
            className="profile-resume__name"
            title={user?.name}
          >
            {user?.name}
          </div>
        </Link>

        {user?.profiles?.[0] && !viewEmail && (
          <span
            className="profile-resume__tag"
            title={user?.profiles?.[0]?.name ?? user?.profiles?.[0]}
          >
            {user?.profiles[0]?.name ?? user?.profiles?.[0]}
          </span>
        )}

        {user?.['profile-names']?.[0] && (
          <span
            className="profile-resume__tag"
            title={user?.['profile-names']?.[0]}
          >
            {user?.['profile-names']?.[0]}
          </span>
        )}

        {viewEmail && (
          <div
            className="profile-resume__email"
            title={user?.email}
          >
            {user?.email}
          </div>
        )}

        {/* {user?.profiles?.[0] && (
          <span className="profile-resume__tag" title={user?.profiles?.[0]}>
            {user?.profiles[0]}
          </span>
        )} */}
      </div>
    </div>
  );
};
export default UserInfo;
