import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';

export default function TaskSolutionColumnChart(props) {
  const { t } = useTranslation();
  const { chartData } = props;

  const pieColors = (function () {
    let colors = [],
      base = '#442b8c',
      i;

    for (i = 0; i < 10; i += 1) {
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      colors.push(
        Highcharts.color(base)
          .brighten(i / 7)
          .get(),
      );
    }
    return colors;
  })();

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'column',
    },
    colors: pieColors,
    credits: { enabled: false },
    title: { text: null },
    subtitle: {
      text: null,
    },
    legend: {
      symbolWidth: 12,
      symbolHeight: 12,
      symbolRadius: 3,
    },
    xAxis: {
      categories: chartData.map(data => data.label),
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: t("journey.notCompleted"),
        data: chartData.map(data => data.createdNotCompleted),
      },
      {
        name: t("journey.completed"),
        data: chartData.map(data => data.createdCompleted),
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
