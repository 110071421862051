import React from 'react';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import { navigate, useLocation } from '@reach/router';
import { t } from 'i18next';
import { AiOutlineTrophy } from 'react-icons/ai';
import moreMedal from 'app/images/more-medal.svg';
import { FiClipboard, FiEdit3, FiTarget, FiVideo } from 'react-icons/fi';
import { useQuery } from 'jsonapi-react';
import IconVerified from 'app/components/Icons/IconVerified';

export default function CourseCard(props) {
  const { course, journey, setShowModal, setSelectedCourse } = props;
  const location = useLocation();
  const { data } = useQuery(`notifications?filter[courses.id]=${course.id}`);
  //TODO: Pedir ao backend para criar um filtro que retorne todas as notificacoes de cursos em uma única chamada para evitar chamada
  //em cada render deste componente.

  const handleClick = () => {
    setSelectedCourse(course);
    setShowModal(true);
  };

  const downloadCertificate = () => {
    window.open(`${process.env.REACT_APP_DEV_URL}//user_certificates/download/${course?.certificate?.hash_id}.pdf`, '_blank');
  };

  const amountClipboardNotifications = data?.filter(item => item.icon === 'FiClipboard' && item.unread).length;
  const amountEditNotifications = data?.filter(item => item.icon === 'FiEdit3' && item.unread).length;
  const amountTargetNotifications = data?.filter(item => item.icon === 'FiTarget' && item.unread).length;
  const amountVideoNotifications = data?.filter(item => item.icon === 'FiVideo' && item.unread).length;

  const courseProgress = course?.progress?.toFixed(0);

  return (
    <div
      className="course-card"
      onClick={() => {
        navigate(window.location.pathname === '/curso' || window.location.pathname === '/' ? `/curso/acompanhamento/${course?.id}` : handleClick());
      }}
    >
      <div className="course-card__body course-card__body--home">
        <div className="course-card__img-wrapper">
          {/* <span className="badge badge--tiny badge--primary">{course?.knowledge ? course?.knowledge : ''}</span> */}
          <img
            className="course-card__img course-card__img--md"
            src={course?.image?.url || defaultImage}
            alt=""
          />
        </div>

        <div className="course-card__content">
          <div className="course-card__header">
            <div className="course-card__inner-header">
              <div className="admin-course-card--flex-gap-5">
                {course?.['official-content'] && <IconVerified />}
                <h3 className="course-card__title">{course?.title}</h3>
              </div>
              <p className="course-card__subtitle">
                <strong>{course?.['finished-learning-system-items']}</strong> / {course?.['learning-system-item-count']} {t('dashboard.completedLessons')}
              </p>
            </div>

            <div className="course-card__notifications">
              {amountClipboardNotifications > 0 && (
                <div className="notification__icon notification__icon--sm notification__icon--warning">
                  <FiClipboard />
                  <span className="notifications__badge">{amountClipboardNotifications > 9 ? '+9' : amountClipboardNotifications}</span>
                </div>
              )}

              {amountEditNotifications > 0 && (
                <div className="notification__icon notification__icon--sm notification__icon--warning">
                  <FiEdit3 />
                  <span className="notifications__badge">{amountEditNotifications > 9 ? '+9' : amountEditNotifications}</span>
                </div>
              )}

              {amountTargetNotifications > 0 && (
                <div className="notification__icon notification__icon--sm notification__icon--warning">
                  <FiTarget />
                  <span className="notifications__badge">{amountTargetNotifications > 9 ? '+9' : amountTargetNotifications}</span>
                </div>
              )}

              {amountVideoNotifications > 0 && (
                <div className="notification__icon notification__icon--sm notification__icon--warning">
                  <FiVideo />
                  <span className="notifications__badge">{amountVideoNotifications > 9 ? '+9' : amountVideoNotifications}</span>
                </div>
              )}
            </div>

            {course?.progress === 100 && (
              <div className="medal medal--sm">
                <img
                  src={moreMedal}
                  alt="nova medalha"
                  className="medal__img"
                />
                <span className="medal__value">
                  <AiOutlineTrophy />
                </span>
              </div>
            )}
          </div>

          <div>
            {course?.progress === 100 && !journey ? (
              <>
                <span className="badge badge--tiny badge--success">Concluído</span>
                {course?.certificate?.hash_id && (
                  <div>
                    <a
                      onClick={e => {
                        e.stopPropagation();
                        downloadCertificate();
                      }}
                      type="button"
                      className="btn btn--link u-p-0"
                    >
                      {t('button.certification')}
                    </a>
                  </div>
                )}
              </>
            ) : (
              <div className="card__progress u-mb-0">
                <div className="card__progress-container">
                  <div
                    className="card__progress-bar"
                    role="progressbar"
                    style={{ width: `${courseProgress}%` }}
                    aria-valuenow={courseProgress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
                <div className="card__progress-text">{courseProgress}%</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
