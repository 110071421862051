import React from 'react';
import AccordionItem from './AccordionItem';
import ModuleCard from './ModuleCard';

export default function ModuleCardAdmin(props) {
  const { id } = props;

  return (
    <div className="module-card-admin">
      <ModuleCard id={id} />

      <div className="module-card-admin__disciplines">
        <div className="accordion">
          {[...Array(10)].map((d, i) => (
            <AccordionItem key={i} />
          ))}
        </div>
      </div>
    </div>
  );
}
