import React, { useState } from 'react';
import { FiChevronLeft, FiHelpCircle } from 'react-icons/fi';
import { useFormik } from 'formik';
import ReportForm from 'app/components/ReportForm';
import { navigate } from '@reach/router';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import GreTable from './GreTable';
import InstitutionTable from './InstitutionTable';
import StudentTable from './StudentTable';

export default function EnemReport() {
  const filters = useFormik({
    initialValues: {
      regional: 'blank',
      institution: 'blank',
      searchTerm: '',
      'start-date': '',
      'end-date': ''
    }
  });

  const regionals = [
    {
      id: 1,
      title: '21º GRE - Parnaíba'
    }
  ];

  const institutions = [
    {
      id: 1,
      title: 'U. E. LÍVIO RIBEIRO DOS SANTOS'
    }
  ];

  const kpis = [
    {
      id: 1,
      help: 'Número total de estudantes cadastrados de acordo com o filtro selecionado.',
      variation: 'report-kpi-card--check',
      title: 'Total de estudantes cadastrados',
      value: '10.548'
      // percentage: null
    },
    {
      id: 2,
      help: 'Inscritos no Enem 2024 de acordo com o filtro selecionado.',
      variation: 'report-kpi-card--users',
      title: 'Inscritos no Enem 2024',
      value: '4.245',
      percentage: 48
    },
    {
      id: 3,
      help: 'Solicitaram taxa de isenção de acordo com o filtro selecionado.',
      variation: 'report-kpi-card--currency',
      title: 'Solicitaram taxa de isenção',
      value: '3.189',
      percentage: 88
    }
  ];

  return (
    <main className="report">
      <header className="report__header">
        <h1 className="report__title">
          <button
            className="card__icon-wrapper"
            aria-label="Voltar"
            title="Voltar"
            onClick={() => navigate(-1)}
          >
            <FiChevronLeft className="card__icon" />
          </button>
          Enem 2024
        </h1>
        <p className="report__description">Acompanhe as inscrições para o exame</p>
      </header>

      <div className="report-tab">
        <button className="report-tab__link report-tab__link--is-active">Inscrições</button>
      </div>

      <div className="report__body">
        <div className="report__filter">
          <ReportForm.Select
            blankLabel="Regional"
            label="Regional"
            value={filters.values.regional}
            onChange={e => filters.setFieldValue('regional', e.target.value)}
            options={regionals.map(c => ({ id: c.id, name: c.title }))}
          />
          <ReportForm.Select
            blankLabel="Instituição"
            label="Instituição"
            value={filters.values.institution}
            onChange={e => filters.setFieldValue('institution', e.target.value)}
            options={institutions.map(c => ({ id: c.id, name: c.title }))}
            disabled={filters.values.regional === 'blank'}
          />
          <div className="report-form__datepicker">
            <input
              className="report-form__control"
              id="start-date"
              name="start-date"
              type="date"
              value={moment(filters.values['start-date']).format('YYYY-MM-DD')}
              onChange={e => filters.setFieldValue('start-date', e.target.value)}
              title="Data de início"
              aria-label="Data de início"
            />
            <input
              className="report-form__control"
              id="start-date"
              name="start-date"
              type="date"
              value={moment(filters.values['end-date']).format('YYYY-MM-DD')}
              onChange={e => filters.setFieldValue('end-date', e.target.value)}
              min={moment(filters.values['start-date']).format('YYYY-MM-DD')}
              title="Data de fim"
              aria-label="Data de fim"
            />
          </div>
        </div>

        <div className="report-widget">
          <div className="report-widget__header">
            <h3 className="report-widget__title">Visão geral</h3>
          </div>

          <div className="report-widget__body">
            <div className="report-kpi-card-wrapper">
              {kpis.map(kpi => (
                <div
                  className={`report-kpi-card ${kpi.variation}`}
                  key={kpi.id}
                >
                  {kpi.help && (
                    <>
                      <FiHelpCircle
                        className="report-kpi-card__help-icon"
                        data-tooltip-id={kpi.id}
                        data-tooltip-content={kpi.help}
                      />
                      <ReactTooltip
                        id={kpi.id}
                        className="tooltip"
                      />
                    </>
                  )}
                  <h3 className="report-kpi-card__title">{kpi.title}</h3>
                  <p className="report-kpi-card__value">
                    {kpi.value} {kpi.percentage ? <span>({kpi.percentage}%)</span> : null}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="report-widget">
          <div className="report-widget__header report-widget__header--column">
            {filters.values.regional !== 'blank' && filters.values.institution !== 'blank' ? <h3 className="report-widget__title">Estudantes</h3> : <h3 className="report-widget__title">Ranking</h3>}

            <div className="report-widget__filter">
              <input
                className="report-form__control report-form__control--search-with-icon"
                aria-label="Pesquisar"
                placeholder="Pesquisar"
                type="search"
                name="search"
                id="search"
                onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
              />
            </div>
          </div>

          <div className="report-widget__body">
            {filters.values.regional === 'blank' && <GreTable />}
            {filters.values.regional !== 'blank' && filters.values.institution === 'blank' && <InstitutionTable />}
            {filters.values.regional !== 'blank' && filters.values.institution !== 'blank' && <StudentTable />}
          </div>
        </div>
      </div>
    </main>
  );
}
