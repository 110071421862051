import { SimpleModal } from 'app/components/Modal';
import React from 'react';
import styles from './DetailModal.module.scss';
import SkillTree from './SkillTree';
import PositionsCompatibility from './PositionsCompatibility';
import ProfileBanner from 'app/components/ProfileBanner';
import AnswerKey from './AnswerKey';

export default function DetailModal(props) {
  const { show, onClose } = props;

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      contentClassName="simple-modal__content--full simple-modal__content--br-md"
      hideCloseButton
    >
      <div className={styles.wrapper}>
        <ProfileBanner />
        <PositionsCompatibility />
        <SkillTree />
        <AnswerKey />
      </div>
    </SimpleModal>
  );
}
