import React from 'react';
import { Link } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';
import MainSidebar from 'app/components/Sidebar';
import spinWheel from './img/spin-wheel.gif';

export default function WeekChallengeSidebar() {
  return (
    <MainSidebar hasBrand={false}>
      <Link
        to="/desafio-da-semana/desafios"
        className="filter-bar__back"
      >
        <div className="card__icon-wrapper">
          <FiChevronLeft />
        </div>
        <span className="filter-bar__back-container">
          <span className="filter-bar__title">Desafio da semana</span>
        </span>
      </Link>

      <div className="step-by-step-nav">
        <div className="side-box">
          <div className="side-box__header">
            <img
              className="side-box__img"
              src={spinWheel}
              alt="roda de rotação"
            />
          </div>

          <div className="side-box__body">
            <p>
              No <strong>Desafio da Semana</strong>, os estudantes podem girar a roleta, responder perguntas e acumular pontos sobre um tema escolhido por você.
            </p>
            <p>
              <strong>Cada desafio é norteado por um tema central que é dividido em 8 matérias. Cada matéria deve ter uma ou mais  perguntas com 4 alternativas e um comentário.</strong>
            </p>
            <p>Você pode criar quantos desafios quiser. Sua criatividade e conhecimento ajudará a proporcionar uma experiência de aprendizado única e transformadora a todos.</p>
            <p>
              <strong>Vamos lá!</strong>
            </p>
          </div>
        </div>
      </div>
    </MainSidebar>
  );
}
