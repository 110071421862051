import React, { useState } from 'react';
import UserInfo from 'app/components/UserInfo';
import CustomParticipantsModal from './CustomParticipantsModal';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { t } from 'i18next';

export default function StepCustomParticipants(props) {
  const { form, selectedCourse } = props;
  const [showModal, setShowModal] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [loading, setLoading] = useState(true);

  function filterUsersByClassroom(classroomId, classrooms, users) {
    const classroom = classrooms.find(c => c.id === classroomId);
    if (!classroom) {
      return [];
    }
    const userIds = classroom['user-ids'];
    return users.filter(u => userIds.includes(u.id));
  }

  const addParticipant = participant => {
    const newParticipants = [...form.values.participants, participant.id];
    form.setFieldValue('selectedParticipants', [...form.values.selectedParticipants, participant]);
    form.setFieldValue('participants', newParticipants);
  };

  const removeParticipant = participant => {
    const newParticipants = form.values.participants.filter(u => u !== participant.id);
    form.setFieldValue(
      'selectedParticipants',
      form.values.selectedParticipants.filter(u => u.id !== participant.id)
    );
    form.setFieldValue('participants', newParticipants);
  };

  useEffect(() => {
    setLoading(true);
    if (form.values.course !== '' && selectedCourse) {
      setParticipants(selectedCourse.users);

      if (form.values.classroom !== '') {
        setParticipants(filterUsersByClassroom(+form.values.classroom, selectedCourse.classrooms, selectedCourse.users));
      }
    }
    setLoading(false);
  }, [form.values.course, form.values.classroom]);

  useEffect(() => {
    setSelectedParticipants(form.values.selectedParticipants);
  }, [form.values.selectedParticipants]);

  return (
    <div className="page__content fadeIn u-mb-5">
      <div className="new-questions__header">
        <h2 className="new-questions__title">{form.values.title || t('projects.newProjects')}</h2>
      </div>

      <p>*Adicione os participantes</p>

      <button
        type="button"
        className="btn btn--wide btn--primary u-mb-3"
        onClick={() => setShowModal(true)}
      >
        Adicionar
      </button>
      <p>{form.touched.participants && form.errors.participants && <span style={{ color: 'red' }}>{form.errors.participants}</span>}</p>

      <span className="form__text">
        <strong>* {t('warning.requiredField')}</strong>
      </span>

      <div>
        <hr className="u-hr" />
        <table className="round-dg round-dg--light">
          <tbody className="round-dg__body">
            {!loading &&
              selectedParticipants.map((participant, i) => (
                <tr
                  className="round-dg__row"
                  key={i}
                >
                  <th className="round-dg__cell-header round-dg__cell-header--row">
                    <UserInfo user={participant} />
                  </th>
                  <td className="round-dg__cell">{participant.email}</td>
                  <td
                    className="round-dg__cell"
                    style={{ width: '140px' }}
                  >
                    <button
                      className="btn btn--outline btn--full"
                      onClick={() => removeParticipant(participant)}
                    >
                      {t('button.remove')}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <CustomParticipantsModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        form={form}
        loading={loading}
        selectedParticipants={selectedParticipants}
        participants={participants}
        addParticipant={addParticipant}
        removeParticipant={removeParticipant}
      />
    </div>
  );
}
