import { useContext } from 'react';
import { WeeklyChallengeContext } from 'app/providers/WeeklyChallenge';

export const useWeeklyChallenge = () => {
  const context = useContext(WeeklyChallengeContext);

  if (!context) {
    throw new Error('useWeeklyChallenge must be used within a WeeklyChallengeProvider.');
  }

  return context;
};
