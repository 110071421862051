import { useTranslation } from 'react-i18next';
import React from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';

export default function StepName(props) {
  const { t } = useTranslation();
  const { form } = props;

  return (
    <div className="fadeIn">
      <div className="form__row">
        <label
          className="form__label"
          htmlFor="title"
        >
          * {t('projects.projectName')}
        </label>

        <input
          className="form__control"
          placeholder={t('projects.placeholderProjectName')}
          id="title"
          name="title"
          type="text"
          value={form.values.title}
          onChange={e => form.setFieldValue('title', e.target.value)}
        />
        {form.touched.title && form.errors.title && <span style={{ color: 'red' }}>{form.errors.title}</span>}
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="description"
        >
          * {t("button.aboutProject")}
        </label>

        <TextareaAutosize
          placeholder={t('projects.placeholderAbout')}
          id="description"
          name="description"
          value={form.values.description}
          onChange={e => form.setFieldValue('description', e.target.value)}
        />
        {form.touched.description && form.errors.description && <span style={{ color: 'red' }}>{form.errors.description}</span>}
      </div>
    </div>
  );
}
