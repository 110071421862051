import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useClient } from 'jsonapi-react';
import { useFormik } from 'formik';
import { FiChevronLeft } from 'react-icons/fi';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import InfoWidget from 'app/components/Widget/InfoWidget';
import ActivityDataPieChart from './ActivityDataPieChart';
import ActivityDataColumnChart from './ActivityDataColumnChart';
import ActivityStudentCard from './ActivityStudentCard';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';

export default function ClassData(props) {
  const { t } = useTranslation();
  const { activity } = props;
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [currClassroom, setCurrClassroom] = useState();
  const [classrooms, setClassrooms] = useState([]);
  const [activityStudents, setActivityStudents] = useState();
  const [activityReport, setActivityReport] = useState();
  const [status, setStatus] = useState('');
  const [statusClass, setStatusClass] = useState('');

  useEffect(() => getClassrooms(), []);

  useEffect(() => {
    updateActivityStatus();
    if (activity && currClassroom) {
      getActivityReport();
      getActivityStudents();
    }
  }, [activity, currClassroom]);

  const getClassrooms = async () => {
    const { data } = await client.fetch('classrooms');
    setClassrooms(data);
    setCurrClassroom(data[0]);
  };

  const getActivityReport = async () => {
    setIsLoading(true);
    const { data } = await client.fetch(['activities', activity.id, `activities_report?filter[classroom_id]=${currClassroom?.id}&classroom=true`]);
    setActivityReport(data);
    setIsLoading(false);
  };

  const getActivityStudents = async () => {
    const { data } = await client.fetch(['activities', activity.id, `activity_students?filter[classrooms.id]=${currClassroom?.id}&classroom=true`]);
    setActivityStudents(data);
  };

  const updateActivityStatus = () => {
    let newStatus = t('textsCommon.available');
    let newStatusClass = 'activity-card__status--success';

    if (moment(activity['publish-at']) > moment()) {
      newStatus = t('textsCommon.unavailable');
      newStatusClass = 'activity-card__status--warning';
    }

    // else if (moment(activity['finish-at']) < moment()) {
    //   newStatus = 'Expirado';
    //   newStatusClass = 'activity-card__status--danger';
    // }

    setStatus(newStatus);
    setStatusClass(newStatusClass);
  };

  const changeClassroom = e => {
    if (e.target.value !== 'blank') {
      const newClassroom = classrooms.find(c => c.id === parseInt(e.target.value));
      setCurrClassroom(newClassroom);
    }
  };

  return (
    <div className="fadeIn">
      <div
        className="filter-bar"
        style={{ borderBottom: '0', marginBottom: '0' }}
      >
        <FilterSelectionBox
          label={t('filter.labelSelectClassroom')}
          value={currClassroom?.id}
          onChange={changeClassroom}
          options={classrooms.map(o => ({ id: o.id, name: o.title }))}
        />
      </div>
      <div
        className="filter-bar"
        style={{ alignItems: 'center' }}
      >
        <button
          onClick={() => navigate(-1)}
          className="filter-bar__back"
        >
          <span className="card__icon-wrapper">
            <FiChevronLeft className="card__icon" />
          </span>

          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{t('textsCommon.data')}</span>
          </span>
        </button>

        <span className="filter-bar__box u-ml-auto">
          {t('textsCommon.lastUpdateAt')} {activityReport?.lastUpdate && moment(activityReport.lastUpdate).format('DD/MM/YYYY H:mm')}h
        </span>
      </div>

      <div className="chart-widget u-mb-3">
        <div className="chart-widget__container">
          <div className="chart-widget__group">
            <div className="chart-widget__meta">
              <span className={`activity-card__status u-mr-2 ${statusClass}`}>{status}</span>
              {/* <span className="activity-card__available">
                {t('textsCommon.until')} {moment(activity['finish-at']).format(t('datesForm.formatDate'))}
              </span> */}
            </div>

            <div>
              <h4 className="chart-widget__title chart-widget__title--md">{currClassroom?.title}</h4>
              <h3 className="chart-widget__title chart-widget__title--lg">{activity.title}</h3>
            </div>
          </div>
          <div className="widget-wrapper widget-wrapper--sm-space">
            <InfoWidget
              title={t('textsCommon.submissionIndex')}
              className="info-widget--light"
            >
              <InfoWidget.Value>{(activityReport?.percentActivitySend && Math.round(activityReport?.percentActivitySend)) || 0}%</InfoWidget.Value>
            </InfoWidget>

            <InfoWidget
              title={t('textsCommon.totalStudents')}
              className="info-widget--light"
            >
              <InfoWidget.Value>{activityReport?.studentsPerCourse || 0}</InfoWidget.Value>
            </InfoWidget>
          </div>
        </div>
      </div>

      <div className="chart-widget-wrapper">
        <div className="chart-widget">
          <div className="chart-widget__header">
            <h3 className="chart-widget__title">{t('activity.totalSubmissions')}</h3>
          </div>
          <div className="chart-widget__body">{isLoading ? <Loader /> : <ActivityDataPieChart activityReport={activityReport} />}</div>
        </div>

        <div className="chart-widget">
          <div className="chart-widget__header">
            <h3 className="chart-widget__title">{t('activity.studentListing')}</h3>
          </div>

          <div className="chart-widget__body">
            <div className="student-card-wrapper">
              {!activityStudents ? (
                <Loader />
              ) : activityStudents.length > 0 ? (
                <div className="round-dg-wrapper">
                  <table className="round-dg round-dg--light">
                    <tbody className="round-dg__body">
                      {activityStudents.map(activityStudent => (
                        <ActivityStudentCard activityStudent={activityStudent} />
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="new-questions__empty-state-text">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  {t('activity.emptyInfo')}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
