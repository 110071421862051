import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'app/components/Modal';
import UserInfo from 'app/components/UserInfo';
import Avatar from 'app/components/Avatar';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function GroupModal(props) {
  const { t } = useTranslation();
  const { data, className, modalTitle, truncateIn, i } = props;
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  function avatarStackModifier(className) {
    switch (className) {
      case 'avatar--xs':
        return 'avatar-stack--sm';
      case 'avatar--md':
        return 'avatar-stack--md';

      default:
        break;
    }
  }

  function avatarImageMemorized(avatar) {
    const avatarImage = avatar?.image?.url;
    // const avatarImageMemo = React.useMemo(() => ({ avatarImage }), [avatarImage]);
    // return avatarImageMemo;
    return avatarImage;
  }

  useEffect(() => {
    let newUsers = data;
    if (searchTerm !== '') {
      newUsers = newUsers.filter(a => a.name != null && a.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
    }
    setFilteredUsers(newUsers);
  }, [searchTerm, data]);

  return (
    data && (
      <>
        <ul
          className={`avatar-stack ${avatarStackModifier(className)}`}
          onClick={() => setShowModal(true)}
        >
          {data.length <= (truncateIn || 3) &&
            data.map(avatar => (
              <li
                className="avatar-stack__item"
                key={avatar?.id}
              >
                <Avatar
                  src={avatarImageMemorized(avatar)}
                  alt={avatar?.name}
                  className={className}
                />
              </li>
            ))}

          {data.length > (truncateIn || 3) && (
            <>
              {data.slice(0, truncateIn || 3).map(avatar => (
                <li
                  className="avatar-stack__item"
                  key={avatar?.id}
                >
                  <Avatar
                    src={avatar?.image?.url}
                    alt={avatar?.name}
                    className={className}
                  />
                </li>
              ))}
              <li className="avatar-stack__item">
                <span
                  className={`avatar ${className}`}
                  title={data.slice(truncateIn || 3).map(avatar => ` ${avatar?.name}`)}
                >
                  +{data.length - (truncateIn || 3)}
                </span>
              </li>
            </>
          )}
        </ul>

        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          <div className="modal__simple-header">
            <h3 className="modal__simple-title modal__simple-title--with-btn">{t('projects.sidebarGroup')} {i + 1}</h3>
            <span className="box-widget__text">
              {data.length} <span>{t('sideBar.participants')}</span>
            </span>
          </div>
          <div className="form__row">
            <label
              htmlFor="participantsSearch"
              className="form__label"
            >
              {t("textsCommon.research")}
            </label>
            <input
              aria-label="Buscar estudante"
              className="form__control form__control--search-with-icon"
              style={{ width: 'auto' }}
              placeholder={t('projects.placeholderStudentName')}
              type="search"
              name="studentsSearch"
              id="studentsSearch"
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>

          <div
            className="u-overflow-y"
            style={{ minHeight: '56px', maxHeight: '88vh' }}
          >
            {filteredUsers.map(user => (
              <div
                className="student-card"
                key={user?.id}
              >
                <header className="student-card__header">
                  <UserInfo
                    className={className}
                    user={user}
                  />
                </header>
              </div>
            ))}
          </div>
        </Modal>
      </>
    )
  );
}
