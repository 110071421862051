import React, { useContext, useEffect, useState } from 'react';
import { MaterialsCard } from 'app/components/ProgramOfStudies/MaterialsCard';
import Documents from 'app/components/Contents/Documents';
import Loader from 'app/components/loader';
import { FiFileText } from 'react-icons/fi';
import Swal from 'sweetalert2';
import Modal from 'app/components/Modal';
import { MaterialsProvider, MaterialsContext } from 'app/providers/MaterialsProvider';
import EmptyState from 'app/components/EmptyState';
import ViewMaterial from './ViewMaterial';
import Onboarding from 'app/components/Onboarding';
import { useSession } from 'app/hooks/useSession';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { useTranslation } from 'react-i18next';

const Materials = props => (
  <MaterialsProvider>
    <Screen {...props} />
  </MaterialsProvider>
);

function Screen(props) {
  const { t } = useTranslation();
  const { materialsFolders, loading, getMaterialsFolders, selectedMaterialsFolder, setSelectedMaterialsFolder, updateMaterialsFolder, setIsMaterialsFolderEditOpen, isMaterialsFolderEditOpen, deleteMaterialsFolder } = useContext(MaterialsContext);
  const path = props.path;

  const [currentEditingMaterialsFolder, setCurrentEditingMaterialsFolder] = useState({ id: null, name: '' });

  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [relationships, setRelationships] = useState({
    courses: []
  });
  const client = useClient();
  const [searchTerm, setSearchTerm] = useState('');
  const { session } = useSession();
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];

  const filters = useFormik({
    initialValues: {
      course: 'blank'
    }
  });

  useEffect(() => {
    getMaterialsFolders();
    getRelationships();
  }, []);

  useEffect(() => {
    setFilteredMaterials(materialsFolders);
    filters.setFieldValue('course', 'blank');
  }, [materialsFolders]);

  useEffect(() => {
    let newFolders = materialsFolders;
    if (filters.values.course !== 'blank') {
      const course = relationships.courses.find(c => c.id === +filters.values.course);
      newFolders = filterMaterialsFolders(course, materialsFolders);
    }

    if (searchTerm !== '') {
      newFolders = newFolders.filter(m => m.name != null && m.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
    }

    setFilteredMaterials(newFolders);
  }, [filters.values.course, searchTerm]);

  useEffect(() => {
    if ((materialsFolders || []).length > 0) {
      history.state?.folder && setSelectedMaterialsFolder(history.state.folder);
      const id = new URLSearchParams(window.location.search).get('id');
      if (id) {
        const folder = materialsFolders.find(f => f.contents.find(c => c.id === parseInt(id)));

        folder && setSelectedMaterialsFolder(folder);
      }
    }
  }, [materialsFolders]);

  const submitEdit = async e => {
    e.preventDefault();
    try {
      await updateMaterialsFolder(currentEditingMaterialsFolder);
    } finally {
      setIsMaterialsFolderEditOpen(false);
    }
  };

  const selectFolder = folder => {
    const url = new URL(window.location);
    window.history.pushState({ folder: folder }, '', url);
    setSelectedMaterialsFolder(folder);
  };

  const backMaterials = () => {
    const url = new URL(window.location);
    let historyState = history.state;
    historyState?.folder && delete historyState.folder;
    window.history.pushState(historyState, '', url);
    setSelectedMaterialsFolder(null);
  };

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses');

    setRelationships({
      courses: courses || []
    });
  };

  function filterMaterialsFolders(course, materialsFolders) {
    const newMaterialsList = [];
    const classroomIds = course.classrooms.map(c1 => c1.id);
    materialsFolders.map(materialsFolder => {
      const newContents = materialsFolder.contents.filter(content => content.courses.map(c => c.id).includes(course.id) || content.classrooms.some(c => classroomIds.includes(c.id)));
      if (newContents.length > 0) {
        newMaterialsList.push({ ...materialsFolder, contents: newContents });
      }
    });
    return newMaterialsList;
  }

  return (
    <>
      <main className="main-content main-content--block">
        <div className="tab__content">
          {!selectedMaterialsFolder && (
            <div className="filter-bar filter-bar--left">
              <div className="filter-bar__row">
                <label
                  htmlFor="activitySearch"
                  className="filter-bar__label"
                >
                  {t('materials.search')}
                </label>
                <input
                  className="form__control form__control--search-with-icon"
                  placeholder={t('filter.searchActivity')}
                  type="search"
                  name="activitySearch"
                  id="activitySearch"
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </div>

              <FilterSelectionBox
                label={t('filter.labelSelectCourse')}
                value={filters.values.course}
                onChange={e => {
                  filters.setFieldValue('course', e.target.value);
                }}
                options={relationships.courses.map(c => ({
                  ...c,
                  name: c.title
                }))}
              />
            </div>
          )}

          {selectedMaterialsFolder && (
            <Documents
              materialsFolder={selectedMaterialsFolder}
              backMaterials={backMaterials}
            />
          )}

          {!selectedMaterialsFolder && !loading && filteredMaterials.length === 0 && (
            <EmptyState
              type="content"
              title={t('emptyState.materialsNotTitle')}
              text={t('emptyState.materialsNotText')}
            />
          )}

          {!selectedMaterialsFolder && (
            <div className="tab__pane">
              {loading ? (
                <Loader />
              ) : (
                <div className="round-dg-wrapper">
                  <table className="round-dg round-dg--light">
                    <tbody className="round-dg__body">
                      {filteredMaterials.map(materialsFolder => (
                        <MaterialsCard
                          key={materialsFolder.id}
                          materialFolder={materialsFolder}
                          onEditOpen={() => {
                            setIsMaterialsFolderEditOpen(true);
                            setCurrentEditingMaterialsFolder({
                              id: materialsFolder.id,
                              name: materialsFolder.name
                            });
                          }}
                          onDelete={() => {
                            Swal.fire({
                              title: t('projects.warningDeleteMaterialsFolder'),
                              confirmButtonText: t('button.yes'),
                              showDenyButton: true,
                              denyButtonText: t('button.no'),
                              showCloseButton: true
                            }).then(res => {
                              if (res.isConfirmed) {
                                deleteMaterialsFolder(materialsFolder.id);
                              }
                            });
                          }}
                          selectFolder={() => selectFolder(materialsFolder)}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        </div>

        <Modal
          show={isMaterialsFolderEditOpen}
          onClose={() => setIsMaterialsFolderEditOpen(false)}
        >
          <form
            onSubmit={submitEdit}
            className="form"
          >
            <h2 className="form__title">Editar pasta de materiais</h2>

            <div className="form__row">
              <label
                className="form__label"
                htmlFor="classCourse"
              >
                {t('textsCommon.name')}
              </label>
              <input
                className="form__control"
                id="name"
                name="name"
                type="text"
                placeholder="Digite o novo nome da pasta de materiais"
                value={currentEditingMaterialsFolder.name}
                onChange={e =>
                  setCurrentEditingMaterialsFolder({
                    ...currentEditingMaterialsFolder,
                    name: e.target.value
                  })
                }
              />
            </div>

            <button
              className="btn btn--primary"
              type="submit"
            >
              Editar pasta de materiais
            </button>
          </form>
        </Modal>

        <Onboarding
          path={path}
          show={showOnboarding}
          setShowOnboarding={setShowOnboarding}
          onClose={() => setShowOnboarding(false)}
          profiles={profiles}
          title={t('onboarding.titleMaterials')}
          videoId="g44jc7HO7YY"
        />
      </main>
    </>
  );
}

export { Materials as default, ViewMaterial };
