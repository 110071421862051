import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'app/components/Modal';
import { FiMessageCircle } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import CommentCard from 'app/components/postComment';
import { FiSend } from 'react-icons/fi';
import { mockComment } from './mockData';

export default function CommentsModal(props) {
  const { show, onClose, createComment, commentText, setCommentText, isAdmin, answer, comments, deleteComment, setPinnedComment, recentComment } = props;

  const { t } = useTranslation();
  const sortedComments = [...comments].sort((a, b) => (b.pinned ? 1 : a.pinned ? -1 : 0));
  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <div className="comments-modal">
        <div className="comments-modal__header">
          <h3 className="comments-modal__title">Comentários</h3>
          <span className="comments-modal__count">
            <FiMessageCircle /> {comments?.length}
          </span>
        </div>

        <div className="comments-modal__body">
          {comments?.length === 0 && (
            <EmptyState
              type="register"
              title={'Seu comentário aparecerá aqui'}
              text={'Digite e pressione enter para enviar'}
              bgless
            />
          )}

          {(isAdmin || (answer && comments && comments.length > 0)) && (
            <>
              {sortedComments.map((comment, index) => (
                <div
                  className="comments-modal__pinned"
                  key={comment.id}
                >
                  <CommentCard
                    index={index}
                    comment={comment}
                    comments={comments}
                    deleteComment={comment => deleteComment(comment)}
                    pinned={comment.id === recentComment?.id}
                    setPinnedComment={setPinnedComment}
                  />
                </div>
              ))}
            </>
          )}
        </div>

        <div className="comments-modal__footer">
          <form
            className="chat-widget__comment-form"
            method="post"
            onSubmit={e => createComment(e)}
          >
            <input
              className="form__control"
              placeholder={t('textsCommon.placeholderSendYourComment')}
              type="text"
              name="comment"
              id="comment"
              onChange={e => setCommentText(e.target.value)}
              value={commentText}
            />
            <button
              className="btn btn--primary btn--send"
              type="submit"
            >
              <FiSend />
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
}
