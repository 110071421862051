import React from 'react';
import {
  FiMoreHorizontal,
  FiHeadphones,
  FiVideo,
  FiFileText,
} from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import { useTranslation } from 'react-i18next';

export default function StepVideoCard(props) {
  const { t } = useTranslation();
  const { videoQuestion, removeQuestion, openModal } = props;

  function icon(type) {
    switch (type) {
      case 'Áudio':
        return <FiHeadphones className="card__icon" />;
      case 'Vídeo':
        return <FiVideo className="card__icon" />;
      default:
        return <FiFileText className="card__icon" />;
    }
  }

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
        <div className="card__header u-mb-0">
          <div className="card__inner-header">
            <p className="card__header">{videoQuestion.duration}</p>
            <div className="card__icon-wrapper">{icon()}</div>
            <h2 className="card__title" style={{ flexGrow: 'initial' }}>
              {videoQuestion.body}
            </h2>
          </div>
        </div>
      </th>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '38px' }}
      >
        <Dropdown>
          <Dropdown.Toggle>
            <FiMoreHorizontal />
          </Dropdown.Toggle>

          <Dropdown.Content>
            <Dropdown.Item onClick={removeQuestion}>{t('button.delete')}</Dropdown.Item>
            <Dropdown.Item onClick={openModal}>
            {t("button.edit")}
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown>
      </td>
    </tr>
  );
}
