import React from 'react';
import styles from './AnswerKey.module.scss';

export default function AnswerKeyRow() {
  return (
    <div
      className="round-dg-wrapper"
      style={{ width: '100%' }}
    >
      <table style={{ width: '100%', minWidth: 525 }}>
        <tbody>
          <tr className="round-dg__row">
            <td
              className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row"
              style={{ width: '25%', paddingLeft: 0 }}
            >
              <span className={styles.id}>Q123456</span>
            </td>
            <td
              className="round-dg__cell round-dg__cell--sm"
              style={{ width: '25%' }}
            >
              <span className={styles.cell}>C</span>
            </td>
            <td
              className="round-dg__cell round-dg__cell--sm"
              style={{ width: '25%' }}
            >
              <span className={`${styles.cell} ${styles.primary}`}>B</span>
            </td>
            <td
              className="round-dg__cell round-dg__cell--sm"
              style={{ width: '25%', paddingRight: 0 }}
            >
              <span className={`${styles.cell} ${styles.primary}`}>Nível 1</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
