import { useEffect, useMemo, useState } from 'react';
import { SimpleModal } from '../Modal';
import { useClient, useQuery } from 'jsonapi-react';
import toast from 'react-hot-toast';
import styles from './styles.module.scss';
import Select from 'react-select';
import { useFormik } from 'formik';
import makeAnimated from 'react-select/animated';
import { useSession } from 'app/hooks/useSession';
import InputMask from 'react-input-mask';
import { apiTokenless } from 'app/services/api';
import { validateCPF } from 'app/utils/validateCPF';
import promiseRequest from 'app/utils/promiseToast';

export default function NoClassroomModal({ show, setShow, renderCPF, renderClassrooms, currentUser }) {
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [courses, setCourses] = useState([]);
  const [regionals, setRegionals] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [cpfError, setCpfError] = useState('');
  const { promiseToast, isSubmitting } = promiseRequest();
  const animatedComponents = makeAnimated();
  const client = useClient();

  const form = useFormik({
    initialValues: {
      'course-ids': null,
      'regional-ids': null,
      'institution-ids': null,
      'classroom-ids': null,
      cpfCnpj: null
    },
    onSubmit: updateUser
  });

  useEffect(() => {
    fetchCourses();
    fetchRegionals();
  }, []);

  async function fetchCourses() {
    try {
      const response = await apiTokenless.get('courses?filter[is_public]=false');
      setCourses(response.data.data.map(item => item.attributes));
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchRegionals() {
    try {
      const response = await apiTokenless.get('regionals');
      setRegionals(response.data.data.map(item => item.attributes));
    } catch (e) {
      console.log(e);
    }
  }

  async function fetchInstitutions() {
    const response = await apiTokenless.get(`institutions?filter[regional_id]=${form.values['regional-ids'].value}`);
    setInstitutions(response.data.data.map(item => item.attributes));
  }

  async function fetchClassrooms() {
    const response = await apiTokenless.get(`classrooms?filter[institution_id]=${form.values['institution-ids'].value}&filter[course_id]=${form.values['course-ids'].value}`);
    setClassrooms(response.data.data.map(item => item.attributes));
  }

  useEffect(() => {
    if (form.values['regional-ids']) {
      fetchInstitutions();
    }
    if (form.values['institution-ids'] && form.values['course-ids']) {
      fetchClassrooms();
    }
  }, [form.values['regional-ids'], form.values['institution-ids'], form.values['course-ids']]);

  function parseOptions(data) {
    return data.map(item => ({ value: item.id, label: item.title ?? item.name }));
  }

  async function updateUser() {
    const auxValues = { ...form.values };

    const payload = { 'reviewed-infos': true };
    if (auxValues['classroom-ids'] !== null) {
      payload['classroom-ids'] = [auxValues['classroom-ids'].value];
    }
    if (auxValues.cpfCnpj !== null || !!currentUser.cpfCnpj) {
      payload['cpf-cnpj'] = auxValues.cpfCnpj;
    }

    if (!renderCPF || auxValues.cpfCnpj === null || validateCPF(auxValues?.cpfCnpj)) {

      promiseToast({
        url: ['users', currentUser.id],
        request: payload,
        successText: 'Dados atualizados com sucesso',
        errorText: 'Erro ao atualizar dados'
      }).then(data => {
        setShow(false);
        let newSession = { ...JSON.parse(localStorage.getItem('session')) };
        newSession.user.cpfCnpj = auxValues.cpfCnpj;
        newSession.user.reviewedInfos = true;
        localStorage.setItem('session', JSON.stringify(newSession));
      });
    } else {
      setCpfError('CPF inválido');
    }
  }

  useEffect(() => {
    form.setFieldValue('classroom-ids', null);
  }, [form.values['course-ids'], form.values['institution-ids']]);

  useEffect(() => {
    form.setFieldValue('institution-ids', null);
    form.setFieldValue('classroom-ids', null);
  }, [form.values['regional-ids']]);

  return (
    <SimpleModal
      contentClassName="simple-modal active simple-modal__content--sm   simple-modal__content--review-data"
      show={show}
      onClose={() => setShow(false)}
    >
      <form onSubmit={form.handleSubmit}>
        <div className={styles.container}>
          <span
            className="form__label"
            style={{ textAlign: 'center' }}
          >
            Revise seus dados cadastrais
          </span>
          <span style={{ textAlign: 'center' }}>Percebemos que seus dados podem estar incorretos, revise os dados abaixo:</span>
          <div>
            {renderCPF && (
              <>
                <label
                  style={{ marginTop: '14px' }}
                  className="form__label"
                  htmlFor="course-ids"
                >
                  CPF
                </label>

                <InputMask
                  className="form__control"
                  mask="999.999.999-99"
                  name="cpf"
                  id="cpf"
                  value={form.values.cpfCnpj}
                  onChange={e => form.setFieldValue('cpfCnpj', e.target.value)}
                />
                {cpfError && <span style={{ color: 'red' }}>{cpfError}</span>}
              </>
            )}

            {renderClassrooms && (
              <>
                <label
                  style={{ marginTop: '14px' }}
                  className="form__label"
                  htmlFor="course-ids"
                >
                  Curso
                </label>
                <Select
                  classNamePrefix="react-multi-select"
                  openMenuOnFocus
                  noOptionsMessage={() => 'Sem opções'}
                  isDisabled={loadingCourses}
                  value={form?.values?.['course-ids']}
                  placeholder={loadingCourses ? 'Carregando...' : 'Selecione o curso'}
                  onChange={item => form.setFieldValue('course-ids', item)}
                  components={{
                    animatedComponents,
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null
                  }}
                  isSearchable={true}
                  options={parseOptions(courses)}
                />
                <label
                  style={{ marginTop: '14px' }}
                  className="form__label"
                  htmlFor="course-ids"
                >
                  Regional
                </label>
                <Select
                  classNamePrefix="react-multi-select"
                  openMenuOnFocus
                  noOptionsMessage={() => 'Sem opções'}
                  isDisabled={loadingCourses}
                  value={form?.values?.['regional-ids']}
                  placeholder={loadingCourses ? 'Carregando...' : 'Selecione a regional'}
                  onChange={item => form.setFieldValue('regional-ids', item)}
                  components={{
                    animatedComponents,
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null
                  }}
                  isSearchable={true}
                  options={parseOptions(regionals)}
                />
                <label
                  style={{ marginTop: '14px' }}
                  className="form__label"
                  htmlFor="course-ids"
                >
                  Escola
                </label>
                <Select
                  classNamePrefix="react-multi-select"
                  openMenuOnFocus
                  noOptionsMessage={() => 'Sem opções'}
                  isDisabled={loadingCourses}
                  value={form?.values?.['institution-ids']}
                  placeholder={loadingCourses ? 'Carregando...' : 'Selecione a escola'}
                  onChange={item => form.setFieldValue('institution-ids', item)}
                  components={{
                    animatedComponents,
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null
                  }}
                  isSearchable={true}
                  options={parseOptions(institutions)}
                />
                <label
                  style={{ marginTop: '14px' }}
                  className="form__label"
                  htmlFor="course-ids"
                >
                  Turma
                </label>
                <Select
                  classNamePrefix="react-multi-select"
                  openMenuOnFocus
                  noOptionsMessage={() => 'Sem opções'}
                  isDisabled={loadingCourses}
                  value={form?.values?.['classroom-ids']}
                  placeholder={loadingCourses ? 'Carregando...' : 'Selecione a turma'}
                  onChange={item => form.setFieldValue('classroom-ids', item)}
                  components={{
                    animatedComponents,
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null
                  }}
                  isSearchable={true}
                  options={parseOptions(classrooms)}
                />
              </>
            )}

            <button
              disabled={isSubmitting}
              style={{ width: '100%', marginTop: '40px' }}
              type="submit"
              className="btn btn--outline btn--wide"
            >
              Salvar
            </button>
          </div>
        </div>
      </form>
    </SimpleModal>
  );
}
