import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import WarningWall from './WarningWall';

export default function WallSlider({ data }) {
  return (
    <div className="warning-slider u-mb-4">
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
      >
        {data?.map(warning => (
          <SwiperSlide key={warning.id}>
            <WarningWall warning={warning} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
