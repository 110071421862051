import React, { useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import userAvatar from 'app/images/user-avatar.svg';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import EmptyState from 'app/components/EmptyState';

export function QuizDataOpenAnswers(props) {
  const { surveyId, studentId } = props;

  const client = useClient();
  const [surveyData, setSurveyData] = useState();
  const [studentData, setStudentData] = useState();

  const getSurveyData = async () => {
    try {
      const { data } = await client.fetch(['surveys', surveyId]);
      setSurveyData(data);
    } catch (e) {
      console.log(e);
      toast.error('Erro ao buscar dados do questionário.');
    }
  };

  const getStudentData = async () => {
    try {
      const { data } = await client.fetch([`survey_answers?filter[survey_id]=${surveyId}`]);
      setStudentData(data.filter(p => p.id == studentId)[0]);
    } catch (e) {
      console.log(e);
      toast.error('Erro ao buscar respostas.');
    }
  };

  const quizStatusClass = () => {
    switch (surveyData?.about?.status) {
      case 'Inativo':
        return 'activity-card__status--danger';
        break;
      case 'Ativo':
        return 'activity-card__status--success';
        break;
    }
  };

  useEffect(() => {
    getSurveyData();
    getStudentData();
  }, []);

  return (
    <>
      <main className="main-content main-content--block fadeIn">
        <div className="filter-bar">
          <button
            onClick={() => history.back()}
            className="filter-bar__back"
          >
            <FiChevronLeft />

            <span className="filter-bar__back-container">
              <span className="filter-bar__title">Resposta do estudante</span>
            </span>
          </button>
        </div>

        <div className="page fadeIn u-mb-3">
          <div className="page__content">
            <div
              className="chart-widget__container u-mb-4"
              style={{ alignItems: 'center' }}
            >
              <div
                className="chart-widget__group"
                style={{ gap: '0' }}
              >
                <div className="chart-widget__meta">
                  <span className={`activity-card__status ${quizStatusClass()} u-mr-2`}>{surveyData?.about?.status}</span>
                </div>

                <h3 className="chart-widget__title chart-widget__title--lg">{surveyData?.title}</h3>
              </div>
            </div>

            {studentData &&
              studentData[`survey-question-answers`].map((answer, index) => (
                <div
                  className="quiz-data"
                  key={index}
                >
                  <h4 className="quiz-data__question">{answer[`survey_question`]}</h4>
                  <div className="quiz-data__answer">
                    <img
                      src={studentData.user.image.thumb.url || userAvatar}
                      alt="Foto do estudante"
                      className="quiz-data__avatar"
                    />
                    <p className="quiz-data__response-text">
                      <strong>{studentData?.user?.name}</strong> respondeu:
                      <br />
                      {answer[`answer_body`]}
                    </p>
                  </div>
                </div>
              ))}
          </div>

          <div className="page__aside" />
        </div>
      </main>
    </>
  );
}
