import { useTranslation } from 'react-i18next';
import { TopBar } from './TopBar';
import cardProjectPng from '../../images/onboarding/projects/cardProject.png';
import navigateProjectPng from '../../images/onboarding/projects/navigateProject.png';
import buttonProjectPng from '../../images/onboarding/projects/buttonProject.png';
import approvProjectPng from '../../images/onboarding/projects/approvProject.png';
import cardApprovProjectPng from '../../images/onboarding/projects/cardApprovProject.png';

export function ProjectsOnboarding() {
  const { t } = useTranslation();

  return (
    <section>
      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.exploreProjectsCard')}</h1>
        <p className="text-option-onboarding">{t('onboarding.exploreProjectsCardDesc')}</p>

        <div style={{ width: 300 }}>
          <img src={cardProjectPng} />
        </div>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.navigateProjectDetails')}</h1>
        <p className="text-option-onboarding"> {t('onboarding.navigateProjectDetailsDesc')}
        </p>
        <div style={{ width: 580 }}>
          <img src={navigateProjectPng} />
        </div>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.sendFile')}</h1>
        <p className="text-option-onboarding">{t('onboarding.sendFileDesc')}</p>

        <div style={{ width: 240 }}>
          <img src={buttonProjectPng} />
        </div>
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.projectApproval')}</h1>
        <p className="text-option-onboarding">{t('onboarding.projectApprovalDesc')}</p>

        <img
          style={{ width: 250 }}
          src={approvProjectPng}
        />
        <img
          style={{ width: 250 }}
          src={cardApprovProjectPng}
        />
      </div>
    </section>
  );
}
