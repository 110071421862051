import { t } from 'i18next';
import React from 'react';
import { FiClipboard, FiEdit3, FiTarget, FiUsers } from 'react-icons/fi';
import { TbCopy } from 'react-icons/tb';

export default function InfoWidget({ course }) {
  return (
    <div className="widget-wrapper u-mb-0">
      <div className="info-widget info-widget--t3">
        <div className="info-widget__header">
          <h3 className="info-widget__title">{t('textsCommon.modules')}</h3>
        </div>

        <div className="info-widget__body">
          <p className="info-widget__value">
            <TbCopy /> {course ? course?.['learning-systems'].length : 0}
          </p>
        </div>
      </div>

      <div className="info-widget info-widget--t3">
        <div className="info-widget__header">
          <h3 className="info-widget__title">{t('courses.classes')}</h3>
        </div>

        <div className="info-widget__body">
          <p className="info-widget__value">
            <FiUsers /> {course ? course?.['classroom-count'] : 0}
          </p>
        </div>
      </div>

      <div className="info-widget info-widget--t3">
        <div className="info-widget__header">
          <h3 className="info-widget__title">{t('courses.exams')}</h3>
        </div>

        <div className="info-widget__body">
          <p className="info-widget__value">
            <FiTarget /> {course ? course?.['exam-count'] : 0}
          </p>
        </div>
      </div>

      <div className="info-widget info-widget--t3">
        <div className="info-widget__header">
          <h3 className="info-widget__title">{t('courses.activities')}</h3>
        </div>

        <div className="info-widget__body">
          <p className="info-widget__value">
            <FiEdit3 /> {course ? course?.['activity-count'] : 0}
          </p>
        </div>
      </div>

      <div className="info-widget info-widget--t3">
        <div className="info-widget__header">
          <h3 className="info-widget__title">{t('courses.projects')}</h3>
        </div>

        <div className="info-widget__body">
          <p className="info-widget__value">
            <FiClipboard /> {course ? course?.['project-count'] : 0}
          </p>
        </div>
      </div>
    </div>
  );
}
