import Modal from 'app/components/Modal';
import MultiSelect from 'app/components/MultiSelect';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { useEffect } from 'react';
import { useState } from 'react';
import React from 'react';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { FiTrash2 } from 'react-icons/fi';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

const VideoQuestionModal = props => {
  const { t } = useTranslation();
  const { show, onClose, formikObject, activeSelectedQuestion, quizIndex } = props;
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);
  const [firstLevelTaxonomies, setFirstLevelTaxonomies] = useState([]);
  const [questionType, setQuestionType] = useState('multipla-escolha');
  const [isOpened, setIsOpened] = useState(false);

  const client = useClient();

  useEffect(() => {
    if (activeSelectedQuestion?.quiz_item_alternatives?.length <= 2) {
      setQuestionType('certo ou errado');
    } else {
      setQuestionType('multipla-escolha');
    }
  }, []);

  const createVideoQuestions = async () => {
    const items = formikObject.values.quizItemsAttributes;
    if (quizIndex !== undefined) {
      formikObject.setFieldValue(
        'quizItemsAttributes',
        items.map((item, index) => (index === quizIndex ? createVideoQuestionsForm.values : item))
      );
    } else {
      formikObject.setFieldValue('quizItemsAttributes', [...items, createVideoQuestionsForm.values]);
    }

    onClose();
  };

  const validationSchema = Yup.object({
    duration: Yup.string().required(t('warning.requiredField')),
    body: Yup.string().required(t('warning.requiredField')),
    quiz_item_alternatives: Yup.array(
      Yup.object({
        title: Yup.string().required(t('warning.requiredField'))
      })
    ).required(t('warning.requiredField'))
  });

  const createVideoQuestionsForm = useFormik({
    initialValues: {
      id: activeSelectedQuestion ? activeSelectedQuestion.id : null,
      duration: activeSelectedQuestion ? activeSelectedQuestion.duration : '00:00:00',
      body: activeSelectedQuestion ? activeSelectedQuestion.body : '',
      selectedTaxonomies: activeSelectedQuestion ? activeSelectedQuestion.taxonomies : [],
      quiz_item_alternatives: activeSelectedQuestion
        ? activeSelectedQuestion?.quiz_item_alternatives
        : [
            {
              'is-correct': true,
              title: ''
            },
            {
              'is-correct': false,
              title: ''
            },
            {
              'is-correct': false,
              title: ''
            },
            {
              'is-correct': false,
              title: ''
            }
          ]
    },
    validationSchema,
    onSubmit: createVideoQuestions
  });

  useEffect(() => {
    if (questionType === 'multipla-escolha') {
      createVideoQuestionsForm.setValues({
        ...createVideoQuestionsForm.values,
        quiz_item_alternatives: activeSelectedQuestion
          ? activeSelectedQuestion?.quiz_item_alternatives
          : [
              {
                'is-correct': true,
                title: ''
              },
              {
                'is-correct': false,
                title: ''
              },
              {
                'is-correct': false,
                title: ''
              },
              {
                'is-correct': false,
                title: ''
              }
            ]
      });
    } else if (questionType === 'certo ou errado') {
      createVideoQuestionsForm.setValues({
        ...createVideoQuestionsForm.values,
        quiz_item_alternatives: [
          {
            'is-correct': true,
            title: 'Certo'
          },
          {
            'is-correct': false,
            title: 'Errado'
          }
        ]
      });
    }
  }, [questionType]);

  const fetchTaxonomies = async () => {
    const { data, error } = await client.fetch('taxonomies');

    if (error) {
      toast.error(t('toast.errorGetFirstLvlTaxonomy'));
    } else {
      setFirstLevelTaxonomies(data);
    }
  };

  useEffect(() => fetchTaxonomies(), []);

  const btnGroup = [
    { id: 1, name: 'multipla-escolha', label: 'Múltipla escolha' },
    {
      id: 2,
      name: 'certo ou errado',
      label: 'Certo ou errado'
    }
  ];

  const handleChangeTrueOrFalse = index => event => {
    createVideoQuestionsForm.setValues({
      ...createVideoQuestionsForm.values,
      quiz_item_alternatives: createVideoQuestionsForm.values?.quiz_item_alternatives.map((item, i) => {
        return {
          ...item,
          'is-correct': index === i
        };
      })
    });
    createVideoQuestionsForm.handleChange(event);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsOpened(true);
    }, 100);
  }, []);

  const addAlternative = () => {
    createVideoQuestionsForm?.setFieldValue('quiz_item_alternatives', [
      ...createVideoQuestionsForm?.values?.quiz_item_alternatives,
      {
        'is-correct': false,
        title: ''
      }
    ]);
  };

  return (
    <Modal
      show={isOpened}
      onClose={onClose}
    >
      <form onSubmit={createVideoQuestionsForm.handleSubmit}>
        <div className="form_row">
          <fieldset className="form__row">
            <label className="form__label">{t('lessons.setTimeQuestionTitle')}</label>
            <input
              onChange={createVideoQuestionsForm?.handleChange}
              name="duration"
              className="form__control"
              type="text"
              id="duration"
              step="1"
              value={createVideoQuestionsForm.values.duration}
            />
            <span style={{ color: 'red' }}>{createVideoQuestionsForm.touched.duration && createVideoQuestionsForm.errors.duration}</span>
          </fieldset>
        </div>
        <div className="form_row">
          <label className="form__label">*{t('exams.formTitleStatementQuestion')}</label>
          <textarea
            onChange={createVideoQuestionsForm?.handleChange}
            placeholder="Escreva o enunciado aqui"
            type="text"
            id="body"
            name="body"
            value={createVideoQuestionsForm?.values.body}
          />
        </div>
        <span style={{ color: 'red' }}>{createVideoQuestionsForm.touched.body && createVideoQuestionsForm.errors.body}</span>
        <div className="form__row">
          <label className="form__label">{t('tasks.mattersRelated')}</label>

          <MultiSelect
            selectedTaxonomies={createVideoQuestionsForm?.values.selectedTaxonomies}
            setFieldValue={createVideoQuestionsForm?.setFieldValue}
            openModal={() => setIsOpenModalTaxonomies(true)}
            placeholder={t('tasks.mattersAdd')}
          />
        </div>

        {firstLevelTaxonomies.length > 0 && isOpenModalTaxonomies && (
          <ModalTaxonomies
            taxonomies={firstLevelTaxonomies}
            selectedTaxonomies={createVideoQuestionsForm?.values.selectedTaxonomies || []}
            setFieldValue={createVideoQuestionsForm?.setFieldValue}
            closeModal={() => setIsOpenModalTaxonomies(false)}
          />
        )}

        <div
          className="btn-group u-mb-3"
          role="group"
          aria-label="Escolha o tipo de questão"
        >
          {btnGroup?.map(btn => (
            <>
              <input
                type="radio"
                name={btn.name}
                id={btn.id}
                autoComplete="off"
                checked={questionType === btn.name}
                onChange={() => {
                  setQuestionType(btn.name);
                  createVideoQuestionsForm.setTouched({}, false);
                }}
              />
              <label
                htmlFor={btn.id}
                className="btn btn--outline"
              >
                {btn.label}
              </label>
            </>
          ))}
        </div>

        {questionType === 'multipla-escolha' ? (
          <>
            <div className="form-row">
              <label className="form__label">*Alternativas</label>
              <p className="new-questions__subtitle">Escreva o texto das alternativas e marque a correta</p>
            </div>

            {(createVideoQuestionsForm?.values?.quiz_item_alternatives || []).map((option, index) => {
              const changeCorrectOption = () => {
                createVideoQuestionsForm?.values?.quiz_item_alternatives.forEach((_, i) => {
                  createVideoQuestionsForm?.setFieldValue(`quiz_item_alternatives.${i}.is-correct`, false);
                });
                createVideoQuestionsForm?.setFieldValue(`quiz_item_alternatives.${index}.is-correct`, true);
              };

              const removeAlternative = () => {
                const alternatives = createVideoQuestionsForm.values?.quiz_item_alternatives;
                alternatives.splice(index, 1);
                createVideoQuestionsForm.setFieldValue('quiz_item_alternatives', alternatives);
              };

              return (
                <div className="form__check form__check--full">
                  <div
                    key={index}
                    style={{ display: 'flex' }}
                  >
                    <p>{option.option}</p>
                    <input
                      type="radio"
                      name={`quiz_item_alternatives.${index}.is-correct`}
                      checked={
                        index === 0
                          ? createVideoQuestionsForm?.values?.quiz_item_alternatives[index]?.['is-correct'] === true || createVideoQuestionsForm.values['quiz_item_alternatives'].filter(opt => opt['is-correct'] === true).length === 0
                          : createVideoQuestionsForm?.values?.quiz_item_alternatives[index]?.['is-correct'] === true
                      }
                      onChange={changeCorrectOption}
                      className="form__check-input form__check-input--rounded"
                    />
                    <input
                      name={`quiz_item_alternatives.${index}.title`}
                      value={option.title}
                      id="title"
                      type="text"
                      onChange={createVideoQuestionsForm?.handleChange}
                      className="form__control form__control--small"
                    />
                    <FiTrash2 onClick={removeAlternative} />
                  </div>
                  <span style={{ color: 'red' }}>{createVideoQuestionsForm.touched['quiz_item_alternatives']?.[index] && createVideoQuestionsForm.errors['quiz_item_alternatives']?.[index]?.title}</span>
                </div>
              );
            })}

            <button
              type="button"
              className="btn btn--small btn--full btn--outline-dashed"
              onClick={addAlternative}
            >
              + Alternativa
            </button>
          </>
        ) : null}

        {questionType === 'certo ou errado' && (
          <div className="form__row">
            <label className="form__label">*Marque a opção correta para a questão</label>
            <div style={{ display: 'flex', gap: 20 }}>
              <div className="form__check">
                <input
                  name="teste"
                  onChange={handleChangeTrueOrFalse(0)}
                  className="form__check-input"
                  type="radio"
                  checked={createVideoQuestionsForm?.values?.['quiz_item_alternatives']?.[0]?.['is-correct']}
                />
                <p className="new-questions__subtitle">Certo</p>
              </div>
              <div className="form__check">
                <input
                  name="teste"
                  onChange={handleChangeTrueOrFalse(1)}
                  className="form__check-input"
                  type="radio"
                />
                <p className="new-questions__subtitle">Errado</p>
              </div>
            </div>
          </div>
        )}
        <div className="form__row">
          <button
            className="btn btn--primary btn--wide"
            type="submit"
          >
            {t('nps.buttonAddQuestions')}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default VideoQuestionModal;
