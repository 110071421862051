import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { navigate } from '@reach/router';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';

import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import Modal from 'app/components/Modal';
import { useClassroom } from 'app/hooks/useClassroom';
import { useCourse } from 'app/hooks/useCourse';
import Error404 from 'app/components/Error404';
import getPermission from 'app/utils/getPermission';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import Select from 'react-select';
import ClassroomCard from './ClassroomCard';
import TabNavBar from './TabNavBar';
import { ManageClassroomParticipants } from './ManageClassroomParticipants';
import ClassroomInvitations from './ClassroomInvitations';
import ViewInvitations from './ClassroomInvitations/ViewInvitations';
import ClassroomImports from './ClassroomImports';
import ViewImports from './ClassroomImports/ViewImports';
import { t } from 'i18next';
import EndScroll from 'app/components/endScroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as Yup from 'yup';
import promiseRequest from 'app/utils/promiseToast';
import BreadCrumbs from 'app/components/BreadCrumbs';

export function Classrooms(props) {
  const { courseId, uri } = props;

  const [show, setShow] = useState(false);
  const [currentClassroom, setCurrentClassroom] = useState('');
  const [institutions, setInstitutions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const client = useClient();
  const { getClassrooms, selectedClassroom, setSelectedClassroom, getClassroomsPagination, classrooms, hasMoreClassrooms, setClassrooms, loading } = useClassroom();
  const { courses, getSimpleCourses } = useCourse();
  const { promiseToast, isSubmitting } = promiseRequest();
  const debounceTimer = useRef(null);

  const filters = useFormik({
    initialValues: {
      discipline: courseId || 'blank',
      institution_id: 'blank',
      bestPerformance: false,
      minorPerformance: false,
      lowerFrequency: false
    },
    onSubmit: values => {
      console.log(values);
    }
  });

  const schema = Yup.object({
    title: Yup.string().required(t('warning.requiredField')),
    course_id: Yup.string().required(t('warning.requiredField'))
  });

  const classroomForm = useFormik({
    initialValues: {
      title: '',
      course_id: '',
      institution_id: '',
      initial_date: moment(),
      end_date: moment()
    },
    validationSchema: schema,
    onSubmit: async values => {
      const parsedForm = {
        ...values,
        initial_date: moment(values.initial_date).format('YYYY-MM-DD'),
        end_date: moment(values.end_date).format('YYYY-MM-DD')
      };

      promiseToast({
        url: 'classrooms',
        request: parsedForm,
        successText: t('toast.successSaving'),
        errorText: t('toast.errorCreateClass')
      }).then(data => {
        getClassrooms();
        navigate(`/turmas/gerenciar/${data.id}`);
      });
      classroomForm.resetForm();
    }
  });

  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    getClassroomsPagination();
    if (courses.length === 0) {
      getSimpleCourses();
    }
  }, []);

  useEffect(() => {
    if (isEditing && currentClassroom) {
      classroomForm.setFieldValue('title', currentClassroom?.title);
      classroomForm.setFieldValue('institution_id', currentClassroom?.institution?.id);
      classroomForm.setFieldValue('course_id', currentClassroom?.course?.id);
      classroomForm.setFieldValue('initial_date', currentClassroom?.['initial-date']);
      classroomForm.setFieldValue('end_date', currentClassroom?.['end-date']);
    }
  }, [show, currentClassroom, isEditing]);

  const editClassroomForm = classroom => {
    setCurrentClassroom(classroom);
    setShow(true);
  };

  const editCurrentClassroom = async () => {
    const { data, error } = await client.mutate(['classrooms', currentClassroom.id], classroomForm.values);

    if (error) {
      toast.error('Turma não pode ser editada!');
    } else {
      setSelectedClassroom({ ...selectedClassroom, name: data.title });

      let newListEdit = classrooms;
      let newClassroom = newListEdit.find(item => item.id === currentClassroom.id);
      newClassroom.title = data.title;
      // setClassroomsList([...newListEdit]);

      toast.success('Turma editada!');
    }

    setShow(false);
    setIsEditing(false);
    setCurrentClassroom(null);
    getClassrooms();
  };

  const handleSubmit = () => {
    if (isEditing) {
      editCurrentClassroom();
    } else {
      classroomForm.submitForm();
      setShow(false);
    }
  };

  const closeModal = () => {
    setShow(false);
    setIsEditing(false);
    setCurrentClassroom(null);
    classroomForm.resetForm();
  };

  const getInstitutions = async () => {
    const { data, error } = await client.fetch('/institutions');
    if (error) {
      toast.error('Erro ao buscar instituições');
    } else {
      setInstitutions(data);
    }
  };

  useEffect(() => {
    getInstitutions();
  }, []);

  const deleteClassroom = async classroom => {
    const { error } = await client.delete(['classrooms', classroom.id]);
    if (error) {
      toast.error('Erro ao excluir turma.');
    } else {
      toast.success('Turma excluida com sucesso.');
      getClassrooms();
    }
  };

  let today = new Date();
  today = today.toISOString().split('T')[0];

  const viewClassroom = getPermission('Visualizar lista de turmas', 'Turmas');

  useEffect(() => {
    getClassroomsPagination(searchTerm, filters.values.discipline, filters.values.institution_id, pageNumber);
  }, [pageNumber]);

  const breadCrumbs = {
    title: 'Organização da Plataforma',
    nav: [
      {
        route: uri,
        name: 'Turmas',
        isActive: true
      }
    ]
  };

  if (!viewClassroom) return <Error404 />;

  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    // setLoading(true);
    debounceTimer.current = setTimeout(() => {
      if (searchTerm.length > 0) {
        setPageNumber(1);
      }
      setClassrooms([]);
      getClassroomsPagination(searchTerm, '', '', pageNumber);
    }, 500);
    // setLoading(false);
  }, [searchTerm]);

  const handleChangeFilterSelect = (field, e) => {
    filters.setFieldValue(field, e);

    setPageNumber(1);
    setClassrooms([]);
  };

  useEffect(() => {
    getClassroomsPagination('', filters?.values?.discipline, filters?.values?.institution_id, pageNumber);
  }, [filters.values.discipline, filters.values.institution_id]);

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <TabNavBar selectedTab="turmas" />

      <div className="tab__pane">
        <div className="filter-bar">
          <div className="filter-bar__row">
            <label
              htmlFor="participantsSearch"
              className="filter-bar__label"
            >
              {t('materials.search')}
            </label>

            <input
              aria-label="Buscar estudante"
              className="form__control form__control--search-with-icon"
              style={{ width: 'auto' }}
              placeholder={t('classes.searchClass')}
              type="search"
              name="classroomsSearch"
              id="classroomsSearch"
              onChange={e => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
          </div>
          <FilterSelectionBox
            label={t('exams.selectCourse')}
            value={filters.values.discipline}
            onChange={e => {
              if (filters.values.discipline !== e.target.value) handleChangeFilterSelect('discipline', e.target.value);
            }}
            options={courses.map(c => ({ id: c.id, name: c.title }))}
          />
          <div>
            <label className="form__label">{t('filter.institution')}:</label>
            <Select
              defaultValue={filters.values.institution_id}
              openMenuOnFocus
              options={[
                { value: 'blank', label: 'Todas' },
                ...institutions?.map(item => {
                  return { value: item.id, label: item.name };
                })
              ]}
              className="react-multi-select filter-bar__multi-select"
              classNamePrefix="react-multi-select"
              placeholder={t('filter.blankLabelAll')}
              noOptionsMessage={() => 'Sem opções'}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              onChange={e => {
                if (filters.values.institution_id !== e.value) handleChangeFilterSelect('institution_id', e.value);
              }}
              style={{ width: '300px' }}
            />
          </div>

          {getPermission('Criar turmas', 'Turmas') && (
            <button
              className="btn btn--wide btn--primary btn--wide"
              onClick={() => setShow(true)}
            >
              {t('courses.newClass')}
            </button>
          )}
        </div>

        {!classrooms.length > 0 && !loading && searchTerm?.length === 0 && filters.values.discipline === 'blank' && filters.values.institution_id === 'blank' && <EmptyState type="data" />}
        {(searchTerm?.length > 0 || filters.values.discipline !== 'blank' || filters.values.institution_id !== 'blank') && classrooms?.length === 0 && !loading && (
          <EmptyState
            type="data"
            text="Nenhuma turma encontrada"
          />
        )}
        {loading && <Loader />}
        {classrooms.length > 0 && (
          <InfiniteScroll
            dataLength={classrooms.length}
            next={() => setPageNumber(pageNumber + 1)}
            hasMore={hasMoreClassrooms}
            loader={<Loader />}
            // endMessage={<EndScroll />}
          >
            <div className="tab__cards">
              {classrooms.map(c => (
                <ClassroomCard
                  key={c.id}
                  classroom={c}
                  editClassroomForm={editClassroomForm}
                  deleteClassroom={deleteClassroom}
                  setIsEditing={setIsEditing}
                />
              ))}
            </div>
          </InfiniteScroll>
        )}
        <Modal
          show={show}
          onClose={closeModal}
        >
          <form
            method="post"
            className="form"
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <h2 className="modal__simple-title">{t('courses.newClass')}</h2>

            <p className="form__description">{t('courses.newClassDescription')}</p>

            <div className="form__row">
              <label
                className="form__label"
                htmlFor="classTitle"
              >
                *{t('courses.className')}
              </label>
              <input
                className="form__control"
                id="classTitle"
                name="classTitle"
                type="text"
                placeholder={t('courses.placeholderClassName')}
                value={classroomForm.values.title}
                onChange={e => classroomForm.setFieldValue('title', e.target.value)}
              />
              {classroomForm.touched.title && classroomForm.errors.title && <span style={{ color: 'red' }}>{classroomForm.errors.title}</span>}
            </div>

            <div className="form__Row u-mb-3 ">
              <label className="form__label">{t('filter.institution')}:</label>
              <Select
                defaultValue={filters.values.institution}
                openMenuOnFocus
                options={institutions?.map(item => {
                  return { value: item.id, label: item.name };
                })}
                value={institutions.map(item => ({ value: item.id, label: item.name })).find(inst => inst.value === classroomForm.values.institution_id)}
                className="react-multi-select filter-bar__multi-select"
                classNamePrefix="react-multi-select"
                placeholder="Selecione uma instituição"
                noOptionsMessage={() => 'Sem opções'}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
                onChange={e => classroomForm.setFieldValue('institution_id', e.value)}
                style={{ width: '300px' }}
              />
            </div>

            <div className="form__row">
              <label
                className="form__label"
                htmlFor="course"
              >
                *{t('courses.relatedClasstoCourse')}
              </label>
              <select
                className="form__select"
                name="course"
                id="course"
                onChange={e => classroomForm.setFieldValue('course_id', e.target.value)}
                value={classroomForm.values['course_id']}
              >
                <option>{t('exams.selectCourse')}</option>
                {courses.map(t => (
                  <option value={t.id}>{t.title}</option>
                ))}
              </select>
              {classroomForm.touched.course_id && classroomForm.errors.course_id && <span style={{ color: 'red' }}>{classroomForm.errors.course_id}</span>}
            </div>

            <div className="form__row">
              <label
                className="form__label"
                htmlFor="initial_date"
              >
                {t('courses.classAvailabilityPeriod')}
              </label>

              <div className="form__columns">
                <div className="form__col">
                  <input
                    className="form__control"
                    id="initial_date"
                    name="initial_date"
                    type="date"
                    min={isEditing ? null : today}
                    value={classroomForm?.values?.['initial_date']}
                    onChange={e => classroomForm.setFieldValue('initial_date', e.target.value)}
                  />
                </div>
                <div className="form__col">
                  <input
                    className="form__control"
                    id="end_date"
                    name="end_date"
                    type="date"
                    min={today}
                    value={classroomForm?.values?.['end_date']}
                    onChange={e => classroomForm.setFieldValue('end_date', e.target.value)}
                  />
                </div>
              </div>
            </div>

            <button
              className="btn btn--primary"
              type="submit"
              disabled={isSubmitting}
            >
              {isEditing ? t('classes.editClass') : t('courses.buttonNewClass')}
            </button>
          </form>
        </Modal>
      </div>
    </main>
  );
}

export { Classrooms as default, ManageClassroomParticipants, ClassroomInvitations, ViewInvitations, ClassroomImports, ViewImports };
