import React from 'react';
import { ProjectsList } from './ProjectsList';
import ProjectsContent from './ProjectsContent';
import { ProjectsProvider } from './provider';
import ProjectsSidebar from './ProjectsSidebar';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import Menu from 'app/components/Menu';

export const ProjectsSingle = props => {
  const { projectId } = props;
  const authorityLevel = getAuthorityLevel();

  return (
    <div className="main-screen">
      {authorityLevel !== 'student' && <ProjectsSidebar projectId={projectId} />}
      {authorityLevel === 'student' && <Menu />}

      <main className="main-content u-p-0">
        <div className={authorityLevel === 'student' ? 'new-questions new-questions--student' : 'new-questions'}>
          <ProjectsProvider>
            <ProjectsList projectId={projectId} />
            <ProjectsContent projectId={projectId} />
          </ProjectsProvider>
        </div>
      </main>
    </div>
  );
};
