import React from 'react';
import Modal from 'app/components/Modal';
import { t } from 'i18next';

export default function CreationCustomModal(props) {
  const { show, onClose, form, handleSubmit } = props;

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <h3 className="modal__simple-title">{t('textsCommon.participantDistribution')}</h3>

      <div
        className="kpi-widget kpi-widget--bright u-mb-3"
        style={{ width: '80%' }}
      >
        <div className="kpi-widget__header">
          <span className="kpi-widget__hat">Projeto</span>
          <p className="kpi-widget__action-title">{form?.values?.title}</p>
          <h4 className="kpi-widget__count">Personalizado</h4>
        </div>

        <div>
          <div className="kpi-widget__count-in-body u-mb-2">
            {form?.values?.participants?.length} <span>{t('sideBar.participants')}</span>
          </div>
        </div>
      </div>

      <p>Você confirma a criação dos grupos para o projeto?</p>

      <div className="card__columns">
        <button
          type="button"
          className="btn btn--outline btn--full"
        >
          Não
        </button>
        <button
          type="button"
          className="btn btn--primary btn--full"
          onClick={() => handleSubmit()}
        >
          Sim
        </button>
      </div>
    </Modal>
  );
}
