import React, { useState, useEffect, useRef } from 'react';
import { FiMessageSquare } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import Notification from './Notification';
import { useClient } from 'jsonapi-react';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'app/hooks/useNotification';

export default function DropdownMessages() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { notificationsComment, setNotificationsComment, notificationCommentCount, setNotificationCommentCount, getNotificationsComment } = useNotification();

  const client = useClient();
  const menuRef = useRef();

  async function handleReadBtn() {
    const { data, error } = await client.mutate('update_all/notifications', {
      notifications: notificationsComment.map(notification => notification['related-notification-ids'].map(id => ({ id: id, unread: false }))).flat()
    });

    if (!error) {
      setNotificationCommentCount(0);
      setNotificationsComment(notificationsComment.map(n => ({ ...n, unread: false })));
    }
  }

  useEffect(() => {
    const closeOnEscapeKeyDown = event => event.key === 'Escape' && isOpen && setIsOpen(false);
    const closeOnClickOutside = event => !menuRef.current.contains(event.target) && setIsOpen(false);

    document.addEventListener('keydown', closeOnEscapeKeyDown);
    document.addEventListener('mouseup', closeOnClickOutside);

    getNotificationsComment();

    return () => {
      document.removeEventListener('keydown', closeOnEscapeKeyDown);
      document.removeEventListener('mouseup', closeOnClickOutside);
    };
  }, [isOpen]);
  return (
    <div
      className="notifications"
      aria-label="Mensagens"
      ref={menuRef}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="notifications__toggle"
      >
        <FiMessageSquare />
        {notificationCommentCount >= 1 && <span className="notifications__badge">{notificationCommentCount}</span>}
      </button>

      {isOpen && (
        <div className="notifications__content">
          <div className="notifications__header">
            <h3 className="notifications__title">{t('textsCommon.messagesTitle')}</h3>
            <button
              onClick={() => (notificationCommentCount != 0 ? handleReadBtn() : '')}
              className="notifications__read-btn"
            >
              {t('header.notificationRead')}
            </button>
          </div>

          <div className="notifications__body">
            {notificationsComment.length === 0 && (
              <EmptyState
                type="notification"
                title={t('emptyState.notificationTitle')}
              />
            )}
            {notificationsComment.map(notification => (
              <Notification
                notifications={notificationsComment}
                notification={notification}
                setNotificationCount={setNotificationCommentCount}
                setNotifications={setNotificationsComment}
                notificationCount={notificationCommentCount}
                key={notification.id}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
