import { useTranslation } from 'react-i18next';
import React from 'react';
import { navigate } from '@reach/router';

function TabNavBar({ selectedTab, abilities, id }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="tab">
        {abilities?.metricas && (
          <button
            className={`tab__link ${selectedTab === 'metricas' ? 'active' : ''}`}
            onClick={() => navigate(`/usuario/metricas/${id}`)}
          >
            {t('journey.toggleMetrics')}
          </button>
        )}

        <button
          className={`tab__link ${selectedTab === 'medalhas' ? 'active' : ''}`}
          onClick={() => navigate(`/usuario/medalhas/${id}`)}
        >
          {t('journey.toggleMedals')}
        </button>
      </div>
    </>
  );
}

export default TabNavBar;
