import React, { useState } from 'react';
import { ReactComponent as Star } from 'app/images/icons/fi-star.svg';

export default function StarRating({
  rating = 1,
  setRating,
  hover,
  setHover,
  style,
  staticRating,
}) {
  return !staticRating ? (
    <div className="star-rating" style={style}>
      {[...Array(5)].map((star, index) => {
        index += 1;

        return (
          <button
            type="button"
            key={index}
            className={
              index <= (hover || rating)
                ? 'star-rating__btn on'
                : 'star-rating__btn off'
            }
            onClick={() => setRating(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <Star className="star-rating__star" />
          </button>
        );
      })}
    </div>
  ) : (
    <div className="star-rating" style={style}>
      {[...Array(5)].map((star, index) => {
        index += 1;

        return (
          <button
            type="button"
            key={index}
            className={
              index <= rating
                ? 'u-cursor-default star-rating__btn on'
                : 'u-cursor-default star-rating__btn off'
            }
          >
            <Star className="star-rating__star" />
          </button>
        );
      })}
    </div>
  );
}
