import React from 'react';
import { FiClock } from 'react-icons/fi';
import Expanded from './Expanded';
import { useTranslation } from 'react-i18next';

function Calendar() {
  const { t } = useTranslation();
  return (
    <>
      <div className="home-content__side-calendar">
        <div className="home-content__side-calendar__section">
          Calendário semanal
          <div className="home-content__side-calendar__expand_button">{t('button.expand')}</div>
        </div>
        <div className="home-content__side-calendar__section">25/02/2021</div>
        <div className="home-content__side-calendar__section">01/03/2021</div>
        <div className="home-content__side-calendar__section">07/03/2021</div>
        <div className="home-content__side-calendar__section">14/03/2021</div>
      </div>

      <div className="home-content__main-calendar">
        <div className="home-content__main-calendar__section">
          <p>
            <span style={{ color: '#FDB221', fontWeight: 'bold' }}>Teoria</span> Português - Tópicos 3-5
          </p>
          <div>
            <FiClock style={{ marginRight: 10 }} />
            <FiClock style={{ marginRight: 10 }} />
            <FiClock style={{ marginRight: 10 }} />
          </div>
        </div>
        <div className="home-content__main-calendar__section">
          <p>
            <span style={{ color: '#EB5650', fontWeight: 'bold' }}>{t('textsCommon.questions')}</span> Português - Tópicos 3-5
          </p>
          <div>
            <FiClock style={{ marginRight: 10 }} />
          </div>
        </div>
        <div className="home-content__main-calendar__section">
          <p>
            <span style={{ color: '#B622FC', fontWeight: 'bold' }}>Revisão</span> Português - Tópicos 3-5
          </p>
          <div>
            <FiClock style={{ marginRight: 10 }} />
            <FiClock style={{ marginRight: 10 }} />
          </div>
        </div>
        <div className="home-content__main-calendar__section">
          <p>
            <span style={{ color: '#1EC9FF', fontWeight: 'bold' }}>Simulado</span> Português - Tópicos 3-5
          </p>
          <div>
            <FiClock style={{ marginRight: 10 }} />
            <FiClock style={{ marginRight: 10 }} />
          </div>
        </div>
        <div className="home-content__main-calendar__section">
          <p>
            <span style={{ color: '#FDB221', fontWeight: 'bold' }}>Teoria</span> Português - Tópicos 3-5
          </p>
          <div>
            <FiClock style={{ marginRight: 10 }} />
            <FiClock style={{ marginRight: 10 }} />
            <FiClock style={{ marginRight: 10 }} />
          </div>
        </div>
        <div className="home-content__main-calendar__section">
          <p>
            <span style={{ color: '#EB5650', fontWeight: 'bold' }}>{t('textsCommon.questions')}</span> Português - Tópicos 3-5
          </p>
          <div>
            <FiClock style={{ marginRight: 10 }} />
            <FiClock style={{ marginRight: 10 }} />
          </div>
        </div>
      </div>

      {/* <div className="home-content__higlight">
        <div className="tagline">Em destaque</div>
        <h1 className="home-content__higlight__title">{t("textsCommon.questions")}</h1>
        <div className="home-content__higlight__description">
          Treine questões para prova da OAB
        </div>
        <div className="home-content__higlight__body">
          Você poderá treinar a partir de agora!
        </div>
        <div className="home-content__higlight__cta">
          <Link to="/questions" className="btn btn--primary">
            Começar a treinar
          </Link>
        </div>
      </div>
      <div className="home-content__feed">
        <div className="tagline">Feed, treending topic</div>

        {isLoading ? (
          <Loader />
        ) : (
          data.map(post => <PostCard key={post.id} post={post} />)
        )}
      </div> */}
    </>
  );
}

export default Calendar;

Calendar.Expanded = Expanded;
