import React, { useState, useContext } from 'react';
import { SessionContext } from '../providers/SessionProvider';
import api from '../services/api.js';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';

function ForgorPassword(props) {
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [submitted, setSubmitted] = useState(false);

  const state = useContext(SessionContext);

  if (state.session && Object.keys(state.session).length !== 0) {
    window.location.pathname = '';
  }

  const sendPasswordInstructions = async e => {
    setSubmitted(true);
    e.preventDefault();
    await api
      .post('auth/password', { email: email.toLowerCase(), app: true })
      .then(response => {
        console.log(response.error);
        setEmail('');
        toast.success(response.message, { duration: 30000 });
      })
      .catch(error => {
        if (error.request.status === 404) {
          toast.error(t('toast.errorEmailNotFound'));
        } else {
          toast.error(error.response.data.errors[0].title);
        }
      });
  };

  const handleChangeEmail = e => {
    setEmail(e.target.value);
  };

  const handleSendRequest = e => {
    e.preventDefault();
    if (!submitted) {
      sendPasswordInstructions(e);
    } else {
      toast.error('Deve haver um intervalo mínimo de 15 minutos entre as solicitações de redefinição de senha.');
    }
    setTimeout(setSubmitted(true), 900000);
  };

  const appName = process.env.REACT_APP_NAME;
  const logo = require(`app/images/${appName}/horizontal-logo.svg`);
  const backimage = require(`app/images/${appName}/login-bg.svg`)

  return (
    <div className="new-login">
      <div className="new-login__container">
        <div className="new-login__body">
          <Link
            to="/login"
            className="filter-bar__back"
          >
            <div className="card__icon-wrapper">
              <FiChevronLeft />
            </div>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{t('button.back')}</span>
            </span>
          </Link>

          <div className="new-login__body-header">
            <div className="new-login__body-logo">
              <img
                alt="Logo Estudologia"
                src={logo}
              />
            </div>

            <div className="new-login__body-title">
              {t('login.forgotText')}

              <p className="small-description">{t('login.forgotTextDescription')}</p>
            </div>
          </div>
          <div className="new-login__body-form">
            <form
              className="form"
              method="post"
              onSubmit={e => handleSendRequest(e)}
            >
              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="email"
                >
                  Email:
                </label>
                <input
                  className="form__control"
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={handleChangeEmail}
                />
              </div>

              <div className="form__row">
                <input
                  type="submit"
                  value={t('login.sendLink')}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="new-login__image">
        <div
          className="new-login__login-image"
          style={{ backgroundImage: `url(${backimage})` }}
        >
          {' '}
        </div>
      </div>
    </div>
  );
}

export default ForgorPassword;
