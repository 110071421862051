import React from 'react';
import Avatar from 'app/components/Avatar';

export default function ProfileChallenge({ data }) {

  return (
    <div className="profile-challenge">
      <Avatar
        className="avatar--lg"             
        src={data?.user?.image}
        alt="Imagem do usuário"
      />

      <div className="profile-challenge__header">
        <p className="profile-challenge__hat">Autoria</p>
        <h3 className="profile-challenge__title">{data?.user?.name}</h3>
      </div>

      <div className="profile-challenge__body">
        <div
          className="card__badges card__badges--one-line u-mb-0"
          style={{ lineHeight: 1, alignItems: 'center' }}
        >
          <span
            className="u-text-truncate"
            title={data?.user?.institution?.name}
          >
            {data?.user?.institution?.name}
          </span>
          {/* <span                            
            className="badge badge--tiny badge--more"
            title={'Regional 1, Regional 2'}
          >
            +2
          </span> */}
        </div>
        <div
          className="card__badges card__badges--one-line u-mb-0"
          style={{ lineHeight: 1, alignItems: 'center' }}
        >
          <span
            className="u-text-truncate"
            title={data?.user?.institution?.regional?.name}
          >
            {data?.user?.institution?.regional?.name}
          </span>
          {/* <span
            className="badge badge--tiny badge--more"
            title={'Regional 1, Regional 2'}
          >
            +2
          </span> */}
        </div>
      </div>

      <div className="profile-challenge__footer">
        <span className="square-tag square-tag--md square-tag--full square-tag--success">Acerto geral {data?.['accuracy-rate']}%</span>
      </div>
    </div>
  );
}
