import React, { useEffect, useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiTrash2 } from 'react-icons/fi';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import TextareaAutosize from 'react-autosize-textarea';
import Modal from 'app/components/Modal';
import { useAgenda } from 'app/hooks/useAgenda';
import Task from 'app/components/Calendar/Task.js';
import Loader from 'app/components/loader';
import { NewSlotForm } from 'app/components/Calendar/NewSlotForm.js';
import { useTranslation } from 'react-i18next';

function MetricsCalendar({ userId, user }) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const currentWeekDay = new Date().getDay();
  const [currentSlotOriginalBody, setCurrentSlotOriginalBody] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentData, setCurrentData] = useState();

  const {
    loading,
    slots,
    currentSelectedWeek,
    currentSlot,
    setCurrentSlot,
    updateSlot,
    deleteSlot,
    getCurrentSlot,
    checkAccomplishSlot,
    getSprints,
    getSelectedSprintSlots,
    getPreviousSprintSlots,
    hasPreviousSprint,
    hasNextSprintSlots,
    getNextSprintSlots,
    sprints,
    currentSprintIndex,
    updateSlotOrderOnDrop,
  } = useAgenda();

  useEffect(() => {
    getSprints(user);
    getSelectedSprintSlots(null, user);
  }, []);

  return (
    <div className="expanded-calendar">
      <header className="expanded-calendar__header">
        {hasPreviousSprint() && (
          <button
            className="expanded-calendar__btn"
            aria-label="semana anterior"
            onClick={() => getPreviousSprintSlots()}
          >
            <FiArrowLeft />{' '}
            <span className="expanded-calendar__btn-text" aria-hidden>
            {t('liveClass.lastWeek')}
            </span>
          </button>
        )}

        <h2 className="expanded-calendar__selected-week">
          {currentSelectedWeek.sunday.format('DD/MM')} -{' '}
          {currentSelectedWeek.saturday.format('DD/MM')}
        </h2>

        {hasNextSprintSlots() && (
          <button
            className="expanded-calendar__btn"
            aria-label="próxima semana"
            onClick={() => getNextSprintSlots()}
          >
            <span className="expanded-calendar__btn-text" aria-hidden>
            {t('liveClass.nextWeek')}
            </span>{' '}
            <FiArrowRight />
          </button>
        )}
      </header>

      <div className="expanded-calendar__body">
        {loading ? (
          <div className="expanded-calendar__loader">
            <Loader />
          </div>
        ) : (
          <DragDropContext onDragEnd={updateSlotOrderOnDrop}>
            <div className="expanded-calendar__day">
              <h3 className="expanded-calendar__day-header">
                <span className="expanded-calendar__day-number">
                  {currentSelectedWeek.sunday.format('DD/MM')}
                </span>
                <span
                  className={`expanded-calendar__weekday${
                    currentWeekDay === 0 ? '__current' : ''
                  }`}
                >
                  Domingo
                </span>
              </h3>

              <Droppable droppableId="sunday">
                {provided => (
                  <div
                    className="expanded-calendar__hours-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {slots &&
                      slots.sunday.map((slot, index) => (
                        <Task
                          disabled={true}
                          key={slot.id}
                          slot={slot}
                          index={index}
                          onClick={() => {
                            getCurrentSlot(slot.id, 'sunday');
                            setCurrentSlotOriginalBody(slot.body);

                            setShow(true);
                          }}
                        />
                      ))}
                    {provided.placeholder}

                    <span
                      className="expanded-calendar__plus-btn"
                      onClick={() => {
                        setShowModal(true);
                        setCurrentData(
                          currentSelectedWeek.sunday.format('YYYY-MM-DD'),
                        );
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </div>

            <div className="expanded-calendar__day">
              <h3 className="expanded-calendar__day-header">
                <span className="expanded-calendar__day-number">
                  {currentSelectedWeek.monday.format('DD/MM')}
                </span>
                <span
                  className={`expanded-calendar__weekday${
                    currentWeekDay === 1 ? '__current' : ''
                  }`}
                >
                  Segunda
                </span>
              </h3>

              <Droppable droppableId="monday">
                {provided => (
                  <div
                    className="expanded-calendar__hours-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {slots &&
                      slots.monday.map((slot, index) => (
                        <Task
                          disabled={true}
                          key={slot.id}
                          slot={slot}
                          index={index}
                          onClick={() => {
                            getCurrentSlot(slot.id, 'monday');
                            setCurrentSlotOriginalBody(slot.body);

                            setShow(true);
                          }}
                        />
                      ))}
                    {provided.placeholder}

                    <span
                      className="expanded-calendar__plus-btn"
                      onClick={() => {
                        setShowModal(true);
                        setCurrentData(
                          currentSelectedWeek.monday.format('YYYY-MM-DD'),
                        );
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </div>

            <div className="expanded-calendar__day">
              <h3 className="expanded-calendar__day-header">
                <span className="expanded-calendar__day-number">
                  {currentSelectedWeek.tuesday.format('DD/MM')}
                </span>
                <span
                  className={`expanded-calendar__weekday${
                    currentWeekDay === 2 ? '__current' : ''
                  }`}
                >
                  Terça
                </span>
              </h3>

              <Droppable droppableId="tuesday">
                {provided => (
                  <div
                    className="expanded-calendar__hours-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {slots &&
                      slots.tuesday.map((slot, index) => (
                        <Task
                          disabled={true}
                          key={slot.id}
                          slot={slot}
                          index={index}
                          onClick={() => {
                            getCurrentSlot(slot.id, 'tuesday');
                            setCurrentSlotOriginalBody(slot.body);

                            setShow(true);
                          }}
                        />
                      ))}
                    {provided.placeholder}

                    <span
                      className="expanded-calendar__plus-btn"
                      onClick={() => {
                        setShowModal(true);
                        setCurrentData(
                          currentSelectedWeek.tuesday.format('YYYY-MM-DD'),
                        );
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </div>

            <div className="expanded-calendar__day">
              <h3 className="expanded-calendar__day-header">
                <span className="expanded-calendar__day-number">
                  {currentSelectedWeek.wednesday.format('DD/MM')}
                </span>
                <span
                  className={`expanded-calendar__weekday${
                    currentWeekDay === 3 ? '__current' : ''
                  }`}
                >
                  Quarta
                </span>
              </h3>

              <Droppable droppableId="wednesday">
                {provided => (
                  <div
                    className="expanded-calendar__hours-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {slots &&
                      slots.wednesday.map((slot, index) => (
                        <Task
                          disabled={true}
                          key={slot.id}
                          slot={slot}
                          index={index}
                          onClick={() => {
                            getCurrentSlot(slot.id, 'wednesday');
                            setCurrentSlotOriginalBody(slot.body);

                            setShow(true);
                          }}
                        />
                      ))}
                    {provided.placeholder}
                    <span
                      className="expanded-calendar__plus-btn"
                      onClick={() => {
                        setShowModal(true);
                        setCurrentData(
                          currentSelectedWeek.wednesday.format('YYYY-MM-DD'),
                        );
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </div>

            <div className="expanded-calendar__day">
              <h3 className="expanded-calendar__day-header">
                <span className="expanded-calendar__day-number">
                  {currentSelectedWeek.thursday.format('DD/MM')}
                </span>
                <span
                  className={`expanded-calendar__weekday${
                    currentWeekDay === 4 ? '__current' : ''
                  }`}
                >
                  Quinta
                </span>
              </h3>

              <Droppable droppableId="thursday">
                {provided => (
                  <div
                    className="expanded-calendar__hours-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {slots &&
                      slots.thursday.map((slot, index) => (
                        <Task
                          disabled={true}
                          key={slot.id}
                          slot={slot}
                          index={index}
                          onClick={() => {
                            getCurrentSlot(slot.id, 'thursday');
                            setCurrentSlotOriginalBody(slot.body);

                            setShow(true);
                          }}
                        />
                      ))}
                    {provided.placeholder}
                    <span
                      className="expanded-calendar__plus-btn"
                      onClick={() => {
                        setShowModal(true);
                        setCurrentData(
                          currentSelectedWeek.thursday.format('YYYY-MM-DD'),
                        );
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </div>

            <div className="expanded-calendar__day">
              <h3 className="expanded-calendar__day-header">
                <span className="expanded-calendar__day-number">
                  {currentSelectedWeek.friday.format('DD/MM')}
                </span>
                <span
                  className={`expanded-calendar__weekday${
                    currentWeekDay === 5 ? '__current' : ''
                  }`}
                >
                  Sexta
                </span>
              </h3>

              <Droppable droppableId="friday">
                {provided => (
                  <div
                    className="expanded-calendar__hours-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {slots &&
                      slots.friday.map((slot, index) => (
                        <Task
                          disabled={true}
                          key={slot.id}
                          slot={slot}
                          index={index}
                          onClick={() => {
                            getCurrentSlot(slot.id, 'friday');
                            setCurrentSlotOriginalBody(slot.body);

                            setShow(true);
                          }}
                        />
                      ))}
                    {provided.placeholder}
                    <span
                      className="expanded-calendar__plus-btn"
                      onClick={() => {
                        setShowModal(true);
                        setCurrentData(
                          currentSelectedWeek.friday.format('YYYY-MM-DD'),
                        );
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </div>

            <div className="expanded-calendar__day">
              <h3 className="expanded-calendar__day-header">
                <span className="expanded-calendar__day-number">
                  {currentSelectedWeek.saturday.format('DD/MM')}
                </span>
                <span
                  className={`expanded-calendar__weekday${
                    currentWeekDay === 6 ? '__current' : ''
                  }`}
                >
                  Sábado
                </span>
              </h3>

              <Droppable droppableId="saturday">
                {provided => (
                  <div
                    className="expanded-calendar__hours-container"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {slots &&
                      slots.saturday.map((slot, index) => (
                        <Task
                          disabled={true}
                          key={slot.id}
                          slot={slot}
                          index={index}
                          onClick={() => {
                            getCurrentSlot(slot.id, 'saturday');
                            setCurrentSlotOriginalBody(slot.body);

                            setShow(true);
                          }}
                        />
                      ))}
                    {provided.placeholder}
                    <span
                      className="expanded-calendar__plus-btn"
                      onClick={() => {
                        setShowModal(true);
                        setCurrentData(
                          currentSelectedWeek.saturday.format('YYYY-MM-DD'),
                        );
                      }}
                    />
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        )}
      </div>

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <NewSlotForm
          day={currentData}
          sprint_id={sprints[currentSprintIndex]?.id}
          setShowModal={setShowModal}
        />
      </Modal>

      <Modal
        show={show}
        onClose={() => {
          if (currentSlotOriginalBody !== currentSlot.body) {
            updateSlot();
          }
          setShow(false);
        }}
        contentClassName="u-p-0"
      >
        <div
          className={`calendar-task-modal calendar-task-modal--${
            currentSlot && currentSlot.activity
          }`}
        >
          <label className="calendar-task-modal__header">
            <input
              type="checkbox" name="checkbox" id="checkbox"
              checked={currentSlot?.accomplished}
              className="calendar-task-modal__check-input"
            />

            <span className="calendar-task-modal__matter">
              <span
                className={`calendar-task-modal__category calendar-task-modal__category--${
                  currentSlot && currentSlot.activity
                }`}
              >
                {currentSlot && currentSlot['t-activity']}
              </span>{' '}
              {currentSlot && currentSlot.taxonomy.name}
            </span>
            <button
              className="archive-card__btn"
              onClick={() => {
                deleteSlot();
                setShow(false);
              }}
              type="button"
            >
              <div className="calendar-task-modal__delete-icon">
                <FiTrash2 />
              </div>
            </button>
          </label>

          <div className="calendar-task-modal__body">
            {currentSlot && (
              <TextareaAutosize
                placeholder={t("textsCommon.placeholderTaskDescription")}
                onChange={e =>
                  setCurrentSlot({ ...currentSlot, body: e.target.value })
                }
                value={currentSlot.body}
                style={{ width: '100%', border: 'none', resize: 'none' }}
              />
            )}
          </div>

          <div className="calendar-task-modal__footer">
            <div className="calendar-task-modal__footer-instructions">
            {t("tasks.titleEditDescription")}
            </div>
            {/*<button className="btn btn--small btn--outline">
							Materiais relacionados
						</button>
						<button className="btn btn--small btn--outline">
							Duplicar tarefa
						</button>*/}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MetricsCalendar;
