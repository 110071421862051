import React from 'react';
import { FiMousePointer, FiMoreHorizontal, FiVideo } from 'react-icons/fi';
import { useFormik } from 'formik';
import Dropdown from 'app/components/Dropdown';
import { t } from 'i18next';

export default function AdminLessonCard(props) {
  const { id } = props;

  const moduleForm = useFormik({
    initialValues: {
      publish: false
    }
  });

  return (
    <div className="admin-lesson-card">
      <div className="card__icon-wrapper card__icon-wrapper--md">
        <FiVideo className="card__icon" />
      </div>

      <div className="admin-lesson-card__body">
        <h3 className="admin-lesson-card__title">Nome da aula nome da aula nome </h3>
        <div className="admin-lesson-card__inner-body">
          <div className="form__check form__switch">
            <input
              className="form__check-input"
              id={`publish-l-${id}`}
              name={`publish-l-${id}`}
              type="checkbox"
              checked={moduleForm.values.publish}
              onClick={() => {
                moduleForm.setFieldValue('publish', !moduleForm.values.publish);
              }}
            />
            <label htmlFor={`publish-l-${id}`}>Publicar módulo</label>
          </div>
          <p className="card__count">
            <FiMousePointer className="card__icon" /> 10
          </p>
        </div>
      </div>

      <div className="admin-lesson-card__footer">
        <Dropdown>
          <Dropdown.Toggle>
            <FiMoreHorizontal />
          </Dropdown.Toggle>

          <Dropdown.Content>
            <Dropdown.Item>{t('button.edit')}</Dropdown.Item>
            <Dropdown.Item>{t('button.delete')}</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown>
      </div>
    </div>
  );
}
