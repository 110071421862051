import React, { useEffect, useState } from 'react';
import { FiChevronLeft, FiAlertCircle } from 'react-icons/fi';
import Calendar from 'app/components/Calendar';
import { useAgenda } from 'app/hooks/useAgenda';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

export function ManageExcessStudentTasks(props) {
  const { studentId } = props;
  const client = useClient();

  const [loading, setLoading] = useState(true);
  const [student, setStudent] = useState(null);

  useEffect(() => {
    getUser(studentId);
  }, []);

  const getUser = async studentId => {
    setLoading(true);
    const { data, error } = await client.fetch(`users/${studentId}`);
    if (error) {
      toast.error(t('toast.errorGetUser'));
    } else {
      setStudent(data);
      setLoading(false);
    }
  };

  return (
    <>
      <main className="main-content main-content--block">
        <div className="filter-bar">
          <button
            onClick={() => history.back()}
            className="filter-bar__back"
          >
            <FiChevronLeft />

            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{student?.name}</span>
            </span>
          </button>

          <div className="filter-bar__box u-ml-auto fadeIn">
            <span className="filter-bar__icon">
              <FiAlertCircle />
            </span>
            <span>
              Remaneje tarefas clicando na tarefa
              <br /> e arrastando para o lugar desejado
            </span>
          </div>
        </div>

        <Calendar.Expanded
          slotType="slot"
          excessTasks
          student={student}
        />
      </main>
    </>
  );
}
