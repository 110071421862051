import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FiClock, FiSave } from 'react-icons/fi';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import 'moment/locale/pt';
import { useTranslation } from 'react-i18next';

export function QuestionsSidebar({ reply, questionBook, lastAnswerSubmited }) {
  const { t } = useTranslation();
  const client = useClient();
  const [currGroupedAnswer, setCurrGroupedAnswer] = useState();
  const [currDuration, setCurrDuration] = useState();
  const [currDurationInterval, setCurrDurationInterval] = useState();
  const [chartOptions, setChartOptions] = useState();

  useEffect(() => {
    fetchGroupedAnswers();
  }, [lastAnswerSubmited]);

  useEffect(() => {
    getChartOptions();
  }, [currGroupedAnswer]);

  const fetchGroupedAnswers = async () => {
    try {
      const { data } = await client.fetch('daily_grouped_answers/last');
      setCurrGroupedAnswer(data);
      if (data && data.status === 'played') {
        getDuration(data);
        setCurrDurationInterval(setInterval(() => getDuration(data), 1000 * 60));
      } else {
        getDuration(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDuration = currGroupedAnswer => {
    const diff = moment().unix() - moment(currGroupedAnswer?.currentTimeStart).unix() || 0;
    setCurrDuration(moment.utc(diff * 1000 + (currGroupedAnswer?.duration || 0)));
  };

  const pauseGroupedAnswer = async (newStatus, exit) => {
    if (currGroupedAnswer) {
      const { data } = await client.mutate(['daily_grouped_answers', currGroupedAnswer.id], { status: newStatus });
      setCurrGroupedAnswer(data);
      clearInterval(currDurationInterval);
      if (data && data.status === 'played') {
        getDuration(data);
        setCurrDurationInterval(setInterval(() => getDuration(data), 1000 * 60));
      } else {
        getDuration(data);
      }
    }

    if (exit) {
      window.location.pathname = 'questions/apostilas';
    }
  };

  const getChartOptions = () => {
    if (currGroupedAnswer) {
      setChartOptions({
        chart: {
          backgroundColor: 'transparent',
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
          // width: 44,
          // height: 44,
        },
        colors: ['#32C9D3', '#FD6262'],
        credits: {
          enabled: false
        },
        title: {
          text: null
        },
        tooltip: { enabled: false },
        legend: {
          enabled: false
        },
        xAxis: {
          visible: false
        },
        yAxis: {
          visible: false
        },
        plotOptions: {
          pie: {
            size: '100%',
            dataLabels: { enabled: false }
          }
        },
        series: [
          {
            name: 'Aproveitamento',
            data: [
              {
                name: 'Acertou',
                y: currGroupedAnswer.statistics.correctCount
              },
              {
                name: 'Errou',
                y: currGroupedAnswer.statistics.wrongCount
              }
            ]
          }
        ]
      });
    }
  };

  return (
    <aside className="questions-sidebar">
      {reply && (
        <footer className="questions-sidebar__footer">
          {/*<button className="btn btn--small btn--link btn--full">
            Esconder performance
          </button>*/}
          <button
            className="btn btn--primary btn--full"
            onClick={() => pauseGroupedAnswer('paused', true)}
          >
            {t('activity.saveAndLeave')}
          </button>
        </footer>
      )}
      <div className="questions-sidebar__body">
        <div className="questions-sidebar__section">
          <h3 className="questions-sidebar__title">{t('activity.titleResponseToday')}</h3>
          <div className="questions-sidebar__chart">
            {reply && chartOptions && (
              <div className="questions-sidebar__chart-container">
                <HighchartsReact
                  style={{ width: '80px', height: '80px' }}
                  highcharts={Highcharts}
                  options={chartOptions}
                />
              </div>
            )}
            <div className="questions-sidebar__legends">
              <p className="questions-sidebar__legend">
                {t('activity.missed')}{' '}
                <strong className={`questions-sidebar__count ${(currGroupedAnswer?.statistics?.wrongCount || 0) > 0 ? 'questions-sidebar__count--error' : ''}`}>{(currGroupedAnswer && currGroupedAnswer?.statistics?.wrongCount) || 0}</strong>
              </p>
              <p className="questions-sidebar__legend">
                {t('activity.right')}{' '}
                <strong className={`questions-sidebar__count ${(currGroupedAnswer?.statistics?.correctCount || 0) > 0 ? 'questions-sidebar__count--success' : ''}`}>{(currGroupedAnswer && currGroupedAnswer?.statistics?.correctCount) || 0}</strong>
              </p>
            </div>
          </div>
          <p className="questions-sidebar__highlight">
            {t('activity.useOf')} <strong>{(currGroupedAnswer && currGroupedAnswer?.statistics?.hitPercentage) || 0}%</strong>
          </p>

          {/* <p className="questions-sidebar__highlight">
            Questões respondidas: <strong>{(currGroupedAnswer && currGroupedAnswer?.statistics?.totalCount) || 0}</strong>
          </p> */}
        </div>

        <div className="questions-sidebar__section">
          <div className="questions-sidebar__time">
            <div className="questions-sidebar__clock">
              <FiClock className={currGroupedAnswer && currGroupedAnswer.status == 'played' ? 'spin' : ''} />
              {currGroupedAnswer ? currDuration?.format('HH:mm') : '00:00'}
            </div>
            {currGroupedAnswer && currGroupedAnswer.status == 'played' && (
              <button
                className="btn btn--tiny btn--outline"
                onClick={() => pauseGroupedAnswer('paused', false)}
              >
                {t('questionBooks.buttonPause')}
              </button>
            )}
            {currGroupedAnswer && currGroupedAnswer.status == 'paused' && (
              <button
                className="btn btn--tiny btn--outline"
                onClick={() => pauseGroupedAnswer('played', false)}
              >
                {t('button.continue')}
              </button>
            )}
          </div>
          <p className="questions-sidebar__highlight">
            <div dangerouslySetInnerHTML={{ __html: t('activity.warningText') }}></div>
          </p>

          {/* {currGroupedAnswer && currGroupedAnswer.lastGroupedAnswer && (
            <div className="questions-sidebar__yesterday">
              <h3 className="questions-sidebar__title">Ontem você fez:</h3>
              <p>
                Questões: <strong>{currGroupedAnswer.lastGroupedAnswer?.statistics?.totalCount}</strong>
              </p>
              <p>
                Aproveitamento: <strong>{currGroupedAnswer.lastGroupedAnswer?.statistics?.hitPercentage}%</strong>
              </p>
              {currGroupedAnswer.lastGroupedAnswer?.duration > 0 && (
                <p>
                  Durante: <strong>{moment.utc(currGroupedAnswer.lastGroupedAnswer?.duration)?.format('HH:mm')}</strong>
                </p>
              )}
            </div>
          )} */}
        </div>
      </div>
    </aside>
  );
}
