import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import MainSidebar from 'app/components/Sidebar';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';

export default function QuestionSidebar(props) {
  const { t } = useTranslation();
  const { steps, currentStep, setCurrentStep, edit } = props;

  return (
    <MainSidebar hasBrand={false}>
      <nav className="notebook-sidebar__nav">
        <Link
          to="/avaliacoes/banco-de-questoes"
          className="filter-bar__back"
        >
          <div className="card__icon-wrapper">
            <FiChevronLeft />
          </div>
          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{t('textsCommon.questions')}</span>
          </span>
        </Link>

        <ul className="step-by-step-nav">
          {steps.map((step, index) => {
            return (
              <li
                className={`step-by-step-nav__item ${index === currentStep ? 'active' : ''} ${currentStep > index ? 'step-by-step-nav__item--completed' : ''}`}
                onClick={() => edit && setCurrentStep(index)}
                key={index}
              >
                <Link
                  to={edit ? `/lms/criar/modulo/${step.route}` : ''}
                  className="step-by-step-nav__link"
                  style={{ cursor: edit ? 'pointer' : 'default' }}
                >
                  {step.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </MainSidebar>
  );
}
