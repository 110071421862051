import React, { useEffect, useState } from 'react';
import { FiMessageCircle, FiTrash2, FiFileText, FiHeadphones, FiVideo } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import moment from 'moment';
import { useAuditorium } from 'app/hooks/useAuditorium';
import { useParams } from '@reach/router';

export default function MessageCard(props) {
  const { deleteMessage, setMessageIds } = useAuditorium();
  const { text = true, message, onlyFile, chats } = props;
  const [file, setFile] = useState();
  const id = useParams().auditoriumId;

  const isAdmin = !checkIncludeProfiles({ only: ['student'] });

  useEffect(() => {
    if (message?.['auditorium-files']?.length > 0) {
      setFile(message?.['auditorium-files']?.[0]);
    }
  }, []);

  const handleSendMessageIds = () => {
    const messageIds = {
      messageId: message.id,
      lessonId: message['auditorium-lesson']?.id,
      messageBody: message.body
    };
    setMessageIds(messageIds);
  };

  const handleDelete = () => {
    Swal.fire({
      title: 'Deseja mesmo excluir esta mensagem?',
      text: 'Esta ação não poderá ser desfeita',
      confirmButtonText: 'Sim',
      showDenyButton: true,
      denyButtonText: 'Não',
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteMessage(id, message, onlyFile, chats);
      }
    });
  };

  const icon = file => {
    const splitFilename = file.filename.split('.');
    const extension = splitFilename[splitFilename.length - 1];
    switch (true) {
      case ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'mpeg', '3gp', 'webm'].includes(extension):
        return <FiVideo className="message-card__file-thumb" />;
      case ['mp3', 'wav', 'flac', 'aac', 'ogg', 'wma', 'aiff', 'm4a', 'alac', 'opus'].includes(extension):
        return <FiHeadphones className="message-card__file-thumb" />;
      default:
        return <FiFileText className="message-card__file-thumb" />;
    }
  };

  return (
    <div className="message-card-wrapper">
      <div className="message-card">
        <div className="message-card__user">
          {/* <Avatar
            className="avatar--xs"
            src={message?.user?.image?.url}
          /> */}
          <div className="message-card__info">
            <h3 className="message-card__name">{message?.user?.name}</h3>
            <p className="message-card__date-time">{moment(message?.['created-at']).format('HH:mm')}</p>
          </div>
        </div>

        <div className="message-card__inside">
          {text && <div dangerouslySetInnerHTML={{ __html: message?.body }} />}

          {file && (
            <div className="message-card__file">
              {file.filename && icon(file)}
              <div className="message-card__file-content">
                <h4 className="message-card__file-title">{file?.filename}</h4>
                <p className="message-card__file-meta">{`Prof. ${message.user.name} em ${moment(message?.['created-at']).format('DD/MM/YYYY')}`}</p>
              </div>
              <a
                className="btn btn--small btn--wide btn--outline"
                href={file?.file.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Baixar
              </a>
            </div>
          )}

          {onlyFile && (
            <div className="message-card__file">
              {message?.filename && icon(message)}
              <div className="message-card__file-content">
                <h4 className="message-card__file-title">{message.filename}</h4>
                <p className="message-card__file-meta">{`Prof. ${message.user?.name ?? ''} em ${moment(message?.['created-at']).format('DD/MM/YYYY')}`}</p>
              </div>
              <a
                className="btn btn--small btn--wide btn--outline"
                href={message.file.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Baixar
              </a>
            </div>
          )}
        </div>
      </div>

      <div className="message-card__actions">
        {!onlyFile && (
          <FiMessageCircle
            className="message-card__icon"
            onClick={handleSendMessageIds}
          />
        )}

        {isAdmin && (
          <FiTrash2
            className="message-card__icon message-card__icon--danger"
            onClick={handleDelete}
          />
        )}
      </div>
    </div>
  );
}
