import React from 'react';
import Dropdown from 'app/components/Dropdown';
import { FiMoreHorizontal, FiEdit3, FiMessageCircle, FiFolder, FiCheckCircle } from 'react-icons/fi';
import { MdOutlineQuiz } from 'react-icons/md';
import dragAndDrop from 'app/images/icons/drag-and-drop.svg';
import { navigate } from '@reach/router';
import { useClient } from 'jsonapi-react';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import YouTube from 'react-youtube';
import { useEffect } from 'react';
import { useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { useTranslation } from 'react-i18next';

export default function LessonCard(props) {
  const { t } = useTranslation();
  const { title, taxonomies, messageCount, files, lessonId, disciplineId, questions, activities, learningSystemId, onSuccess, lesson, selectedDisciplineId, selectedModuleId, quizItemsCount } = props;
  const client = useClient();
  const [videoPlatform, setVideoPlatform] = useState('');
  const [videoId, setVideoId] = useState();
  const [requiredLesson, setRequiredLesson] = useState(!lesson?.unrequired);

  const opts = {
    height: '120',
    width: '180',
    playerVars: {
      autoplay: 0
    }
  };

  useEffect(() => {
    checkVideoPlayer(lesson.url);
  }, [lesson.url]);

  const checkVideoPlayer = url => {
    const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    const vimeoRegex = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

    const youtubeResult = url.match(youtubeRegex);
    if (youtubeResult) {
      setVideoPlatform('Youtube');
      setVideoId(youtubeResult[6]);
      return;
    }

    const vimeoResult = url.match(vimeoRegex);
    if (vimeoResult) {
      setVideoPlatform('Vimeo');
      setVideoId(vimeoResult[4]);
    }
  };

  const deleteLesson = async () => {
    try {
      await client.delete(`/learning_system_items/${lessonId}`);
      toast.success(t('toast.successDeleteLesson'));
      onSuccess();
    } catch (e) {
      toast.error(t('toast.errorDeleteLesson'));
    }
  };

  const handleDelete = lessonId => {
    Swal.fire({
      title: t('projects.warningDeleteLesson'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteLesson(lessonId);
      }
    });
  };

  const updateLesson = async () => {
    const { error } = await client.mutate(['/learning_system_items', lessonId], {
      unrequired: !requiredLesson
    });
    if (error) {
      toast.error('Erro ao atualizar obrigatoriedade da aula');
    } else {
      if (!requiredLesson === true) {
        toast.success(t('toast.successSetLessonNonMandatory'));
      } else {
        toast.success(t('toast.successSetLessonMandatory'));
      }
    }
  };

  return (
    <div className="card card--h">
      <div className="card__header">
        <img
          src={dragAndDrop}
          alt="ícone de arrastar"
          style={{
            width: '10px',
            cursor: 'move',
            cursor: 'grab',
            marginRight: '12px'
          }}
        />

        <div className="card__video">
          {videoPlatform && videoPlatform === 'Youtube' && <iframe src={`https://www.youtube.com/embed/${videoId}`} />}
          {videoPlatform && videoPlatform === 'Vimeo' && <iframe src={`https://player.vimeo.com/video/${videoId}`} />}
        </div>
      </div>

      <div style={{ flexGrow: '1' }}>
        <div className="card__inner-header">
          <h3
            className="card__title"
            title={title}
          >
            {title}
          </h3>
          <Dropdown>
            <Dropdown.Toggle>
              <FiMoreHorizontal />
            </Dropdown.Toggle>

            <Dropdown.Content>
              <Dropdown.Item onClick={() => navigate(`/lms/criar/aula/${disciplineId}/nome/${lessonId}`, { state: { disciplineId: selectedDisciplineId, moduleId: selectedModuleId, editLesson: true } })}>{t('button.edit')}</Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  handleDelete(lessonId);
                }}
              >
                {t('button.delete')}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  navigate(`/show-course/${learningSystemId}/classroom/${lessonId}`);
                }}
              >
                {t('button.view')}
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </div>
        <div className="card__switcher">
          <div className="form__check form__switch">
            <input
              className="form__check-input"
              id="requiredLesson"
              name="requiredLesson"
              type="checkbox"
              checked={requiredLesson}
              onClick={() => {
                setRequiredLesson(!requiredLesson);
                updateLesson();
              }}
            />
            <label> {t('textsCommon.mandatoryLesson')}</label>
          </div>
        </div>

        {/* <div className="card__badges card__badges--one-line">
          {taxonomies.slice(0, 3).map(item => {
            return (
              <span
                className="badge badge--primary"
                title={item.name}
              >
                {item.name}
              </span>
            );
          })}
        </div> */}

        <div className="card__columns card__columns--h u-mb-0">
          <p
            className="card__count"
            title={activities + ' atividades'}
            aria-label={activities}
          >
            <FiEdit3 className="card__icon" /> {activities}
          </p>
          <p
            className="card__count"
            title={messageCount + ' mensagens'}
            aria-label={messageCount}
          >
            <FiMessageCircle className="card__icon" /> {messageCount}
          </p>
          <p
            className="card__count"
            title={files + ' materiais'}
            aria-label={files}
          >
            <FiFolder className="card__icon" /> {files}
          </p>
          <p
            className="card__count"
            title={questions + ' cadernos de questões'}
            aria-label={questions}
          >
            <FiCheckCircle className="card__icon" /> {questions}
          </p>
          <p
            className="card__count"
            title={quizItemsCount + ' quizzes em tempo real'}
            aria-label={quizItemsCount}
          >
            <MdOutlineQuiz className="card__icon" /> {quizItemsCount}
          </p>
        </div>
      </div>
    </div>
  );
}
