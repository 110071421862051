import register from './img/register.svg';
import content from './img/content.svg';
import notification from './img/notification.svg';
import date from './img/date.svg';
import select from './img/select.svg';
import add from './img/add.svg';
import data from './img/data.svg';
import project from './img/project.svg';
import processed from './img/processed.svg';
import discipline from './img/discipline.svg';
import disapproved from './img/disapproved.svg';
import selectCard from './img/selectCard.svg';

import { useTranslation } from 'react-i18next';

function EmptyState(props) {
  const { t } = useTranslation();
  const { type, title, text, bgless, cta } = props;

  function getImage(type) {
    switch (type) {
      case 'register':
        return register;

      case 'content':
        return content;

      case 'notification':
        return notification;

      case 'date':
        return date;

      case 'select':
        return select;

      case 'add':
        return add;

      case 'data':
        return data;

      case 'project':
        return project;

      case 'processed':
        return processed;

      case 'discipline':
        return discipline;

      case 'disapproved':
        return disapproved;

      case 'selectCard':
        return selectCard;

      default:
        break;
    }
  }

  function getText(type) {
    switch (type) {
      case 'register':
        return t('emptyState.textRegister');

      case 'content':
        return t('emptyState.textContent');

      case 'notification':
        return t('emptyState.textNotification');

      case 'date':
        return t('emptyState.textDate');

      case 'select':
        return t('emptyState.textSelect');

      case 'add':
        return t('emptyState.textAdd');

      case 'data':
        return t('emptyState.textData');

      case 'project':
        return t('emptyState.textProject');

      case 'processed':
        return t('emptyState.textProcessed');

      case 'discipline':
        return t('emptyState.textDiscipline');

      default:
        return t('emptyState.textDefault');
    }
  }

  return (
    <div className={bgless ? 'empty-state empty-state--transparent' : 'empty-state'}>
      {type && (
        <img
          className="empty-state__icon"
          alt=""
          src={getImage(type)}
        />
      )}

      {title && <h3 className="empty-state__title">{title}</h3>}

      <p className="empty-state__text">{text || getText(type)}</p>
      {type === 'data' && !text && <p className="empty-state__text">{t('emptyState.textData2')}</p>}

      {cta && (
        <button
          type="button"
          className="btn btn--wide btn--outline u-mt-2"
          onClick={cta?.onClick}
        >
          {cta?.text}
        </button>
      )}
    </div>
  );
}

export default EmptyState;
