import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import EmptyState from 'app/components/EmptyState';
import ChartPerformanceCard from './chartPerformanceCard';
import { useTranslation } from 'react-i18next';

function MetricsJourney({ userId, user }) {
  const { t } = useTranslation();
  const client = useClient();
  const [timeFilterSelected, setTimeFilterSeleted] = useState(1);
  const [taxonomies, setTaxonomies] = useState([]);
  const [topics, setTopics] = useState([]);
  const [subTopics, setSubTopics] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData('taxonomies');
  }, [timeFilterSelected]);

  const fetchData = async (list, taxonomyParentId) => {
    setLoading(true);
    try {
      const taxonomyFilter = taxonomyParentId
        ? `&taxonomy_parent_id=${taxonomyParentId}`
        : '';
      const { data } = await client.fetch(
        `users/${userId}/metrics?week=${timeFilterSelected}${taxonomyFilter}`,
      );
      if (list === 'topics') {
        setTopics(data.data);
        setSubTopics([]);
      } else if (list === 'subTopics') {
        setSubTopics(data.data);
      } else {
        setTaxonomies(data.data);
        setTopics([]);
        setSubTopics([]);
      }

      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const selectTaxonomy = (taxonomy, currentList, nextList) => {
    setTaxonomies([...taxonomies]);
    if (nextList === 'subTopics') {
      topics.map(t => (t.isActive = false));
    } else if (nextList === 'topics') {
      taxonomies.map(t => (t.isActive = false));
    } else {
      subTopics.map(t => (t.isActive = false));
    }

    taxonomy.isActive = true;
    fetchData(nextList, taxonomy.id);
  };

  return (
    <div className="matter-performance">
      <header className="matter-performance__header">
        <h1 className="matter-performance__title">Rendimento por assunto</h1>
        <p className="matter-performance__subtitle">
         asdasdsa
        </p>
      </header>

      <div className="matter-performance__body">
        <div
          className="btn-group"
          role="group"
          aria-label="Escolha o tipo de evento"
        >
          <input
            type="radio"
            name="timeFilter"
            id="ultima-semana"
            autoComplete="off"
            checked={timeFilterSelected === 1}
            onChange={() => setTimeFilterSeleted(1)}
          />
          <label htmlFor="ultima-semana" className="btn btn--outline">
            {t("journey.lastWeek")}
          </label>

          <input
            type="radio"
            name="timeFilter"
            id="ultimo-mes"
            autoComplete="off"
            checked={timeFilterSelected === 4}
            onChange={() => setTimeFilterSeleted(4)}
          />
          <label htmlFor="ultimo-mes" className="btn btn--outline">
            {t("journey.lastmonth")}
          </label>

          <input
            type="radio"
            name="timeFilter"
            id="tres-meses"
            autoComplete="off"
            checked={timeFilterSelected === 12}
            onChange={() => setTimeFilterSeleted(12)}
          />
          <label htmlFor="tres-meses" className="btn btn--outline">
            {t("journey.threeMonth")}
          </label>
        </div>

        <div className="matter-performance__container">
          <div className="matter-performance__col">
            {taxonomies &&
              taxonomies.map((taxonomy, index) => (
                <ChartPerformanceCard
                  key={index}
                  taxonomy={taxonomy}
                  onClick={() =>
                    selectTaxonomy(taxonomy, 'taxonomies', 'topics')
                  }
                />
              ))}
          </div>

          <div className="matter-performance__col">
            {topics &&
              topics.map((taxonomy, index) => (
                <ChartPerformanceCard
                  key={index}
                  taxonomy={taxonomy}
                  onClick={() =>
                    selectTaxonomy(taxonomy, 'topics', 'subTopics')
                  }
                />
              ))}

            {!loading && topics.length === 0 && (
              <EmptyState type="select" text={t("emptyState.selectTopicText")} />
            )}
          </div>

          <div className="matter-performance__col">
            {subTopics &&
              subTopics.map((taxonomy, index) => (
                <ChartPerformanceCard key={index} taxonomy={taxonomy} />
              ))}

            {!loading && subTopics.length === 0 && (
              <EmptyState type="select" text={t("emptyState.selectSubtopicText")} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MetricsJourney;
