import React from 'react';
import devices from './img/devices.svg';
import hat from './img/hat.svg';
import monitor from './img/monitor.svg';
import peopleTalking from './img/people-talking.svg';

export default function KpiIcon(props) {
  const { index } = props;

  let src;

  switch (index) {
    case 0:
      src = hat;
      break;
    case 1:
      src = peopleTalking;
      break;
    case 2:
      src = devices;
      break;
    case 3:
      src = monitor;
      break;

    default:
      src = 'https://placehold.co/50x36';
      break;
  }

  return (
    <img
      className="report-kpi__icon"
      src={src}
      alt="ícone"
    />
  );
}
