import React from 'react';
import { Link } from '@reach/router';
import { t } from 'i18next';

export default function TabNavBar({ selectedTab }) {
  return (
    <div className="tab">
      <Link
        className={selectedTab === 'curso' ? 'tab__link active' : 'tab__link'}
        to="/curso"
      >
        {t('courses.toggleMyCourses')}
      </Link>
      {/* <Link
        className={selectedTab === 'recomendado' ? 'tab__link active' : 'tab__link'}
        to="/curso/recomendado"
      >
        Recomendados
      </Link> */}
      <Link
        className={selectedTab === 'certificados' ? 'tab__link active' : 'tab__link'}
        to="/curso/certificado"
      >
        {t('lms.toggleCertifications')}
      </Link>
    </div>
  );
}
