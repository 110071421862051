import { useTranslation } from 'react-i18next';
import React from 'react';

function TabNavBar({ activeTabs, setActiveTabs }) {
  const { t } = useTranslation();
  return (
    <div className="tab">
      <button
        className={`tab__link ${activeTabs === 'forum' ? 'active' : ''}`}
        onClick={() => setActiveTabs('forum')}
      >
        Fórum
      </button>
      <button
        className={`tab__link ${activeTabs === 'material' ? 'active' : ''}`}
        onClick={() => setActiveTabs('material')}
      >
        Materiais
      </button>
      <button
        className={`tab__link ${activeTabs === 'question' ? 'active' : ''}`}
        onClick={() => setActiveTabs('question')}
      >
        {t('textsCommon.questions')}
      </button>
      <button
        className={`tab__link ${activeTabs === 'activity' ? 'active' : ''}`}
        onClick={() => setActiveTabs('activity')}
      >
        Atividades complementares
      </button>
    </div>
  );
}

export default TabNavBar;
