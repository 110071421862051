import React from 'react';
import { t } from 'i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

function TabNavBar({ selectedTab, setSelectedTab, questionbooksCount }) {
  const { abilities } = usePlatformAbilities();

  return (
    <div className="tab">
      <button
        className={selectedTab === 'aulas' ? 'tab__link active' : 'tab__link'}
        onClick={() => setSelectedTab('aulas')}
      >
        Aulas
      </button>

      {abilities?.['cursos-materiais'] && (
        <button
          className={selectedTab === 'arquivos' ? 'tab__link active' : 'tab__link'}
          onClick={() => setSelectedTab('arquivos')}
        >
          {t('courses.toggleFiles')}
        </button>
      )}

      {abilities?.['cursos-ativ-complementar'] && (
        <button
          className={selectedTab === 'atividades' ? 'tab__link active' : 'tab__link'}
          onClick={() => setSelectedTab('atividades')}
        >
          Atividades
        </button>
      )}
      {questionbooksCount > 0 && abilities?.['avaliacoes-apostilas'] && (
        <button
          className={selectedTab === 'apostilas' ? 'tab__link active' : 'tab__link'}
          onClick={() => setSelectedTab('apostilas')}
        >
          {t('courses.toggleQuestionBooks')}
        </button>
      )}
    </div>
  );
}

export default TabNavBar;
