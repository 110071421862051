import React from 'react';
import PositionsPieChart from './PositionsPieChart';
import { ReportLegendWrapper } from 'app/components/ReportLegend';

export default function PieChartWidget(props) {
  const { setSelectedPositions } = props;

  return (
    <section className="report__section">
      <div className="report-widget">
        <div className="report-widget__header">
          <h3 className="report-widget__title">Cargos que realizaram o nivelamento</h3>
        </div>

        <div className="report-widget__body">
          <PositionsPieChart
            data={[
              {
                value: 1,
                name: 'Diretor',
                y: 21.3
              },
              {
                value: 2,
                name: 'Diretor de área',
                y: 18.7
              },
              {
                value: 3,
                name: 'Desenvolvedor',
                y: 20.2
              },
              {
                value: 4,
                name: 'Analista de produto',
                y: 14.2
              },
              {
                value: 5,
                name: 'Contador',
                y: 25.6
              }
            ]}
            setSelectedPositions={setSelectedPositions}
          />
        </div>
      </div>

      <ReportLegendWrapper />
    </section>
  );
}
