import React from 'react';

export default function InstructionWidget(props) {
  const { data } = props;
  const appname = process.env.REACT_APP_NAME;

  return (
    <div className="instruction-widget">
      <div className="instruction-widget__header">
        <span className="instruction-widget__number">{data?.number}</span>
        <div className="instruction-widget__info">
          <h3 className="instruction-widget__title">{data?.title}</h3>
          {data?.type === 'list' ? (
            <ul style={{ listStyle: 'outside' }}>
              {data?.description?.map(item => (
                <li>{item}</li>
              ))}
            </ul>
          ) : (
            <p className="instruction-widget__description">{data?.description}</p>
          )}
          {data?.cta && (
            <div className="instruction-widget__footer">
              <a
                href={appname === 'canaleducacao' ? '/folha_de_redacao_laboratorio.pdf' : '/redação.pdf'}
                download={appname === 'canaleducacao' ? '/folha_de_redacao_laboratorio.pdf' : '/redação.pdf'}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn--small btn--outline"
              >
                {data.cta.text}
              </a>
            </div>
          )}
        </div>
      </div>

      {data?.img && (
        <div className="instruction-widget__body">
          <img
            className="instruction-widget__img"
            src={data?.img}
            alt=""
          />
        </div>
      )}
    </div>
  );
}
