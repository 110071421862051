import React, { useEffect, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';
import GroupedQuestion from 'app/components/groupedQuestion';
import { useClient } from 'jsonapi-react';
import Loader from 'app/components/loader';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function CommentQuestion(props) {
  const { uri } = props;

  const { questionId } = useParams();
  const client = useClient();
  const [question, setQuestion] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getQuestion = async id => {
      const { data } = await client.fetch(`/questions/${id}?with_comments=true`);
      setQuestion(data);
    };
    getQuestion(questionId);
    setLoading(false);
  }, [questionId, loading]);

  if (loading || !question) {
    return <Loader />;
  }

  const breadCrumbs = {
    title: 'Ferramentas de Avaliação',
    nav: [
      {
        route: '/avaliacoes/banco-de-questoes',
        name: 'Banco de Questões',
        isActive: false
      },
      {
        route: uri,
        name: `Q${questionId} ${question?.code}`,
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <div
        className="main-content"
        style={{ marginLeft: '0', width: '100%', paddingTop: '0' }}
      >
        <div className="question-list">
          <GroupedQuestion
            groupedQuestion={[question]}
            taxonomyIds={question?.taxonomies.map(item => item?.id)}
          />
        </div>

        <aside className="questions-options" />
      </div>
    </main>
  );
}
