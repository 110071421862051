import { useSession } from '../hooks/useSession';

export default function getAuthorityLevel() {
  const { session } = useSession();
  const profiles =
    session?.user?.profileNames ||
    (session?.user && session?.user['profile-names']) ||
    [];

  let level = '';

  profiles.map(profile => {
    if (
      [
        'Administrador',
        '4 secretariado',
        'Professor',
        'Tutor',
        'Designer Institucional',
      ].includes(profile)
    ) {
      level = 'admin';
    } else if (['Aluno'].includes(profile)) {
      level = 'student';
    }
  });

  return level;
}

export const getAuthorityLevels = () => {
  const { session } = useSession();
  const profiles =
    session?.user?.profileNames ||
    (session?.user && session?.user['profile-names']) ||
    [];

  let levels = [];
  if (profiles.includes('Aluno')) {
    levels.push('student');
  }
  if (profiles.includes('Professor')) {
    levels.push('professor');
  }
  if (profiles.includes('Administrador')) {
    levels.push('admin');
  }
  if (profiles.includes('Tutor')) {
    levels.push('tutor');
  }
  if (profiles.includes('Designer Institucional')) {
    levels.push('designer');
  }

  return levels;

};

export const checkIncludeProfiles = ({ only = [], except = [] }) => {
  const { session } = useSession();
  const authorityLevels = getAuthorityLevels(session);

  const commonProfiles = authorityLevels.filter(l => only.includes(l));
  const exceptProfiles = authorityLevels.filter(l => except.includes(l));

  return commonProfiles.length > 0 && exceptProfiles.length === 0;
};

export const checkIncludeAppName = ({ only = [], except = [] }) => {
  const appName = process.env.REACT_APP_NAME;

  const commonAppName = only.includes(appName);
  const exceptAppName = !except.includes(appName);

  return commonAppName && exceptAppName;
};
