import { Link } from '@reach/router';
import GameIcon from 'app/images/icons/game';
import SimulationIcon from 'app/images/icons/simulation-icon';
import { FiClipboard, FiInfo } from 'react-icons/fi';
import { FiStar } from 'react-icons/fi';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Navigation } from 'swiper/modules';

export default function Disclaimer({ alerts }) {
  const icon = type => {
    switch (type) {
      case 'challenge':
        return <GameIcon />;
      case 'project':
        return <FiClipboard />;
      case 'survey':
        return <FiStar />;
      case 'exam':
        return <SimulationIcon />;
      case 'info':
        return <FiInfo />;
    }
  };

  return (
    <Swiper
      className="disclaimer-container"
      pagination={{ clickable: true }}
      modules={[Pagination, A11y]}
      spaceBetween={10}
      slidesPerView={1.5}
    >
      {alerts.map((item, i) => {
        if (item?.['access_url'])
          return (
            <SwiperSlide key={i}>
              <Link
                to={item.type === 'challenge' ? '/desafio-da-semana/desafios' : item?.['access_url'] ? item?.['access_url'] : '/'}
                className="disclaimer-wrapper u-mb-3"
              >
                <span
                  className="shortcut-widget__icon icon"
                  style={{ width: 30, height: 30 }}
                >
                  {icon(item.type)}
                </span>

                <div className="content">
                  <span className="title">{item?.name}</span>
                  <span alt={item?.description}>{item?.description}</span>
                </div>
                <span className="btn btn--link">Acessar</span>
              </Link>
            </SwiperSlide>
          );
      })}
    </Swiper>
  );
}
