import { useParams } from '@reach/router';
import React from 'react';
import { Link } from '@reach/router';
import { FiEdit3 } from 'react-icons/fi';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { t } from 'i18next';

export default function ActivityCard({ activity, isNotFinished, idCourse }) {
  const authorityLevel = getAuthorityLevel();
  const { courseId } = useParams();

  return (
    <tr className="round-dg__row">
      <td
        className="round-dg__cell-header "
        style={{ width: '100%' }}
      >
        <div className="card__header u-mb-0">
          <div className="card__inner-header">
            <div className="card__icon-wrapper">
              <FiEdit3 />
            </div>
            <h2
              className="card__title"
              title={'Atividade complementar'}
              style={{ flexGrow: 'initial' }}
            >
              {activity?.title}
            </h2>
          </div>
        </div>
      </td>

      <td className="round-dg__cell ">
        {activity?.status === 'Aguardando envio' || activity?.status === 'Activity not submitted' ? <span className="badge badge--tiny badge--warning">{activity?.status} </span> : null}
        {activity?.status === 'Atividade não enviada' || activity?.status === 'Awaiting submission' ? <span className="badge badge--tiny badge--danger">{activity?.status} </span> : null}
        {activity?.status === 'Atividade enviada' || activity?.status === 'Activity evaluated' ? <span className="badge badge--tiny badge--info">{activity?.status} </span> : null}
        {activity?.status === 'Atividade avaliada' || activity?.status === 'Activity submitted' ? <span className="badge badge--tiny badge--info">{activity?.status} </span> : null}
      </td>

      <td className="round-dg__cell last-cell">
        {!isNotFinished && (
          <Link
            className="btn btn--outline btn--small btn--wide"
            to={authorityLevel === 'student' ? `/atividade-complementar/atividade/${activity?.id}` : `/atividade-complementar/${activity?.id}/${courseId ?? idCourse}`}
          >
            {t('button.access')}
          </Link>
        )}
      </td>
    </tr>
  );
}
