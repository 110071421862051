import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import selectCoursePng from '../../images/onboarding/courses/select-course.png';
import viewCertificatesPng from '../../images/onboarding/courses/view-certificates.png';
import filterCoursePng from '../../images/onboarding/courses/filter-course.png';
import overviewCourseCardPng from '../../images/onboarding/courses/overview-course-card.png';
import aboutCoursePng from '../../images/onboarding/courses/about-course.png';
import aboutCourse2Png from '../../images/onboarding/courses/aboutCourse2.png';
import aboutCourse3Png from '../../images/onboarding/courses/aboutCourse3.png';
import togglePng from '../../images/onboarding/courses/toggle.png';
import toggle2Png from '../../images/onboarding/courses/toggle2.png';
import accessingLessonsPng from '../../images/onboarding/courses/accessing-lessons.png';
import informationSvg from '../../images/onboarding/courses/icon-information.svg';

export function CourseOnboarding() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.selectCourseOrCertificates')}</h1>
        <p className="text-option-onboarding">{t('onboarding.selectCourseOrCertificatesDescription')}</p>

        <img
          src={selectCoursePng}
          className="w-auto"
        />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.viewYourCertificates')}</h1>
        <p className="text-option-onboarding">{t('onboarding.viewYourCertificatesDescription')}</p>

        <img src={viewCertificatesPng} />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.filterAndCourseSelect')}</h1>
        <p className="text-option-onboarding">{t('onboarding.filterAndCourseSelectDescription')}</p>

        <ShadowContainer width="fit-content">
          <img src={filterCoursePng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.courseCardsOverview')}</h1>
        <p className="text-option-onboarding">{t('onboarding.courseCardsOverviewDescription')}</p>

        <div style={{ width: 415 }}>
          <img src={overviewCourseCardPng} />
        </div>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.aboutCourse')}</h1>
        <p className="text-option-onboarding"> {t('onboarding.onSelectCourse')} </p>
        <img src={aboutCoursePng} />
        <p className="text-option-onboarding"> {t('onboarding.aboutCourseDescription')} </p>
        <div style={{ width: 220 }}>
          <img src={togglePng} />
        </div>
        <div style={{ width: 500 }}>
          <img src={aboutCourse2Png} />
        </div>
        <p className="text-option-onboarding">{t('onboarding.aboutCourseTwoDescription')}</p>
        <div style={{ width: 220 }}>
          <img src={toggle2Png} />
        </div>
        <div style={{ width: 550 }}>
          <img src={aboutCourse3Png} />
        </div>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.accessingAndViewingLessons')}</h1>
        <p className="text-option-onboarding mb-0"> {t('onboarding.accessingAndViewingLessonsDesc')} </p>

        <img src={accessingLessonsPng} />
      </div>

      <div className="container-pay-attention">
        <div className="bg-icon-pay-attention">
          <img
            src={informationSvg}
            className="info-icon"
          />
        </div>

        <div className="text-option-onboarding">
          <h1 className="text-option-onboarding m-0">
            <b>{t('onboarding.payAttentionToTheRequirements')}</b>
          </h1>
          <p className="text-option-onboarding m-0">{t('onboarding.payAttentionToTheRequirementsDescription')}</p>
        </div>
      </div>
    </div>
  );
}
