import React, { useState, useEffect } from 'react';
import TabNavBar from './TabNavBar';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { FiAlertCircle } from 'react-icons/fi';
import Calendar from 'app/components/Calendar';
import toast from 'react-hot-toast';
import Select from 'react-select';
import EmptyState from 'app/components/EmptyState';
import { useAgenda } from 'app/hooks/useAgenda';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function ManageByStudent(props) {
  const { t } = useTranslation();
  const { selectedTab, uri } = props;
  const { currentSelectedWeek } = useAgenda();

  const [relationships, setRelationships] = useState({
    students: []
  });
  const [limitAlertMessage, setLimitAlertMessage] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const StudentTasks = true;
  const client = useClient();

  const filters = useFormik({
    initialValues: {
      student: 'blank'
    }
  });

  const getRelationships = async () => {
    try {
      const { data: students } = await client.fetch(`users?filter[profile]=Aluno`);

      setRelationships({
        students: students || []
      });
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetParticipants'));
    }
  };

  useEffect(() => {
    getRelationships();
  }, []);

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: uri,
        name: 'Tarefas',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <TabNavBar selectedTab={selectedTab} />

      <div className="tab__pane">
        <div className="filter-bar">
          <div className="filter-bar__row">
            <label className="filter-bar__label">{t('filter.labelSearchStudent')}</label>
            <Select
              openMenuOnFocus
              defaultValue={filters.values.student}
              options={relationships.students.map(user => ({
                value: user,
                label: user.name
              }))}
              className="react-multi-select filter-bar__multi-select"
              classNamePrefix="react-multi-select"
              placeholder={t('filter.placeholderStudentName')}
              noOptionsMessage={() => 'Sem opções'}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              onChange={e => {
                filters.setFieldValue('student', e.value);
                setSelectedUser(e.value);
              }}
              style={{ width: '300px' }}
            />
          </div>

          {limitAlertMessage && (
            <div
              className="filter-bar__box u-ml-auto fadeIn"
              style={{ width: '412px' }}
            >
              <span className="filter-bar__icon">
                <FiAlertCircle />
              </span>
              <span>
                <strong>Atenção! {selectedUser} excedeu o limite de 8h diárias de tarefas</strong> na semana de {currentSelectedWeek.sunday.format('DD/MM')} a {currentSelectedWeek.saturday.format(t('datesForm.formatDate'))}. Remaneje tarefas clicando
                na tarefa e arrastando para o lugar desejado.
              </span>
            </div>
          )}
        </div>

        {filters.values.student === 'blank' && (
          <EmptyState
            type="select"
            title={t('emptyState.selectOption')}
            text={t('emptyState.toStartViewingData')}
          />
        )}

        {filters.values.student !== 'blank' && (
          <div className="home-content">
            <Calendar.Expanded
              slotType="slot"
              StudentTasks={StudentTasks}
              selectedTab={selectedTab}
              setLimitAlertMessage={setLimitAlertMessage}
              title
              excessTasks={limitAlertMessage === true}
              selectedUser={selectedUser}
              filters={filters}
            />
          </div>
        )}
      </div>
    </main>
  );
}
