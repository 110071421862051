import React from 'react';
import {
  FiMoreHorizontal,
  FiFileText,
  FiHeadphones,
  FiVideo,
} from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import { useTranslation } from 'react-i18next';

export default function MaterialCard(props) {
  const { t } = useTranslation();
  const { deleteMaterial, showMaterial, item, editMaterial } = props;

  function icon(type) {
    switch (type) {
      case 'Áudio':
        return <FiHeadphones className="card__icon" />;
      case 'Vídeo':
        return <FiVideo className="card__icon" />;
      default:
        return <FiFileText className="card__icon" />;
    }
  }

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <header className="card__header">
          <div className="card__inner-header">
            <div className="card__icon-wrapper">{icon('Áudio')}</div>
            <h2 className="card__title">{item.title}</h2>
            {/* <p className="card__meta">2.6 MB</p> */}
          </div>
        </header>
      </th>

      <td className="round-dg__cell">
        <div className="card__badges">
          {item.taxonomies.slice(0, 4).map(t => {
            return (
              <span className="badge badge--primary" title={`Fonemas`}>
                {t.name}
              </span>
            );
          })}

          {item.taxonomies.length > 4 ? (
            <span
              className="badge badge--more"
              title={`mais ${item.taxonomies.length - 4} taxonomias`}
            >
              +{item.taxonomies.length - 4}
            </span>
          ) : null}
        </div>
      </td>

      <td className="round-dg__cell" style={{ width: '200px' }}>
        <a
          href={item.url ? item.url : item.file.url}
          target="_blank"
          className="btn btn--outline btn--small btn--full"
        >
          {t('button.view')}
        </a>
      </td>

      <td className="round-dg__cell" style={{ width: '66px' }}>
        <Dropdown>
          <Dropdown.Toggle>
            <FiMoreHorizontal />
          </Dropdown.Toggle>
          <Dropdown.Content>
            <Dropdown.Item onClick={() => editMaterial(item)}>
            {t("button.edit")}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => deleteMaterial(item.id)}>
            {t('button.delete')}
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown>
      </td>
    </tr>
  );
}
