import { useTranslation } from 'react-i18next';

function Spinner(props) {
  const { text = 'Gerando o relatório' } = props;

  return (
    <div className="loader-spinner-wrapper">
      {text && <p className="loader-spinner-wrapper__text">{text}</p>}
      <div className="loader-spinner" />
    </div>
  );
}

function Loader() {
  const { t } = useTranslation();
  return (
    <div className="loader-container">
      <div className="loader"></div>
      <div className="loader-text">{t('loader.loading')}</div>
    </div>
  );
}

Loader.Spinner = Spinner;

export default Loader;
