import React, { useState, useEffect } from 'react';
import AuditoriumSidebar from './AuditoriumSidebar';
import AuditoriumContent from './AuditoriumContent';
import { useParams } from '@reach/router';
import { useAuditorium } from 'app/hooks/useAuditorium';
import Loader from 'app/components/loader';
import { t } from 'i18next';

export default function CreateAuditorium(props) {
  const { getAuditorium, auditorium } = useAuditorium();
  const { step } = props;
  const { id } = useParams();

  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    {
      id: 0,
      route: 'nome',
      text: t('auditorium.stepName')
    },
    {
      id: 1,
      route: 'escola',
      text: t('auditorium.stepSchool')
    }
  ];

  function getStep() {
    switch (step) {
      case 'nome':
        setCurrentStep(0);
        break;
      case 'escola':
        setCurrentStep(1);
        break;
      default:
        setCurrentStep(0);
        break;
    }
  }

  useEffect(() => {
    if (id) {
      getAuditorium(id);
    }
  }, [id]);

  useEffect(() => getStep());

  return (
    <div className="main-screen">
      <AuditoriumSidebar
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
      />

      <main className="main-content">
        {!id ? (
          <AuditoriumContent
            step={step}
            steps={steps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        ) : auditorium?.id === parseInt(id) ? (
          <AuditoriumContent
            step={step}
            steps={steps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            id={id}
          />
        ) : (
          <Loader />
        )}
      </main>
    </div>
  );
}
