import React, { useState } from 'react';
import { Link } from '@reach/router';
import { useSession } from '../hooks/useSession';
import { useMutation } from 'jsonapi-react';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

function Register() {
  const { t } = useTranslation();
  const { session, setSession, sanitizeResponse } = useSession();
  const [addUser] = useMutation('users');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState({
    remoteImageUrl: null,
    slug: null,
    canReceiveNewsletter: false
  });

  if (session && Object.keys(session).length !== 0) {
    window.location.pathname = '/simulados';
  }

  const registerUser = e => {
    e.preventDefault();

    if (agreeTerms && values.password === values.passwordConfirmation) {
      addUser(values)
        .then(response => {
          if (response.error) {
            toast.error(response.error.title);
          } else {
            const newObject = sanitizeResponse(response.data);
            setSession({ user: newObject });
          }
        })
        .catch(error => {
          toast.error(t('toast.error'));
          console.error(error);
        });
    } else {
      if (agreeTerms) {
        setValues({ ...values, password: '', passwordConfirmation: '' });
        toast.error('Senha e confirmação de senha não são iguais');
      } else {
        toast.error('Você precisa concordar com os termos de uso');
      }
    }
  };

  const handleChange = e => {
    let auxValues = values;

    auxValues[e.target.name] = e.target.name === 'sendPhoneNotifications' ? !auxValues[e.target.name] : e.target.value;
    setValues({ ...auxValues });
  };

  const appName = process.env.REACT_APP_NAME;
  const logo = require(`app/images/${appName}/white-logo.svg`).default;

  return (
    <div className="register">
      <div className="register__body">
        <div className="register__brand">
          <img
            alt="Logo"
            src={logo}
          />
        </div>
        {/* <div className="register__list">
          <div className="register__item">
            <div className="register__title">Ganhe confiança</div>
            <div className="register__description">
              Chegue na prova com o preparo certo, confiante de que vai se
              tornar um profissional qualificado!
            </div>
          </div>
          <div className="register__item">
            <div className="register__title">Matenha ritmo</div>
            <div className="register__description">
              Mais de 2500 questões de provas anteriores e inéditas, para você
              se preparar todos os dias, no seu ritmo.
            </div>
          </div>
          <div className="register__item">
            <div className="register__title">Otimize tempo</div>
            <div className="register__description">
              Tudo que você precisa para treinar para a prova da CACD está aqui,
              em nosso banco de questões exclusivo!
            </div>
          </div>
        </div> */}
      </div>
      <div className="register__form">
        <h1>Crie sua conta</h1>
        <form
          className="form"
          method="post"
          onSubmit={e => registerUser(e)}
        >
          <div className="form__row">
            <label
              className="form__label"
              htmlFor="name"
            >
              Nome completo
            </label>
            <input
              className="form__control"
              type="text"
              name="name"
              id="name"
              onChange={handleChange}
            />
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="form__control"
              type="email"
              name="email"
              id="email"
              onChange={handleChange}
            />
          </div>
          <div className="form__row">
            <label
              className="form__label"
              htmlFor="phone"
            >
              {t('profile.labelPhone')}:
            </label>
            <InputMask
              className="form__control"
              mask="(99) 99999-9999"
              name="phone"
              id="phone"
              onChange={handleChange}
            />
          </div>
          {/* <div className="form__row">
            <label
              className="form__row back-login label-terms"
              htmlFor="sendPhoneNotifications"
            >
              <input
                type="radio"
                name="sendPhoneNotifications"
                id="sendPhoneNotifications"
                checked={values.sendPhoneNotifications}
                onClick={handleChange}
              />
              Quero receber notificações por SMS/WhatsApp
            </label>
          </div> */}

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="password"
            >
              Senha
            </label>
            <div className="form__row--password">
              <input
                className="form__control"
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                onChange={handleChange}
              />
              <span onClick={() => setShowPassword(!showPassword)}>{showPassword ? <FiEyeOff /> : <FiEye />}</span>
            </div>
          </div>
          <div className="form__row">
            <label className="form__label passwordConfirmation">Confirme a senha:</label>
            <input
              className="form__control"
              type={showPassword ? 'text' : 'password'}
              name="passwordConfirmation"
              id="passwordConfirmation"
              onChange={handleChange}
            />
          </div>

          <label
            className="form__row back-login label-terms"
            htmlFor="agreeTerms"
          >
            <input
              type="radio"
              name="agreeTerms"
              id="agreeTerms"
              checked={agreeTerms}
              onClick={() => setAgreeTerms(!agreeTerms)}
            />
            Ao clicar em "Criar conta", você concorda com os <Link to="/termos-de-uso">termos de uso</Link>.
          </label>
          <div className="form__row">
            <input
              type="submit"
              value="Criar conta"
            />
          </div>

          <div className="form__row back-login">
            Já tem uma conta? <Link to="/login">Acesse.</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
