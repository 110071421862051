import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useClient } from 'jsonapi-react';
import { useCourse } from 'app/hooks/useCourse';
import { FiChevronLeft } from 'react-icons/fi';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import InfoWidget from 'app/components/Widget/InfoWidget';
import ActivityDataPieChart from './ActivityDataPieChart';
import ActivityDataColumnChart from './ActivityDataColumnChart';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';

export default function CourseData(props) {
  const { t } = useTranslation();
  const { activity } = props;
  const client = useClient();
  const { courses, getSimpleCourses } = useCourse();
  const [isLoading, setIsLoading] = useState(true);
  const [currCourse, setCurrCourse] = useState();
  const [activityReport, setActivityReport] = useState();
  const [status, setStatus] = useState('');
  const [statusClass, setStatusClass] = useState('');

  useEffect(() => getSimpleCourses(), []);
  useEffect(() => {
    if (courses.length > 0) {
      setCurrCourse(courses[0]);
    }
  }, [courses]);

  useEffect(() => {
    updateActivityStatus();
    if (activity && currCourse) {
      getActivityReport();
    }
  }, [activity, currCourse]);

  const getActivityReport = async () => {
    setIsLoading(true);
    const { data } = await client.fetch(['activities', activity.id, `activities_report?filter[course_id]=${currCourse?.id}&course=true`]);
    setActivityReport(data);
    setIsLoading(false);
  };

  const updateActivityStatus = () => {
    let newStatus = t('textsCommon.available');
    let newStatusClass = 'activity-card__status--success';

    if (moment(activity['publish-at']) > moment()) {
      newStatus = t('textsCommon.unavailable');
      newStatusClass = 'activity-card__status--warning';
    }

    // else if (moment(activity['finish-at']) < moment()) {
    //   newStatus = 'Expirado';
    //   newStatusClass = 'activity-card__status--danger';
    // }

    setStatus(newStatus);
    setStatusClass(newStatusClass);
  };

  const changeCourse = e => {
    if (e.target.value !== 'blank') {
      const newCourse = courses.find(c => c.id === parseInt(e.target.value));
      setCurrCourse(newCourse);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="fadeIn">
      <div
        className="filter-bar"
        style={{ borderBottom: '0', marginBottom: '0' }}
      >
        <FilterSelectionBox
          label={t('filter.labelSelectCourse')}
          value={currCourse?.id}
          onChange={changeCourse}
          options={courses}
        />
      </div>
      <div
        className="filter-bar"
        style={{ alignItems: 'center' }}
      >
        <button
          onClick={() => navigate(-1)}
          className="filter-bar__back"
        >
          <span className="card__icon-wrapper">
            <FiChevronLeft className="card__icon" />
          </span>

          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{t('textsCommon.data')}</span>
          </span>
        </button>

        <span className="filter-bar__box u-ml-auto">
          {t('textsCommon.lastUpdateAt')} {activityReport?.lastUpdate && moment(activityReport.lastUpdate).format('DD/MM/YYYY H:mm')}h
        </span>
      </div>

      <div className="chart-widget u-mb-3">
        <div className="chart-widget__container">
          <div className="chart-widget__group">
            <div className="chart-widget__meta">
              <span className={`activity-card__status u-mr-2 ${statusClass}`}>{status}</span>
              {/* <span className="activity-card__available">
                {t('textsCommon.until')} {moment(activity['finish-at']).format(t('datesForm.formatDate'))}
              </span> */}
            </div>

            <div>
              <h4 className="chart-widget__title chart-widget__title--md">{currCourse?.title}</h4>
              <h3 className="chart-widget__title chart-widget__title--lg">{activity.title}</h3>
            </div>
          </div>
          <div className="widget-wrapper widget-wrapper--sm-space">
            <InfoWidget
              title={t('textsCommon.submissionIndex')}
              className="info-widget--light"
            >
              <InfoWidget.Value>{(activityReport?.percentActivitySend && Math.round(activityReport?.percentActivitySend)) || 0}%</InfoWidget.Value>
            </InfoWidget>

            <InfoWidget
              title={t('textsCommon.totalStudents')}
              className="info-widget--light"
            >
              <InfoWidget.Value>{activityReport?.studentsPerCourse || 0}</InfoWidget.Value>
            </InfoWidget>
          </div>
        </div>
      </div>

      <div className="chart-widget-wrapper">
        <div className="chart-widget">
          <div className="chart-widget__header">
            <h3 className="chart-widget__title">{t('activity.totalSubmissions')}</h3>
          </div>
          <div className="chart-widget__body">{isLoading ? <Loader /> : <ActivityDataPieChart activityReport={activityReport} />}</div>
        </div>

        <div className="chart-widget">
          <div className="chart-widget__header">
            <h3 className="chart-widget__title">{t('activity.comparisonTitleClasses')}</h3>
          </div>
          <div className="chart-widget__body">{isLoading ? <Loader /> : <ActivityDataColumnChart activityReport={activityReport} />}</div>
        </div>
      </div>
    </div>
  );
}
