import React, { useState, useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import { FiChevronLeft } from 'react-icons/fi';
import MainNav from 'app/components/mainNav';
import { FilterCheckBox } from 'app/components/FilterCheckBox';
import getPermission from 'app/utils/getPermission';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { ProjectsProvider } from '../../provider';
import { ProjectsContext } from '../../provider';
import ProjectsCard from '../../ProjectsCard';
import { t } from 'i18next';

export function ViewIndividualProject(props) {
  return (
    <ProjectsProvider>
      <ScreenContentViweIndividualProject {...props} />
    </ProjectsProvider>
  );
}

export function ScreenContentViweIndividualProject() {
  const { loading, projects, getProjects } = useContext(ProjectsContext);

  const filters = useFormik({
    initialValues: {
      approvedProjects: false,
      projectsInProgress: false
    }
  });

  useEffect(() => {
    getProjects(true);
  }, []);

  const currentProjects = projects
    ?.filter(p => {
      if (filters.values.approvedProjects) return p.approved;
      return p;
    })
    .filter(p => {
      if (filters.values.projectsInProgress) return !p.approved;
      return p;
    });

  return (
    <ProjectsProvider>
      <div className="main-screen">
        <MainNav />

        {getPermission('Visualizar lista de projetos', 'Projetos') && (
          <main className="main-content main-content--block">
            <button
              onClick={() => history.back()}
              className="filter-bar__back u-mb-3"
            >
              <div className="card__icon-wrapper">
                <FiChevronLeft />
              </div>

              <span className="filter-bar__back-container">
                <span className="filter-bar__title">Gestão Financeira Inovadora</span>
              </span>
            </button>

            <div className="filter-bar">
              <div className="filter-bar__inner">
                <FilterCheckBox
                  label={t('metrics.approvedProjects')}
                  value={filters.values.approvedProjects}
                  onChange={() => {
                    filters.setFieldValue('approvedProjects', !filters.values.approvedProjects);
                    filters.setFieldValue('projectsInProgress', false);
                  }}
                />
                <FilterCheckBox
                  label={t('metrics.projectsInProgress')}
                  value={filters.values.projectsInProgress}
                  onChange={() => {
                    filters.setFieldValue('projectsInProgress', !filters.values.projectsInProgress);
                    filters.setFieldValue('approvedProjects', false);
                  }}
                />
              </div>

              <div className="filter-bar__box">
                <span>
                  <strong>54 {t('dashboard.projects')}</strong>
                </span>
              </div>
            </div>

            {loading && <Loader />}

            {projects.length === 0 && !loading && (
              <EmptyState
                type="project"
                title={t('projects.newProjects')}
                text={t('emptyState.createNewProjectInteract')}
              />
            )}

            {!loading && (
              <div className="tab__cards">
                {currentProjects.map(p => (
                  <ProjectsCard
                    data={p}
                    key={p.id}
                  />
                ))}
              </div>
            )}
          </main>
        )}
      </div>
    </ProjectsProvider>
  );
}
