import React, { useEffect, useState } from 'react';
import { t } from 'i18next';

export default function TaxonomiesForm(props) {
  const { taxonomies, form } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTaxonomies, setFilteredTaxonomies] = useState([]);

  const addTaxonomy = t => {
    form.setFieldValue('taxonomyIds', [...form.values['taxonomyIds'], t.id]);
    form.setFieldValue('selectedTaxonomies', [...form.values.selectedTaxonomies, t]);
  };

  const removeTaxonomy = t => {
    const newTaxonomies = taxonomies.filter(a => a.id !== t.id);
    const newTaxonomiesIds = newTaxonomies.map(t => t.id);
    form.setFieldValue('taxonomyIds', newTaxonomiesIds);
    form.setFieldValue('selectedTaxonomies', newTaxonomies);
  };

  useEffect(() => {
    let newTaxonomies = taxonomies;
    if (searchTerm !== '') {
      newTaxonomies = taxonomies.filter(t => t.name != null && t.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
    }
    setFilteredTaxonomies(newTaxonomies);
  }, [taxonomies, searchTerm]);

  return (
    <>
      <h2 className="modal__simple-title">{t('textsCommon.matters')}</h2>
      <input
        style={{ position: 'sticky', top: '0' }}
        className="form__control u-mb-3"
        type="search"
        placeholder={t('exams.getSubject')}
        onChange={e => setSearchTerm(e.target.value)}
      />
      <div
        className="u-overflow-y"
        style={{
          minHeight: '56px',
          maxHeight: '70vh',
          padding: '16px',
          margin: '0 -16px 1.5em -16px'
        }}
      >
        {filteredTaxonomies.map(item => {
          const isSelected = form.values.selectedTaxonomies.find(s => s.id === item.id);
          return (
            <div className="card card--h u-mb-2">
              <div
                className="card__body"
                style={{ flexDirection: 'column', alignItems: 'flex-start' }}
              >
                <h3 className="card__title">{item.name}</h3>
                <h4 className="card__subtitle">
                {/* {typeof item?.["question-count"] === 'number' && `${item?.["question-count"]} ${t('exams.questions')}`} */}
                </h4>
              </div>
              <div className="card__footer">
                <button
                  onClick={() => {
                    if (isSelected) {
                      removeTaxonomy(item);
                    } else {
                      addTaxonomy(item);
                    }
                  }}
                  className={isSelected ? 'btn btn--outline btn--wide active' : 'btn btn--outline btn--wide'}
                >
                  {isSelected ? t('button.remove') : t('button.add')}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
