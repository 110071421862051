import React, { useEffect } from 'react';
import { FiLock, FiUnlock } from 'react-icons/fi';
import confetti from './img/confetti.svg';

export default function NavSection(props) {
  const { data, finished, setActiveSectionIndex, activeSectionIndex } = props;

  const getIndexOfClickedSection = (id) => {
    const index =  data.findIndex(s => s.id === id);
    setActiveSectionIndex(index);
  }

  useEffect(() => {
    const index = data.findIndex(s => s.isActive || !s.done);
    if (index === -1) setActiveSectionIndex(0);
    else setActiveSectionIndex(index);
  }, [data]);

  return (
    <ul className="nav-section">
      {data.map((section, index, arr) => {
        const lastItem = arr.length - 1;
        const active = activeSectionIndex

        return (
          <li
            className={ active === index ? 'nav-section__item nav-section__item--success' : section.isActive ? 'nav-section__item nav-section__item--is-active' : 'nav-section__item'}
            style={{ cursor: (section.done || index === data.findIndex(s => !s.done)) ? 'pointer' : 'default' }}
            key={index}
            onClick={() => {

              if (section.done || index === data.findIndex(s => !s.done)) getIndexOfClickedSection(section.id);
            }}
          >
            <span
              className="nav-section__icon"
              key={section.id}
            >
              {index === lastItem && finished ? (
                <img
                  src={confetti}
                  alt="confetes"
                />
              ) : section.isActive || section.done ? (
                <FiUnlock />
              ) : (
                <FiLock />
              )}
            </span>
            <h3
              className="nav-section__title"
              title={section.title}
            >
              {section.title === 'questions' ? 'Avaliação de conhecimento' : section.title}
            </h3>
            <input
              className="nav-section__check"
              type="checkbox"
              name="done"
              checked={index === lastItem && finished ? true : section.done}
              readOnly
            />
          </li>
        );
      })}
    </ul>
  );
}
