import React, { useState } from 'react';
import { FiThumbsUp, FiMessageCircle, FiFolder, FiVideo } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import Modal from 'app/components/Modal';
import StarRating from 'app/components/StarRating';
import EmptyState from 'app/components/EmptyState';
import moment from 'moment';
import IncomeBySubject from '../IncomeBySubjectWidget';
import UserInfo from 'app/components/UserInfo';
import getPermission from 'app/utils/getPermission';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

export default function StudentListWidget({ studentsList, classroomName, loading }) {
  const [activeStudent, setActiveStudent] = useState();
  const { abilities } = usePlatformAbilities();
  const { t } = useTranslation();

  return (
    <>
      <div className="widget">
        <div className="widget__header">
          <div className="widget__title-with-btn">
            <h3 className="widget__title">
              <div dangerouslySetInnerHTML={{ __html: t('exams.titleAsideBarSimulations', { currentCourseTitle: classroomName }) }}></div>
            </h3>
            <span className="widget__count">
              {studentsList?.length} <span>{t('metrics.student')}s</span>
            </span>
          </div>
        </div>

        <div className="widget__body">
          <table
            className="dg-widget"
            style={{ tableLayout: 'auto' }}
          >
            <thead className="dg-widget__header">
              <tr className="dg-widget__row">
                <th
                  className="dg-widget__cell-header"
                  scope="col"
                >
                  {t('metrics.student')}
                </th>
                <th
                  className="dg-widget__cell-header"
                  scope="col"
                >
                  {t('journey.thLastAccess')}
                </th>
                {getPermission('Visualizar dados e métricas', 'Questionário de Percepção') && abilities?.nps && (
                  <th
                    className="dg-widget__cell-header"
                    scope="col"
                  >
                    {t('metrics.perception')}
                  </th>
                )}

                <th
                  className="dg-widget__cell-header"
                  scope="col"
                >
                  {t('metrics.accessesLastWeek')}
                </th>
                <th
                  className="dg-widget__cell-header"
                  scope="col"
                >
                  {t('sideBar.metrics')}
                </th>
              </tr>
            </thead>

            <tbody className="dg-widget__body">
              {loading && <Loader />}
              {!loading && (studentsList || []).length == 0 ? (
                <EmptyState
                  type="register"
                  text="Nenhum estudante nessa turma"
                />
              ) : (
                (studentsList || []).map((item, index) => (
                  <tr
                    className="dg-widget__row"
                    key={index}
                  >
                    <th
                      scope="row"
                      className="dg-widget__cell-header"
                    >
                      <UserInfo user={item} />
                    </th>
                    <td className="dg-widget__cell">{item.lastAccessDate ? moment(item.lastAccessDate).format(t('datesForm.formatDate')) : '00-00-0000'}</td>
                    {getPermission('Visualizar dados e métricas', 'Questionário de Percepção') && abilities?.nps && (
                      <td className="dg-widget__cell">
                        <StarRating
                          staticRating
                          rating={item.perception}
                        />
                      </td>
                    )}

                    <td className="dg-widget__cell">
                      {item?.weeklyAccessCount} <span className={item?.weeklyAccessChangePercent?.toFixed(2) > 0 ? 'dg-widget__text--increment' : 'dg-widget__text--decrement'}>{/* {item?.weeklyAccessChangePercent?.toFixed(2)}% */}</span>
                    </td>
                    <td className="dg-widget__cell">
                      <span
                        id={991 * (index + 1)}
                        className="accessed-materials-widget__counter"
                        data-tooltip-content={t('metrics.tooltipsLikes')}
                      >
                        <ReactTooltip
                          anchorId={991 * (index + 1)}
                          className="tooltip"
                        />
                        <FiThumbsUp /> {item.likeCount}
                      </span>
                      <span
                        className="accessed-materials-widget__counter u-ml-2-5"
                        id={992 * (index + 1)}
                        data-tooltip-content={t('metrics.tooltipsComments')}
                      >
                        <ReactTooltip
                          anchorId={992 * (index + 1)}
                          className="tooltip"
                        />
                        <FiMessageCircle /> {item.postCount}
                      </span>
                      <span
                        className="accessed-materials-widget__counter u-ml-2-5"
                        id={993 * (index + 1)}
                        data-tooltip-content={t('metrics.tooltipsMaterials')}
                      >
                        <ReactTooltip
                          anchorId={993 * (index + 1)}
                          className="tooltip"
                        />
                        <FiFolder /> {item.contentAccessCount}
                      </span>
                      <span
                        className="accessed-materials-widget__counter u-ml-2-5"
                        id={994 * (index + 1)}
                        data-tooltip-content={t('metrics.tooltipsLessons')}
                      >
                        <ReactTooltip
                          anchorId={994 * (index + 1)}
                          className="tooltip"
                        />
                        <FiVideo /> {item.LMSAccessCount}
                      </span>
                      <span
                        className="accessed-materials-widget__counter u-ml-2-5"
                        id={995 * (index + 1)}
                        data-tooltip-content={t('metrics.tooltipsModules')}
                      >
                        <ReactTooltip
                          anchorId={995 * (index + 1)}
                          className="tooltip"
                        />
                        <AiOutlineTrophy />
                        {item.LMSFinishedCount}
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        show={activeStudent}
        onClose={() => setActiveStudent(null)}
        contentClassName="modal__content--full"
      >
        {activeStudent && (
          <IncomeBySubject
            onClose={() => setActiveStudent(null)}
            title={`Rendimento de ${activeStudent.name}`}
            userId={activeStudent?.id}
          />
        )}
      </Modal>
    </>
  );
}
