import React, { useEffect, useState } from 'react';
import TabNavBar from './TabNavBar';
import TabDisciplinesAndLessons from './TabDisciplinesAndLessons';
import TabMaterials from './TabMaterials';
import TabBooklet from './TabBooklet';
import TabActivities from './TabActivities';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { DragDropContext } from 'react-beautiful-dnd';
import { t } from 'i18next';
import { i18n } from 'app/components/i18n/i18n';

export default function Content(props) {
  const { currentModuleId, course, courseId, selectedTab } = props;
  const [activeTab, setActiveTab] = useState('disciplinas-e-aulas');
  const [moduleData, setModuleData] = useState();
  const [filteredDisciplines, setFilteredDisciplines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const client = useClient();

  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LOCALE);
  }, []);

  const getModuleData = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/learning_systems/${currentModuleId}?detailed=true`);
    if (error) {
      toast.error(t('toast.errorGetModule'));
    } else {
      setModuleData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currentModuleId !== 'm' && currentModuleId !== undefined) {
      getModuleData();
    }
  }, [currentModuleId]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const updateDisciplineList = async values => {
    setLoading(true);
    const { destination, source } = values;
    if (!destination) {
      return null;
    }
    if (destination.index === source.index) {
      return null;
    }

    const dIndex = destination.index;
    const sIndex = source.index;
    const newItems = reorder(filteredDisciplines, sIndex, dIndex);

    const newDisciplinesOrder = newItems.map((discipline, index) => {
      return { id: discipline.id, order: index };
    });

    const { error } = await client.mutate(
      ['learning_systems', currentModuleId, 'sort'],
      {
        'learning-system-contents': newDisciplinesOrder
      },
      { method: 'PUT' }
    );
    if (error) {
      toast.error(t('toast.errorReorderSubject'));
    } else {
      getModuleData();
      toast.success(t('toast.successReorderDisciplines'));
    }
  };

  const publishUnpublishModule = async () => {
    if (isSubmitting) return
    
    setIsSubmitting(true)
    setLoading(true);
    const { error } = await client.mutate(['learning_systems', currentModuleId], {
      publish: !moduleData?.publish
    });

    await getModuleData()

    if (error) {
      toast.error('Erro ao atualizar conteúdo');
    } else if(!moduleData?.publish){
      toast.success('Publicado com sucesso!');
    } else {
      toast.success('Despublicado com sucesso!');
    }

    setLoading(false);
    setIsSubmitting(false);
  }

  return (
    <div className="new-questions__content">
      <div className="new-questions__body">
        {moduleData && !moduleData?.publish && <span className="badge badge--tiny badge--danger">{t('textsCommon.unpublished')}</span>}
        {moduleData && moduleData?.publish && <span className="badge badge--tiny badge--success">{t('textsCommon.published')}</span>}

        <div
            className="form__check form__switch u-mb-0"
            onClick={e => e.stopPropagation()}
            style={{marginTop: '5px', marginLeft: '5px', paddingBottom: '5px'}}
          >
            <input
              className="form__check-input"
              id={`publish-${currentModuleId}`}
              name={`publish-${currentModuleId}`}
              type="checkbox"
              checked={moduleData?.publish}
              onClick={() => publishUnpublishModule()}
            />
            <label htmlFor={`publish-${currentModuleId}`}>{t('lms.publish')}</label>
          </div>

        <TabNavBar
          selectedTab={selectedTab}
          courseId={courseId}
          currentModuleId={currentModuleId}
        />

        {selectedTab === 'disciplinas-e-aulas' && (
          <DragDropContext onDragEnd={values => updateDisciplineList(values)}>
            <TabDisciplinesAndLessons
              loading={loading}
              setFilteredDisciplines={setFilteredDisciplines}
              filteredDisciplines={filteredDisciplines}
              currentModuleId={currentModuleId}
              moduleData={moduleData}
              course={course}
            />
          </DragDropContext>
        )}

        {selectedTab === 'materiais' && <TabMaterials currentModuleId={currentModuleId} />}

        {selectedTab === 'apostilas' && <TabBooklet currentModuleId={currentModuleId} />}

        {selectedTab === 'atividades' && (
          <TabActivities
            courseId={courseId}
            currentModuleId={currentModuleId}
          />
        )}
      </div>
    </div>
  );
}
