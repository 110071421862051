import React, { useState } from 'react';
import ManagersModal from './ManagersModal';
import UserInfo from 'app/components/UserInfo';
import { useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { t } from 'i18next';

export default function StepManagers(props) {
  const { form } = props;
  const client = useClient();

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [managers, setManagers] = useState([]);

  const getManagers = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('users?non_students=true');
    if (error) {
      toast.error(t('toast.errorGetParticipants'));
    } else {
      setManagers(data);
      setLoading(false);
    }
  };

  const addManager = manager => {
    const newManagers = [...form.values.managers, manager.id];
    form.setFieldValue('selectedManagers', [...form.values.selectedManagers, manager]);
    form.setFieldValue('managers', newManagers);
  };

  const removeManager = manager => {
    const newManagers = form.values.managers.filter(u => u !== manager.id);
    form.setFieldValue(
      'selectedManagers',
      form.values.selectedManagers.filter(u => u.id !== manager.id)
    );
    form.setFieldValue('managers', newManagers);
  };

  useEffect(() => {
    getManagers();
    if (form.values.selectedManagers.length > 0) {
      setSelectedManagers(form.values.selectedManagers);
    }
  }, []);

  useEffect(() => {
    setSelectedManagers(form.values.selectedManagers);
  }, [form.values.selectedManagers]);

  return (
    <div className="page__content fadeIn u-mb-5">
      <div className="new-questions__header">
        <h2 className="new-questions__title">{form.values.title || t('projects.newProjects')}</h2>
      </div>

      <p>{t('projects.managersDescription')}</p>

      <button
        type="button"
        className="btn btn--wide btn--primary"
        onClick={() => setShowModal(true)}
      >
        {t('button.add')}
      </button>
      <p>{form.touched.managers && form.errors.managers && <span style={{ color: 'red' }}>{form.errors.managers}</span>}</p>

      <div>
        <hr className="u-hr" />
        <table className="round-dg round-dg--light">
          <tbody className="round-dg__body">
            {!loading &&
              selectedManagers.map((manager, i) => (
                <tr
                  className="round-dg__row"
                  key={i}
                >
                  <th className="round-dg__cell-header round-dg__cell-header--row">
                    <UserInfo user={manager} />
                  </th>
                  <td className="round-dg__cell">{manager.email}</td>
                  <td
                    className="round-dg__cell"
                    style={{ width: '140px' }}
                  >
                    <button
                      className="btn btn--outline btn--full active"
                      type="button"
                      onClick={() => removeManager(manager)}
                    >
                      {t('button.remove')}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <ManagersModal
        form={form}
        show={showModal}
        loading={loading}
        selectedManagers={selectedManagers}
        managers={managers}
        addManager={addManager}
        removeManager={removeManager}
        onClose={() => {
          setShowModal(false);
        }}
      />
    </div>
  );
}
