import { Link } from '@reach/router';
import { FiClock } from 'react-icons/fi';

export default function GuideCardDashboard({ studyPlan, id }) {
  const clockIconsCount = Math.floor(Math.random() * 2) + 2;
  const clockIcons = Array.from({ length: clockIconsCount }, (_, index) => (
    <FiClock
      key={index}
      className="guide-card__icon"
    />
  ));
  if (!studyPlan) return null
  return (
    studyPlan && (
      <Link to={`/guia-de-estudos/bloco-de-estudo/${id}`}>
        <div className="guide-card-dashboard u-mb-3">
          <div className="guide-card-dashboard__content">
            <div>
              <h3>Guia de Estudos</h3>
              <div>
                <h1>{studyPlan?.title}</h1>
                {studyPlan?.taxonomies?.slice(0, 3).map((taxonomy, index) => (
                  <span
                    className="badge badge--tiny"
                    title={taxonomy?.name}
                    key={index}
                    style={{ marginRight: '8px' }}
                  >
                    {taxonomy?.name}
                  </span>
                ))}

                <span
                  className="badge badge--tiny badge--more"
                  title={studyPlan?.taxonomies?.slice(3).map(t => ` ${t.name}`)}
                >
                  +{studyPlan?.taxonomies?.length - 3}
                </span>
              </div>
            </div>
          </div>
          <div className="guide-card__time">{clockIcons}</div>
        </div>
      </Link>
    )
  );
}
