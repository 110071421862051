import React from 'react';
import { navigate, useParams } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';
import { useClient } from 'jsonapi-react';
import { useState } from 'react';
import { useEffect } from 'react';
import Loader from 'app/components/loader';
import { useSession } from 'app/hooks/useSession';

export default function ShowEmbed(props) {
  const client = useClient();
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(false);
  const { reportId } = useParams();
  const { session } = useSession();

  const getReport = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/embed_reports/${parseInt(reportId)}`);
    if (error) {
      toast.error('Erro ao buscar relatório');
    } else {
      setReport(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getReport();
  }, []);

  return (
    <main
      className="main-content"
      style={{ flexDirection: 'column' }}
    >
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <button
            className="filter-bar__back"
            onClick={() => navigate(-1)}
          >
            <span className="card__icon-wrapper">
              <FiChevronLeft className="card__icon" />
            </span>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{report?.label}</span>
            </span>
          </button>
        </div>
      </div>

      <div
        className="page__content"
        style={{ padding: '20px', width: '100%', height: '220px', flexGrow: '1', position: 'relative' }}
      >
        {!report && loading && <Loader />}
        {report && report.url ? (
          <iframe
            src={report?.url + '/' + session.user.uuid}
            frameborder="0"
            width="100%"
            height="100%"
            allowtransparency
            style={{ position: 'absolute', width: 'calc(100% - 40px)', height: 'calc(100% - 40px)' }}
          />
        ) : null}
      </div>
    </main>
  );
}
