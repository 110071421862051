import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import { FiChevronLeft, FiHelpCircle } from 'react-icons/fi';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { useQuery } from 'jsonapi-react';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import MostAccessedTimesChart from './MostAccessedTimesChart';
import toast from 'react-hot-toast';
import { useFilteredQuery } from 'app/hooks/useFilteredQuery';
import { GeoChart } from './GeoChart';
import useReportAbilities from 'app/hooks/useReportAbilities';
import Loader from 'app/components/loader';
import ReportForm from 'app/components/ReportForm';

export default function AccessReport() {
  const filters = useFormik({
    initialValues: {
      course: 'blank',
      'start-date': null,
      'end-date': moment().format('DD/MM/YYYY')
    }
  });

  const { data: courses = [], error, isLoading: isLoadingCourses } = useQuery('courses/simple?filter[is_public]=false');
  const { data: report, isLoading: isLoadingReport } = useFilteredQuery('regionals/access_report', filters?.values);
  const { reportAbilities, getReportAbilities } = useReportAbilities();
  const today = moment().format(t('datesForm.formatDate'));

  useEffect(() => {
    if (error) {
      toast.error('Erro ao buscar cursos');
    }
  }, [error]);

  const formatStartDate = filters.values['start-date'] ? moment(filters.values['start-date']).format('DD/MM/YYYY') : null;
  const formatEndDate = filters.values['end-date'] ? moment(filters.values['end-date']).format('DD/MM/YYYY') : null;

  const appName = process.env.REACT_APP_NAME;

  useEffect(() => {
    filters.setFieldValue('end-date', moment().format('YYYY-MM-DD'));
    getReportAbilities();
  }, []);

  return (
    <main className="report">
      <header className="report__header">
        <h1 className="report__title">
          <button
            className="card__icon-wrapper"
            aria-label="Voltar"
            title="Voltar"
            onClick={() => navigate(-1)}
          >
            <FiChevronLeft className="card__icon" />
          </button>
          Mapeamento de acesso
        </h1>
        <p className="report__description">Região geográfica e horários de maior atividade</p>
      </header>

      <div className="report__body">
        <div className="report__filter">
          <ReportForm.Select
            label={t('exams.selectCourse')}
            blankLabel={t('metrics.blankLabelSelect')}
            value={filters.values.course}
            onChange={e => filters.setFieldValue('course', e.target.value)}
            options={courses.map(o => ({
              id: o.id,
              name: o.title
            }))}
          />
          <div className="report-widget__filter">
            <div className="report-form__datepicker">
              <input
                className="report-form__control"
                id="start-date"
                name="start-date"
                min={today}
                type="date"
                value={moment(filters.values['start-date']).format('YYYY-MM-DD')}
                onChange={e => filters.setFieldValue('start-date', e.target.value)}
                title="Data de início"
                aria-label="Data de início"
              />
              <input
                className="report-form__control"
                id="end-date"
                name="end-date"
                type="date"
                min={today}
                value={moment(filters.values['end-date']).format('YYYY-MM-DD')}
                onChange={e => filters.setFieldValue('end-date', e.target.value)}
                title="Data de fim"
                aria-label="Data de fim"
              />
            </div>

            <FiHelpCircle
              className="report-widget__help-icon"
              data-tooltip-id="access"
              data-tooltip-content="O mapa de calor exibe no número de acessos por região entre o período selecionado, com cores variando de roxo (menos acessos) a amarelo (mais acessos)."
            />
            <ReactTooltip
              id="access"
              className="tooltip"
            />
          </div>
        </div>

        {isLoadingReport && <Loader.Spinner />}

        {!isLoadingReport && (
          <div className="report-widget">
            {(appName === 'canaleducacao' || 'demo') && reportAbilities?.['mapeamento-de-acesso-do-usuario']?.['mapa-de-calor'] ? (
              <>
                <div
                  className="report-widget__header"
                  style={{ justifyContent: 'normal' }}
                >
                  <h3 className="report-widget__title">Acessos por GRE </h3>
                  {formatStartDate && formatEndDate && (
                    <span>
                      {' '}
                      {formatStartDate} a {formatEndDate}{' '}
                    </span>
                  )}
                </div>

                <div className="report-widget__body u-mb-4">
                  <GeoChart
                    regionals={report?.regionals}
                    initialDate={filters.values['start-date']}
                    finalDate={filters.values['end-date']}
                  />
                </div>
              </>
            ) : null}

            {reportAbilities?.['mapeamento-de-acesso-do-usuario']?.['horarios-mais-acessados'] && (
              <div style={{ position: 'relative' }}>
                <div
                  className="report-widget__header"
                  style={{ justifyContent: 'normal' }}
                >
                  <div className="report-widget__title-with-btn">
                    <h3 className="report-widget__title">Horários mais acessados (Acessos recorrentes)</h3>
                    <FiHelpCircle
                      className="report-widget__help-icon"
                      data-tooltip-id="timetable"
                      data-tooltip-content="O gráfico mostra a frequência de acessos por dia e hora. Tons mais escuros indicam mais atividade."
                      style={{ right: '-24px' }}
                    />
                    <ReactTooltip
                      id="timetable"
                      className="tooltip"
                    />
                  </div>
                  {formatStartDate && formatEndDate && (
                    <span style={{ marginRight: '24px' }}>
                      {' '}
                      {formatStartDate} a {formatEndDate}{' '}
                    </span>
                  )}
                </div>
                <div className="report-widget__body">
                  <MostAccessedTimesChart dayMetrics={report?.dayMetrics} />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </main>
  );
}
