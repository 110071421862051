import React, { useEffect } from 'react';
import { Link } from '@reach/router';
// import logo from './img/logo.svg';
import error from './img/error.svg';
import { useTranslation } from 'react-i18next';

export default function Error404() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LOCALE);
  }, []);

  return (
    <div className="error-404">
      <div className="error-404__inner">
        {/* <img
          className="error-404__logo"
          src={logo}
          alt="logotipo Estudologia"
        /> */}
        <img
          className="error-404__img"
          src={error}
          alt="imagem de erro"
        />

        <h1 className="error-404__title">{t('notFoundPage.titleOhNo')}</h1>
        <p className="error-404__description">{t('notFoundPage.Description404')}</p>

        <Link
          className="btn btn--outline"
          to="/"
        >
          {t('notFoundPage.buttonBackToPlatform')}
        </Link>
      </div>
    </div>
  );
}
