import React, { useState, useEffect } from 'react';
import Calendar from 'app/components/Calendar';
import ViewTask from './ViewTask';
import Onboarding from 'app/components/Onboarding';
import { useSession } from 'app/hooks/useSession';
import { useTranslation } from 'react-i18next';

function MyTasks(props) {
  const { t } = useTranslation();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const path = props.path;
  const { session } = useSession();
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];

  return (
    <>
      <div className="main-content">
        <div className="home-content">
          <Calendar.Expanded
            myTasks
            slotType="master_slot"
          />
        </div>
      </div>

      <Onboarding
        path={path}
        show={showOnboarding}
        setShowOnboarding={setShowOnboarding}
        onClose={() => setShowOnboarding(false)}
        profiles={profiles}
        title={t('onboarding.titleTasks')}
        videoId="_xkxl1uzQSI"
      />
    </>
  );
}

export { MyTasks as default, ViewTask };
