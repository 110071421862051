import axios from 'axios';
import { getToken } from './auth';

export const baseURL = () => {
  const env = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_URL : process.env.REACT_APP_DEV_URL;

  return env;
};

const api = axios.create({
  baseURL: baseURL()
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(response => {
  return response.data;
});

export const apiTokenless = axios.create({
  baseURL: baseURL()
});

export default api;
