import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FiStar } from 'react-icons/fi';

export default function AverageAccessChart(props) {
  const chartOptions = {
    chart: {
      type: 'bar',
      height: 400
    },
    title: { text: null },

    xAxis: {
      categories: ['1 estrela', '2 estrelas', '3 estrelas', '4 estrelas', '5 estrelas'],
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
        align: 'high'
      },
      labels: {
        overflow: 'justify'
      },
      plotLines: [
        {
          color: '#5DC9DD',
          value: '15',
          width: '2',
          zIndex: 4,
          label: {
            text: 'Linha média',
            align: 'left'
          }
        }
      ]
    },
    tooltip: {
      valueSuffix: ' nome da aula, nome da aula'
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        }
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    series: [
      {
        name: 'Satisfação',
        data: [200, 150, 50, 125, 30],
        color: '#442B8C'
      }
    ]
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
}
