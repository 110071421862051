import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function QuestionTimeChart(props) {
  const { name, color, chartData, valueSuffix } = props;

  const chartOptions = {
    chart: {
      type: 'column',
      height: 200,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    xAxis: [
      {
        categories: (chartData?.categories || []),
        title: { text: null },
      },
    ],
    yAxis: {
      // Primary yAxis
      title: {
        text: null,
        style: {
          color: '#32C9D3',
        },
      },
      labels: {
        style: {
          color: '#32C9D3',
        },
      },
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br>',
      pointFormat: '{point.x:%b}: {point.y}h',
      valueSuffix: valueSuffix,    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: 'Tempo',
        data: (chartData?.data || []),
      }
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
}

export default QuestionTimeChart;
